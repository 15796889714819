import React, {useCallback, useState} from 'react';
import {Paper, TableContainer} from '@mui/material';
import {PostingDetailsListItemModel} from '../models/PostingDetailListItemModel';
import {IPostingDetailsState} from '../../../state/IPostingDetailsState';
import {intl} from '../../../../../../root/IntlProvider';
import {observer} from 'mobx-react-lite';
import {
  ButtonTertiary,
  EmptyTable,
  Icon,
  IconColor,
  IconSvg,
  IconVariant,
  Table,
  TableRow,
  Tooltip,
} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {cutTextLength} from '../../../../../../common/helpers/tableInputTextCutLenght';
import {PostingDetailsBodyListNumericInput} from './PostingDetailsBodyListNumericInput';
import {DecreeLineTypeEnum} from '@symfonia-ksef/graphql';
import {InvoiceCurrencyStatusCellValue} from '../../../../../../common';

export interface PostingDetailsBodyListRowProps {
  state: IPostingDetailsState;
}

export const PostingDetailsBodyListTable = observer(({state}: PostingDetailsBodyListRowProps) => {
  const detailsBodyState = state.postingDetailsBodyListState;
  const [openRow, setOpenRow] = useState<string[]>([]);
  const [openAll, setOpen] = useState(false);

  const tableHeader = [
    {
      name: '1',
      content: (
        <ButtonTertiary
          onClick={() => {
            setOpen(!openAll);
          }}
          lIcon={!openAll ? IconSvg.ADD_BOX : IconSvg.INDETERMINATE_CHECK_BOX}
          iconOptions={{variant: IconVariant.CONTOUR}}
        />
      ),
      width: 'w-[50px]',
      className: 'grow-0',
    },
    {
      name: 'rowIndex',
      title: intl.formatMessage({id: 'Lp'}),
      width: 'w-[40px]',
      className: 'grow-0',
    },
    {
      name: 'category',
      title: intl.formatMessage({id: 'category'}),
      width: 'w-[150px]',
    },
    {
      name: 'account',
      title: intl.formatMessage({id: 'account'}),
      width: 'w-[120px]',
    },
    {
      name: 'amountWn',
      title: intl.formatMessage({id: 'amountWn'}),
      width: 'w-[100px]',
    },
    {
      name: 'amountMa',
      title: intl.formatMessage({id: 'amountMa'}),
      width: 'w-[100px]',
      className: 'mr-[20px]',
    },
  ];

  const isPageEditable = state.isPageEditableByStatus;
  const isEmptyOnSalesInvoice = (item: string) => {
    return item.length === 0;
  };

  const onAccountCellClicked = (row: PostingDetailsListItemModel, anchorRef?: HTMLElement) => {
    if (!row.positions?.length) return;
    const isAccountChangeAvailable =
      row.decreeLineType !== DecreeLineTypeEnum.Tax && row.decreeLineType !== DecreeLineTypeEnum.DocumentRelated;
    state.postingDetailsBodyListState.openSingleRowAccountActionsMenu(row, anchorRef, isAccountChangeAvailable);

    const updatedOpenRow = openRow.filter(i => i !== `${row.categoryId}-${row.accountType}`);
    setOpenRow(updatedOpenRow);
  };

  const onCategoryCellClicked = (row: PostingDetailsListItemModel, anchorRef?: HTMLElement) => {
    if (!row.positions?.length) return;
    state.postingDetailsBodyListState.openSingleRowCategoryActionsMenu(row, anchorRef);
    const updatedOpenRow = openRow.filter(i => i !== `${row.categoryId}-${row.accountType}`);
    setOpenRow(updatedOpenRow);
  };
  const changeAccordion = useCallback(
    (idx: string) => {
      if (openRow.includes(idx)) {
        const updatedOpenRow = openRow.filter(i => i !== idx);
        setOpenRow(updatedOpenRow);
      } else {
        const updatedOpenRow = [idx, ...openRow];
        setOpenRow(updatedOpenRow);
      }
    },
    [openRow, setOpenRow],
  );

  const styles = {
    column: `grow shrink leading-[24px] pl-[16px] items-center inline-flex min-h-[48px] text-[16px]} `,
    txt: ' text-base font-quicksand truncate ',
  };
  const getValidationErrorClass = (isWnCell = true, row: PostingDetailsListItemModel) => {
    if (!detailsBodyState.isSumValid && row.positions.filter(p => p.isRuleCategory).length > 0) {
      if ((isWnCell && !state.isSalesInvoice) || (!isWnCell && state.isSalesInvoice)) {
        return 'font-bold text-red';
      }
    }
    return '';
  };

  return (
    <TableContainer
      component={Paper}
      className={'overflow-scroll border-2 border-gray-200 border-solid rounded-lg shadow-none bg-white box-border'}
    >
      <Table header={tableHeader} className={'w-full font-normal'}>
        {detailsBodyState.rows !== undefined ? (
          detailsBodyState.rows?.map((row: PostingDetailsListItemModel, index) => {
            return (
              <TableRow
                key={row.id + openAll}
                dropdownAtColumn={0}
                dropdownOpen={openAll || openRow.includes(`${row.category}-${row.categoryId}-${row.accountType}`)}
                onClick={e => {
                  setOpenRow(
                    openRow.includes(`${row.category}-${row.categoryId}-${row.accountType}`)
                      ? openRow.filter(x => x !== `${row.category}-${row.categoryId}-${row.accountType}`)
                      : [...openRow, `${row.category}-${row.categoryId}-${row.accountType}`],
                  );
                }}
                dropdownContent={
                  row.positions.length > 0 ? (
                    <div className="w-full bg-grey-200">
                      {row.positions.map(position => (
                        <div
                          key={position.id}
                          className={
                            'flex w-full items-center h-[48px] border-b border-solid border-grey-100 rounded-[2px]'
                          }
                        >
                          <span className={styles.column + ' w-[50px] grow-0'}/>
                          <span className={styles.column + ' w-[40px] grow-0'}/>
                          <Tooltip text={position.name} position={'bottom-end'}>
                            <div
                              className={styles.column + styles.txt + ' w-[150px]'}
                              onClick={e => {
                                if (isPageEditable) {
                                  e.stopPropagation();
                                  state.postingDetailsBodyListState.openSinglePositionCategoryActionsMenu(
                                    position,
                                    e.currentTarget,
                                  );
                                }
                              }}
                            >
                              {isPageEditable && (
                                <ButtonTertiary
                                  lIcon={IconSvg.MORE_VERT}
                                  disabled={!isPageEditable}
                                  className={'w-[20px] p-0 ml-0 flex min-w-[20px]'}
                                  iconOptions={{
                                    color: !isPageEditable ? IconColor.GREY_500 : IconColor.GREEN_600,
                                    className: 'w-[10px] min-w-[25px]',
                                  }}
                                />
                              )}
                              {cutTextLength(position.name, state.invoicePreview)?.truncatedText}
                            </div>
                          </Tooltip>
                          <Tooltip text={row.account} position={'bottom-end'}>
                            <div
                              className={styles.column + styles.txt + ' w-[120px]'}
                              onClick={e => {
                                if (isPageEditable) {
                                  e.stopPropagation();
                                  state.postingDetailsBodyListState.openSinglePositionAccountActionsMenu(
                                    position,
                                    e.currentTarget,
                                  );
                                }
                              }}
                            >
                              {isPageEditable && (
                                <ButtonTertiary
                                  lIcon={IconSvg.MORE_VERT}
                                  disabled={!isPageEditable}
                                  className={'w-[20px] p-0 ml-0 flex min-w-[20px]'}
                                  iconOptions={{
                                    color: !isPageEditable ? IconColor.GREY_500 : IconColor.GREEN_600,
                                    className: 'w-[10px] min-w-[25px]',
                                  }}
                                />
                              )}
                              {!isEmptyOnSalesInvoice(row.account)
                                ? cutTextLength(row.account, state.invoicePreview)?.truncatedText
                                : intl.formatMessage({id: Tr.missingAccountLabel})}
                            </div>
                          </Tooltip>
                          <div
                            className={
                              getValidationErrorClass(false, row) +
                              ' ' +
                              styles.column +
                              styles.txt +
                              ' w-[100px] justify-end'
                            }
                          >
                            {position.value !== null && !state.isSalesInvoice && (
                              <PostingDetailsBodyListNumericInput
                                value={position.value}
                                isPageEditable={isPageEditable}
                                IsRound={position.IsRound}
                                onPositionValueChange={v =>
                                  detailsBodyState.onPositionValueChange(
                                    `${row.category}-${row.categoryId}-${row.accountType}`,
                                    position.id,
                                    v,
                                  )
                                }
                              />
                            )}
                          </div>
                          <div
                            className={
                              getValidationErrorClass(true, row) +
                              ' ' +
                              styles.column +
                              styles.txt +
                              ' w-[100px] justify-end mr-[20px]'
                            }
                          >
                            {position.value !== null && state.isSalesInvoice && (
                              <PostingDetailsBodyListNumericInput
                                value={position.value}
                                isPageEditable={isPageEditable}
                                IsRound={position.IsRound}
                                onPositionValueChange={v =>
                                  detailsBodyState.onPositionValueChange(
                                    `${row.category}-${row.categoryId}-${row.accountType}`,
                                    position.id,
                                    v,
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : undefined
                }
              >
                <ButtonTertiary
                  className="ml-[10px]"
                  onClick={() => changeAccordion(`${row.category}-${row.categoryId}-${row.accountType}`)}
                  lIcon={
                    openRow.includes(`${row.category}-${row.categoryId}-${row.accountType}`)
                      ? IconSvg.ADD_BOX
                      : IconSvg.INDETERMINATE_CHECK_BOX
                  }
                  iconOptions={{variant: IconVariant.CONTOUR}}
                />
                <span>{row.rowIndex}</span>
                <Tooltip text={row.category} position={'bottom-end'}>
                  <button
                    disabled={!isPageEditable && !!row.positions.length}
                    className={
                      'truncate w-full flex justify-between text-base font-quicksand items-start whitespace-nowrap ' +
                      (isPageEditable && !!row.positions.length ? 'border-b-primary-500 border-b' : '')
                    }
                    onClick={e => {
                      if (isPageEditable) {
                        e.stopPropagation();
                        onCategoryCellClicked(row, e.currentTarget);
                      }
                    }}
                  >
                    <div className={'truncate max-w-[90%]'}>
                      {!isEmptyOnSalesInvoice(row.category)
                        ? row.category
                        : intl.formatMessage({id: Tr.missingCategoryLabel})}
                    </div>
                    {isPageEditable && !!row.positions.length && (
                      <Icon svg={IconSvg.EDIT} color={IconColor.GREEN_700} className={'min-w-[24px]'}/>
                    )}
                  </button>
                </Tooltip>
                <Tooltip text={row.account} position={'bottom-end'}>
                  <button
                    disabled={state.isSaveInProgress}
                    className={
                      'truncate w-full flex justify-between text-base font-quicksand items-start whitespace-nowrap ' +
                      (isPageEditable && !!row.positions.length ? 'border-b-primary-500 border-b' : '')
                    }
                    onClick={e => {
                      if (isPageEditable) {
                        e.stopPropagation();
                        onAccountCellClicked(row, e.currentTarget);
                      }
                    }}
                  >
                    <div className={'max-w-[90%] truncate'}>
                      {!isEmptyOnSalesInvoice(row.account)
                        ? row.account
                        : intl.formatMessage({id: Tr.missingAccountLabel})}
                    </div>
                    {isPageEditable && !!row.positions.length && (
                      <Icon svg={IconSvg.EDIT} color={IconColor.GREEN_700} className={'min-w-[24px]'}/>
                    )}
                  </button>
                </Tooltip>
                <div className={'justify-end w-full flex ' + styles.txt}>
                  {!state.isSalesInvoice ? (
                    row.wn !== null ? (
                      row.decreeLineType === DecreeLineTypeEnum.Tax ? (
                        <PostingDetailsBodyListNumericInput
                          value={row.wn}
                          isPageEditable={isPageEditable}
                          IsRound={!!row.positions.find(e => e.IsRound)}
                          onPositionValueChange={x =>
                            detailsBodyState.onDecreeLineValueChange(
                              `${row.category}-${row.categoryId}-${row.accountType}`,
                              x,
                            )
                          }
                        />
                      ) : (
                        <>
                          {!!row.positions.find(e => e.IsRound) && <span className={'font-bold'}>*</span>}
                          <p className={row.positions.find(e => e.IsRound) ? 'font-bold' : ''}>
                            {
                              cutTextLength(InvoiceCurrencyStatusCellValue(row.wn ?? 0), state.invoicePreview, false)
                                ?.truncatedText
                            }
                          </p>
                        </>
                      )
                    ) : (
                      ' '
                    )
                  ) : row.wn !== null ? (
                    row.decreeLineType === DecreeLineTypeEnum.DocumentRelated ? (
                      <PostingDetailsBodyListNumericInput
                        value={row.wn}
                        isPageEditable={isPageEditable}
                        IsRound={!!row.positions.find(e => e.IsRound)}
                        onPositionValueChange={x =>
                          detailsBodyState.onDecreeLineValueChange(
                            `${row.category}-${row.categoryId}-${row.accountType}`,
                            x,
                          )
                        }
                      />
                    ) : (
                      <>
                        {!!row.positions.find(e => e.IsRound) && <span className={'font-bold'}>*</span>}
                        <p className={row.positions.find(e => e.IsRound) ? 'font-bold' : ''}>
                          {
                            cutTextLength(InvoiceCurrencyStatusCellValue(row.wn ?? 0), state.invoicePreview, false)
                              ?.truncatedText
                          }
                        </p>
                      </>
                    )
                  ) : (
                    ' '
                  )}
                </div>
                <div className={'justify-end w-full flex ' + styles.txt}>
                  {!state.isSalesInvoice ? (
                    row.ma !== null ? (
                      row.decreeLineType === DecreeLineTypeEnum.DocumentRelated ? (
                        <PostingDetailsBodyListNumericInput
                          value={row.ma}
                          isPageEditable={isPageEditable}
                          IsRound={!!row.positions.find(e => e.IsRound)}
                          maximumValue={1000000000000000}
                          onPositionValueChange={x =>
                            detailsBodyState.onDecreeLineValueChange(
                              `${row.category}-${row.categoryId}-${row.accountType}`,
                              x,
                            )
                          }
                        />
                      ) : (
                        <>
                          {!!row.positions.find(e => e.IsRound) && <span className={'font-bold'}>*</span>}
                          <p className={row.positions.find(e => e.IsRound) ? 'font-bold' : ''}>
                            {
                              cutTextLength(InvoiceCurrencyStatusCellValue(row.ma ?? 0), state.invoicePreview, false)
                                ?.truncatedText
                            }
                          </p>
                        </>
                      )
                    ) : (
                      ' '
                    )
                  ) : row.ma !== null ? (
                    row.decreeLineType === DecreeLineTypeEnum.Tax ? (
                      <PostingDetailsBodyListNumericInput
                        value={row.ma}
                        isPageEditable={isPageEditable}
                        IsRound={!!row.positions.find(e => e.IsRound)}
                        onPositionValueChange={x =>
                          detailsBodyState.onDecreeLineValueChange(
                            `${row.category}-${row.categoryId}-${row.accountType}`,
                            x,
                          )
                        }
                      />
                    ) : (
                      <>
                        {!!row.positions.find(e => e.IsRound) && <span className={'font-bold'}>*</span>}
                        <p className={row.positions.find(e => e.IsRound) ? 'font-bold' : ''}>
                          {
                            cutTextLength(InvoiceCurrencyStatusCellValue(row.ma ?? 0), state.invoicePreview, false)
                              ?.truncatedText
                          }
                        </p>
                      </>
                    )
                  ) : (
                    ' '
                  )}
                </div>
              </TableRow>
            );
          })
        ) : (
          <TableRow caption>
            <EmptyTable/>
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
});
