import {
  AuthorizeInKSeFWithTokenJobRunner,
  AuthorizeInKSeFWithTokenJobRunnerI,
} from '../../services/KSeFJobRunners/AuthorizeInKSeFWithTokenJobRunner';
import {
  RemoveAuthorizationInKSeFWithTokenJobRunner,
} from '../../services/KSeFJobRunners/RemoveAuthorizationInKSeFWithTokenJobRunner';
import {JobRunnerManager, JobRunnerManagerI} from '../../services/JobRunnerManager';
import {
  AuthorizeInKSeFWithTokenMutationVariables,
  RemoveAuthorizationWithExternalTokenInKSeFMutationVariables,
  WebsocketErrorType,
} from '@symfonia-ksef/graphql';
import {EventJobRunnerI} from '../../services/EventJobRunner';
import {action} from 'mobx';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class AuthorizationInKSeFWithTokenService {
  public errorType: WebsocketErrorType | undefined;

  private authorizationRunnerManager: JobRunnerManagerI<AuthorizeInKSeFWithTokenMutationVariables, AuthorizeInKSeFWithTokenJobRunnerI>;

  private removeAuthorizationRunnerManager: JobRunnerManagerI<RemoveAuthorizationWithExternalTokenInKSeFMutationVariables, AuthorizeInKSeFWithTokenJobRunnerI>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    this.authorizationRunnerManager = new JobRunnerManager<AuthorizeInKSeFWithTokenMutationVariables, AuthorizeInKSeFWithTokenJobRunnerI>(() => new AuthorizeInKSeFWithTokenJobRunner(envObserver, earchiveState), envObserver);
    this.removeAuthorizationRunnerManager = new JobRunnerManager<RemoveAuthorizationWithExternalTokenInKSeFMutationVariables, AuthorizeInKSeFWithTokenJobRunnerI>(() => new RemoveAuthorizationInKSeFWithTokenJobRunner(envObserver, earchiveState), envObserver);
  }

  public get removeAuthorizationRunner(): EventJobRunnerI<RemoveAuthorizationWithExternalTokenInKSeFMutationVariables> & AuthorizeInKSeFWithTokenJobRunnerI {
    return this.removeAuthorizationRunnerManager.jobRunner;
  }

  public get authorizationRunner(): EventJobRunnerI<AuthorizeInKSeFWithTokenMutationVariables> & AuthorizeInKSeFWithTokenJobRunnerI {
    return this.authorizationRunnerManager.jobRunner;
  }

  @action.bound
  public setErrorType(errorType: WebsocketErrorType | undefined): this {
    this.errorType = errorType;
    return this;
  }
}
