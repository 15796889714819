import {authState} from '@symfonia-ksef/state/rootRepository';

export const TEN_YEARS = 315360000000;

export interface StorageServiceInterface {
  setItem<T>(keyName: string, keyValue: T, ttl: number): void;

  getItem<T>(keyName: string): T | undefined;

  removeItem<T>(keyName: string): T | undefined;

  getStorage(): Storage;
}

class StorageService implements StorageServiceInterface {
  constructor(private storage: Storage) {
  }

  /**
   * @param {string} keyName - A key to identify the value.
   * @param {any} keyValue - A value associated with the key.
   * @param {number} ttl- Time to live in seconds.
   */
  public setItem<T>(keyName: string, keyValue: T, ttl: number = TEN_YEARS) {
    const data = {
      value: keyValue,
      ttl: Date.now() + ttl,
    };
    // store data in Storage
    this.storage.setItem(this.getStorageKeyWithSuffix(keyName), JSON.stringify(data));
  }

  /**
   * @param {string} keyName - A key to identify the data.
   * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
   */
  public getItem<T>(keyName: string): T | undefined {
    const data = this.storage.getItem(this.getStorageKeyWithSuffix(keyName));
    if (!data) {
      return undefined;
    }
    const item = JSON.parse(data);
    if (Date.now() > item.ttl) {
      localStorage.removeItem(this.getStorageKeyWithSuffix(keyName));
      return undefined;
    }
    // return data if not expired
    return item.value;
  }

  /**
   * @param {string} keyName - A key to identify the data.
   * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
   */
  public removeItem<T>(keyName: string): T | undefined {
    this.storage.removeItem(this.getStorageKeyWithSuffix(keyName));
    return undefined;
  }

  private getStorageKeyWithSuffix(s: string): string {
    return s + '.' + authState.currentUserEmail ?? '';
  }

  public getStorage(): Storage {
    return this.storage;
  }
}

export const sessionStorageService = new StorageService(sessionStorage);
export const localStorageService = new StorageService(localStorage);



