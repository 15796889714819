import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {PostingDetailsBodyListSummary} from './components/PostingDetailsBodyListSummary';
import {PostingDetailsBodyListCategoryActionMenu} from './components/PostingDetailsBodyListCategoryActionMenu';
import {PostingDetailsBodyListAccountActionMenu} from './components/PostingDetailsBodyListAccountActionMenu';
import {PostingDetailsBodyListTable} from './components/PostingDetailsBodyListTable';

export interface PostingDetailsProps {
  state: IPostingDetailsState;
}

export const PostingDetailsBodyList = observer(({state}: PostingDetailsProps) => {
  const detailsBodyState = state.postingDetailsBodyListState;

  return (
    <div className={'flex h-full flex-col items-center justify-between'}>
      <PostingDetailsBodyListAccountActionMenu state={detailsBodyState}/>
      <PostingDetailsBodyListCategoryActionMenu state={detailsBodyState}/>

      <PostingDetailsBodyListTable state={state}/>

      <div className="w-full">
        <PostingDetailsBodyListSummary
          sumWn={detailsBodyState.sumWn}
          sumMa={detailsBodyState.sumMa}
          isSumValid={detailsBodyState.isSumValid}
          isSalesInvoice={state.isSalesInvoice ?? false}
          isMaValueModified={detailsBodyState.isMaValueModified}
          isWnValueModified={detailsBodyState.isWnValueModified}
        />
      </div>
    </div>
  );
});
