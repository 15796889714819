import {Navigate, Route, Routes} from 'react-router-dom';
import * as React from 'react';
import {lazy} from 'react';
import {LazyComponent} from '../common';
import {AppRoutes} from './Routes';
import {AuthGuard} from '../auth/guards/AuthGuard';
import {GraphQLProvider} from './providers/GraphQLProvider';
import {RootPage} from './pages/Root/RootPage';
import {RootEnvironmentsLoader} from './RootEnvironmentsLoader';
import {LayoutModule} from '../layout';

const EArchiveModule = lazy(() => import('../earchive/EArchiveModule'));

export const RootModule = () => {
  return (
    <AuthGuard>
      <GraphQLProvider>
        <RootEnvironmentsLoader>
          <LayoutModule>
            <Routes>
              <Route path="/" element={<RootPage/>}>
                <Route index element={<Navigate to="earchive" replace/>}/>
                <Route
                  path={AppRoutes.eArchive.address + '/*'}
                  element={
                    <LazyComponent>
                      <EArchiveModule title={AppRoutes.eArchive.title}/>
                    </LazyComponent>
                  }
                />
                <Route path="*" element={<Navigate to="/earchive" replace/>}/>
              </Route>
            </Routes>
          </LayoutModule>
        </RootEnvironmentsLoader>
      </GraphQLProvider>
    </AuthGuard>
  );
};
