import {GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables} from "@symfonia-ksef/graphql";
import {ExtendedTableServiceI} from "../../../../../../../root/services/TableServices/ExtendedTableService";
import {TableBuilder} from "../../../../../../../../modules/root/services/TableServices/TableBuilder";
import {BaseModule} from "../../../../../../../root/services/MobXServices/BaseModule";
import {IVehicleState} from "./state/IVehicleState";
import {VehiclesDataSourceHandler, VehiclesRepositoryI, VehiclesRow} from "./state/Vehicles.repository";
import {vehiclesColumns} from "./VehiclesTable/VehiclesTableConfig";
import {SearchService, SearchServiceI} from "../../../../../../../root/services/SearchService/SearchService";
import {TableSortDirection} from "@symfonia/brandbook";
import {PaginationMode} from "../../../../../../../root/services/TableServices/PaginationTableService";
import {computed, makeObservable} from "mobx";
import {Pills} from "../../../../../../../root/components/FiltersPills";
import {TranslatedFilter} from "@symfonia-ksef/state/filters/BaseFilterState";
import {intl} from "../../../../../../../root/IntlProvider";


export type VehiclesTableType = ExtendedTableServiceI<VehiclesRow, 'GetCompanyVehicles', GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables, IVehicleState>;

export class VehiclesPageService extends BaseModule {
  public readonly tableService!: VehiclesTableType;
  public readonly repository!: VehiclesRepositoryI;
  public readonly searchService!: SearchServiceI;

  constructor(private readonly state: IVehicleState) {
    super();

    const dataSourceHandler = new VehiclesDataSourceHandler(state);
    this.repository = dataSourceHandler.repository;
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);
    this.tableService = TableBuilder.create<VehiclesRow, 'CompanyVehicles', GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables, IVehicleState>()
      .connectKeyFactory(row => row.Id)
      .connectColumns(vehiclesColumns)
      .connectSortBy({
        sortBy: {name: 'RegistrationNumber', direction: TableSortDirection.DESC},
        persistKey: 'vehicleTableSortBy',
      })
      .connectContext(state)
      .connectDataSource(dataSourceHandler)
      .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}, persistKey: 'vehicleTablePagination'})
      .buildExtended();
    makeObservable(this);
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};

    for (const [filterKey, value] of this.state.filterState.pillsList.entries()) {
      const {values, translationKey} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: translationKey ?? filterKey.replace('.', '')}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.state.filterState.clearSingleFilter(filterKey, key, value);
            this.repository.fetch();
          },
        })),
      };
    }

    return convertedPills;
  }

  protected async _onMount(): Promise<void> {
    this.tableService.onMount();
    this.searchService.onMount();
    await this.repository.fetch();
  }

  protected _onUnmount() {
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }
}
