import {SendInvoicesDataModel} from "../../../../../services/helpers/NotificationDataParsers";
import {SendInvoicesModelToMap} from "../services/KSeFEventsConverters/SendingInvoicesEventConverter";
import {KSeFEventsConverter} from "../services/KSeFEventsConverters/AbstractKSeFEventsConverters";

export class SendingInvoicesModelMapper {
    public static map({invoices, ...rest}: SendInvoicesModelToMap): SendInvoicesDataModel | null {
        return {
          ...rest, invoices: invoices.map(invoice => ({
              ...invoice,
              kSeFStatus: KSeFEventsConverter.getMappedKSeFStatus(invoice.kSeFStatus),
            }),
          ),
        };
      }
}