import {FormattedMessage, useIntl} from 'react-intl';
import React, {FC, useMemo, useState} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {earchiveState, grantPermissionsService} from '@symfonia-ksef/state/rootRepository';
import {CredentialsFragment, KSeFIdentifierType} from '@symfonia-ksef/graphql';
import {IconSvg} from '@symfonia/brandbook';
import {ConfirmModal} from '@symfonia/symfonia-ksef-components';
import {ContextualMenu} from '../../../../common/components/ContextualMenu/ContextualMenu';
import {MenuAction} from '../../../../common/types/MenuActions';

type SinglePermissionActionMenuProps = {
  setAddEditMode: (isEditMode: boolean) => void,
  setIsEditMode: (isEditMode: boolean) => void,
  isSingleActionMenuVisible: boolean,
  setIsSingleActionMenuVisible: (isEditMode: boolean) => void,
  singleActionAnchorEl: HTMLElement | undefined,
  data: CredentialsFragment,
  setAddSelfInvoicingEditMode: (isAddSelfInvoicingEditMode: boolean) => void,
};

export const SinglePermissionActionMenu: FC<SinglePermissionActionMenuProps> = observer(({
                                                                                           setAddEditMode,
                                                                                           setIsEditMode,
                                                                                           isSingleActionMenuVisible,
                                                                                           setIsSingleActionMenuVisible,
                                                                                           singleActionAnchorEl,
                                                                                           data,
                                                                                           setAddSelfInvoicingEditMode,
                                                                                         }) => {
  const intl = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isSelfInvoicing = data.CredentialsSelfInvoicing;
  const isUserSelfEditing = data.Identifier === earchiveState.company.identifier;

  const handleEditPermission = () => {
    setIsSingleActionMenuVisible(false);
    setIsEditMode(true);
    if (isSelfInvoicing) {
      setAddSelfInvoicingEditMode(true);
    } else {
      setAddEditMode(true);
    }
  };

  const handleRemovePermission = () => {
    setIsSingleActionMenuVisible(false);
    setOpenDeleteModal(true);
  };

  const onCancelDeletePermission = () => {
    setOpenDeleteModal(false);
  };

  const deletePermission = async () => {
    setOpenDeleteModal(false);
    grantPermissionsService.setPermissions(data);
    await grantPermissionsService.delete(isSelfInvoicing ? KSeFIdentifierType.InstitutionalNip : KSeFIdentifierType.IndividualNip);
  };

  const actions = useMemo<MenuAction[]>(() => [
    {
      key: Tr.edit,
      label: intl.formatMessage({id: Tr.edit}),
      onClick: handleEditPermission,
      icon: IconSvg.EDIT,
      disabled: isUserSelfEditing,
      'test-id': 'editPermissionMenuItem',
      tooltipProps: isUserSelfEditing ? {
        tooltipTitle: <FormattedMessage id={Tr.userCannotEditOwnersPermissions_Tooltip}/>,
      } : undefined,
    },
    {
      key: Tr.removeButton,
      label: intl.formatMessage({id: Tr.removeButton}),
      onClick: handleRemovePermission,
      icon: IconSvg.DELETE,
      disabled: isUserSelfEditing,
      loading: grantPermissionsService.checkIsPending(data.Identifier),
      'test-id': 'removePermissionMenuItem',
      tooltipProps: {
        tooltipTitle: intl.formatMessage({id: isUserSelfEditing ? Tr.userCannotDeleteOwnersPermissions_Tooltip : Tr.removePermissionButtonTooltip_Content}),
      },
    },
  ], [data.Identifier, isUserSelfEditing, handleEditPermission, handleRemovePermission]);

  return (
    <>
      <ConfirmModal
        title={isSelfInvoicing ? intl.formatMessage({id: Tr.deleteSelfInvoicingActionTitle}) : intl.formatMessage({id: Tr.deletePermissionTitle})}
        content={
          <FormattedMessage
            id={isSelfInvoicing ? Tr.deleteSelfInvoicingActionContent : Tr.deletePermissionTableActionContent}
            values={{
              identifier: data.Identifier,
              br: <br/>,
            }}/>
        }
        test-id="singlePermissionsActionMenuConfirmModal"
        confirmButtonName={intl.formatMessage({id: Tr.continue})}
        cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
        open={openDeleteModal}
        onCancel={onCancelDeletePermission}
        onConfirm={
          deletePermission}
      />
      <ContextualMenu
        test-id="singlePermissionsActionMenu"
        isOpen={isSingleActionMenuVisible}
        actions={actions}
        anchorEl={singleActionAnchorEl ?? null}
        onClose={() => setIsSingleActionMenuVisible(false)}
      />
    </>
  );
});
