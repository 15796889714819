import {ErrorEventMapperI} from '../ErrorEventAdapter';
import {GraphQLErrorParsed} from '../GraphQLErrorEventAdapterManager';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';

export class GraphQLErrorEventDefaultMapper implements ErrorEventMapperI<GraphQLErrorParsed> {
  constructor(public type: WebsocketErrorType) {
  }

  public map(error: GraphQLErrorParsed) {
    return {
      errorType: this.type,
      errorContent: Object.entries(error.extensions.data ?? {}).map(([key, value]) => ({
        key: String(key),
        value: String(value),
      })),
    };
  }
}
