import {CategoryRulesFiltersDataDocument, CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables} from "@symfonia-ksef/graphql";
import {apolloClient} from "../../../../../../../../root/providers/GraphQLProvider";

export const GetCategoryRulesFilterDataQueryFunction = async (
  companyId: string,
  financialYearId: string,
  isActive: boolean) =>
  await apolloClient
    .query<CategoryRulesFiltersDataQuery, CategoryRulesFiltersDataQueryVariables>({
      query: CategoryRulesFiltersDataDocument,
      context: {
        envId: companyId,
      },
      variables: {
        IsActive: isActive,
        CompanyId: companyId,
        FinancialYearId: financialYearId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return {
        Categories: response.data.Categories?.items,
        Users: response.data.UsersAndPositions?.UsersList,
        Positions: response.data.UsersAndPositions?.Positions,
      };
    });

    export type CategoryRuleUserModel =
    {
      UserId?: any | undefined;
      UserFirstName: string;
      UserLastName: string;
    }

    export type CategoryRulePositionModel =
    {
      Id: any,
       PositionName: string
    }
