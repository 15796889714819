import {InvoiceBound} from '@symfonia-ksef/graphql';
import {GenericInvoicesPage} from '../GenericInvoicesPage';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IDocumentsState} from '../state/IDocumentsState';

export interface SalesPageProps {
  state: IDocumentsState;
}

export const SalesPage = ({state}: SalesPageProps) => {
  return <GenericInvoicesPage
    forceAccess
    invoicesBound={InvoiceBound.Internal}
    pageTitleKey={Tr.salesDocuments}
    state={state.invoicePageState}/>;
};
