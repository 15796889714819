import {ApolloError} from '@apollo/client';
import {Tr} from '../../../../locales/translationKeys';
import {FormattedMessage} from 'react-intl';

export const ApolloErrorMessages = ({error}: { error: ApolloError | undefined }) => {
  if (!error) {
    return null;
  }

  if (!error.graphQLErrors) {
    return <span>{error.message}</span>;
  }

  const validationMessages = error.graphQLErrors
    .filter(e => e.extensions?.code === 'validation')
    .map((e) => e.extensions?.data as Record<string, string[]>)
    .map((data) => {
      return Object.keys(data).reduce((prev: string[], key: string) => {
        return [...prev, ...data[key]];
      }, []);
    })
    .reduce((prev, next) => {
      return [...prev, ...next];
    }, []);

  if (!validationMessages.length) {
    return <FormattedMessage id={Tr.genericError}/>;
  }

  return (
    <>
      {validationMessages.map((message, index) => (
        <span key={index}>
          {message}
          {index < validationMessages.length - 1 && <br/>}
        </span>
      ))}
    </>
  );
};
