import {useIntl} from 'react-intl';

import {CopyToClipboardButton} from '../Forms';
import {Tr} from '../../../../locales/translationKeys';
import {IconColor, IconSvg, Input, InputSize, InputWidth, Tooltip, TooltipPosition} from '@symfonia/brandbook';
import {IconWithTooltip} from '@symfonia/symfonia-ksef-components';


export const CompanyKeyField = ({companyKey}: {companyKey: string}) => {
  const intl = useIntl();
  const inputSize = InputSize.SM;
  const inputWidth = InputWidth.FULL;

  const {companyKey: companyKeyTranslation, companyKeyTooltip_Content, companyKeyTooltip_Header} = Tr;

  return (
    <div className="flex items-center w-full">
      <Tooltip text={companyKey} position={'top'} tooltipClass="text-xs">
        <div className="flex-auto max-w-[280px]">
            <Input
              disabled
              label={intl.formatMessage({id: companyKeyTranslation})}
              size={inputSize}
              value={companyKey}
              width={inputWidth}
              testId="companyInfoCompanyKey"
              inputClassName="pointer-events-none"
            />
        </div>
      </Tooltip>
      <div className="flex h-[40px] self-end">
        <IconWithTooltip
          tooltipWidth="auto"
          tooltipClass={'w-[800px]'}
          icon={{icon: IconSvg.HELP_OUTLINE, color: IconColor.GREEN_500}}
          btnClass={'flex items-center ml-[10px]'}
          tooltipPosition={TooltipPosition.RIGHT}
          tooltipContent={<>
            <h1 className={'mb-[10px] font-bold'}>{intl.formatMessage({id: companyKeyTooltip_Header})}</h1>
            <p>{intl.formatMessage({id: companyKeyTooltip_Content}, {br: <br/>})}</p>
          </>
          }
        />
          <CopyToClipboardButton onClick={() => companyKey} id={companyKey}/>
      </div>
    </div>
  );
};