import {
  localStorageService,
  sessionStorageService,
  StorageServiceInterface,
  TEN_YEARS,
} from '../../../common/helpers/storage';
import {computed, makeObservable, observable, runInAction} from 'mobx';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {NonFunction} from '../../../common/types/NonFunction';
import {AnyObject} from 'yup/es/types';
import {EnvObserverI, EnvSubscriber} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';

export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage'
}

export interface BasePersistServiceI<T> extends BaseModuleI {
  get isLocalStorage(): boolean;

  get isSessionStorage(): boolean;

  get key(): string;

  save(data: ((currentData: T | undefined) => T) | NonFunction<T>): void;

  load(): T | undefined;

  subscribe(listener: (data: T) => void, opt?: { load: boolean, clear?: boolean }): this;
}

export class BasePersistService<T extends AnyObject | string | number | boolean | Array<any>> extends BaseModule implements BasePersistServiceI<T> {
  private storage!: StorageServiceInterface;

  @observable
  private envId?: string;

  @observable
  private tenantId?: string;

  private listeners: Array<(data: T) => void> = [];

  constructor(private readonly _key: string, private readonly envObserver: EnvObserverI, private readonly storageType: StorageType = StorageType.localStorage, private readonly ttl: number = TEN_YEARS) {
    super();
    this.storage = this.isLocalStorage ? localStorageService : sessionStorageService;
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
    this.envObserver.subscribeTenantId(this.tenantIdSubscriber, true);
    this.reactionsManager.add(() => this.key, () => this.runListeners());
    makeObservable(this);
  }

  public get isLocalStorage(): boolean {
    return this.storageType === StorageType.localStorage;
  }

  public get isSessionStorage(): boolean {
    return this.storageType === StorageType.sessionStorage;
  }

  @computed
  public get key(): string {
    return `${this._key}.${this.tenantId}.${this.envId}`;
  }

  public save(data: ((currentData: T | undefined) => T) | NonFunction<T>): void {
    this.storage.setItem<T>(this.key, typeof data === 'function' ? data(this.load()) : data, this.ttl);
  }

  public load(): T | undefined {
    return this.storage.getItem<T>(this.key);
  }

  public clearListeners(): this {
    this.listeners.length = 0;
    return this;
  }

  public subscribe(listener: (data: T) => void, opt?: { load: boolean, clear?: boolean }): this {
    opt?.clear && this.clearListeners();
    this.listeners.push(listener);
    if (opt?.load) {
      const data = this.load();
      data && listener(data);
    }
    return this;
  }

  protected _onMount(): Promise<void> | void {
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
    this.envObserver.subscribeTenantId(this.tenantIdSubscriber, true);
  }

  protected override _onUnmount() {
    this.envObserver.unsubscribeCompanyId(this.envIdSubscriber);
    this.envObserver.unsubscribeTenantId(this.tenantIdSubscriber);
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
    this.envObserver.subscribeTenantId(this.tenantIdSubscriber, true);
  }

  private readonly envIdSubscriber: EnvSubscriber = envId => envId !== null && runInAction(() => this.envId = envId);

  private readonly tenantIdSubscriber: EnvSubscriber = tenantId => tenantId !== null && runInAction(() => this.tenantId = tenantId);

  private runListeners(): void {
    const data = this.load();
    if (data !== undefined) {
      this.listeners.forEach(listener => listener(data));
    }
  }
}
