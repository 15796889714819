import {FeatureSwitchTypeEnum} from "../enums/FeatureSwitchTypeEnum";

export interface StateFeature<T> {
    name: FeatureSwitchTypeEnum | string;
    implementation: T ;
    mock?: () => T ;
}

/**
 * Simple function to check env if given feature is enabled.
 * Technical description in {@link https://dev.azure.com/symfoniapl/DRO/_wiki/wikis/DRO.wiki/1486/ADR-3.1.9-Prze%C5%82%C4%85czniki-funkcji-(feature-switch) ADR-3.1.9}
 * @param feature FeatureSwitchType enum value or string name of feature that should be checked
 * @returns True if feature exists and is enabled, false otherwise
 */
export const isFeatureEnabled = (feature: FeatureSwitchTypeEnum | string): boolean => {
    const featureEnabled = process.env.NX_ENABLED_FEATURES?.split(',').includes(feature) || feature === FeatureSwitchTypeEnum.default;

    if (process.env.NODE_ENV === 'development'
     && !featureEnabled 
     && !Object.values(FeatureSwitchTypeEnum).includes(<FeatureSwitchTypeEnum>feature)) {
        console.error(`There is no feature named "${feature}" in use. Check FeatureSwitches enum for available feature switches`);
    }
   
    return featureEnabled ?? false;
};

/**
 * Utility function to help with changing used implementation of class depending on enabled feature switches in env file.
 * Technical description in {@link https://dev.azure.com/symfoniapl/DRO/_wiki/wikis/DRO.wiki/1486/ADR-3.1.9-Prze%C5%82%C4%85czniki-funkcji-(feature-switch) ADR-3.1.9}
 * @param featureName FeatureSwitchType enum value or string name of feature that should be checked for activation
 * @param defaultFeature Default implementation to bo chosen when features is either disabled or no optional implementation of this features matches
 * @param optionalFeatures Arbitrary count of optional implementations or array of feature implementations to be checked if matches with `featureName`
 * @returns Implementation of matching feature
 */
export function stateFeatureSwitch<T>(featureName: FeatureSwitchTypeEnum | string, defaultFeature: StateFeature<T>, ...optionalFeatures: StateFeature<T>[]): T {
    const features = optionalFeatures.filter(x => x.name === featureName);

    if (features.length > 1) {
        throw new Error("Multiple implementations match feature");        
    }

    const feature = features[0];

    return isFeatureEnabled(featureName) && feature ? feature.implementation : defaultFeature.implementation;
}