import {observer} from 'mobx-react-lite';
import {getUpoResultService} from '../../../../state/rootRepository';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '../../../../locales/translationKeys';
import {ConfirmModal} from '@symfonia/symfonia-ksef-components';

export const GetUPOResultModal: FC = observer(() => {
  const {formatMessage} = useIntl();
  return <ConfirmModal
    content={formatMessage({id: Tr.getUPOResultContent})}
    title={formatMessage({id: Tr.getUPOResultTitle})}
    open={getUpoResultService.modalIsActive}
    onCancel={() => getUpoResultService.setModalIsActive(false)}
    onConfirm={() => getUpoResultService.fetch().finally(() => getUpoResultService.setModalIsActive(false))}
    confirmButtonName={formatMessage({id: Tr.yes})}
    cancelButtonName={formatMessage({id: Tr.cancelButton})}
  />;
});
