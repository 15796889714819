import {action, IObservableArray, makeObservable, observable} from 'mobx';
import {IPostingConfigurationState} from '../../IPostingConfigurationState';
import {ISettingsState} from './ISettingsState';
import {
  AcceptBeforeSendToFkScopeEnum,
  AcceptBeforeSendToFkSettingResponse,
  AutoPostingOfInvoicesSettingResponse,
  AutoPostingSettingChangeInput,
  GetAutoPostingSettingsDocument,
  GetAutoPostingSettingsQuery,
  GetAutoPostingSettingsQueryVariables,
  SaveAutoPostingSettingsDocument,
  SaveAutoPostingSettingsMutation,
  SaveAutoPostingSettingsMutationVariables,
  SettingTypeEnum,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../../../../../root/providers/GraphQLProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {addAlert} from '../../../../../../../../../services/helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';
import {envObserver} from '@symfonia-ksef/state/rootRepository';


export class SettingsState implements ISettingsState {

  @observable
  defaultAcceptBeforeSendToFKIsActive: boolean = false;

  @observable
  defaultAutoPostingOfInvoicesIsActive: boolean = false;

  @observable
  defaultAcceptBeforeSendToFKParams: AcceptBeforeSendToFkScopeEnum = AcceptBeforeSendToFkScopeEnum.AllDecrees;

  @observable
  acceptBeforeSentToFK: AcceptBeforeSendToFkSettingResponse | undefined = undefined;

  @observable
  autoPostingOfInvoices: AutoPostingOfInvoicesSettingResponse | undefined = undefined;

  @observable
  readonly autoPostingSettingChanges: IObservableArray<AutoPostingSettingChangeInput> = observable([]);

  @observable
  postingAutomation: boolean = false;

  @observable
  confirmDocsBeforeSendingToFK: boolean = false;

  @observable
  acceptDecrees: boolean = true;

  @observable
  public isModified = false;

  public postingConfigurationState: IPostingConfigurationState;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.postingConfigurationState = postingConfigurationState;
  }

  @action
  setAcceptBeforeSentToFKIsActive(acceptBeforeSentToFKIsActive: boolean) {
    if (this.acceptBeforeSentToFK) {
      this.acceptBeforeSentToFK.IsActive = acceptBeforeSentToFKIsActive;
      this.autoPostingSettingChangesUpdate(this.acceptBeforeSentToFK.SettingId, this.acceptBeforeSentToFK.SettingType, acceptBeforeSentToFKIsActive, this.acceptBeforeSentToFK.Params?.Scope);
    }
  }

  @action setPostingAutomation(automationOnOff: boolean) {
    this.postingAutomation = automationOnOff;
    this.isModified = true;
  }

  @action setConfirmDocsBeforeSendingToFK(confirm: boolean) {
    this.confirmDocsBeforeSendingToFK = confirm;
    this.isModified = true;
  }

  @action
  setAutoPostingOfInvoicesIsActive(autoPostingOfInvoicesIsActive: boolean) {
    if (this.autoPostingOfInvoices) {
      this.autoPostingOfInvoices.IsActive = autoPostingOfInvoicesIsActive;
      this.autoPostingSettingChangesUpdate(this.autoPostingOfInvoices.SettingId, this.autoPostingOfInvoices.SettingType, autoPostingOfInvoicesIsActive);
    }
  }


  @action
  setAcceptAllDecrees(accept: boolean) {
    this.acceptDecrees = accept;
    this.isModified = true;
  }

  @action
  setAcceptBeforeSentToFKParams(acceptBeforeSentToFKParams: string) {
    if (this.acceptBeforeSentToFK?.Params) {
      this.acceptBeforeSentToFK.Params.Scope = acceptBeforeSentToFKParams as AcceptBeforeSendToFkScopeEnum;
      this.autoPostingSettingChangesUpdate(this.acceptBeforeSentToFK.SettingId, this.acceptBeforeSentToFK.SettingType, this.acceptBeforeSentToFK.IsActive, acceptBeforeSentToFKParams);
    }
  }

  @action
  resetChanges() {
    this.autoPostingSettingChanges.clear();
    if (this.acceptBeforeSentToFK) this.setAcceptBeforeSentToFKIsActive(this.defaultAcceptBeforeSendToFKIsActive);
    if (this.autoPostingOfInvoices) this.setAutoPostingOfInvoicesIsActive(this.defaultAutoPostingOfInvoicesIsActive);
    this.setIsModified(false);
  }

  @action
  async saveSettings(): Promise<void> {
    await apolloClient.mutate<SaveAutoPostingSettingsMutation, SaveAutoPostingSettingsMutationVariables>({
      mutation: SaveAutoPostingSettingsDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        CompanyId: this.postingConfigurationState.companyId,
        UserId: envObserver.currentEnv.userId,
        AutoPostingSettingChanges: this.autoPostingSettingChanges,
      },
    }).then(async (result) => {
      if (result.data?.SaveAutoPostingSettings.IsDone) addAlert({
        id: Tr.saveSuccess,
        color: ToastVariant.SUCCESS,
        duration: 10000,
      });
      else addAlert({id: Tr.genericError, color: ToastVariant.ERROR, duration: 10000});
      await this.getAutopostingSettings();
      this.resetChanges();
    }).catch(err => addAlert({id: Tr.genericError, color: ToastVariant.ERROR, duration: 10000}));
  }

  @action
  async getAutopostingSettings(): Promise<void> {
    await apolloClient.query<GetAutoPostingSettingsQuery, GetAutoPostingSettingsQueryVariables>({
      query: GetAutoPostingSettingsDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        CompanyId: this.postingConfigurationState.companyId,
      },
    })
      .then(result => {
        // TODO: #301816 activate after going back to development of AP
        // if (result.data.GetAutoPostingSettings.AutoPostingSettings.AcceptBeforeSendToFK) {
        //   this.setAcceptBeforeSentToFK(result.data.GetAutoPostingSettings.AutoPostingSettings.AcceptBeforeSendToFK);
        // }
        if (result.data.GetAutoPostingSettings.AutoPostingSettings.AutoPostingOfInvoices) {
          this.setAutoPostingOfInvoices(result.data.GetAutoPostingSettings.AutoPostingSettings.AutoPostingOfInvoices);
        }
        this.setIsModified(false);
      });
  }

  @action
  private setDefaultAcceptBeforeSendToFKIsActive(defaultAcceptBeforeSendToFKIsActive: boolean) {
    this.defaultAcceptBeforeSendToFKIsActive = defaultAcceptBeforeSendToFKIsActive;
  }

  @action
  private setDefaultAutoPostingOfInvoicesIsActive(defaultAutoPostingOfInvoicesIsActive: boolean) {
    this.defaultAutoPostingOfInvoicesIsActive = defaultAutoPostingOfInvoicesIsActive;
  }

  @action
  private setDefaultAcceptBeforeSendToFKParams(defaultAcceptBeforeSendToFKParams: AcceptBeforeSendToFkScopeEnum) {
    this.defaultAcceptBeforeSendToFKParams = defaultAcceptBeforeSendToFKParams;
  }

  @action
  private setAcceptBeforeSentToFK(acceptBeforeSentToFK: AcceptBeforeSendToFkSettingResponse) {
    this.acceptBeforeSentToFK = acceptBeforeSentToFK;
    this.setDefaultAcceptBeforeSendToFKIsActive(acceptBeforeSentToFK.IsActive);
    this.setDefaultAcceptBeforeSendToFKParams(acceptBeforeSentToFK.Params?.Scope ?? AcceptBeforeSendToFkScopeEnum.AllDecrees);
  }

  @action
  private setAutoPostingOfInvoices(autoPostingOfInvoices: AutoPostingOfInvoicesSettingResponse) {
    this.autoPostingOfInvoices = autoPostingOfInvoices;
    this.setDefaultAutoPostingOfInvoicesIsActive(autoPostingOfInvoices.IsActive);
  }

  @action
  private setIsModified(isModified: boolean) {
    this.isModified = isModified;
  }

  @action
  private autoPostingSettingChangesUpdate(settingId: string, settingType: SettingTypeEnum, isActive: boolean, params: string | undefined = undefined) {
    if ((settingType === SettingTypeEnum.AcceptBeforeSendToFk && (isActive !== this.defaultAcceptBeforeSendToFKIsActive || params !== this.defaultAcceptBeforeSendToFKParams)) ||
      (settingType === SettingTypeEnum.AutoPostingOfInvoices && isActive !== this.defaultAutoPostingOfInvoicesIsActive)) {
      this.setIsModified(true);
      const positionIndex = this.autoPostingSettingChanges.findIndex(e => e.SettingId === settingId);
      if (positionIndex === -1) this.autoPostingSettingChanges.push({
        SettingId: settingId,
        IsActive: isActive,
        Params: this.GetParams(params),
      });
      else {
        this.autoPostingSettingChanges.splice(positionIndex, 1, {
          SettingId: settingId,
          IsActive: isActive,
          Params: this.GetParams(params),
        });
      }
    } else this.autoPostingSettingChanges.remove({
      SettingId: settingId,
      IsActive: isActive,
      Params: this.GetParams(params),
    });
  }

  private GetParams(params: string | undefined) {
    if (!params) return;
    const enumValue = params === AcceptBeforeSendToFkScopeEnum.AllDecrees as string ? 1 : 2;
    return '{"Scope": "' + enumValue + '"}';
  }
}

