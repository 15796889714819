import {InvoicePreviewInterface} from './InvoicePreviewRepository';
import {sessionStorageService} from '../../modules/common/helpers/storage';
import {InvoicePreviewState} from './InvoicePreviewModel';

export type ItemsKeys = Partial<Record<keyof Pick<InvoicePreviewInterface, 'state' | 'zoomRation' | 'currentInvoiceId' | 'enableInvoicePreview'>, boolean>>
type Keys = Record<keyof ItemsKeys, string>
type LoadCallback = (preview: InvoicePreviewInterface) => void
type LoadConfig = {
  onBefore?: LoadCallback,
  onAfter?: LoadCallback,
  onError?: LoadCallback,
  onStateEmpty?: LoadCallback,
  onZoomRatioEmpty?: LoadCallback,
  onCurrentInvoiceIdEmpty?: LoadCallback
}

export class InvoicePreviewPersistService implements InvoicePreviewPersistInterface {
  private readonly zoomRatioKey;
  private readonly currentInvoiceIdKey;
  private readonly isEnabledKey;
  private readonly keys: Keys;

  constructor(private key: string, private invoicePreview: InvoicePreviewInterface, private storage: Storage = sessionStorage) {
    this.zoomRatioKey = this.key + '.zoomRatio';
    this.currentInvoiceIdKey = this.key + '.currentInvoiceId';
    this.isEnabledKey = this.key + '.isEnabled';
    this.keys = {
      state: this.key,
      zoomRation: this.zoomRatioKey,
      currentInvoiceId: this.currentInvoiceIdKey,
      enableInvoicePreview: this.isEnabledKey,
    };
  }

  public save(items?: ItemsKeys): void {
    for (const key in items ?? this.keys) {
      sessionStorageService.setItem(this.createKeyWithSufix(this.keys[key as keyof Keys]), this.invoicePreview[key as keyof InvoicePreviewInterface]);
    }
  }

  public load(config?: LoadConfig): void {
    try {
      config?.onBefore?.(this.invoicePreview);
      const invoicePreviewState = sessionStorageService.getItem<InvoicePreviewState>(this.createKeyWithSufix(this.key));
      const zoomRatioState = sessionStorageService.getItem<number>(this.createKeyWithSufix(this.zoomRatioKey));
      const currentInvoiceIdState = sessionStorageService.getItem<string>(this.createKeyWithSufix(this.currentInvoiceIdKey));
      const inEnabledState = sessionStorageService.getItem<boolean>(this.createKeyWithSufix(this.isEnabledKey));
      invoicePreviewState ? this.invoicePreview.setState(invoicePreviewState, {disableSaveToStorage: true}) : config?.onStateEmpty?.(this.invoicePreview);
      currentInvoiceIdState ? this.invoicePreview.setCurrentInvoiceId(currentInvoiceIdState, {disableSaveToStorage: true}) : config?.onCurrentInvoiceIdEmpty?.(this.invoicePreview);
      zoomRatioState ? this.invoicePreview.setZoomRatio(zoomRatioState, {disableSaveToStorage: true}) : config?.onZoomRatioEmpty?.(this.invoicePreview);
      this.invoicePreview.setEnableInvoicePreview(inEnabledState ?? false, {disableSaveToStorage: true});
      config?.onAfter?.(this.invoicePreview);
    } catch (err) {
      console.error(err);
      config?.onError?.(this.invoicePreview);
    }
  }

  public remove(items?: ItemsKeys): void {
    for (const key in items ?? this.keys) {
      sessionStorageService.removeItem(this.createKeyWithSufix(this.keys[key as keyof Keys]));
    }
  }

  private createKeyWithSufix(key: string): string {
    return `${key}.${this.invoicePreview.tenantId}.${this.invoicePreview.envId}`;
  }
}

export interface InvoicePreviewPersistInterface {
  save(items?: ItemsKeys): void;

  load(config?: LoadConfig): void;

  remove(items?: ItemsKeys): void;
}
