import {action, IObservableArray, makeObservable, observable} from "mobx";
import {CategoryRulePositionModel, CategoryRuleUserModel,GetCategoryRulesFilterDataQueryFunction} from "../queries/GetCategoryRulesFilterDataQueryFunction";
import {ICategoryDetailsState} from "./ICategoryDetailsState";
import {ICategoryDetailsStore} from "./ICategoryDetailsStore";
import {CategoryViewModel} from "@symfonia-ksef/graphql";
import {CategoryRulesFiltersKeys} from "../../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions";

export class CategoryDetailsStore implements ICategoryDetailsStore {
  @observable
  public categories: IObservableArray<CategoryViewModel> = observable([]);

  @observable
  public users: IObservableArray<CategoryRuleUserModel> = observable([]);

  @observable
  public positions: IObservableArray<CategoryRulePositionModel> = observable([]);

  public categoryDetailsState: ICategoryDetailsState;

  constructor(categoryDetailsState: ICategoryDetailsState) {
    makeObservable(this);

    this.categoryDetailsState = categoryDetailsState;
  }

  @action
  async fetchCategoryRulesFilterData(): Promise<void> {
    return await GetCategoryRulesFilterDataQueryFunction(
      this.categoryDetailsState.postingConfigurationState.companyId,
      this.categoryDetailsState.postingConfigurationState.selectedFinancialYear!,
      this.categoryDetailsState.filterState.activeFilters.get(CategoryRulesFiltersKeys.IsActive)?.values[0] ?? true
    )
      .then(categoryRules => {
        if (categoryRules) {
          this.categories.replace(categoryRules.Categories as CategoryViewModel[]);
          this.users.replace(categoryRules.Users as CategoryRuleUserModel[]);
          this.positions.replace(categoryRules.Positions as CategoryRulePositionModel[]);
        }
      });
  }

}
