import {ISearchTreeViewState} from './state/ISearchTreeViewState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {IconSvg, Input, InputWidth} from '@symfonia/brandbook';
import {debounce} from 'lodash';

export interface SearchTreeViewProps {
  state: ISearchTreeViewState;
}

export const SearchInputForTree = observer(({state}: SearchTreeViewProps): JSX.Element => {
  const intl = useIntl();

  const debouncedSearchHandler = debounce((e: string) => state.setSearchTerm(e.toString()), 500);

  return (
    <Input
      autofocus
      onInput={debouncedSearchHandler}
      placeholder={intl.formatMessage({id: Tr.searchAccount})}
      rightIcon={IconSvg.SEARCH}
      width={InputWidth.FULL}
      value={state.searchTerm}
    />
  );
});
