import {MultiSelectModel} from '../../../../../common/components/MultiSelect/MultiSelect';
import {IconSvg, Input, InputWidth} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import React from 'react';
import {useIntl} from 'react-intl';

export interface VatRegistrySelectProps {
  vatRegistries: MultiSelectModel[] | [];
  selectedId: string | undefined;
  handleSearch: (e: string) => void;
  search: string;

  onSelect(event: React.SyntheticEvent, value: MultiSelectModel | null): void;
}

export const VatRegistrySelect = ({
                                    vatRegistries,
                                    selectedId,
                                    onSelect,
                                    handleSearch,
                                    search,
                                  }: VatRegistrySelectProps) => {
  const intl = useIntl();

  return <div className={'flex flex-col items-start max-h-[450px]'}>
    <Input
      autofocus
      onChange={e => handleSearch(e)}
      placeholder={intl.formatMessage({id: Tr.searchAccount})}
      rightIcon={IconSvg.SEARCH}
      width={InputWidth.FULL}
      value={search}
    />
    <div className={'flex flex-col w-full items-start overflow-y-scroll mt-[20px]'}>
      {vatRegistries.length > 0 &&
        vatRegistries.map(v => {
          return (
            <button
              key={v.key}
              onClick={(event) => onSelect(event, v)}
              className={'rounded-lg text-left w-full p-[10px] hover:bg-gray-100 transition-colors ' + (v.key === selectedId ? 'bg-[#009A00] text-white' : '')}
            >
              {v.value}
            </button>
          );
        })
      }
    </div>
  </div>;
};
