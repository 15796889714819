import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {NotificationEvent} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsEventsRepository';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';

type AuthorizeTypes = WebsocketNotificationType.AuthorizeInKsef | WebsocketNotificationType.AuthorizeInKsefExternalToken

export class AuthorizeEventConverter extends KSeFEventsConverter<AuthorizeTypes> {
  private readonly isAuthorizeByToken!: boolean;

  constructor(type: AuthorizeTypes, event: WebSocketNotificationFragment) {
    super(type, event);
    this.isAuthorizeByToken = type === WebsocketNotificationType.AuthorizeInKsefExternalToken;
  }

  public get notification(): ReactNode {
    if (this.isAuthorizeByToken) {
      return this.createNotification(this.model.hasError ? {id: Tr.authSubTokenError} : {id: Tr.authSubTokenSuccess});
    }
    return this.createNotification(this.model.hasError ? {id: Tr.authSubSignatureError} : {id: Tr.authSubSignatureSuccess});
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    return undefined;
  }

  public get model() {
    return {data: null, hasError: !!this.event.ErrorType};
  }

  public action(): void {
    return;
  }
}
