import {Icon, IconColor, IconSvg, TableHeaderProps, TableRowProps} from '@symfonia/brandbook';
import {Children, cloneElement, FC, memo, useEffect, useMemo, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {TableCell} from '../../../../../brandbook/src/components/Table/components/TableCell';

export type TableRowReflectionProps = TableRowProps & {
  header?: TableHeaderProps[];
  widths?: string[];
};

export const TableRowReflection: FC<TableRowReflectionProps> = memo(({
                                                                       children = [],
                                                                       className = undefined,
                                                                       columnClassName = undefined,
                                                                       wrapperClassName = undefined,
                                                                       dropdownContent = undefined,
                                                                       dropdownAtColumn = undefined,
                                                                       dropdownOpen = false,
                                                                       onClick = undefined,
                                                                       caption = false,
                                                                       header = [],
                                                                       widths = [],
                                                                     }) => {
  const [showDropdownContent, setShowDropdownContent] = useState<boolean>(dropdownOpen);

  useEffect(() => {
    setShowDropdownContent(!!dropdownOpen);
  }, [dropdownOpen]);

  const columns = useMemo(
    () => caption ? [] : Children.map(children, (child, index) => <TableCell
      className={clsx(columnClassName, widths[index], header[index]?.className)} key={child.key}>
      {dropdownAtColumn === index ? (
        <Icon
          color={showDropdownContent ? IconColor.PRIMARY_500 : IconColor.GREY_500}
          svg={showDropdownContent ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
        />
      ) : (
        cloneElement(child, {width: widths[index]})
      )}
    </TableCell>)
    ,
    [children, widths, showDropdownContent, header, columnClassName, dropdownAtColumn, caption],
  );


  if (caption === false && header?.length !== undefined && header?.length !== columns.length) {
    throw new Error('Symfonia: Ilość kolumn musi odpowiadać ilości headerów');
  }

  const renderColumns = () => (
    <>
      <div
        onClick={() => dropdownAtColumn !== undefined && setShowDropdownContent(!showDropdownContent)}
        className={twMerge(
          clsx(
            'flex w-full items-center h-[48px]',
            {
              'cursor-pointer': !!dropdownContent,
            },
            className,
          ),
        )}
      >
        {columns}
      </div>
      {dropdownContent && <div className={clsx({hidden: !showDropdownContent})}>{dropdownContent}</div>}
    </>
  );

  return (
    <div
      onClick={onClick}
      className={twMerge(
        clsx(
          {
            'flex flex-col': caption === false,
            'border-b-grey-100 border-b': caption === false && !className?.includes('border'),
          },
          wrapperClassName,
        ),
      )}
      data-testid="table-row"
    >
      {caption === false ? renderColumns() : children}
    </div>
  );
});
