import {State} from '../../modules/root/services/MobXServices/State';
import {computed, makeObservable} from 'mobx';
import {AuthError, PublicClientApplication} from '@azure/msal-browser';
import {authorityFactory} from '../../modules/auth/factories/AuthorityFactory';
import {AnyObject} from 'yup/es/types';
import {environment} from '../../environments/environment';
import {l} from '../../modules/logger/loggerInstance';

const authErrorCodes = {
  operationCancelled: 'AADB2C90091',
  userForgotPassword: 'AADB2C90118',
};


export type AuthStateT = {
  isAuthenticated: boolean;
  currentUserEmail: string | null;
  accessToken: string | null;
}


export class AuthState extends State<AuthStateT> {
  constructor(private authClient: PublicClientApplication) {
    super({
      isAuthenticated: false,
      currentUserEmail: null,
      accessToken: null,
    });

    makeObservable(this);
  }

  @computed
  public get isAuthenticated(): boolean {
    return this.state.isAuthenticated;
  }

  @computed
  public get currentUserEmail(): string | null {
    return this.state.currentUserEmail;
  }

  @computed
  public get accessToken(): string | null {
    return this.state.accessToken;
  }

  public async handleAuth(): Promise<boolean> {
    await this.authClient.initialize();
    await this.authClient.handleRedirectPromise();

    if (this.authClient.getAllAccounts().length === 0) {
      this.signIn();
      return false;
    }
    return await this.acquireToken();
  }

  public handleAuthProcessingError(err: unknown): void {
    if (err instanceof AuthError) {
      const userCancelledAuthOperation = err?.errorMessage.startsWith(authErrorCodes.operationCancelled);
      if (userCancelledAuthOperation) {
        return this.signIn();
      }

      const userForgotPassword = err?.errorMessage.startsWith(authErrorCodes.userForgotPassword);
      if (userForgotPassword) {
        return this.resetPassword();
      }
    }

    if (err instanceof Error && err?.name === 'InteractionRequiredAuthError') {
      return this.signIn();
    } else {
      l.error('Error when logging user', err);
    }
  }

  public signIn() {
    this.authClient.loginRedirect({
      authority: authorityFactory(environment.msalSignInPolicy),
      scopes: environment.msalScopes,
    }).catch(console.error);
  }

  public subscribeToTokenChange(callback: () => void | Promise<void>) {
    return this.reactionsManager.register(() => this.accessToken, async (currentToken, prevToken) => {
      if (currentToken && prevToken && currentToken !== prevToken) {
        await callback();
      }
    });
  }

  public resetPassword() {
    this.authClient.loginRedirect({
      authority: authorityFactory(environment.msalResetPasswordPolicy),
      scopes: environment.msalScopes,
    }).catch(console.error);
  }

  public signOut() {
    this.authClient.logoutRedirect({
      authority: authorityFactory(environment.msalSignInPolicy),
    }).catch(console.error);
  }

  public async acquireToken(): Promise<boolean> {
    try {
      this.authClient.setActiveAccount(this.authClient.getAllAccounts()[0]);
      const {idTokenClaims, accessToken} = await this.authClient.acquireTokenSilent({
        authority: authorityFactory(environment.msalSignInPolicy),
        scopes: environment.msalScopes,
      });
      this.set({
        isAuthenticated: true,
        accessToken,
        currentUserEmail: (idTokenClaims as AnyObject)['mainEmail'],
      });


      return true;
    } catch (err) {
      await this.authClient.acquireTokenRedirect({scopes: environment.msalScopes});

      return false;
    }
  }

}
