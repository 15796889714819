import {BaseEventConsumer} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {EventParams} from '../SubscriptionEventsService';

export class PostInvoicesFailedEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.PostInvoicesFailed, undefined, {
      success: {id: undefined},
      error: {id: Tr.postInvoicesFailedErrorMessage},
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event);
  }
}
