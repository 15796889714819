import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {ToastProps} from './Toast';
import {State} from '../../external/types';

export type ToastState = {
  toastList: ToastProps[];
  setToast: (toast: ToastProps) => void;
  clearToast: (toastId: ToastProps['id']) => void;
  clearAll: () => void;
};

const handler: State<ToastState> = (set, get) => ({
  toastList: [],
  setToast: toast => {
    set(
      draft => {
        draft.toastList = draft.toastList.filter(({id}) => id !== toast.id);
        draft.toastList.push(toast);
      },
      false,
      'Set Toast',
    );
  },
  clearToast: toastId => {
    set(
      draft => {
        draft.toastList = draft.toastList.filter(({id}) => id !== toastId);
      },
      false,
      'Clear Toast',
    );
  },
  clearAll: () => {
    set(
      draft => {
        draft.toastList = [];
      },
      false,
      'Clear All Toasts',
    );
  },
});

export const useToastState = create<ToastState>()(immer(handler));
