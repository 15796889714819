import {
  GetUserKSeFRequestForCredentialsDocument,
  GetUserKSeFRequestForCredentialsQuery,
  GetUserKSeFRequestForCredentialsQueryVariables,
} from '@symfonia-ksef/graphql';
import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
} from '../../../root/services/TableServices/DataSourceTableService';
import {makeObservable} from 'mobx';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export type PermissionsRegisterRepositoryI = BaseTableDataRepositoryServiceI<keyof GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>

export class PermissionsRegisterRepository extends BaseTableDataRepositoryService<'GetUserKSeFRequestForCredentials', GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables> implements PermissionsRegisterRepositoryI {
  constructor() {
    super('GetUserKSeFRequestForCredentials', GetUserKSeFRequestForCredentialsDocument, envObserver, earchiveState);
    this.configure({SearchText: '', RequestedCredentialFiltersFields: {}});
    makeObservable(this);
  }

  public override checkIsReady(): boolean {
    return super.checkIsReady() && typeof this.variables.skip === 'number' && this.variables.take;
  }
}
