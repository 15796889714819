import {createTheme} from '@mui/material';
import {grey} from '@mui/material/colors';
import '../assets/styles/global.css';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4D4F53',
      dark: '#4D4F53',
      light: '#4D4F53',
    },
  },
  components: {
    MuiFormLabel:{
      styleOverrides:{
        root:{
          opacity:1,
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '4.5px 7px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: grey['100'],
          border: `1px solid ${grey['400']}`,
          color: grey['900'],
          textTransform: 'none',
          '&.Mui-selected': {
            background: '#ffffff',
            color: grey['900'],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          top: 0,
          height: 3,
        },
        flexContainer: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
});
