import {useIntl} from 'react-intl';

import {observer} from 'mobx-react-lite';
import {useCategoryAccountsTableMethods} from './useCategoryAccountsTableMethods';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CategoryTypeEnum} from '@symfonia-ksef/graphql';
import {AppRoutes} from '../../../../../../../../root/Routes';
import {useNavigate} from 'react-router-dom';
import {ChangeCategoryNameModal} from './modals/ChangeCategoryNameModal';
import {DoubleNameErrorModal} from './modals/DoubleNameErrorModal';
import {DeleteCategoryWithCategoryRulesModal} from './modals/DeleteCategoryWithCategoryRulesModal';
import {DeleteCategoryRulesModal} from './modals/DeleteCategoryModal';
import {FC} from 'react';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {IconSvg, ToastVariant} from '@symfonia/brandbook';
import {CategoryAccountService} from './CategoryAccount.service';
import {ContextualMenu} from '../../../../../../../../common/components/ContextualMenu/ContextualMenu';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

interface CategoryAccountsTableMenuProps {
  service: CategoryAccountService;
  state: ICategoryAccountState;
}

export const CategoryAccountsTableMenu: FC<CategoryAccountsTableMenuProps> = observer(({service, state}) => {
  const intl = useIntl();
  const addAlert = earchiveState.alertsState.addAlert;
  const ownCategoryType = state.selectedCategory?.Type === CategoryTypeEnum.Custom;
  const {closeCategoryActionMenu, deleteCategoryAction} = useCategoryAccountsTableMethods(state);
  const navigate = useNavigate();

  const {changeCategoryNameAction} = useCategoryAccountsTableMethods(state);

  const handleOpenDeleteCategory = async () => {
    if (!state.selectedCategory?.Id) {
      addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR);
      return;
    }
    closeCategoryActionMenu();
    if (state.categoryRulesAssignedToSelectedCategory.length === 0) state.setOpenDeleteCategoryDialog(true);
    else state.setOpenDeleteCategoryWithCategoryRulesDialog(true);
  };

  const handleDeleteCategory = async () => {
    if (!state.selectedCategory?.Id) {
      addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR);
      return;
    }

    const currentPage = service.tableService.currentPage;
    const isLastPage = service.tableService.pagesCapacity === currentPage;
    const isLastElement = service.tableService.matrix.length === 1;

    await deleteCategoryAction(state.selectedCategory.Id, state.selectedCategory.Name);
    if (isLastPage && isLastElement && currentPage !== 0) {
      await service.tableService.setPagination({page: currentPage - 1});
    }
    await service.repository.fetch();
    state.setOpenDeleteCategoryDialog(false);
    state.setOpenDeleteCategoryWithCategoryRulesDialog(false);
  };

  const handleRedirection = () => {
    navigate('.' + AppRoutes.categoryDetails.address);
    closeCategoryActionMenu();
  };
  const handleOpenChangeCategoryName = () => {
    closeCategoryActionMenu();
    state.setOpenChangeCategoryDialog(true);
  };
  const handleCloseDialogs = () => {
    state.setOpenChangeCategoryDialog(false);
    state.setChangeDialogDoubleNameError(false);
  };

  const handleChangeName = async (name: string) => {
    await changeCategoryNameAction(state.selectedCategory!.Name, name, state.selectedCategory!.Id);
    await service.repository.fetch();
  };

  const handleRename = () => {
    state.setOpenChangeCategoryDialog(true);
    state.setChangeDialogDoubleNameError(false);
  };

  const actions = [
    {
      disabled: !state.postingConfigurationState.isFinancialYearActive,
      icon: IconSvg.EDIT,
      key: Tr.editCategory,
      label: intl.formatMessage({id: Tr.editCategory}),
      onClick: () => handleOpenChangeCategoryName(),
    },
    {
      icon: IconSvg.VISIBILITY,
      key: Tr.openCategory,
      label: intl.formatMessage({id: Tr.openCategory}),
      onClick: () => handleRedirection(),
    },
    {
      disabled: !state.postingConfigurationState.isFinancialYearActive,
      hidden: !ownCategoryType,
      icon: IconSvg.DELETE,
      key: Tr.deleteCategory,
      label: intl.formatMessage({id: Tr.deleteCategory}),
      onClick: () => handleOpenDeleteCategory(),
    },
  ];

  return (
    <>
      {state.selectedCategory && state.changeDialogDoubleNameError && (
        <DoubleNameErrorModal
          open={state.changeDialogDoubleNameError}
          onChange={handleRename}
          onCancel={handleCloseDialogs}
        />
      )}

      {state.selectedCategory && state.openChangeCategoryDialog && (
        <ChangeCategoryNameModal
          existingName={state.selectedCategory.Name}
          open={state.openChangeCategoryDialog}
          onOk={handleChangeName}
          onCancel={() => state.setOpenChangeCategoryDialog(false)}
        />
      )}

      {state.selectedCategory && (
        <DeleteCategoryRulesModal
          categoryName={state.selectedCategory.Name}
          open={state.openDeleteCategoryDialog}
          onDelete={handleDeleteCategory}
          onCancel={() => state.setOpenDeleteCategoryDialog(false)}
        />
      )}

      {state.selectedCategory && (
        <DeleteCategoryWithCategoryRulesModal
          categoryId={state.selectedCategory.Id}
          categoryName={state.selectedCategory.Name}
          open={state.openDeleteCategoryWithCategoryRulesDialog}
          onDelete={handleDeleteCategory}
          onCancel={() => state.setOpenDeleteCategoryWithCategoryRulesDialog(false)}
          state={state}
        />
      )}

      <ContextualMenu
        onClose={closeCategoryActionMenu}
        isOpen={state.selectedInvoiceMenuOpened}
        anchorEl={state.selectedInvoiceMenuAnchorEl ?? null}
        actions={actions}
      />
    </>
  );
});
