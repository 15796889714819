import {clsx} from 'clsx';
import {useRef} from 'react';
import {twMerge} from 'tailwind-merge';

export const useClsx: typeof clsx = (...props) => {
  const stringClassName = clsx(...props);
  const refClassName = useRef('');
  const className = useRef('');

  if (refClassName.current !== stringClassName) {
    refClassName.current = stringClassName;
    className.current = twMerge(stringClassName);
  }

  return className.current;
};
