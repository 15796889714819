import {
  ArchiveCategoryMutation,
  ArchiveCategoryMutationVariables,
  CategoryViewModel,
  CategoryViewModelFilterInput,
  ChangeCategoryNameMutation,
  ChangeCategoryNameMutationVariables,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  GetCategoriesAccountsDocument,
  GetCategoriesAccountsQuery,
  GetCategoryRulesQuery,
  GetCategoryRulesQueryVariables,
  InvoiceTypeGroupEnum,
  useArchiveCategoryMutation,
  useChangeCategoryNameMutation,
  useCreateCategoryMutation,
  useGetCategoryRulesLazyQuery,
} from '@symfonia-ksef/graphql';

import {l} from '../../../../../../../../logger/loggerInstance';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CategoryAccountModel} from './models/CategoryAccountModel';
import {apolloClient} from '../../../../../../../../root/providers/GraphQLProvider';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {BuildFilters} from '../../../../../../../../common/filters/FilterOptionExtensions';
import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions,
  MutationFunctionOptions,
  MutationResult,
  QueryResult,
} from '@apollo/client';
import {FetchResult} from '@apollo/client/link/core';
import {CategoriesAccountsFiltersKeys} from './models/CategoryTypesFilterOptions';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {
  MuiGridLoadMethod,
  MUIGridPaginatedData,
  MUIGridWrapperListQueryParams,
} from '../../CategoryDetails/CategoryDetailsTable/useCategoryDetailsTableMethods';
import {intl} from '../../../../../../../../root/IntlProvider';

export const useCategoryAccountsTableMethods = (
  state: ICategoryAccountState,
): {
  closeCategoryActionMenu: () => void;
  openCategoryActionMenu: (category: CategoryAccountModel, anchorRef?: HTMLElement) => void;
  deleteCategoryAction: (categoryId: string, categoryName: string) => Promise<void>;
  changeCategoryNameAction: (oldName: string, name: string, categoryId: string) => Promise<void>;
  getCategoriesAccounts: (
    options: MUIGridWrapperListQueryParams,
    envId?: string | undefined,
    filters?: Record<string, any> | undefined,
  ) => Promise<MUIGridPaginatedData<any>>;
  addNewCategory: (name: string, group: InvoiceTypeGroupEnum) => Promise<void>;
  getCategoryRulesForCategory: (categoryName: string) => Promise<void>;
  deleteCategoryAndMoveCategoryRulesAction: (
    categoryId: string,
    nextCategoryId: string,
    categoryName: string,
  ) => Promise<void>;
} => {
  const {
    company: {companyId},
  } = earchiveState;
  const [addCategory]: [
    (
      options?: MutationFunctionOptions<
        CreateCategoryMutation,
        CreateCategoryMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<CreateCategoryMutation>>,
    MutationResult<CreateCategoryMutation>,
  ] = useCreateCategoryMutation({context: {envId: companyId}});
  const [getCategoryRules]: [
    (
      options?: Partial<LazyQueryHookOptions<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>>,
    ) => Promise<QueryResult<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>>,
    QueryResult<GetCategoryRulesQuery, GetCategoryRulesQueryVariables>,
  ] = useGetCategoryRulesLazyQuery({context: {envId: companyId}});
  const [changeCategoryName]: [
    (
      options?: MutationFunctionOptions<
        ChangeCategoryNameMutation,
        ChangeCategoryNameMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<ChangeCategoryNameMutation>>,
    MutationResult<ChangeCategoryNameMutation>,
  ] = useChangeCategoryNameMutation({context: {envId: companyId}});
  const [deleteCategory]: [
    (
      options?: MutationFunctionOptions<
        ArchiveCategoryMutation,
        ArchiveCategoryMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<ArchiveCategoryMutation>>,
    MutationResult<ArchiveCategoryMutation>,
  ] = useArchiveCategoryMutation({context: {envId: companyId}});

  const getCategoriesAccounts: MuiGridLoadMethod = async (
    options: MUIGridWrapperListQueryParams,
    envId?: string | undefined,
    activeFilters?: Record<string, any> | undefined,
  ): Promise<MUIGridPaginatedData<CategoryViewModel>> => {
    if (!options) {
      return {
        data: [],
        totalCount: 0,
      };
    }
    if (activeFilters) {
      const response = await apolloClient.query<GetCategoriesAccountsQuery>({
        query: GetCategoriesAccountsDocument,
        context: {envId},
        variables: {
          Skip: options.skip,
          SearchText: options.searchValue ?? '',
          Order: {[options.sortFieldName]: options.sortDirection},
          CompanyId: envId,
          FinancialYearId: state.postingConfigurationState.selectedFinancialYear,
          FilterFields: activeFilters ? (BuildFilters(activeFilters) as CategoryViewModelFilterInput) : undefined,
        },
      });
      if (response.error) {
        l.error('Error when loading posting invoices table', undefined, response.errors);
        earchiveState.alertsState.addAlert(
          intl.formatMessage({id: Tr.postingInvoicesActionError}),
          ToastVariant.ERROR,
          {displayDuration: 10000, omitIfHasTheSameAlert: true},
        );
        // TODO: dodać loader globalny przy wrzucaniu odpowiedniego query
        // setShowLoader(false);
      }

      state.tableState.setData(response.data!.GetCategoriesViewModel!.items! as CategoryViewModel[]);
      if (Object.keys(activeFilters).length === 2)
        state.tableState.setDataWithoutFilters(response.data!.GetCategoriesViewModel!.items! as CategoryViewModel[]);

      return {
        data: response.data!.GetCategoriesViewModel!.items! as CategoryViewModel[],
        totalCount: response.data!.GetCategoriesViewModel!.totalCount!,
      };
    }
    return {
      data: [],
      totalCount: 0,
    };
  };

  const changeCategoryNameAction = (oldName: string, name: string, categoryId: string): Promise<void> =>
    changeCategoryName({
      variables: {
        Name: name,
        CategoryId: categoryId,
        CompanyId: companyId,
      },
    })
      .then(res => {
        if (!res?.data?.ChangeCategoryName?.IsDone) {
          state.setChangeDialogDoubleNameError(true);
          return;
        } else {
          earchiveState.alertsState.addAlert(
            intl.formatMessage(
              {id: Tr.categoryNameChanged},
              {oldName: <span>{oldName}</span>, name: <span>{name}</span>},
            ),
            ToastVariant.SUCCESS,
            {displayDuration: 10000, omitIfHasTheSameAlert: true},
          );

          state.tableState.triggerTableRefresh();
          state.filterState.getFilters();
          state.setOpenChangeCategoryDialog(false);
        }
      })
      .catch((error): any =>
        earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.categoryNameChangedFailed}), ToastVariant.ERROR, {
          displayDuration: 10000,
          omitIfHasTheSameAlert: true,
        }),
      );

  const addNewCategory = (name: string, group: InvoiceTypeGroupEnum): Promise<void> =>
    addCategory({
      variables: {
        Name: name,
        CompanyId: companyId,
        InvoiceTypeGroup: group,
      },
    })
      .then(res => {
        if (!res?.data?.CreateCategory?.IsDone) {
          state.setAddDialogDoubleNameError(true);
          return;
        } else {
          earchiveState.alertsState.addAlert(
            intl.formatMessage({id: Tr.categoryAdded}, {categoryName: <span>{name}</span>}),
            ToastVariant.SUCCESS,
            {
              displayDuration: 10000,
              omitIfHasTheSameAlert: true,
            },
          );
          state.tableState.triggerTableRefresh();
          state.filterState.getFilters();
          state.setOpenAddCategoryDialog(false);
        }
      })
      .catch((error): any =>
        earchiveState.alertsState.addAlert(
          intl.formatMessage({id: Tr.postingInvoicesActionError}),
          ToastVariant.ERROR,
          {displayDuration: 10000, omitIfHasTheSameAlert: true},
        ),
      );

  const getCategoryRulesForCategory = (categoryName: string): Promise<void> =>
    getCategoryRules({
      variables: {
        CompanyId: companyId,
        FinancialYearId: state.postingConfigurationState.selectedFinancialYear,
        Skip: 0,
        Take: 0,
        Filters: {
          CategoryNames: [categoryName],
          IsActive: true,
        },
      },
    })
      .then(res => {
        if (res?.data?.GetCategoryRules?.Items) {
          state.setCategoryRulesAssignedToSelectedCategory(res?.data?.GetCategoryRules?.Items.map(x => x.PositionName));
        }
      })
      .catch(() => state.setCategoryRulesAssignedToSelectedCategory([]));

  const deleteCategoryAction = (categoryId: string, categoryName: string): Promise<void> =>
    deleteCategory({
      variables: {
        CategoryId: categoryId,
        ShouldMoveCategoryRules: false,
      },
    })
      .then(res => {
        if (!res?.data?.ArchiveCategory.IsDone) {
          earchiveState.alertsState.addAlert(
            intl.formatMessage({id: Tr.categoryDeletedFailed}, {categoryName: <span>{categoryName}</span>}),
            ToastVariant.ERROR,
            {displayDuration: 10000, omitIfHasTheSameAlert: true},
          );
        } else {
          earchiveState.alertsState.addAlert(
            intl.formatMessage({id: Tr.categoryDeleted}, {categoryName: <span>{categoryName}</span>}),
            ToastVariant.SUCCESS,
            {displayDuration: 10000, omitIfHasTheSameAlert: true},
          );
        }
      })
      .then(() => {
        state.filterState.clearSingleFilter(CategoriesAccountsFiltersKeys.Id, categoryId, categoryName);
        state.filterState.storage.saveToStorage(state.filterState.activeFilters);
        state.tableState.triggerTableRefresh();
        state.filterState.getFilters();
      })
      .catch((error): any =>
        earchiveState.alertsState.addAlert(
          intl.formatMessage({id: Tr.categoryDeletedFailed}, {categoryName: <span>{categoryName}</span>}),
          ToastVariant.SUCCESS,
          {displayDuration: 10000, omitIfHasTheSameAlert: true},
        ),
      );

  const deleteCategoryAndMoveCategoryRulesAction = (
    categoryId: string,
    nextCategoryId: string,
    categoryName: string,
  ): Promise<void> =>
    deleteCategory({
      variables: {
        CategoryId: categoryId,
        NextCategoryId: nextCategoryId,
        ShouldMoveCategoryRules: true,
      },
    })
      .then(res => {
        if (!res?.data?.ArchiveCategory.IsDone) {
          earchiveState.alertsState.addAlert(
            intl.formatMessage({id: Tr.categoryDeletedFailed}, {categoryName: <span>{categoryName}</span>}),
            ToastVariant.ERROR,
            {displayDuration: 10000, omitIfHasTheSameAlert: true},
          );
        } else {
          earchiveState.alertsState.addAlert(
            intl.formatMessage({id: Tr.categoryDeleted}, {categoryName: <span>{categoryName}</span>}),
            ToastVariant.SUCCESS,
            {displayDuration: 10000, omitIfHasTheSameAlert: true},
          );
        }
      })
      .then(() => {
        state.filterState.clearSingleFilter(CategoriesAccountsFiltersKeys.Id, categoryId, categoryName);
        state.filterState.storage.saveToStorage(state.filterState.activeFilters);
        state.tableState.triggerTableRefresh();
        state.filterState.getFilters();
      })
      .catch((error): any =>
        earchiveState.alertsState.addAlert(
          intl.formatMessage({id: Tr.categoryDeletedFailed}, {categoryName: <span>{categoryName}</span>}),
          ToastVariant.SUCCESS,
          {displayDuration: 10000, omitIfHasTheSameAlert: true},
        ),
      );

  const openCategoryActionMenu = (category: CategoryAccountModel, anchorRef?: HTMLElement) => {
    state.setSelectedCategory(category);
    state.setCategoriesToMoveCategoryRules(category.Id);
    state.setSelectedInvoiceMenuAnchorEl(anchorRef);
    state.setSelectedInvoiceMenuOpened(true);
  };

  const closeCategoryActionMenu = () => {
    state.setSelectedInvoiceMenuAnchorEl(undefined);
    state.setSelectedInvoiceMenuOpened(false);
  };

  return {
    getCategoriesAccounts,
    openCategoryActionMenu,
    closeCategoryActionMenu,
    addNewCategory,
    changeCategoryNameAction,
    deleteCategoryAction,
    deleteCategoryAndMoveCategoryRulesAction,
    getCategoryRulesForCategory,
  };
};
