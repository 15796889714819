import {EventJobRunner} from '../EventJobRunner';
import {
  RemoveAuthorizationWithExternalTokenInKSeFDocument,
  RemoveAuthorizationWithExternalTokenInKSeFMutation,
  RemoveAuthorizationWithExternalTokenInKSeFMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../modules/root/IntlProvider';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {AppRoutes} from '../../modules/root/Routes';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {AuthorizeInKSeFWithTokenJobRunnerI} from './AuthorizeInKSeFWithTokenJobRunner';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class RemoveAuthorizationInKSeFWithTokenJobRunner extends EventJobRunner<RemoveAuthorizationWithExternalTokenInKSeFMutationVariables, keyof RemoveAuthorizationWithExternalTokenInKSeFMutation> implements AuthorizeInKSeFWithTokenJobRunnerI {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('RemoveAuthorizationWithExternalTokenInKSeF', WebsocketNotificationType.UnauthorizedInKsefExternalToken, RemoveAuthorizationWithExternalTokenInKSeFDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => {
          const {companyId, tenantId} = this.envObserver.currentEnv;
          return {
            notification: intl.formatMessage({id: Tr.unAuthSubPending}, {
              link: React.createElement(NavLink, {
                children: intl.formatMessage({id: Tr.authentication}),
                to: AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + '/account/authentication',
              }),
            }),
          };

        },
        error: (errors, errorMessage) => ({
          id: Tr.unAuthSubError,
        }),
      },
    );
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }
}
