import {FC} from 'react';
import {Grid, LinearProgress, Typography} from '@mui/material';
import {useLinearLimitCalculations} from './linearPackageLimitHooks';
import {LinearPackageLimitProps} from './LinearPackageLimit.type';
import {styled} from '@mui/material/styles';

const StyledLinearProgress = styled(LinearProgress)(({}) => ({
  '.MuiLinearProgress-barColorSuccess': {
    backgroundColor: 'var(--linearProgressSuccess)',
  },
  '.MuiLinearProgress-barColorWarning': {
    backgroundColor: 'var(--linearProgressWarning)',
  },
  '.MuiLinearProgress-barColorError': {
    backgroundColor: 'var(--linearProgressError)',
  },
}));

const LinearPackageLimit: FC<LinearPackageLimitProps> = ({
                                                           label,
                                                           thresholds = [0, 90, 100],
                                                           loading,
                                                           ...restProps
                                                         }) => {
  const {normalizedUsage, message, barColor, textColor, variant} = useLinearLimitCalculations({
    ...restProps,
    loading,
    thresholds,
  });

  return <Grid container>
    {label && <Grid item xs={12}>
      <Typography variant="caption" sx={{color: textColor}}>{!loading && label}</Typography>
    </Grid>}
    <Grid item xs={12}>
      <StyledLinearProgress
        sx={{height: 6}}
        variant={variant}
        color={barColor}
        value={normalizedUsage}/>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="caption" sx={{color: textColor}}>{!loading && message}</Typography>
    </Grid>
  </Grid>;
};

export {LinearPackageLimit};
