import {useState} from 'react';
import {DisconnectWithKSeFButton} from '../../../components';
import {DisconnectWithKSeFTokenModal} from '../../../modals';
import {authorizationInKSeFWithTokenService, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';

export const DisconnectWithKSeFToken = observer(() => {
  const {company: {companyId}} = earchiveState;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleButtonClick = () => setIsModalOpen(true);
  const handleCancelModal = () => setIsModalOpen(false);

  const handleConfirmModal = async () => {
    companyId && await authorizationInKSeFWithTokenService.removeAuthorizationRunner.fetch();
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen &&
        <DisconnectWithKSeFTokenModal open={isModalOpen} onCancel={handleCancelModal} onConfirm={handleConfirmModal}/>}
      <DisconnectWithKSeFButton handleButtonClick={handleButtonClick}/>
    </>
  );
});
