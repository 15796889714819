import {FC} from 'react';
import {InvoicePreviewInterface} from '@symfonia-ksef/state/InvoicePreviewState/InvoicePreviewRepository';
import {observer} from 'mobx-react-lite';
import {Text} from '../../../root/components/Text';

export const ZoomTooltip: FC<{ title?: string, previewRepository: InvoicePreviewInterface }> = observer(({
                                                                                                           title,
                                                                                                           previewRepository,
                                                                                                         }) => {
  const {zoomRation, shouldShowZoomRatio} = previewRepository;

  return <Text>{shouldShowZoomRatio ? zoomRation + '%' : title}</Text>;
});
