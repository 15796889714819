import {pl} from '../../../../../locales';

export const ACCOUNT_MANAGEMENT_CONSTS = {
  TENANT_METADATA: {
    nameLabel: pl.tenantName,
    titleLabel: 'Dane organizacji',
  },
  COMPANY_METADATA: {
    nameLabel: pl.companyName,
    keyLabel: pl.companyKey,
    titleLabel: 'Dane firmy',
    keyTooltipContent: pl.companyKeyTooltip_Content,
    keyTooltipHeader: pl.companyKeyTooltip_Header,
  },
  SUBSCRIPTION_METADATA: {
    titleLabel: pl.subscriptionFormTitle,
    subcriptionNumber: pl.subcriptionNumber,
    serialNumber: pl.serialNumber,
  }
} as const;
