import {observer} from 'mobx-react-lite';
import {downloadInvoicesProgressTracker} from '@symfonia-ksef/state/rootRepository';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {LoadingButton, LoadingButtonSize, LoadingButtonWidth, Text} from '@symfonia/symfonia-ksef-components';
import {Tooltip} from '@symfonia/brandbook';

export const CancelInvoiceDownloadButton = observer(() => {
  if (!downloadInvoicesProgressTracker.isActive) {
    return null;
  }

  return <div className="flex items-center flex-wrap mb-[8px] justify-end">
    <Text small block className="text-green-600 ml-[8px]">
      {downloadInvoicesProgressTracker.userNotification}
    </Text>
    <Tooltip
      text={intl.formatMessage({id: Tr.cancelDownloadInvoices})}
    >
      <div>
        <LoadingButton
          className="ml-[8px]"
          width={LoadingButtonWidth.BASE}
          text={intl.formatMessage({id: Tr.cancelDownloadInvoices})}
          size={LoadingButtonSize.SM}
          loading={downloadInvoicesProgressTracker.cancellationInProgress}
          onClick={() => downloadInvoicesProgressTracker.cancelDownload()}
        />
      </div>
    </Tooltip>
  </div>;
});
