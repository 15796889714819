import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {Tr} from '../../../../../../../../../../locales/translationKeys';
import {FC, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCategoryAccountsTableMethods} from '../useCategoryAccountsTableMethods';
import {MultiSelectModel} from '../../../../../../../../../common/components/MultiSelect/MultiSelect';
import {ICategoryAccountState} from '../state/ICategoryAccountState';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

interface MoveCategoryRulesModalProps {
  categoryId: string;
  categoryName: string;
  open: boolean;
  onCancel: () => void;
  state: ICategoryAccountState;
}

export const MoveCategoryRulesModal: FC<MoveCategoryRulesModalProps> = ({
                                                                          categoryId,
                                                                          categoryName,
                                                                          // categoryRules,
                                                                          open,
                                                                          onCancel,
                                                                          state,
                                                                        }) => {
  const intl = useIntl();
  const addAlert = earchiveState.alertsState.addAlert;
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const {deleteCategoryAndMoveCategoryRulesAction, getCategoryRulesForCategory} = useCategoryAccountsTableMethods(state);


  useEffect(() => {
    if (state.categoriesToMoveCategoryRules.length !== 0) setSelectedCategory(state.categoriesToMoveCategoryRules[0].key);
    state.selectedCategory?.Name !== undefined && getCategoryRulesForCategory(state.selectedCategory.Name);

  }, [state.categoriesToMoveCategoryRules]);

  const handleCancel = () => {
    onCancel();
  };

  const handleMoveCategoryRulesAndRemoveCategory = () => {
    if (!selectedCategory) {
      addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR);
      return;
    }
    deleteCategoryAndMoveCategoryRulesAction(categoryId, selectedCategory, categoryName);
    onCancel();
  };

  const setSelectedCategoryOnChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  return (
    <>
      {open && (
        <Dialog onClose={() => onCancel()} open={open} fullWidth={true} maxWidth={'xs'}>
          <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', textTransform: 'uppercase'}}>
            <FormattedMessage id={Tr.moveCategoryRules}/>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign: 'center', color: 'black'}}>
              <Grid>
                <Grid>
                  <Typography sx={{pt: 1}}>
                    <FormattedMessage
                      id={Tr.chooseNewCategoryDescription}
                      values={{categoryName: <span>{categoryName}</span>}}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container width={'100%'} rowGap={1} sx={{pt: 3}}>
                <Grid item xs={4}>
                  <Typography sx={{textAlign: 'left', pt: 1.2}}>
                    <FormattedMessage id={Tr.moveToCategory}/>
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Select
                    fullWidth={true}
                    value={selectedCategory}
                    size={'small'}
                    labelId="category_select_label"
                    id="category_select"
                    onChange={setSelectedCategoryOnChange}
                    MenuProps={MenuProps}
                  >
                    {state.categoriesToMoveCategoryRules.map((category: MultiSelectModel) => {
                      return (
                        <MenuItem key={category.key} value={category.key}>
                          {category.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{pt: 1}}>
                  <Typography sx={{textAlign: 'left'}}>
                    <FormattedMessage id={Tr.categoryRulesList}/>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MenuList sx={{textAlign: 'left', maxHeight: '17vh', overflow: 'auto'}}>
                    {state.categoryRulesAssignedToSelectedCategory.map((x: string) => [<ListItemText sx={{paddingBottom: 1}}>{x}</ListItemText>])}
                  </MenuList>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{pt: 3}}>
                <Button
                  className="btn"
                  sx={{width: '60%'}}
                  onClick={handleMoveCategoryRulesAndRemoveCategory}
                  variant="contained"
                  autoFocus
                >
                  <FormattedMessage id={Tr.moveCategoryRulesAndRemoveCategory}/>
                </Button>
                <Button className="btn" onClick={handleCancel} variant="outlined" autoFocus>
                  <FormattedMessage id={Tr.cancelButton}/>
                </Button>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
