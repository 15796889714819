import {observer} from 'mobx-react-lite';
import {PostingDetailsBodyList} from '../PostingDetailsList/PostingDetailsBodyList';
import {PostingDetailsBodyInvPreview} from './PostingDetailsBodyInvPreview';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {useWindowSize} from 'usehooks-ts';
import clsx from 'clsx';

export interface PostingDetailsBodyProps {
  state: IPostingDetailsState;
  showDetails: boolean;
}

export const PostingDetailsBody = observer(({state, showDetails}: PostingDetailsBodyProps) => {
  const {height} = useWindowSize();

  return (
    <div
      className={clsx('h-full w-full lg:flex-row lg:overflow-clip flex gap-4 flex-col-reverse', {
        'lg:flex-col-reverse': height < 780 && showDetails,
      })}
    >
      <div className="overflow-clip basis-full flex flex-col">
        <PostingDetailsBodyList state={state}/>
      </div>
      {state.invoicePreview && (
        <div className="basis-full">
          <PostingDetailsBodyInvPreview state={state}/>
        </div>
      )}
    </div>
  );
});
