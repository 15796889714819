import {FC, ReactNode, useState} from 'react';
import clsx from 'clsx';
import {Icon, IconSvg} from '../Icon/Icon';
import {EmptyState} from '../EmptyState/EmptyState';
import {ButtonPrimary, ButtonPrimaryProps, ButtonPrimaryWidth} from '../Button/ButtonPrimary';
import {ButtonSecondary, ButtonSecondaryProps, ButtonSecondaryWidth} from '../Button/ButtonSecondary';
import {ButtonTertiary, ButtonTertiaryProps, ButtonTertiaryWidth} from '../Button/ButtonTertiary';
import {TestableElement} from '../../external/types';

export type BottomsheetProps = {
  header?: string;
  content?: ReactNode;
  primaryButton?: Omit<ButtonPrimaryProps, 'className'>;
  secondaryButton?: Omit<ButtonSecondaryProps, 'className'>;
  tertiaryButton?: Omit<ButtonTertiaryProps, 'className'>;
  onClose?: () => void;
  className?: string;
} & TestableElement;

export const Bottomsheet: FC<BottomsheetProps> = ({
  header = '',
  content = undefined,
  primaryButton = undefined,
  secondaryButton = undefined,
  tertiaryButton = undefined,
  onClose = undefined,
  className = undefined,
  testId = undefined,
}) => {
  const [bottomsheetOpened, setBottomsheetOpened] = useState(true);

  const styles = {
    mask: clsx('absolute w-[100vw] h-[100vh] z-40 duration-500', {'bg-black/50': bottomsheetOpened}, className),
    component: clsx('fixed flex flex-col items-center w-full bg-white rounded-t bottom-0 duration-500 z-50', {
      'translate-y-[calc(100%-20px)] bg-opacity-0': !bottomsheetOpened,
      'translate-y-0': bottomsheetOpened,
    }),
    barBox: 'py-[8px] px-[8px] cursor-pointer',
    bar: 'w-[64px] h-[4px] bg-grey-200 rounded opacity-1',
    contentBox: clsx('flex flex-col gap-y-[16px] w-full px-[24px] pt-[8px] pb-[24px]', {
      invisible: !bottomsheetOpened,
    }),
    headerBox: 'flex w-full justify-between',
    closeIcon: 'cursor-pointer',
    header: 'font-quicksand text-base font-bold',
    buttonBox: 'flex flex-col items-center gap-y-[8px]',
  };

  const handleClose = () => {
    setBottomsheetOpened(false);
    onClose?.();
  };

  const handleToggle = () => {
    if (bottomsheetOpened) {
      handleClose();
    } else {
      setBottomsheetOpened(true);
    }
  };

  return (
    <div className={styles.mask} onClick={handleClose} data-test-element="bottomsheet-mask" data-testid={testId}>
      <div className={styles.component} onClick={e => e.stopPropagation()} data-testid="bottomsheet-component">
        <div className={styles.barBox} onClick={handleToggle} data-testid="bottomsheet-bar-box">
          <div className={styles.bar} />
        </div>
        <div className={styles.contentBox}>
          <div className={styles.headerBox}>
            <Icon svg={IconSvg.CLOSE} onClick={handleClose} className={styles.closeIcon} />
            <div className={styles.header}>{header}</div>
            <div className="w-[24px]" />
          </div>
          {content || <EmptyState />}
          {(primaryButton || secondaryButton || tertiaryButton) && (
            <div className={styles.buttonBox}>
              {ButtonPrimary && (
                <ButtonPrimary
                  lIcon={primaryButton?.lIcon}
                  rIcon={primaryButton?.rIcon}
                  text={primaryButton?.text}
                  disabled={primaryButton?.disabled}
                  onClick={primaryButton?.onClick}
                  width={ButtonPrimaryWidth.FULL}
                />
              )}
              {ButtonSecondary && (
                <ButtonSecondary
                  lIcon={secondaryButton?.lIcon}
                  rIcon={secondaryButton?.rIcon}
                  text={secondaryButton?.text}
                  disabled={secondaryButton?.disabled}
                  onClick={secondaryButton?.onClick}
                  width={ButtonSecondaryWidth.FULL}
                />
              )}
              {ButtonTertiary && (
                <ButtonTertiary
                  lIcon={tertiaryButton?.lIcon}
                  rIcon={tertiaryButton?.rIcon}
                  text={tertiaryButton?.text}
                  disabled={tertiaryButton?.disabled}
                  onClick={tertiaryButton?.onClick}
                  width={ButtonTertiaryWidth.FULL}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
