import React, {Key, useEffect, useState} from 'react';
import {Dropdown} from '../../Components/Dropdown/Dropdown';
import {DropdownListOption} from '../../Components/Dropdown/DropdownList';
import {TagCloud} from '../../Components/TagCloud/TagCloud';
import {DropdownWidth, TagProps} from '@symfonia/brandbook';
import {usePrev} from '@react-spring/shared';

export type MultiSelectFilterProps<TOptionType = string> = {
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  items: DropdownListOption<TOptionType>[];
  options: Map<React.Key, DropdownListOption<TOptionType>>;
  onSelected: (selected: (DropdownListOption<TOptionType> | undefined)[]) => void;
  onRemoved?: (removed: React.Key) => void;
  allOption?: Omit<DropdownListOption<TOptionType>, 'group' | 'href'> & {key: Key};
  testId?: string;
  useSearch?: boolean;
};

const createOptions = <T extends any = string>({
  options,
  allOption,
}: Pick<MultiSelectFilterProps<T>, 'allOption' | 'options'>): Map<React.Key, DropdownListOption<T>> => {
  if (!allOption) {
    return options;
  }
  const {key, ...rest} = allOption;
  return new Map([[key, rest], ...options.entries()]);
};

const MultiSelectFilterMemoized = <TOption,>({
  disabled = false,
  allOption = {
    value: '_ALL' as TOption,
    key: '_ALL',
    label: 'Zaznacz wszystko',
  },
  testId,
  useSearch,
  ...props
}: MultiSelectFilterProps<TOption>): JSX.Element => {
  const isControlled = props.onRemoved !== undefined;
  const prevItems = usePrev(props.items);

  const [options] = useState<Map<React.Key, DropdownListOption<TOption>>>(() =>
    createOptions({
      allOption,
      options: props.options,
    }),
  );

  const [localItems, setLocalItems] = useState<Set<Key>>(() => {
    const indexes: Set<Key> = new Set();
    for (const item of props.items) {
      for (const [key, option] of createOptions(props)) {
        if (item.value === option.value) indexes.add(key);
      }
    }
    if (indexes.size === props.options.size && allOption) {
      indexes.add(allOption.key);
    }
    return indexes;
  });

  const [tags, setTags] = useState<Map<React.Key, TagProps>>(new Map());

  const items = localItems;

  const handleChange = (values: React.Key[]) => {
    if (allOption) {
      const allIsNowChecked = values.includes(allOption.key);
      const allWasChecked = localItems.has(allOption.key);
      const valuesWithoutAll = values.filter(v => v !== allOption.key);
      const containAllValues = valuesWithoutAll.length === props.options.size;

      if (allWasChecked) {
        if (!allIsNowChecked) {
          values = [];
        } else if (!containAllValues) {
          values = valuesWithoutAll;
        }
      } else {
        if (allIsNowChecked || containAllValues) {
          values = [...props.options.keys(), allOption.key];
        }
      }
    }

    setLocalItems(new Set(values));

    const tempItems: (DropdownListOption<TOption> | undefined)[] = [];
    values.forEach(key => {
      if (props.options.has(key)) {
        tempItems.push(props.options.get(key));
      }
    });
    props.onSelected(tempItems);
  };

  const handleTagClick = (key: React.Key) => {
    if (isControlled) {
      return props.onRemoved?.(key);
    }
    const itemsUpdate = new Set(Array.from(localItems.keys()).filter(item => item !== key && item !== allOption?.key));
    setLocalItems(itemsUpdate);
    const tempItems: (DropdownListOption<TOption> | undefined)[] = [];
    props.options.forEach((val, itemKey) => {
      if (key !== itemKey && itemsUpdate.has(itemKey)) {
        tempItems.push(props.options.get(itemKey));
      }
    });
    props.onSelected(tempItems);
  };

  useEffect(() => {
    const tempTags = new Map<React.Key, TagProps>();
    if (!props.items.length && prevItems?.length && tags.size) {
      handleChange([]);
      setTags(new Map());
      return;
    }
    items.forEach((val, key) => {
      if (props.options.has(key)) {
        tempTags.set(key, {text: props.options.get(key)!.label});
      }
    });
    setTags(tempTags);
  }, [items, isControlled, props.options, props.items]);

  return (
    <div className="w-full">
      <Dropdown
        width={DropdownWidth.FULL}
        options={options}
        onChange={handleChange}
        placeholder={props.placeholder ?? 'Wyszukaj i zaznacz'}
        displaySelected={false}
        multiple={true}
        values={items}
        testId={testId}
        disabled={!!disabled}
        useSearch={useSearch}
      />
      <div className="my-[12px]"/>
      <TagCloud tags={tags} onTagClicked={key => handleTagClick(key)} maxVisiblePills={5}/>
    </div>
  );
};

export const MultiSelectFilter = React.memo(MultiSelectFilterMemoized);
