export type Event<T> = (params: T) => void;

type BaseParams = Record<string, unknown>;

export interface EventServiceI<T extends string | number, P extends BaseParams> {
  subscribe(key: T, event: Event<P>): () => void;

  unsubscribe(key: T, event: Event<P>): boolean;

  notify(key: T, data: P): void;

  clear(): void;
}

export class EventsService<Keys extends string | number, Params extends BaseParams = BaseParams>
  implements EventServiceI<Keys, Params>
{
  protected events: Partial<Record<Keys, Event<Params>[]>> = {};

  public subscribe(key: Keys, event: Event<Params>): () => void {
    this.getEventsByKey(key).push(event);
    return () => this.unsubscribe(key, event);
  }

  public unsubscribe(key: Keys, event: Event<Params>): boolean {
    const events = this.getEventsByKey(key);
    if (!events.length) {
      return false;
    }

    const newEvents = events.filter(currentEvent => event !== currentEvent);
    if (newEvents.length === events.length) {
      return false;
    }

    this.events[key] = newEvents;

    return true;
  }

  public notify(key: Keys, params: Params): void {
    this.events[key]?.forEach?.(event => event(params));
  }

  public clear(): void {
    // eslint-disable-next-line no-return-assign
    Object.keys(this.events).forEach(key => (this.events[key as Keys] = []));
  }

  private getEventsByKey(key: Keys): Event<Params>[] {
    if (!this.events[key]) {
      this.events[key] = [];
    }
    return this.events[key] as Event<Params>[];
  }
}
