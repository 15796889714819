import {setContext} from '@apollo/client/link/context';
import {authState} from '@symfonia-ksef/state/rootRepository';

export const getBarerToken = () => `Bearer ${authState.accessToken}`;

export const authorizationMiddleware = () => setContext(async (operation, {headers}) => {
  try {
    return {
      headers: {
        ...headers,
        authorization: getBarerToken(),
      },
    };
  } catch (e) {
    return authState.signOut();
  }
});
