import {WsEventsRepository} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsEventsRepository';
import {GetUPOResultService} from '@symfonia-ksef/state/KSeFSubscriptionServices/GetUPOResultService';
import {
  AuthorizationInKSeFService,
  KSeFAuthorizationSignatureAction,
} from '@symfonia-ksef/state/AuthenticationState/AuthorizationInKSeFService';
import {
  AuthorizationInKSeFWithTokenService,
} from '@symfonia-ksef/state/AuthenticationState/AuthorizationInKSeFWithTokenService';
import {GrantPermissionsService} from '@symfonia-ksef/state/KSeFSubscriptionServices/GrantPermissionsService';
import {WsActiveEventsRepository} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsActiveEventsRepository';
import {
  DownloadedInvoicesResultService,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadedInvoicesResultService';
import {SendInvoicesResultService} from '@symfonia-ksef/state/KSeFSubscriptionServices/SendInvoicesResultService';
import {UploadKSeFInvoicesService} from '@symfonia-ksef/state/KSeFSubscriptionServices/UploadKSeFInvoicesService';

import {DeleteIncoviceResultService} from '@symfonia-ksef/state/KSeFSubscriptionServices/DeleteInvoicesResultService';
import {CheckWhiteListService} from '@symfonia-ksef/state/KSeFSubscriptionServices/CheckWhiteListService';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {KSeFNotificationUserDocument} from '@symfonia-ksef/graphql';
import {registerGlobalNotificationEvents} from '../services/EventConsumers/GlobalEventConsumers';
import {SubscriptionEventsService} from '../services/SubscriptionEventsService';
import {auth} from '../modules/root/providers/GraphQLProvider';
import {
  PostingTableRefreshService,
} from '../modules/auto-posting/pages/PostingTablePage/PostingTable/State/PostingTableRefreshService';
import {
  DownloadInvoicesProgressTracker,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressTracker';

export {auth as authState} from '../modules/root/providers/GraphQLProvider';
export const earchiveState = new EArchiveState(auth);
export const envObserver = earchiveState.company.envObserver;
export const ksefEventsService = new SubscriptionEventsService(KSeFNotificationUserDocument, envObserver, earchiveState, registerGlobalNotificationEvents);
export const wsEventsRepository = new WsEventsRepository(envObserver, earchiveState);
export const wsActiveEventsRepository = new WsActiveEventsRepository(envObserver, earchiveState);
export const getUpoResultService = new GetUPOResultService(envObserver, earchiveState);
export const downloadedInvoicesResultService = new DownloadedInvoicesResultService(envObserver, earchiveState);
export const authorizationInKSeFService = new AuthorizationInKSeFService(KSeFAuthorizationSignatureAction.Authorize, envObserver, earchiveState);
export const removeAuthorizationInKSeFService = new AuthorizationInKSeFService(KSeFAuthorizationSignatureAction.RemoveAuthorize, envObserver, earchiveState);
export const authorizationInKSeFWithTokenService = new AuthorizationInKSeFWithTokenService(envObserver, earchiveState);
export const grantPermissionsService = new GrantPermissionsService(envObserver, earchiveState);
export const backgroundActionsService = earchiveState.backgroundActionsServices;
export const sendInvoicesResultService = new SendInvoicesResultService(earchiveState);
export const uploadInvoicesService = new UploadKSeFInvoicesService(envObserver, earchiveState);
export const postingTableRefreshService = new PostingTableRefreshService();
export const deleteInvoiceService = new DeleteIncoviceResultService(earchiveState.company, earchiveState);
export const checkWhiteListService = new CheckWhiteListService(envObserver, earchiveState);
export const authenticationState = earchiveState.ksefAuthentication;
export const downloadInvoicesProgressTracker = new DownloadInvoicesProgressTracker(wsActiveEventsRepository, ksefEventsService);
