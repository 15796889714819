import {action, computed, makeObservable, observable} from 'mobx';
import {Notification} from './NotificationDataService';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';

export enum DataTypes {
  ManualFetching = 'manualFetching',
  AutoFetching = 'autoFetching',
  Archived = 'archived'
}

class ResultData<T> {
  @observable.ref
  public result: T | null = null;

  @observable.ref
  public notification: Partial<Notification> = null;

  @observable
  public isActive: boolean = false;

  constructor(public readonly type: DataTypes) {
    makeObservable(this);
  }

  @action.bound
  public set(params: { result: T | null, notification: Partial<Notification> | null }): this {
    this.result = params.result;
    this.notification = params.notification;
    return this;
  }

  @action.bound
  public setIsActive(isActive: boolean): this {
    this.isActive = isActive;
    return this;
  }
}

interface Parent<T> {
  setDownloadType?: (type?: WebsocketNotificationType) => void,
  downloadType?: WebsocketNotificationType,
  result: T | null,
  modalIsActive: boolean,
  notification: Notification

  setModalIsActive(isActive: boolean): void
}

//Pozwala zarządzać wersją danych kontekstowych dla eventu zakończonego procesu subskrypcyjnego
//Dane kontekstowe mogą być pobierane dla archiwalnych eventów, triggerowanych automatycznie oraz ręcznie (poprzez uruchomienie jobRunnera)
//Ważne jest żeby się wzajemnie nie nadpisywały (np jeśli user otworzy modal z danymi pochodzącymi z historycznego eventu  i w tym samym czasie przyjdzie event triggerowany ręcznie tego samego typu, dane na modalu nie zostaną nadpisane)
export interface NotificationDataResultManagerI<T> {
  currentResult: T | null;

  setCurrentResultType(type: DataTypes | null): this;

  setArchivedResult(result: T | null, NotificationId: string): this;

  setFetchingResult(result: T | null, notification: Notification): this;

  resetResults(...results: DataTypes[]): void;
}


export type TypeMap = Partial<Record<WebsocketNotificationType, DataTypes | null>>

const defaultTypeMap: TypeMap = {
  [WebsocketNotificationType.DownloadInvoices]: DataTypes.ManualFetching,
  [WebsocketNotificationType.AutoFetchingInvoices]: DataTypes.AutoFetching,
  [WebsocketNotificationType.GetUpo]: DataTypes.ManualFetching,
  [WebsocketNotificationType.SendingInvoices]: DataTypes.ManualFetching,
  [WebsocketNotificationType.AutoSendingInvoices]: DataTypes.AutoFetching,
  [WebsocketNotificationType.UploadInvoices]: DataTypes.ManualFetching,
  [WebsocketNotificationType.GrantPermission]: null,
  [WebsocketNotificationType.DeleteInvoices]: DataTypes.ManualFetching,
  [WebsocketNotificationType.WhiteListValidation]: DataTypes.ManualFetching,
};

const getMappedType = (type: WebsocketNotificationType | undefined, map: TypeMap): DataTypes | null => type ? map[type] ?? null : null;

export class NotificationDataResultManager<T> implements NotificationDataResultManagerI<T> {

  @observable
  private currentResultType: DataTypes | null = null;

  private [DataTypes.ManualFetching] = new ResultData<T>(DataTypes.ManualFetching);

  private [DataTypes.AutoFetching] = new ResultData<T>(DataTypes.AutoFetching);

  private [DataTypes.Archived] = new ResultData<T>(DataTypes.Archived);

  constructor(private parent: Parent<T>, private typeMap: TypeMap = defaultTypeMap) {
    makeObservable(this);
  }

  @computed
  public get currentType(): DataTypes | null {
    return this.currentResultType;
  }

  @computed
  public get currentResult(): T | null {
    return this.currentResultType ? this[this.currentResultType].result : null;
  }


  @action.bound
  public setCurrentResultType(type: DataTypes | null): this {
    this.currentResultType = type;
    return this;
  }

  @action.bound
  public resetResults(...types: DataTypes[]): void {
    if (!types.length && this.currentType) {
      this._reset(this.currentType);
    }
    types.forEach(type => this._reset(type));
  }

  @action.bound
  public setArchivedResult(result: T | null, NotificationId: string): this {
    if (!this.parent.modalIsActive) {
      this[DataTypes.Archived].set({result, notification: {NotificationId}});
      this.setCurrentResultType(DataTypes.Archived);
    }
    return this;
  }

  public setFetchingResult(result: T | null, notification: Notification): this {
    if (!this.parent.modalIsActive) {
      this.parent.setDownloadType?.(notification?.Type);
      this.setCurrentResultType(getMappedType(notification?.Type, this.typeMap));
      this.currentResultType && this[this.currentResultType].set({result, notification});
    }
    return this;
  }

  private _reset(type: DataTypes): void {
    this[type].set({result: null, notification: null});
  }
}
