import {observer} from 'mobx-react-lite';
import {
  ButtonTertiary,
  Checkbox,
  DateRange as DateRangeType,
  InputDate,
  InputDateRangeSize,
  InputDateWidth,
  Notification,
  NotificationSize,
  NotificationVariant,
} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {downloadedInvoicesResultService} from '@symfonia-ksef/state/rootRepository';
import {useCallback, useMemo, useRef, useState} from 'react';
import {DateErrorMessage, DateRangeFilterType} from '@symfonia-ksef/state/FiltersModel/DatePickerState';
import {mapFromRange, mapToRange, toDateString, useMinMaxDate, useValidation} from './GetInvoicesForm.helpers';
import {FormattedMessage} from 'react-intl';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {AppRoutes} from '../../../root/Routes';
import {DATE_DMY} from '@symfonia/brandbook-external/types';
import dayjs from 'dayjs';

export enum TogglableSize {
  SM = 'SM',
  MD = 'MD',
}

export const GetInvoicesForm = observer(() => {
  const {
    company: {companyId, tenantId},
  } = earchiveState;

  if (!downloadedInvoicesResultService.Internal || !downloadedInvoicesResultService.External) return <></>;
  const ksefSettingsRoute =
    AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + AppRoutes.ksefSettings.address;

  const {
    startDate: internalStartDate,
    endDate: internalEndDate,
    setDate: setInternalDate,
    errorMessage: internalErrorMessage,
    resetValidationError: resetInternalValidationError,
    date: internalDate,
    checked: isInternalChecked,
    setChecked: setInternalChecked,
    onDateInputError: onInternalDateInputError,
    isMaxDateRangeExceeded: isMaxDateRangeExceededInternal,
  } = downloadedInvoicesResultService.Internal ?? {};

  const {
    startDate: externalStartDate,
    endDate: externalEndDate,
    setDate: setExternalDate,
    errorMessage: externalErrorMessage,
    resetValidationError: resetExternalValidationError,
    date: externalDate,
    checked: isExternalChecked,
    setChecked: setExternalChecked,
    onDateInputError: onExternalDateInputError,
    isMaxDateRangeExceeded: isMaxDateRangeExceededExternal,
  } = downloadedInvoicesResultService.External ?? {};

  const {minDate, maxDate} = useMinMaxDate();

  useValidation(externalDate, onExternalDateInputError, resetExternalValidationError);
  useValidation(internalDate, onInternalDateInputError, resetInternalValidationError);

  const [inputExternalDateRange, setInputExternalDateRange] = useState<DateRangeType | undefined>(
    mapToRange(externalDate),
  );
  const [inputInternalDateRange, setInputInternalDateRange] = useState<DateRangeType | undefined>(
    mapToRange(internalDate),
  );

  const isDateDefault = (date?: string, defaultDate?: string) => {
    if (!date || !defaultDate) return;
    return date === defaultDate;
  };

  const defaultExternalInputDate = useRef<DateRangeType | undefined>(inputExternalDateRange).current;
  const defaultInternalInputDate = useRef<DateRangeType | undefined>(inputInternalDateRange).current;

  const isExternalFromDefault = isDateDefault(inputExternalDateRange?.from, defaultExternalInputDate?.from);
  const isExternalToDefault = isDateDefault(inputExternalDateRange?.to, defaultExternalInputDate?.to);
  const isInternalFromDefault = isDateDefault(inputInternalDateRange?.from, defaultInternalInputDate?.from);
  const isInternalToDefault = isDateDefault(inputInternalDateRange?.to, defaultInternalInputDate?.to);

  const onChange = (
    range: DateRangeType | undefined,
    setter: (date: DateRangeFilterType | undefined) => void,
    inputSetter: (range: DateRangeType | undefined) => void,
  ): void => {
    inputSetter(range);
    setter(mapFromRange(range));
  };

  const handleExternalDateChange = (output: DATE_DMY, type: 'from' | 'to'): void => {
    const isDateValid = dayjs(output, 'DD-MM-YYYY').isValid();
    const dateObj = {
      from: toDateString(externalStartDate) as DATE_DMY,
      to: toDateString(externalEndDate) as DATE_DMY,
      [type]: isDateValid ? output : undefined,
    };
    onChange(dateObj, setExternalDate, setInputExternalDateRange);
  };

  const handleInternalDateChange = (output: DATE_DMY, type: 'from' | 'to'): void => {
    const isDateValid = dayjs(output, 'DD-MM-YYYY').isValid();
    const dateObj = {
      from: toDateString(internalStartDate) as DATE_DMY,
      to: toDateString(internalEndDate) as DATE_DMY,
      [type]: isDateValid ? output : undefined,
    };
    onChange(dateObj, setInternalDate, setInputInternalDateRange);
  };

  const createErrorMessage = useCallback((messages: DateErrorMessage | undefined): string | undefined => {
    if (!messages) {
      return;
    }
    const {start, end} = messages;

    return `${start ? intl.formatMessage({id: start}) : ''}${start && end && end !== start ? ' ' : ''}${end && end !== start ? intl.formatMessage({id: end}) : ''}`;
  }, []);

  const externalMessage = useMemo<string | undefined>(
    () => createErrorMessage(externalErrorMessage),
    [externalErrorMessage],
  );
  const internalMessage = useMemo<string | undefined>(
    () => createErrorMessage(internalErrorMessage),
    [internalErrorMessage],
  );

  return (
    <div className="flex flex-col w-full pt-[24px]">
      <div className="flex flex-col w-full mb-7">
        <Checkbox
          className="mb-2"
          label={intl.formatMessage({id: Tr.externalRadioLabel})}
          name="ExternalEnabled"
          onChange={checked => setExternalChecked(checked)}
          checked={!!isExternalChecked}
          size={TogglableSize.SM}
          value=""
        />
        {isExternalChecked && (
          <div className="flex items-center gap-[16px] items-baseline">
            <div className="flex flex-col">
              <InputDate
                width={InputDateWidth.FIT}
                size={InputDateRangeSize.SM}
                notification={!isExternalFromDefault && externalErrorMessage?.start ? externalMessage : ''}
                isError={!isExternalFromDefault && !!externalErrorMessage?.start}
                placeholder={intl.formatMessage({id: Tr.From})}
                label={intl.formatMessage({id: Tr.DateFrom})}
                onChange={(date: DATE_DMY) => handleExternalDateChange(date, 'from')}
                className="mb-[8px]"
                value={toDateString(externalStartDate) as DATE_DMY}
                maxDate={maxDate}
                minDate={minDate}
              />
              {!isExternalFromDefault &&
                <ButtonTertiary
                  text={intl.formatMessage({id: Tr.returnLabel})}
                  disabled={!defaultExternalInputDate}
                  onClick={() =>
                    defaultExternalInputDate && handleExternalDateChange(defaultExternalInputDate.from, 'from')
                  }
                />
              }
            </div>
            <div className="flex flex-col">
              <InputDate
                width={InputDateWidth.FIT}
                size={InputDateRangeSize.SM}
                notification={!isExternalToDefault && externalErrorMessage?.end ? externalMessage : ''}
                isError={!isExternalToDefault && !!externalErrorMessage?.end}
                placeholder={intl.formatMessage({id: Tr.To})}
                label={intl.formatMessage({id: Tr.DateTo})}
                onChange={(date: DATE_DMY) => handleExternalDateChange(date, 'to')}
                className="mb-[8px]"
                value={toDateString(externalDate?.to) as DATE_DMY}
                maxDate={maxDate}
                minDate={minDate}
              />
              {!isExternalToDefault &&
                <ButtonTertiary
                  text={intl.formatMessage({id: Tr.returnLabel})}
                  disabled={!defaultExternalInputDate}
                  onClick={() => defaultExternalInputDate && handleExternalDateChange(defaultExternalInputDate.to, 'to')}
                />
              }
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full mb-7">
        <Checkbox
          className="mb-2"
          label={intl.formatMessage({id: Tr.internalRadioLabel})}
          name="InternalEnabled"
          onChange={checked => setInternalChecked(checked)}
          checked={!!isInternalChecked}
          size={TogglableSize.SM}
          value=""
        />
        {isInternalChecked && (
          <div className="flex items-center gap-[16px] items-baseline">
            <div className="flex flex-col">
              <InputDate
                width={InputDateWidth.FIT}
                size={InputDateRangeSize.SM}
                notification={!isInternalFromDefault && internalErrorMessage?.start ? internalMessage : ''}
                isError={!isInternalFromDefault && !!internalErrorMessage?.start}
                placeholder={intl.formatMessage({id: Tr.From})}
                label={intl.formatMessage({id: Tr.DateFrom})}
                onChange={(date: DATE_DMY) => handleInternalDateChange(date, 'from')}
                className="mb-[8px]"
                value={toDateString(internalStartDate) as DATE_DMY}
                maxDate={maxDate}
                minDate={minDate}
              />
              {!isInternalFromDefault &&
                <ButtonTertiary
                  text={intl.formatMessage({id: Tr.returnLabel})}
                  disabled={!defaultInternalInputDate}
                  onClick={() =>
                    defaultInternalInputDate && handleInternalDateChange(defaultInternalInputDate.from, 'from')
                  }
                />
              }
            </div>
            <div className="flex flex-col">
              <InputDate
                width={InputDateWidth.FIT}
                size={InputDateRangeSize.SM}
                notification={!isInternalToDefault && internalErrorMessage?.end ? internalMessage : ''}
                isError={!isInternalToDefault && !!internalErrorMessage?.end}
                placeholder={intl.formatMessage({id: Tr.To})}
                label={intl.formatMessage({id: Tr.DateTo})}
                onChange={(date: DATE_DMY) => handleInternalDateChange(date, 'to')}
                className="mb-[8px]"
                value={toDateString(internalDate?.to) as DATE_DMY}
                maxDate={maxDate}
                minDate={minDate}
              />
              {!isInternalToDefault &&
                <ButtonTertiary
                  text={intl.formatMessage({id: Tr.returnLabel})}
                  disabled={!defaultInternalInputDate}
                  onClick={() => defaultInternalInputDate && handleInternalDateChange(defaultInternalInputDate.to, 'to')}
                />
              }
            </div>
          </div>
        )}
      </div>
      {(isMaxDateRangeExceededInternal || isMaxDateRangeExceededExternal) && (
        <Notification
          text={intl.formatMessage({id: Tr.invoicesDateRangeExceedsTwoYearsWarning})}
          variant={NotificationVariant.ERROR}
          size={NotificationSize.MD}
        />
      )}
      <FormattedMessage
        id={Tr.autoFetchingInvoicesLink}
        values={{
          a: chunks => (
            <a href={ksefSettingsRoute} rel="noreferrer" target="_blank" className="text-primary-500 underline">
              {chunks}
            </a>
          ),
        }}
      />
    </div>
  );
});
