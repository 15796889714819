import * as React from 'react';
import {FeatureSwitchTypeEnum} from '../../enums/FeatureSwitchTypeEnum';
import {isFeatureEnabled} from '../../helpers/featureSwitch';


export interface FeatureToggleProps extends React.PropsWithChildren {
    feature: FeatureSwitchTypeEnum | string ;
}

export const FeatureToggle = ({feature, children}: FeatureToggleProps) => {
    
    const isEnabled = isFeatureEnabled(feature);

    const toggledFeatureChildren = React.Children.map(children, child => React.cloneElement(child as React.ReactElement, {isEnabled}));

    return (
        <>
            {toggledFeatureChildren}
        </>
    );
};
