import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';

export enum StatusPillColor {
    Success,
    Warning,
    Error,
    Default
}

export interface StatusPillProps {
    Id: string;
    color?: StatusPillColor;
    label: string | undefined;
}

type StatusPillStyles = {
  component: string;
}

const getStyles = (color: StatusPillColor): StatusPillStyles => {
  const styles = {
    component: clsx({'text-[14px] py-[8px] px-[12px] inline rounded-[4px]' : true,
    'bg-gray-200' : color === StatusPillColor.Default,
    'bg-[#D9F0D9] text-[#003E00]' : color === StatusPillColor.Success,
    'bg-[#F0D0B2] text-[#623E1C]' : color === StatusPillColor.Warning,
    'bg-[#F2BBC2] text-[#411319]' : color === StatusPillColor.Error,
    }),
  };

  return styles;
};
  
export const StatusPill: FC<StatusPillProps> = ({
    Id,
    color = StatusPillColor.Default,
    label,
}) => {
  const styles = (() => getStyles(color))();

  return (
    <div key={Id} className={styles.component}>
      <FormattedMessage id={label}/>
    </div>
  );
};
