import {AccountTypeEnum} from '@symfonia-ksef/graphql';
import {CheckboxGroupOrientation, CheckboxGroupSize, RadioButtonGroup} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';

export interface RadioBtnsKUPvsNKUPProps {
  onRadioBtnChange?: (type: AccountTypeEnum) => void;
}

export const AccountTypeRadioButtons = observer(({onRadioBtnChange}: RadioBtnsKUPvsNKUPProps): JSX.Element => {
  const intl = useIntl();

  const [value, setValue] = useState<AccountTypeEnum>(AccountTypeEnum.Kup);
  const handleChange = (value: (string | number)[]) => {
    const newValue = value[0] as AccountTypeEnum;
    setValue(newValue);
    !!onRadioBtnChange && onRadioBtnChange(newValue as AccountTypeEnum);
  };

  const options = [
    {
      label: intl.formatMessage({id: Tr.accountKUP}),
      value: AccountTypeEnum.Kup,
    },
    {
      label: intl.formatMessage({id: Tr.accountNKUP}),
      value: AccountTypeEnum.Nkup,
    },
  ];

  return (
    <div className="flex flex-col">
      <RadioButtonGroup
        aria-labelledby="demo-radio-buttons-group-label"
        checkboxes={options}
        name="radio-buttons-group"
        onChange={value => handleChange(value)}
        size={CheckboxGroupSize.SM}
        value={[value.toString()]}
        orientation={CheckboxGroupOrientation.HORIZONTAL}
        label={intl.formatMessage({id: Tr.accountTypes})}
      />
    </div>
  );
});
