import classnames from 'classnames';
import {FC, ReactNode} from 'react';


interface ITextProps {
  block?: boolean
  className?: string,
  small?: boolean,
  children?: ReactNode | ReactNode[]
}

export const Text: FC<ITextProps> = ({children, className, block, small}) => {


  return <span
    className={classnames('font-[Quicksand]', className, {
        'block': block,
        'text-[14px]': small,
        'text-[16px]': !small,
      },
    )}>{children}</span>;
};
