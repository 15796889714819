import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Header} from '../../../../root/components/Header';
import {Breadcrumbs, ButtonSecondary, Input, InputSize, InputWidth} from '@symfonia/brandbook';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {authState, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {usePageTitle} from '../../../../common';

export const UserPage: FC = observer(() => {
  const {userDetails} = earchiveState.company;
  const intl = useIntl();

  const {setPageTitle} = usePageTitle();

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.userDetails}),
    },
  ];
  const handleClick = () => {
    authState.resetPassword();
  };

  setPageTitle(intl.formatMessage({id: Tr.userDetails}));

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
        <Header>{intl.formatMessage({id: Tr.userDetails})}</Header>

        <main className="flex gap-12 justify-start my-6 lg:flex-row flex-col mt-[20px] md:mt-[40px]">
          <section className="lg:w-1/3 md:w-[90%] w-full max-w-[400px] grid gap-y-6 gap-y-5 items-center">
            <h3 className={'font-bold'}>{intl.formatMessage({id: Tr.personalData})}</h3>
            <Input
              width={InputWidth.FULL}
              size={InputSize.MD}
              disabled
              value={userDetails.Email ?? ''}
              label={intl.formatMessage({id: Tr.userEmail})}
            />
            <Input
              width={InputWidth.FULL}
              size={InputSize.MD}
              disabled
              value={userDetails.FirstName ?? ''}
              label={intl.formatMessage({id: Tr.userFirstName})}
            />
            <Input
              width={InputWidth.FULL}
              size={InputSize.MD}
              disabled
              value={userDetails.LastName ?? ''}
              label={intl.formatMessage({id: Tr.userLastName})}
            />
          </section>
          <section className="w-full lg:max-w-1/2 items-start">
            <h3 className={'font-bold mb-[20px]'}>{intl.formatMessage({id: Tr.password})}</h3>
            <ButtonSecondary text={intl.formatMessage({id: Tr.changePassword})} onClick={handleClick}/>
          </section>
        </main>
      </div>
    </PageContent>
  );
});
