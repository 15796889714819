export enum WindowSize {
    LargeDesktop,
    SmallDesktop,
    Tablet,
    Mobile,
}

export const getWindowSize = () : WindowSize => {
    const size = window.innerWidth;
    if (size <= 360) {
        return WindowSize.Mobile;
    }
    else if (size <= 768) {
        return WindowSize.Tablet;
    }
    else if (size <= 1366) {
        return WindowSize.SmallDesktop;
    }
    return WindowSize.LargeDesktop;
};

export const getModalMaxWidth = () : string => {
    const size = getWindowSize();
    switch (size) {
        case WindowSize.Mobile:
            return 'max-w-full';
        case WindowSize.Tablet:
            return 'max-w-2xl';
        case WindowSize.SmallDesktop:
            return 'max-w-xl';
        case WindowSize.LargeDesktop:
            return 'max-w-xl';
        default:
            return 'max-w-xl';
    }
};