import {BrowserRouter} from 'react-router-dom';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {l} from './modules/logger/loggerInstance';
import {RootModule} from './modules/root/RootModule';
import {environment} from './environments/environment';
import {disableAppInsights} from './modules/common/helpers/helperFunctions';
import {browserHistory} from './modules/root/browserHistory';
import {HistoryRouter} from './modules/root/HistoryRouter';
import './modules/root/helpers/locationEvent';
import {AppInsightsProvider} from '@symfonia/utils';

const App = () => {
  return disableAppInsights ?
    <BrowserRouter>
      <RootModule/>
    </BrowserRouter>
    :
    <AppInsightsProvider instrumentationKey={environment.appInsightsInstrumentationKey}>
      <AppInsightsContext.Provider value={l.reactPlugin}>
        <HistoryRouter history={browserHistory}>
          <RootModule/>
        </HistoryRouter>
      </AppInsightsContext.Provider>
    </AppInsightsProvider>;
};

export default App;
