import {Columns} from '../../../../../../../../root/services/TableServices/BaseTableService';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {cutTextLength} from '../../../../../../../../common/helpers/tableInputTextCutLenght';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {AccountTypeEnum, AccountViewModel, CategoryTypeEnum, CategoryViewModel} from '@symfonia-ksef/graphql';
import {CategoryAccountRow} from './CategoryAccountRepository';
import {Button} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {CategoryAccountInput} from './components/CategoryAccountInput';
import {Tooltip, TooltipPosition} from '@symfonia/brandbook';

const getAccountName = (account: AccountViewModel): string => {
  return account.Number + '  ' + account.Name;
};

const intlFunction = (id: string) => {
  return intl.formatMessage({id});
};

export const categoryAccountsColumns: Columns<CategoryAccountRow, ICategoryAccountState> = Object.freeze({
  Name: {
    cell: row => (
      <Tooltip text={row.Name} position={TooltipPosition.LEFT}>
        <div className="text-ellipsis overflow-hidden whitespace-nowrap">{row.Name}</div>
      </Tooltip>
    ),
    header: intlFunction(Tr.categoryName),
    isObserver: true,
    order: 1,
    sortable: true,
    width: 'w-[300px]',
  },
  Type: {
    header: intlFunction(Tr.categoryType),
    isObserver: true,
    order: 2,
    cell: row =>
      intl.formatMessage({
        id: row.Type === CategoryTypeEnum.Default ? Tr.categoryDefault : Tr.categoryCustom,
      }),
    width: 'w-[150px]',
  },
  AccountKup: {
    header: intlFunction(Tr.accountKUP),
    isObserver: true,
    order: 3,
    sortable: false,
    width: 'w-[350px]',
    cell: (row, _, __, context) => {
      const account = row.AccountKup?.find(({AccountType}) => AccountType === AccountTypeEnum.Kup);

      const accountName = account !== undefined ? getAccountName(account) : intlFunction(Tr.missingCategoryLabel);
      const {truncatedText, wasTruncated} = cutTextLength(accountName) || {};

      if (context.postingConfigurationState.isFinancialYearActive) {
        return (
          <CategoryAccountInput
            accountId={account?.Id}
            accountName={accountName}
            accountType={AccountTypeEnum.Kup}
            categoryId={row.Id}
            state={context}
          />
        );
      }

      return (
        <Tooltip position={TooltipPosition.BOTTOM} text={wasTruncated ? accountName : ''}>
          <div>{truncatedText}</div>
        </Tooltip>
      );
    },
  },
  AccountNKup: {
    header: intlFunction(Tr.accountNKUP),
    isObserver: true,
    order: 4,
    sortable: false,
    width: 'w-[350px]',
    cell: (row, _, __, context) => {
      const account = row.AccountNKup?.find(({AccountType}) => AccountType === AccountTypeEnum.Nkup);

      const accountName = account !== undefined ? getAccountName(account) : intlFunction(Tr.missingCategoryLabel);
      const {truncatedText, wasTruncated} = cutTextLength(accountName) || {};

      if (context.postingConfigurationState.isFinancialYearActive) {
        return (
          <CategoryAccountInput
            accountId={account?.Id}
            accountName={accountName}
            accountType={AccountTypeEnum.Nkup}
            categoryId={row.Id}
            state={context}
          />
        );
      }

      return (
        <Tooltip position={TooltipPosition.BOTTOM} text={wasTruncated ? accountName : ''}>
          <div>{truncatedText}</div>
        </Tooltip>
      );
    },
  },
  Id: {
    asAction: true,
    header: intlFunction(Tr.actions),
    isObserver: true,
    width: 'w-[70px]',
    order: 5,
    cell: (row, _, __, context) => {
      return (
        <Button
          size="small"
          onClick={e => {
            e.stopPropagation();
            const postingState = context;
            postingState.setSelectedCategory(row);
            postingState.setCategoriesToMoveCategoryRules(row.Id);
            postingState.setSelectedInvoiceMenuAnchorEl(e.currentTarget);
            postingState.setSelectedInvoiceMenuOpened(true);
          }}
        >
          <MoreVert className={'IconWithTransform'}/>
        </Button>
      );
    },
  },
});
