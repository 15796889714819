import {FC} from 'react';
import {LinearProgress} from '@mui/material';
import {useLinearLimitCalculations} from './linearPackageLimitHooks';
import {styled} from '@mui/material/styles';
import {LinearPackageLimitProps} from '@symfonia/symfonia-ksef-components';
import ProgressBar from '@ramonak/react-progress-bar';

const StyledLinearProgress = styled(LinearProgress)(({}) => ({
  borderRadius: 5,
  height: '4px',
  '.MuiLinearProgress-barColorSuccess': {
    backgroundColor: 'var(--linearProgressSuccess)',
    strokeLinecap: 'round',
  },
  '.MuiLinearProgress-barColorWarning': {
    backgroundColor: 'var(--linearProgressWarning)',
    strokeLinecap: 'round',
  },
  '.MuiLinearProgress-barColorError': {
    backgroundColor: 'var(--linearProgressError)',
    strokeLinecap: 'round',
  },
}));

const LinearPackageLimit: FC<LinearPackageLimitProps> = ({label, thresholds = [0, 90, 100], loading, lineLabel, ...restProps}) => {
  const {normalizedUsage, limit, message, barColor, textColor, usage, variant} = useLinearLimitCalculations({
    ...restProps,
    loading,
    thresholds,
  });

  const singleLineLabel = lineLabel ? `${lineLabel}: ` : '';

  return (
    <div className={'mt-[15px]'}>
      {label && <p>{!loading && label}</p>}
      <div className={'flex flex-col w-full justify-start'}>
        <ProgressBar bgColor="#009c10" completed={usage} height="4px" isLabelVisible={false} maxCompleted={limit}/>
        <p className={'text-xs mt-[8px]'}>{singleLineLabel}{!loading && message}</p>
      </div>
    </div>
  );
};

export {LinearPackageLimit};
