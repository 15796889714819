import {Popover} from '@mui/material';
import {PopoverOrigin} from '@mui/material/Popover/Popover';
import clsx from 'clsx';
import {isEqual} from 'lodash';
import {
  ChangeEvent,
  FC,
  Fragment,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {NavLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {Checkbox, CheckboxSize} from '../Checkbox/Checkbox';
import {Icon, IconColor, IconProps, IconSize, IconSvg} from '../Icon/Icon';
import {Highlight} from '../../external/components/Highlight/Highlight';

export enum DropdownListWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export enum DropdownListSize {
  SM = 'SM',
  MD = 'MD',
}

export type DropdownListOption<VALUE = string> = {
  label: string;
  value: VALUE;
  sublabel?: string;
  disabled?: boolean;
  href?: string;
  group?: string;
  disableClick?: boolean;
  isDefault?: boolean;
  maxLines?: 1 | 2;
  icon?: ReactElement<IconProps>;
};

export type DropdownListOptions = DropdownListOption[];
export type DropdownListOptionValue = DropdownListOption['value'];

export enum IconPolicy {
  ALWAYS_VISIBLE = 'ALWAYS_VISIBLE',
  SHOW_ON_HOVER = 'SHOW_ON_HOVER',
}

export type DropdownListProps = {
  options: DropdownListOptions;
  value?: DropdownListOptionValue[];
  onChange?: (value: DropdownListOptionValue[]) => void;
  onClose?: () => void;
  isOpen?: boolean;
  anchorEl?: RefObject<HTMLElement> | null;
  className?: string | undefined;
  activeOptionClassName?: string | undefined;
  width?: DropdownListWidth;
  size?: DropdownListSize;
  multiple?: boolean;
  selectable?: boolean;
  useSearch?: boolean;
  defaultOpenGroups?: string[];
  dropUp?: boolean;
  maxLines?: DropdownListOption['maxLines'];
  iconPolicy?: IconPolicy;
};

export const DropdownList: FC<PropsWithChildren<DropdownListProps>> = ({
  options,
  value = [],
  onChange = () => undefined,
  onClose = () => undefined,
  isOpen = false,
  anchorEl = null,
  className = undefined,
  activeOptionClassName = 'active:bg-primary-100 active:text-primary-700',
  width = DropdownListWidth.BASE,
  size = DropdownListSize.MD,
  multiple = false,
  selectable = true,
  useSearch = false,
  children = undefined,
  defaultOpenGroups = [],
  dropUp = false,
  maxLines = 2,
  iconPolicy = IconPolicy.ALWAYS_VISIBLE,
}) => {
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [isMouse, setIsMouse] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<DropdownListOptionValue[]>(value);
  const valueRef = useRef(value);
  const defaultOptionRef = useRef<HTMLDivElement | null>(null);

  const [openGroups, _setOpenGroups] = useState(defaultOpenGroups);
  const setOpenGroup = (name: string, nextIsOpen: boolean) => {
    _setOpenGroups(() => (nextIsOpen ? [...openGroups, name] : openGroups.filter(s => s !== name)));
  };
  const isOpenGroup = (name: string) => openGroups.includes(name);

  useEffect(() => {
    if (!isEqual(valueRef.current, value)) {
      setSelectedValues(value);
      valueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    setTimeout(() => {
      if (!!anchorEl && isOpen && defaultOptionRef.current) {
        defaultOptionRef.current.scrollIntoView({block: 'start'});
      }
    }, 0);
  }, [anchorEl, isOpen]);

  const isOptionSelected = (option: DropdownListOption): boolean => selectedValues.includes(option.value);

  const isOptionDisabled = (option: DropdownListOption): boolean => {
    return (!multiple && isOptionSelected(option)) || !!option.disabled;
  };

  const handleOptionSelect = (option: DropdownListOption) => {
    let nextSelectedValues: DropdownListOptionValue[];
    if (!multiple) {
      nextSelectedValues = [option.value];
    } else {
      nextSelectedValues = selectedValues.includes(option.value)
        ? selectedValues.filter(v => v !== option.value)
        : [...selectedValues, option.value];
    }

    if (selectable) {
      setSelectedValues(nextSelectedValues);
    }

    if (!isOptionDisabled(option)) {
      onChange(nextSelectedValues);
    } else {
      onClose();
    }
  };

  const handleGroupSelect = (valuesInGroup: DropdownListOptionValue[], checked: boolean): void => {
    const nextSelectedValues = checked
      ? [...selectedValues.filter(_value => !valuesInGroup.includes(_value)), ...valuesInGroup]
      : [...selectedValues.filter(_value => !valuesInGroup.includes(_value))];

    if (selectable) {
      setSelectedValues(() => nextSelectedValues);
    }

    onChange(nextSelectedValues);
  };

  const handleOptionKeyDown = (keyCode: string, option: DropdownListOption, index: number) => {
    if (keyCode === 'Enter' || keyCode === 'Space') {
      handleOptionSelect(option);
    }
  };

  const startsAt = (str: string, subStr: string): number => str.toLowerCase().indexOf(subStr.toLowerCase());

  const getLabel = (option: DropdownListOption, isDisabled: boolean, subStr = ''): ReactElement => {
    return (
      <div>
        <Highlight
          className={clsx('whitespace-pre-wrap', {
            'line-clamp-1': (option.maxLines || maxLines) === 1 || option.sublabel,
            'line-clamp-2': (option.maxLines || maxLines) === 2 && !option.sublabel,
          })}
          text={option.label}
          phrase={searchPhrase || ''}
          highlightClassName={clsx('bg-primary-100')}
        />
        {option.sublabel && (
          <div
            className={clsx('line-clamp-1', 'text-xs', {
              'group-hover:text-primary-600 text-grey-500': !isDisabled,
              'text-grey-300': isDisabled,
            })}
          >
            {option.sublabel}
          </div>
        )}
      </div>
    );
  };

  const renderGroupCheckbox = (optionIndex: number, hasSublabel: boolean, isDisabled: boolean): ReactElement => {
    const groupName = options[optionIndex].group;
    const optionsInGroup = options.filter(o => o.group === groupName);
    const valuesInGroup = optionsInGroup.map(o => o.value);
    const checkedOptionsInGroup = optionsInGroup.filter(o => selectedValues.includes(o.value));

    if (groupName === undefined || options.findIndex(option => option.group === groupName) < optionIndex) {
      return <></>;
    }
    return (
      <div
        onKeyDown={event => {
          /** sic */
        }}
        key={groupName}
        className={clsx(
          'group hover:bg-primary-50 hover:text-primary-700 active:bg-primary-100 rounded-lg cursor-pointer px-[16px] shrink-0 flex items-center relative text-black',
          {
            'h-[40px]': size === DropdownListSize.SM,
            'h-[48px]': size === DropdownListSize.MD,
          },
        )}
      >
        <div className={clsx('flex w-full h-full items-center')}>
          <Icon
            svg={isOpenGroup(groupName) ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
            size={IconSize.LG}
            className="shrink-0 mr-[8px] filter-grey-900 group-hover:filter-primary-700"
          />
          <div
            tabIndex={0}
            onClick={() => {
              setOpenGroup(groupName, !isOpenGroup(groupName));
            }}
            className={clsx(
              'w-full h-full flex items-center appearance-none focus:outline-none before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg',
              {
                'before:h-[24px]': size === DropdownListSize.SM && !hasSublabel,
                'before:h-[40px]': size === DropdownListSize.SM && hasSublabel,
                'before:h-[32px]': size === DropdownListSize.MD && !hasSublabel,
                'before:h-[48px]': size === DropdownListSize.MD && hasSublabel,
              },
            )}
          >
            <Checkbox
              size={CheckboxSize.SM}
              checked={checkedOptionsInGroup.length === optionsInGroup.length}
              moderate={checkedOptionsInGroup.length > 0 && checkedOptionsInGroup.length < optionsInGroup.length}
              value={groupName}
              disabled={isDisabled}
              onChange={(checked: boolean, event) => {
                (event as MouseEvent<HTMLInputElement>)?.nativeEvent?.stopPropagation();
                handleGroupSelect(valuesInGroup, checked);
              }}
              className="mr-[8px] shrink-0"
            />
            {groupName}
          </div>
        </div>
      </div>
    );
  };

  const renderOption = (option: DropdownListOption, index: number) => {
    if (option.group !== undefined && isOpenGroup(option.group) === false) {
      return <></>;
    }
    return (
      <div
        ref={option.isDefault ? defaultOptionRef : undefined}
        onKeyDown={event => handleOptionKeyDown(event.code, option, index)}
        key={option.value}
        className={twMerge(
          clsx('pr-[16px] group shrink-0 flex items-center relative text-black', {
            'pl-[16px]': option.group === undefined,
            'pl-[48px]': option.group !== undefined,
            'h-[40px]': size === DropdownListSize.SM && !option.sublabel,
            'h-[52px]': size === DropdownListSize.SM && option.sublabel,
            'h-[48px]': size === DropdownListSize.MD && !option.sublabel,
            'h-[62px]': size === DropdownListSize.MD && option.sublabel,
            [`hover:bg-primary-50 hover:text-primary-700 focus:text-primary-700 rounded-lg cursor-pointer ${
              activeOptionClassName || ''
            }`]: !isOptionDisabled(option),
          }),
        )}
        data-testid="dropdownChild"
      >
        {!multiple && option.href && (
          <NavLink
            to={option.href}
            onClick={() => handleOptionSelect(option)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleOptionSelect(option);
              }
            }}
            data-testid="dropdown-component-link"
            tabIndex={isOptionDisabled(option) ? -1 : 0}
            title={option.label}
            className={() =>
              clsx({
                'text-grey-500': isOptionDisabled(option),
                'w-full h-full flex items-center appearance-none focus:outline-none': true,
                'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
                'focus:before:outline focus:before:outline-2 focus:before:outline-primary-700':
                  !isOptionDisabled(option) && !isMouse,
                'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
                'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
                'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
                'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
              })
            }
          >
            {getLabel(option, isOptionDisabled(option))}
          </NavLink>
        )}
        {!option.href && (
          <span
            tabIndex={isOptionDisabled(option) ? -1 : 0}
            title={option.label}
            onClick={() => handleOptionSelect(option)}
            className={clsx({
              'text-grey-500': isOptionDisabled(option),
              'w-full h-full flex items-center appearance-none focus:outline-none': true,
              'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
              'focus:before:outline focus:before:outline-[1px] focus:before:outline-primary-700':
                (!isOptionDisabled(option) || multiple) && !isMouse,
              'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
              'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
              'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
              'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
              'pointer-events-none': !isOptionDisabled(option) && option.disableClick,
            })}
          >
            {multiple && (
              <Checkbox
                size={CheckboxSize.SM}
                checked={isOptionSelected(option)}
                value={option.value}
                onChange={(_next, event) => {
                  (event as MouseEvent<HTMLInputElement>).stopPropagation();
                }}
                className={clsx('mr-[8px] shrink-0')}
              />
            )}
            {getLabel(option, isOptionDisabled(option))}
          </span>
        )}
        {option.icon && (
          <span
            className={clsx('flex', {
              'opacity-50': isOptionDisabled(option),
              invisible: iconPolicy === IconPolicy.SHOW_ON_HOVER,
              'group-hover:visible': iconPolicy === IconPolicy.SHOW_ON_HOVER,
            })}
          >
            {option.icon}
          </span>
        )}
      </div>
    );
  };

  const render = () => (
    <div
      onMouseDown={() => setIsMouse(true)}
      onKeyDown={() => setIsMouse(false)}
      className={twMerge(
        clsx(
          'px-[8px] flex bg-white border rounded-lg font-quicksand flex-col border-grey-300 shadow overflow-x-hidden',
          {
            'w-full': width === DropdownListWidth.FULL,
            'w-[285px]': width === DropdownListWidth.BASE,
            'w-fit': width === DropdownListWidth.FIT,
            'pt-[8px]': !useSearch,
            'pb-[8px]': !children,
          },
        ),
        className,
      )}
    >
      {useSearch && (
        <div className="flex w-full h-[48px] shrink-0 px-[16px] items-center sticky top-0 bg-white z-[1]">
          <input
            autoFocus
            value={searchPhrase || ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchPhrase(event.target.value || '')}
            placeholder="Wyszukaj ..."
            className="appearance-none outline-none w-full"
          />
          <Icon className="grow-0 shrink-0 justify-self-end" color={IconColor.PRIMARY_500} svg={IconSvg.SEARCH} />
        </div>
      )}

      {options
        .filter(option => !searchPhrase || searchPhrase.length === 0 || startsAt(option.label, searchPhrase) !== -1)
        .map((option, index) => (
          <Fragment key={option.value}>
            {renderGroupCheckbox(index, !!option.sublabel, !!option.disabled)}
            {renderOption(option, index)}
          </Fragment>
        ))}
      {children && (
        <div className="w-full flex h-[48px] shrink-0 items-center justify-center sticky bottom-0 bg-white z-[1]">
          {children}
        </div>
      )}
    </div>
  );

  const renderPopOver = () => {
    const popoverWidth = (() => {
      if (width === DropdownListWidth.FULL) {
        return '100%';
      }
      if (width === DropdownListWidth.BASE) {
        return '285px';
      }

      if (width === DropdownListWidth.INITIAL) {
        return anchorEl?.current ? `${anchorEl.current.getBoundingClientRect().width}px` : '100%';
      }
      return 'fit-content';
    })();

    const anchorOrigin: PopoverOrigin = dropUp
      ? {
          vertical: 'top',
          horizontal: 'left',
        }
      : {
          vertical: 'bottom',
          horizontal: 'left',
        };

    const transformOrigin: PopoverOrigin = dropUp
      ? {
          vertical: 'bottom',
          horizontal: 'left',
        }
      : {
          vertical: 'top',
          horizontal: 'left',
        };

    return (
      <Popover
        open={!!anchorEl && isOpen}
        onClose={onClose}
        anchorEl={anchorEl?.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{
          paper: {
            style: {
              width: popoverWidth,
              background: 'transparent',
              boxShadow: 'none',
            },
          },
        }}
      >
        {render()}
      </Popover>
    );
  };

  return <>{anchorEl ? renderPopOver() : render()}</>;
};
