import {BaseTableServiceI} from './BaseTableService';
import {AnyObject} from 'yup/es/types';
import {computed, makeObservable} from 'mobx';
import {InputMaybe, ItemsOrderInput, OrderDirection} from '@symfonia-ksef/graphql';
import {TypedDocumentNode} from '@apollo/client';
import {InitializerType} from '../../../../services/helpers/fetchMatchedAction';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {TableSort, TableSortDirection} from '@symfonia/brandbook';
import {Repository, RepositoryI} from '../MobXServices/Repository/Repository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export type Order = InputMaybe<ItemsOrderInput>

export type GetVariablesParams = {
  clientSideSortingEnabled: boolean,
  serverSidePaginationEnabled: boolean,
  offset: number,
  size: number,
  sortBy: TableSort | undefined
}

export type DefaultVariables = {
  Skip?: number,
  Take?: number,
  SearchText?: string,
  Order?: Order
}

export type BaseVariables = AnyObject

export interface DataSourceTableServiceBuildingI<Key extends string, Data extends AnyObject, Variables extends BaseVariables, T extends AnyObject> extends DataSourceTableServiceI<Key, Data, Variables, T> {
  setComponents(repository: DataSourceHandlerI<Key, Data, Variables, T>): void;
}

export interface DataSourceHandlerI<Key extends string, Data extends AnyObject, Variables extends BaseVariables, T extends AnyObject> {
  repository: BaseTableDataRepositoryServiceI<Key, Data, Variables>;
  dataMapper: DataMapper<Key, Data, T>;
  getTotalCount: GetTotalCount<Data, Key>;

  prepareVariablesConfig(params: GetVariablesParams): Partial<Variables>;
}

export interface DataSourceTableServiceI<Key extends string, Data extends AnyObject, Variables extends BaseVariables, T extends AnyObject> extends BaseModuleI {
  load(variables?: Partial<Variables> | null): Promise<void>;

  get parameters(): Partial<Omit<Variables, keyof BaseVariables>>;

  getTotalCount(data?: Data[Key] | null): number;

  prepareVariablesConfig(params: GetVariablesParams): Partial<Variables>;
}

export type GetTotalCount<Data extends AnyObject, Key extends string> = (data: Data[Key]) => number

export type DataMapper<Key extends string, SourceData extends AnyObject, TableRow extends AnyObject> = (source: SourceData[Key] | null) => Array<TableRow>

interface Wrapper<T extends AnyObject> {
  setRows: BaseTableServiceI<T>['setRows'];
}

export class DataSourceTableService<Key extends string, Data extends AnyObject, Variables extends BaseVariables, T extends AnyObject> extends BaseModule implements DataSourceTableServiceBuildingI<Key, Data, Variables, T> {

  private syncRowsReactionId?: string;

  constructor(protected wrapped: Wrapper<T>, protected handler: DataSourceHandlerI<Key, Data, Variables, T>) {
    super();
    this.syncRows();
    makeObservable(this);
  }

  @computed
  get parameters(): Partial<Omit<Variables, keyof BaseVariables>> {
    return this.handler.repository.settledParameters;
  }

  @computed
  get loading(): boolean {
    return this.handler.repository.loading;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<Variables> {
    return this.handler.prepareVariablesConfig(params);
  }

  public getTotalCount(data?: Data[Key] | null): number {
    data ??= this.handler.repository.data ?? undefined;
    return data ? this.handler.getTotalCount(data) : 0;
  }

  public setComponents(components: DataSourceHandlerI<Key, Data, Variables, T>): void {
    this.handler = components;
    this.syncRows(true);
  }

  public async load(variables?: Partial<Variables> | null) {
    variables && this.handler.repository.configure(variables);
    await this.handler.repository.fetch({
      onSuccess: data => {
        this.wrapped.setRows(this.mapSourceToRows(data));
      },
    });
  }

  protected override _onMount(): void {
    this.handler.repository.onMount();
  }

  protected override _onUnmount() {
    this.handler.repository.onUnmount();
  }

  private mapSourceToRows(source: Data[Key] | null): Array<T> {
    return this.handler.dataMapper(source) ?? [];
  }

  private syncRows(run: boolean = false) {
    this.wrapped.setRows(this.mapSourceToRows(this.handler.repository.data));
    this.syncRowsReactionId && this.reactionsManager.remove(this.syncRowsReactionId);
    this.syncRowsReactionId = this.reactionsManager.add(() => this.handler.repository.data, data => {
      data && this.wrapped.setRows(this.mapSourceToRows(data));
    });
    run && this.reactionsManager.run(this.syncRowsReactionId);
    return true;
  }
}


export interface BaseTableDataRepositoryServiceI<Key extends string, T extends AnyObject, Variables extends BaseVariables> extends RepositoryI<Key, T, Variables> {
  get settledParameters(): Partial<Variables>;
}

export class BaseTableDataRepositoryService<Key extends string, T extends AnyObject, Variables extends BaseVariables> extends Repository<Key, T, Variables> implements RepositoryI<Key, T, Variables> {

  constructor(key: Key, gqlDocument: TypedDocumentNode, envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(key, gqlDocument, envObserver, earchiveState, InitializerType.Query);
    makeObservable(this);
  }


  @computed
  get settledParameters(): Partial<Variables> {
    return this.variables;
  }

}

export abstract class DefaultDataSourceHandler<Key extends string, Data extends AnyObject, T extends AnyObject, Variables extends DefaultVariables = DefaultVariables> implements DataSourceHandlerI<Key, Data, Variables, T> {

  protected constructor(public repository: BaseTableDataRepositoryServiceI<Key, Data, Variables>) {
  }

  public abstract dataMapper(data: Data[Key] | null): T[];

  public abstract getTotalCount(data: Data[Key] | null): number

  public prepareVariablesConfig(params: GetVariablesParams): Partial<Variables> {
    const variables: Partial<Variables> = {};
    if (!params.clientSideSortingEnabled) {
      variables.Order = {
        FieldName: params.sortBy?.name ?? '',
        Direction: params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc,
      };
    }
    if (params.serverSidePaginationEnabled) {
      variables.Skip = params.offset;
      variables.Take = params.size;
    }
    return variables;
  }
}
