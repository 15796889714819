import {action, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {AccountResponse, AccountViewModel, CategoryTypeEnum, CategoryViewModel} from '@symfonia-ksef/graphql';
import {ICategoryAccountStore} from './ICategoryAccountStore';
import {ICategoryAccountState} from './ICategoryAccountState';
import {GetCategoriesAccountsQueryFunction} from '../queries/GetCategoryAccountsQueryFunction';
import {CategoriesAccountsFiltersKeys} from '../models/CategoryTypesFilterOptions';
import {TreeViewDropdownModel} from '../../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {accountMapper} from '../../../../../../../../../auto-posting/helpers/accountMapper';

export class CategoryAccountStore implements ICategoryAccountStore {
  @observable
  public categories: IObservableArray<CategoryViewModel> = observable([]);

  @observable
  public accountsInUse: IObservableArray<AccountViewModel> = observable([]);

  @observable
  public dropdownAccountsKUP: IObservableArray<TreeViewDropdownModel> = observable([]);

  @observable
  public dropdownAccountsNKUP: IObservableArray<TreeViewDropdownModel> = observable([]);

  @computed
  public get dropdownAccountsAll(): TreeViewDropdownModel[] {
    return this.dropdownAccountsKUP.concat(this.dropdownAccountsNKUP);
  }

  public categoryAccountState: ICategoryAccountState;

  constructor(categoryAccountState: ICategoryAccountState) {
    makeObservable(this);

    this.categoryAccountState = categoryAccountState;
  }

  @action
  public async fetchCategoryAccountsFilterData() {
    return await GetCategoriesAccountsQueryFunction(
      this.categoryAccountState.postingConfigurationState.companyId,
      this.categoryAccountState.postingConfigurationState.selectedFinancialYear!,
      this.categoryAccountState.filterState.activeFilters.get(CategoriesAccountsFiltersKeys.Type)
        ?.values as CategoryTypeEnum[],
      this.categoryAccountState.postingConfigurationState.invoiceTypeGroup,
    ).then(categoriesAccounts => {
      if (categoriesAccounts) {
        this.categories.replace(categoriesAccounts.Categories as CategoryViewModel[]);
        this.accountsInUse.replace(categoriesAccounts.Accounts as AccountViewModel[]);
      }
    });
  }

  @action
  setDdlAccountsKUP(ddlAccounts: AccountResponse[]) {
    this.dropdownAccountsKUP.replace(accountMapper.mapToTreeViewDropdownModel(ddlAccounts));
  }

  @action
  setDdlAccountsNKUP(ddlAccounts: AccountResponse[]) {
    this.dropdownAccountsNKUP.replace(accountMapper.mapToTreeViewDropdownModel(ddlAccounts));
  }

  @action
  public clearDdlAccounts(): void {
    this.dropdownAccountsKUP.clear();
    this.dropdownAccountsNKUP.clear();
  }
}
