import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';

export class TerminateKSeFSessionEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.TerminateSession, undefined, {
      success: {id: Tr.terminateKSeFSessionSuccess},
      error: {id: Tr.terminateKSeFSessionError},
    });
  }
}
