import {
  MutationSaveChangesOnDecreeArgs,
  SaveChangesOnDecreeDocument,
  SaveChangesOnDecreeMutation,
  SaveChangesOnDecreeMutationVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const SaveChangesOnDecreeMutationFunction = async (companyId: string, {...mutationArgs}: MutationSaveChangesOnDecreeArgs) =>
  await apolloClient.mutate<SaveChangesOnDecreeMutation, SaveChangesOnDecreeMutationVariables>({
    mutation: SaveChangesOnDecreeDocument,
    context: {
      envId: companyId,
    },
    variables: {
      DecreeId: mutationArgs.DecreeId,
      CompanyId: companyId,
      UserId: mutationArgs.UserId,
      CategoryAccountChanges: mutationArgs.CategoryAccountChanges,
      PositionCategoryChanges: mutationArgs.PositionCategoryChanges,
      PositionAccountChanges: mutationArgs.PositionAccountChanges,
      PositionVehicleUsageTypeChanges: mutationArgs.PositionVehicleUsageTypeChanges,
      DetailsChanges: mutationArgs.DetailsChanges,
      VatSummaryChanges: mutationArgs.VatSummaryChanges,
      PositionValueChanges: mutationArgs.PositionValueChanges,
      DecreeLineValueChanges: mutationArgs.DecreeLineValueChanges,
      ShouldRememberChanges: mutationArgs.ShouldRememberChanges,
    },
  })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data?.SaveChangesOnDecree.IsChanged;
    });
