import {IModuleStatus} from '../../../../state/ModuleSettings/IModuleStatus';
import React from 'react';

import {NotPurchased} from './NotPurchased/NotPurchased';
import {NotInitialized} from './NotInitialized/NotInitialized';

export interface ModuleToggleProps extends React.PropsWithChildren {
  disableCheck?: boolean;
  moduleStatus?: IModuleStatus;
}

export const ModuleToggle = ({moduleStatus, disableCheck, children}: ModuleToggleProps) => {
  const notPurchasedType = ((<NotPurchased/>) as React.ReactElement).type;
  const notInitializedType = ((<NotInitialized/>) as React.ReactElement).type;

  if (disableCheck || (moduleStatus && moduleStatus.isModulePurchased && moduleStatus.isModuleInitialized)) {
    return (
      <>
        {React.Children.toArray(children).filter(
          child =>
            (child as React.ReactElement).type !== notPurchasedType &&
            (child as React.ReactElement).type !== notInitializedType)}
      </>
    );
  }

  return (
    <>
      {React.Children.toArray(children)
        .filter(
          child =>
            (child as React.ReactElement).type === notPurchasedType ||
            (child as React.ReactElement).type === notInitializedType)
        .map(child => 
        React.cloneElement(child as React.ReactElement, {moduleStatus}))}
    </>
  );
};
