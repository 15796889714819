import {BaseModule} from '../../../modules/root/services/MobXServices/BaseModule';


export type EnvSubscriber = (state: string | null) => unknown | Promise<unknown>

export interface EnvI {
  get companyId(): string | null,

  get tenantId(): string | null,

  get userId(): string | null
}

type PublicSubscriber = (subscriber: EnvSubscriber, setImmediately: boolean) => () => boolean
type PublicUnSubscriber = (subscriber: EnvSubscriber) => boolean

type SubscribersMap = Map<EnvSubscriber, number>

export interface EnvObserverI {
  readonly currentEnv: EnvI,
  subscribeCompanyId: PublicSubscriber
  unsubscribeCompanyId: PublicUnSubscriber
  subscribeTenantId: PublicSubscriber
  unsubscribeTenantId: PublicUnSubscriber
  subscribeUserId: PublicSubscriber
  unsubscribeUserId: PublicUnSubscriber
}

//Pozwala zasubskrybować się do zmiany stanu kontekstu (companyId, userId, tenantId)
export class EnvObserver extends BaseModule implements EnvObserverI {


  private readonly companyIdSubscribers: SubscribersMap = new Map();
  private readonly tenantIdSubscribers: SubscribersMap = new Map();
  private readonly userIdSubscribers: SubscribersMap = new Map();

  constructor(public readonly currentEnv: EnvI) {
    super();
    this.addReaction('companyId', this.companyIdSubscribers);
    this.addReaction('tenantId', this.tenantIdSubscribers);
    this.addReaction('userId', this.userIdSubscribers);
    this.reactionsManager.runAll();
  }


  public subscribeCompanyId(subscriber: EnvSubscriber, setImmediately: boolean = true): () => boolean {
    return this.subscribe(subscriber, setImmediately, 'companyId', this.companyIdSubscribers);
  }

  public unsubscribeCompanyId(subscriber: EnvSubscriber): boolean {
    return this.unsubscribe(subscriber, this.companyIdSubscribers);
  }

  public subscribeTenantId(subscriber: EnvSubscriber, setImmediately: boolean = true): () => boolean {
    return this.subscribe(subscriber, setImmediately, 'tenantId', this.tenantIdSubscribers);
  }

  public unsubscribeTenantId(subscriber: EnvSubscriber): boolean {
    return this.unsubscribe(subscriber, this.tenantIdSubscribers);
  }

  public subscribeUserId(subscriber: EnvSubscriber, setImmediately: boolean = true): () => boolean {
    return this.subscribe(subscriber, setImmediately, 'userId', this.userIdSubscribers);
  }

  public unsubscribeUserId(subscriber: EnvSubscriber): boolean {
    return this.unsubscribe(subscriber, this.userIdSubscribers);
  }

  private handleChange(id: string | null, map: SubscribersMap): void {
    map.forEach((_, subscriber) => subscriber(id));
  }

  private addReaction(key: keyof EnvI, map: SubscribersMap) {
    this.reactionsManager.add(() => this.currentEnv[key], id => this.handleChange(id, map), {fireImmediately: true});
  }

  private subscribe(subscriber: EnvSubscriber, setImmediately: boolean, key: keyof EnvI, map: SubscribersMap): () => boolean {
    map.set(subscriber, Date.now());
    if (setImmediately) {
      subscriber(this.currentEnv[key]);
    }
    return () => this.unsubscribe(subscriber, map);
  }

  private unsubscribe(subscriber: EnvSubscriber, map: SubscribersMap): boolean {
    return map.delete(subscriber);
  }
}
