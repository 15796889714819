export class Base64Mapper {
  constructor(
    private readonly domParser: DOMParser = new DOMParser(),
    private readonly xmlSerializer: XMLSerializer = new XMLSerializer
  ) {}

  public mapFromXml(xml: string): string {
    const xmlDocument = this.domParser.parseFromString(xml, 'application/xml');
    const base64 = this.xmlSerializer.serializeToString(xmlDocument);
    return window.btoa(base64);
  }
}
