import {action, makeObservable, observable} from 'mobx';

export interface InvoicePreviewState {
  HtmlContent?: string;
  PdfContent?: string;
  PdfName?: string;
  MimeType?: string;
}

export interface InvoicePreviewModelInterface extends InvoicePreviewState {
  set(state: InvoicePreviewState): void;

  reset(): void;
}


export class InvoicePreviewModel implements InvoicePreviewModelInterface {

  @observable
  public HtmlContent?: string;

  @observable
  public PdfContent?: string;

  @observable
  public PdfName?: string;

  @observable
  public MimeType?: string;

  constructor(initialState?: InvoicePreviewState) {
    makeObservable(this);

    initialState && this.set(initialState);

  }

  @action.bound
  public set(state: InvoicePreviewState) {
    Object.entries(state).forEach(([key, value]) => {
      this[key as keyof InvoicePreviewState] = value;
    });
  }

  @action.bound
  public reset() {
    for (const key in this as InvoicePreviewState) {
      this[key as keyof InvoicePreviewState] = undefined;
    }
  }
}
