import {useCallback} from 'react';
import {AccountTypeEnum, useGetAccountsLazyQuery} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {addAlert} from '../../../../../../../../services/helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';
import {ISettlementsState} from './State/ISettlementsState';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export const useSettlementsMethods = (state: ISettlementsState) => {
  const companyEnvironmentId = earchiveState.company.companyId;
  const [accounts] = useGetAccountsLazyQuery({context: {envId: companyEnvironmentId}});

  const onSuccess = () => {
    state.setIsLoading(false);
    addAlert({id: Tr.saveAutoPostingSettings, color: ToastVariant.SUCCESS, duration: 10000});
  };

  const onError = () => {
    state.setIsLoading(false);
    addAlert({id: Tr.settlementsSaveFailed, color: ToastVariant.SUCCESS, duration: 10000});
  };

  const getSettlementAccounts = useCallback(
    async (year: string) => {
      state.setIsLoading(true);
      state.clearAccounts();
      await accounts({
        variables: {
          CompanyId: companyEnvironmentId,
          FinancialYearId: year,
          InvoiceTypeGroup: state.postingConfigurationState.invoiceTypeGroup,
          AccountType: AccountTypeEnum.Vat,
        },
      }).then(res => {
        if (res.data !== undefined) {
          state.setDdlAccountsVAT(res.data.GetAccounts.AccountsPlan);
        }
      }).then(async () => {
        await accounts({
          variables: {
            CompanyId: companyEnvironmentId,
            FinancialYearId: year,
            InvoiceTypeGroup: state.postingConfigurationState.invoiceTypeGroup,
            AccountType: AccountTypeEnum.Document,
          },
        }).then(res => {
          if (res.data !== undefined) {
            state.setDdlAccountsMa(res.data.GetAccounts.AccountsPlan);
          }
        });
      }).then(() => {
        state.setIsLoading(false);
      }).catch(error => {
        onError();
      });
    },
    [accounts, onError, onSuccess, state.setIsLoading],
  );

  return {
    getSettlementAccounts,
  };
};
