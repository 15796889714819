import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {usePageTitle} from '../../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {backgroundActionsService, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {Breadcrumbs} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';
import {BackgroundActionsForm} from './BackgroundActionsForm';
import {KSeFAutomaticOperationType} from '@symfonia-ksef/graphql';
import dayjs from 'dayjs';

export const BackgroundActionsPage = observer(() => {
  const intl = useIntl();
  const {setPageTitle} = usePageTitle();
  setPageTitle(intl.formatMessage({id: Tr.ksefSettings}));

  const {
    AutoSendingDisabled,
    AutoFetchingDisabled,
    getRadioOptionValue,
    minutesToTime
  } = backgroundActionsService;

  const autoFetchingInvoices = earchiveState.company.autoFetchingInvoicesConfig;
  const autoSendingInvoices = earchiveState.company.autoSendingInvoicesConfig;
  
  const defaultValues = useMemo(() => {
    return {
      AutoFetchingInvoices: {
        LastModifiedBy: autoFetchingInvoices?.LastModifiedBy ?? undefined,
        LastModifiedDate: autoFetchingInvoices?.LastModifiedDate ?? undefined,
        ExternalEnabled: autoFetchingInvoices?.ExternalEnabled ?? false,
        InternalEnabled: autoFetchingInvoices?.InternalEnabled ?? false,
        DownloadLimitDate: autoFetchingInvoices?.DownloadLimitDate ? (dayjs(autoFetchingInvoices.DownloadLimitDate)?.format('DD-MM-YYYY')) : undefined,
        Type: autoFetchingInvoices?.Type ?? KSeFAutomaticOperationType.Time,
        Minutes: autoFetchingInvoices?.Minutes === null || autoFetchingInvoices?.Minutes === undefined ? undefined : autoFetchingInvoices.Minutes,
        Status: autoFetchingInvoices?.Status ?? false,
      },
      AutoSendingInvoices: {
        LastModifiedBy: autoSendingInvoices?.LastModifiedBy ?? undefined,
        LastModifiedDate: autoSendingInvoices?.LastModifiedDate ?? undefined,
        Type: autoSendingInvoices?.Type ?? KSeFAutomaticOperationType.Time,
        Minutes: autoSendingInvoices?.Minutes === null || autoSendingInvoices?.Minutes === undefined ? undefined : autoSendingInvoices.Minutes,
        Status: autoSendingInvoices?.Status ?? false,
      },
      DownloadLimitDateSelected: !!autoFetchingInvoices?.DownloadLimitDate,
      FetchOption: getRadioOptionValue(autoFetchingInvoices?.InternalEnabled, autoFetchingInvoices?.ExternalEnabled),
      SendOption: autoSendingInvoices?.Type ?? KSeFAutomaticOperationType.Time,
      FetchTime: autoFetchingInvoices?.Type === KSeFAutomaticOperationType.Time && autoFetchingInvoices?.Minutes !== null ? minutesToTime(autoFetchingInvoices?.Minutes) : '',
      FetchInterval: autoFetchingInvoices?.Type === KSeFAutomaticOperationType.Interval && autoFetchingInvoices?.Minutes ? (autoFetchingInvoices?.Minutes / 60) : 0,
      SendTime: autoSendingInvoices?.Type === KSeFAutomaticOperationType.Time && autoSendingInvoices?.Minutes !== null ? minutesToTime(autoSendingInvoices?.Minutes) : '',
      SendInterval: autoSendingInvoices?.Type === KSeFAutomaticOperationType.Interval && autoSendingInvoices?.Minutes ? (autoSendingInvoices?.Minutes / 60) : 0,
      FetchType: autoFetchingInvoices?.Type ?? KSeFAutomaticOperationType.Time,
      AutoFetchingDisabled: AutoFetchingDisabled,
      AutoSendingDisabled: AutoSendingDisabled,
    };
  }, [autoSendingInvoices, autoFetchingInvoices, minutesToTime, getRadioOptionValue, AutoSendingDisabled, AutoFetchingDisabled]);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.ksefSettings}),
    },
  ];

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
        <div className="max-w-[650px]">
          <Header>{intl.formatMessage({id: Tr.ksefSettings})}</Header>
          <div className="mt-[40px]">
             <BackgroundActionsForm defaultValues={defaultValues}/>
          </div>
        </div>
      </div>
    </PageContent>
  );
});
