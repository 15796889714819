import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
  DataSourceHandlerI,
  GetVariablesParams,
} from '../../../../../../../../root/services/TableServices/DataSourceTableService';
import {
  CategoryRuleResponse,
  CategoryViewModel,
  GetCategoryRulesDocument,
  GetCategoryRulesQuery,
  GetCategoryRulesQueryVariables,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {GraphQLErrorWithMessage} from '../../../../../../../../root/providers/GraphQLProvider';
import {l} from '../../../../../../../../logger/loggerInstance';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {TableSortDirection, ToastVariant} from '@symfonia/brandbook';
import {ICategoryDetailsState} from '../state/ICategoryDetailsState';
import {filterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {BuildFilters, BuildSortOption} from '../../../../../../../../common/filters/FilterOptionExtensions';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export type CategoryDetailsRow = CategoryRuleResponse;

export type CategoryDetailsRepositoryI = BaseTableDataRepositoryServiceI<keyof GetCategoryRulesQuery,
    GetCategoryRulesQuery,
    GetCategoryRulesQueryVariables>

export class CategoryDetailsRepository extends BaseTableDataRepositoryService<'GetCategoryRules',
  GetCategoryRulesQuery,
  GetCategoryRulesQueryVariables> {
  constructor(private readonly categoryDetailsState: ICategoryDetailsState) {
    super('GetCategoryRules', GetCategoryRulesDocument, envObserver, earchiveState);
  }

  public setFilters(filterFields: Record<string, filterType>): this {
    return this.configure({Filters: BuildFilters(filterFields)});
  }

  protected override handleEnvIdChange(envId: string | null) {
    envId && this.configure({CompanyId: envId});
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.configure({
      FinancialYearId: this.categoryDetailsState.postingConfigurationState.selectedFinancialYear,
    });
    this.setFilters(this.categoryDetailsState.filterState.getActiveFiltersForQuery);
  }

  protected override onSuccess(data: GetCategoryRulesQuery['GetCategoryRules']): void | Promise<void> {
    const newData = data.Items.map(
      ({...rest}) =>
        ({
          ...rest,
        }) as unknown as CategoryViewModel,
    );

    data && this.categoryDetailsState.setCategories(newData);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    l.error('Error when loading category details table', undefined, errors);

    const {addAlert} = earchiveState.alertsState;
    addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {
      displayDuration: 1000,
      omitIfHasTheSameAlert: true,
    });
    this.categoryDetailsState.setIsLoading(false);
  }
}

export class CategoryDetailsDataSourceHandler
  implements DataSourceHandlerI<'GetCategoryRules', GetCategoryRulesQuery, GetCategoryRulesQueryVariables, CategoryDetailsRow> {
  public readonly repository!: CategoryDetailsRepository;

  constructor(state: ICategoryDetailsState) {
    this.repository = new CategoryDetailsRepository(state);
  }

  public dataMapper(data: GetCategoryRulesQuery['GetCategoryRules'] | null): CategoryDetailsRow[] {
    const newArray = data?.Items.map(
      ({Id, ...rest}) =>
        ({
          Id,
          CompanyId: '',
          ...rest,
        }) as CategoryDetailsRow,
    );

    return newArray ?? [];
  }

  public getTotalCount(data: GetCategoryRulesQuery['GetCategoryRules']): number {
    return data.TotalCount ?? 0;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<GetCategoryRulesQueryVariables> {
    const direction = params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc;
    const name = params.sortBy?.name ?? '';

    return {
      Take: params.size,
      Skip: params.offset,
      Order: BuildSortOption(name, direction),
    };
  }
}
