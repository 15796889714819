import {WebsocketErrorType, WebSocketNotificationFragment} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {ErrorEvent, INotificationErrorService} from './NotificationErrorService';
import {kSeFConnectionErrorService} from './KSeFConnectionErrorService';
import {kSeFAuthorizationErrorService} from './KSeFAuthorizationErrorService';
import {kSeFLimitErrorService} from './KSeFLimitErrorService';
import {kSeFDefaultErrorService} from './KSeFDefaultErrorService';
import {transformKeys} from '../../helpers/transformKeys';
import {EventParams, TransformedEventParams} from '../../SubscriptionEventsService';
import {kSeFSystemErrorService} from './KSeFSystemErrorService';
import {earchiveValidationErrorService} from './EarchiveValidationErrorService';

export const keysTransformers = Object.freeze({
  uppercase: (event: EventParams) => transformKeys<WebSocketNotificationFragment>(event, (key) => key.slice(0, 1).toUpperCase() + key.slice(1)),
  lowercase: (event: WebSocketNotificationFragment | EventParams) => transformKeys<TransformedEventParams>(event, (key) => key.slice(0, 1).toLowerCase() + key.slice(1)),
});

//Zarządza obsługą błędów generycznych przyhcodzących z backendu
//Zaprojektowany pod kontrakt WebsocketErrorType
class NotificationErrorsManager {
  private handlers: Partial<Record<WebsocketErrorType, INotificationErrorService>> = {};

  public add(handler: INotificationErrorService, config?: { override: boolean }): this {
    if (handler.type in this.handlers && !config?.override) {
      return this;
    }
    this.handlers[handler.type] = handler;
    return this;
  }

  public remove(type: WebsocketErrorType): boolean {
    if (type in this.handlers) {
      delete this.handlers[type];
      return true;
    }
    return false;
  }

  public async handle(event: ErrorEvent, opt?: { once?: boolean, fallback?: AlertConfig, historical?: boolean }): Promise<{ alertConfig?: AlertConfig, matched: boolean, ignored: boolean, handled: boolean }> {
    const handler = event.errorType && this.handlers[event.errorType];
    if (!handler) {
      return {alertConfig: opt?.fallback, matched: false, ignored: false, handled: false};
    }
    const alertConfig = opt?.fallback && handler.createAlert(event, opt?.fallback);
    await handler.handle(event, opt);
    const {matched, ignored, handled} = handler;
    return {alertConfig, matched, ignored, handled};
  }

  public get(e: ErrorEvent | (() => ErrorEvent), fallback: AlertConfig = {}): { alertConfig: AlertConfig, matched: boolean, code?: number } {
    const event = typeof e === 'function' ? e() : e;
    const handler = event.errorType && this.handlers[event.errorType];
    if (!handler) {
      return {alertConfig: fallback, matched: false};
    }
    const alertConfig = handler.createAlert(event, fallback);
    const code = handler.getExceptionCode(event.errorContent);
    return {alertConfig, matched: handler.matched, code};
  }
}

export const errorsManager = new NotificationErrorsManager()
  .add(kSeFConnectionErrorService)
  .add(kSeFAuthorizationErrorService)
  .add(kSeFLimitErrorService)
  .add(kSeFDefaultErrorService)
  .add(earchiveValidationErrorService)
  .add(kSeFSystemErrorService);
