import clsx from 'clsx';
import {Children, FC, ReactElement, useEffect, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import {TableRowProps} from './TableRow';
import {TableHeader, TableHeaderProps} from './components/TableHeader';
import {TableSortDirection} from './Table.types';
import {TableRowReflection} from './components/TableRowReflection';
import {TestableElement} from '../../external/types';

export {TableSortDirection} from './Table.types';

export enum TableRowHeight {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type TableSort = {
  name: string;
  direction: TableSortDirection;
};

export type TableProps = {
  sortBy?: TableSort;
  onSortChange?: (sortBy: TableSort) => void;
  header: TableHeaderProps[];
  headerClassName?: string;
  innerClassName?: string;
  children: ReactElement<TableRowProps> | ReactElement<TableRowProps>[];
  className?: string;
  tableRowHeight?: TableRowHeight;
  lastColumnSticky?: boolean;
} & TestableElement;

export const Table: FC<TableProps> = ({
  header,
  headerClassName = '',
  innerClassName = '',
  children,
  tableRowHeight = TableRowHeight.LG,
  className = undefined,
  sortBy = undefined,
  onSortChange = () => undefined,
  lastColumnSticky = false,
  testId = undefined,
}) => {
  useEffect(() => {
    header.forEach(hr => {
      if (header.filter(_hr => _hr.name === hr.name).length > 1) {
        throw new Error('Symfonia: Nazwa kolumny tabeli musi być unikatowa');
      }
    });
  }, [header]);

  const rowStyles = {
    [TableRowHeight.SM]: {
      header: 'text-[12px]',
      column: 'min-h-[32px]',
      cell: 'text-[14px]',
    },
    [TableRowHeight.MD]: {
      header: 'text-[14px]',
      column: 'min-h-[40px]',
      cell: 'text-[14px]',
    },
    [TableRowHeight.LG]: {
      header: 'text-[16px]',
      column: 'min-h-[48px]',
      cell: 'text-[16px]',
    },
  };

  const widthClassNames: string[] = header.map(({width}) =>
    width !== undefined && /^w-.*?/.test(width) ? width : `wp-${Math.round(100 / header.length)}`,
  );

  const styles = {
    component: twMerge(clsx('overflow-auto border border-solid border-grey-100 rounded-[2px]', className)),
    table: twMerge(clsx('flex min-w-full w-fit flex-col', innerClassName)),
    column: `grow shrink leading-[24px] pl-[16px] items-center inline-flex ${rowStyles[tableRowHeight].column}`,
    header: twMerge(
      clsx(`flex bg-grey-50 font-quicksand font-bold`, rowStyles[tableRowHeight].header, headerClassName),
    ),
    headerStickyLastColumnElement:
      'sticky right-0 bg-grey-50 before:shadow-[-20px_0_10px_-10px_inset] before:shadow-grey-50 before:h-full before:right-[calc(100%-1px)] before:w-[40px] before:absolute',
    rowStickyLastColumn:
      'last:sticky last:right-0 last:bg-white before:last:shadow-[-20px_0_10px_-10px_inset] before:last:shadow-white before:last:h-full before:last:right-[calc(100%-1px)] before:last:w-[40px] before:last:absolute',
    cell: rowStyles[tableRowHeight].cell,
  };

  const rows = useMemo(
    () =>
      Children.map(children, child => (
        <TableRowReflection
          {...child.props}
          widths={widthClassNames}
          columnClassName={twMerge(
            clsx(
              styles.column,
              styles.cell,
              {[styles.rowStickyLastColumn]: lastColumnSticky},
              child.props.columnClassName,
            ),
          )}
          header={header}
        />
      )),
    [children],
  );

  const handleSortChange = (name: string, defaultDirection: TableSortDirection) => {
    if (!onSortChange) {
      return;
    }
    if (sortBy && sortBy.name === name) {
      if (sortBy.direction === TableSortDirection.ASC) {
        onSortChange({name, direction: TableSortDirection.DESC});
      } else {
        onSortChange({name, direction: TableSortDirection.ASC});
      }
    } else {
      onSortChange({name, direction: defaultDirection});
    }
  };

  return (
    <div className={styles.component} data-testid={testId} data-test-element="table">
      <div className={styles.table}>
        <div className={clsx(styles.header, {hidden: header.every(el => !el.title?.length)})}>
          {header.map(({className: headerColClassName, ...headerProps}, index) => {
            const direction = sortBy !== undefined && headerProps.sortable ? sortBy.direction : undefined;
            return (
              <TableHeader
                key={headerProps.name}
                className={clsx(
                  styles.column,
                  widthClassNames[index],
                  {
                    [styles.headerStickyLastColumnElement]: lastColumnSticky && index === header.length - 1,
                  },
                  headerColClassName,
                )}
                {...headerProps}
                onSortChange={() =>
                  handleSortChange(headerProps.name, headerProps.defaultSortDirection || TableSortDirection.DESC)
                }
                direction={sortBy && sortBy.name === headerProps.name ? direction : undefined}
              />
            );
          })}
        </div>
        <div>{rows}</div>
      </div>
    </div>
  );
};
