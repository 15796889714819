import {Tr} from '../../../locales/translationKeys';
import {GraphQLErrorWithMessage} from '../../root/providers/GraphQLProvider';

export function getPackageEndingTooltip(isPackageUsed: boolean, subscriptionIsActive: boolean | null, invoicePackageIsEnding: boolean, storagePackageIsEnding: boolean, packageDateIsEnding: boolean | null) {
  if (!subscriptionIsActive) {
    return {
      header: Tr.usageIconExpired_Tooltip_Header,
      content: Tr.usageIconExpired_Tooltip_Content,
    };
  }
  if (isPackageUsed) {
    return {
      header: Tr.usageIconUsed_Tooltip_Header,
      content: Tr.usageIconUsed_Tooltip_Content,
    };
  }
  if (invoicePackageIsEnding && storagePackageIsEnding && packageDateIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageAllConditionsExpired_Tooltip_Content,
    };
  }
  if (invoicePackageIsEnding && storagePackageIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconInvoiceLimitAndDiskSpaceLimit_Tooltip_Content,
    };
  }
  if (invoicePackageIsEnding && packageDateIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconExpiryDateAndInvoiceLimit_Tooltip_Content,
    };
  }
  if (storagePackageIsEnding && packageDateIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconExpiryDateAndDiskSpaceLimit_Tooltip_Content,
    };
  }
  if (invoicePackageIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconInvoiceLimit_Tooltip_Content,
    };
  }
  if (storagePackageIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconDiskSpaceLimit_Tooltip_Content,
    };
  }
  if (packageDateIsEnding) {
    return {
      header: Tr.usageIconExpiring_Tooltip_Header,
      content: Tr.usageIconExpiryDate_Tooltip_Content,
    };
  }
  return null;
}

export function convertToGB(space: number | undefined) {
  if (space === undefined) return;
  return space / 1000;
}

export function getGraphqlBackendError(errors: GraphQLErrorWithMessage[]): string | undefined {
  return errors.find(err => err.extensions.code?.toLowerCase() === 'validation')?.extensions?.data?.['-empty-']?.[0];
}
