import {IEarchiveStateMobx} from '../../../IEArchiveStateMobx';
import {IDocumentsState} from './IDocumentsState';
import {IInvoicePageState} from './IInvoicePageState';
import {InvoicePageState} from './InvoicePageState';

export class DocumentsState implements IDocumentsState {
  private _invoicePageState: IInvoicePageState | undefined;
  public get invoicePageState(): IInvoicePageState {
    if (this._invoicePageState === undefined) {
      this._invoicePageState = new InvoicePageState(this);
    }
    return this._invoicePageState;
  }

  public earchiveState: IEarchiveStateMobx;

  constructor(rootState: IEarchiveStateMobx) {
    this.earchiveState = rootState;
  }
}
