import {Skeleton} from '@mui/material';

export type UploaderSkeletonProps = { loading: boolean }

export const UploaderSkeleton: FCC<UploaderSkeletonProps> = ({loading, children}) => {
  return <div className="mt-[32px] mb-[16px] mx-auto w-full max-w-[620px]">
    {loading ?
      <>
        <Skeleton variant="text" height={40} width="40%" sx={{
          margin: '0 auto',
        }}/>
        <Skeleton variant="text" height={20}/>
        <Skeleton variant="text" height={280}/>
      </>
      : children
    }
  </div>;
};
