import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
  DataSourceHandlerI,
  GetVariablesParams,
} from '../../../../../../../../root/services/TableServices/DataSourceTableService';
import {
  GetSettlementsDocument,
  GetSettlementsQuery,
  GetSettlementsQueryVariables,
  SettlementResponse,
} from '@symfonia-ksef/graphql';
import {ToastVariant} from '@symfonia/brandbook';
import {GraphQLErrorWithMessage} from '../../../../../../../../root/providers/GraphQLProvider';
import {l} from '../../../../../../../../logger/loggerInstance';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ISettlementsState} from './ISettlementsState';
import {envObserver, earchiveState} from '@symfonia-ksef/state/rootRepository';

export type SettlementsRow = SettlementResponse;

export type SettlementsRepositoryI = BaseTableDataRepositoryServiceI<keyof GetSettlementsQuery, GetSettlementsQuery, GetSettlementsQueryVariables>

export class SettlementsRepository extends BaseTableDataRepositoryService<'GetSettlements', GetSettlementsQuery, GetSettlementsQueryVariables> implements SettlementsRepositoryI {
  constructor(private readonly settlementsState: ISettlementsState) {
    super('GetSettlements', GetSettlementsDocument, envObserver, earchiveState);
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.configure({
      FinancialYearId: this.settlementsState.postingConfigurationState.selectedFinancialYear,
      InvoiceTypeGroup: this.settlementsState.postingConfigurationState.invoiceTypeGroup,
    });
  }

  protected override handleEnvIdChange(envId: string | null): void {
    super.handleEnvIdChange(envId);
    envId && this.configure({CompanyId: envId});
  }

  protected override onSuccess(data: GetSettlementsQuery['GetSettlements']): void | Promise<void> {
    data && this.settlementsState.setSettlements(data.Settlements as SettlementsRow[]);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    l.error('Error when loading settlements table', undefined, errors);
    const {addAlert} = earchiveState.alertsState;
    addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {
      displayDuration: 10000,
      omitIfHasTheSameAlert: true,
    });
    this.settlementsState.setIsLoading(false);
  }
}

export class SettlementsDataSourceHandler implements DataSourceHandlerI<'GetSettlements', GetSettlementsQuery, GetSettlementsQueryVariables, SettlementsRow> {
  public readonly repository!: SettlementsRepositoryI;

  constructor(state: ISettlementsState) {
    this.repository = new SettlementsRepository(state);
  }

  public dataMapper(data: GetSettlementsQuery['GetSettlements'] | null) {
    return (data?.Settlements ?? []) as Array<SettlementsRow>;
  }

  public getTotalCount(data: GetSettlementsQuery['GetSettlements']): number {
    return data.Settlements.length;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<GetSettlementsQueryVariables> {
    return {};
  }
}
