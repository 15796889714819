import {Icon, IconColor, IconProps, IconSize, IconSvg} from '@symfonia/brandbook';

export interface RowActionsButtonProps extends Partial<IconProps> {
  testId?: string;
}

export const RowActionsButton = ({testId, ...props}: RowActionsButtonProps) => {
  return (
    <Icon
        svg={IconSvg.MORE_VERT}
        size={IconSize.LG}
        color={IconColor.PRIMARY_500}
        className={`hover:rotate-90 ease-in duration-150 ${props.className}`}
        onClick={props.onClick}
        testId={testId}
        {...props}
    />
  );
};
