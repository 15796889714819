import clsx from 'clsx';
import {FC, ReactElement, ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {DropdownListProps} from '../Dropdown/DropdownList';
import {Badge, BadgeSize} from '../Badge/Badge';
import {ChildComponentWithDropdown, ComponentWithDropdown, WithDropdownList} from '../Dropdown/WithDropdownList';
import {Icon, IconSize} from '../Icon/Icon';
import {TestableElement} from '../../external/types';

export type TabProps = {
  isActive?: boolean;
  disabled?: boolean;
  text?: ReactNode;
  href?: string;
  badge?: string;
  onClick?: () => void;
  children?: ReactElement<DropdownListProps>;
  className?: string;
} & TestableElement;

export const TabInternal: FC<ChildComponentWithDropdown<TabProps>> = ({
  isActive = false,
  disabled = undefined,
  text = undefined,
  href = undefined,
  badge = undefined,
  onClick = () => undefined,
  children: dropdown = undefined,
  dropdownIcon = undefined,
  dropdownIsOpen = false,
  className = undefined,
  testId = undefined,
}) => {
  const styles = {
    component: (active: boolean) =>
      twMerge(
        clsx(
          'z-[1] relative group font-quicksand inline-flex items-center justify-center',
          {
            'border-b-[2px] -bottom-[1px] border-b-solid border-b-primary-500': !disabled && (active || dropdownIsOpen),
            'cursor-pointer': !disabled && !active,
            'border-b-[1px] -bottom-[1px] border-b-solid border-b-grey-200 w-full grow':
              !disabled && !active && !dropdownIsOpen,
          },
          className,
        ),
      ),
    hoverable: (active: boolean) =>
      clsx(
        'inline-flex relative items-center p-[8px] rounded-[4px]',

        {
          'focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500': !disabled,
          'cursor-default': disabled || active,
          'bottom-[1px]': !disabled && (active || dropdownIsOpen),
        },
      ),
    icon: (active: boolean) =>
      clsx('ml-[8px]', {
        'filter-grey-900': !disabled && !active && !dropdownIsOpen,
        'filter-primary-500': !disabled && (active || dropdownIsOpen),
        'group-hover:filter-primary-500': !disabled,
        'filter-grey-500': disabled,
      }),
    badge: 'ml-[6px]',
    text: (active: boolean) =>
      clsx('text-[16px] leading-[24px]', {
        'text-grey-900': !disabled && !active && !dropdownIsOpen,
        'text-primary-500 font-bold': !disabled && (active || dropdownIsOpen),
        'group-hover:text-primary-500': !disabled,
        'text-grey-500': disabled,
      }),
  };

  const renderButton = () => (
    <span className={styles.component(isActive)} data-test-element="tab" data-testid={testId}>
      <span
        className={styles.hoverable(isActive)}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onClick();
          }
        }}
        data-testid="tab-hoverable"
      >
        <span className={styles.text(isActive)} data-testid="tab-text">
          {text}
        </span>
        {dropdownIcon && <Icon svg={dropdownIcon} size={IconSize.MD} className={styles.icon(isActive)} />}
        {badge && <Badge className={styles.badge} text={badge} size={BadgeSize.MD} />}
      </span>
    </span>
  );

  const renderLink = () => {
    return (
      <NavLink
        end
        to={href!}
        className={() => styles.component(isActive)}
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            onClick();
          }
        }}
        data-testid="tab-component-link"
      >
        {() => {
          return (
            <span className={styles.hoverable(isActive)}>
              <span className={styles.text(isActive)}>{text}</span>
              {badge && <Badge className={styles.badge} text={badge} size={BadgeSize.MD} />}
            </span>
          );
        }}
      </NavLink>
    );
  };

  return typeof href === 'string' && href.length > 0 ? renderLink() : renderButton();
};

export const Tab: FC<ComponentWithDropdown<TabProps>> = ({dropdown, ...props}) => (
  <WithDropdownList
    className="shrink-0"
    dropdown={props.href ? undefined : dropdown}
    disabled={!!props.href || !!props.disabled}
  >
    <TabInternal {...props} />
  </WithDropdownList>
);
