import {FC, useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {usePrevious} from '@symfonia/symfonia-ui-components';
import {CategoryAccountsFiltersConfig} from './filters/CategoryAccountsFiltersConfig';
import {Tr} from '../../../../../../../../../locales/translationKeys';
import {Loader} from '../../../../../../../../common/components/Loader/Loader';
import {CategoryAccountsTableMenu} from './CategoryAccountsTableMenu';
import {observer} from 'mobx-react-lite';
import _ from 'lodash';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {ButtonSecondary, ButtonSecondarySize, IconSvg} from '@symfonia/brandbook';
import {CategoryAccountService} from './CategoryAccount.service';
import {FiltersDrawer} from '@symfonia/symfonia-ksef-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface CategoryAccountsTableProps {
  state: ICategoryAccountState;
  pageService: CategoryAccountService;
}

export const CategoryAccountsFilters: FC<CategoryAccountsTableProps> = observer(({pageService, state}) => {
  const {
    company: {companyId},
  } = earchiveState;
  const intl = useIntl();
  const filterState = state.filterState;
  const [mainFilterMenuOpen, setMainFilterMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    state.filterState.getFilters();
  }, []);
  const handleFilter = useCallback(() => {
    setMainFilterMenuOpen(false);
    filterState?.startFiltering();
    filterState.storage.saveToStorage(filterState.activeFilters);
    pageService.repository.fetch();
  }, [filterState, pageService]);

  if (!companyId || !filterState) {
    return <Loader/>;
  }
  const previousFilters = usePrevious(filterState.getActiveFilters);
  const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(filterState.getChangedFilters));

  return (
    <>
      <CategoryAccountsTableMenu service={pageService} state={state}/>
      {mainFilterMenuOpen && (
        <FiltersDrawer
          hasError={filterState.hasError}
          isFiltersMenuOpened={mainFilterMenuOpen}
          openFiltersMenu={v => setMainFilterMenuOpen(v)}
          handleFilter={handleFilter}
          clearFilters={filterState.handleClearMenuFilter}
          tableLabel={intl.formatMessage({id: Tr.tableLabel})}
          closeLabel={intl.formatMessage({id: Tr.closeLabel})}
          filterLabel={intl.formatMessage({id: Tr.filterLabel})}
          clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
          filtersTouched={wasFilterChanged}
          filtersModified={wasFilterChanged}
          onClose={() => {
            setMainFilterMenuOpen(false);
            filterState.resetFilterToStorage();
          }}
        >
          <CategoryAccountsFiltersConfig state={state}/>
        </FiltersDrawer>
      )}
      <ButtonSecondary
        disabled={filterState.hasError}
        lIcon={IconSvg.FILTER_LIST}
        onClick={() => setMainFilterMenuOpen(true)}
        size={ButtonSecondarySize.SM}
      />
    </>
  );
});
