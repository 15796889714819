import {cloneElement, ReactElement, useRef, useState} from 'react';
import clsx from 'clsx';
import {IconSvg} from '@symfonia/brandbook';
import {DropdownList, DropdownListProps} from './DropdownList';

export type WithDropdownListProps<T = any> = {
  dropdown?: DropdownListProps<T>;
  disabled?: boolean;
  children: ReactElement;
  className?: string;
  onChange?: (selectedValues: React.Key[]) => void;
};

export const WithDropdownList = <T extends any = any>({
                                                        dropdown = undefined,
                                                        disabled = false,
                                                        children,
                                                        className = undefined,
                                                        onChange,
                                                      }: WithDropdownListProps<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const dropdownIcon: IconSvg | null = (() => {
    if (dropdown) {
      return !disabled && isOpen ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN;
    }
    return null;
  })();

  const handleClick = () => {
    if (!disabled && dropdown) {
      setIsOpen(!isOpen);
    }
  };

  const renderList = () =>
    dropdown && (
      <DropdownList
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onChange={onChange}
        className="mt-[8px]"
        anchorEl={ref}
        selectable={false}
        listWithTooltip={true}
        {...dropdown}
      />
    );

  const renderChildren = () => {
    return cloneElement(children, {
      dropdownIcon,
      dropdownIsOpen: isOpen,
    });
  };

  return (
    <span ref={ref} className={clsx(className, 'w-fit h-fit')} onClick={handleClick}>
      {renderList()}
      {renderChildren()}
    </span>
  );
};
