import {
  PublishInvoicesDocument,
  PublishInvoicesMutation,
  PublishInvoicesMutationVariables, PublishInvoicesResponse,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const PublishInvoicesMutationFunction = async (
  companyId: string,
  invoiceIds?: string[],
): Promise<PublishInvoicesResponse | undefined> =>
  await apolloClient
    .mutate<PublishInvoicesMutation, PublishInvoicesMutationVariables>({
      mutation: PublishInvoicesDocument,
      context: {
        envId: companyId,
      },
      variables: {
        InvoiceIds: invoiceIds,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }

      return response?.data?.PublishInvoices;
    });
