import {
  CategoryTypeEnum,
  GetCategoryAccountsFilterDataDocument,
  GetCategoryAccountsFilterDataQuery,
  GetCategoryAccountsFilterDataQueryVariables,
  InvoiceTypeGroupEnum
} from "../../../../../../../../../../graphql-hot-chocolate/graphql";
import {apolloClient} from "../../../../../../../../../root/providers/GraphQLProvider";

export const GetCategoriesAccountsQueryFunction = async (
  companyId: string,
  financialYearId: string,
  type: CategoryTypeEnum[],
  invoiceTypeGroup: InvoiceTypeGroupEnum) =>
  await apolloClient
    .query<GetCategoryAccountsFilterDataQuery, GetCategoryAccountsFilterDataQueryVariables>({
      query: GetCategoryAccountsFilterDataDocument,
      context: {
        envId: companyId,
      },
      variables: {
        Type: type,
        InvoiceTypeGroup: invoiceTypeGroup,
        CompanyId: companyId,
        FinancialYearId: financialYearId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return {
        Categories: response.data.Categories?.items,
        Accounts: response.data.Accounts?.items?.flatMap(a => a.Accounts)
      };
    });
