import {action, makeObservable, observable} from "mobx";
import {IBaseModalState} from "./IBaseModalState";

export abstract class BaseModalState implements IBaseModalState {
  @observable
  isOpen: boolean = false;
  
  constructor() {
    makeObservable(this);
  }
  
  @action
  handleOpen(): void {
    this.isOpen = true;
  }

  @action
  handleClose(): void {
    this.isOpen = false;
  }

  abstract handleSave(): void
}