import {intl} from '../../../../../root/IntlProvider';

export const VatRegistryTableColumnsConfig = () => {
  return [
    {
      field: 'VatRegisterName',
      headerName: intl.formatMessage({id: 'vatRegistry'}),
      width: 'w-[35%]',
    },
    {
      field: 'TaxRate',
      headerName: intl.formatMessage({id: 'vatRate'}),
    },
    {
      field: 'Net',
      headerName: intl.formatMessage({id: 'net'}),
    },
    {
      field: 'Vat',
      headerName: intl.formatMessage({id: 'vatValue'}),
    },
    {
      field: 'Gross',
      headerName: intl.formatMessage({id: 'GrossValue'}),
    },
    {
      field: 'GTUItems',
      headerName: intl.formatMessage({id: 'gtu'}),
    },
  ];

};
