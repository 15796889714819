import {
  InitializationStatusEnum,
  InitializationTaskTypeEnum,
  ModuleSubscriptionStatusEnum
} from '@symfonia-ksef/graphql';
import {ModulesEnum} from '../../modules/common/enums/ModulesEnum';
import {IModuleStatus} from './IModuleStatus';

export class ModuleStatus implements IModuleStatus {
  public moduleType: ModulesEnum;

  public subscriptionsStatus: ModuleSubscriptionStatusEnum;

  public initializationStatus: InitializationStatusEnum;

  public initializationTaskType: InitializationTaskTypeEnum;

  public errorDetails: string | undefined;

  constructor(
    name: ModulesEnum,
    subscriptionsStatus: ModuleSubscriptionStatusEnum,
    initializationStatus: InitializationStatusEnum,
    initializationTaskType: InitializationTaskTypeEnum,
    errorDetails: string | undefined
  ) {
    this.moduleType = name;
    this.subscriptionsStatus = subscriptionsStatus;
    this.initializationStatus = initializationStatus;
    this.initializationTaskType = initializationTaskType;
    this.errorDetails = errorDetails;
  }

  public get isModulePurchased() {
    return this.subscriptionsStatus === ModuleSubscriptionStatusEnum.Subscribed;
  }

  public get isModuleInitialized() {
    return this.initializationStatus === InitializationStatusEnum.InitializationCompleted;
  }
}
