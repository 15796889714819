import {ICreateCategoryModalState} from '../../state/ICreateCategoryModalState';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../../../../../locales/translationKeys';
import {Text} from '../../../../../../root/components/Text';
import {Input, InputWidth} from '@symfonia/brandbook';

export interface CreateCategoryFirstStepProps {
  state: ICreateCategoryModalState;
}

export const CreateCategoryFirstStep = observer(({state}: CreateCategoryFirstStepProps) => {
  const intl = useIntl();

  const hasErrors = () => !!(state.categoryName && !state.checkFirstStepRequirements);

  return (
    <div className="flex flex-col gap-3 w-full">
      <Text>
        <FormattedMessage id={Tr.addCategoryDescription}/>
      </Text>
      <Input
        label={intl.formatMessage({id: Tr.category})}
        onBlur={({target}) => target.focus()}
        onChange={value => {
          state.setCategoryName(value);
        }}
        width={InputWidth.FULL}
        defaultValue=""
        notification={hasErrors() ? intl.formatMessage({id: Tr.categoryAlreadyExist}) : undefined}
        isError={hasErrors()}
        placeholder={intl.formatMessage({id: Tr.categoryName})}
      />
      <Input
        disabled
        label={intl.formatMessage({id: Tr.PositionName})}
        value={state.positionName}
        width={InputWidth.FULL}
      />
    </div>
  );
});
