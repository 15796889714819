import {
  AccountResponse,
  AccountTypeEnum,
  GetAccountsForPostingDocument,
  GetAccountsForPostingQuery,
  GetAccountsForPostingQueryVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetAccountsForPostingQueryFunction = async (
  decreeId: string,
  companyId: string,
  accountType: AccountTypeEnum
): Promise<AccountResponse[]> => {
  return await apolloClient
    .query<GetAccountsForPostingQuery, GetAccountsForPostingQueryVariables>({
      query: GetAccountsForPostingDocument,
      context: {
        envId: companyId,
      },
      variables: {
        DecreeId: decreeId,
        CompanyId: companyId,
        AccountType: accountType
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetAccountsForPosting.AccountsPlan as AccountResponse[];
    });
};
