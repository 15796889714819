import * as React from 'react';

export const useIsOverflowing = <T extends HTMLElement>(
  ref: React.RefObject<T | undefined>,
  callback?: () => void,
) => {
  const [isOverflowing, setIsOverflow] = React.useState(false);
  React.useLayoutEffect(() => {
    if (ref === undefined) return;    
    
    const {current} = ref;

    const check = () => {
      if (!current) return;

      const hasOverflow = current.scrollHeight > current.clientHeight;
      setIsOverflow(hasOverflow);

      callback?.();
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(check).observe(current);
      }

      check();
    }
  }, [callback, ref]);

  return isOverflowing;
};
