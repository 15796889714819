import {BaseExtendedTableServiceI, BaseTableService, Columns, KeyFactory, OnSortByChange} from './BaseTableService';
import {AnyObject} from 'yup/es/types';
import {PaginationConfig} from '../PaginationServices/PaginationService';
import {
  BaseTableDataRepositoryServiceI,
  BaseVariables,
  DataSourceHandlerI,
  DataSourceTableService,
} from './DataSourceTableService';
import {PaginationTableLifeCycleI, PaginationTableService} from './PaginationTableService';
import {SelectionFactory, SelectionTableService} from './SelectionTableService';
import {TableSort} from '@symfonia/brandbook';

import {ExtendedTableService, TableServices} from './ExtendedTableService';
import {isEmpty} from 'ramda';
import {ClickableConfig, ClickableTableService} from './ClickableTableService';
import {Filter, FilterFactory, FiltersTableService} from './FiltersTableService';


//klasa służąca do budowowy i konfiguracji serwisu tabeli oraz jej wszystkich funkcjonalności
export class TableBuilder<Row extends AnyObject, Key extends string = string, Data extends AnyObject = AnyObject, Variables extends BaseVariables = BaseVariables, Context extends AnyObject = AnyObject> {
  private services: Partial<TableServices<Row, Key, Data, Variables, Context>> = {};
  private _sortByPersistKey?: string;
  private _onSortByChange?: OnSortByChange;

  protected constructor() {
  }

  public get enabled(): Record<keyof TableServices<Row, Key, Data, Variables, Context>, boolean> {
    return {
      pagination: !!this.services.pagination,
      dataSource: !!this.services.dataSource,
      selection: !!this.services.selection,
      clickableRow: !!this.services.clickableRow,
      filter: !!this.services.filter,
      repository: !!this.services.repository,
      context: !!this.services.context,
    };
  }

  private _columns: Columns<Row, Context> = {};

  protected get columns(): Columns<Row, Context> {
    if (isEmpty(this._columns)) {
      console.warn('columns is empty');
    }
    return this._columns;
  }

  private _keyFactory?: KeyFactory<Row>;

  protected get keyFactory() {
    this.throwErrorIfKeyFactoryIsDisabled();
    return this._keyFactory as KeyFactory<Row>;
  }

  private _sortBy?: TableSort;

  protected get sortBy(): TableSort | undefined {
    return this._sortBy;
  }

  private _baseTable?: BaseExtendedTableServiceI<Row, Context>;

  protected get baseTable() {
    this.throwErrorIfBaseTableIsDisabled();
    return this._baseTable as BaseExtendedTableServiceI<Row, Context>;
  }

  //Tworzy intancję buildera tabeli o podanych typach generycznych
  //Row - model wiersza tabeli
  //Data - model danych zwracanych z backendu
  //Variables = model zmiennych przekazywanych do requestu o dane
  //Key - klucz danych zwracanych z backendu zapisywanych do stanu
  //Context - obiekt będący kontekstem tabeli dostępny przy renderowaniu komórek tabeli i headerów (mi. w parametrze metody cell)
  public static create<Row extends AnyObject, Key extends string = string, Data extends AnyObject = AnyObject, Variables extends BaseVariables = BaseVariables, Context extends AnyObject = AnyObject>(): TableBuilder<Row, Key, Data, Variables, Context> {
    return new TableBuilder<Row, Key, Data, Variables, Context>();
  }

  //podłącza konfigurację kolumn tabeli
  public connectColumns(columns: Columns<Row, Context>): this {
    this._columns = columns;
    return this;
  }

  //podłącza do tabeli funkcję filtrowania po stronie frontendu
  public connectFilter(filter: Filter<Row, Context> | FilterFactory<Row, Context>, isActive: boolean = false): this {
    this.services.filter = new FiltersTableService<Row, Context>(this.buildBase(), filter, isActive);
    return this;
  }

  //podłącza obowiązkową fabrykę unikalnych kluczy dla wierszy
  public connectKeyFactory(keyFactory: KeyFactory<Row>): this {
    this._keyFactory = keyFactory;
    return this;
  }

  //podłącza kontekst tabeli (dowolny obiekt dostępny przy renderowaniu komórek w metodzie cell)
  public connectContext(context: Context): this {
    this.services.context = context;
    return this;
  }

  //podłącza domyślne funkcjonalność sortowania tabeli oraz synchronizacje sortowania z localsotrage
  public connectSortBy(config: { sortBy?: TableSort, persistKey?: string, onSortByChange?: OnSortByChange }): this {
    this._sortBy = config?.sortBy;
    this._sortByPersistKey = config?.persistKey;
    this._onSortByChange = config?.onSortByChange;
    return this;
  }


  //buduje podstawową tabelę (bez rozrzeczeń, sama tabela)
  public buildBase(override: boolean = false) {

    if (override || !this._baseTable) {
      this._baseTable = new BaseTableService<Row, Context>({
        columns: this.columns,
        keyFactory: this.keyFactory,
        sortBy: this.sortBy,
        context: this.services.context,
        persistKey: this._sortByPersistKey,
        onSortByChange: this._onSortByChange,
      });
    }
    return this.baseTable;
  }

  //buduje rozszerzoną wersję tabeli z podłączonymi wcześniej funkcjonalnościami
  public buildExtended() {
    return new ExtendedTableService<Row, Key, Data, Variables, Context>(this.buildBase(), this.services);
  }

  //podłącza do tabeli źródło danych, synchronizuje stan tabeli ze źródłęm danych
  public connectDataSource(dataSourceComponents: DataSourceHandlerI<Key, Data, Variables, Row>) {
    this.connectRepository(dataSourceComponents.repository);
    this.services.dataSource = new DataSourceTableService<Key, Data, Variables, Row>(this.buildBase(), dataSourceComponents);
    return this;
  }

  //podłącza funkcję aktywnego/klikalnego wiersza tabeli
  public connectClickableRow(config?: Partial<ClickableConfig<Row, Context>>): this {
    this.services.clickableRow = new ClickableTableService<Row, Context>(this.buildBase(), config);
    return this;
  }

  //podłącza do tabeli paginację, synchronizuje ją ze stanem tabeli
  public connectPagination(config?: PaginationConfig & { persistKey?: string, lifeCycle?: PaginationTableLifeCycleI }) {
    const {lifeCycle, persistKey, ...restConfig} = config ?? {};
    this.services.pagination = new PaginationTableService<Row, Context>(this.buildBase(), persistKey, lifeCycle, restConfig);
    return this;
  }

  //podłącza do tabeli funkcję wybierania/zaznaczania wierszy
  public connectSelection(selectionConfigFactory?: SelectionFactory<Row>) {
    this.services.selection = new SelectionTableService<Row, Context>(this.buildBase(), selectionConfigFactory);
    return this;
  }

  //podłącza repozytorium
  private connectRepository(repository: BaseTableDataRepositoryServiceI<Key, Data, Variables>): this {
    this.services.repository = repository;
    return this;
  }

  private throwErrorIfKeyFactoryIsDisabled() {
    if (!this._keyFactory) {
      throw Error('key factory must be created');
    }
  }

  private throwErrorIfBaseTableIsDisabled() {
    if (!this._baseTable) {
      throw Error('base table must be created');
    }
  }


}
