(() => {
  const pushState = history.pushState;
  const replaceState = history.replaceState;

  history.pushState = (...args: Parameters<typeof pushState>) => {
    pushState.apply(history, args);
    window.dispatchEvent(new Event('locationChange'));
  };

  history.replaceState = (...args: Parameters<typeof replaceState>) => {
    replaceState.apply(history, args);
    window.dispatchEvent(new Event('locationChange'));
  };

  window.addEventListener('popstate', function () {
    window.dispatchEvent(new Event('locationChange'));
  });
})();
