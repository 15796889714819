import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {ErrorContent} from '../../../SubscriptionEventsService';
import {ErrorEvent} from '../NotificationErrorService';

export interface ErrorEventMapperI<P> {
  type: WebsocketErrorType;

  map(error: P): ErrorEvent;
}

export type ErrorEventAdapterI = Pick<ErrorEvent, 'errorType' | 'errorContent'>

export class ErrorEventAdapter<T> implements ErrorEventAdapterI {

  private errorEvent: ErrorEvent;

  constructor(protected error: T, protected mapper?: ErrorEventMapperI<T>) {
    this.errorEvent = this.mapper?.map?.(this.error) ?? {};
  }

  public get errorType(): WebsocketErrorType | undefined {
    return this.errorEvent.errorType;
  }

  public get errorContent(): ErrorContent | undefined {
    return this.errorEvent.errorContent;
  }
}

