import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {NavLink} from 'react-router-dom';

import {ButtonTertiary} from '../Button/ButtonTertiary';
import {Icon, IconSize, IconSvg} from '../Icon/Icon';
import {TestableElement} from '../../external/types';

export type Breadcrumb = {
  label: string;
  href?: string;
  onClick?: () => void;
};

export type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
  maxDepth?: number;
  className?: string;
  onClick?: (breadcrumb: Breadcrumb, index: number) => void;
} & TestableElement;

type BreadcrumbWithKey = [Breadcrumb, string];

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs = [],
  maxDepth = 5,
  className = '',
  onClick = (_breadcrumb: Breadcrumb, _index: number) => undefined,
  testId = undefined,
}) => {
  const breadcrumbsWithKey = useMemo<BreadcrumbWithKey[]>(
    () => breadcrumbs.map(o => [o, crypto.randomUUID()]),
    [breadcrumbs],
  );

  const styles = {
    component: clsx(className, 'flex items-center w-full text-sm'),
    breadcrumb: (isCurrent: boolean) =>
      clsx('flex items-center group', {
        'cursor-default pointer-events-none': isCurrent,
        'cursor-pointer': !isCurrent,
      }),
    label: (isCurrent: boolean) =>
      clsx('font-quicksand font-medium leading-[16px] whitespace-nowrap overflow-hidden overflow-ellipsis', {
        'text-grey-500': isCurrent,
        'group-hover:text-primary-400': !isCurrent,
      }),
    icon: (isCurrent: boolean, isCompact: boolean) =>
      clsx('scale-[.80]', {
        'filter-grey-500': isCurrent && !isCompact,
        'filter-grey-800': isCompact,
        'filter-primary-500 group-hover:filter-primary-400': !isCurrent,
      }),
  };

  const renderBreadcrumb = (breadcrumb: Breadcrumb, key: string, index: number, isCompact = false) => {
    const isCurrent = index === breadcrumbs.length - 1;

    return (
      <span
        data-test-element="breadcrumbs"
        data-testid={testId}
        key={key}
        onClick={() => {
          if (!isCurrent) {
            if (onClick) {
              onClick(breadcrumb, index);
            }
            if (breadcrumb.onClick) {
              breadcrumb.onClick();
            }
          }
        }}
        className={styles.breadcrumb(isCurrent)}
      >
        {!breadcrumb?.href && <span className={styles.label(isCurrent)}>{breadcrumb.label}</span>}
        {breadcrumb.href && (
          <NavLink className={styles.label(isCurrent)} to={breadcrumb.href}>
            {breadcrumb.label}
          </NavLink>
        )}

        {!isCurrent && (
          <Icon size={IconSize.LG} svg={IconSvg.CHEVRON_RIGHT} className={styles.icon(isCurrent, isCompact)} />
        )}
      </span>
    );
  };

  const renderFull = () =>
    breadcrumbsWithKey.map(([breadcrumb, key], index) => renderBreadcrumb(breadcrumb, key, index));

  const [firstBreadcrumb, firstKey] = breadcrumbsWithKey[0] || [];
  const lastIndex = breadcrumbsWithKey.length - 1;
  const [lastBreadcrumb, lastKey] = breadcrumbsWithKey[lastIndex] || [];

  const handleDropdownOnChange = (breadcrumbIndex: number) => {
    onClick(breadcrumbs[breadcrumbIndex], breadcrumbIndex);
  };

  const renderCompact = () => {
    return (
      <>
        {renderBreadcrumb(firstBreadcrumb, firstKey, 0, true)}
        ...
        <ButtonTertiary
          rIcon={IconSvg.CHEVRON_RIGHT}
          className="scale-[.80]"
          dropdown={{
            options: breadcrumbs.slice(1, breadcrumbs.length - 1).map((breadcrumb, breadcrumbIndex) => ({
              ...breadcrumb,
              value: `${breadcrumbIndex}`,
            })),
            onChange: ([breadcrumbIndex]) => handleDropdownOnChange(parseInt(breadcrumbIndex, 10)),
          }}
        />
        {renderBreadcrumb(lastBreadcrumb, lastKey, lastIndex)}
      </>
    );
  };

  return <div className={styles.component}>{breadcrumbs.length <= maxDepth ? renderFull() : renderCompact()}</div>;
};
