import {CategoryTypesFilterOptions} from '../models/CategoryTypesFilterOptions';
import {CategoryTypeEnum} from '@symfonia-ksef/graphql';

export const mapCategoryTypeToActiveFilters = (option: CategoryTypesFilterOptions) => {
  switch (option) {
    case CategoryTypesFilterOptions.All:
      return [CategoryTypeEnum.Default, CategoryTypeEnum.Custom];
    case CategoryTypesFilterOptions.Default:
      return [CategoryTypeEnum.Default];
    case CategoryTypesFilterOptions.Custom:
      return [CategoryTypeEnum.Custom];
    default:
      throw new Error('not implemented');
  }
};

export const mapCategoryTypesToOptions = (types: CategoryTypeEnum[]) => {
  if (types.includes(CategoryTypeEnum.Default) && types.includes(CategoryTypeEnum.Custom)) {
    return CategoryTypesFilterOptions.All;
  } else if (types.every(x => x === CategoryTypeEnum.Default)) {
    return CategoryTypesFilterOptions.Default;
  } else if (types.every(x => x === CategoryTypeEnum.Custom)) {
    return CategoryTypesFilterOptions.Custom;
  } else {
    throw new Error('not implemented');
  }
};
