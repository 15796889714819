import {
  ButtonTertiaryConfig,
  ButtonTertiaryProps,
  ButtonTertiarySize,
  ButtonTertiaryVariant,
  ButtonTertiaryWidth,
  Icon,
  IconSize,
  IconSvg,
  IconVariant
} from "@symfonia/brandbook";
import {FC, SyntheticEvent} from "react";
import clsx from "clsx";

export type MenuButtonProps = Partial<ButtonTertiaryProps> & {
    id: string,
    lIcon?: IconSvg | null;
    lIconVariant?: IconVariant | null;
    active: boolean,
    text: string;
    href: string;
    handleClick?: ((event: SyntheticEvent) => void) | ((event: SyntheticEvent) => Promise<void>);
    disabled?: boolean;
    isRequiredAction?: boolean;
    'test-id'?: string;
    buttonVariant?: ButtonTertiaryVariant;
}

type MenuButtonStyles = {
    button: string;
    lIcon: string;
    rIcon: string;
    text: string;
};

const getStyles = (
    config: ButtonTertiaryConfig & { disabled: boolean; hasText: boolean; isClickable: boolean, selected: boolean },
): MenuButtonStyles => {
  return {
    button: clsx(
        'w-full py-4 px-3 group whitespace-nowrap flex text-start select-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500',
      {
          'border-l-4 border-primary-500': config.selected,
          'pl-4': !config.selected,
        'w-full': config.hasText && config.width === ButtonTertiaryWidth.FULL,
        'min-w-[200px]': config.hasText && config.width === ButtonTertiaryWidth.BASE,
        'w-fit': config.hasText && config.width === ButtonTertiaryWidth.FIT,
          'min-w-[24px]': config.size === ButtonTertiarySize.SM,
          'min-w-[28px]': config.size === ButtonTertiarySize.MD,
        'cursor-pointer': !config.disabled && config.isClickable,
      },
    ),
      lIcon: clsx(
          'flex-none mr-4',
          {
        'mr-2': config.selected,
      'filter-grey-300': config.disabled,
        'filter-grey-400': !config.disabled,
      'group-hover:filter-primary-500 group-focus:filter-primary-500 group-active:filter-primary-600':
        !config.disabled && config.isClickable,
    }),
      rIcon: clsx(
          'flex-none justify-self-end self-center',
          {
      'filter-grey-300': config.disabled,
      'filter-red-500': !config.disabled,
        'group-hover:filter-red-400 group-focus:filter-red-500 group-active:filter-red-600':
        !config.disabled && config.isClickable,
    }),
      text: clsx(
          'grow',
          {
      'font-quicksand font-medium text-grey-800': true,
      'text-grey-300': config.disabled,
        'text-grey-600': !config.disabled,
            'group-hover:text-primary-500 group-active:text-primary-500':
          !config.disabled && (config.isClickable || config.selected),
            'text-primary-600': config.selected,
      'text-sm': config.size === ButtonTertiarySize.SM,
      'text-base': config.size === ButtonTertiarySize.MD,
    }),
  };
};

export const MenuButton: FC<MenuButtonProps> = ({
                                                  handleClick = undefined,
                                                  lIcon = null,
                                                  lIconVariant = IconVariant.CONTOUR,
                                                  text = '',
                                                  className = '',
                                                  disabled = false,
                                                  size = ButtonTertiarySize.MD,
                                                  width = ButtonTertiaryWidth.FIT,
                                                  isRequiredAction = false,
                                                  active = false,
                                                  buttonVariant = ButtonTertiaryVariant.GREEN,
                                                }) => {

  const styles = getStyles({
    size,
    width,
    disabled,
    hasText: text.length > 0,
    isClickable: !!handleClick,
    selected: active,
    variant: buttonVariant,
  });

  const iconSize = size === ButtonTertiarySize.MD ? IconSize.LG : IconSize.MD;

  return (
      <button
        type="button"
        onClick={handleClick}
        className={clsx([styles.button, className])}
        tabIndex={0}
        disabled={disabled}
        data-testid="menu-button"
      >
        {lIcon && (
          <Icon
              className={clsx({[styles.lIcon]: true})}
            svg={lIcon}
            variant={lIconVariant}
            size={iconSize}
          />
        )}
        {text && <span className={styles.text}>{text}</span>}
          {(isRequiredAction) && (
          <Icon
            className={clsx({[styles.rIcon]: true, 'ml-[4px]': text.length > 0})}
            svg={(IconSvg.ERROR_OUTLINE) as IconSvg}
            variant={IconVariant.CONTOUR}
            size={IconSize.SM}
          />
        )}
      </button>
  );
};
