import {useRef} from 'react';
import {styled} from '@mui/material/styles';
import {TreeItem, treeItemClasses, TreeView} from '@mui/x-tree-view';
import {TreeViewDropdownModel} from '../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {observer} from 'mobx-react-lite';
import {TransitionComponentForTreeView} from '../../../earchive/pages/Account/Posting/modals/PostingChooseAccountModalConfig/TransitionComponentForTreeView';
import {Icon, IconColor, IconSize, IconSvg, Img} from '@symfonia/brandbook';
import * as _trees from './assents';

const trees = _trees as Record<keyof typeof _trees, string>;

interface PostingTreeViewProps {
  treeDropdownList: TreeViewDropdownModel[];
  selectedItemId: string | undefined;
  expandedIds: string[];
  onSelect(selected: string): void;
  onToggle(toggled: string[]): void;
}

const StyledTreeItem = styled(({isLast, ...props}: any) => {
  const treeItemRef = useRef<HTMLLIElement | null>(null);
  return (
    <div className={'flex items-start'}>
      {props.label?.toString().slice(0, 10).includes('-') ? (
        isLast ? (
          <Img src={trees.lastSmall} alt="tree" className={'max-w-[70%]'}/>
        ) : (
          <Img src={trees.treeD} alt="tree" className={'max-w-[70%]'}/>
        )
      ) : null}
      <TreeItem
        className={'font-quicksand text-base font-medium'}
        {...props}
        TransitionComponent={TransitionComponentForTreeView}
        ref={treeItemRef}
      />
    </div>
  );
})(({theme}) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    paddingTop: 8,
    paddingBottom: 8,
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
  '.MuiTreeItem-content .MuiTreeItem-label': {
    fontSize: '12px',
    fontFamily: 'Quicksand',
  },
}));

export const PostingTreeView = observer(
  ({treeDropdownList, onSelect, selectedItemId, expandedIds, onToggle}: PostingTreeViewProps): JSX.Element => {
    const renderTree = (node: TreeViewDropdownModel, isLast?: boolean) => {
      if (node === undefined) return;
      if (node.isHidden) return;

      const colorItem = node?.key === selectedItemId && node?.isSelectable ? '#009c10' : '';
      return (
        <StyledTreeItem
          key={node.key}
          nodeId={node.key}
          label={node.value}
          sx={{p: 0, color: colorItem}}
          disabled={node.isDisabled}
          isLast={isLast}
        >
          {node.children?.map((x, idx) => {
            const isLast = node.children?.length !== undefined && node.children?.length === idx + 1;
            return renderTree(x, isLast);
          })}
        </StyledTreeItem>
      );
    };

    return (
      <TreeView
        defaultCollapseIcon={
          <Icon svg={IconSvg.INDETERMINATE_CHECK_BOX} size={IconSize.MD} color={IconColor.GREEN_500}/>
        }
        defaultExpandIcon={<Icon svg={IconSvg.ADD_BOX} size={IconSize.MD} color={IconColor.GREEN_500}/>}
        defaultEndIcon={<Icon svg={IconSvg.INDETERMINATE_CHECK_BOX} size={IconSize.MD} color={IconColor.GREEN_500}/>}
        expanded={expandedIds}
        selected={selectedItemId}
        onNodeToggle={(event, nodeIds) => onToggle(nodeIds)}
        onNodeSelect={(event: React.SyntheticEvent, nodeIds: string) => onSelect(nodeIds)}
        sx={{overflowX: 'hidden', maxWidth: '480px'}}
      >
        {!!treeDropdownList &&
          treeDropdownList.map(n => {
            return renderTree(n);
          })}
      </TreeView>
    );
  },
);
