import * as React from 'react';
import Button from '@mui/material/Button';
import MoreVert from '@mui/icons-material/MoreVert';
import {toLower} from 'lodash';
import {dateParser, InvoiceCurrencyStatusCell} from '../../../../common';
import {cutTextLength} from '../../../../common/helpers/tableInputTextCutLenght';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {intl} from '../../../../root/IntlProvider';
import {IconSvg, Tag, TagColor, TagSize} from '@symfonia/brandbook';
import {PostingStatusEnum} from '@symfonia-ksef/graphql';
import {PostingRow} from './State/PostingTableRepository';
import {IPostingTableState} from './State/IPostingTableState';

export const postingColumns: Columns<PostingRow, IPostingTableState> = Object.freeze({
  InvoiceNumber: {
    sortable: true,
    header: intl.formatMessage({id: 'invoiceOwnNumber'}),
    tooltipContent: row =>
      cutTextLength(row.InvoiceNumber, false, false, true)?.wasTruncated ? row.InvoiceNumber : '',
    cell: row => cutTextLength(row.InvoiceNumber, false, false, true)?.truncatedText,
    order: 1,
    isObserver: true,
    width: 'w-[180px]',
  },
  InvoiceTypeGroup: {
    header: intl.formatMessage({id: 'DocumentTypes'}),
    cell: row => intl.formatMessage({id: 'invoice' + row.InvoiceTypeGroup}),
    order: 2,
    isObserver: true,
    hidden: true,
    width: 'w-[180px]',
  },
  ContractorName: {
    sortable: true,
    header: intl.formatMessage({id: 'contractor'}),
    tooltipContent: row =>
      cutTextLength(row.ContractorName ?? null, false, false, true)?.wasTruncated ? row.ContractorName : '',
    cell: row =>
      cutTextLength(row.ContractorName ?? intl.formatMessage({id: 'missingCategoryLabel'}), false, false, true)
        ?.truncatedText,
    order: 3,
    isObserver: true,
    ellipsisEnabled: true,
    width: 'w-[250px]',
  },
  ContractorIdentifier: {
    hidden: true,
    header: intl.formatMessage({id: 'Identifier'}),
    cell: row => row.ContractorIdentifier,
    order: 4,
    isObserver: true,
    width: 'w-[160px]',
  },
  GrossValue: {
    sortable: true,
    header: intl.formatMessage({id: 'invoiceAmountGross'}),
    cell: row => <InvoiceCurrencyStatusCell value={row.GrossValue}/>,
    order: 5,
    isObserver: true,
    width: 'w-[150px]',
    alignRight: true,
  },
  CreatedAt: {
    sortable: true,
    header: intl.formatMessage({id: 'PostingDate'}),
    cell: row => dateParser(row.CreatedAt, false),
    order: 6,
    isObserver: true,
    width: 'w-[160px]',
  },
  PostingStatus: {
    sortable: true,
    isObserver: true,
    header: intl.formatMessage({id: 'PostingStatus'}),
    order: 7,
    width: 'w-[150px]',
    cell: row => (
      <Tag
        text={intl.formatMessage({id: 'postingStatus_' + toLower(row.PostingStatus)})}
        color={row.PostingStatus === PostingStatusEnum.Incomplete ? TagColor.RED_LIGHT : TagColor.GREEN_LIGHT}
        lIcon={row.PostingStatus === PostingStatusEnum.Incomplete ? IconSvg.WARNING_CIRCLE : undefined}
        size={TagSize.SM}
      />
    ),
  },
  Id: {
    asAction: true,
    order: 8,
    isObserver: true,
    width: 'w-[70px]',
    header: intl.formatMessage({id: 'actions'}),
    cell: (row, _, __, context) => (
      <Button
        size="small"
        onClick={e => {
          e.stopPropagation();
          const postingState = context as IPostingTableState;
          postingState.postingState.setSelectedDecree(row);
          postingState.setSelectedMenuAnchorEl(e.currentTarget);
          postingState.setSelectedMenuOpened(true);
        }}
      >
        <MoreVert className={'IconWithTransform'}/>
      </Button>
    ),
  },
});
