import {TreeViewDropdownModel} from '../../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {action, autorun, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {MultiSelectModel} from '../../../../../../../../../common/components/MultiSelect/MultiSelect';
import {
  AccountTypeEnum,
  AccountViewModel,
  CategoryAccountRequestInput,
  CategoryViewModel,
  InvoiceTypeGroupEnum,
} from '@symfonia-ksef/graphql';
import {CategoryAccountModel} from '../models/CategoryAccountModel';
import {
  convertToMultiSelectType,
  convertToTreeViewDropdownModel,
} from '../../../../../../../../../common/helpers/baseFilterHelpers';
import {CategoryAccountFilterState} from './CategoryAccountFilterState';
import {ICategoryAccountState} from './ICategoryAccountState';
import {IPostingConfigurationState} from '../../../IPostingConfigurationState';
import {CategoryAccountStore} from './CategoryAccountStore';
import {ICategoryAccountStore} from './ICategoryAccountStore';
import {accountNumberComparer} from '../../../../../../../../../auto-posting/helpers/accountNumberComparer';
import {ICategoryAccountTableState} from './ICategoryAccountTableState';
import {ICategoryAccountFilterState} from './ICategoryAccountFilterState';
import {CategoryAccountTableState} from './CategoryAccountTableState';

export class CategoryAccountState implements ICategoryAccountState {
  @observable
  selectedCategory: CategoryAccountModel | undefined;

  @observable
  categoryRulesAssignedToSelectedCategory: string[] = [];

  @observable
  categoriesToMoveCategoryRules: MultiSelectModel[] = [];

  @observable
  categoryAccountChangedArray: CategoryAccountRequestInput[] = [];

  @observable
  dropdownAccountsKUP: IObservableArray<TreeViewDropdownModel> = observable([]);

  @observable
  dropdownAccountsNKUP: IObservableArray<TreeViewDropdownModel> = observable([]);

  @observable
  selectedInvoiceMenuOpened = false;

  @observable
  selectedInvoiceMenuAnchorEl: HTMLElement | undefined = undefined;

  @observable
  addDialogDoubleNameError = false;

  @observable
  openAddCategoryDialog = false;

  @observable
  openChangeCategoryDialog = false;

  @observable
  openDeleteCategoryDialog = false;

  @observable
  openDeleteCategoryWithCategoryRulesDialog = false;

  @observable
  changeDialogDoubleNameError = false;

  @observable
  isLoading = false;

  @computed
  get checkIfAccountsAreNotEmptyOnPurchase(): boolean {
    if (this.postingConfigurationState.invoiceTypeGroup === InvoiceTypeGroupEnum.Purchase) {
      const start = this.categoryAccountChangedArray;
      const countDeleted = this.categoryAccountChangedArray.reduce(
        (acc, x) => acc + (x.AccountId === undefined ? 1 : 0),
        0,
      );

      const countAdded = this.categoryAccountChangedArray.reduce(
        (acc, x) => acc + (x.AccountId !== undefined ? 1 : 0),
        0,
      );

      const count = this.tableState.initAccountCount + countAdded - countDeleted;

      return count > 0;
    }
    if (this.postingConfigurationState.invoiceTypeGroup === InvoiceTypeGroupEnum.Sales) {
      return true;
    }
    return false;
  }

  filterState: ICategoryAccountFilterState;
  tableState: ICategoryAccountTableState;

  postingConfigurationState: IPostingConfigurationState;
  categoryAccountStore: ICategoryAccountStore;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.postingConfigurationState = postingConfigurationState;
    this.categoryAccountStore = new CategoryAccountStore(this);
    this.filterState = new CategoryAccountFilterState(this);
    this.tableState = new CategoryAccountTableState(this);

    autorun(() => {
      if (this.categoryAccountStore.categories.length && this.categoryAccountStore.accountsInUse.length) {
        this.setCategories(this.categoryAccountStore.categories);
        this.setAccountsKUP(this.categoryAccountStore.accountsInUse);
        this.setAccountsNKUP(this.categoryAccountStore.accountsInUse);
      }
    });
  }

  @action
  setSelectedCategory(category: CategoryAccountModel | undefined) {
    this.selectedCategory = category;
  }

  @action
  setCategoryRulesAssignedToSelectedCategory(categoryRulesAssignedToSelectedCategory: string[]) {
    this.categoryRulesAssignedToSelectedCategory = categoryRulesAssignedToSelectedCategory;
  }

  @action
  setCategoriesToMoveCategoryRules(id: string) {
    this.categoriesToMoveCategoryRules = this.filterState.categories.filter(x => x.key !== id);
  }

  @action
  updateCategoryAccountChangedArray(accountId: string | undefined, categoryId: string, accountType: AccountTypeEnum) {
    const request = {
      CategoryId: categoryId,
      AccountId: accountId,
      AccountType: accountType,
    } as CategoryAccountRequestInput;

    const categoryIndex = this.categoryAccountChangedArray.findIndex(
      e => e.CategoryId === categoryId && e.AccountType === accountType,
    );

    if (categoryIndex === -1) {
      this.categoryAccountChangedArray.push(request);
    } else {
      if (accountId === undefined) {
        this.categoryAccountChangedArray.splice(categoryIndex, 1);
      } else this.categoryAccountChangedArray.splice(categoryIndex, 1, request);
    }
  }

  @action
  setCategories(cat: CategoryViewModel[]) {
    this.filterState.setCategories(
      convertToMultiSelectType(
        cat,
        el => ({
          value: el.Name,
          key: el.Id as string,
        }),
        true,
      ),
    );
  }

  @action
  setAccountsKUP(acc: AccountViewModel[]) {
    const accounts = acc.filter(a => a.AccountType === AccountTypeEnum.Kup).sort(accountNumberComparer);

    this.filterState.setAccountsKUP(
      convertToTreeViewDropdownModel(accounts, el => ({
        value: el?.Number + ' ' + el?.Name,
        key: el?.Id,
        isSelectable: (el?.IsFinal ?? false) && (!el?.IsDisabled ?? false),
        parentId: el?.ParentId,
        childrenIds: el?.Children?.map(x => x.Id),
      })),
    );
  }

  @action
  setAccountsNKUP(acc: AccountViewModel[]) {
    const accounts = acc.filter(a => a.AccountType === AccountTypeEnum.Nkup).sort(accountNumberComparer);

    this.filterState.setAccountsNKUP(
      convertToTreeViewDropdownModel(accounts, el => ({
        value: el?.Number + ' ' + el?.Name,
        key: el?.Id,
        isSelectable: (el?.IsFinal ?? false) && (!el?.IsDisabled ?? false),
        parentId: el?.ParentId,
        childrenIds: el?.Children?.map(x => x.Id),
      })),
    );
  }

  @action
  setChangeDialogDoubleNameError(val: boolean) {
    this.changeDialogDoubleNameError = val;
  }

  @action
  setAddDialogDoubleNameError(val: boolean) {
    this.addDialogDoubleNameError = val;
  }

  @action
  setOpenDeleteCategoryWithCategoryRulesDialog(val: boolean) {
    this.openDeleteCategoryWithCategoryRulesDialog = val;
  }

  @action
  setOpenChangeCategoryDialog(val: boolean) {
    this.openChangeCategoryDialog = val;
  }

  @action
  setOpenAddCategoryDialog(val: boolean) {
    this.openAddCategoryDialog = val;
  }

  @action
  setOpenDeleteCategoryDialog(val: boolean) {
    this.openDeleteCategoryDialog = val;
  }

  @action
  setSelectedInvoiceMenuAnchorEl(selectedInvoiceMenuAnchorEl: HTMLElement | undefined) {
    this.selectedInvoiceMenuAnchorEl = selectedInvoiceMenuAnchorEl;
  }

  @action
  setSelectedInvoiceMenuOpened(selectedInvoiceMenuOpened: boolean) {
    this.selectedInvoiceMenuOpened = selectedInvoiceMenuOpened;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  resetState() {
    this.categoryAccountChangedArray = [];
    this.categoryAccountStore.clearDdlAccounts();
  }

  @action
  public canAddRemovingToChangedArray(categoryId: string, accountType: AccountTypeEnum) {
    const categoryAccountToChange = this.tableState.data.find(
      x => x.Id === categoryId && x.Accounts.find(y => y.AccountType === accountType),
    );
    const cosdfsd = this.categoryAccountChangedArray.find(
      x => x.CategoryId === categoryId && x.AccountType === accountType,
    );

    return categoryAccountToChange !== undefined || cosdfsd !== undefined;
  }
}
