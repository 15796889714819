import {FC, useRef, ReactElement, Children, useMemo, cloneElement, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {get} from 'lodash';
import {Tooltip} from '../../Tooltip/Tooltip';

export type TableCellProps = {
  className?: string;
  children: ReactElement;
};

export const TableCell: FC<TableCellProps> = ({className: cellClassName = undefined, children}) => {
  const ref = useRef<HTMLElement>(null);

  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const child = useMemo(() => {
    const onlyChild = Children.only(children);
    return cloneElement(onlyChild, {
      ref,
      onMouseEnter: () => setIsMouseOver(true),
      onMouseLeave: () => setIsMouseOver(false),
      children: get(onlyChild, 'props.children') as ReactElement,
      className: twMerge(
        clsx('overflow-hidden whitespace-nowrap overflow-ellipsis', get(onlyChild, 'props.className') as string),
      ),
    });
  }, [children]);

  const isEllipsis = (() => {
    if (ref.current === null) {
      return false;
    }

    return ref.current.clientWidth !== ref.current.scrollWidth;
  })();

  return (
    <span className={cellClassName}>
      <Tooltip text={ref.current?.textContent || ''} open={isMouseOver && isEllipsis}>
        {child}
      </Tooltip>
    </span>
  );
};
