import {CategoryTypeEnum, InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {CategoryAccountState} from './CategoryAccountState';
import {BaseSideMenuFilterState} from '../../../../../../../../../../state/filters/BaseSideMenuFilterState';
import {CategoriesAccountsFiltersKeys} from '../models/CategoryTypesFilterOptions';
import {FilterScopeType, ListFilterType} from '../../../../../../../../../../state/filters/BaseFilterState';
import {ICategoryAccountFilterState} from './ICategoryAccountFilterState';
import {action, autorun, IObservableArray, makeObservable, observable} from 'mobx';
import {MultiSelectModel} from '../../../../../../../../../common/components/MultiSelect/MultiSelect';
import {localStorageService} from '../../../../../../../../../../modules/common/helpers/storage';

export class CategoryAccountFilterState
  extends BaseSideMenuFilterState<CategoryAccountState, CategoriesAccountsFiltersKeys>
  implements ICategoryAccountFilterState
{
  @observable
  categories: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  accountsKUP: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  accountsNKUP: IObservableArray<MultiSelectModel> = observable([]);

  constructor(parent: CategoryAccountState) {
    super(parent);
    this.defaultFilters = [
      {
        key: CategoriesAccountsFiltersKeys.Type,
        value: {
          values: [CategoryTypeEnum.Custom, CategoryTypeEnum.Default],
          type: FilterScopeType.Enum,
          isActive: true,
        },
      },
      {
        key: CategoriesAccountsFiltersKeys.InvoiceTypeGroup,
        value: {
          values: [InvoiceTypeGroupEnum.Purchase],
          type: FilterScopeType.Enum,
          isActive: true,
        },
      },
      {
        key: CategoriesAccountsFiltersKeys.Id,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.UUIDs,
          isPageFilter: true,
        },
      },
      {
        key: CategoriesAccountsFiltersKeys.AccountKUPIds,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.UUIDs,
          isPageFilter: true,
          related: {
            parentName: 'Accounts',
            key: 'Id',
          },
        },
      },
      {
        key: CategoriesAccountsFiltersKeys.AccountNKUPIds,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.UUIDs,
          isPageFilter: true,
          related: {
            parentName: 'Accounts',
            key: 'Id',
          },
        },
      },
    ];
    makeObservable(this);
    autorun(() => (this.parent.postingConfigurationState.companyId !== '' ? this.setDefaultValues() : null));
    autorun(() => {
      if (
        this.parent.postingConfigurationState.companyId !== '' &&
        this.parent.postingConfigurationState.selectedFinancialYear &&
        this.activeFilters.get(CategoriesAccountsFiltersKeys.Type) &&
        this.activeFilters.get(CategoriesAccountsFiltersKeys.InvoiceTypeGroup)
      ) {
        return this.parent.categoryAccountStore.fetchCategoryAccountsFilterData();
      } else {
        return null;
      }
    });
  }

  @action
  public getFilters() {
    this.activeFilters.clear();
    this.setDefaultValues();
  }

  @action
  public setCategories(categories: MultiSelectModel[]): void {
    this.categories.replace(categories);
  }

  @action
  public setAccountsKUP(accountsKUP: MultiSelectModel[]): void {
    this.accountsKUP.replace(accountsKUP);
  }

  @action
  public setAccountsNKUP(accountsNKUP: MultiSelectModel[]): void {
    this.accountsNKUP.replace(accountsNKUP);
  }

  @action.bound
  protected override setDefaultValues() {
    let filterToCreate: ListFilterType = [];
    const storedFilters =
      this.storage !== undefined && this.storage.loadFromStorage() && this.storage.loadFromStorage().length > 0
        ? this.storage.loadFromStorage()
        : undefined;

    storedFilters ? (filterToCreate = storedFilters) : (filterToCreate = this.defaultFilters);

    filterToCreate
      .filter(f => f.value.isActive)
      .map(f => {
        if (Object.keys(CategoriesAccountsFiltersKeys).find(c => c === f.key)) {
          this.activeFilters.set(f.key as CategoriesAccountsFiltersKeys, f.value);
        }
      });

    if (
      localStorageService.getItem(
        'autoposting.InvoiceTypeGroup.companyId.' + this.parent.postingConfigurationState.companyId,
      ) !== undefined
    ) {
      this.activeFilters.set(CategoriesAccountsFiltersKeys.InvoiceTypeGroup, {
        values: [
          localStorageService.getItem(
            'autoposting.InvoiceTypeGroup.companyId.' + this.parent.postingConfigurationState.companyId,
          ),
        ],
        type: FilterScopeType.Enum,
        isActive: true,
      });
    }
    this.createPills();
  }
}
