import {useIntl} from 'react-intl';
import {usePageTitle} from '../../../common';
import React, {useEffect} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InvoiceBound, InvoicePostingStatus} from '@symfonia-ksef/graphql';
import {PackageSubscriptionAccessGuard} from '../../../common/components/PackageSubscriptionAccessGuard';
import {isFeatureEnabled} from '../../../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../../../common/enums/FeatureSwitchTypeEnum';
import {useInvoicesTableMethods} from '../../hooks/useInvoicesTableMethods';
import {IInvoicePageState} from './state/IInvoicePageState';
import {ActionPartlyNotAvailableModal} from '../../../common/modals/ActionPartlyNotAvailableModal';
import {InvoicesView} from '../../modules/Invoices/Invoices.view';
import {useInvoicesViewButtonsAndFiltersMethods} from '../../hooks/useInvoicesViewButtonsAndFiltersMethods';
import {
  decreeInvoices,
  exportToFKPartlyUnavailableList,
  exportToFKWithoutDecree,
} from '../../helpers/postingStatusHelpers';
import {ActionPartlyNotAvailableModalExtended} from '../../../common/modals/ActionPartlyNotAvailableModalExtended';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';

type GenericInvoicesPageProps = {
  pageTitleKey: Tr,
  invoicesBound: InvoiceBound,
  forceAccess?: boolean,
  checkIsLoading?: () => boolean,
  state: IInvoicePageState
}

export const GenericInvoicesPage: FCC<GenericInvoicesPageProps> = observer(({
  forceAccess,
  pageTitleKey,
  invoicesBound,
  state,
}) => {
  const intl = useIntl();
  const {setPageTitle} = usePageTitle();
  const {company: {autoPosting}, invoicesContext} = earchiveState;
  const {
    state: {
      postingUnavailableInvoices,
      selectedInvoices,
      isPostingPartlyNotAvailableModalOpen,
      isPublishInvoicesModalOpen,
      isSentToKsefInvoicesModalOpen,
      isInvoicesWithoutDecreeModalOpen,
    },
  } = invoicesContext;
  const acceptBeforeSendToFKIsActive = !!autoPosting?.AcceptBeforeSendToFK?.IsActive;


  const {runPosting, publishInvoicesByServiceBusOrAPI} = useInvoicesTableMethods();
  const {senToFKDecreeVerification} = useInvoicesViewButtonsAndFiltersMethods();

  const proceedPosting = () => {
    runPosting(selectedInvoices.map(x => x.Id + ''), postingUnavailableInvoices.length);
    invoicesContext.set({isPostingPartlyNotAvailableModalOpen: false});
  };

  const publishInvoicesAction = () => {
    publishInvoicesByServiceBusOrAPI(selectedInvoices);
    invoicesContext.set({isPublishInvoicesModalOpen: false});
    invoicesContext.set({isInvoicesWithoutDecreeModalOpen: false});
  };

  useEffect(() => setPageTitle(intl.formatMessage({id: pageTitleKey})), [pageTitleKey]);

  return <PackageSubscriptionAccessGuard forceAccess={forceAccess}>
      {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && postingUnavailableInvoices.length > 0 &&
        <ActionPartlyNotAvailableModalExtended
          title={Tr.postingPartlyNotAvailableModalTitle}
          message={Tr.postingPartlyNotAvailableModalText}
          unavailable={decreeInvoices(selectedInvoices) ?? []}
          selectedNumber={selectedInvoices.length}
          open={isPostingPartlyNotAvailableModalOpen}
          onConfirm={() => proceedPosting()}
          onCancel={() => invoicesContext.set({isPostingPartlyNotAvailableModalOpen: false, selectedInvoices: []})}/>}
      {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) &&
        <ActionPartlyNotAvailableModal
          title={Tr.exportToFinancesAndAccountancyPartlyNotAvailableModalTitle}
          message={Tr.exportToFinancesAndAccountancyPartlyNotAvailableModalText}
          unavailable={selectedInvoices.filter(x => x.InvoicePostingStatus === InvoicePostingStatus.DecreeSent || x.InvoicePostingStatus === InvoicePostingStatus.DecreeDownloaded).length}
          selected={selectedInvoices.length}
          open={isPublishInvoicesModalOpen}
          onConfirm={() => {
            publishInvoicesAction();
            invoicesContext.set({selectedInvoices: []});
          }}/>}
      {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) &&
        <ActionPartlyNotAvailableModalExtended
          title={Tr.exportToFinancesAndAccountancyPartlyNotAvailableModalTitle}
          message={Tr.sendToKsefExtendedModal}
          unavailable={exportToFKPartlyUnavailableList(selectedInvoices, acceptBeforeSendToFKIsActive ?? false)}
          selectedNumber={selectedInvoices.length}
          open={isSentToKsefInvoicesModalOpen}
          onConfirm={() => senToFKDecreeVerification(selectedInvoices)}
          onCancel={() => invoicesContext.set({isSentToKsefInvoicesModalOpen: false, selectedInvoices: []})}
        />}
      {isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) &&
        <ActionPartlyNotAvailableModalExtended
          title={Tr.someInvoicesWithoutDecree}
          message={Tr.someInvoicesWithoutDecreeModal}
          unavailable={exportToFKWithoutDecree(selectedInvoices)}
          selectedNumber={selectedInvoices.length}
          open={isInvoicesWithoutDecreeModalOpen}
          onConfirm={() => publishInvoicesAction()}
          onCancel={() => invoicesContext.set({isInvoicesWithoutDecreeModalOpen: false, selectedInvoices: []})}
        />}
      <InvoicesView
        eArchiveState={state.documentsState.earchiveState}
        filtersStateMobX={state.filtersState}
        invoiceBound={invoicesBound}
      />
    </PackageSubscriptionAccessGuard>;
});
