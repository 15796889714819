import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CheckWhiteListDataModel} from '../../../../../../services/helpers/NotificationDataParsers';
import {checkWhiteListService} from '@symfonia-ksef/state/rootRepository';
import ShowPopupIcon from '../../../../../../assets/ic_show_popup.svg';

export class WhiteListValidationEvent extends KSeFEventsConverter<WebsocketNotificationType.WhiteListValidation> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.WhiteListValidation, event);
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    if (!this.model.hasError) {
      return undefined;
    }
    return {
      icon: ShowPopupIcon,
      tooltipText: Tr.showMessage,
    };
  }

  public get notification(): ReactNode {
    const successCount = this.model.data?.invoiceIds?.length ?? 0;
    const notWhiteListedCount = this.model.data?.notWhiteListed.length ?? 0;
    const whiteListedCount = this.model.data?.whiteListed.length ?? 0;
    if (!notWhiteListedCount && !whiteListedCount) {
      return this.createNotification({id: Tr.whiteListCheckAllAccountsIsEmpty, values: {count: successCount}});
    }
    if (notWhiteListedCount) {
      return this.createNotification({id: Tr.whiteListCheckSuccessWithNotWhiteListed, values: {notWhiteListedCount: notWhiteListedCount, count: successCount}});
    }
    return this.createNotification({id: this.model.hasError ? Tr.whiteListCheckError : Tr.whiteListCheckSuccess, values: {count: successCount}});
  }

  public get model(): { hasError: boolean, data: CheckWhiteListDataModel | null } {
    const data = this.parser.parse();
    const hasError = !!this.event.ErrorType || (!!data?.invoiceIds?.length && !!data?.errorItems?.length);
    return {hasError, data};
  }

  public action(): void {
    const data = this.parser.parse();
    if (data) {
      checkWhiteListService.setNotification(this.event).setArchivedResult(data as CheckWhiteListDataModel, this.event.NotificationId).setResultIsAvailable(true);
    }
    (checkWhiteListService.errorInvoicesCount > 0 || checkWhiteListService.notWhiteListedInvoicesCount > 0) && checkWhiteListService.setModalIsActive(true);
  }
}
