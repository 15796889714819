import {useIntl} from 'react-intl';
import {PageMessage, usePageTitle} from '../../../common';
import {Tr} from '../../../../locales/translationKeys';

export const CompanyPending = ({companyName}: { companyName: string }) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();

  setPageTitle(companyName);

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.companyPendingTitle})}
      description={intl.formatMessage({id: Tr.companyPendingeDesc}, {br: <br/>})}
    />
  );
};
