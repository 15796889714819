import {Tr} from '@symfonia-ksef/locales/keys';
import {usePageTitle} from '../../hooks';
import {PageMessage} from '../PageMessage/PageMessage';
import {useIntl} from 'react-intl';

export const TenantRejected = ({tenantName}: { tenantName: string }) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();

  setPageTitle(tenantName);

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.tenantRejectedTitle})}
      description={intl.formatMessage({id: Tr.tenantRejectedDesc})}
    />
  );
};
