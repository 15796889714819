import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';

import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../SubscriptionEventsService';
import {Tr} from '../../locales/translationKeys';
import {getUpoResultService} from '../../state/rootRepository';
import {intl} from '../../modules/root/IntlProvider';
import {AlertConfig} from '../helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';


export class GetUPOEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {

  constructor() {
    super(WebsocketNotificationType.GetUpo, getUpoResultService, {
      error: () => getUpoResultService.hasDownloadableContent && getUpoResultService.hasReturnedInvoices ? this.createSuccessAlert({
        color: ToastVariant.SUCCESS,
        duration: 8000,
      }) : this.createErrorAlert(),
      success: () => getUpoResultService.hasReturnedInvoices ? this.createSuccessAlert() : this.createErrorAlert({
        color: ToastVariant.ERROR,
        duration: undefined,
      }),
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await getUpoResultService.getAsyncData(event));
    if (getUpoResultService.jobRunner.initialized && !event.errorType) {
      const {hasMissed} = getUpoResultService.downloadUpoFile();
      hasMissed && getUpoResultService.setModalIsActive(true);
    }
    getUpoResultService.setResult(null);
    if (event.errorType) {
      getUpoResultService.clearFetchedResult();
    }
  }

  private createErrorAlert(additionalConfig?: AlertConfig): AlertConfig {
    return {id: Tr.getUpoSubError, ...additionalConfig};
  }

  private createSuccessAlert(additionalConfig?: AlertConfig): AlertConfig {
    return {
      id: Tr.getUpoSubSuccess,
      actions: getUpoResultService.jobRunner.initialized ? undefined : {
        title: intl.formatMessage({id: Tr.downloadUPO}),
        onClick: () => {
          const {hasMissed} = getUpoResultService.downloadUpoFile();
          hasMissed && getUpoResultService.setModalIsActive(true);
        },
      },
      ...additionalConfig,
    };
  }
}
