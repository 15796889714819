import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
  DataSourceHandlerI,
  GetVariablesParams,
} from '../../../../../../../../root/services/TableServices/DataSourceTableService';
import {
  CompanyVehiclesFiltersInput,
  CompanyVehiclesResponse,
  GetCompanyVehiclesDocument,
  GetCompanyVehiclesQuery,
  GetCompanyVehiclesQueryVariables,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {TableSortDirection, ToastVariant} from '@symfonia/brandbook';
import {GraphQLErrorWithMessage} from '../../../../../../../../root/providers/GraphQLProvider';
import {l} from '../../../../../../../../logger/loggerInstance';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IVehicleState} from './IVehicleState';
import {filterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {BuildFilters, BuildSortOption} from '../../../../../../../../common/filters/FilterOptionExtensions';
import {envObserver, earchiveState} from '@symfonia-ksef/state/rootRepository';

export type VehiclesRow = CompanyVehiclesResponse;

export type VehiclesRepositoryI = BaseTableDataRepositoryServiceI<keyof GetCompanyVehiclesQuery, GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables>

export class VehiclesRepository extends BaseTableDataRepositoryService<'CompanyVehicles', GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables> implements VehiclesRepositoryI {
  constructor(private readonly vehicleState: IVehicleState) {
    super('CompanyVehicles', GetCompanyVehiclesDocument, envObserver, earchiveState);
  }

  public setFilters(filterFields: Record<string, filterType>): this {
    const filters = Object.keys(filterFields).length > 0 ? BuildFilters(filterFields) as CompanyVehiclesFiltersInput : {};
    return this.configure({FilterFields: filters});
  }

  protected override handleEnvIdChange(envId: string | null): void {
    super.handleEnvIdChange(envId);
    envId && this.configure({CompanyId: envId});
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.setFilters(this.vehicleState.filterState.getActiveFiltersForQuery);
  }

  protected override onSuccess(data: GetCompanyVehiclesQuery['CompanyVehicles']): void | Promise<void> {
    data && this.vehicleState.setVehicles(data.items as VehiclesRow[]);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    l.error('Error when loading settlements table', undefined, errors);
    const {addAlert} = earchiveState.alertsState;
    addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {
      displayDuration: 10000,
      omitIfHasTheSameAlert: true,
    });
    this.vehicleState.setIsLoading(false);
  }
}

export class VehiclesDataSourceHandler implements DataSourceHandlerI<'CompanyVehicles', GetCompanyVehiclesQuery, GetCompanyVehiclesQueryVariables, VehiclesRow> {
  public readonly repository!: VehiclesRepositoryI;

  constructor(state: IVehicleState) {
    this.repository = new VehiclesRepository(state);
  }

  public dataMapper(data: GetCompanyVehiclesQuery['CompanyVehicles'] | null) {
    return (data?.items ?? []) as Array<VehiclesRow>;
  }

  public getTotalCount(data: GetCompanyVehiclesQuery['CompanyVehicles']): number {
    return data?.totalCount ?? 0;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<GetCompanyVehiclesQueryVariables> {
    const direction = params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc;
    const name = params.sortBy?.name ?? '';

    return {
      Take: params.size,
      Skip: params.offset,
      Order: BuildSortOption(name, direction),
    };
  }
}
