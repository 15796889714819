import {FC} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {TestableElement} from '../../external/types';

export enum SeparatorDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum SeparatorSize {
  SM = 'SM',
  MD = 'MD',
}

export type SeparatorProps = {
  size?: SeparatorSize;
  direction?: SeparatorDirection;
  className?: string;
  dashed?: boolean;
} & TestableElement;

export const Separator: FC<SeparatorProps> = ({
  size = SeparatorSize.MD,
  direction = SeparatorDirection.HORIZONTAL,
  className = '',
  dashed = false,
  testId = undefined,
}) => {
  const styles = {
    component: twMerge(
      clsx({
        'border-grey-200': true,
        'border-dashed': dashed,
        'w-full border-t-[1px]': size === SeparatorSize.SM && direction === SeparatorDirection.HORIZONTAL,
        'w-full border-t-[2px]': size === SeparatorSize.MD && direction === SeparatorDirection.HORIZONTAL,
        'h-full border-l-[1px]': size === SeparatorSize.SM && direction === SeparatorDirection.VERTICAL,
        'h-full border-l-[2px]': size === SeparatorSize.MD && direction === SeparatorDirection.VERTICAL,
      }),
      className,
    ),
  };
  return <div className={styles.component} data-test-element="separator" data-testid={testId} />;
};
