import {FC} from 'react';
import {useForm} from 'react-hook-form';
import {intl} from '../../../../root/IntlProvider';

import {ACCOUNT_MANAGEMENT_CONSTS} from './consts';
import {TenantInfoFormValuesModel} from './TenantInfoFormValuesModel';
import {Tr} from '../../../../../locales/translationKeys';
import {Input, InputSize, InputWidth} from '@symfonia/brandbook';
import {Text} from '../../../../root/components/Text';

export interface TenantInfoContainerProps {
  data: TenantInfoFormValuesModel;
}

const TenantInfoContainer: FC<TenantInfoContainerProps> = ({data}) => {
  const inputSize = InputSize.SM;
  const inputWidth = InputWidth.FULL;
  const metaData = ACCOUNT_MANAGEMENT_CONSTS.TENANT_METADATA;

  const {getValues} = useForm<TenantInfoFormValuesModel>({defaultValues: data});

  const {tenantName} = Tr;

  const tenantIntl = intl.formatMessage({id: tenantName});

  const identifierTypeIntl = intl.formatMessage({id: Tr[data.IdentifierType]});

  const {Identifier, Name} = getValues();

  return (
    <div className="grid">
      <Text className="font-bold my-[20px]">{metaData.titleLabel}</Text>
      <div className="grid gap-[18px] max-w-[280px]">
        <Input disabled label={tenantIntl} placeholder={tenantIntl} size={inputSize} value={Name} width={inputWidth} testId="tenantInfoTenantName"/>
        <Input
          disabled
          label={identifierTypeIntl}
          placeholder={identifierTypeIntl}
          size={inputSize}
          value={Identifier}
          width={inputWidth}
          testId="tenantInfoTenantIdentifier"
        />
      </div>
    </div>
  );
};

export default TenantInfoContainer;
