import {BaseRepository, BaseRepositoryI} from './BaseRepository';
import {AnyObject} from 'yup/es/types';
import {TypedDocumentNode} from '@apollo/client';
import {EnvObserverI, EnvSubscriber} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {InitializerType} from '../../../../../services/helpers/fetchMatchedAction';
import {makeObservable, override} from 'mobx';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

//umożliwia zasubskrybowanie zmiany kontekstu (earchive.company.companyId, earchive.company.tenantId lub earchive.company.userId)
// get envObserver(): EnvObserverI;

export type RepositoryI<Key extends string = string, Data extends Record<Key, any> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> = BaseRepositoryI<Key, Data, Variables>

export class Repository<Key extends string = string, Data extends Record<Key, any> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> extends BaseRepository<Key, Data, Variables> {
  constructor(key: Key, graphqlDocument: TypedDocumentNode, public readonly envObserver: EnvObserverI, public readonly earchiveState: EArchiveState, initializerType: InitializerType = InitializerType.Query) {
    super(key, graphqlDocument, initializerType);
    makeObservable(this);
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
  }

  @override
  public override get envId(): string | null {
    return this.envObserver.currentEnv.companyId;
  }

  protected override _onMount(): Promise<void> | void {
    this.envObserver.unsubscribeCompanyId(this.envIdSubscriber);
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
  }

  protected override _onUnmount() {
    this.envObserver.unsubscribeCompanyId(this.envIdSubscriber);
  }

  //possible to override
  protected handleEnvIdChange(envId: string | null) {
    return;
  }

  private readonly envIdSubscriber: EnvSubscriber = envId => this.handleEnvIdChange(envId);

}
