import {EventConsumer} from '../../../services/EventConsumer';
import {SubscriptionStateEnum, WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../../../services/SubscriptionEventsService';
import {keysTransformers} from '../../../services/ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {wsActiveEventsRepository} from '@symfonia-ksef/state/rootRepository';

interface EventsStateI {
  addEvent(event: WebSocketNotificationFragment): void;
}

export class AutoFetchingEventSynchronizer extends EventConsumer {
  constructor() {
    super(WebsocketNotificationType.AutoFetchingInvoices, [SubscriptionStateEnum.Processing, SubscriptionStateEnum.Enqueued, SubscriptionStateEnum.CancellationInProgress]);
  }

  public override onUpdate(event: EventParams): void {
    wsActiveEventsRepository.addEvent(keysTransformers.uppercase(event));
  }
}
