import {FC, useMemo} from 'react';
import clsx from 'clsx';
import {Img} from '@symfonia/brandbook';
import {spinnerSm} from '../../../../../brandbook/src/components/Loader/assets';

export enum LoadingButtonVariant {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum LoadingButtonSize {
  SM = 'SM',
  MD = 'MD',
}

export enum LoadingButtonWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
}

export type LoadingButtonConfig = {
  variant: LoadingButtonVariant;
  size: LoadingButtonSize;
  width: LoadingButtonWidth;
};

export type LoadingButtonProps = Partial<LoadingButtonConfig> & {
  text?: string;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
};

type ButtonStyles = {
  button: string;
  loader: string;
  text: string;
};

const getStyles = (config: LoadingButtonConfig & { loading: boolean; disabled: boolean; hasText: boolean }): ButtonStyles => {
  const styles = {
    button: clsx({
      'inline-flex whitespace-nowrap justify-center items-center rounded-lg select-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500':
        true,
      'w-full': config.hasText && config.width === LoadingButtonWidth.FULL,
      'min-w-[200px]': config.hasText && config.width === LoadingButtonWidth.BASE,
      'w-fit': config.hasText && config.width === LoadingButtonWidth.FIT,
      'px-[16px]': config.hasText,
      'h-[40px]': config.size === LoadingButtonSize.SM,
      'h-[48px]': config.size === LoadingButtonSize.MD,
      'min-w-[40px]': config.size === LoadingButtonSize.SM && config.width !== LoadingButtonWidth.BASE,
      'min-w-[48px]': config.size === LoadingButtonSize.MD && config.width !== LoadingButtonWidth.BASE,
      'bg-primary-500 hover:bg-primary-400 focus:bg-primary-500 active:bg-primary-600':
        config.variant === LoadingButtonVariant.GREEN && !config.loading && !config.disabled,
      'bg-red-500 hover:bg-red-400 focus:bg-red-500 active:bg-red-600':
        config.variant === LoadingButtonVariant.RED && !config.loading && !config.disabled,
      'bg-grey-300': config.loading || config.disabled,
      'pointer-events-none': config.loading || config.disabled,
    }),
    loader: clsx({
      'w-[28px] h-[28px] mr-[10px]': config.size === LoadingButtonSize.MD,
      'w-[20px] h-[20px] m-[7px]': config.size === LoadingButtonSize.SM,
    }),
    text: clsx(['font-quicksand font-bold text-base', config.loading || config.disabled ? 'text-grey-100' : 'text-white']),
  };

  return styles;
};

export const LoadingButton: FC<LoadingButtonProps> = ({
                                                        onClick = undefined,
                                                        text = '',
                                                        className = '',
                                                        loading = false,
                                                        disabled = false,
                                                        variant = LoadingButtonVariant.GREEN,
                                                        size = LoadingButtonSize.MD,
                                                        width = LoadingButtonWidth.FIT,
                                                        testId = "loadingButton"
                                                      }) => {
  const styles = useMemo(() => getStyles({
    variant,
    size,
    width,
    loading,
    disabled,
    hasText: text.length > 0,
  }), [variant, size, width, loading, disabled, text?.length]);
  
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx([styles.button, className])}
      tabIndex={0}
      disabled={loading || disabled}
      data-testid={testId}
    >
      {loading &&
        <Img
          src={spinnerSm}
          className={clsx(
            'animate-spin',
            styles.loader,
            className,
          )}
          alt="Spinner"
        />}
      {text && <span className={styles.text}>{text}</span>}
    </button>
  );
};
