import {SubscriptionStateEnum, WebsocketErrorType, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {BaseEventConsumerI, EventParams, SubscriptionEventsServiceI} from './SubscriptionEventsService';
import {AlertConfig} from './helpers/AlertService';
import {wsActiveEventsRepository, wsEventsRepository} from '../state/rootRepository';
import {AnyObject} from 'yup/es/types';
import {notificationsManager} from './NotificationsAlertQueue';
import {NotificationDataType} from './helpers/NotificationDataParsers';
import {errorsManager, keysTransformers} from './ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {ToastVariant} from '@symfonia/brandbook';
import {BaseKsefEventContextDataHandlerI} from './KsefEventContextDataHandler';


export interface EventConsumerI<T extends string | number = WebsocketNotificationType, P extends AnyObject = EventParams> extends BaseEventConsumerI<T, P> {
  register(eventSystem: SubscriptionEventsServiceI<P, T>): () => void;

  get registered(): boolean;

  cancel(): void;
}

const DEFAULT_TRIGGER_STATES: SubscriptionStateEnum[] = Object.values(SubscriptionStateEnum);

export type IEventConsumerPublic = Omit<EventConsumerI, 'onUpdate'>

export abstract class EventConsumer implements EventConsumerI {
  protected readonly triggerStates!: Set<SubscriptionStateEnum>;
  private unsubscribe?: () => void;

  protected constructor(public readonly type: WebsocketNotificationType, triggerStates: SubscriptionStateEnum | SubscriptionStateEnum[] = DEFAULT_TRIGGER_STATES) {
    this.triggerStates = new Set<SubscriptionStateEnum>(Array.isArray(triggerStates) ? triggerStates : [triggerStates]);
  }

  public get registered() {
    return !!this.unsubscribe;
  }

  public abstract onUpdate(event: EventParams, data?: ((event: EventParams) => (NotificationDataType | Promise<NotificationDataType | null> | null)) | NotificationDataType | null): void | Promise<void>;

  public register(eventSystem: SubscriptionEventsServiceI): () => void {
    return this.unsubscribe = eventSystem.subscribe(this).unsubscribe;
  }

  public cancel() {
    this.unsubscribe?.();
    this.unsubscribe = undefined;
  }

  protected shouldTrigger(subscriptionState: SubscriptionStateEnum): boolean {
    return this.triggerStates.has(subscriptionState);
  }
}

//Klasa abstraktyjna przeznaczona do konsumowania eventów przychhodzących z subskrypcji websocketowej dla ksef
//KLASA SŁUŻY JEDYNIE DO KONSUMOWANIE EVENTÓW! Uruchomienie procesu emitującego event na backendzie odbywa się przy pomocy klasy JobRunner
export abstract class BaseEventConsumer<T extends BaseKsefEventContextDataHandlerI = BaseKsefEventContextDataHandlerI> extends EventConsumer implements EventConsumerI {

  protected constructor(public type: WebsocketNotificationType, protected notificationService?: T, private readonly alertsConfig?: Record<'error' | 'success', AlertConfig | ((cancelled: boolean) => AlertConfig)>) {
    super(type, [SubscriptionStateEnum.Finished, SubscriptionStateEnum.Cancelled]);
  }

  public async onUpdate(event: EventParams, dataGetter?: ((event: EventParams) => NotificationDataType | null | Promise<NotificationDataType | null>) | NotificationDataType | null): Promise<void> {
    if (!this.shouldTrigger(event.state)) {
      return;
    }

    this.notificationService?.setErrorType?.(event.errorType);

    //pobranie danych kontekstowych dla eventu (zależnych od jego typu i rezultatu procesu)
    const data = typeof dataGetter === 'function' ? await dataGetter(event) : dataGetter;
    //przetworzenie danych z eventu w celu przygotowania notyfikacji dla użytkownika
    const {
      error,
      success,
      omitAlert,
    } = this.mergeHandlers(event.notificationId, event.state === SubscriptionStateEnum.Cancelled, event.errorType);

    //zapisanie eventu do stanu ukończonych procesów jako archiwalny event
    wsEventsRepository.addEvent(event, data ?? undefined);

    //usunięcie eventu ze stanu aktywnych procesów
    wsActiveEventsRepository.removeEvent(event.notificationId);


    //Obsuga generycznych błędów oraz dodanie alertu na UI
    const {
      alertConfig,
      matched,
      ignored,
    } = await errorsManager.handle(keysTransformers.lowercase(event), {fallback: error});
    if ((ignored && matched) || omitAlert) {
      return;
    }
    if (!event.errorType) {
      notificationsManager.addAlert(success);
      return;
    }
    notificationsManager.addAlert({...error, ...alertConfig});
  }


  private mergeHandlers(notificationId: string, cancelled: boolean, errorType?: WebsocketErrorType): Record<'error' | 'success', AlertConfig & {
    notificationId: string
  }> & { omitAlert?: boolean } {
    if (!this.alertsConfig) {
      return {omitAlert: true, error: {notificationId}, success: {notificationId}};
    }
    const success = typeof this.alertsConfig.success === 'function' ? this.alertsConfig.success(cancelled) : this.alertsConfig.success;
    const error = typeof this.alertsConfig.error === 'function' ? this.alertsConfig.error(cancelled) : this.alertsConfig.error;
    return {
      success: {
        notificationId,
        color: ToastVariant.SUCCESS,
        duration: 8000,
        ...success,
        onClick: () => {
          success.onClick?.();
          wsEventsRepository.setMessageRead(notificationId);
        },
        onClose: () => {
          success.onClose?.();
          wsEventsRepository.setMessageRead(notificationId);
        },
      },
      error: {
        notificationId,
        color: ToastVariant.ERROR,
        ...error,
        onClick: () => {
          error.onClick?.();
          wsEventsRepository.setMessageRead(notificationId);
        },
        onClose: () => {
          error.onClose?.();
          wsEventsRepository.setMessageRead(notificationId);
        },
      },
    };
  }
}



