import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';

interface DoubleNameErrorModalProps {
  open: boolean;
  onChange: () => void;
  onCancel: () => void;
}

export const DoubleNameErrorModal: FC<DoubleNameErrorModalProps> = ({open, onChange, onCancel}) => {
  const intl = useIntl();
  const handleChange = () => {
    onChange();
  };
  const handleCancel = () => {
    onCancel();
  };

  return (
      <Modal
        onClose={handleCancel}
        title={intl.formatMessage({id: Tr.categoryAlreadyExist})}
        show={open}
        centerButtons={true}
        className={'max-w-[500px]'}
      >
        <p>{intl.formatMessage({id: Tr.categoryAlreadyExistTxt})}</p>
        <div className="flex gap-[16px] pt-[24px] justify-between">
          <ButtonSecondary
            onClick={handleCancel}
            text={intl.formatMessage({id: Tr.closeLabel})}
            width={ButtonSecondaryWidth.BASE}
          />
          <ButtonPrimary
            onClick={handleChange}
            text={intl.formatMessage({id: Tr.change})}
            width={ButtonPrimaryWidth.BASE}
          />
        </div>
      </Modal>
  );
};
