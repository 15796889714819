import {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {useCopyToClipboard} from 'usehooks-ts';
import {Icon, IconColor, IconSvg, IconVariant} from '@symfonia/brandbook';

interface CopyToClipboardButtonProps {
  onClick: (id?: string) => string;
  id?: string;
}

export const CopyToClipboardButton = ({onClick, id}: CopyToClipboardButtonProps) => {
  const [copiedValue, copy] = useCopyToClipboard();
  const [disabled, setDisabled] = useState<boolean>();
  const handleClick = () => copy(onClick(id));

  useEffect(() => {
    setDisabled(!onClick(id));
  }, [id, onClick]);

  return (
    <IconButton
      test-id="copyToClickboard"
      onClick={handleClick}
      edge="end"
      sx={{
        ml: 1,
      }}
      disabled={disabled}
    >
      <Icon color={disabled ? IconColor.GREY_400 : IconColor.GREEN_600} svg={IconSvg.FILE_COPY} variant={IconVariant.FILLED}/>
    </IconButton>
  );
};
