import Snackbar from '@mui/material/Snackbar';
import {Toast, ToastDirection, ToastVariant} from '@symfonia/brandbook';
import {ApolloErrorMessages} from '../../../common';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {MessageModel} from '@symfonia-ksef/state/EarchiveState/AlertsState';

export interface SnackBarProps {
  message: MessageModel;
  onClose: () => void;
}

export const SnackBar: FC<SnackBarProps> = ({onClose, message}) => {
  const {error, color, content} = message;
  const intl = useIntl();
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={true}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Toast
        id={message.uid}
        description={color === ToastVariant.ERROR && error ? <ApolloErrorMessages error={error}/> : content}
        variant={color}
        onClose={onClose}
        actionButton={undefined}
        direction={ToastDirection.VERTICAL}
        className={undefined}
       />
    </Snackbar>
  );
};
