import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';

export const usePageTitle = () => {
  const intl = useIntl();
  const prefix = intl.formatMessage({id: Tr.appTitle});
  return {
    setPageTitle: (title: string) => {
      document.title = `${title} | ${prefix}`;
    },
  };
};
