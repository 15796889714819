import {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {Text} from '../../root/components/Text';

type KSeFConnection = {
  isDemo: boolean;
};

export const KSeFConnectionType: FC<KSeFConnection> = ({isDemo}) => {
  const intl = useIntl();
  return (
    <div className="mt-[12px]">
      <Text>
        <FormattedMessage
          id={Tr.typeOfConnection}
          values={{
            typeOfConnection: isDemo ? intl.formatMessage({id: 'DEMO'}) : intl.formatMessage({id: 'PROD'}),
          }}
        />
      </Text>
    </div>
  );
};