import {AbstractNotificationErrorService, ErrorEvent} from './NotificationErrorService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '../../../locales/translationKeys';
import {ErrorContent} from '../../SubscriptionEventsService';
import {intl} from '../../../modules/root/IntlProvider';
import {dateParser} from '../../../modules/common';

class KSeFLimitErrorService extends AbstractNotificationErrorService {
  constructor() {
    super(WebsocketErrorType.KsefLimit);
  }


  protected _createAlert(event: ErrorEvent): AlertConfig {
    const nextExecutionTime = this.findContentValue('PossibleExecutionTime', event.errorContent);
    let roundedNextExecutionTime: string | Date | undefined = nextExecutionTime;
    if (nextExecutionTime) {
      const execDate = new Date(nextExecutionTime);
      roundedNextExecutionTime = new Date(execDate.setMinutes(execDate.getSeconds() > 0 ? execDate.getMinutes() + 1 : execDate.getMinutes()));
    }
    return {
      id: Tr.ksefOperationError,
      values: {ksefDescription: intl.formatMessage({id: Tr.ksefRequestLimit}, {dateTime: dateParser(roundedNextExecutionTime, true, true)})},
    };
  }

  private createTimeLeft(content?: ErrorContent): number | undefined {
    const timeLeft = this.findContentValue('TimeLeft', content);
    return timeLeft === undefined ? timeLeft : Math.ceil(Number(timeLeft) / 60);
  }
}

export const kSeFLimitErrorService = new KSeFLimitErrorService();
