import {Tr} from '../../../../locales/translationKeys';
import {useIntl} from 'react-intl';
import {PageMessage} from '../../../common';

export const CompanyNotFound = () => {
  const intl = useIntl();

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.companyNotFoundTitle})}
      description={intl.formatMessage({id: Tr.companyNotFoundDesc})}
    />
  );
};
