import * as React from 'react';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IPostingAccountModalState} from './state/IPostingAccountModalState';
import {observer} from 'mobx-react-lite';
import {SearchTreeView} from '../../../../../auto-posting/components/AccountSelect/SearchTreeView';
import {TreeViewDropdownModel} from '../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {AccountTypeEnum} from '@symfonia-ksef/graphql';
import {Loader} from '../../../../../common/components/Loader/Loader';
import {AccountTypeRadioButtons} from '../../../../../auto-posting/components/AccountTypeRadioButtons/AccountTypeRadioButtons';
import {SearchInputForTree} from '../../../../../auto-posting/components/AccountSelect/SearchInputForTree';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';

interface PostingChooseAccountModalProps {
  state: IPostingAccountModalState;
  onClose: () => void;
  onSelected: (selected: TreeViewDropdownModel) => void;
  withRadioBtns?: boolean;
  onRadioBtnChange?: (type: AccountTypeEnum) => void;
  isLoading?: boolean;
}

export const PostingChooseAccountModal: FC<PostingChooseAccountModalProps> = observer(
  ({state, onClose, onSelected, withRadioBtns, onRadioBtnChange, isLoading}) => {
    const intl = useIntl();
    const handleClose = () => {
      state.handleClose();
      onClose();
    };

    return (
      <Modal
        onClose={handleClose}
        title={intl.formatMessage({id: Tr.chooseAccount})}
        show={state.isOpen}
        centerButtons={true}
        okButton={{
          disabled: !state.canSave,
          text: intl.formatMessage({id: Tr.choose}),
          onClick: () => onSelected(state.searchTreeViewState.selected!),
        }}
        cancelButton={{onClick: handleClose, text: intl.formatMessage({id: Tr.cancelButton})}}
      >
        <div className="flex flex-col h-full gap-y-[20px]">
          {withRadioBtns && <AccountTypeRadioButtons onRadioBtnChange={onRadioBtnChange}/>}
          <SearchInputForTree state={state.searchTreeViewState}/>
          <div className={'max-h-[380px] overflow-y-scroll'}>
            {isLoading ? <Loader/> : <SearchTreeView state={state.searchTreeViewState}/>}
          </div>
        </div>
      </Modal>
    );
  },
);
