import {useIntl} from 'react-intl';
import React, {FC} from 'react';
import {Tr} from '../../../../../../../../../../locales/translationKeys';
import {observer} from 'mobx-react-lite';
import {CategoryRulesFiltersKeys} from '../../../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {ICategoryDetailsState} from '../../state/ICategoryDetailsState';
import {DateRangeFilterEnumPosting} from '@symfonia-ksef/state/filters/BaseFilterState';
import {Accordion, AccordionSize, DatePicker, MultiSelectFilter} from '@symfonia/symfonia-ksef-components';
import {
  convertToDropdownListOption,
  convertToMapDropdownListOption,
} from '../../../../../../../../../common/helpers/baseFilterHelpers';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';

export interface CategoryDetailsTableFiltersConfigProps {
  state: ICategoryDetailsState;
}

export const CategoryDetailsTableFiltersConfig: FC<CategoryDetailsTableFiltersConfigProps> = observer(({state}) => {
  const intl = useIntl();

  const filterState = state.filterState;

  if (!filterState) {
    return <></>;
  }

  const positionNameFilter = filterState.activeFilters.get(CategoryRulesFiltersKeys.PositionName);
  const categoryNamesFilter = filterState.activeFilters.get(CategoryRulesFiltersKeys.CategoryNames);
  const userIdsFilter = filterState.activeFilters.get(CategoryRulesFiltersKeys.UserIds);
  const modifiedFilter = filterState.activeFilters.get(CategoryRulesFiltersKeys.ModifiedAt);
  const typeFilter = filterState.activeFilters.get(CategoryRulesFiltersKeys.InvoiceTypeGroup);

  return (
    <>
      <Accordion
        group={'expression'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.expression}) +
          (positionNameFilter?.values !== undefined && positionNameFilter?.values?.length !== 0
            ? ' (' + positionNameFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.filterState.positions, el => ({
            label: el.value,
            value: el.key,
          }))}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoryRulesFiltersKeys.PositionName)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoryRulesFiltersKeys.PositionName)
          }
        />
      </Accordion>

      <Accordion
        group={'categoryName'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.categoryName}) +
          (categoryNamesFilter?.values !== undefined && categoryNamesFilter?.values?.length !== 0
            ? ' (' + categoryNamesFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.filterState.categories, el => ({
            label: el.value,
            value: el.key,
          }))}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoryRulesFiltersKeys.CategoryNames)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoryRulesFiltersKeys.CategoryNames)
          }
        />
      </Accordion>

      <Accordion
        group={'type'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.type}) +
          (typeFilter?.values !== undefined && typeFilter?.values?.length !== 0
            ? ' (' + typeFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.filterState.parent.typeFilterObject, el => ({
            label: el.value,
            value: el.key,
          }))}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoryRulesFiltersKeys.InvoiceTypeGroup)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoryRulesFiltersKeys.InvoiceTypeGroup)
          }
        />
      </Accordion>

      <Accordion
        group={'user'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.user}) +
          (userIdsFilter?.values !== undefined && userIdsFilter?.values?.length !== 0
            ? ' (' + userIdsFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={convertToMapDropdownListOption(state.filterState.users, el => ({
            label: el.value,
            value: el.key,
          }))}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoryRulesFiltersKeys.UserIds)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoryRulesFiltersKeys.UserIds)
          }
        />
      </Accordion>

      <Accordion group={'dateLabel'} size={AccordionSize.SM} title={intl.formatMessage({id: Tr.dateLabel})}>
        <DatePicker
          startDate={modifiedFilter?.values[0]?.from ?? undefined}
          endDate={modifiedFilter?.values[0]?.to ?? undefined}
          specificDate={modifiedFilter?.values[0].specific ?? undefined}
          setDate={v => filterState.setRangeFilter(CategoryRulesFiltersKeys.ModifiedAt, v, DateRangeFilterEnumPosting)}
          singleDateLabel={intl.formatMessage({id: Tr.dateLabel})}
          rangeStartDateLabel={intl.formatMessage({id: Tr.dateFrom})}
          singleDateInputLabel={intl.formatMessage({id: Tr.selectSpecificDate})}
          rangeDateInputLabel={intl.formatMessage({id: Tr.selectDate})}
          setValidationError={filterState.setHasError}
        />
      </Accordion>
    </>
  );
});
