import * as React from 'react';
import {Tab} from '@symfonia/brandbook';
import {FeatureToggle} from '../FeatureToggle/FeatureToggle';
import {On} from '../FeatureToggle/OnOff';
import {FeatureSwitchTypeEnum} from '../../enums/FeatureSwitchTypeEnum';
import {IModuleStatus} from '../../../../state/ModuleSettings/IModuleStatus';
import {ModulesEnum} from '../../enums/ModulesEnum';
import {ModuleToggle} from '../ModuleToggle/ModuleToggle';
import {NotInitialized} from '../ModuleToggle/NotInitialized/NotInitialized';
import ErrorIcon from '@mui/icons-material/Error';

export interface NavTabsElementChild {
  label: string;
  href: string;
  'test-id': string;
  errorIcon?: boolean;
}

export interface NavTabsElement {
  isRequiredAction?: boolean;
  label: string;
  active: boolean;
  href: string;
  children?: NavTabsElementChild[];
  disabled?: boolean;
  feature?: FeatureSwitchTypeEnum;
  module?: ModulesEnum;
  moduleStatus?: IModuleStatus;
  'test-id': string;
}

export interface NavTabsProps {
  elements: NavTabsElement[];
}

export const NavTabs = (props: NavTabsProps) => {
  return (
    <div className="flex">
      {props.elements.map((element, index) => {
        const tab = (
          <Tab
            text={element.label}
            disabled={element.disabled}
            href={element.href}
            isActive={element.active}
            children={element.isRequiredAction ? <ErrorIcon className={'IconRedOnHover'}/> : <></>}
            dropdown={
              element.children?.length
                ? {
                    options: element.children.map(child => ({
                      label: child.label,
                      value: child.href,
                    })),
                  }
                : undefined
            }
          />
        );
        return (
          <FeatureToggle feature={element.feature ?? FeatureSwitchTypeEnum.default} key={index}>
            <On>
              <ModuleToggle disableCheck={!element.module} moduleStatus={element.moduleStatus}>
                {tab}
                <NotInitialized>{tab}</NotInitialized>
              </ModuleToggle>
            </On>
          </FeatureToggle>
        );
      })}
    </div>
  );
};
