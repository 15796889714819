import {action, IObservableArray, makeObservable, observable} from "mobx";
import {IPostingTableStore} from "./IPostingTableStore";
import {IPostingTableState} from "./IPostingTableState";
import {GetPostedInvoicesFiltersDataQueryFunction} from '../../Queries/GetPostedInvoicesFiltersDataQueryFunction';
import {ContractorViewModel} from '@symfonia-ksef/graphql';

export class PostingTableStore implements IPostingTableStore {
  @observable
  public contractors: IObservableArray<ContractorViewModel> = observable([]);

  public postingTableState: IPostingTableState;

  constructor(postingTableState: IPostingTableState) {
    makeObservable(this);
    this.postingTableState = postingTableState;
  }

  @action
  async fetchContractorsFilterData() {
    return await GetPostedInvoicesFiltersDataQueryFunction(
      this.postingTableState.postingState.earchiveState.companyId
    )
      .then(data => {
        if (data) {
          this.contractors.replace(data.Contractors as ContractorViewModel[]);
        }
      });
  }
}
