import {ITreeNodeWithChildren} from "../../common/components/TreeViewDropdown/TreeViewDropdown";

export class treeNodeHelper {

	/**
	 * Helper function to map flat tree list to proper n-ary tree
	 * @param nodes Flat list where every element has id of a parent node and list of children ids
	 * @returns List of root level nodes with all parent/child relations fully mapped
	 */
	static mapListToTree<T extends ITreeNodeWithChildren>(nodes: T[], map: Map<string, T>): T[] {
		const nodesParents = nodes.filter(x => x.parentId === null);
		const nodesToReturn: T[] = [];
		nodesParents.forEach(element => {
			this.setAccountWithChildren(element, nodesToReturn, map);
		});

		return nodesToReturn.filter(x => x.parentId === null);
	}

	/**
	 * Helper function to find all ancestors of element in treelike structure
	 * @param node Element parents of which we want to find
	 * @param nodesMap Map of all possible elements in structure
	 * @returns List containing node and all its ancestors
	 */
	static findAllNodeAncestors<T extends ITreeNodeWithChildren>(node: T, nodesMap: Map<string, T>): T[] {
		const ancestorList: T[] = [];

		let temp = nodesMap.get(node.key);

		if (!temp) {
			throw new Error(`Element ${node.key} is missing in map`);
		}

		ancestorList.push(temp);

		while (temp.parentId !== null && temp.parentId !== undefined && nodesMap.has(temp.parentId)) {
			temp = nodesMap.get(temp.parentId)!;

			ancestorList.push(temp);
		}

		return ancestorList;
	}

	static setAccountWithChildren<T extends ITreeNodeWithChildren>(currentNode: T, nodesToReturn: T[], map: Map<string, T>) : T[] {
		nodesToReturn.push(currentNode);
		if (currentNode.parentId !== null && currentNode.parentId !== undefined) {
			currentNode.parent = map.get(currentNode.parentId);
	}
		if (currentNode.childrenIds !== null && currentNode.childrenIds !== undefined && currentNode.childrenIds.length > 0) {
			currentNode.children = currentNode.childrenIds.map(childId => map.get(childId) as T);
			currentNode.children.forEach(childElement => {
				this.setAccountWithChildren(childElement, nodesToReturn, map);
			});
		}

		return nodesToReturn;
	}
}
