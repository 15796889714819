import {SubscriptionEventsServiceI} from '../SubscriptionEventsService';
import {AuthorizeInKSeFEventConsumer} from './AuthorizeInKSeFEventConsumer';
import {GetUPOEventConsumer} from './GetUPOEventConsumer';
import {DeleteInvoicesEventConsumer} from './DeleteInvoicesEventConsumer';
import {DownloadedInvoicesResultConsumer} from './DownloadedInvoicesResultConsumer';
import {TerminateKSeFSessionEventConsumer} from './TerminateKSeFSessionEventConsumer';
import {RemoveAuthorizationInKSeFEventConsumer} from './RemoveAuthorizationInKSeFEventConsumer';
import {RemoveAuthorizationInKSeFWithTokenEventConsumer} from './RemoveAuthorizationInKSeFWithTokenEventConsumer';
import {AuthorizeInKSeFWithTokenEventConsumer} from './AuthorizeInKSeFWithTokenEventConsumer';
import {GrantPermissionsEventConsumer} from './GrantPermissionsEventConsumer';
import {KSeFAutoFetchingInvoicesConsumer} from './KSeFAutoFetchingInvoicesConsumer';
import {SendInvoicesToKSeFEventConsumer} from './SendInvoicesToKSeFEventConsumer';
import {AutoSendInvoicesToKSeFEventConsumer} from './AutoSendInvoicesToKSeFEventConsumer';
import {
  AutoRemoveAuthorizationInKSeFWithTokenEventConsumer,
} from './AutoRemoveAuthorizationInKSeFWithTokenEventConsumer';
import {RegistrationNumberCreatedEventConsumer} from './RegistrationNumberCreatedEventConsumer';
import {UploadKSeFInvoicesEventConsumer} from './UploadKSeFInvoicesEventConsumer';
import {OcrInvoiceUploadedKSeFEventConsumer} from './OcrInvoiceUploadedKSeFEventConsumer';
import {PostInvoicesFailedEventConsumer} from './PostInvoicesFailedEventConsumer';
import {CheckWhiteListEventConsumer} from './CheckWhiteListEventConsumer';
import {
  AutoFetchingEventSynchronizer,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/AutoFetchingEventSynchronizer';

export const registerGlobalNotificationEvents = (eventService: SubscriptionEventsServiceI) => [
  new AutoFetchingEventSynchronizer(),
  new AuthorizeInKSeFEventConsumer(),
  new AuthorizeInKSeFWithTokenEventConsumer(),
  new GetUPOEventConsumer(),
  new DownloadedInvoicesResultConsumer(),
  new GrantPermissionsEventConsumer(),
  new TerminateKSeFSessionEventConsumer(),
  new RemoveAuthorizationInKSeFEventConsumer(),
  new RemoveAuthorizationInKSeFWithTokenEventConsumer(),
  new AutoRemoveAuthorizationInKSeFWithTokenEventConsumer(),
  new KSeFAutoFetchingInvoicesConsumer(),
  new SendInvoicesToKSeFEventConsumer(),
  new AutoSendInvoicesToKSeFEventConsumer(),
  new RegistrationNumberCreatedEventConsumer(),
  new UploadKSeFInvoicesEventConsumer(),
  new OcrInvoiceUploadedKSeFEventConsumer(),
  new PostInvoicesFailedEventConsumer(),
  new DeleteInvoicesEventConsumer(),
  new CheckWhiteListEventConsumer(),
].forEach(consumer => consumer.register(eventService));
