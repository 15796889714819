import {
  AbstractNotificationErrorService,
  ErrorEvent,
  HandlerOptions,
  RequiredErrorEvent,
} from './NotificationErrorService';
import {KSeFAuthResult, WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {authenticationState} from '../../../state/rootRepository';
import {ErrorContent} from '../../SubscriptionEventsService';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

type CodesMatch = { tokenExpired: boolean, signature: boolean, token: boolean }

export const TOKEN_EXPIRED_EXCEPTION_CODES: readonly number[] = Object.freeze([21302, 21303, 21154]);
export const TOKEN_EXCEPTION_CODES: readonly number[] = Object.freeze([21112, 21116, 21409, 21304, 21301]);
export const SIGNATURE_EXCEPTION_CODES: readonly number[] = Object.freeze([21159, 21407, 21115, 21305, 21406, 9102]);

class KSeFAuthorizationErrorService extends AbstractNotificationErrorService {

  constructor() {
    super(WebsocketErrorType.KsefAuthorization);
  }


  protected override _createAlert(event: ErrorEvent): AlertConfig {
    const code = this.getExceptionCode(event.errorContent);
    if (code && [...TOKEN_EXPIRED_EXCEPTION_CODES, ...TOKEN_EXCEPTION_CODES].includes(code)) {
      return {
        id: Tr.authSubTokenError,
        values: {ksefEnv: earchiveState.layoutState.isDemo ? 'DEMO' : 'PROD'},
      };
    }
    return {
      id: Tr.ksefOperationError,
      values: {ksefDescription: this.getExceptionDescription(event.errorContent)},
    };
  }

  protected override _handle(event: RequiredErrorEvent, {historical}: HandlerOptions): void {
    if (this.createMatches(event.errorContent).tokenExpired) {
      authenticationState.setAutoUnauthorizedNotificationId(event.notificationId);
      authenticationState.setExpiredToken(true);
      authenticationState.updateUserAuthAndCredentials(KSeFAuthResult.NotAuthorized);
      authenticationState.setUnauthorized();
    }
  }

  private createMatches(content?: ErrorContent): CodesMatch {
    const code = this.getExceptionCode(content);
    const matched: CodesMatch = {
      token: false,
      tokenExpired: false,
      signature: false,
    };
    if (code === undefined) {
      return matched;
    }
    if (TOKEN_EXPIRED_EXCEPTION_CODES.includes(code)) {
      matched.tokenExpired = true;
      return matched;
    }
    if (TOKEN_EXCEPTION_CODES.includes(code)) {
      matched.token = true;
      return matched;
    }
    if (SIGNATURE_EXCEPTION_CODES.includes(code)) {
      matched.signature = true;
      return matched;
    }
    return matched;
  }
}

export const kSeFAuthorizationErrorService = new KSeFAuthorizationErrorService();
