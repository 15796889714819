import {FC, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Breadcrumbs, ButtonPrimary, ButtonTertiary, ButtonTertiarySize, IconSvg} from '@symfonia/brandbook';
import {Header} from '../../../../../../../root/components/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {IVehicleState} from './state/IVehicleState';
import {VehiclesPageService} from './VehiclesPage.service';
import {useModule} from '../../../../../../../root/services/MobXServices/BaseModule';
import {VehiclesTable} from './VehiclesTable/VehiclesTable';
import {VehicleAddEditModal} from '../../../../../../../common/components/VehicleAddEditModal/VehicleAddEditModal';
import {VehicleDeleteModal} from './modals/VehicleDeleteModal';
import {CompanyVehiclesResponse} from '@symfonia-ksef/graphql';
import TableHeader from '../../../../../../components/TableHeader/TableHeader';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {FiltersBar} from '../../../../../../components/FiltersBar/FiltersBar';

export interface VehiclesPageProps {
  state: IVehicleState;
}

export const VehiclesPage: FC<VehiclesPageProps> = observer(({state}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [mainFilterMenuOpen, setMainFilterMenuOpen] = useState<boolean>(false);
  const envId = earchiveState.company.companyId ?? '';

  const vehicleModalState = state.vehicleModalState;

  const service = useModule(() => new VehiclesPageService(state));

  const {companyId} = useParams<{companyId: string}>();

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.category}),
    },
    {
      label: intl.formatMessage({id: Tr.vehicles}),
    },
  ];

  const onOpen = () => {
    vehicleModalState.setModalTitle(Tr.addVehicle, false);
    vehicleModalState.setOpenModal(true);
  };
  const handleSaveVehicle = async (companyVehicle: CompanyVehiclesResponse) => {
    state.vehicleModalState.setSelectedVehicle(companyVehicle);
    state.vehicleModalState.isEditModelType
      ? await vehicleModalState.updateVehicle(companyId, false)
      : await vehicleModalState.saveVehicle(envId, companyId, false);

    service.tableService.fetch();
    vehicleModalState.resetVehicle();
    vehicleModalState.setOpenModal(false);
  };

  const handleDeleteVehicle = async () => {
    const tableService = service.tableService;
    const isLastPage = tableService.currentPage === tableService.pagesCapacity;
    const isLastElementOnPage = state.vehicles.length === 1;
    state.deleteVehicle(envId).then(() => {
      isLastPage &&
        isLastElementOnPage &&
        tableService.currentPage !== 1 &&
        service.tableService.setPagination({page: tableService.currentPage - 1});
      service.tableService.fetch();
    });
    vehicleModalState.resetVehicle();
    vehicleModalState.setOpenModal(false);
  };

  const handleOpenAndLoad = useCallback((v: boolean) => {
    setMainFilterMenuOpen(v);
  }, []);

  return (
    <>
      <VehicleAddEditModal
        open={vehicleModalState.modalAddEditOpen}
        isDecreeDetails={false}
        title={vehicleModalState.modalTitle}
        companyVehicle={vehicleModalState.editedVehicle}
        selectedVehicle={vehicleModalState.selectedVehicle}
        onSave={vehicle => handleSaveVehicle(vehicle)}
        onClose={() => vehicleModalState.resetVehicle()}
        checkIfLicensePlateExist={(r: string) => vehicleModalState.checkIfLicensePlateExist(r, companyId)}
      />
      <VehicleDeleteModal
        registrationNumberPlate={vehicleModalState.selectedVehicle?.RegistrationNumber ?? ''}
        open={state.modalDeleteOpen}
        onDelete={() => handleDeleteVehicle()}
        onCancel={() => {
          state.setModalDeleteOpen(false);
          vehicleModalState.resetVehicle();
        }}
      />
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <div className="flex justify-between w-full">
          <div className="flex flex-col items-start content-between">
            <ButtonTertiary
              lIcon={IconSvg.CHEVRON_LEFT}
              text={intl.formatMessage({id: Tr.goBackToConfiguration})}
              onClick={() => navigate('../')}
              className={'md:flex hidden'}
            />
            <Header>{intl.formatMessage({id: Tr.vehicles})}</Header>
          </div>
          <div className="flex items-end">
            <ButtonPrimary text={intl.formatMessage({id: Tr.addVehicle})} onClick={onOpen} lIcon={IconSvg.ADD}/>
          </div>
        </div>

        <main className="flex justify-start lg:flex-row flex-col">
          <section className="lg:w-[100%] md:w-[90%] w-full grid items-center">
            <div className="flex flex-col min-h-[280px] w-full">
              <TableHeader translationKey={Tr.search} searchService={service.searchService}>
                <div className="flex items-center mr-[8px] my-[10px]">
                  <FiltersBar
                    isFiltersMenuOpened={mainFilterMenuOpen}
                    openFiltersMenu={() => handleOpenAndLoad(!mainFilterMenuOpen)}
                    tableLength={service.tableService.rows.length}
                    filtersApplied={state.filterState.pillsList.size > 0}
                  />
                  {state.filterState.pillsList.size > 0 && (
                    <ButtonTertiary
                      className="mx-[8px] mt-[6px]"
                      size={ButtonTertiarySize.SM}
                      onClick={() => {
                        state.filterState.handleClearMenuFilter();
                        service.repository.fetch();
                      }}
                      text={intl.formatMessage({id: Tr.clearFiltersLabel})}
                      lIcon={IconSvg.CLOSE}
                    />
                  )}
                </div>
              </TableHeader>
              <VehiclesTable
                state={state}
                pageService={service}
                filtersMenuIsOpen={mainFilterMenuOpen}
                setFiltersMenuIsOpen={setMainFilterMenuOpen}
              />
            </div>
          </section>
        </main>
      </div>
    </>
  );
});
