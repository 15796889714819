import * as Yup from 'yup';
import {IdentifierType} from '@symfonia-ksef/graphql';
import {validateNip, validatePesel} from '@symfonia/utils';

export default Yup.object().shape({
  Identifier: Yup.string()
    .ensure()
    .when('IdentifierType', {
      is: IdentifierType.Nip,
      then: Yup.string()
        .required('Pole wymagane')
        .test('is-valid-nip', 'Podaj prawidłowy NIP.', (value) => {
          return value ? validateNip(value) : false;
        }),
    })
    .when('IdentifierType', {
      is: IdentifierType.Pesel,
      then: Yup.string()
        .required('Pole wymagane')
        .test('is-valid-pesel', 'Podaj prawidłowy PESEL.', (value) => {
          return value ? validatePesel(value) : false;
        }),
    }),
  // Pole Nazwa wlasna zostaje ukryte na czas wydania wersji czerwcowej 2022. W przyszlosci do odkomentowania
  // Name: Yup.string()
  //   .trim()
  //   .required('Pole wymagane')
  //   .min(2, 'Tekst jest zbyt krótki!')
  //   .max(100, 'Wartość zawiera zbyt wiele znaków!'),
});
