import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppRoutes} from '../../../root/Routes';
import symf_ap_test from '../../../../assets/symf_ap_test.svg';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {Link, useNavigate} from 'react-router-dom';
import {Breadcrumbs, ButtonPrimary} from '@symfonia/brandbook';
import React from 'react';
import {Header} from '../../../root/components/Header';
import {usePageTitle} from '../../../common';

export const PostingNotConfiguredPage = observer(() => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();
  const {tenantId, companyId} = earchiveState.company;
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.symfonia}),
    },
    {
      label: intl.formatMessage({id: Tr.posting}),
    },
  ];

  setPageTitle(intl.formatMessage({id: Tr.posting}));

  return (
    <PageContent>
      <div
        className={
          'md:px-[28px] sm:my-[20px] my-0 mx-auto max-w-[1920px] flex flex-col w-full ' +
          'justify-start items-start bg-white box-border'
        }
      >
        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <Header>{intl.formatMessage({id: Tr.decreesList})}</Header>
        <div
          className="flex justify-center items-center font-medium bg-white sm:p-[32px] pt-[10px] md:flex-row flex-col
        md:w-full sm:w-[80%] w-full md:shadow-none sm:shadow-lg shadow-none rounded-xl m-auto md:mt-0 mt-[10px]"
        >
          <div className={'max-w-[800px] md:w-1/2 sm:w-full w-full md:h-full sm:h-[400px] h-[200px] mr-[20px]'}>
            <img
              src={symf_ap_test}
              alt="symf_ap_test"
              className="md:mb-0 sm:mb-[40px] mb-0 object-cover w-full h-full"
            />
          </div>
          <div className={'md:w-[45%] w-full flex flex-col'}>
            <h1 className={'font-bold text-2xl mb-[20px]'}>{intl.formatMessage({id: Tr.autopostingConfigTitle})}</h1>
            <p>
              <FormattedMessage
                id={Tr.apTestConfigurationMsg}
                values={{
                  b: chunks => <strong>{chunks}</strong>,
                  br: <br/>,
                  configurationLink: (
                    <Link
                      className="text-primary-green"
                      to={AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + '/account/posting'}
                    >
                      {intl.formatMessage({id: Tr.decreeConfigure})}
                    </Link>
                  ),
                  writeUsLink: (
                    <Link
                      className="text-primary-green"
                      target="_blank"
                      to={'https://glosklienta.symfonia.pl/hc/pl/community/topics'}
                    >
                      {intl.formatMessage({id: Tr.writeUs})}
                    </Link>
                  ),
                }}
              />
            </p>
            <ButtonPrimary
              className={'mt-[30px] md:self-start self-end sm:w-auto w-full'}
              text={intl.formatMessage({id: Tr.autopostingConfig})}
              onClick={() =>
                navigate(AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + '/account/posting')
              }
            />
          </div>
        </div>
      </div>
    </PageContent>
  );
});
