import {environment} from '../../../../environments/environment';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../root/IntlProvider';
import {Footer} from '@symfonia/brandbook';


const footerLinks = [
  {label: intl.formatMessage({id: Tr.regulations}), href: environment.cloudConsentDocumentUrl},
  {label: intl.formatMessage({id: Tr.privacyPolicy}), href: environment.privacyPolicyDocumentUrl},
  {label: intl.formatMessage({id: Tr.onlineDocumentation}), href: environment.onlineDocumentationUrl},
  {label: intl.formatMessage({id: Tr.contact}), href: environment.contactDocumentUrl},
];

export const FooterComponent = () => {

  return <Footer
    links={footerLinks}
    reactAppVersion={process.env.NX_REACT_APP_VERSION}
  />;
};
