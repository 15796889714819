import {IModuleStatus} from '../../../state/ModuleSettings/IModuleStatus';
import {ModuleStatus} from '../../../state/ModuleSettings/ModuleStatus';
import {
  GetAutoPostingModuleStatusDocument,
  GetAutoPostingModuleStatusQuery,
  GetAutoPostingModuleStatusQueryVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../root/providers/GraphQLProvider';
import {ModulesEnum} from '../../common/enums/ModulesEnum';

export const GetAutoPostingModuleStatusQueryFunction = (companyId: string): (() => Promise<IModuleStatus>) => {
  return () =>
    apolloClient
      .query<GetAutoPostingModuleStatusQuery, GetAutoPostingModuleStatusQueryVariables>({
        query: GetAutoPostingModuleStatusDocument,
        context: {
          envId: companyId,
        },
      })
      .then(response => {
        if (response.errors) {
          throw new Error(response.errors.toString());
        }

        const data = response.data.GetAutoPostingModuleStatus.ModuleSettings;
        return new ModuleStatus(
          ModulesEnum.autoPosting,
          data.ModuleSubscriptionStatusValue,
          data.InitializationStatusValue,
          data.InitializationTaskTypeValue,
          data.ErrorDetails,
        );
      });
};
