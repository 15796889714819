import {Tr} from '../../locales/translationKeys';
import {AlertConfig} from './AlertService';
import {GrantPermissionsDataModel, KSeFRequestForCredentialResultType} from './NotificationDataParsers';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {intl} from '../../modules/root/IntlProvider';
import {AppRoutes} from '../../modules/root/Routes';
import {errorsManager} from '../ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {ToastVariant} from '@symfonia/brandbook';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';


export const messagesMap: Record<KSeFRequestForCredentialResultType, { success: Tr, error: Tr }> = {
  [KSeFRequestForCredentialResultType.Edit]: {
    success: Tr.editKSeFPermissionSuccess,
    error: Tr.editKSeFPermissionError,
  },
  [KSeFRequestForCredentialResultType.Grant]: {
    success: Tr.addKSeFPermissionSuccess,
    error: Tr.addKSeFPermissionError,
  },
  [KSeFRequestForCredentialResultType.Revoke]: {
    success: Tr.removeKSeFPermissionSuccess,
    error: Tr.removeKSeFPermissionError,
  },
  [KSeFRequestForCredentialResultType.None]: {
    success: Tr.grantKSeFPermissionSubSuccess,
    error: Tr.grantKSeFPermissionSubError,
  },
};

export const getGrantPermissionsMessage = (type?: KSeFRequestForCredentialResultType, success?: boolean) => {
  const notificationType = success ? 'success' : 'error';
  if (type === KSeFRequestForCredentialResultType.None) {
    return messagesMap[KSeFRequestForCredentialResultType.None][type === KSeFRequestForCredentialResultType.None ? 'success' : notificationType];
  }
  return messagesMap[type ?? KSeFRequestForCredentialResultType.None][notificationType];
};

export enum FeedbackType {
  Success = 'success',
  Error = 'error'
}

export const grantPermissionsFeedbackFactory = (envObserver: EnvObserverI, type: FeedbackType, errorType?: WebsocketErrorType, data?: GrantPermissionsDataModel) => (): AlertConfig => {
  const {matched} = errorsManager.get({errorType});
  const error = type === FeedbackType.Error || matched || (data?.permissionList?.some?.(permission => !permission.success) ?? true);
  const success = !matched && ((data?.completed && !error || data?.operationType === KSeFRequestForCredentialResultType.None) ?? type === FeedbackType.Success);
  const messageId = getGrantPermissionsMessage(data?.operationType, success);
  const {companyId, tenantId} = envObserver.currentEnv;

  return {
    id: messageId,
    color: success ? ToastVariant.SUCCESS : ToastVariant.ERROR,
    values: {
      link: React.createElement(NavLink, {
        children: intl.formatMessage({id: Tr.CurrentKSeFPermissions}),
        to: AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + '/account/permissions',
      }),
    },
  };
};
