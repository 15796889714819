import {FC} from 'react';
import clsx from 'clsx';
import {Avatar, AvatarProps, AvatarSize, AvatarVariant} from './Avatar';

export enum AvatarLabelPosition {
  ASIDE = 'ASIDE',
  BELOW = 'BELOW',
}

export type AvatarWithLabelProps = AvatarProps & {
  labelPosition?: AvatarLabelPosition;
  labelText?: string;
  labelSubText?: string;
};

export const AvatarWithLabel: FC<AvatarWithLabelProps> = ({
  imgUrl = undefined,
  text = undefined,
  variant = AvatarVariant.EMPTY_GREEN,
  size = AvatarSize.MD,
  badge = undefined,
  userName = undefined,
  userSurname = undefined,
  labelPosition = AvatarLabelPosition.ASIDE,
  labelText = undefined,
  labelSubText = undefined,
  watermark = undefined,
  watermarkDisplay = undefined,
  onClick = undefined,
}) => {
  return (
    <span
      className={clsx({
        'inline-flex font-quicksand items-center': true,
        'flex-col': AvatarLabelPosition.BELOW === labelPosition,
        'flex-row': AvatarLabelPosition.ASIDE === labelPosition,
      })}
    >
      <Avatar
        className="self-center"
        watermark={watermark}
        watermarkDisplay={watermarkDisplay}
        onClick={onClick}
        userName={userName}
        userSurname={userSurname}
        badge={badge}
        variant={variant}
        size={size}
        imgUrl={imgUrl}
        text={text}
      />
      <span
        className={clsx({
          'inline-flex flex-col': true,
          'mt-[8px] items-center': AvatarLabelPosition.BELOW === labelPosition,
          'ml-[8px]': AvatarLabelPosition.ASIDE === labelPosition,
        })}
      >
        <div
          className={clsx('flex items-center leading-6 mb-[2px]', {
            'items-center': AvatarLabelPosition.ASIDE === labelPosition,
          })}
        >
          <span
            className={clsx({
              'leading-6 font-medium': true,
              'text-xl': size === AvatarSize.MD,
              'text-base': size === AvatarSize.SM,
            })}
          >
            {labelText}
          </span>
        </div>
        <div className="inline-flex items-center leading-4">
          <span className="text-sm leading-none">{labelSubText}</span>
        </div>
      </span>
    </span>
  );
};
