import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {UploadInvoicesDataModel} from '../../../../../../services/helpers/NotificationDataParsers';
import {ReactNode} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import ShowPopupIcon from '../../../../../../assets/ic_show_popup.svg';
import {
  PackageStatisticsRepositoryInterface,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {UploadKSeFInvoicesService} from '@symfonia-ksef/state/KSeFSubscriptionServices/UploadKSeFInvoicesService';

export class UploadInvoicesEventConverter extends KSeFEventsConverter<WebsocketNotificationType.UploadInvoices> {
  constructor(event: WebSocketNotificationFragment, private packageStatistics: PackageStatisticsRepositoryInterface, private uploadInvoicesService: UploadKSeFInvoicesService) {
    super(WebsocketNotificationType.UploadInvoices, event);
  }

  public get notification(): ReactNode {
    const successCount = this.model.data?.successItems?.length ?? 0;
    const subscriptionWasActive = this.packageStatistics.checkSubscriptionWasActiveFor(this.event.Timestamp);
    const hasError = this.model.hasError || !successCount;
    if (hasError) {
      return this.createNotification({id: subscriptionWasActive ? Tr.uploadInvoicesError : Tr.uploadInvoiceError});
    }
    return this.createNotification({id: subscriptionWasActive ? Tr.uploadInvoicesSuccess : Tr.uploadInvoiceSuccess, values: {count: successCount}});
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    if (!this.model.hasError) {
      return undefined;
    }
    return {
      icon: ShowPopupIcon,
      tooltipText: Tr.showMessage,
    };
  }

  public get model(): { hasError: boolean, data: UploadInvoicesDataModel | null } {
    const data = this.parser.parse();
    const hasError = !!this.event.ErrorType || (!data?.successItems?.length && !!data?.errorItems?.length);
    return {hasError, data};
  }

  public async action(): Promise<void> {
    const data = this.parser.parse({reparse: true});
    if (data) {
      this.uploadInvoicesService.setArchivedResult(data, this.event.NotificationId).setResultIsAvailable(true);
    }
    this.uploadInvoicesService.missingInvoicesCount && this.uploadInvoicesService.setModalIsActive(true);
  }
}
