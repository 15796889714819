import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {sendInvoicesResultService} from '../../state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';
import {AlertConfig} from '../helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';

export class AutoSendInvoicesToKSeFEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.AutoSendingInvoices, sendInvoicesResultService, {
      success: () => this.handleSuccess(),
      error: () => this.handleError(),
    });

  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await sendInvoicesResultService.getAsyncData(event, () => sendInvoicesResultService.setDownloadType(event.type)));
  }

  private handleError(config?: AlertConfig): AlertConfig {
    if (sendInvoicesResultService.someIsApproved) {
      return {id: Tr.sendToKsefSendingError, ...config};
    }
    const count = sendInvoicesResultService.returnedInvoicesCount ?? 0;
    return {id: Tr.autoSendToKSeFError, values: {count}, ...config};
  }

  private handleSuccess(): AlertConfig {
    if (sendInvoicesResultService.everyIsApproved) {
      const count = sendInvoicesResultService.returnedInvoicesCount ?? 0;
      return {id: Tr.autoSendToKSeFSuccess, values: {count}};
    }
    return this.handleError({color: ToastVariant.ERROR, duration: undefined});
  }
}
