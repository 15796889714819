import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Modal} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import FilehubModalHeader from '../components/FilehubModalHeader';
import {FilehubTable} from '../table/FilehubTable';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {useEffect} from 'react';
import AlertBuilder from './AlertBuilder';

type Props = {
  onClose: () => void;
  state: IFilehubState;
};

const FileHubModal = observer(({onClose, state}: Props) => {
  const hideRequestAlert = () => {
    state.setSuccessfulDeleteRequest(null);
    state.setSuccessfulMultipleActionsRequest(null);
  };

  const toggleAddAttachmentModal = () => {
    state.setIsAddAttachmentModalOpen(!state.isAddAttachmentModalOpen);
    state.setIsFileHubOpen(false);
    hideRequestAlert();
  };

  const {getAttachmentsAction} = useFilehubMethods(state);

  useEffect(() => {
    getAttachmentsAction(state.invoiceId);
  }, []);

  return (
    <>
      <Modal
        className="w-[1100px] xl:w-[1000px] xl:h-[600px]"
        onClose={onClose}
        show={state.isFileHubOpen}
        title={intl.formatMessage({id: Tr.attachmentsList})}
      >
        <div className="flex flex-col gap-[20px]">
          <FilehubModalHeader openAddAttachmentModal={toggleAddAttachmentModal} state={state}/>
          <FilehubTable state={state}/>
          <AlertBuilder state={state}/>
        </div>
      </Modal>
    </>
  );
});

export default FileHubModal;
