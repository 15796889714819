import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {mountStoreDevtool} from 'simple-zustand-devtools';
import {GenericState} from './zustandHelpers';
import {isLocalDev} from '../helpers/helperFunctions';

export interface IAuthState {
  isAuthenticated: boolean;
  currentUserEmail?: string;
  accessToken?: string;

  setUserData(isAuthenticated: boolean, currentUserEmail?: string, accessToken?: string): void;
}

const authStateData: GenericState<IAuthState> = (set, get) => ({
  isAuthenticated: false,
  currentUserEmail: undefined,
  accessToken: undefined,
  setUserData: (isAuthenticated, currentUserEmail, accessToken) => {
    set(state => ({
      isAuthenticated,
      currentUserEmail,
      accessToken,
    }));
  },
});

export const authState = create<IAuthState>()(immer(authStateData));

if (isLocalDev) {
  mountStoreDevtool('AuthState', authState);
}
