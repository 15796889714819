import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IconSvg, Input, InputSize, InputWidth} from '@symfonia/brandbook';
import {useIntl} from 'react-intl';
import {SearchServiceI} from '../services/SearchService/SearchService';

export const SearchInput: FC<{
  service: SearchServiceI;
  translationKey: Tr;
  className?: string;
  onFocusChange?: (focused: boolean) => void;
}> = observer(({service, className, onFocusChange, translationKey}) => {
  const intl = useIntl();
  return (
    <div className={className}>
      <Input
        autofocus={false}
        onBlur={() => onFocusChange?.(false)}
        onFocus={() => onFocusChange?.(true)}
        placeholder={intl.formatMessage({id: translationKey})}
        rightIcon={IconSvg.SEARCH}
        width={InputWidth.FULL}
        size={InputSize.SM}
        value={service.searchValue}
        onInput={service.setSearchValue}
      />
    </div>
  );
});
