import {NotificationDataService} from '../../services/NotificationDataService';
import {
  GetWebsocketNotificationModelQuery,
  UploadInvoicesMutationVariables,
  UpoMissingInvoiceReason,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {
  UploadKSeFInvoicesJobRunner,
  UploadKSeFInvoicesJobRunnerI,
} from '../../services/KSeFJobRunners/UploadKSeFInvoicesJobRunner';
import {action, computed, makeObservable, observable} from 'mobx';
import {MissingInvoiceModel} from '../../modules/earchive/models';
import {
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {UploadInvoicesDataModel} from '../../services/helpers/NotificationDataParsers';
import {toBase64} from '../../modules/common/helpers/toBase64';

import {AlertConfig} from '../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface UploadKSeFInvoicesServiceI {
  get multiple(): boolean;
}

export class UploadKSeFInvoicesService extends NotificationDataService<WebsocketNotificationType.UploadInvoices, UploadInvoicesMutationVariables, UploadKSeFInvoicesJobRunnerI> implements UploadKSeFInvoicesServiceI {

  @observable
  public modalIsActive: boolean = false;

  protected dataResultManager: NotificationDataResultManagerI<UploadInvoicesDataModel>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(envObserver, earchiveState, () => new UploadKSeFInvoicesJobRunner(envObserver, earchiveState));
    this.jobRunner.setService(this);
    this.registerOnChangeHandler((jobRunner) => jobRunner.setService(this));
    this.dataResultManager = new NotificationDataResultManager<UploadInvoicesDataModel>(this);
    makeObservable(this);
  }

  @computed
  public get missingInvoices(): MissingInvoiceModel[] {
    return this.currentResult?.errorItems?.map?.(item => ({
      invoiceNumber: item.FileName,
      message: item.errorMessage,
      reason: UpoMissingInvoiceReason.Error,
    })) ?? [];
  }

  @computed
  public get sentInvoicesCount(): number {
    return this.uploadedInvoicesCount + this.missingInvoicesCount;
  }

  @computed
  public get uploadedInvoicesCount(): number {
    return (this.currentResult?.successItems?.length ?? 0);
  }

  @computed
  public get missingInvoicesCount(): number {
    return (this.currentResult?.errorItems?.length ?? 0);
  }

  @computed
  public get currentResult(): UploadInvoicesDataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get multiple(): boolean {
    return !!this.earchiveState.packageStatistics.subscriptionIsActive;
  }

  public async sendInvoicesFiles(files?: File[]): Promise<void> {
    if (!files) {
      return;
    }
    const requestVariables: UploadInvoicesMutationVariables = {invoices: []};
    for (const file of files) {
      const base64File = await this.transformFileToBase64(file);
      Array.isArray(requestVariables.invoices) && base64File && requestVariables.invoices.push({
        FileName: file.name,
        FileContent: base64File,
      });
    }
    await this.jobRunner.configure(requestVariables).fetch();
  }

  @action.bound
  public setArchivedResult(result: UploadInvoicesDataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  @action.bound
  public setModalIsActive(modalIsActive: boolean): void {
    this.modalIsActive = modalIsActive;
  }

  public createAlert(): { success: () => AlertConfig, error: () => AlertConfig } {
    return {
      success: () => this.createSuccessAlert(),
      error: () => this.createErrorAlert(),
    };
  }

  protected override onSuccess(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel']) {
    super.onSuccess(data);
    this.result && this.dataResultManager.setFetchingResult({...this.result}, this.notification);
    this.missingInvoices.length && this.setModalIsActive(true);
  }

  protected override async afterFetch(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel'] | null, wholeData: GetWebsocketNotificationModelQuery, forced: boolean): Promise<void> {
    await super.afterFetch(data, wholeData, forced);
    await this.earchiveState.packageStatistics.load();

  }

  private createErrorAlert(): AlertConfig {
    return {
      id: this.multiple ? Tr.uploadInvoicesError : Tr.uploadInvoiceError,
      color: ToastVariant.ERROR,
      duration: null,
    };
  }


  private createSuccessAlert(): AlertConfig {
    if (!this.uploadedInvoicesCount) {
      return this.createErrorAlert();
    }

    return {
      id: this.multiple ? Tr.uploadInvoicesSuccess : Tr.uploadInvoiceSuccess,
      values: {count: this.uploadedInvoicesCount},
    };
  }

  private async transformFileToBase64(file: File): Promise<string | null> {
    try {
      return await toBase64(file);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

}
