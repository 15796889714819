import {
  GrantPermissionContext,
  GrantPermissionKSeFI,
  GrantPermissionsJobRunner,
} from '../../services/KSeFJobRunners/GrantPermissionsJobRunner';
import {PERMISSIONS_PAGE_CONSTS} from '../../modules/earchive/pages/Account/Permissions/consts';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {
  GrantPermissionsInKsefMutationVariables,
  KSeFIdentifierType,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {makeObservable} from 'mobx';
import {NotificationDataService} from '../../services/NotificationDataService';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export enum PermissionAlertType {
  onSuccessDelete = 'onDelete',
  onSuccessAdd = 'onSuccessAdd',
  onSuccessEdit = 'onSuccessEdit',
}


export class GrantPermissionsService extends NotificationDataService<WebsocketNotificationType.GrantPermission, GrantPermissionsInKsefMutationVariables, GrantPermissionKSeFI> {

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(envObserver, earchiveState, () => new GrantPermissionsJobRunner(envObserver, earchiveState));
    makeObservable(this);
  }

  public reset(onReset?: (permissions: Partial<GrantPermissionsInKsefMutationVariables>) => void): void {
    this.jobRunner.reset();
    onReset?.(this.jobRunner.permissions);
  }


  public setPermissions(variables: Parameters<GrantPermissionsJobRunner['configure']>[0]) {
    return this.jobRunner.configure(variables);
  }

  public async delete(nipType: KSeFIdentifierType, onSuccess?: (permissionAlertType: PermissionAlertType) => void): Promise<void> {
    const {
      Identifier,
      IdentifierType,
      ...defaultPermissions
    } = PERMISSIONS_PAGE_CONSTS.DEFAULT_PERMISSIONS_MODEL;
    this.jobRunner.setOperationContext(GrantPermissionContext.Remove);
    await this.setPermissions({...defaultPermissions, IsNew: false, NipType: nipType}).fetch({
      onSuccess: () => onSuccess?.(PermissionAlertType.onSuccessDelete),
    });
  }

  public checkIsPending(identifier: string): boolean {
    return this.activeEvents.some(event => event.data?.identifier === identifier);
  }

  public async edit(nipType: KSeFIdentifierType, onSuccess?: (permissionAlertType: PermissionAlertType) => void): Promise<void> {
    this.jobRunner.setOperationContext(GrantPermissionContext.Edit);
    await this.setPermissions({IsNew: false, NipType: nipType}).fetch({
      onSuccess: () => onSuccess?.(PermissionAlertType.onSuccessEdit),
    });
  }

  public async add(nipType: KSeFIdentifierType, {onSuccess, onError}: {
    onSuccess?: (permissionAlertType: PermissionAlertType) => void, onError?: (errors: readonly GraphQLErrorWithMessage[]) => void
  }): Promise<void> {
    this.jobRunner.addForcingPermissionIdentifier();
    this.jobRunner.setOperationContext(GrantPermissionContext.Add);
    await this.setPermissions({NipType: nipType}).fetch({
      onSuccess: () => onSuccess?.(PermissionAlertType.onSuccessAdd),
      onError: (errors) => {
        onError?.(errors);
      },
    });
  }
}
