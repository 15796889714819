import React, {FC, ReactNode} from 'react';
import {Card, CardWidth} from '@symfonia/brandbook';

export interface PageMessageProps {
  title: string;
  description: string | ReactNode;
  width?: CardWidth;
}

export const PageMessage: FC<PageMessageProps> = ({title, description, width = CardWidth.FIT}) => {
  return (
    <div className="flex items-center justify-center">
      <Card
        className="mt-16"
        header={title}
        description={description as string}
        contentClassName="hidden"
        width={width}
      />
    </div>
  );
};
