import {
  CompanyAssigmentListItemFragment,
  GetCompanyStatisticsDocument,
  GetCompanyStatisticsQuery,
  GetCompanyStatisticsQueryVariables,
} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {BaseRepository, BaseRepositoryI} from '../../modules/root/services/MobXServices/Repository/BaseRepository';

export interface CompaniesRepositoryI extends BaseRepositoryI<'CompanyStatistics', GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables> {

  get organizations(): GetCompanyStatisticsQuery['CompanyStatistics']['TenantAssignments'];

  get settledParameters(): Partial<GetCompanyStatisticsQueryVariables>;

  getCompaniesByTenantId(id: string): CompanyAssigmentListItemFragment[];
}

export class CompaniesRepository extends BaseRepository<'CompanyStatistics', GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables> implements CompaniesRepositoryI {
  constructor() {
    super('CompanyStatistics', GetCompanyStatisticsDocument);
    this.configure({SearchText: ''});
    makeObservable(this);
  }

  @computed
  get settledParameters(): Partial<GetCompanyStatisticsQueryVariables> {
    return this.variables;
  }

  @computed
  public get organizations() {
    return this.data?.TenantAssignments ?? [];
  }

  public override checkIsReady(): boolean {
    return typeof this.variables.Skip === 'number' && this.variables.Take;
  }

  public getCompaniesByTenantId(id: string) {
    return (this.data?.TenantAssignments?.find(tenant => tenant?.TenantId === id)?.CompanyAssignments?.filter(Boolean) ?? []) as CompanyAssigmentListItemFragment[];
  }
}
