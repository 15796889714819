import Chip from '@mui/material/Chip';
import {useIntl} from 'react-intl';
import {FC} from 'react';
import {CredentialsFragment, RequestedCredentialsFragment} from '@symfonia-ksef/graphql';

export interface PermissionsTableListProps {
  val: CredentialsFragment | RequestedCredentialsFragment;
}

export const PermissonsTableList: FC<PermissionsTableListProps> = ({val}) => {
  const intl = useIntl();

  const emptyElement = (<>-</>);
  if (!val) {
    return emptyElement;
  }

  const permissionsList: string[] = [];
  Object.entries(val).map(([key, val]) => {
    if (val === true) permissionsList.push(intl.formatMessage({id: key}));
  });

  return (
    <>
      {
        permissionsList && permissionsList.map((permission, key) => <Chip key={'ItemPermissions' + val.Identifier + key}
                                                                          label={permission} size="small"
                                                                          sx={{mr: '4px'}}/>)
      }
    </>
  );
};
