import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';
import {useModule} from '../../services/MobXServices/BaseModule';
import {HubService} from './Hub.service';
import {OrganizationsSelect} from './components/OrganizationsSelect';
import {ExtendedTable} from '../../components/ExtendedTable';
import {Header} from '../../components/Header';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Content} from '../../components/Content';
import {Text} from '../../components/Text';
import classnames from 'classnames';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {SearchInput} from '../../components/SearchInput';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {AnyObject} from 'yup/es/types';

type HubViewProps = AnyObject;
export const HubView: FC<HubViewProps> = observer(() => {
  const {isDesktop, isTablet, isLargeDesktop, isPhone, isSmallPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const {searchService, organizationsService, tableService, loading} = useModule(
    () => new HubService(earchiveState.tenant, isPhone || isSmallPhone),
  );
  const [showSelect, setShowSelect] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    if (!isPhone && !showSelect) {
      setShowSelect(true);
    }
  }, [isPhone, showSelect]);

  useEffect(() => {
    const width = isPhone ? 'w-[300px]' : isTablet ? 'w-[450px]' : isDesktop ? 'w-[600px]' : 'w-[1150px]';
    tableService.configureColumn('CompanyName', {width});
  }, [isPhone, isTablet, isDesktop, isLargeDesktop]);

  return (
    <Content loading={loading}>
      <Header>{intl.formatMessage({id: Tr.loginHubSetTenant})}</Header>
      <Text block>{intl.formatMessage({id: Tr.loginHubChooseTenant})}</Text>
      <div className="flex mb-[8px] items-end">
        <OrganizationsSelect
          className={classnames('w-4/5 md:w-[404px] mr-[8px] flex', {hidden: !showSelect})}
          service={organizationsService}
        />
        <SearchInput
          service={searchService}
          translationKey={Tr.searchCompany}
          onFocusChange={focused => isPhone && setShowSelect(!focused)}
          className={classnames('md:w-[164px]', 'flex', {
            'w-1/5': isPhone && showSelect,
            'w-full': isPhone && !showSelect,
          })}
        />
      </div>
      <ExtendedTable service={tableService}/>
    </Content>
  );
});
