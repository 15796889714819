import {FilterState} from './FilterState';
import {IDocumentsState} from './IDocumentsState';
import {IFiltersState} from './IFiltersState';
import {IInvoicePageState} from './IInvoicePageState';

export class InvoicePageState implements IInvoicePageState {
  public documentsState: IDocumentsState;

  private _filtersState: IFiltersState | undefined;
  public get filtersState(): IFiltersState {
    if (this._filtersState === undefined) {
      this._filtersState = new FilterState(this);
    }
    return this._filtersState;
  }

  constructor(documentsState: IDocumentsState) {
    this.documentsState = documentsState;
  }
}
