import {ApolloLink} from '@apollo/client';

export const environmentIdMiddleware = () => new ApolloLink((operation, forward) => {
  const environmentId = operation.getContext().envId;

  if (environmentId) {
    operation.setContext(({headers = {}}) => ({
      headers: {...headers, environmentId},
    }));
  }

  return forward(operation);
});
