import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {PostingConfigurationActions} from '../components/PostingConfigurationActions';
import {PostingConfigurationHeaderAlternate} from '../components/PostingConfigurationHeaderAlternate';
import {DocumentTypeSettingsList} from './components/DocumentTypeSettingsList/DocumentTypeSettingsList';
import {IPostingDocumentTypesState} from './state/IPostingDocumentTypesState';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {usePostingConfigurationStatus} from '../../../../../../hooks/usePostingConfigurationStatus';
import {earchiveStateMobx} from '../../../../../../EArchiveModule';
import {RequiredModulesToFillEnum} from '@symfonia-ksef/graphql';

export interface DocumentTypesProps {
  state: IPostingDocumentTypesState;
}

export const DocumentTypes: FC<DocumentTypesProps> = observer(({state}) => {
  const intl = useIntl();
  const {getRequiredConfigurationStatus} = usePostingConfigurationStatus();

  const handleSave = async () => {
    await state.saveDocumentTypesSettings();
    if (state.postingConfigurationState.isFinancialYearActive) getRequiredConfigurationStatus();
  };

  return (
    <div className="flex flex-col gap-2 lg:ml-[40px]">
      <PostingConfigurationHeaderAlternate
        defaultSwitchBtnValue={state.postingConfigurationState.invoiceTypeGroup}
        state={state}
        requiredModulesPurchase={earchiveStateMobx.postingState.requiredModules.includes(
          RequiredModulesToFillEnum.DocumentTypePurchase,
        )}
        requiredModulesSales={earchiveStateMobx.postingState.requiredModules.includes(
          RequiredModulesToFillEnum.DocumentTypeSales,
        )}
      />
      <main className="flex flex-col lg:flex-row gap-6 items-start lg:justify-between w-full">
        <DocumentTypeSettingsList state={state} disabled={!state.postingConfigurationState.isFinancialYearActive}/>
      </main>
        <PostingConfigurationActions
          onPrimaryButtonClick={handleSave}
          onSecondaryButtonClick={() => state.resetChanges()}
          primaryButtonText={intl.formatMessage({id: Tr.save})}
          secondaryButtonText={intl.formatMessage({id: Tr.cancelButton})}
          primaryButtonDisabled={!state.isValid || !state.postingConfigurationState.isFinancialYearActive}
          secondaryButtonDisabled={!state.isModified || !state.postingConfigurationState.isFinancialYearActive}
          buttonFixed={true}
        />
    </div>
  );
});
