import {action, computed, makeObservable, observable} from 'mobx';
import {Repository} from '../../../modules/root/services/MobXServices/Repository/Repository';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {
  CheckIsDownloadInvoicesRunningDocument,
  CheckIsDownloadInvoicesRunningQuery,
  CheckIsDownloadInvoicesRunningQueryVariables,
} from '@symfonia-ksef/graphql';

interface DownloadTrackerI {
  get isActive(): boolean;
}

export class DownloadInvoicesChecker extends Repository<'IsDownloadInvoicesRunning', CheckIsDownloadInvoicesRunningQuery, CheckIsDownloadInvoicesRunningQueryVariables> {

  @observable
  private _shouldShowAlert: boolean = false;

  constructor(private readonly downloadTracker: DownloadTrackerI) {
    super('IsDownloadInvoicesRunning', CheckIsDownloadInvoicesRunningDocument, envObserver, earchiveState);
    makeObservable(this);
  }

  @computed
  public get isRunning(): boolean {
    return (this.downloadTracker.isActive || !!this.data?.IsRunning) && this._shouldShowAlert;
  }

  public override checkIsReady(): boolean {
    return !!this.envId && !this.downloadTracker.isActive;
  }

  @action.bound
  public hideAlert(): void {
    this._shouldShowAlert = false;
  }

  protected override afterFetch(data: CheckIsDownloadInvoicesRunningQuery['IsDownloadInvoicesRunning'] | null): void | Promise<void> {
    if (data?.IsRunning) {
      this.showAlert();
    }
  }

  @action
  private showAlert(): void {
    this._shouldShowAlert = true;
  }
}
