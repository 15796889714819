import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {checkWhiteListService} from '@symfonia-ksef/state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';

export class CheckWhiteListEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.WhiteListValidation, checkWhiteListService, {
      error: checkWhiteListService.handleError(),
      success: () => checkWhiteListService.handleSuccess(checkWhiteListService.currentResult)(),
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await checkWhiteListService.getAsyncData(event));
    if (checkWhiteListService.errorInvoicesCount || checkWhiteListService.notWhiteListedInvoices.length) {
      checkWhiteListService.setModalIsActive(true);
      return;
    }
  }
}
