import {Columns} from '../../../../../../../../root/services/TableServices/BaseTableService';
import {CategoryDetailsRow} from './CategoryDetailsRepository';
import {ICategoryDetailsState} from '../state/ICategoryDetailsState';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Tooltip} from '@symfonia/brandbook';
import {cutTextLength} from '../../../../../../../../common/helpers/tableInputTextCutLenght';
import {FormattedMessage} from 'react-intl';
import {DateRenderer} from '../../../../../../../../common';
import {Button} from '@mui/material';
import {MoreVert} from '@mui/icons-material';

export const categoryDetailsColumns: Columns<CategoryDetailsRow, ICategoryDetailsState> = Object.freeze({
  PositionName: {
    cell: row => {
      return (
        <Tooltip text={cutTextLength(row.PositionName)?.wasTruncated ? row.PositionName : ''}>
          <span>{cutTextLength(row.PositionName)?.truncatedText}</span>
        </Tooltip>
      );
    },
    header: intl.formatMessage({id: Tr.expression}),
    isObserver: true,
    order: 1,
    sortable: true,
    width: 'w-[100px]',
  },
  CurrentCategoryName: {
    cell: row => {
      return (
        <Tooltip
          text={cutTextLength(row.CurrentCategoryName, false, false, true)?.wasTruncated ? row.CurrentCategoryName : ''}
        >
          <span>{cutTextLength(row.CurrentCategoryName, false, false, true)?.truncatedText}</span>
        </Tooltip>
      );
    },
    header: intl.formatMessage({id: Tr.category}),
    isObserver: true,
    order: 2,
    width: 'w-[100px]',
  },
  InvoiceTypeGroup: {
    cell: row => {
      return <FormattedMessage id={row.InvoiceTypeGroup}/>;
    },
    header: intl.formatMessage({id: Tr.type}),
    isObserver: true,
    order: 3,
    width: 'w-[100px]',
  },
  PreviousCategoryName: {
    cell: row => {
      return (
        <Tooltip
          text={
            cutTextLength(row.PreviousCategoryName, false, false, true)?.wasTruncated ? row.PreviousCategoryName : ''
          }
        >
          <span>{cutTextLength(row.PreviousCategoryName, false, false, true)?.truncatedText}</span>
        </Tooltip>
      );
    },
    header: intl.formatMessage({id: Tr.previousCategory}),
    isObserver: true,
    order: 4,
    width: 'w-[150px]',
  },
  UserLastName: {
    cell: row => {
      return (
        <Tooltip
          text={
            cutTextLength(row.PreviousCategoryName, false, false, true)?.wasTruncated ? row.PreviousCategoryName : ''
          }
        >
          <span>{cutTextLength(row.PreviousCategoryName, false, false, true)?.truncatedText}</span>
        </Tooltip>
      );
    },
    header: intl.formatMessage({id: Tr.user}),
    isObserver: true,
    order: 5,
    width: 'w-[100px]',
  },
  ModifiedAt: {
    cell: row => DateRenderer({value: row.ModifiedAt}),
    header: intl.formatMessage({id: Tr.dateLabel}),
    isObserver: true,
    order: 6,
    width: 'w-[100px]',
  },
  Id: {
    asAction: true,
    cell: row => {
      return (
        <Button
          size="small"
          onClick={e => {
            e.stopPropagation();
            console.log('Clicked button');
          }}
        >
          <MoreVert className={'IconWithTransform'}/>
        </Button>
      );
    },

    header: intl.formatMessage({id: Tr.actions}),
    order: 7,
  },
});
