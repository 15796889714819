import {useGetAutoPostingModuleStatusLazyQuery, useGetRequiredConfigurationStatusLazyQuery} from '@symfonia-ksef/graphql';
import {useCallback} from 'react';
import {ApolloError} from '@apollo/client';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {earchiveStateMobx} from '../EArchiveModule';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const usePostingConfigurationStatus = () => {
  const addAlert = earchiveState.alertsState.addAlert;
  const [configurationStatus] = useGetAutoPostingModuleStatusLazyQuery({context: {envId: earchiveStateMobx.companyId}});
  const [requiredConfigurationStatus] = useGetRequiredConfigurationStatusLazyQuery({context: {envId: earchiveStateMobx.companyId}});
  const onError = useCallback((error?: ApolloError) => {
    addAlert(intl.formatMessage({id: Tr.getPostingConfigurationStatusFailed}), ToastVariant.ERROR, {
      displayDuration: 10000,
      omitIfHasTheSameAlert: true,
    });
  }, []);

  const getRequiredConfigurationStatusForFinancialYearId = useCallback(() => {
    configurationStatus()
      .then(res => {
        if (res.data !== undefined) {
          earchiveStateMobx.postingState.setRequiredModulesToFill(res.data.GetAutoPostingModuleStatus.ConfigurationStatus.RequiredModulesToFill);
          earchiveStateMobx.postingState.setIsPostingConfigured(res.data.GetAutoPostingModuleStatus.ConfigurationStatus.IsRequiredConfiguration);
        }
      }).catch((errors: ApolloError | undefined) => {
      onError(errors);
    });
  }, [configurationStatus, onError]);

  const getRequiredConfigurationStatus = useCallback(() => {
    requiredConfigurationStatus({
      variables: {
        CompanyId: earchiveStateMobx.companyId,
      },
    })
      .then(res => {
        if (res.data !== undefined) {
          earchiveStateMobx.postingState.setIsPostingConfigured(res.data.GetRequiredConfigurationStatus.IsRequiredConfiguration);
        }
      }).catch((errors: ApolloError | undefined) => {
      onError(errors);
    });
  }, [configurationStatus, onError]);


  return {
    getRequiredConfigurationStatusForFinancialYearId,
    getRequiredConfigurationStatus
  };
};
