import {TextField} from "@mui/material";
import {Tr} from "@symfonia-ksef/locales/keys";
import {ButtonTertiary, ButtonTertiarySize, IconSvg} from "@symfonia/brandbook";
import {observer} from "mobx-react-lite";
import {useState} from "react";
import {IFilehubState} from "../../earchive/pages/Documents/state/IFilehubState";
import {intl} from "../../root/IntlProvider";

type filehubTextareaProps = {
    state: IFilehubState;
};

const FilehubTextarea = observer(({state}: filehubTextareaProps) => {

    const [textFieldDisabled, setTextFieldDisabled] = useState(true);

    const height = 24;

    const handleChangeEmailText = (e: React.ChangeEvent<HTMLInputElement>) => {
        state.setSendByEmailText(e.target.value);
    };

    const editMessageText = () => {
        setTextFieldDisabled(!textFieldDisabled);
    };

    return (
        <div>

            <div className='mt-[16px]'>
                {intl.formatMessage({id: Tr.messageText})}
            </div>
            <div>
                <TextField fullWidth
                    inputProps={{style: {height, fontSize: 16, lineHeight: 1.5}}}
                    multiline
                    rows={4}
                    value={state.sendByEmailText}
                    onChange={handleChangeEmailText}
                    size="medium"
                    disabled={textFieldDisabled}/>
            </div>

            <div className='text-right mt-[16px]'>
                <ButtonTertiary
                    lIcon={IconSvg.EDIT}
                    onClick={editMessageText}
                    size={ButtonTertiarySize.MD}
                    text={intl.formatMessage({id: Tr.editEmailMessage})}
                />
            </div>

        </div>
    );
});

export default FilehubTextarea;