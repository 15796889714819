import classNames from 'classnames';
import {LinearProgress} from '@mui/material';
import React from 'react';

export const Content: FCC<{ loading?: boolean, className?: string }> = ({children, loading, className}) => {
  return <div>
    {loading !== undefined && <div className="h-[4px]">{loading && <LinearProgress/>}</div>}
    <div
      className={classNames('px-[28px] my-[16px] mx-auto w-full max-w-[1920px] box-border', className)}>{children}</div>
  </div>;
};
