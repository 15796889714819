import {Menu, MenuOptions} from '../../../earchive/components/Menu/Menu';
import React, {FC, useMemo, Fragment} from 'react';
import {MenuAction} from '../../types/MenuActions';
import {MenuItemBtn} from '../MenuItem/MenuItem';
import {Separator, SeparatorSize} from '@symfonia/brandbook';

export type DesktopContextualMenuProps = {
  isOpen: boolean,
  actions: MenuAction[]
  anchorEl: HTMLElement | null
  onClose: () => void
  'test-id'?: string
}

export const DesktopContextualMenu: FC<DesktopContextualMenuProps> = ({
                                                                        isOpen,
                                                                        actions,
                                                                        anchorEl,
                                                                        onClose,
                                                                        'test-id': testId,
                                                                      }) => {
  const menuOptions = useMemo<MenuOptions>(() =>
      actions.map(({hidden, key, loading, disabled, label, icon, onClick, tooltipTitle, 'test-id': actionTestId}, idx) => ({
        hidden: !!hidden,
        renderComponent: () => <Fragment key={'MenuItemBtnElement' + key.toString() + label + idx}>
          <MenuItemBtn
            test-id={actionTestId}
            text={label}
            disabled={disabled}
            onClick={onClick}
            icon={icon}
            loading={loading}
            key={'MenuItemBtn' + key.toString() + label + idx}
            tooltipTitle={tooltipTitle}
          />
          <Separator size={SeparatorSize.SM}/>
        </Fragment>,
      }))
    , [actions]);
  return <Menu test-id={testId} onClose={onClose} options={menuOptions} isOpen={isOpen} anchorEl={anchorEl}/>;
};
