import * as React from 'react';
import {SyntheticEvent} from 'react';
import {ButtonTertiarySize} from '@symfonia/brandbook';
import {useNavigate} from 'react-router-dom';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {FeatureSwitchTypeEnum} from '../../../common/enums/FeatureSwitchTypeEnum';
import {ModulesEnum} from '../../../common/enums/ModulesEnum';
import {IModuleStatus} from '@symfonia-ksef/state/ModuleSettings/IModuleStatus';
import {CompanyEnvironmentModel} from '../../../earchive/models';
import {MenuButton, MenuButtonProps, SeparatorAlt, useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {AppRoutes} from '../../../root/Routes';
import {FeatureToggle} from '../../../common/components/FeatureToggle/FeatureToggle';
import {ModuleToggle} from '../../../common/components/ModuleToggle/ModuleToggle';
import {On} from '../../../common/components/FeatureToggle/OnOff';
import {NotInitialized} from '../../../common/components/ModuleToggle/NotInitialized/NotInitialized';

export type ConfigMenuSection = {
  id: string,
  sectionName: string,
  items: MenuButtonProps[],
  featureProps?: FeatureFlagProps
}

export type FeatureFlagProps = {
  feature?: FeatureSwitchTypeEnum;
  module?: ModulesEnum;
  moduleStatus?: IModuleStatus;
};

export type ConfigMenuProps = {
  className?: string;
  sections: ConfigMenuSection[];
  selectedCompanyEnvironment: CompanyEnvironmentModel | undefined;
};

export const ConfigMenu: React.FC<ConfigMenuProps> = observer(({...props}: ConfigMenuProps) => {
  const navigate = useNavigate();
  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);

  const onClick = (event: SyntheticEvent, href: string, sectionIndex: number, itemIndex: number) => {
    handleNavigate(event, href);
    handleChangeSelectedItem(event, sectionIndex, itemIndex);
  };

  const handleChangeSelectedItem = (event: SyntheticEvent, sectionIndex: number, itemIndex: number) => {
    props.sections.forEach(s => s.items.forEach(i => i.active = false));
    const section = props.sections[sectionIndex];
    if (section) {
      const item = section.items[itemIndex];
      if (item) item.active = true;
    }
  };

  const handleNavigate = (event: SyntheticEvent, href: string) => {
    navigate(AppRoutes.eArchive.address + '/' + props.selectedCompanyEnvironment?.Tenant.Id +
      '/company/' + props.selectedCompanyEnvironment?.EnvironmentId + AppRoutes.account.address + '/' + href);
    event.preventDefault();
  };

  const getSection = (section: ConfigMenuSection, index: number) => {
    return (
      <>
        <div className="px-8 py-6">
          <span className={'font-bold text-grey-800 text-xl'}>{section.sectionName}:</span>
        </div>
        <ul className={'mb-4'} key={section.id}>
          {section.items.map((item, itemIndex) => {
            return (
              <li key={item.id}>
                <MenuButton id={item.id}
                            lIcon={item.lIcon}
                            active={item.active}
                            text={item.text}
                            href={item.href}
                            size={ButtonTertiarySize.MD}
                            isRequiredAction={item.isRequiredAction}
                            handleClick={(event: SyntheticEvent) => onClick(event, item.href, index, itemIndex)}/>
              </li>
            );
          })}
        </ul>
        {index < props.sections.length - 1 ? <SeparatorAlt dashed/> : null}
      </>);
  };

  return (
    <div className={"w-full h-full bg-white" + (!isPhone ? ' border-r-2 border-grey-300' : "")} id="menu-sidebar">
      {
        props.sections.map((section, si) => {
          return (
            <FeatureToggle feature={section.featureProps?.feature ?? FeatureSwitchTypeEnum.default}
                           key={si}>
              <On>
                <ModuleToggle disableCheck={!section.featureProps?.module}
                              moduleStatus={section.featureProps?.moduleStatus}>
                  {getSection(section, si)}
                  <NotInitialized>
                    {getSection(section, si)}
                  </NotInitialized>
                </ModuleToggle>
              </On>
            </FeatureToggle>
          );
        })
      }
    </div>
  );
});

