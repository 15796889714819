export const cutTextLength = (txt: string | null | undefined, previewOn?: boolean, shortCell?: boolean, inputCell?: boolean) => {
  if (txt !== null && txt !== undefined) {
    let textLenght = 0;
    const windowWidth = window.innerWidth;
    if (windowWidth > 1900) {
      textLenght = 90 - (inputCell ? 65 : 0);
    }
    if (windowWidth < 1900 && windowWidth > 1400) {
      textLenght = 45 - (inputCell ? 20 : 0);
    }
    if (windowWidth < 1400 && windowWidth > 1300) {
      textLenght = 35 - (inputCell ? 10 : 0);
    }
    if (windowWidth < 1300) {
      textLenght = 20;
    }
    if (windowWidth < 900 && inputCell) {
      textLenght = 7;
    }
    textLenght = (previewOn ? textLenght / 2 : textLenght) - (shortCell ? 10 : 0);

    return {
      truncatedText: txt.length > textLenght ? txt.substring(0, textLenght) + '...' : txt,
      wasTruncated: txt.length > textLenght,
    };
  }
  return;
};

