import {
  FiltersFieldsInput,
  OrderDirection,
  ReportFileTypeEnum,
  useGetInvoicesReportQuery,
} from '@symfonia-ksef/graphql';
import {useParams} from 'react-router-dom';
import {useCallback, useRef, useState} from 'react';
import {base64ToBlob} from '../../common';
import fileSaver from 'file-saver';
import {ApolloError} from '@apollo/client';
import {normalizeFilterDate} from '../../common/helpers/baseFilterHelpers';
import {InvoicesFiltersState} from '../modules/Invoices/InvoicesFilters/InvoicesFiltersState';

export type DownloadReport = (options: { reportFileType: 'CSV' | 'XLS' }) => void;

export const useDownloadInvoicesReport = (
  filtersState: InvoicesFiltersState,
  searchText: string,
  order?: { FieldName?: string, Direction?: OrderDirection },
): { download: DownloadReport; loading: boolean; error?: ApolloError } => {
  const {companyId} = useParams();
  const activeFilters = filtersState.filtersForQuery;
  const [skip, setSkip] = useState(true);
  const reportFilesTypeRef = useRef<ReportFileTypeEnum | null>(null);
  const {loading, error} = useGetInvoicesReportQuery({
    skip,
    onCompleted: ({GetInvoicesReport: {FileContent, MimeType, FileName}}) => {
      fileSaver(base64ToBlob(FileContent, MimeType), FileName);
      setSkip(true);
      reportFilesTypeRef.current = null;
    },
    context: {envId: companyId},
    variables: {
      FiltersFields: {
        ...activeFilters,
        KsefDate: normalizeFilterDate(activeFilters?.KsefDate),
        DateOfIssue: normalizeFilterDate(activeFilters?.DateOfIssue),
        GrossValue: {
          From: activeFilters?.GrossValue?.From,
          To: activeFilters?.GrossValue?.To,
        },
      } as FiltersFieldsInput,
      SearchText: searchText,
      take: 0,
      skip: 0,
      order: order,
      ReportFileType: reportFilesTypeRef.current as ReportFileTypeEnum,
    },
  });

  const download = useCallback<DownloadReport>(
    options => {
      reportFilesTypeRef.current = options.reportFileType === 'CSV' ? ReportFileTypeEnum.Csv : ReportFileTypeEnum.Xlsx;
      (activeFilters || searchText) && setSkip(false);
    },
    [activeFilters, searchText],
  );

  return {loading, error, download};
};
