import {
  CategoryViewModel,
  GetCategoriesDocument,
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
  SortEnumType,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetCategoriesQueryFunction = async (companyId: string, financialYearId: string) =>
  await apolloClient
    .query<GetCategoriesQuery, GetCategoriesQueryVariables>({
      query: GetCategoriesDocument,
      context: {
        envId: companyId,
      },
      variables: {
        CompanyId: companyId,
        FinancialYearId: financialYearId,
        Order: [{Name: SortEnumType.Asc}]
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetCategoriesViewModel?.items as CategoryViewModel[];
    });
