import {AnyObject} from 'yup/es/types';
import {Columns, Matrix, Row} from './BaseTableService';
import {createElement, Key, ReactElement} from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import {CheckboxSize, TableHeaderProps} from '@symfonia/brandbook';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {ObserverCheckbox} from '../../components/ObserverCheckbox';
import {ObserverTableCell, TableCellProps} from '../../components/TableCell';
import {ClassNamesService} from './ExtendedTableService';


export interface SelectionTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModuleI {
  unselect(...rows: Row<T>[]): void;

  select(...rows: Row<T>[]): void;

  get selected(): Row<T>[];

  get headers(): TableHeaderProps[];

  get matrix(): Matrix<T, C>;

  get selectedRowClassName(): string | undefined;

  get selections(): Selections<Row<T>>;

  reset(): void;

  setIsSelectable(selectable: boolean): void;

  checkIsSelected(row: Row<T>): boolean;

  getMatrix(matrix: Matrix<T, C>, tableService?: ClassNamesService<T>): Matrix<T, C>;
}

interface Wrapped<T extends AnyObject, C extends AnyObject = AnyObject> {
  get headers(): TableHeaderProps[];

  get matrix(): Matrix<T, C>;

  get rows(): Row<T>[];

  get sortRows(): Row<T>[];

  get widths(): string[];

  get columns(): Columns<T, C>;

  createTableRow(row: Row<T>, columns: Columns<T, C>, props?: Partial<TableCellProps<T, C>>): ReactElement[];
}

export interface SelectionConfig<T extends AnyObject> {
  cell: SelectionCell<T>,
  name: string,
  width?: string,
  className?: string
  onChange: (selected: boolean, row: Row<T> | null) => void
}

type SelectionCell<T extends AnyObject, R extends ReactElement | string = ReactElement> = (row: Row<T>, key: Key, selected?: boolean) => R

export type SelectionFactory<T extends AnyObject> = (service: SelectionServiceI<Row<T>>) => SelectionConfig<T>

export class SelectionTableService<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModule implements SelectionTableServiceI<T, C> {
  protected readonly selection!: SelectionConfig<T>;
  private readonly _selectionService!: SelectionServiceI<Row<T>>;
  @observable
  private _selectable: boolean = true;

  constructor(protected wrapped: Wrapped<T, C>, selectionFactory?: SelectionFactory<T>, selected?: Row<T>[]) {
    super();
    this._selectionService = new SelectionService<Row<T>>(selected);
    this.selection = selectionFactory?.(this._selectionService) ?? new DefaultSelectionConfig(this._selectionService);
    makeObservable(this);
    this.reactionsManager.add(() => this.wrapped.rows, () => this.reset());
  }

  @computed
  public get selected() {
    return this._selectionService.selectedList;
  }

  public get selectedRowClassName(): string | undefined {
    return this.selection.className;
  }

  @computed
  public get headers(): TableHeaderProps[] {
    const headers = [...this.wrapped.headers];
    if (!this._selectable) {
      return headers;
    }
    const allChecked = this.checkIsAllChecked();
    const selectionHeader = {
      checkbox: {
        checked: allChecked,
        value: '',
        onChange: (state) => {
          state ? this._selectionService.addSelections(this.wrapped.rows) : this._selectionService.removeSelections(this.wrapped.rows);
          this.selection.onChange(state, null);
        },
      },
      name: this.selection.name,
    } as TableHeaderProps;
    headers.unshift(selectionHeader);
    return headers;
  }

  @computed
  public get matrix(): Matrix<T, C> {
    return this.getMatrix(this.wrapped.matrix, this);
  }

  @computed
  public get selections(): Selections<Row<T>> {
    return this._selectionService.selected;
  }

  public checkIsSelected(row: Row<T>): boolean {
    return !!this.selections[row.key];
  }


  public getMatrix(matrix: Matrix<T, C>, tableService?: ClassNamesService<T>): Matrix<T, C> {
    return matrix.map(item => {
      const cells = this.wrapped.createTableRow(item.row, this.wrapped.columns, {tableService: tableService ?? this});
      const key = `${item.row.key}.Select`;
      cells.unshift(createElement(ObserverTableCell, {
        row: item.row,
        content: this.selection.cell(item.row, key),
        tableService: tableService ?? this,
        key,
      }));
      return {
        ...item,
        cells,
        row: item.row,
      };
    });
  }

  public reset(): void {
    this._selectionService.reset();
  }

  @action.bound
  public setIsSelectable(selectable: boolean): void {
    this._selectable = !!this.selection && selectable;
  }

  public select(...rows: Row<T>[]): void {
    const selected = this._selectionService.addSelections(rows);
    selected.forEach(row => this.selection?.onChange?.(true, row));
  }

  public unselect(...rows: Row<T>[]): void {
    const unselected = this._selectionService.removeSelections(rows);
    unselected.forEach(row => this.selection?.onChange?.(false, row));
  }


  private checkIsAllChecked(): boolean {
    return !!this.wrapped.rows.length && this.wrapped.rows.every(row => !!this._selectionService.selected[row.key]);
  }
}

interface SelectionServiceI<T extends { key: Key }> {
  get selected(): Selections<T>;

  get selectedList(): T[];

  addSelections(selections: T | T[]): T[];

  removeSelections(selections: T | T[]): T[];

  reset(): void;
}

export type Selections<T extends { key: Key }> = Partial<Record<Key, T>>

class SelectionService<T extends { key: Key }> implements SelectionServiceI<T> {
  constructor(selected: T[] = []) {
    this._selected = this.createSelected(selected);
    makeObservable(this);
  }

  @observable.ref
  protected _selected!: Selections<T>;

  @computed
  public get selected(): Selections<T> {
    return this._selected;
  }

  @computed
  public get selectedList(): T[] {
    return Object.values(this.selected).filter(Boolean) as T[];
  }

  public addSelections(selections: T | T[]): T[] {
    const selectionsList = Array.isArray(selections) ? selections : [selections];
    this.setSelected(currentSelected => this.mergeSelections(currentSelected, selectionsList));
    return this.mergeSelections(selectionsList);
  }

  public removeSelections(selections: T | T[]): T[] {
    const selectionsList = Array.isArray(selections) ? selections : [selections];
    this.setSelected(currentSelected => this.getSelectionsDifference(currentSelected, selectionsList));
    return this.mergeSelections(selectionsList);
  }

  public reset(): void {
    this.setSelected([]);
  }

  @action.bound
  protected setSelected(selected: T[] | ((currentSelected: T[]) => T[])): void {
    const selections = typeof selected === 'function' ? selected(this.selectedList) : selected;
    this._selected = this.createSelected(selections);
  }

  private mergeSelections(selectionsA: T[], selectionsB?: T[]): T[] {
    const uniqueSelections: T[] = [];
    const uniqueSelectionsMap = new Map<Key, T>();

    for (const selection of selectionsB ? [...selectionsA, ...selectionsB] : selectionsA) {
      if (uniqueSelectionsMap.get(selection.key)) {
        continue;
      }
      uniqueSelectionsMap.set(selection.key, selection);
      uniqueSelections.push(selection);
    }
    return uniqueSelections;
  }

  private getSelectionsDifference(current: T[], diff: T[]): T[] {
    const diffMap = new Map<Key, { item: T, count: number }>();
    for (const selection of [...current, ...diff]) {
      const item = diffMap.get(selection.key);
      if (item) {
        item.count = item.count + 1;
        continue;
      }
      diffMap.set(selection.key, {count: 1, item: selection});
    }
    const diffResult: T[] = [];
    for (const selectionItem of diffMap.values()) {
      selectionItem.count === 1 && diffResult.push(selectionItem.item);
    }
    return diffResult;
  }

  private createSelected(selected: T[]): Selections<T> {
    return selected.reduce<Selections<T>>((selections, selectedItem) => {
      selections[selectedItem.key] = selectedItem;
      return selections;
    }, {});
  }

}

export class DefaultSelectionConfig<T extends AnyObject> implements SelectionConfig<T> {
  public readonly name = 'selection';
  public readonly className: string = 'bg-green-200';

  constructor(protected readonly selectionService: SelectionServiceI<Row<T>>) {

  }

  onChange(selected: boolean, row: Row<T> | null) {
    if (!row) {
      return;
    }
    selected ? this.selectionService.addSelections(row) : this.selectionService.removeSelections(row);
  }

  cell(row: Row<T>, key: Key) {
    return createElement(ObserverCheckbox, {
      checkIsSelected: () => !!this.selectionService.selected[row.key],
      size: CheckboxSize.SM,
      value: '',
      onChange: (selected) => this.onChange(selected, row),
    });
  }
}

