import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {observer} from "mobx-react-lite";
import {IFilehubState} from "../../earchive/pages/Documents/state/IFilehubState";
import {ButtonTertiary, ButtonTertiarySize, DropdownSize, DropdownWidth, Icon, IconColor, IconSize, IconSvg, Modal, Tooltip} from '@symfonia/brandbook';
import FilehubFooterButtons from "../components/FilehubFooterButtons";
import FilehubFormatSelect from '../components/FilehubFormatSelect';
import FilehubTextarea from '../components/FilehubTextarea';

type SendAttachmentByLinkModalProps = {
    onClose: () => void;
    switchToAttachmentList: () => void;
    state: IFilehubState;
};

const SendAttachmentByLinkModal = observer(({onClose, switchToAttachmentList, state}: SendAttachmentByLinkModalProps) => {
    const isModalOpened = state.isSendAttachmentByLinkModalOpen;
    const isSaveEnabled = true;

    if (!state.sendByEmailText)
        state.setSendByEmailText(intl.formatMessage({id: Tr.emailMessage}).replace('{nrfaktury}', state.invoiceNumber));

    const sendAttachment = async () => {
        // ---------------------------
        // :TODO
        // ---------------------------
        console.log('sendAttachment');
    };
    const clearFormState = () => {
        state.setSendByEmailText('');
        state.setInvoiceIssuerName('');
        state.setInvoiceIssuerNIP('');
    };

    const handleDropdowntItemClick = (value: string) => {
        return;
    };

    const handleClose = () => {
        state.setIsSendAttachmentByLinkModalOpen(false);
        clearFormState();
    };

    const handleSwitchToAttachmentList = () => {
        switchToAttachmentList();
    };

    return (
        <Modal show={isModalOpened} onClose={handleClose} title={intl.formatMessage({id: Tr.sendingInfo})}>
                {intl.formatMessage({id: Tr.purchaseInvoiceFormat})}, <strong>{state.invoiceNumber}</strong>
                <Tooltip text={intl.formatMessage({id: Tr.formatInvoiceTooltipContent})}>
                    <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD} className="align-middle ml-[4px]"/>
                </Tooltip>
                <div className="mt-[16px]">
                    <FilehubFormatSelect
                        onDropdownItemClick={handleDropdowntItemClick}
                        dropdownSize={DropdownSize.MD}
                        dropdownWidth={DropdownWidth.FULL}
                        notificationString={intl.formatMessage({id: Tr.attachingPDFfileInfo})}/>
                </div>
                <div className="mt-[16px]">
                    {intl.formatMessage({id: Tr.publicAttachments})}, <strong>5</strong>
                    <Tooltip text={intl.formatMessage({id: Tr.messageInfoText})}>
                        <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD} className="align-middle ml-[4px]"/>
                    </Tooltip>
                </div>
                <div className='text-right mt-[5px]'>
                    <ButtonTertiary
                        lIcon={IconSvg.LIST}
                        onClick={handleSwitchToAttachmentList}
                        size={ButtonTertiarySize.MD}
                        text={intl.formatMessage({id: Tr.gotoAttachmentsList})}
                    />
                </div>
                <FilehubTextarea state={state}/>
                <div className='mt-[16px]'>
                    <span>{intl.formatMessage({id: Tr.contractor})}: <b>{state.invoiceIssuerName}</b></span>
                </div>
                <div>
                    <span>{intl.formatMessage({id: Tr.NIP})}: <b>{state.invoiceIssuerNIP}</b></span>
                </div>

                <div className='mt-[35px]'>
                    <FilehubFooterButtons onClose={handleClose} onSave={sendAttachment} isSaveEnabled={isSaveEnabled}/>
                </div>
            </Modal>
    );
});

export default SendAttachmentByLinkModal;