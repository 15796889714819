import {FC, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {usePrevious} from '@symfonia/symfonia-ui-components';
import {Tr} from '../../../../../../../../../locales/translationKeys';
import {Loader} from '../../../../../../../../common/components/Loader/Loader';
import {observer} from 'mobx-react-lite';
import {CategoryDetailsTableFiltersConfig} from './filters/CategoryDetailsTableFiltersConfig';
import _ from 'lodash';
import {ICategoryDetailsState} from '../state/ICategoryDetailsState';
import {FiltersDrawer} from '@symfonia/symfonia-ksef-components';
import {ExtendedTable} from '../../../../../../../../root/components/ExtendedTable';
import {CategoryDetailsService} from './CategoryDetails.service';
import {SearchInput} from '../../../../../../../../root/components/SearchInput';
import {ButtonSecondary, ButtonSecondarySize, IconSvg} from '@symfonia/brandbook';
import {FiltersPills} from '../../../../../../../../root/components/FiltersPills';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface CategoryDetailsTableProps {
  service: CategoryDetailsService;
  state: ICategoryDetailsState;
}

export const CategoryDetailsTable: FC<CategoryDetailsTableProps> = observer(({service, state}) => {
  const {
    company: {companyId},
  } = earchiveState;

  const intl = useIntl();
  const [mainFilterMenuOpen, setMainFilterMenuOpen] = useState<boolean>(false);
  const filterState = state.filterState;

  const handleFilter = useCallback(() => {
    setMainFilterMenuOpen(false);
    filterState?.startFiltering();
    filterState.storage.saveToStorage(filterState.activeFilters);
  }, [filterState]);

  if (!companyId) {
    return <Loader/>;
  }

  const previousFilters = usePrevious(filterState.getActiveFilters);
  const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(filterState.getChangedFilters));

  return (
    <>
      <div className="flex gap-[10px]">
        <SearchInput className="w-[276px]" service={service.searchService} translationKey={Tr.search}/>
        <ButtonSecondary disabled={true} lIcon={IconSvg.FILTER_LIST} size={ButtonSecondarySize.SM}/>
      </div>
      <div>
        <FiltersPills pills={service.pills}/>
      </div>
      <ExtendedTable service={service.tableService} loaderEnabled className="w-full"/>
      {mainFilterMenuOpen && (
        <FiltersDrawer
          hasError={filterState.hasError}
          isFiltersMenuOpened={mainFilterMenuOpen}
          openFiltersMenu={v => setMainFilterMenuOpen(v)}
          handleFilter={handleFilter}
          clearFilters={filterState.handleClearMenuFilter}
          tableLabel={intl.formatMessage({id: Tr.tableLabel})}
          closeLabel={intl.formatMessage({id: Tr.closeLabel})}
          filterLabel={intl.formatMessage({id: Tr.filterLabel})}
          clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
          filtersTouched={wasFilterChanged}
          filtersModified={wasFilterChanged}
          onClose={() => {
            setMainFilterMenuOpen(false);
            filterState.resetFilterToStorage();
          }}
        >
          <CategoryDetailsTableFiltersConfig state={state}/>
        </FiltersDrawer>
      )}
    </>
  );
});
