import {useEffect, useRef} from 'react';

export type SubjectSubscriptionFn<T> = {
  (data: T): void;
};

export class Subject<D> {
  protected subscribers = new Map<symbol, SubjectSubscriptionFn<D>>();

  public onComplete?: () => void;

  public complete(): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const [symbol] of this.subscribers) {
      this.subscribers.delete(symbol);
    }

    if (this.onComplete !== undefined) {
      this.onComplete();
    }
  }

  public subscribe(fn: SubjectSubscriptionFn<D>): () => void {
    const symbol = Symbol('subscription symbol');
    this.subscribers.set(symbol, fn);
    return () => {
      this.subscribers.delete(symbol);
    };
  }

  public next(data?: D): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const [, fn] of this.subscribers) {
      fn(data as D);
    }
  }
}

export const useSubject = <D>(subject: Subject<D> | undefined, fn: SubjectSubscriptionFn<D>) => {
  useEffect(() => {
    if (subject !== undefined) {
      return subject.subscribe(fn);
    }
    return undefined;
  }, []);
};

export const useSubjectSource = <D>() => {
  const subjectRef = useRef<Subject<D>>(new Subject());
  return subjectRef.current;
};
