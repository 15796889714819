import {FormattedMessage, useIntl} from 'react-intl';
import {useKSeFCheckStatusSubscription} from '@symfonia-ksef/graphql';
import {KSeFConnectionError} from './KSeFConnectionError';
import {dateParser} from '../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import React, {useEffect, useState} from 'react';
import {Icon, IconColor, IconSvg, ToastVariant} from '@symfonia/brandbook';
import {IconWithTooltip, useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export enum KSeFOperatingStatus {
  Down = 'DOWN',
  Operational = 'OPERATIONAL',
  Unknown = 'UNKNOWN'
}

function getIcon(status: Partial<KSeFOperatingStatus>) {
  switch (status) {
    case KSeFOperatingStatus.Down:
      return {icon: IconSvg.CLOUD_OFF, color: IconColor.RED_500};
    case KSeFOperatingStatus.Operational:
      return {icon: IconSvg.CLOUD_DONE, color: IconColor.GREEN_500};
    case KSeFOperatingStatus.Unknown:
      return {icon: IconSvg.LIVE_HELP, color: IconColor.YELLOW_500};
    default:
      return;
  }
}

export const KSeFConnectionStatus = observer(() => {
  const intl = useIntl();
  const addAlert = earchiveState.alertsState.addAlert;
  const [skip, setSkip] = useState(true);
  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const {
    error,
    loading,
    data,
  } = useKSeFCheckStatusSubscription({context: {envId: earchiveState.company.tenantId ?? ''}, skip});

  useEffect(() => {
    setSkip(false);
  }, []);

  if (error) {
    return <KSeFConnectionError/>;
  }

  if (loading) {
    return <IconWithTooltip
      tooltipWidth="auto"
      icon={{icon: IconSvg.LIVE_HELP, color: IconColor.YELLOW_500}}
      tooltipContent={<p className="pre-line-typography font-quicksand text-sm"><FormattedMessage
        id={Tr[`ksefHealth_${KSeFOperatingStatus.Unknown}_tooltip_Content`]}
        values={{date: dateParser(new Date(), true), br: <br/>}}/></p>
      }/>;
  }

  if (data && data.onKSeFOperatingStatusUpdateGeneral) {
    const {status, statusDate} = data.onKSeFOperatingStatusUpdateGeneral;

    if (status === KSeFOperatingStatus.Down) addAlert((intl.formatMessage({id: Tr.ksefStatusError})), ToastVariant.ERROR, {
      displayDuration: 10000,
      omitIfHasTheSameAlert: true,
    });

    return <>
      {isPhone
        ? <div className={'flex w-full mt-[20px] mb-[10px]'}>
          <Icon svg={getIcon(status)?.icon ?? IconSvg.CLOUD_OFF} color={getIcon(status)?.color}/>
          <p className={'ml-[10px] text-left'}>
            {intl.formatMessage({id: Tr[`ksefHealth_${status}_tooltip_Content`]},
              {date: dateParser(statusDate, true), br: <br/>})}
          </p>
        </div>
        : <IconWithTooltip
          tooltipWidth="auto"
          icon={getIcon(status)}
          tooltipContent={<p className="pre-line-typography font-quicksand text-sm">
            <FormattedMessage
              id={Tr[`ksefHealth_${status}_tooltip_Content`]}
              values={{date: dateParser(statusDate, true), br: <br/>}}/></p>
          }/>
      }
    </>;
  }
  return <></>;
});
