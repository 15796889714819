import {SzafirSignedTask} from '../models';
import {XmlMapper} from './xml.mapper';

export class SignedTaskListMapper {
  constructor(private readonly xmlMapper: XmlMapper = new XmlMapper) {}
  public mapFromString(taskString: string): SzafirSignedTask[] {
    const xmlDocument = this.xmlMapper.mapFromString(taskString);
    const taskElements = xmlDocument.getElementsByTagName('SigningTaskItem');

    if (!taskElements?.length) {
      return [];
    }

    return Array.from(taskElements).map((element: Element) => {
      const id = element.getAttribute('Id') as string;
      const dataToSign = element.querySelector('DataToSign Base64Binary')?.textContent as string;
      const signatureToCreate = element.querySelector('SignatureToCreate Base64Binary')?.textContent as string;
      return new SzafirSignedTask(id, dataToSign, signatureToCreate);
    });
  }
}
