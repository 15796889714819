import {PageContent} from '@symfonia/symfonia-ksef-components';
import {Route, Routes, useParams} from 'react-router-dom';
import {CategoryDetails} from './CategoryDetails/CategoryDetails';
import {AppRoutes} from '../../../../../../root/Routes';

import {useEffect} from 'react';
import {IPostingConfigurationState} from './IPostingConfigurationState';
import {observer} from 'mobx-react-lite';
import {FinancialYearsInitialization} from './FinancialYearsInitialization';
import {CategoryAccounts} from './CategoryAccounts/CategoryAccounts';
import {DocumentTypes} from './DocumentTypes/DocumentTypes';
import {Settings} from './Settings/Settings';
import {usePostingConfigurationStatus} from "../../../../../hooks/usePostingConfigurationStatus";
import {earchiveStateMobx} from "../../../../../EArchiveModule";
import {SettlementsPage} from "./Settlements/SettlementsPage";
import {VehiclesPage} from "./Vehicles/VehiclesPage";

export interface PostingConfigurationModuleProps {
  state: IPostingConfigurationState;
}

export const PostingConfigurationModule = observer(({state}: PostingConfigurationModuleProps) => {
  const {companyId} = useParams();
  const {getRequiredConfigurationStatus} = usePostingConfigurationStatus();

  useEffect(() => {
    state.setCompanyId(companyId ?? '');
    state.loadInvoiceTypeGroupFromStorage();
  }, [companyId]);

  useEffect(() => {
    if (earchiveStateMobx.accountState.postingInitializationState.postingConfigurationState.isFinancialYearActive) {
      getRequiredConfigurationStatus();
    }
  }, [state.selectedFinancialYear, companyId]);

  return (
    <PageContent>
      <FinancialYearsInitialization state={state}>
        <Routes>
          <Route path="/" element={<CategoryAccounts state={state.categoryAccountState}/>}/>
          <Route
            path={AppRoutes.categoryDetails.address}
            element={<CategoryDetails state={state.categoryDetailsState}/>}
          />
          <Route path={AppRoutes.settlements.address} element={<SettlementsPage state={state.settlementsState}/>}/>
          <Route path={AppRoutes.postingVehicles.address} element={<VehiclesPage state={state.vehicleState}/>}/>
          <Route path={AppRoutes.documentTypes.address} element={<DocumentTypes state={state.documentTypesState}/>}/>
          <Route path={AppRoutes.postingSettings.address} element={<Settings state={state.settingsState}/>}/>
        </Routes>
      </FinancialYearsInitialization>
    </PageContent>
  );
});
