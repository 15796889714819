import clsx from 'clsx';
import {FC, KeyboardEvent} from 'react';
import {Icon, IconSize, IconVariant} from '@symfonia/brandbook';
import {colorStyles, TagColor, TagProps, TagVariant} from '@symfonia/brandbook';

export enum TagSize {
  VSM = 'VSM',
  SM = 'SM',
  MD = 'MD',
}

export interface KSEFTagProps extends Omit<TagProps, "size">{
  size?: TagSize
}

export const Tag: FC<KSEFTagProps> = ({
  text = '',
  lIcon = null,
  rIcon = null,
  size = TagSize.MD,
  disabled = false,
  onClick = undefined,
  color = TagColor.DEFAULT,
  variant = TagVariant.FILLED,
}) => {
  const styles = {
    component: clsx(
      'gap-x-[8px] group select-none w-fit flex justify-between items-center font-quicksand rounded-[4px]',
      {
        [`${colorStyles[variant][color]} focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500`]:
          !disabled,
        'border-[2px] border-solid border-transparent': !disabled && variant === TagVariant.FILLED,
        'border-[2px] border-solid': !disabled && variant === TagVariant.OUTLINED,
        'text-grey-500 bg-grey-100 cursor-default': disabled,
        'h-[32px] text-sm px-[6px]': size === TagSize.MD,
        'h-[24px] text-xs px-[2px]': size === TagSize.SM,
        'h-[14px] text-[8px] px-[1px]': size === TagSize.VSM,
        'cursor-default': onClick === undefined,
      },
    ),
    icon: clsx({
      'filter-grey-500': disabled,
      'filter-grey-0':
        !disabled &&
        [
          TagColor.DEFAULT,
          TagColor.GREY,
          TagColor.GREEN,
          TagColor.RED,
          TagColor.ORANGE,
          TagColor.YELLOW,
          TagColor.PURPLE,
          TagColor.BLUE1,
          TagColor.BLUE2,
        ].includes(color) &&
        variant === TagVariant.FILLED,
      'filter-primary-800': !disabled && color === TagColor.DEFAULT_LIGHT && variant === TagVariant.FILLED,
      'filter-grey-800': !disabled && color === TagColor.GREY_LIGHT && variant === TagVariant.FILLED,
      'filter-green-800': !disabled && color === TagColor.GREEN_LIGHT && variant === TagVariant.FILLED,
      'filter-red-800': !disabled && color === TagColor.RED_LIGHT && variant === TagVariant.FILLED,
      'filter-orange-800': !disabled && color === TagColor.ORANGE_LIGHT && variant === TagVariant.FILLED,
      'filter-yellow-800': !disabled && color === TagColor.YELLOW_LIGHT && variant === TagVariant.FILLED,
      'filter-purple-800': !disabled && color === TagColor.PURPLE_LIGHT && variant === TagVariant.FILLED,
      'filter-blue1-800': !disabled && color === TagColor.BLUE1_LIGHT && variant === TagVariant.FILLED,
      'filter-blue2-800': !disabled && color === TagColor.BLUE2_LIGHT && variant === TagVariant.FILLED,

      'filter-primary-500 group-hover:filter-grey-0 group-focus-visible:filter-primary-500':
        !disabled && color === TagColor.DEFAULT && variant === TagVariant.OUTLINED,
      'filter-grey-500 group-hover:filter-grey-0 group-focus-visible:filter-grey-500':
        !disabled && color === TagColor.GREY && variant === TagVariant.OUTLINED,
      'filter-green-500 group-hover:filter-grey-0 group-focus-visible:filter-green-500':
        !disabled && color === TagColor.GREEN && variant === TagVariant.OUTLINED,
      'filter-red-500 group-hover:filter-grey-0 group-focus-visible:filter-red-500':
        !disabled && color === TagColor.RED && variant === TagVariant.OUTLINED,
      'filter-orange-500 group-hover:filter-grey-0 group-focus-visible:filter-orange-500':
        !disabled && color === TagColor.ORANGE && variant === TagVariant.OUTLINED,
      'filter-yellow-500 group-hover:filter-grey-0 group-focus-visible:filter-yellow-500':
        !disabled && color === TagColor.YELLOW && variant === TagVariant.OUTLINED,
      'filter-purple-500 group-hover:filter-grey-0 group-focus-visible:filter-purple-500':
        !disabled && color === TagColor.PURPLE && variant === TagVariant.OUTLINED,
      'filter-blue1-500 group-hover:filter-grey-0 group-focus-visible:filter-blue1-500':
        !disabled && color === TagColor.BLUE1 && variant === TagVariant.OUTLINED,
      'filter-blue2-500 group-hover:filter-grey-0 group-focus-visible:filter-blue2-500':
        !disabled && color === TagColor.BLUE2 && variant === TagVariant.OUTLINED,

      'filter-primary-600': !disabled && color === TagColor.DEFAULT_LIGHT && variant === TagVariant.OUTLINED,
      'filter-grey-600': !disabled && color === TagColor.GREY_LIGHT && variant === TagVariant.OUTLINED,
      'filter-green-600': !disabled && color === TagColor.GREEN_LIGHT && variant === TagVariant.OUTLINED,
      'filter-red-600': !disabled && color === TagColor.RED_LIGHT && variant === TagVariant.OUTLINED,
      'filter-orange-600': !disabled && color === TagColor.ORANGE_LIGHT && variant === TagVariant.OUTLINED,
      'filter-yellow-600': !disabled && color === TagColor.YELLOW_LIGHT && variant === TagVariant.OUTLINED,
      'filter-purple-600': !disabled && color === TagColor.PURPLE_LIGHT && variant === TagVariant.OUTLINED,
      'filter-blue1-600': !disabled && color === TagColor.BLUE1_LIGHT && variant === TagVariant.OUTLINED,
      'filter-blue2-600': !disabled && color === TagColor.BLUE2_LIGHT && variant === TagVariant.OUTLINED,
    }),
  };

  return (
    <div
      className={styles.component}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (onClick && !disabled && event.key === 'Enter') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
      data-testid="tagMainDiv"
    >
      {lIcon && <Icon className={styles.icon} svg={lIcon} size={IconSize.SM} variant={IconVariant.CONTOUR}/>}
      <div>{text}</div>
      {rIcon && <Icon className={styles.icon} svg={rIcon} size={IconSize.SM} variant={IconVariant.CONTOUR}/>}
    </div>
  );
};
