import {useCallback, useEffect} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {ToastVariant} from '@symfonia/brandbook';
import {
  LoadingHandlers,
  PackageStatisticsRepositoryInterface,
  SendSerialNumberHandlers,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const usePackageStatisticLoader = (observableRepository: PackageStatisticsRepositoryInterface) => {

  useEffect(() => {
    observableRepository.setEnabled(true);
    return () => observableRepository.setEnabled(false);
  }, []);
};

export const usePackageStatisticHandlers = (): LoadingHandlers & SendSerialNumberHandlers => {
  const addAlert = earchiveState.alertsState.addAlert;
  const {formatMessage} = useIntl();


  const mapErrorMessage = useCallback(() => {
    if (!earchiveState.company.currentEnvironment) return;
    const message = formatMessage({id: Tr.LoadingPackageStatisticError});
    addAlert(message, ToastVariant.ERROR);
    return message;
  }, []);

  return {
    mapErrorMessage,
  };
};

