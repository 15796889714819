import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Modal} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import FilehubFooterButtons from '../components/FilehubFooterButtons';
import {FilehubUploadFile} from '../components/FilehubUploadFile';
import {useState} from 'react';
import {addAttachments} from '../table/useFilehubMethods';

type Props = {
  onClose: () => void;
  state: IFilehubState;
};

const AddAttachmentModal = observer(({onClose, state}: Props) => {
  //zmień z any na interface z danymi plików jak backend bedzie gotowy
  const [files, setFiles] = useState<any[]>([]);
  const [inValidFiles, setInvalidFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isModalOpened = state.isAddAttachmentModalOpen;
  const hasInvalidFiles = inValidFiles.length !== 0;
  const hasFiles = files.length > 0;
  const isSaveEnabled = !hasInvalidFiles && hasFiles;

  const sendAttachment = async () => {
    await addAttachments(files, state, setFiles, setIsLoading);
  };

  const handleClose = () => {
    state.setIsFileHubOpen(true);
    state.setAreFooterButtonsShown(false);
    onClose();
    setFiles([]);
  };

  const hideSuccessInfo = () => {
    state.setSuccessfulAddRequest(null);
  };

  return (
    <div onClick={hideSuccessInfo}>
      <Modal onClose={handleClose} show={isModalOpened} title={intl.formatMessage({id: Tr.addingAttachment})}>
        {intl.formatMessage({id: Tr.invoicePURCHASE})}, <strong>{state.invoiceNumber}</strong>
        <FilehubUploadFile
          files={files}
          invalidFiles={inValidFiles}
          setFiles={setFiles}
          setInvalidFiles={setInvalidFiles}
          state={state}
        />
        <div className="mt-[16px]">
          <FilehubFooterButtons
            loading={isLoading}
            onClose={handleClose}
            onSave={sendAttachment}
            isSaveEnabled={isSaveEnabled}
            secondaryButtonText={Tr.cancelButton}
          />
        </div>
      </Modal>
    </div>
  );
});

export default AddAttachmentModal;
