import {CompanyState} from './CompanyState';
import {TenantState} from './TenantState';
import {EnvironmentsState} from './EnvironmentsState';
import {LayoutState} from './LayoutState';
import {AlertsState} from './AlertsState';

import {action, computed, makeObservable} from 'mobx';
import {BaseModule, BaseModuleI} from '../../modules/root/services/MobXServices/BaseModule';
import {
  PackageStatisticsRepository,
  PackageStatisticsRepositoryInterface,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {
  IAuthenticationState,
  KSeFAuthenticationState,
} from '@symfonia-ksef/state/AuthenticationState/AuthenticationState';
import {EnvironmentType} from '@symfonia-ksef/graphql';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {KsefSessionState} from '@symfonia-ksef/state/EarchiveState/KsefSessionState';
import {BackgroundActionsService} from '@symfonia-ksef/state/BackgroundActionsServices/BackgroundActionsService';
import {State, StateI} from '../../modules/root/services/MobXServices/State';
import {AuthState} from '@symfonia-ksef/state/EarchiveState/AuthState';
import {InvoicesContextState} from '@symfonia-ksef/state/EarchiveState/InvoicesContextState';

export enum StoreEvents {
  CurrentEnvironmentLoaded,
  EnvironmentsLoaded,
}

export interface StoreMediator {
  notify<Sender extends object>(sender: Sender, event: StoreEvents): void;

  get envObserver(): EnvObserverI;
}

export type PostingT = { postingId: string | null }

export class EArchiveState extends BaseModule implements StoreMediator, BaseModuleI {
  public readonly company!: CompanyState;
  public readonly tenant!: TenantState;
  public readonly environments!: EnvironmentsState;
  public readonly layoutState!: LayoutState;
  public readonly alertsState!: AlertsState;
  public readonly ksefAuthentication!: IAuthenticationState;
  public readonly packageStatistics!: PackageStatisticsRepositoryInterface;
  public readonly ksefSessionStatus!: KsefSessionState;
  public readonly backgroundActionsServices!: BackgroundActionsService;
  public readonly posting!: StateI<PostingT>;
  public readonly invoicesContext!: InvoicesContextState;

  constructor(public readonly auth: AuthState) {
    super();
    this.posting = new State<PostingT>({postingId: null});
    this.company = new CompanyState(this);
    this.ksefAuthentication = new KSeFAuthenticationState(this.company, this);
    this.tenant = new TenantState(this);
    this.environments = new EnvironmentsState(this);
    this.layoutState = new LayoutState(this);
    this.alertsState = new AlertsState(this);
    this.packageStatistics = PackageStatisticsRepository.create(this.company.envObserver);
    this.ksefSessionStatus = new KsefSessionState(this.company.envObserver, this);
    this.backgroundActionsServices = new BackgroundActionsService(this);
    this.invoicesContext = new InvoicesContextState();

    makeObservable(this);
  }


  @computed
  public get envObserver(): EnvObserverI {
    return this.company.envObserver;
  }

  @computed
  public get isAdmin(): boolean {
    return this.environments.environments.some(env => env.Type === EnvironmentType.Tenant && env.Tenant?.Id && env.Tenant.Id === this.company.tenantId);
  }

  @action
  public notify<Sender extends object>(sender: Sender, event: StoreEvents) {
    switch (event) {

      case StoreEvents.CurrentEnvironmentLoaded: {
        const companyState = this.isCompanyState(sender) ? sender : this.company;
        const {tenantId, companyId, currentEnvironment} = companyState;
        this.ksefAuthentication.setCompany(currentEnvironment);
        this.tenant.setRootTenant({companyId}, this.environments);
        this.tenant.setSelectedTenantForHub(tenantId, this.environments);
        this.ksefSessionStatus.fetch();
        return;
      }


      case StoreEvents.EnvironmentsLoaded: {
        const environmentState = this.isEnvironmentsState(sender) ? sender : this.environments;
        this.tenant.initRootTenant(environmentState);
        return;
      }
    }
  }

  private isCompanyState(obj: object): obj is CompanyState {
    // eslint-disable-next-line no-prototype-builtins
    return obj.isPrototypeOf(this.company);
  }

  private isTenantState(obj: object): obj is TenantState {
    // eslint-disable-next-line no-prototype-builtins
    return obj.isPrototypeOf(this.tenant);
  }

  private isEnvironmentsState(obj: object): obj is EnvironmentsState {
    // eslint-disable-next-line no-prototype-builtins
    return obj.isPrototypeOf(this.environments);
  }
}
