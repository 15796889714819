import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {SearchInput} from '../../root/components/SearchInput';
import {ButtonTertiary, ButtonTertiarySize, IconSvg} from '@symfonia/brandbook';
import {SearchServiceI} from '../../root/services/SearchService/SearchService';

type Props = {
  openAddAttachmentModal: () => void;
  state: IFilehubState;
};

const FilehubModalHeader = ({openAddAttachmentModal, state}: Props) => {
  return (
    <div className="flex flex-col gap-[20px]">
      <div>
        {intl.formatMessage({id: Tr.invoicePURCHASE})}, <strong>{state.invoiceNumber}</strong>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <SearchInput service={{} as SearchServiceI} translationKey={Tr.searchInTheList}/>
        </div>
        <div>
          <ButtonTertiary
            lIcon={IconSvg.NOTE_ADD}
            onClick={openAddAttachmentModal}
            size={ButtonTertiarySize.SM}
            text={intl.formatMessage({id: Tr.addAttachments})}
          />
        </div>
      </div>
    </div>
  );
};

export default FilehubModalHeader;