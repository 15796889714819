import {FeatureSwitchTypeEnum} from '../../../common/enums/FeatureSwitchTypeEnum';
import {isFeatureEnabled} from '../../../common/helpers/featureSwitch';
import {IEarchiveStateMobx} from '../../IEArchiveStateMobx';
import {IAccountState} from './IAccountState';
import {IPostingInitializationState} from './Posting/pages/IPostingInitializationState';
import {PostingInitializationState} from './Posting/pages/PostingInitializationState';

export class AccountState implements IAccountState {
  public earchiveState: IEarchiveStateMobx;

  private _postingInitializationState : IPostingInitializationState | undefined;

  public get postingInitializationState(): IPostingInitializationState {
    if (isFeatureEnabled(FeatureSwitchTypeEnum.autoposting)) {
      if (this._postingInitializationState === undefined) {
        this._postingInitializationState = new PostingInitializationState(this);
      }
    } else {
      throw new Error("Autoposting feature is not enabled, state cannot be accessed");
    }

    return this._postingInitializationState;
  }

  constructor(rootState: IEarchiveStateMobx) {
    this.earchiveState = rootState;
  }
}
