import React, {FC, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {MultiSelectModel} from '../components/MultiSelect/MultiSelect';
import arrow from '../../../assets/icon-arrow-forward-ios-filled.svg';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';

export interface PostingPartlyNotAvailableModalProps {
  title: string;
  message: string;
  unavailable: MultiSelectModel[];
  selectedNumber: number;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ActionPartlyNotAvailableModalExtended: FC<PostingPartlyNotAvailableModalProps> = ({
                                                                                                 title,
                                                                                                 message,
                                                                                                 unavailable,
                                                                                                 selectedNumber,
                                                                                                 open,
                                                                                                 onConfirm,
                                                                                                 onCancel,
                                                                                               }) => {
  const intl = useIntl();
  const [showDocumentsList, setShowDocumentsList] = useState(false);
  const handleConfirm = () => {
    setShowDocumentsList(false);
    onConfirm();
  };

  const unavailableNumber = unavailable.length ?? '';
  const iconStyle = {
    filter: 'invert(56%) sepia(33%) saturate(988%) hue-rotate(56deg) brightness(104%) contrast(91%)',
    height: '20px',
    width: '20px',
  };
  const separatorStyle = {
    backgroundColor: 'rgb(211, 211, 211)',
  };

  return <Modal
      onClose={() => {
        setShowDocumentsList(false);
        onCancel();
      }}
      title={intl.formatMessage({id: title}, {br: <br/>})}
      show={open}
      centerButtons={true}
      className={'max-w-[500px]'}
    >
      <p>{intl.formatMessage({id: message}, {unavailableNumber, selectedNumber})}</p>
      {!showDocumentsList
        ? <div className="flex flex-col justify-center items-center mt-4 text-xs">
          <button
            className="btn flex flex-col justify-center items-center p-2 m-0"
            type="button"
            onClick={() => setShowDocumentsList(!showDocumentsList)}
          >
            <p><FormattedMessage id={Tr.showDocumentsList}/></p>
            <span className="inline-flex items-center justify-center mr-[8px] rotate-90">
              <img
                aria-label=""
                style={iconStyle}
                src={arrow}
                className="w-full h-full"
                alt="icon"
              />
            </span>
          </button>
        </div>
        : <div className={'my-4 flex flex-col items-start w-full text-xs'}>
          <FormattedMessage id={Tr.documentsList}/>
          <div className={'w-full h-[1px]'} style={separatorStyle}/>
          <div className={'my-2 block text-black max-h-24 min-h-12 h-auto w-full overflow-y-auto'}>
            {unavailable.map(e => {
              return <div key={e.key} className={'flex justify-start py-1'}>
                <p className={'w-[9rem]'}>{e.key}</p>
                <p>{e.value}</p>
              </div>;
            })}
          </div>
          <div className={'w-full h-[1px]'} style={separatorStyle}/>
        </div>
      }
      <div className="flex gap-[16px] pt-[24px]">
        <ButtonSecondary
          onClick={() => {
            setShowDocumentsList(false);
            onCancel();
          }}
          text={intl.formatMessage({id: Tr.cancelButton})}
          width={ButtonSecondaryWidth.FULL}
        />
        <ButtonPrimary
          onClick={handleConfirm}
          text={intl.formatMessage({id: Tr.acknowledge})}
          width={ButtonPrimaryWidth.FULL}
        />
      </div>
    </Modal>;
};
