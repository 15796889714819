import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {FC, useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {
  ButtonPrimary,
  ButtonPrimaryWidth,
  ButtonSecondary,
  ButtonSecondaryWidth,
  CheckboxGroupOrientation,
  CheckboxSize,
  Input,
  InputWidth,
  Label,
  LabelSize,
  Modal,
  RadioButtonGroup,
} from '@symfonia/brandbook';

interface AddCategoryModalProps {
  open: boolean;
  onOk: (name: string, group: InvoiceTypeGroupEnum) => void;
  onCancel: () => void;
  initialCategoryGroup: InvoiceTypeGroupEnum;
}

export const AddCategoryModal: FC<AddCategoryModalProps> = ({open, onOk, onCancel, initialCategoryGroup}) => {
  const intl = useIntl();
  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [group, setGroup] = useState<InvoiceTypeGroupEnum>(initialCategoryGroup);
  const [validation, setValidation] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (newName && newName.length > 70) {
      setValidation(intl.formatMessage({id: Tr.categoryNameLengthValidation}));
    } else {
      setValidation(undefined);
    }
  }, [newName]);

  const handleOk = () => {
    if (!newName) {
      return;
    }
    onOk(newName, group);
  };
  const handleCancel = () => {
    onCancel();
  };

  const checkboxes = [
    {label: intl.formatMessage({id: Tr.purchaseDocumentsTab}), value: InvoiceTypeGroupEnum.Purchase},
    {label: intl.formatMessage({id: Tr.salesDocumentsTab}), value: InvoiceTypeGroupEnum.Sales},
  ];

  return (
    <Modal onClose={handleCancel} title={intl.formatMessage({id: Tr.addCategory})} show={open} centerButtons={true}>
      <RadioButtonGroup
        aria-labelledby="demo-radio-buttons-group-label"
        checkboxes={checkboxes}
        name="radio-buttons-group"
        onChange={value => setGroup(value[0] as InvoiceTypeGroupEnum)}
        size={CheckboxSize.SM}
        value={[group.toString()]}
        orientation={CheckboxGroupOrientation.HORIZONTAL}
        label={intl.formatMessage({id: Tr.type})}
        className={'mb-[20px]'}
      />
      <div>
        <Label className="mb-[8px]" text={intl.formatMessage({id: Tr.Id})} size={LabelSize.MD}/>
        <Input
          width={InputWidth.FULL}
          onInput={e => setNewName(e)}
          value={newName ?? ''}
          placeholder={intl.formatMessage({id: Tr.writeCategoryName})}
          isError={!!validation}
          notification={validation}
        />
      </div>
      <div className="flex gap-[16px] pt-[24px]">
        <ButtonSecondary
          onClick={handleCancel}
          text={intl.formatMessage({id: Tr.cancelButton})}
          width={ButtonSecondaryWidth.BASE}
        />
        <ButtonPrimary
          disabled={!!validation || !newName}
          onClick={handleOk}
          text={intl.formatMessage({id: Tr.save})}
          width={ButtonPrimaryWidth.BASE}
        />
      </div>
    </Modal>
  );
};
