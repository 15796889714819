import {usePrevious} from '@symfonia/symfonia-ui-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import React, {FC, useCallback, useEffect} from 'react';
import {intl} from '../../../../root/IntlProvider';
import _ from 'lodash';
import {PostingTableActionMenu} from './PostingTableActionMenu';
import {observer} from 'mobx-react-lite';
import {PostingTableFiltersConfig} from './Filters/PostingTableFiltersConfig';
import {PostingStatusEnum} from '@symfonia-ksef/graphql';
import {Loader} from '../../../../common/components/Loader/Loader';
import {earchiveState, postingTableRefreshService} from '@symfonia-ksef/state/rootRepository';
import {ActionPartlyNotAvailableModalExtended} from '../../../../common/modals/ActionPartlyNotAvailableModalExtended';
import {PostingTablePageService} from '../PostingTablePage.service';
import {ExtendedTable} from '../../../../root/components/ExtendedTable';
import {FiltersPills} from '../../../../root/components/FiltersPills';
import {PostingTableGroupActionMenu} from './PostingTableGroupActionMenu';
import {FiltersDrawer} from '@symfonia/symfonia-ksef-components';
import {IPostingTableState} from './State/IPostingTableState';

export interface PostingTableProps {
  state: IPostingTableState;
  pageService: PostingTablePageService;
  filtersMenuIsOpen: boolean;

  setFiltersMenuIsOpen(isOpen: boolean): void;
}

export const PostingTable: FC<PostingTableProps> = observer(
  ({state, pageService, setFiltersMenuIsOpen, filtersMenuIsOpen}) => {
    const userId = earchiveState.company.userId ?? '';
    const canAcceptOwnDecrees = earchiveState.company.autoPosting?.AcceptBeforeSendToFK?.CanAcceptOwn;
    const filterState = state.filterState;

    const handleFilter = useCallback(() => {
      setFiltersMenuIsOpen(false);
      filterState?.startFiltering();
      filterState.storage.saveToStorage(filterState.activeFilters);
      pageService.repository.fetch();
    }, [filterState, pageService, setFiltersMenuIsOpen]);

    const handlePublishInvoices = () => {
      state.setShowExportToFKPartlyNotAvailableModal(false);
      let validDecrees = state.postingState.selectedDecrees.filter(
        x => x.PostingStatus !== PostingStatusEnum.DecreeSent && x.PostingStatus !== PostingStatusEnum.DecreeDownloaded,
      );

      if (state.acceptBeforeSendToFKIsActive) validDecrees = validDecrees.filter(x => x.isAccepted);

      if (validDecrees.length > 0) state.postingState.publishInvoices(validDecrees.map(x => x.InvoiceDocumentId));
    };

    const acceptDecrees = () => {
      state.setShowAcceptPartlyNotAvailableModal(false);
      state.acceptDecrees(userId, canAcceptOwnDecrees ?? true);
    };

    if (!filterState) {
      return <Loader/>;
    }

    const previousFilters = usePrevious(filterState.getActiveFilters);
    const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(filterState.getChangedFilters));
    const postingDetailsEnabledByStatus =
      state.postingState.selectedDecree?.PostingStatus === PostingStatusEnum.DecreeSent ||
      state.postingState.selectedDecree?.PostingStatus === PostingStatusEnum.DecreeDownloaded;

    useEffect(() => {
      postingTableRefreshService.incrementRefreshCounter();
    }, [wasFilterChanged]);

    return (
      <>
        <ActionPartlyNotAvailableModalExtended
          title={Tr.exportToFinancesAndAccountancyPartlyNotAvailableModalTitle}
          message={Tr.sendToKsefExtendedModal}
          unavailable={state.exportToFKPartlyUnavailableList()}
          selectedNumber={state.postingState.selectedDecrees.length}
          open={state.showExportToFKPartlyNotAvailableModal}
          onConfirm={() => handlePublishInvoices()}
          onCancel={() => state.setShowExportToFKPartlyNotAvailableModal(false)}
        />

        <ActionPartlyNotAvailableModalExtended
          title={Tr.acceptationForbidden}
          message={Tr.acceptationForbiddenText}
          unavailable={state.acceptDecreesValidation(userId, canAcceptOwnDecrees ?? true)}
          selectedNumber={state.postingState.selectedDecrees.length}
          open={state.showAcceptPartlyNotAvailableModal}
          onConfirm={() => acceptDecrees()}
          onCancel={() => state.setShowAcceptPartlyNotAvailableModal(false)}
        />

        <PostingTableActionMenu state={state} displayedForPostingStatus={postingDetailsEnabledByStatus}/>
        <FiltersDrawer
          hasError={filterState.hasError}
          isFiltersMenuOpened={filtersMenuIsOpen}
          openFiltersMenu={v => setFiltersMenuIsOpen(v)}
          handleFilter={handleFilter}
          clearFilters={filterState.handleClearMenuFilter}
          tableLabel={intl.formatMessage({id: Tr.tableLabel})}
          closeLabel={intl.formatMessage({id: Tr.closeLabel})}
          filterLabel={intl.formatMessage({id: Tr.filterLabel})}
          clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
          filtersTouched={wasFilterChanged}
          filtersModified={wasFilterChanged}
          onClose={() => {
            setFiltersMenuIsOpen(false);
            filterState.resetFilterToStorage();
          }}
        >
          <PostingTableFiltersConfig state={state.filterState}/>
        </FiltersDrawer>
        <FiltersPills pills={pageService.pills} maxVisiblePills={10}/>
        <PostingTableGroupActionMenu state={state} selectionState={pageService.tableService}/>
        <ExtendedTable loaderEnabled service={pageService.tableService} className={'w-full'}/>
      </>
    );
  },
);
