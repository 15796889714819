import clsx from 'clsx';
import {FC, KeyboardEvent} from 'react';
import {twMerge} from 'tailwind-merge';
import {Icon, IconSize, IconSvg, IconVariant} from '../Icon/Icon';
import {TestableElement} from '../../external/types';

export enum TagSize {
  SM = 'SM',
  MD = 'MD',
}

export enum TagVariant {
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED',
}

export enum TagColor {
  DEFAULT = 'TAG1',
  GREY = 'TAG2',
  GREEN = 'TAG3',
  RED = 'TAG4',
  ORANGE = 'TAG5',
  YELLOW = 'TAG6',
  PURPLE = 'TAG7',
  BLUE1 = 'TAG8',
  BLUE2 = 'TAG9',
  DEFAULT_LIGHT = 'TAG10',
  GREY_LIGHT = 'TAG11',
  GREEN_LIGHT = 'TAG12',
  RED_LIGHT = 'TAG13',
  ORANGE_LIGHT = 'TAG14',
  YELLOW_LIGHT = 'TAG15',
  PURPLE_LIGHT = 'TAG16',
  BLUE1_LIGHT = 'TAG17',
  BLUE2_LIGHT = 'TAG18',
  POSITIVE = 'TAG19',
}

export type TagProps = {
  text?: string;
  lIcon?: IconSvg | null;
  rIcon?: IconSvg | null;
  size?: TagSize;
  disabled?: boolean;
  onClick?: () => void;
  color?: TagColor;
  variant?: TagVariant;
  className?: string;
} & TestableElement;

export const colorStyles: Record<TagVariant, Record<string, string>> = {
  [TagVariant.FILLED]: {
    [TagColor.DEFAULT]: 'text-white bg-primary-500 hover:bg-primary-400 focus-visible:bg-primary-500',
    [TagColor.GREY]: 'text-white bg-grey-500 hover:bg-grey-400 focus-visible:bg-grey-500',
    [TagColor.GREEN]: 'text-white bg-green-500 hover:bg-green-400 focus-visible:bg-green-500',
    [TagColor.RED]: 'text-white bg-red-500 hover:bg-red-400 focus-visible:bg-red-500',
    [TagColor.ORANGE]: 'text-white bg-orange-500 hover:bg-orange-400 focus-visible:bg-orange-500',
    [TagColor.YELLOW]: 'text-white bg-yellow-500 hover:bg-yellow-400 focus-visible:bg-yellow-500',
    [TagColor.PURPLE]: 'text-white bg-purple-500 hover:bg-purple-400 focus-visible:bg-purple-500',
    [TagColor.BLUE1]: 'text-white bg-blue1-500 hover:bg-blue1-400 focus-visible:bg-blue1-500',
    [TagColor.BLUE2]: 'text-white bg-blue2-500 hover:bg-blue2-400 focus-visible:bg-blue2-500',
    [TagColor.DEFAULT_LIGHT]: 'text-primary-800 bg-primary-100 hover:bg-primary-50 focus-visible:bg-primary-100',
    [TagColor.GREY_LIGHT]: 'text-grey-800 bg-grey-100 hover:bg-grey-50 focus-visible:bg-grey-100',
    [TagColor.GREEN_LIGHT]: 'text-green-800 bg-green-100 hover:bg-green-50 focus-visible:bg-green-100',
    [TagColor.RED_LIGHT]: 'text-red-800 bg-red-100 hover:bg-red-50 focus-visible:bg-red-100',
    [TagColor.ORANGE_LIGHT]: 'text-orange-800 bg-orange-100 hover:bg-orange-50 focus-visible:bg-orange-100',
    [TagColor.YELLOW_LIGHT]: 'text-yellow-800 bg-yellow-100 hover:bg-yellow-50 focus-visible:bg-yellow-100',
    [TagColor.PURPLE_LIGHT]: 'text-purple-800 bg-purple-100 hover:bg-purple-50 focus-visible:bg-purple-100',
    [TagColor.BLUE1_LIGHT]: 'text-blue1-800 bg-blue1-100 hover:bg-blue1-50 focus-visible:bg-blue1-100',
    [TagColor.BLUE2_LIGHT]: 'text-blue2-800 bg-blue2-100 hover:bg-blue2-50 focus-visible:bg-blue2-100',
    [TagColor.POSITIVE]: 'text-positive-800 bg-positive-100 hover:bg-positive-50 focus-visible:bg-positive-100',
  },
  [TagVariant.OUTLINED]: {
    [TagColor.DEFAULT]:
      'text-primary-500 border-primary-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-primary-400 hover:[&:not(:focus-visible)]:bg-primary-400',
    [TagColor.GREY]:
      'text-grey-500 border-grey-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-grey-400 hover:[&:not(:focus-visible)]:bg-grey-400',
    [TagColor.GREEN]:
      'text-green-500 border-green-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-green-400 hover:[&:not(:focus-visible)]:bg-green-400',
    [TagColor.RED]:
      'text-red-500 border-red-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-red-400 hover:[&:not(:focus-visible)]:bg-red-400',
    [TagColor.ORANGE]:
      'text-orange-500 border-orange-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-orange-400 hover:[&:not(:focus-visible)]:bg-orange-400',
    [TagColor.YELLOW]:
      'text-yellow-500 border-yellow-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-yellow-400 hover:[&:not(:focus-visible)]:bg-yellow-400',
    [TagColor.PURPLE]:
      'text-purple-500 border-purple-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-purple-400 hover:[&:not(:focus-visible)]:bg-purple-400',
    [TagColor.BLUE1]:
      'text-blue1-500 border-blue1-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-blue1-400 hover:[&:not(:focus-visible)]:bg-blue1-400',
    [TagColor.BLUE2]:
      'text-blue2-500 border-blue2-500 hover:[&:not(:focus-visible)]:text-white hover:[&:not(:focus-visible)]:border-blue2-400 hover:[&:not(:focus-visible)]:bg-blue2-400',
    [TagColor.DEFAULT_LIGHT]:
      'text-primary-600 border-primary-100 hover:[&:not(:focus-visible)]:border-primary-50 hover:[&:not(:focus-visible)]:bg-primary-50',
    [TagColor.GREY_LIGHT]:
      'text-grey-600 border-grey-100 hover:[&:not(:focus-visible)]:border-grey-50 hover:[&:not(:focus-visible)]:bg-grey-50',
    [TagColor.GREEN_LIGHT]:
      'text-green-600 border-green-100 hover:[&:not(:focus-visible)]:border-green-50 hover:[&:not(:focus-visible)]:bg-green-50',
    [TagColor.RED_LIGHT]:
      'text-red-600 border-red-100 hover:[&:not(:focus-visible)]:border-red-50 hover:[&:not(:focus-visible)]:bg-red-50',
    [TagColor.ORANGE_LIGHT]:
      'text-orange-600 border-orange-100 hover:[&:not(:focus-visible)]:border-orange-50 hover:[&:not(:focus-visible)]:bg-orange-50',
    [TagColor.YELLOW_LIGHT]:
      'text-yellow-600 border-yellow-100 hover:[&:not(:focus-visible)]:border-yellow-50 hover:[&:not(:focus-visible)]:bg-yellow-50',
    [TagColor.PURPLE_LIGHT]:
      'text-purple-600 border-purple-100 hover:[&:not(:focus-visible)]:border-purple-50 hover:[&:not(:focus-visible)]:bg-purple-50',
    [TagColor.BLUE1_LIGHT]:
      'text-blue1-600 border-blue1-100 hover:[&:not(:focus-visible)]:border-blue1-50 hover:[&:not(:focus-visible)]:bg-blue1-50',
    [TagColor.BLUE2_LIGHT]:
      'text-blue2-600 border-blue2-100 hover:[&:not(:focus-visible)]:border-blue2-50 hover:[&:not(:focus-visible)]:bg-blue2-50',
    [TagColor.POSITIVE]:
      'text-positive-600 border-positive-100 hover:[&:not(:focus-visible)]:border-positive-50 hover:[&:not(:focus-visible)]:bg-positive-50',
  },
};

export const Tag: FC<TagProps> = ({
  text = '',
  lIcon = null,
  rIcon = null,
  size = TagSize.MD,
  disabled = false,
  onClick = undefined,
  color = TagColor.DEFAULT,
  variant = TagVariant.FILLED,
  className = '',
  testId = undefined,
}) => {
  const styles = {
    component: twMerge(
      clsx(
        'gap-x-[8px] group select-none w-fit flex justify-between items-center font-quicksand rounded-[4px]',
        {
          [`${colorStyles[variant][color]} focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500`]:
            !disabled,
          'border-[2px] border-solid border-transparent': !disabled && variant === TagVariant.FILLED,
          'border-[2px] border-solid': !disabled && variant === TagVariant.OUTLINED,
          'text-grey-500 bg-grey-100 cursor-default': disabled,
          'h-[32px] text-sm px-[6px]': size === TagSize.MD,
          'h-[24px] text-xs px-[2px]': size === TagSize.SM,
          'cursor-default pointer-events-none': onClick === undefined,
        },
        className,
      ),
    ),
    icon: clsx({
      'filter-grey-500': disabled,
      'filter-grey-0':
        !disabled &&
        [
          TagColor.DEFAULT,
          TagColor.GREY,
          TagColor.GREEN,
          TagColor.RED,
          TagColor.ORANGE,
          TagColor.YELLOW,
          TagColor.PURPLE,
          TagColor.BLUE1,
          TagColor.BLUE2,
        ].includes(color) &&
        variant === TagVariant.FILLED,
      'filter-primary-800': !disabled && color === TagColor.DEFAULT_LIGHT && variant === TagVariant.FILLED,
      'filter-grey-800': !disabled && color === TagColor.GREY_LIGHT && variant === TagVariant.FILLED,
      'filter-green-800': !disabled && color === TagColor.GREEN_LIGHT && variant === TagVariant.FILLED,
      'filter-red-800': !disabled && color === TagColor.RED_LIGHT && variant === TagVariant.FILLED,
      'filter-orange-800': !disabled && color === TagColor.ORANGE_LIGHT && variant === TagVariant.FILLED,
      'filter-yellow-800': !disabled && color === TagColor.YELLOW_LIGHT && variant === TagVariant.FILLED,
      'filter-purple-800': !disabled && color === TagColor.PURPLE_LIGHT && variant === TagVariant.FILLED,
      'filter-blue1-800': !disabled && color === TagColor.BLUE1_LIGHT && variant === TagVariant.FILLED,
      'filter-blue2-800': !disabled && color === TagColor.BLUE2_LIGHT && variant === TagVariant.FILLED,

      'filter-primary-500 group-hover:filter-grey-0 group-focus-visible:filter-primary-500':
        !disabled && color === TagColor.DEFAULT && variant === TagVariant.OUTLINED,
      'filter-grey-500 group-hover:filter-grey-0 group-focus-visible:filter-grey-500':
        !disabled && color === TagColor.GREY && variant === TagVariant.OUTLINED,
      'filter-green-500 group-hover:filter-grey-0 group-focus-visible:filter-green-500':
        !disabled && color === TagColor.GREEN && variant === TagVariant.OUTLINED,
      'filter-red-500 group-hover:filter-grey-0 group-focus-visible:filter-red-500':
        !disabled && color === TagColor.RED && variant === TagVariant.OUTLINED,
      'filter-orange-500 group-hover:filter-grey-0 group-focus-visible:filter-orange-500':
        !disabled && color === TagColor.ORANGE && variant === TagVariant.OUTLINED,
      'filter-yellow-500 group-hover:filter-grey-0 group-focus-visible:filter-yellow-500':
        !disabled && color === TagColor.YELLOW && variant === TagVariant.OUTLINED,
      'filter-purple-500 group-hover:filter-grey-0 group-focus-visible:filter-purple-500':
        !disabled && color === TagColor.PURPLE && variant === TagVariant.OUTLINED,
      'filter-blue1-500 group-hover:filter-grey-0 group-focus-visible:filter-blue1-500':
        !disabled && color === TagColor.BLUE1 && variant === TagVariant.OUTLINED,
      'filter-blue2-500 group-hover:filter-grey-0 group-focus-visible:filter-blue2-500':
        !disabled && color === TagColor.BLUE2 && variant === TagVariant.OUTLINED,

      'filter-primary-600': !disabled && color === TagColor.DEFAULT_LIGHT && variant === TagVariant.OUTLINED,
      'filter-grey-600': !disabled && color === TagColor.GREY_LIGHT && variant === TagVariant.OUTLINED,
      'filter-green-600': !disabled && color === TagColor.GREEN_LIGHT && variant === TagVariant.OUTLINED,
      'filter-red-600': !disabled && color === TagColor.RED_LIGHT && variant === TagVariant.OUTLINED,
      'filter-orange-600': !disabled && color === TagColor.ORANGE_LIGHT && variant === TagVariant.OUTLINED,
      'filter-yellow-600': !disabled && color === TagColor.YELLOW_LIGHT && variant === TagVariant.OUTLINED,
      'filter-purple-600': !disabled && color === TagColor.PURPLE_LIGHT && variant === TagVariant.OUTLINED,
      'filter-blue1-600': !disabled && color === TagColor.BLUE1_LIGHT && variant === TagVariant.OUTLINED,
      'filter-blue2-600': !disabled && color === TagColor.BLUE2_LIGHT && variant === TagVariant.OUTLINED,
    }),
  };

  return (
    <div
      className={styles.component}
      onClick={() => {
        if (onClick && !disabled) {
          onClick();
        }
      }}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (onClick && !disabled && event.key === 'Enter') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
      data-test-element="tag"
      data-testid={testId}
    >
      {lIcon && <Icon className={styles.icon} svg={lIcon} size={IconSize.SM} variant={IconVariant.CONTOUR} />}
      <div>{text}</div>
      {rIcon && <Icon className={styles.icon} svg={rIcon} size={IconSize.SM} variant={IconVariant.CONTOUR} />}
    </div>
  );
};
