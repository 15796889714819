export enum SingleActionTypeEnum {
  getInvoiceXml = 'getInvoiceXml',
  sendToKSeF = 'sendToKSeF',
  getUpoPdf = 'getUpoPdf',
  getUpoXml = 'getUpoXml',
  exportToSymfoniaTrade = 'exportToSymfoniaTrade',
  exportToSymfoniaFinancesAndAccountancy = 'exportToSymfoniaFinancesAndAccountancy',
  publishToSymfoniaFinancesAndAccountancy = 'publishToSymfoniaFinancesAndAccountancy',
  sendForPosting = 'sendForPosting',
  downloadInvoicePdf = 'donwloadInvoicePdf',
  deleteInvoice = 'deleteInvoice',
  whiteListCheck = 'whiteListCheck',
  addAttachment = "addAttachment",
  sendByEmail = 'sendByEmail'
}
