import {action, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {
  AccountsPlanFragment,
  CategoryViewModel,
  Invoice,
  DecreeViewModel,
  RequiredModulesToFillEnum,
  PostingStatusEnum,
} from '@symfonia-ksef/graphql';
import {IEarchiveStateMobx} from '../../earchive/IEArchiveStateMobx';
import {IPostingState} from './IPostingState';
import {IPostingDetailsState} from '../pages/PostingDetails/state/IPostingDetailsState';
import {PostingDetailsState} from '../pages/PostingDetails/state/PostingDetailsState';
import {localStorageService} from '../../common/helpers/storage';
import {earchiveState, postingTableRefreshService} from '@symfonia-ksef/state/rootRepository';
import {IPostingTableState} from '../pages/PostingTablePage/PostingTable/State/IPostingTableState';
import {PostingTableState} from '../pages/PostingTablePage/PostingTable/State/PostingTableState';
import {PublishInvoicesMutationFunction} from '../pages/PostingTablePage/Queries/PublishInvoicesMutationFunction';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';

export class PostingState implements IPostingState {
  @observable
  public isPostingConfigured = false;

  @observable
  public requiredModules: RequiredModulesToFillEnum[] = [];

  @observable
  public isLoading = false;

  @observable
  public error?: string | undefined;

  @observable
  public isPageError = false;

  @observable
  public publicPageError?: string | undefined;

  @observable
  public readonly selectedDecrees: IObservableArray<InvoiceDecreeKeysModel> = observable([]);

  @observable
  public selectedDecree: DecreeViewModel | undefined = undefined;

  @observable
  public invoicesForPosting: IObservableArray<Invoice> = observable([]);

  @observable
  public accounts: AccountsPlanFragment[] | undefined;

  @observable
  public categories: CategoryViewModel[] | undefined;

  public earchiveState: IEarchiveStateMobx;

  private _postingInvoicesTableState: IPostingTableState | undefined;
  public get postingInvoicesTableState(): IPostingTableState {
    if (this._postingInvoicesTableState === undefined) {
      this._postingInvoicesTableState = new PostingTableState(this, postingTableRefreshService);
    }
    return this._postingInvoicesTableState;
  }

  private _postingDetailsState: IPostingDetailsState | undefined;
  public get postingDetailsState(): IPostingDetailsState {
    if (this._postingDetailsState === undefined) {
      this._postingDetailsState = new PostingDetailsState(this);
    }
    return this._postingDetailsState;
  }

  constructor(earchiveState: IEarchiveStateMobx) {
    makeObservable(this);
    this.earchiveState = earchiveState;

    if (this.selectedDecree === undefined) this.setSelectedDecree(localStorageService.getItem(String(location.pathname)));
  }

  @computed
  public get isError() {
    return !!this.error;
  }

  @action
  public setInvoicesForPosting(invoices: Invoice[]) {
    this.invoicesForPosting.replace(invoices);
  }

  @action
  public setDecreeDetails(data: any) {
    this.accounts = data?.GetAccountsForPosting?.AccountsPlan;
    this.categories = data?.GetCategories.Categories;
  }

  @action
  public setSelectedDecrees(decrees: DecreeViewModel[]) {
    this.selectedDecrees.replace(decrees.map(x => ({
      Id: x.Id,
      InvoiceDocumentId: x.InvoiceDocumentId,
      Number: x.InvoiceNumber,
      PostingStatus: x.PostingStatus,
      isAccepted: x.IsAccepted,
      CreatedBy: x.CreatedBy
    })));
  }

  @action
  setSelectedDecree(decree: DecreeViewModel | undefined) {
    this.selectedDecree = decree;
    if (decree !== undefined) localStorageService.setItem(String(location.pathname + decree?.Id), decree);
  }

  @action
  public setIsLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @action
  public setError(err?: string | undefined) {
    this.error = err;
  }

  @action
  public setIsPostingConfigured(isPostingConfigured: boolean) {
    this.isPostingConfigured = isPostingConfigured;
  }

  @action
  public setRequiredModulesToFill(requiredModules: RequiredModulesToFillEnum[] | undefined) {
    this.requiredModules = requiredModules ?? new Array<RequiredModulesToFillEnum>();
  }

  @action
  public async publishInvoices(invoiceIds?: string[]) {
    this.postingDetailsState.setIsLoadingSendToKsefButton(true);
    earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.downloadInvoiceToFKInfo}),
      ToastVariant.INFO, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    return await PublishInvoicesMutationFunction(
      this.earchiveState.companyId,
      invoiceIds,
    ).then(res => {
      if (res !== undefined && res.Failed > 0) {
        earchiveState.alertsState.addAlert(intl.formatMessage({id: res.Failed > 1 ? Tr.downloadInvoiceToFKError : Tr.downloadInvoicesToFKError}),
          ToastVariant.ERROR, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
      } else {
        this.postingDetailsState.resetState();
        earchiveState.alertsState.addAlert(intl.formatMessage({id: res !== undefined && res.Sent > 1 ? Tr.downloadInvoiceToFKSuccess : Tr.downloadInvoicesToFKSuccess},
            {count: res?.Sent}),
          ToastVariant.SUCCESS, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
        this.postingDetailsState.setIsLoadingSendToKsefButton(false);
      }
    })
      .catch(() => this.setSelectedDecrees([]));
  }
}

export type InvoiceDecreeKeysModel =
{
  Id: string,
  InvoiceDocumentId: string,
  Number: string,
  PostingStatus: PostingStatusEnum,
  isAccepted: boolean,
  CreatedBy: string | null
}
