import {FC, PropsWithChildren, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {authState} from '@symfonia-ksef/state/rootRepository';

let authTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

export const AuthGuard: FC<PropsWithChildren> = observer(({children}) => {
  const {isAuthenticated} = authState;
  useEffect(() => {
    if (!isAuthenticated) {
      authState.handleAuth().then((authorized) => {
        if (authorized) {
          authTimeout = setTimeout(() => {
            authState.signOut();
          }, 1000 * 60 * 60);
        }
      }).catch((err) => authState.handleAuthProcessingError(err));
    }

  }, [isAuthenticated]);

  useEffect(() => {
    return () => clearTimeout(authTimeout);
  }, []);

  return <>
    {isAuthenticated ? children : null}
  </>;
});
