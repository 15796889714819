import {
  DecreeResponse,
  GetRecalculatedDecreeDocument,
  GetRecalculatedDecreeQuery,
  GetRecalculatedDecreeQueryVariables,
  QueryGetRecalculatedDecreeArgs,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetRecalculatedDecreeQueryFunction = async (companyId: string, {...queryArgs}: QueryGetRecalculatedDecreeArgs) : Promise<DecreeResponse> =>
  await apolloClient.query<GetRecalculatedDecreeQuery, GetRecalculatedDecreeQueryVariables>({
    query: GetRecalculatedDecreeDocument,
    context: {
      envId: companyId,
    },
    variables: {
      DecreeId: queryArgs.DecreeId,
      CompanyId: companyId,
      CategoryAccountChanges: queryArgs.CategoryAccountChanges,
      PositionCategoryChanges: queryArgs.PositionCategoryChanges,
      PositionAccountChanges: queryArgs.PositionAccountChanges,
      PositionVehicleUsageTypeChanges: queryArgs.PositionVehicleUsageTypeChanges
    }
  })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data?.GetRecalculatedDecree.RecalculatedDecree as DecreeResponse;
    });
