import {
  DecreeResponse,
  GetDecreeDetailsDocument,
  GetDecreeDetailsQuery,
  GetDecreeDetailsQueryVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetDecreeDetailsQueryFunction = async (decreeId: string, companyId: string): Promise<DecreeResponse> => {
  return await apolloClient
    .query<GetDecreeDetailsQuery, GetDecreeDetailsQueryVariables>({
      query: GetDecreeDetailsDocument,
      context: {
        envId: companyId,
      },
      variables: {
        DecreeId: decreeId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetDecreeDetails.Decree as DecreeResponse;
    });
};
