import {ModalWithTable} from '../../../components/ModalWithTable/ModalWithTable';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import {MissingInvoiceModel} from '../../../models';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';

export interface MissingInvoicesProps {
  open: boolean;
  missingInvoices?: MissingInvoiceModel[];
  requestedInvoicesCount?: number;
  onClose: () => void;
  onResolve?: () => void | Promise<void>
  title: ReactNode;
  content?: ReactNode | ((missingInvoicesCount?: number, allInvoicesCount?: number) => ReactNode);
  resolveButtonLabel?: string,
  rejectButtonLabel?: string,
  enableRejectButton?: boolean,
  tableTitle?: string,
  tableAlwaysVisible?: boolean,
  listMode?: boolean
  showButtonLabel?: string
  alignTop?: boolean,
  tableHeaders: ReactNode[],
}

export const MissingInvoicesModal = ({
                                       open,
                                       missingInvoices,
                                       requestedInvoicesCount,
                                       onClose,
                                       title,
                                       content,
                                       rejectButtonLabel,
                                       resolveButtonLabel,
                                       enableRejectButton,
                                       tableTitle = undefined,
                                       onResolve,
                                       showButtonLabel,
                                       listMode,
                                       tableAlwaysVisible,
                                       alignTop,
                                       tableHeaders,
                                     }: MissingInvoicesProps) => {
  const intl = useIntl();
  const [showMissingInvoicesTable, setShowMissingInvoicesTable] = useState(() => !!tableAlwaysVisible);
  const rows = useMemo(
    () => missingInvoices?.map(x => {
      if (listMode) {
        return [listMode && x.message ? x.message : x.invoiceNumber || '-', x.message ?? intl.formatMessage({id: `missingInvoiceReason_${x.reason}`})];
      }
      return [listMode && x.message ? x.message : x.invoiceNumber || '-', x.message ?? intl.formatMessage({id: `missingInvoiceReason_${x.reason}`})];
    }),
    [missingInvoices, intl, listMode],
  );

  useEffect(() => {
    !tableAlwaysVisible && setShowMissingInvoicesTable(false);
  }, [open, tableAlwaysVisible]);

  return (
    <ModalWithTable
      tableExpandable={tableAlwaysVisible}
      onConfirm={onResolve ?? onClose}
      onCancel={onClose}
      title={title}
      content={typeof content === 'function' ? content?.(missingInvoices?.length, requestedInvoicesCount) : content}
      open={open}
      cancelButtonName={rejectButtonLabel ? rejectButtonLabel : intl.formatMessage({id: Tr.cancelButton})}
      showCancelButton={enableRejectButton}
      confirmButtonName={resolveButtonLabel ? resolveButtonLabel : intl.formatMessage({id: Tr.ok})}
      tableTitle={tableTitle}
      tableRows={rows}
      tableHeaders={tableHeaders}
      showTableText={showButtonLabel ? showButtonLabel : intl.formatMessage({id: Tr.showDocumentsList})}
    />
  );
};
