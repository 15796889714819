import {ErrorContent, TransformedEventParams} from '../../SubscriptionEventsService';
import {AlertConfig} from '../../helpers/AlertService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {ToastVariant} from '@symfonia/brandbook';

export type ErrorEvent = Partial<TransformedEventParams>

export type HandlerOptions = { matched: boolean, ignored: boolean, historical?: boolean }

export type RequiredErrorEvent =
  Omit<ErrorEvent, 'errorContent' | 'errorType' | 'notificationId'>
  & Required<Pick<ErrorEvent, | 'errorType' | 'notificationId'>>
  & { errorContent: Array<{ key: string, value: string }> }

//Umożliwia obsługę błędu danego typu przychodzącego z backendu
export interface INotificationErrorService {
  type: WebsocketErrorType,
  matched: boolean,
  ignored: boolean,
  handled: boolean

  createAlert(event: ErrorEvent, fallback: AlertConfig): AlertConfig;

  handle(event: ErrorEvent, opt?: { once?: boolean, historical?: boolean }): Promise<void>

  getExceptionCode(errorContent?: ErrorContent): number | undefined
}

export abstract class AbstractNotificationErrorService implements INotificationErrorService {

  public matched: boolean = false;

  public ignored: boolean = false;

  public handled: boolean = false;

  protected constructor(public readonly type: WebsocketErrorType, protected defaultOptions: AlertConfig = {color: ToastVariant.ERROR}) {
  }

  public async handle(event: ErrorEvent, opt?: { once: boolean, historical?: boolean }): Promise<void> {
    if (this.handled && opt?.once) {
      return;
    }
    if (!event.errorType || !event.errorContent) {
      return;
    }
    await this._handle(event as RequiredErrorEvent, {
      matched: this.matched,
      ignored: this.ignored,
      historical: opt?.historical,
    });
    this.handled = true;
  }

  public createAlert(event: ErrorEvent, fallback?: AlertConfig): AlertConfig {
    if (!event.errorType) {
      this.matched = false;
    }

    const alert = this._createAlert(event);
    this.handled = false;
    this.matched = !!alert;
    return alert ? {...this.defaultOptions, ...alert} : {...this.defaultOptions, ...fallback};
  }

  public getExceptionCode(errorContent?: ErrorContent): number | undefined {
    const code = this.findContentValue('ExceptionCode', errorContent, {includesMode: true});
    return code === undefined ? undefined : Number(code);
  }

  protected ignore(ignored: boolean): this {
    this.ignored = ignored;
    return this;
  }

  protected getExceptionDescription(errorContent?: ErrorContent): string | undefined {
    return this.findContentValue('ExceptionDescription', errorContent, {includesMode: true});
  }

  protected findContentValue(searchingKey: string, errorContent: ErrorContent | undefined, opt?: { includesMode: boolean }): string | undefined {
    return errorContent?.find?.(({key}) => {
      if (opt?.includesMode) {
        return key.includes(searchingKey);
      }
      return key === searchingKey;
    })?.value;
  }

  protected abstract _createAlert(event: ErrorEvent): AlertConfig | undefined ;

  protected _handle(event: RequiredErrorEvent, opt?: HandlerOptions): void | Promise<void> {
    return;
  }
}



