import {ApolloError, useApolloClient} from '@apollo/client';
import {InvoiceModel} from '../models';
import {
  InvoiceDto,
  InvoicePostingStatus,
  KSeFTokenCredential,
  usePublishInvoicesMutation,
  useSendInvoicesForPostingMutation,
} from '@symfonia-ksef/graphql';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCallback, useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {AppRoutes} from '../../root/Routes';
import {NavLink, useNavigate} from 'react-router-dom';
import {InvoicePreviewInterface} from '../../../state/InvoicePreviewState/InvoicePreviewRepository';
import {exportToSymfoniaDesktop} from '../components/InvoicesTable/ActionMenus/InvoicesViewServices';
import {ToastVariant} from '@symfonia/brandbook';
import {SelectionI} from '../../root/services/TableServices/ExtendedTableService';
import {ContextualMenuState, InvoicesRow} from '../modules/Invoices/InvoicesTable/invoicesTableConfig';
import {StateI} from '../../root/services/MobXServices/State';
import {earchiveState, sendInvoicesResultService} from '@symfonia-ksef/state/rootRepository';

export const useInvoicesTableMethods = (invoicePreviewRepository?: InvoicePreviewInterface, selectionState?: SelectionI<InvoicesRow>, menuState?: StateI<ContextualMenuState>) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const addAlert = earchiveState.alertsState.addAlert;

  const [decreeForSelectedInvoices] = useSendInvoicesForPostingMutation({context: {envId: earchiveState.company.companyId}});
  const [publishInvoicesMutation] = usePublishInvoicesMutation({context: {envId: earchiveState.company.companyId}});
  const addSnackBarMessage = earchiveState.alertsState.addSnackBarMessage;

  const reloadInvoices = useCallback(async () => {
    await earchiveState.packageStatistics.load();
  }, []);

  const filterInvoicesToSend = useCallback((invoicesIds: string[]): { ids: string[], hasPermission: boolean } => {
    const permissions = earchiveState.company.ksefCredentials;
    if (!permissions?.includes(KSeFTokenCredential.InvoiceWrite)) {

      return {ids: [], hasPermission: false};
    }

    return {ids: invoicesIds, hasPermission: true};
  }, [earchiveState.company.ksefCredentials]);

  const selectedInvoicesIds = useMemo(() => selectionState ? selectionState.selected.map(x => x.Id + '') : [], [selectionState?.selected]);

  const removePending = useCallback((invoicesIds: string[]): string[] => {
    const ids: string[] = [];
    for (const id of invoicesIds) {
      sendInvoicesResultService.checkIsPending(id) || ids.push(id);
    }
    return ids;
  }, []);

  const isEmpty = useMemo<boolean>(() => !removePending(filterInvoicesToSend(selectedInvoicesIds).ids).length, [removePending, selectedInvoicesIds, sendInvoicesResultService.jobRunner.activeEventsUpdateTimestamp, filterInvoicesToSend]);

  const sendToKSeF = useCallback(async (invoicesIds: string[]) => {
    const {ids, hasPermission} = filterInvoicesToSend(invoicesIds);
    if (!hasPermission) {
      addAlert(intl.formatMessage({id: Tr.noPermissionToWrite}), ToastVariant.ERROR);
      return;
    }
    await sendInvoicesResultService.jobRunner.configure({InvoiceIds: removePending(ids)}).fetch();
  }, [addAlert, intl, filterInvoicesToSend, removePending]);

  const openSingleInvoiceActionsMenu = (invoice: InvoiceModel, anchorRef?: HTMLElement) => {
    selectionState?.clearSelection?.();
    menuState?.set({anchorEl: anchorRef, isOpen: true});
  };

  const closeSingleInvoiceActionsMenu = () => {
    selectionState?.clearSelection?.();
    menuState?.set({anchorEl: null, isOpen: false});
  };


  const runPosting = useCallback(
    (invoices: string[], unavailable?: number) => {
      const invoicesAvailable = invoices.length - (unavailable ?? 0);
      decreeForSelectedInvoices({
        variables: {
          InvoiceIds: invoices,
        },
        context: {
          envId: earchiveState.company.companyId,
        },
      })
        .then(res => {
          addAlert(<FormattedMessage id={Tr.sentInvoicesForPostingSuccess} values={{
            selected: invoicesAvailable,
            link: <NavLink
              to={AppRoutes.eArchive.address + '/' + earchiveState.company.tenantId + '/company/' + earchiveState.company.companyId + '/documents/autoposting'}>
              {intl.formatMessage({id: Tr.posting})}
            </NavLink>,
          }}/>, ToastVariant.SUCCESS, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
        })
        .catch((errors: ApolloError | undefined) => {
          addAlert(<FormattedMessage id={Tr.decreeFailed}/>, ToastVariant.ERROR, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
        });
      return;
    },
    [decreeForSelectedInvoices, earchiveState.company, navigate, addSnackBarMessage, intl],
  );

  const publishInvoices = useCallback((selectedInvoiceIds: string[]) => {
    publishInvoicesMutation({variables: {InvoiceIds: selectedInvoiceIds}})
      .then(res => {
        if (res.data?.PublishInvoices && res.data?.PublishInvoices.Failed > 0) {
          addAlert(intl.formatMessage({id: Tr.publishInvoicesAction_error}), ToastVariant.ERROR, {
            omitIfHasTheSameAlert: true,
            displayDuration: 10000,
          });
        } else {
          addAlert(intl.formatMessage({id: Tr.publishInvoicesAction_success}, {count: res.data?.PublishInvoices.Sent}), ToastVariant.SUCCESS, {
            omitIfHasTheSameAlert: true,
            displayDuration: 10000,
          });
        }
      })
      .catch((errors: ApolloError | undefined) => {
        addAlert(<FormattedMessage id={Tr.publishInvoicesAction_error}/>, ToastVariant.ERROR, {
          displayDuration: 10000,
          omitIfHasTheSameAlert: true,
        });
      });
  }, [addAlert, publishInvoicesMutation, earchiveState.company.companyId]);

  const publishInvoicesByServiceBusOrAPI = (Invoices: InvoiceDto[]) => {
    const invoicesSendByServiceBus = Invoices.filter(x =>
      x.InvoicePostingStatus === InvoicePostingStatus.Posted ||
      x.InvoicePostingStatus === InvoicePostingStatus.PostingIncomplete);
    const invoicesSendByAPI = Invoices.filter(
      x => x.InvoicePostingStatus === InvoicePostingStatus.NotAllowed ||
        x.InvoicePostingStatus === InvoicePostingStatus.Posting ||
        x.InvoicePostingStatus === InvoicePostingStatus.PostingFailed ||
        x.InvoicePostingStatus === InvoicePostingStatus.ReadyForPosting);
    if (invoicesSendByAPI.length > 0) exportToSymfoniaDesktop(invoicesSendByAPI);
    if (invoicesSendByServiceBus.length > 0) publishInvoices(invoicesSendByServiceBus.map(x => x.Id));
  };

  return {
    sendToKSeF,
    openSingleInvoiceActionsMenu,
    closeSingleInvoiceActionsMenu,
    removePending,
    isEmpty,
    reloadInvoices,
    runPosting,
    publishInvoices,
    publishInvoicesByServiceBusOrAPI,
  };
};

