import {
  GetPermissionsKSeFDocument,
  GetPermissionsKSeFQuery,
  GetPermissionsKSeFQueryVariables,
} from '@symfonia-ksef/graphql';
import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
} from '../../../root/services/TableServices/DataSourceTableService';
import {makeObservable} from 'mobx';
import {PermissionsFiltersState} from './PermissionsFIlters/PermissionsFiltersState';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export type PermissionsRepositoryI = BaseTableDataRepositoryServiceI<keyof GetPermissionsKSeFQuery, GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>

export class PermissionsRepository extends BaseTableDataRepositoryService<'GetPermissionsKSeF', GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables> implements PermissionsRepositoryI {
  constructor(private readonly filters: PermissionsFiltersState) {
    super('GetPermissionsKSeF', GetPermissionsKSeFDocument, envObserver, earchiveState);
    this.configure({SearchText: '', CredentialFiltersFields: {}, fromKsef: true});
    makeObservable(this);
  }

  public override checkIsReady(): boolean {
    return super.checkIsReady() && typeof this.variables.skip === 'number' && this.variables.take;
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.configure({CredentialFiltersFields: this.filters.filtersForQuery});
  }
}
