import * as React from 'react';
import {FC, useEffect, useRef, useState} from 'react';
import {useDebounce} from '../../../hooks/useDebounce';
import {Input, InputWidth} from '@symfonia/brandbook';

export type MultipleFilterItemType = {
  itemKey: React.Key,
  removeItem: (key: React.Key) => void,
  preventUpdateOnError?: boolean,
  value: string,
  onChange: (key: React.Key, value: string) => void,
  label: string,
  hideLabel?: boolean,
  placeholder?: string,
  disableValidation?: boolean,
  disableErrorFeedback?: boolean,
  min?: number,
  max?: number,
  idx: number,
  inputWidth?: InputWidth;
  onBlur: () => void;
  testId?: string;
  validationPattern?: RegExp
  setValidationError?: (hasError: boolean) => void;
}

export const MultipleFilterItem: FC<MultipleFilterItemType> = ({
                                                                 itemKey,
                                                                 removeItem,
                                                                 value,
                                                                 onChange,
                                                                 label,
                                                                 hideLabel,
                                                                 placeholder,
                                                                 preventUpdateOnError,
                                                                 disableValidation,
                                                                 disableErrorFeedback,
                                                                 min,
                                                                 max,
                                                                 idx,
                                                                 inputWidth,
                                                                 onBlur,
                                                                 testId,
                                                                 validationPattern,
                                                                 setValidationError = () => false,
                                                               }) => {
  const pattern = validationPattern || new RegExp(`^\\d{${min ?? ''},${max ?? ''}}$|(?=.{0,13}$)(^([0-9]+\\-?([0-9]+\\-?)+)$)`);
  const validate = (value: string) => {
    const isValid = !value || pattern.test(value);
    setHasError(!isValid);
    return isValid;
  };

  const [inputValue, setInputValue] = useState<string>(value);
  const [hasError, setHasError] = useState(false);
  const debouncedOnChange = useDebounce(onChange, 300);
  const debouncedOnError = useDebounce(setValidationError, 300);
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = (value: string) => {
    if (!value) return;
    setInputValue(value.toUpperCase());
    const isValid = disableValidation || validate(value);
    debouncedOnChange(itemKey, value.toUpperCase());
    if (preventUpdateOnError && !isValid) {
      debouncedOnError(true);
      return;
    }
    debouncedOnError(false);
  };

  const handleClear = (value: string) => {
    if (!value) {
      setInputValue('');
      const isValid = disableValidation || validate(value);
      debouncedOnChange(itemKey, value.toUpperCase());
      if (preventUpdateOnError && !isValid) {
        debouncedOnError(true);
        return;
      }
      debouncedOnError(false);
    }
  };

  // useEffect(() => {
  //   if (!hasError || disableErrorFeedback) {
  //     return;
  //   }
  //   const timeoutId = setTimeout(() => setHasError(false), 2000);
  //   return () => clearTimeout(timeoutId);

  // }, [hasError, disableErrorFeedback]);

  useEffect(() => {
    inputRef.current?.focus?.();
  }, []);

  return <Input
    label={!hideLabel ? label : ''}
    value={inputValue}
    placeholder={placeholder}
    isError={hasError}
    clearable={true}
    width={inputWidth}
    onBlur={onBlur}
    testId={testId}
    onChange={handleClear}
    onInput={handleChange}
  />;
};

