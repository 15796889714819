import {AnyObject} from 'yup/es/types';
import {action, computed, makeObservable, observable} from 'mobx';
import {NonFunction} from '../../../common/types/NonFunction';

export type PaginationConfig = { pages?: number, current?: number, size?: number }

export interface PaginationServiceI {
  get pages(): number | undefined;

  get current(): number;

  get size(): number;

  configure(config: Partial<Record<keyof PaginationConfig, number | ((current: number) => number)>>): void;

}

export class PaginationService<T extends AnyObject> implements PaginationServiceI {

  constructor(config?: PaginationConfig) {
    config && Object.entries(config).forEach(([key, value]) => (this[`_${key}` as keyof this] as typeof value) = value);
    makeObservable(this);
  }

  @observable
  protected _pages?: number;

  @computed
  public get pages(): number | undefined {
    return this._pages;
  }

  @observable
  protected _current: number = 1;

  @computed
  public get current(): number {
    return this._current;
  }

  @observable
  protected _size: number = 10;

  @computed
  public get size(): number {
    return this._size;
  }

  @action.bound
  public configure(config: Partial<Record<keyof PaginationConfig, number | ((current: number) => number)>>) {
    Object.entries(config).forEach(([key, value]) => (this[`_${key}` as keyof this] as NonFunction<typeof value>) = typeof value === 'function' ? value(this[`_${key}` as keyof this] as NonFunction<typeof value>) : value);
  }

}
