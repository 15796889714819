import React, {FunctionComponent, ReactNode} from 'react';
import {GoogleReCaptchaProvider as GoogleRecaptchaContainer} from 'react-google-recaptcha-v3';

interface IGoogleReCaptchaProvider {
  children: ReactNode;
  reCaptchaKey: string;
}

export const GoogleReCaptchaProvider: FunctionComponent<IGoogleReCaptchaProvider> = ({children, reCaptchaKey}) => {
  return (
    <GoogleRecaptchaContainer
      reCaptchaKey={reCaptchaKey}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body',
        nonce: undefined,
      }}
    >
      {children}
    </GoogleRecaptchaContainer>
  );
};
