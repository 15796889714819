import {createContext} from 'react';
import {SzafirService} from '../services';

export type SzafirSdkError = 'Unexpected' | 'NoHostApp' | 'NoExtension' | 'HostAppOutdated';

export interface SzafirSdkContext {
  szafirSdkError: SzafirSdkError | undefined;
  szafirService?: SzafirService;
}

export const initialContextState: SzafirSdkContext = {
  szafirSdkError: undefined
};

export const SzafirSdkContextProvider = createContext<SzafirSdkContext>(initialContextState);
