import {FC} from 'react';
import {useForm} from 'react-hook-form';
import {intl} from '../../../../root/IntlProvider';

import {CompanyKeyField} from '../../../../common';
import {ACCOUNT_MANAGEMENT_CONSTS} from './consts';
import {TenantInfoFormValuesModel} from './TenantInfoFormValuesModel';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Input, InputSize, InputWidth} from '@symfonia/brandbook';
import {Text} from '../../../../root/components/Text';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface CompanyInfoContainerProps {
  data: TenantInfoFormValuesModel;
}

const CompanyInfoContainer: FC<CompanyInfoContainerProps> = ({data}) => {
  const inputSize = InputSize.SM;
  const inputWidth = InputWidth.FULL;
  const metaData = ACCOUNT_MANAGEMENT_CONSTS.COMPANY_METADATA;
  const {tenantId} = earchiveState.company;
  const {getValues} = useForm<TenantInfoFormValuesModel>({defaultValues: data});

  const {companyName} = Tr;

  const companyNameIntl = intl.formatMessage({id: companyName});

  const identifierTypeIntl = intl.formatMessage({id: Tr[data.IdentifierType]});

  const {Name, Identifier} = getValues();

  if (!tenantId) {
    return <>No selected company</>;
  }

  const companyKey = `${tenantId};${data.Id};${data.Identifier}`;
  return (
    <div className="grid">
      <Text className="font-bold my-[20px]">{metaData.titleLabel}</Text>
      <div className="grid gap-[18px]">
        <div className="max-w-[280px]">
          <Input
            disabled
            label={companyNameIntl}
            placeholder={companyNameIntl}
            size={inputSize}
            value={Name}
            width={inputWidth}
          />
        </div>
        <Input
          className="max-w-[280px]"
          disabled
          label={identifierTypeIntl}
          placeholder={identifierTypeIntl}
          size={inputSize}
          value={Identifier}
          width={inputWidth}
        />
        <CompanyKeyField companyKey={companyKey}/>
      </div>
    </div>
  );
};

export default CompanyInfoContainer;
