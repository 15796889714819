import {BaseEventConsumer} from '../EventConsumer';
import {KSeFAuthResult, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {EventParams} from '../SubscriptionEventsService';
import {authenticationState, authorizationInKSeFWithTokenService} from '../../state/rootRepository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export class AuthorizeInKSeFWithTokenEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.AuthorizeInKsefExternalToken, authorizationInKSeFWithTokenService, {
      success: {id: Tr.authSubTokenSuccess},
      error: () => ({id: Tr.authSubTokenError, values: {ksefEnv: earchiveState.layoutState.isDemo ? 'DEMO' : 'PROD'}}),
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    !event.errorType ? await authenticationState.updateUserAuthAndCredentials(KSeFAuthResult.AuthorizedWithExternalToken) : authenticationState.setUnauthorized();
    event.type === WebsocketNotificationType.AuthorizeInKsefExternalToken ? authorizationInKSeFWithTokenService.authorizationRunner.setLoading(false) : authorizationInKSeFWithTokenService.removeAuthorizationRunner.setLoading(false);
    await super.onUpdate(event);
  }
}
