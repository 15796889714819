import {AbstractNotificationErrorService, ErrorEvent} from './NotificationErrorService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';

class KSeFSystemErrorService extends AbstractNotificationErrorService {
  constructor(private withoutInnerMessage: boolean = true) {
    super(WebsocketErrorType.System);
  }

  protected _createAlert(event: ErrorEvent): AlertConfig {
    if (this.withoutInnerMessage) {
      return {id: Tr.systemErrorWithoutInnerMessage};
    }
    return {
      id: Tr.systemErrorMessage,
      values: {errorMessage: event.errorContent?.find(x => x.key === 'InnerMessage')?.value},
    };
  }
}

export const kSeFSystemErrorService = new KSeFSystemErrorService();
