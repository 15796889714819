import {
  SaveCategoriesAccountsDocument,
  SaveCategoriesAccountsMutation,
  SaveCategoriesAccountsMutationVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const SaveCategoriesAccountsMutationFunction = async ({
  ...mutationArgs
}: SaveCategoriesAccountsMutationVariables): Promise<boolean> =>
  await apolloClient
    .mutate<SaveCategoriesAccountsMutation, SaveCategoriesAccountsMutationVariables>({
      mutation: SaveCategoriesAccountsDocument,
      context: {
        envId: mutationArgs.CompanyId,
      },
      variables: {
        ...mutationArgs,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }

      return response.data?.SaveCategoriesAccounts.IsDone ?? false;
    });
