import {Tr} from '@symfonia-ksef/locales/keys';
import {Dropdown, DropdownListOption, DropdownSize, DropdownWidth} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {intl} from '../../root/IntlProvider';

type FilehubFormatSelectProps = {
  onDropdownItemClick: (value: string) => void;
  dropdownSize: DropdownSize;
  dropdownWidth: DropdownWidth;
  notificationString?: string;
};

const FilehubFormatSelect = observer(
  ({
    onDropdownItemClick: onDropdownItemClick,
    dropdownSize = DropdownSize.SM,
    dropdownWidth = DropdownWidth.FULL,
    notificationString,
  }: FilehubFormatSelectProps) => {
    const [currentDropDownValue, setCurrentDropDownValue] = useState('formatPDF');

    const invoiceFormatList = useMemo<DropdownListOption[]>(() => {
      const options = [
        {
          label: Tr.formatPDF,
          value: 'formatPDF',
        },
        {
          label: Tr.formatXML,
          value: 'formatXML',
        },
        {
          label: Tr.formatPDFandXML,
          value: 'formatPDFandXML',
        },
        {
          label: Tr.noInvoice,
          value: 'noInvoice',
        },
      ];

      return options.map(({value, label}) => ({
        value,
        label: intl.formatMessage({id: label}),
      }));
    }, []);

    const handleDropdownItemClick = ([value]: string[]) => {
      setCurrentDropDownValue(value);
      onDropdownItemClick(value);
    };

    return (
      <Dropdown
        className="min-w-[220px]"
        width={dropdownWidth}
        onChange={handleDropdownItemClick}
        size={dropdownSize}
        options={invoiceFormatList}
        value={[currentDropDownValue]}
        placeholder={intl.formatMessage({id: Tr.formatPDF})}
        notification={notificationString}
      />
    );
  },
);

export default FilehubFormatSelect;
