import {FC, Key, ReactNode} from 'react';
import {
  ButtonSecondary,
  ButtonSecondarySize,
  ButtonTertiary,
  ButtonTertiarySize,
  IconSvg,
  Tooltip,
  TooltipPosition,
} from '@symfonia/brandbook';

export type TButton = {
  key: Key;
  onClick?: () => void;
  disabled?: boolean;
  tooltipDisabled?: boolean;
  tooltipTitle?: ReactNode;
  placement?: TooltipPosition;
  icon: IconSvg
  size?: ButtonSecondarySize,
  outlined?: boolean,
  className?: string
}

export const ButtonWithIcon: FC<TButton> = ({
                                              onClick,
                                              disabled,
                                              tooltipDisabled,
                                              tooltipTitle,
                                              placement,
                                              icon,
                                              size,
                                              outlined,
                                              className,
                                            }) => {

  const buttonProps = {onClick, disabled, lIcon: icon};

  const button = outlined ? <ButtonSecondary  {...buttonProps} size={size ?? ButtonSecondarySize.SM}/> :
    <ButtonTertiary size={(size ?? ButtonTertiarySize.SM) as ButtonTertiarySize} {...buttonProps}/>;

  return tooltipDisabled ? button : <Tooltip text={tooltipTitle} position={placement}>
    <div className={className}>
      {button}
    </div>
  </Tooltip>;


};
