import {observer} from 'mobx-react-lite';
import {FC, useMemo} from 'react';
import {FileItem} from '../FilesUploader/DropzoneUploaderModule/DropzoneUploaderStateService';
import {ButtonTertiary, ButtonTertiarySize, IconColor, IconSvg} from '@symfonia/brandbook';
import classnames from 'classnames';
import {ErrorReasons} from '../FilesUploader/DropzoneUploaderModule/DropzoneUploaderValidator';
import {CircularProgress, Text} from '@symfonia/symfonia-ksef-components';

type FileListItemProps = {
  fileItem: FileItem,
  removeFile: (file: File) => void
}


export const FileListItem: FC<FileListItemProps> = observer(({fileItem, removeFile}) => {

  const sizeError: boolean = useMemo(() => fileItem.validationErrors.some(error => [ErrorReasons.maxSize, ErrorReasons.minSize, ErrorReasons.mixed].includes(error.reason)), [fileItem.validationErrors]);
  const typeError: boolean = useMemo(() => fileItem.validationErrors.some(error => [ErrorReasons.type, ErrorReasons.mixed].includes(error.reason)), [fileItem.validationErrors]);

  return <div className={classnames('border rounded-2xl flex justify-between p-[8px] mb-[12px] items-center', {
    'border-slate-200': fileItem.validated,
    'border-red-500': !fileItem.validated,
  })}>
    <div>
      <CircularProgress progress={fileItem.percentagesProgress} error={!fileItem.validated}/>
    </div>
    <div className="grow px-[8px] h-full">
      <Text block small className={classnames('text-left mb-[6px] font-medium', {
        'text-slate-900': !typeError,
        'text-red-400': typeError,
      })}>{fileItem.file.name}</Text>
      <Text block small className={classnames('text-left', {
        'text-slate-500': !sizeError,
        'text-red-400': sizeError,
      })}>{fileItem.size}</Text>
    </div>
    <ButtonTertiary
      iconOptions={{color: IconColor.GREY_600}}
      size={ButtonTertiarySize.SM}
      lIcon={IconSvg.CLEAR}
      onClick={() => removeFile(fileItem.file)}
    />
  </div>;
});
