import {useTheme} from '@mui/material';
import {useCallback, useMemo} from 'react';
import {
  isFloat,
  matchBarColor,
  matchMessage,
  minmaxFactory,
  normaliseFactory,
  roundFactory,
  sortThresholds,
} from './packageLimitUtils';
import {LinearPackageLimitProps, Pipeable, StatusColorEnum} from './LinearPackageLimitType';

export const useLinearLimitCalculations = ({
                                             thresholds,
                                             limit,
                                             usage,
                                             unit,
                                             inactive,
                                             enableInheritedBackground,
                                             loading,
                                             formatted,
                                           }: LinearPackageLimitProps) => {
  const theme = useTheme();
  const minmax = useCallback<Pipeable>(minmaxFactory({min: 0, max: limit}), [limit]);
  const normalise = useCallback<Pipeable>(normaliseFactory({min: 0, max: minmax(limit)}), [limit, minmax]);
  const round = useCallback<Pipeable>(roundFactory(isFloat(usage) || isFloat(limit) ? 2 : 0), [usage, limit]);
  const normalizedThresholds = sortThresholds(thresholds);
  const message = matchMessage({limit: round(minmax(limit)), usage: round(minmax(usage)), unit, formatted});
  const normalizedUsage = normalise(usage);
  const percentageUsage = usage / limit * 100;
  const barColor = useMemo<StatusColorEnum>(() => matchBarColor(percentageUsage, normalizedThresholds, {
    isInactive: inactive,
    loading,
  }), [usage, limit, normalizedThresholds, inactive, loading]);
  const textColor = enableInheritedBackground ? theme.palette.common.black : theme.palette.common.white;
  const variant: 'determinate' | 'indeterminate' = loading ? 'indeterminate' : 'determinate';

  return {limit, message, normalizedUsage, barColor, textColor, usage, variant};
};
