import {FC} from 'react';
import {CircularProgress as CircularProgressComponent, CircularProgressProps} from '@mui/material';
import classnames from 'classnames';
import {Text} from '../Text/Text';


export const CircularProgress: FC<{ progress: number, error?: boolean, wrapperClassName?: string, labelClassName?: string } & CircularProgressProps> = ({
                                                                                                                                                          progress,
                                                                                                                                                          wrapperClassName,
                                                                                                                                                          labelClassName,
                                                                                                                                                          error,
                                                                                                                                                          ...props
                                                                                                                                                        }) => {
  return <div className={classnames('relative inline-flex w-[50px] h-[50px]', wrapperClassName)}>
    <CircularProgressComponent {...props} color={error ? 'error' : 'primary'} variant="determinate" value={progress}
                               className="!w-full !h-full"/>
    <div className={classnames('absolute flex items-center justify-center top-0 left-0 right-0 bottom-0')}>
      <Text small className={classnames('text-slate-500 !text-[12px]', labelClassName)}>{Math.round(progress)}%</Text>
    </div>
  </div>;
};
