import {
  SetWebsocketNotificationAcknowledgeDocument,
  SetWebsocketNotificationAcknowledgeMutation,
  SetWebsocketNotificationAcknowledgeMutationVariables,
} from '@symfonia-ksef/graphql';
import {InitializerType} from './helpers/fetchMatchedAction';
import {makeObservable, reaction} from 'mobx';
import {Repository} from '../modules/root/services/MobXServices/Repository/Repository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

//Służy do oznaczania notyfikacji o procesach sukbsrypcyjnych jako przeczytane
export class NotificationsReader extends Repository<keyof SetWebsocketNotificationAcknowledgeMutation, SetWebsocketNotificationAcknowledgeMutation, Omit<SetWebsocketNotificationAcknowledgeMutationVariables, 'NotificationIds'> & { NotificationIds: string[] }> {

  private timeoutId: ReturnType<typeof setTimeout> | undefined;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, private timeout: number = 0) {
    super('SetWebsocketNotificationAcknowledge', SetWebsocketNotificationAcknowledgeDocument, envObserver, earchiveState, InitializerType.Mutation);
    makeObservable(this);
    this.subscribeNotificationsIds();
  }

  public override checkIsReady(): boolean {
    return super.checkIsReady() && !!this.variables.NotificationIds?.length;
  }

  public async markAsReadNow(...ids: string[]): Promise<void> {
    await this.fetch({forceWith: {NotificationIds: ids}}).finally(() => this.configure({NotificationIds: []}));
  }

  public markAsReady(...ids: string[]): void {
    this.configure({NotificationIds: [...new Set([...this.variables.NotificationIds ?? [], ...ids])]});
  }

  public setTimeout(timeout: number): this {
    this.timeout = timeout;
    return this;
  }

  protected override afterFetch(data: SetWebsocketNotificationAcknowledgeMutation['SetWebsocketNotificationAcknowledge'] | null, response: SetWebsocketNotificationAcknowledgeMutation, forced: boolean): void | Promise<void> {
    !forced && this.configure({NotificationIds: []});
  }

  private subscribeNotificationsIds(): void {
    reaction(() => this.variables.NotificationIds, (NotificationIds, PrevNotificationIds) => {
      if (!NotificationIds?.length || NotificationIds.length === PrevNotificationIds?.length) {
        return;
      }
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.fetch({forceWith: {NotificationIds}}).finally(() => this.configure({NotificationIds: []})), this.timeout);
    });
  }
}


