import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';

interface DeleteCategoryModalProps {
  categoryName: string;
  open: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export const DeleteCategoryRulesModal: FC<DeleteCategoryModalProps> = ({categoryName, open, onDelete, onCancel}) => {
  const intl = useIntl();
  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      onClose={handleCancel}
      title={intl.formatMessage({id: Tr.deleteCategoryHeader})}
      show={open}
      centerButtons={true}
      className={'max-w-[500px]'}
    >
      <p>{intl.formatMessage({id: Tr.deleteCategoryDescription}, {categoryName: <span>{categoryName}</span>})}</p>
      <div className="flex gap-[16px] pt-[24px] justify-between">
        <ButtonSecondary
          onClick={handleCancel}
          text={intl.formatMessage({id: Tr.cancelButton})}
          width={ButtonSecondaryWidth.BASE}
        />
        <ButtonPrimary
          onClick={onDelete}
          text={intl.formatMessage({id: Tr.removeButton})}
          width={ButtonPrimaryWidth.BASE}
        />
      </div>
    </Modal>
  );
};
