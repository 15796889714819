import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {AddCategoryModal} from './modals/AddCategoryModal';
import {useCategoryAccountsTableMethods} from './useCategoryAccountsTableMethods';
import {DoubleNameErrorModal} from './modals/DoubleNameErrorModal';
import {InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {FC} from 'react';
import {AppRoutes} from '../../../../../../../../root/Routes';
import {CategoriesAccountsFiltersKeys} from './models/CategoryTypesFilterOptions';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {ButtonSecondary, ButtonSecondarySize} from '@symfonia/brandbook';
import {CategoryAccountService} from './CategoryAccount.service';

export interface CategoryAccountsTableRedirectionsProps {
  service: CategoryAccountService;
  state: ICategoryAccountState;
}

export const CategoryAccountsTableRedirections: FC<CategoryAccountsTableRedirectionsProps> = observer(
  ({service, state}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const {addNewCategory} = useCategoryAccountsTableMethods(state);
    const activeFilters = state.filterState.activeFilters;

    const handleRedirection = (route: string) => navigate(route);
    const handleAddCategory = async (name: string, group: InvoiceTypeGroupEnum) => {
      await addNewCategory(name, group).then((res) => {
      service.tableService.fetch();
      });
    };
    const handleCloseDialogs = () => {
      state.setAddDialogDoubleNameError(false);
      state.setOpenAddCategoryDialog(false);
    };

    const handleChangeDialogs = () => {
      state.setOpenAddCategoryDialog(true);
      state.setAddDialogDoubleNameError(false);
    };

    return (
      <>
        {state.addDialogDoubleNameError && (
          <DoubleNameErrorModal
            open={state.addDialogDoubleNameError}
            onChange={handleChangeDialogs}
            onCancel={handleCloseDialogs}
          />
        )}

        {state.openAddCategoryDialog && (
          <AddCategoryModal
            open={state.openAddCategoryDialog}
            onOk={handleAddCategory}
            onCancel={() => state.setOpenAddCategoryDialog(false)}
            initialCategoryGroup={activeFilters.get(CategoriesAccountsFiltersKeys.InvoiceTypeGroup)!.values[0]}
          />
        )}

        <div className="flex gap-[12px] md:mt-0 mt-[10px] ">
          <ButtonSecondary
            onClick={() => handleRedirection('.' + AppRoutes.postingVehicles.address)}
            text={intl.formatMessage({id: Tr.vehicles})}
            size={ButtonSecondarySize.SM}
          />
          <ButtonSecondary
            disabled={!state.postingConfigurationState.isFinancialYearActive}
            onClick={() => state.setOpenAddCategoryDialog(true)}
            text={intl.formatMessage({id: Tr.addCategory})}
            size={ButtonSecondarySize.SM}
          />
        </div>
      </>
    );
  },
);
