import {Alerts, FooterComponent} from './components';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {sessionStorageService} from '../common/helpers/storage';
import {getStoragePermissionsFromCacheKey} from '../earchive/helpers/PermissionsFromCacheStorage';
import {Header} from './Header';
import {VersionRibbon} from './components/VersionRibbon';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export const LayoutModule: FCC<unknown> = observer((props) => {
  const {
    company: {
      companyId,
    }, tenant: {
      state: {
        shouldGetPermissionsFromCache, title,
      },
    },
  } = earchiveState;
  const isDemo = earchiveState.layoutState.isDemo;

  useEffect(() => {
    addEventListener('locationChange', (e) => {
      if (shouldGetPermissionsFromCache && companyId) {
        sessionStorageService.removeItem(getStoragePermissionsFromCacheKey(companyId));
        earchiveState.tenant.set({shouldGetPermissionsFromCache: false});
      }
    });
  }, [shouldGetPermissionsFromCache]);
  return (
    <div className="layout-wrapper">
      <Header title={title}/>
      {isDemo && <VersionRibbon/>}
      <Alerts/>
      <div className="page-content">{props.children}</div>
      <FooterComponent/>
    </div>
  );
});
