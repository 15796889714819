export enum UnitFormat {
  XX = 'XX',
  xx = 'xx',
  Xx = 'Xx',
  xX = 'xX'
}

export enum Unit {
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  Auto = 'Auto'
}

type ValueUnitEntry = [number, Unit]

type Config = { readonly precision: number | null, readonly format: UnitFormat, readonly unit: Unit }

const DEFAULT_CONFIG: Config = Object.freeze({
  precision: 2,
  format: UnitFormat.XX,
  unit: Unit.Auto,
});

export class BytesConverter {
  private config!: Config;

  constructor(bytes: number = 0, config?: Partial<Config>) {
    this.config = {...DEFAULT_CONFIG, ...config};
    this.set(bytes);
  }

  private _bytes!: number;

  public get bytes(): number {
    return this._bytes;
  }

  public get bits(): number {
    return this._bytes * 8;
  }

  public get kilobytes(): number {
    return this.calculate(1);
  }

  public get megabytes(): number {
    return this.calculate(2);
  }

  public get gigabytes(): number {
    return this.calculate(3);
  }

  public get value(): string {
    const [value, unit] = this.matchValueUnitEntry([[this.kilobytes, Unit.KB], [this.megabytes, Unit.MB], [this.gigabytes, Unit.GB]] as ValueUnitEntry[]);
    return `${this.transformValue(value).replace('.', ',')}${this.transformUnit(unit)}`;
  }

  public get valueWithSpace(): string {
    const [value, unit] = this.matchValueUnitEntry([[this.kilobytes, Unit.KB], [this.megabytes, Unit.MB], [this.gigabytes, Unit.GB]] as ValueUnitEntry[]);
    return `${this.transformValue(value).replace('.', ',')} ${this.transformUnit(unit)}`;
  }


  public static format(...parameters: ConstructorParameters<typeof BytesConverter>): BytesConverter {
    return new BytesConverter(...parameters);
  }

  public set(bytes: number): this {
    this._bytes = Math.round(bytes);
    return this;
  }

  public configure(config: Partial<Config>): this {
    this.config = {...this.config, ...config};
    return this;
  }

  private matchValueUnitEntry(entries: ValueUnitEntry[]): ValueUnitEntry {
    const sorted = entries.sort(([valueA], [valueB]) => valueA - valueB);
    if (this.config.unit === Unit.Auto) {
      return sorted.find(([value]) => value * 10 >= 1) ?? sorted[0];
    }
    return entries.find(([_, unit]) => unit === this.config.unit) ?? sorted[0];
  }

  private transformUnit(unit: Unit): string {
    return Array.from(this.config.format.toString()).map((char, index) => {
      const unitChar = unit.toString()[index];
      if (!unitChar) {
        return '';
      }
      return char === 'X' ? unitChar.toUpperCase() : unitChar.toLowerCase();

    }).reduce((str, char) => str + char, '');
  }

  private transformValue(value: number): string {
    return this.config.precision !== null ? value.toFixed(this.config.precision) : String(value);
  }

  private calculate(power: number): number {
    const value = this._bytes / (1024 ** power);
    return parseFloat(this.transformValue(value));
  }
}
