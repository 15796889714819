import React, {FC} from 'react';
import {FiltersBar, FiltersBarProps} from './FiltersBar';
import {ButtonTertiary, ButtonTertiarySize, IconSvg, Toggle} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import {DownloadReport, DownloadReportProps} from '../DownloadReport/DownloadReport';

export interface FiltersBarWithReportsProps extends FiltersBarProps, DownloadReportProps {
  clearFilters: () => void;
  filtersIsActive: boolean;
  previewSwitchLabel: string;
  withoutReport?: boolean;
  filtersTouched?: boolean;
  invoicePreview?: {enableInvoicePreview: boolean; setEnableInvoicePreview(enabled: boolean): void};
  testId?: string;
  clearFiltersLabel: string;
  downloadEnabled: boolean;
}

export const FiltersBarWithReports: FC<FiltersBarWithReportsProps> = observer(
  ({
    tableLength,
    isFiltersMenuOpened,
    openFiltersMenu,
    filtersApplied,
    onConfirm,
    invoicePreview,
    withoutReport,
    previewSwitchLabel,
    tooltipText,
    filtersTouched,
    clearFilters,
    filtersIsActive,
    testId,
    clearFiltersLabel,
    downloadEnabled,
  }) => {
    const downloadReportEnable = tableLength > 0 && downloadEnabled;

    if (withoutReport) {
      return null;
    }

    return (
      <div className="flex justify-between items-center flex-wrap">
        <div className="flex flex-wrap my-[10px] items-center">
          <div>
            <FiltersBar
              isFiltersMenuOpened={isFiltersMenuOpened}
              openFiltersMenu={openFiltersMenu}
              tableLength={tableLength}
              filtersApplied={filtersApplied}
              testId={testId}
            />
          </div>
          <div>
            <DownloadReport onConfirm={onConfirm} tooltipText={tooltipText} disabled={!downloadReportEnable}/>
          </div>
          <div/>
          {filtersIsActive && (
            <ButtonTertiary
              className="mr-[8px] mt-[4px]"
              size={ButtonTertiarySize.SM}
              onClick={() => clearFilters()}
              text={clearFiltersLabel}
              lIcon={IconSvg.CLOSE}
            />
          )}
        </div>
        {invoicePreview && (
          <div className={'items-center hidden md:flex lg:flex xl:flex 2xl:flex'}>
            <p className={'font-quicksand text-base font-medium mr-[10px]'}>{previewSwitchLabel}</p>
            <Toggle
              onChange={checked => invoicePreview.setEnableInvoicePreview(checked)}
              value=""
              checked={invoicePreview.enableInvoicePreview}
            />
          </div>
        )}
      </div>
    );
  },
);
