import {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

export enum LabelSize {
  MD = 'MD',
  SM = 'SM',
}

export type LabelProps = {
  text?: string;
  size?: LabelSize;
  className?: string;
  isRequired?: boolean;
};

export const Label: FC<PropsWithChildren<LabelProps>> = ({
  text = '',
  size = LabelSize.MD,
  className = undefined,
  isRequired = false,
  children = undefined,
}) => {
  const styles = {
    component: clsx(className, 'font-quicksand flex', {
      'text-base leading-[24px]': size === LabelSize.MD,
      'text-sm leading-[16px] ': size === LabelSize.SM,
    }),
    required: clsx('text-red-500 font-bold mr-[4px] inline-block scale-[1.5]', {
      'h-[24px]': size === LabelSize.MD,
      'h-[20px]': size === LabelSize.SM,
    }),
  };

  return (
    <div className={styles.component}>
      {isRequired && <span className={styles.required}>*</span>}
      <span data-testid="input-label" className="overflow-hidden text-ellipsis whitespace-nowrap">
        {children || text}
      </span>
    </div>
  );
};
