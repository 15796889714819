import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {Icon, IconColor, IconSize, IconSvg} from '../Icon/Icon';
import {TestableElement} from '../../external/types';

export enum NotificationVariant {
  INFO = 'BLUE1',
  SUCCESS = 'PRIMARY',
  ERROR = 'RED',
  WARNING = 'ORANGE',
}

export enum NotificationSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export type NotificationProps = {
  text: string;
  variant: NotificationVariant;
  size: NotificationSize;
  className?: string;
} & TestableElement;

const variantToIcon = (variant: NotificationVariant): IconSvg => {
  switch (variant) {
    case NotificationVariant.INFO: {
      return IconSvg.INFO;
    }
    case NotificationVariant.SUCCESS: {
      return IconSvg.CHECKMARK_CIRCLE;
    }
    default: {
      return IconSvg.WARNING_CIRCLE;
    }
  }
};

export const Notification: FC<NotificationProps> = ({
  text,
  variant = NotificationVariant.INFO,
  size = NotificationSize.SM,
  className = '',
  testId = undefined,
}) => {
  const icon = useMemo(() => variantToIcon(variant), [variant]);

  const styles = useMemo(
    () => ({
      text: clsx({
        'pl-2 font-medium font-quicksand': true,
        'text-sm': size === NotificationSize.SM,
        'text-base': size === NotificationSize.MD,
        'text-xl': size === NotificationSize.LG,
        'text-blue1-800': variant === NotificationVariant.INFO,
        'text-primary-800': variant === NotificationVariant.SUCCESS,
        'text-orange-800': variant === NotificationVariant.WARNING,
        'text-red-800': variant === NotificationVariant.ERROR,
      }),
    }),
    [size, variant],
  );

  return (
    <div data-testid={testId} data-test-element="notification" className={`${className} flex items-center w-full`}>
      <Icon
        svg={icon}
        size={IconSize[size]}
        color={IconColor[`${variant}_500`]}
        className="mt-[2px] self-baseline shrink-0"
      />
      <span className={styles.text}>{text}</span>
    </div>
  );
};
