import {uniqBy} from 'lodash';
import {forwardRef, MouseEventHandler, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {escapeRegExp} from '../../../components/InputDate/InputDate.helpers';

/* eslint react/require-default-props: 0 */
export type HighlightProps = {
  text: string;
  phrase: string | string[];
  highlightClassName?: string;
  className?: string;
  caseSensitive?: boolean;
  firstMatchOnly?: boolean;
  onClick?: () => void;
  ellipsis?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
};
/* eslint react/require-default-props: 1 */

type MatchType = {0: string; index: number};

export const getSlices = (
  text: string,
  phrases: string[],
  caseSensitive: boolean,
  firstMatchOnly: boolean,
): [string, boolean][] => {
  let matches = (caseSensitive === false
    ? Array.from(text.toLowerCase().matchAll(escapeRegExp(phrases.join('|').toLowerCase()) as unknown as RegExp))
    : Array.from(text.matchAll(escapeRegExp(phrases.join('|')) as unknown as RegExp))) as unknown as MatchType[];

  if (firstMatchOnly) {
    matches = uniqBy(matches, '0');
  }

  const output: [string, boolean][] = [];

  if (matches.length === 0) {
    return [[text, false]];
  }

  output.push([text.slice(0, matches[0].index), false]);

  for (let i = 0; i < matches.length; i += 1) {
    output.push([text.slice(matches[i].index, matches[i].index + matches[i][0].length), true]);
    if (i < matches.length - 1) {
      output.push([text.slice(matches[i].index + matches[i][0].length, matches[i + 1].index), false]);
    } else {
      output.push([text.slice(matches[i].index + matches[i][0].length), false]);
    }
  }

  return output.filter(([s]) => s.length > 0);
};

export const Highlight = forwardRef<HTMLSpanElement, HighlightProps>(
  (
    {
      caseSensitive = false,
      className = '',
      text,
      phrase,
      highlightClassName = 'bg-primary-50',
      firstMatchOnly = true,
      onClick = undefined,
      ellipsis = false,
      onMouseEnter = undefined,
      onMouseLeave = undefined,
    },
    ref,
  ) => {
    const slices = useMemo<[[string, boolean], string][]>(
      () =>
        getSlices(text || '', typeof phrase === 'string' ? [phrase] : phrase, caseSensitive, firstMatchOnly).map(
          slice => [slice, crypto.randomUUID()],
        ),
      [text, phrase, caseSensitive, firstMatchOnly],
    );

    const isEllipsis = ellipsis && firstMatchOnly;

    return (
      <span
        className={twMerge(clsx({'whitespace-pre': !isEllipsis, 'whitespace-nowrap flex': isEllipsis}, className))}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        {slices.map(([[sliceText, isHighlighted], key], index) => (
          <span
            dir={isEllipsis && index === 0 && slices.length > 1 ? 'rtl' : 'ltr'}
            key={key}
            className={twMerge(
              clsx({
                'space-before':
                  (index === 0 && isEllipsis && sliceText.endsWith(' ') && sliceText.length > 1) ||
                  (isEllipsis && sliceText.startsWith(' ')),
                'space-after': index > 0 && isEllipsis && sliceText.endsWith(' ') && sliceText.length > 1,
                [highlightClassName]: isHighlighted,
                'grow-0 shrink-0': isEllipsis && isHighlighted,
                'whitespace-nowrap': isEllipsis && !isHighlighted,
                'overflow-hidden text-ellipsis whitespace-nowrap grow shrink':
                  isEllipsis && !isHighlighted && [0, slices.length - 1].includes(index),
              }),
            )}
          >
            {sliceText}
          </span>
        ))}
      </span>
    );
  },
);
