import {FC, ReactElement} from 'react';
import {CheckboxProps, Checkbox, CheckboxSize} from '../../Checkbox/Checkbox';
import {TableSortDirection} from '../Table.types';
import {TableSortIcon} from './TableSortIcon';
import {useClsx} from '../../../external/hooks/useClsx';

export type TableHeaderProps = {
  name: string;
  title?: string;
  content?: ReactElement | string;
  checkbox?: CheckboxProps;
  width?: string;
  className?: string;
  sortable?: boolean;
  defaultSortDirection?: TableSortDirection;
};

export type TableHeaderInnerProps = Omit<TableHeaderProps, 'width'> & {
  direction?: TableSortDirection;
  onSortChange?: (name: string) => void;
};

export const TableHeader: FC<TableHeaderInnerProps> = ({
  name,
  title = undefined,
  content = undefined,
  checkbox = undefined,
  className = undefined,
  sortable = undefined,
  direction = undefined,
  onSortChange = () => undefined,
}) => {
  const myClassName = useClsx({'cursor-default': !sortable, 'cursor-pointer hover:bg-grey-100': sortable}, className);
  return (
    <span className={myClassName} onClick={() => sortable && onSortChange(name)}>
      {content || (
        <>
          {checkbox && <Checkbox size={CheckboxSize.SM} {...checkbox} />}
          <span>{title}</span>
        </>
      )}
      {sortable && <TableSortIcon direction={sortable ? direction : undefined} />}
    </span>
  );
};
