import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';
import {Icon, IconSize, IconSvg, IconVariant} from '../Icon/Icon';
import {Tooltip} from '../Tooltip/Tooltip';

export enum ButtonGroupSize {
  SM = 'SM',
  MD = 'MD',
}

export type ButtonGroupButton = {
  text?: string;
  icon?: IconSvg | null;
  iconVariant?: IconVariant | null;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  tooltipText?: string;
};

export type ButtonGroupProps = {
  selectedIndex: number;
  onClick: (index: number) => void;
  className?: string;
  size?: ButtonGroupSize;
  buttons: ButtonGroupButton[];
} & TestableElement;

export const ButtonGroup: FC<ButtonGroupProps> = ({
  selectedIndex = 0,
  className = '',
  size = ButtonGroupSize.MD,
  onClick,
  buttons,
  testId = undefined,
}) => {
  const buttonsWithKeys = useMemo<[ButtonGroupButton, string][]>(
    () => buttons.map(button => [button, crypto.randomUUID()]),
    [buttons],
  );

  const iconSize = size === ButtonGroupSize.MD ? IconSize.LG : IconSize.MD;

  const styles = {
    component: clsx(
      'inline-flex font-quicksand font-bold',
      {
        'h-[48px]': size === ButtonGroupSize.MD,
        'h-[40px]': size === ButtonGroupSize.SM,
      },
      className,
    ),
    button: (buttonIndex: number, buttonGroupLength: number, disabled?: boolean) =>
      twMerge(
        clsx(
          'px-[20px] text-base h-full flex items-center select-none border-x-[1px] border-y-[2px] border-primary-500 box-border group',
          {
            'bg-white cursor-pointer hover:bg-primary-500 hover:border-transparent active:bg-primary-600 active:border-transparent':
              buttonIndex !== selectedIndex && !disabled,
            'bg-primary-500 cursor-pointer hover:bg-primary-600 hover:border-transparent active:bg-primary-700 active:border-transparent':
              buttonIndex === selectedIndex && !disabled,
            'border-grey-300 box-border cursor-default text-grey-300 bg-white': disabled,
            'border-l-[2px] rounded-l-[8px]': buttonIndex === 0,
            'border-r-[2px] rounded-r-[8px]': buttonIndex === buttonGroupLength - 1,
          },
        ),
      ),
    icon: (buttonIndex: number, buttonText?: string, disabled?: boolean) =>
      clsx({
        'mr-[8px]': buttonText && buttonText.length > 0,
        'filter-grey-300': disabled,
        'filter-primary-500 group-hover:filter-grey-0': !disabled && buttonIndex !== selectedIndex,
        'filter-grey-0': !disabled && buttonIndex === selectedIndex,
      }),
    text: (buttonIndex: number, disabled?: boolean) =>
      clsx('font-quicksand font-bold text-base', {
        'text-grey-300': disabled,
        'text-primary-500 group-hover:text-white': !disabled && buttonIndex !== selectedIndex,
        'text-white': !disabled && buttonIndex === selectedIndex,
      }),
  };

  const handleClick = (buttonIndex: number) => {
    if (buttons[buttonIndex] !== undefined && buttons[buttonIndex].onClick !== undefined) {
      buttons[buttonIndex].onClick!();
    }
    onClick(buttonIndex);
  };

  return (
    <span className={styles.component} data-test-elemenet="button-group" data-testid={testId}>
      {buttonsWithKeys.map(([button, key], buttonIndex, array) => {
        const componentBody = (
          <>
            {button.icon && (
              <Icon
                className={styles.icon(buttonIndex, button.text, button.disabled)}
                svg={button.icon}
                size={iconSize}
                variant={button.iconVariant || IconVariant.CONTOUR}
              />
            )}
            {button.text && <span className={styles.text(buttonIndex, button.disabled)}>{button.text}</span>}
          </>
        );

        return (
          <Tooltip key={key} text={button.tooltipText}>
            {button.href ? (
              <NavLink
                className={styles.button(buttonIndex, array.length, button.disabled)}
                to={button.href}
                onClick={() => !button.disabled && handleClick(buttonIndex)}
              >
                {componentBody}
              </NavLink>
            ) : (
              <button
                type="button"
                className={styles.button(buttonIndex, array.length, button.disabled)}
                onClick={() => !button.disabled && handleClick(buttonIndex)}
              >
                {componentBody}
              </button>
            )}
          </Tooltip>
        );
      })}
    </span>
  );
};
