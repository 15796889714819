import {useEffect, useMemo, useState} from 'react';

export function useWindowWidthChange({unit = 'px', min, max}: {min?: number; max?: number; unit?: Unit}): boolean {
  const query = useMemo(() => buildQuery({min, max}, unit), [min, max, unit]);
  const mediaQueries = useMemo<MediaQueryList>(() => window.matchMedia(query), [query]);
  const [matches, setMatches] = useState<boolean>(mediaQueries.matches);

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent): void => setMatches(e.matches);
    mediaQueries.addEventListener('change', handleChange);
    return () => mediaQueries.removeEventListener('change', handleChange);
  }, [mediaQueries]);

  return matches;
}

export function useDeviceViewport(
  value: (viewport: {
    isDesktop: boolean;
    isTablet: boolean;
    isPhone: boolean;
    isLargeDesktop: boolean;
    isSmallPhone: boolean;
  }) => void,
): {isPhone: boolean; isTablet: boolean; isDesktop: boolean; isSmallPhone: boolean; isLargeDesktop: boolean} {
  const isSmallPhone = useWindowWidthChange({max: 440});
  const isPhone = useWindowWidthChange({max: 767});
  const isTablet = useWindowWidthChange({min: 768, max: 1365});
  const isDesktop = useWindowWidthChange({min: 1366, max: 1919});
  const isLargeDesktop = useWindowWidthChange({min: 1920});
  const setViewport = value;
  const viewport = useMemo(
    () => ({
      isSmallPhone,
      isPhone,
      isTablet,
      isDesktop,
      isLargeDesktop,
    }),
    [isSmallPhone, isPhone, isTablet, isDesktop, isLargeDesktop],
  );

  useEffect(() => {
    setViewport(viewport);
  }, [viewport, setViewport]);

  return viewport;
}

function buildQuery(opt: {min?: number; max?: number}, unit: Unit = 'px') {
  return Object.entries(opt)
    .filter(([, value]) => value !== undefined)
    .reduce<string>((query, [key, value]) => query + (query ? ' and ' : '') + `(${key}-width: ${value}${unit})`, '');
}

type Unit = 'px' | 'rem' | 'em';
