export function getUnique<T, P = string>(arr: T[], getUniqueKey: (item: T) => P | undefined): T[] {
  const uniqueMap: Map<P, T> = new Map<P, T>();
  const uniqueArr: T[] = [];
  for (const item of arr) {
    const key = getUniqueKey(item);
    if (key && !uniqueMap.has(key)) {
      uniqueMap.set(key, item);
      uniqueArr.push(item);
    }
  }
  return uniqueArr;
}
