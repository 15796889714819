import {ILoggerCallback, LogLevel} from '@azure/msal-browser';


export const authLogger = (): ILoggerCallback => {
  return (level: LogLevel, message: string) => {
    const log = (func: (message: string, error?: Error | any, data?: any) => void) => func(`Auth: ${message}`);
    switch (level) {
      case LogLevel.Trace:
        return log(console.debug);
      case LogLevel.Verbose:
        return log(console.debug);
      case LogLevel.Info:
        return log(console.debug);
      case LogLevel.Warning:
        return log(console.debug);
      case LogLevel.Error:
        return log(console.debug);
    }
  };
};
