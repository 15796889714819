import {
  KSeFAutoFetchingInvoicesDocument,
  KSeFAutoFetchingInvoicesMutation,
  KSeFAutoFetchingInvoicesMutationVariables,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../../services/helpers/fetchMatchedAction';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {intl} from '../../modules/root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {Repository} from '../../modules/root/services/MobXServices/Repository/Repository';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class AutoFetchingInvoicesService extends Repository<'KSeFAutoFetchingInvoices', KSeFAutoFetchingInvoicesMutation, KSeFAutoFetchingInvoicesMutationVariables> {

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('KSeFAutoFetchingInvoices', KSeFAutoFetchingInvoicesDocument, envObserver, earchiveState, InitializerType.Mutation);
  }

  public override onSuccess(data: KSeFAutoFetchingInvoicesMutation['KSeFAutoFetchingInvoices']): void | Promise<void> {
    const addAlert = earchiveState.alertsState.addAlert;
    if (data.Success) {
      addAlert(intl.formatMessage({id: Tr.submitFormSuccess}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    } else {
      addAlert(intl.formatMessage({id: Tr.submitFormError}), ToastVariant.ERROR, {
        displayDuration: 0,
        omitIfHasTheSameAlert: true,
      });
    }
    return;
  }

  public override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    if (errors && errors.length > 0) {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.submitFormError}), ToastVariant.ERROR, {
        displayDuration: 0,
        omitIfHasTheSameAlert: true,
      });
    }
  }

}
