import clsx from 'clsx';
import {FC, KeyboardEvent} from 'react';
import {Icon, IconSize, IconSvg, IconVariant} from '../Icon/Icon';
import {TestableElement} from '../../external/types';

export enum IconTagSize {
  SM = 'SM',
  MD = 'MD',
}

export enum IconTagVariant {
  FILLED = 'FILLED',
  OUTLINED = 'OUTLINED',
}

export enum IconTagColor {
  DEFAULT = 'TAG1',
  GREY = 'TAG2',
  GREEN = 'TAG3',
  RED = 'TAG4',
  ORANGE = 'TAG5',
  YELLOW = 'TAG6',
  PURPLE = 'TAG7',
  BLUE1 = 'TAG8',
  BLUE2 = 'TAG9',
  DEFAULT_LIGHT = 'TAG10',
  GREY_LIGHT = 'TAG11',
  GREEN_LIGHT = 'TAG12',
  RED_LIGHT = 'TAG13',
  ORANGE_LIGHT = 'TAG14',
  YELLOW_LIGHT = 'TAG15',
  PURPLE_LIGHT = 'TAG16',
  BLUE1_LIGHT = 'TAG17',
  BLUE2_LIGHT = 'TAG18',
}

export type IconTagProps = {
  text?: string;
  icon?: IconSvg | null;
  actionIcon: IconSvg | null;
  onActionIconClick: () => void;
  variant?: IconTagVariant;
  size?: IconTagSize;
  disabled?: boolean;
  color?: IconTagColor;
} & TestableElement;

const colorStyles: Record<IconTagVariant, Record<string, string>> = {
  [IconTagVariant.FILLED]: {
    [IconTagColor.DEFAULT]: 'text-white bg-primary-500',
    [IconTagColor.GREY]: 'text-white bg-grey-500',
    [IconTagColor.GREEN]: 'text-white bg-green-500',
    [IconTagColor.RED]: 'text-white bg-red-500',
    [IconTagColor.ORANGE]: 'text-white bg-orange-500',
    [IconTagColor.YELLOW]: 'text-white bg-yellow-500',
    [IconTagColor.PURPLE]: 'text-white bg-purple-500',
    [IconTagColor.BLUE1]: 'text-white bg-blue1-500',
    [IconTagColor.BLUE2]: 'text-white bg-blue2-500',
    [IconTagColor.DEFAULT_LIGHT]: 'text-primary-800 bg-primary-100',
    [IconTagColor.GREY_LIGHT]: 'text-grey-800 bg-grey-100',
    [IconTagColor.GREEN_LIGHT]: 'text-green-800 bg-green-100',
    [IconTagColor.RED_LIGHT]: 'text-red-800 bg-red-100',
    [IconTagColor.ORANGE_LIGHT]: 'text-orange-800 bg-orange-100',
    [IconTagColor.YELLOW_LIGHT]: 'text-yellow-800 bg-yellow-100',
    [IconTagColor.PURPLE_LIGHT]: 'text-purple-800 bg-purple-100',
    [IconTagColor.BLUE1_LIGHT]: 'text-blue1-800 bg-blue1-100',
    [IconTagColor.BLUE2_LIGHT]: 'text-blue2-800 bg-blue2-100',
  },
  [IconTagVariant.OUTLINED]: {
    [IconTagColor.DEFAULT]: 'text-primary-500 border-primary-500',
    [IconTagColor.GREY]: 'text-grey-500 border-grey-500',
    [IconTagColor.GREEN]: 'text-green-500 border-green-500',
    [IconTagColor.RED]: 'text-red-500 border-red-500',
    [IconTagColor.ORANGE]: 'text-orange-500 border-orange-500',
    [IconTagColor.YELLOW]: 'text-yellow-500 border-yellow-500',
    [IconTagColor.PURPLE]: 'text-purple-500 border-purple-500',
    [IconTagColor.BLUE1]: 'text-blue1-500 border-blue1-500',
    [IconTagColor.BLUE2]: 'text-blue2-500 border-blue2-500',
    [IconTagColor.DEFAULT_LIGHT]: 'text-primary-600 border-primary-100',
    [IconTagColor.GREY_LIGHT]: 'text-grey-600 border-grey-100',
    [IconTagColor.GREEN_LIGHT]: 'text-green-600 border-green-100',
    [IconTagColor.RED_LIGHT]: 'text-red-600 border-red-100',
    [IconTagColor.ORANGE_LIGHT]: 'text-orange-600 border-orange-100',
    [IconTagColor.YELLOW_LIGHT]: 'text-yellow-600 border-yellow-100',
    [IconTagColor.PURPLE_LIGHT]: 'text-purple-600 border-purple-100',
    [IconTagColor.BLUE1_LIGHT]: 'text-blue1-600 border-blue1-100',
    [IconTagColor.BLUE2_LIGHT]: 'text-blue2-600 border-blue2-100',
  },
};

const iconBackgroundStyles: Record<IconTagVariant, Record<string, string>> = {
  [IconTagVariant.FILLED]: {
    [IconTagColor.DEFAULT]: 'hover:bg-primary-400 focus-visible:bg-primary-500',
    [IconTagColor.GREY]: 'hover:bg-grey-400 focus-visible:bg-grey-500',
    [IconTagColor.GREEN]: 'hover:bg-green-400 focus-visible:bg-green-500',
    [IconTagColor.RED]: 'hover:bg-red-400 focus-visible:bg-red-500',
    [IconTagColor.ORANGE]: 'hover:bg-orange-400 focus-visible:bg-orange-500',
    [IconTagColor.YELLOW]: 'hover:bg-yellow-400 focus-visible:bg-yellow-500',
    [IconTagColor.PURPLE]: 'hover:bg-purple-400 focus-visible:bg-purple-500',
    [IconTagColor.BLUE1]: 'hover:bg-blue1-400 focus-visible:bg-blue1-500',
    [IconTagColor.BLUE2]: 'hover:bg-blue2-400 focus-visible:bg-blue2-500',
    [IconTagColor.DEFAULT_LIGHT]: 'hover:bg-primary-50 focus-visible:bg-primary-100',
    [IconTagColor.GREY_LIGHT]: 'hover:bg-grey-50 focus-visible:bg-grey-100',
    [IconTagColor.GREEN_LIGHT]: 'hover:bg-green-50 focus-visible:bg-green-100',
    [IconTagColor.RED_LIGHT]: 'hover:bg-red-50 focus-visible:bg-red-100',
    [IconTagColor.ORANGE_LIGHT]: 'hover:bg-orange-50 focus-visible:bg-orange-100',
    [IconTagColor.YELLOW_LIGHT]: 'hover:bg-yellow-50 focus-visible:bg-yellow-100',
    [IconTagColor.PURPLE_LIGHT]: 'hover:bg-purple-50 focus-visible:bg-purple-100',
    [IconTagColor.BLUE1_LIGHT]: 'hover:bg-blue1-50 focus-visible:bg-blue1-100',
    [IconTagColor.BLUE2_LIGHT]: 'hover:bg-blue2-50 focus-visible:bg-blue2-100',
  },
  [IconTagVariant.OUTLINED]: {
    [IconTagColor.DEFAULT]: 'hover:[&:not(:focus-visible)]:bg-primary-400',
    [IconTagColor.GREY]: 'hover:[&:not(:focus-visible)]:bg-grey-400',
    [IconTagColor.GREEN]: 'hover:[&:not(:focus-visible)]:bg-green-400',
    [IconTagColor.RED]: 'hover:[&:not(:focus-visible)]:bg-red-400',
    [IconTagColor.ORANGE]: 'hover:[&:not(:focus-visible)]:bg-orange-400',
    [IconTagColor.YELLOW]: 'hover:[&:not(:focus-visible)]:bg-yellow-400',
    [IconTagColor.PURPLE]: 'hover:[&:not(:focus-visible)]:bg-purple-400',
    [IconTagColor.BLUE1]: 'hover:[&:not(:focus-visible)]:bg-blue1-400',
    [IconTagColor.BLUE2]: 'hover:[&:not(:focus-visible)]:bg-blue2-400',
    [IconTagColor.DEFAULT_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-primary-50',
    [IconTagColor.GREY_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-grey-50',
    [IconTagColor.GREEN_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-green-50',
    [IconTagColor.RED_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-red-50',
    [IconTagColor.ORANGE_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-orange-50',
    [IconTagColor.YELLOW_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-yellow-50',
    [IconTagColor.PURPLE_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-purple-50',
    [IconTagColor.BLUE1_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-blue1-50',
    [IconTagColor.BLUE2_LIGHT]: 'hover:[&:not(:focus-visible)]:bg-blue2-50',
  },
};

export const IconTag: FC<IconTagProps> = ({
  actionIcon,
  onActionIconClick,
  text = '',
  icon = null,
  variant = IconTagVariant.FILLED,
  size = IconTagSize.MD,
  disabled = false,
  color = IconTagColor.DEFAULT,
  testId = undefined,
}) => {
  const styles = {
    component: clsx('gap-x-[8px] select-none w-fit flex justify-between items-center font-quicksand rounded-[4px]', {
      [`${colorStyles[variant][color]} focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500`]:
        !disabled,
      'border-[2px] border-solid border-transparent': !disabled && variant === IconTagVariant.FILLED,
      'border-[2px] border-solid': !disabled && variant === IconTagVariant.OUTLINED,
      'text-grey-500 bg-grey-100 cursor-default': disabled,
      'h-[32px] text-sm px-[6px]': size === IconTagSize.MD,
      'h-[24px] text-xs px-[2px]': size === IconTagSize.SM,
    }),
    actionIconWrapper: clsx('flex items-center', {
      'cursor-default': disabled,
      [`${iconBackgroundStyles[variant][color]} group focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500 cursor-pointer rounded-[4px] -ml-[1px]`]:
        !disabled,
    }),
    actionIcon: clsx({
      'group-hover:filter-grey-0 group-focus-visible:filter-primary-500':
        !disabled && color === IconTagColor.DEFAULT && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-grey-500':
        !disabled && color === IconTagColor.GREY && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-green-500':
        !disabled && color === IconTagColor.GREEN && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-red-500':
        !disabled && color === IconTagColor.RED && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-orange-500':
        !disabled && color === IconTagColor.ORANGE && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-yellow-500':
        !disabled && color === IconTagColor.YELLOW && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-purple-500':
        !disabled && color === IconTagColor.PURPLE && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-blue1-500':
        !disabled && color === IconTagColor.BLUE1 && variant === IconTagVariant.OUTLINED,
      'group-hover:filter-grey-0 group-focus-visible:filter-blue2-500':
        !disabled && color === IconTagColor.BLUE2 && variant === IconTagVariant.OUTLINED,
    }),
    icon: clsx({
      'filter-grey-0':
        !disabled &&
        [
          IconTagColor.DEFAULT,
          IconTagColor.GREY,
          IconTagColor.GREEN,
          IconTagColor.RED,
          IconTagColor.ORANGE,
          IconTagColor.YELLOW,
          IconTagColor.PURPLE,
          IconTagColor.BLUE1,
          IconTagColor.BLUE2,
        ].includes(color) &&
        variant === IconTagVariant.FILLED,
      'filter-primary-800': !disabled && color === IconTagColor.DEFAULT_LIGHT && variant === IconTagVariant.FILLED,
      'filter-grey-800': !disabled && color === IconTagColor.GREY_LIGHT && variant === IconTagVariant.FILLED,
      'filter-green-800': !disabled && color === IconTagColor.GREEN_LIGHT && variant === IconTagVariant.FILLED,
      'filter-red-800': !disabled && color === IconTagColor.RED_LIGHT && variant === IconTagVariant.FILLED,
      'filter-orange-800': !disabled && color === IconTagColor.ORANGE_LIGHT && variant === IconTagVariant.FILLED,
      'filter-yellow-800': !disabled && color === IconTagColor.YELLOW_LIGHT && variant === IconTagVariant.FILLED,
      'filter-purple-800': !disabled && color === IconTagColor.PURPLE_LIGHT && variant === IconTagVariant.FILLED,
      'filter-blue1-800': !disabled && color === IconTagColor.BLUE1_LIGHT && variant === IconTagVariant.FILLED,
      'filter-blue2-800': !disabled && color === IconTagColor.BLUE2_LIGHT && variant === IconTagVariant.FILLED,

      'filter-primary-500': !disabled && color === IconTagColor.DEFAULT && variant === IconTagVariant.OUTLINED,
      'filter-grey-500': disabled || (!disabled && color === IconTagColor.GREY && variant === IconTagVariant.OUTLINED),
      'filter-green-500': !disabled && color === IconTagColor.GREEN && variant === IconTagVariant.OUTLINED,
      'filter-red-500': !disabled && color === IconTagColor.RED && variant === IconTagVariant.OUTLINED,
      'filter-orange-500': !disabled && color === IconTagColor.ORANGE && variant === IconTagVariant.OUTLINED,
      'filter-yellow-500': !disabled && color === IconTagColor.YELLOW && variant === IconTagVariant.OUTLINED,
      'filter-purple-500': !disabled && color === IconTagColor.PURPLE && variant === IconTagVariant.OUTLINED,
      'filter-blue1-500': !disabled && color === IconTagColor.BLUE1 && variant === IconTagVariant.OUTLINED,
      'filter-blue2-500': !disabled && color === IconTagColor.BLUE2 && variant === IconTagVariant.OUTLINED,

      'filter-primary-600': !disabled && color === IconTagColor.DEFAULT_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-grey-600': !disabled && color === IconTagColor.GREY_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-green-600': !disabled && color === IconTagColor.GREEN_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-red-600': !disabled && color === IconTagColor.RED_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-orange-600': !disabled && color === IconTagColor.ORANGE_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-yellow-600': !disabled && color === IconTagColor.YELLOW_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-purple-600': !disabled && color === IconTagColor.PURPLE_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-blue1-600': !disabled && color === IconTagColor.BLUE1_LIGHT && variant === IconTagVariant.OUTLINED,
      'filter-blue2-600': !disabled && color === IconTagColor.BLUE2_LIGHT && variant === IconTagVariant.OUTLINED,
    }),
  };

  return (
    <div className={styles.component} data-testid={testId} data-test-element="tag-with-icon">
      {icon && <Icon className={styles.icon} svg={icon} size={IconSize.SM} variant={IconVariant.CONTOUR} />}
      <div>{text}</div>
      {actionIcon && (
        <span
          className={styles.actionIconWrapper}
          role="button"
          tabIndex={0}
          onClick={e => {
            if (!disabled) {
              e.stopPropagation();
              onActionIconClick();
            }
          }}
          onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
            if (!disabled && event.key === 'Enter') {
              onActionIconClick();
            }
          }}
        >
          <Icon
            className={clsx(styles.icon, styles.actionIcon)}
            svg={actionIcon}
            size={IconSize.SM}
            variant={IconVariant.CONTOUR}
          />
        </span>
      )}
    </div>
  );
};
