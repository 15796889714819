import {observer} from 'mobx-react-lite';
import {InvoicePreviewComponent} from '../../../../../earchive/components/InvoicePreview/InvoicePreviewComponent';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {useWindowSize} from 'usehooks-ts';
import clsx from 'clsx';
import {useEffect} from 'react';

export interface PostingDetailsProps {
  state: IPostingDetailsState;
}

export const PostingDetailsBodyInvPreview = observer(({state}: PostingDetailsProps) => {
  const {height} = useWindowSize();

  useEffect(() => {
    if (!state.postingDetailsStore.postingDetails || state.decreeId !== state.postingDetailsStore.postingDetails?.Id) {
      return;
    }
    state.invoicePreviewRepository?.setCurrentInvoiceId?.(state.postingDetailsStore.postingDetails.InvoiceDocumentId, {disableSaveToStorage: true});
    state.invoicePreviewRepository?.setEnableInvoicePreview(true);
    return () => {
      state.invoicePreviewRepository?.setEnableInvoicePreview(false);
      state.invoicePreviewRepository?.reset();
      state.invoicePreviewRepository?.setCurrentInvoiceId(null);
    };
  }, [state.decreeId, state.postingDetailsStore.postingDetails, state.invoicePreviewRepository]);

  if (state.invoicePreviewRepository === null) {
    return null;
  }

  return <>
    {state.invoicePreviewRepository?.currentInvoiceId &&
      <InvoicePreviewComponent
        className={clsx('w-full h-160 pb-3', {
          'lg:h-full': height > 780,
        })}
        invoicePreviewRepository={state.invoicePreviewRepository}
        withButtons={false}
        invoices={[]}
      />
    }
  </>;
});
