import {FC} from 'react';
import {ButtonWithIcon, TButton} from '../ButtonWithIcon/ButtonWithIcon';

export interface IButtonMenu {
  buttons: TButton[],
  className?: string | undefined;
}

export const ButtonMenu: FC<IButtonMenu> = ({
                                              buttons,
                                              className,
                                            }) => {
  return (
    <div className={className}>
      {buttons.map(button => <ButtonWithIcon {...button}/>)}
    </div>
  );
};
