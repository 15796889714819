import React from 'react';
import {withFileUploader} from './DropzoneUploaderModule/DropzoneContainer';
import {UploadXMLFilesList} from '../UploadXML/UploadXMLFilesList';
import {uploadInvoicesService} from '../../../../state/rootRepository';
import {UploaderSkeleton} from './UploaderSkeleton';
import {invoicesUploaderInitialConfig} from './invoicesUploaderInitialConfig';
import {UploadXMLFile} from '../UploadXML/UploadXMLFile';

export const InvoicesUploader = withFileUploader(({
                                                    inputProps,
                                                    dropzoneProps,
                                                    stateService,
                                                    handleRemove,
                                                  }) => {
  const {disabled, loaded} = stateService;
  const {jobRunner: {loading}} = uploadInvoicesService;
  return <UploaderSkeleton loading={!loaded}>
    <UploadXMLFile
      dropzoneProps={dropzoneProps}
      inputProps={inputProps}
      loading={loading}
      disabled={disabled}
    />
    <UploadXMLFilesList
      handleRemove={handleRemove}
      state={stateService}
      {...dropzoneProps}
      {...inputProps}
    />
  </UploaderSkeleton>;
}, invoicesUploaderInitialConfig);
