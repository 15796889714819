import {FC} from 'react';
import {useIntl} from 'react-intl';
import {IChangeCategoryModalState} from './state/IChangeCategoryModalState';
import {CategorySelect} from './components/CategorySelect';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx';
import {Modal} from '@symfonia/brandbook';

export interface ChangeCategoryModalProps {
  state: IChangeCategoryModalState;
  onClose(): void;
  onFinished(selectedCategory: string): void;
}

export const ChangeCategoryModal: FC<ChangeCategoryModalProps> = observer(({state, onClose, onFinished}) => {
  const intl = useIntl();

  const handleClose = () => {
    state.handleClose();
    onClose();
  };

  return (
    <Modal
      cancelButton={{onClick: handleClose, text: intl.formatMessage({id: Tr.cancelButton})}}
      okButton={{onClick: () => onFinished(state.selectedCategoryId), text: intl.formatMessage({id: Tr.use})}}
      onClose={handleClose}
      title={intl.formatMessage({id: Tr.changeCategory})}
      show={state.isOpen}
    >
      <CategorySelect
        categories={toJS(state.categories)}
        selectedId={state.selectedCategoryId}
        positionName={state.positionName}
        onSelect={value => state.onSelect(value)}
      />
    </Modal>
  );
});
