import {action, computed, makeObservable, observable} from 'mobx';
import {
  AccountResponse,
  AccountSettlementsChangesInput,
  SaveSettlementsDocument,
  SaveSettlementsMutation,
  SaveSettlementsMutationVariables,
  SettlementResponse,
  SettlementTypeEnum,
} from '@symfonia-ksef/graphql';
import {ISettlementsState} from './ISettlementsState';
import {apolloClient} from '../../../../../../../../root/providers/GraphQLProvider';
import {IPostingConfigurationState} from '../../IPostingConfigurationState';
import {ApolloError} from '@apollo/client';
import {Tr} from '@symfonia-ksef/locales/keys';
import {convertToTreeViewDropdownModel} from '../../../../../../../../common/helpers/baseFilterHelpers';
import {TreeViewDropdownModel} from '../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {IPostingAccountModalState} from '../../../../modals/state/IPostingAccountModalState';
import {PostingAccountModalState} from '../../../../modals/state/PostingAccountModalState';
import {addAlert} from '../../../../../../../../../services/helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';


export class SettlementsState implements ISettlementsState {

  @observable
  settlements: SettlementResponse[] = [];

  @observable
  ddlAccountsVAT: TreeViewDropdownModel[] = [];

  @observable
  ddlAccountsMa: TreeViewDropdownModel[] = [];

  @observable
  accountSettlementChangedArray: AccountSettlementsChangesInput[] = [];

  @observable
  canBeSaved: boolean = false;

  @observable
  shouldRefresh: boolean = false;

  @observable
  isLoading: boolean = false;

  @observable
  settlementType: SettlementTypeEnum = SettlementTypeEnum.Undefined;

  @observable
  isModified: boolean = false;

  public postingConfigurationState: IPostingConfigurationState;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.postingConfigurationState = postingConfigurationState;
  }

  private _postingAccountModalState: IPostingAccountModalState | undefined;

  public get postingAccountModalState(): IPostingAccountModalState {
    if (this._postingAccountModalState === undefined) {
      this._postingAccountModalState = new PostingAccountModalState();
    }
    return this._postingAccountModalState;
  }

  @computed
  public get isEditable(): boolean {
    return !this.isLoading && this.settlementType === SettlementTypeEnum.Vat
      ? this.ddlAccountsVAT.length > 0
      : this.ddlAccountsMa.length > 0;
  }

  get checkIfAccountsAreEmptyOnPurchase() {
    const requiredSettlement = this.settlements.find(x => x.IsRequired === true);
    const changed = this.accountSettlementChangedArray.find(x => x.SettlementId === requiredSettlement?.Id);

    if (changed !== undefined && changed?.AccountId === undefined) {
      return false;
    }
    return requiredSettlement?.AccountId !== undefined || (changed !== undefined && changed?.AccountId !== undefined);
  }

  @action setSettlements(settlements: SettlementResponse[]) {
    this.settlements = settlements;
  }

  @action updateAccountSettlementChangedArray(settlementId: string, accountId: string) {
    const request = {
      SettlementId: settlementId,
      AccountId: accountId,
    } as AccountSettlementsChangesInput;

    const settlementIndex = this.accountSettlementChangedArray.findIndex(e => e.SettlementId === settlementId);
    if (settlementIndex === -1) {
      this.accountSettlementChangedArray.push(request);
    } else {
      this.accountSettlementChangedArray.splice(settlementIndex, 1, request);
    }

    this.setIsModified(true);
  }

  @action setCanBeSaved() {
    const nationalRecipentSettlement = this.settlements.find(x => x.SettlementType === SettlementTypeEnum.DomesticRecipient);
    const nationalSupplierSettlement = this.settlements.find(x => x.SettlementType === SettlementTypeEnum.DomesticSupplier);

    const isAccountSettlementChanged = this.accountSettlementChangedArray.length > 0;

    if (nationalRecipentSettlement !== undefined) {
      const isNationalRecipientSettlementSet = nationalRecipentSettlement.AccountId !== null;
      const isNationalRecipentSettlementChanged = this.accountSettlementChangedArray.find(x => x.SettlementId === nationalRecipentSettlement.Id) !== undefined;
      this.canBeSaved = isAccountSettlementChanged && (isNationalRecipientSettlementSet || isNationalRecipentSettlementChanged);
    } else if (nationalSupplierSettlement !== undefined) {
      const isNationalSupplierSettlementSet = nationalSupplierSettlement.AccountId !== null;
      const isNationalSupplierSettlementChanged = this.accountSettlementChangedArray.find(x => x.SettlementId === nationalSupplierSettlement.Id) !== undefined;
      this.canBeSaved = isAccountSettlementChanged && (isNationalSupplierSettlementSet || isNationalSupplierSettlementChanged);
    }
  }

  @action setDdlAccountsVAT(ddlAccounts: AccountResponse[]) {
    this.ddlAccountsVAT = convertToTreeViewDropdownModel(
      ddlAccounts,
      el => ({
        value: el?.Number + ' ' + el?.Name,
        key: el?.Id,
        isSelectable: el.IsFinal && !el.IsDisabled,
        isDisabled: el.IsDisabled,
        isHidden: false,
        parentId: el?.ParentId,
        childrenIds: el?.ChildrenIds,
      }));
  }

  @action setDdlAccountsMa(ddlAccounts: AccountResponse[]) {
    this.ddlAccountsMa = convertToTreeViewDropdownModel(
      ddlAccounts,
      el => ({
        value: el?.Number + ' ' + el?.Name,
        key: el?.Id,
        isSelectable: el.IsFinal && !el.IsDisabled,
        isDisabled: el.IsDisabled,
        isHidden: false,
        parentId: el?.ParentId,
        childrenIds: el?.ChildrenIds,
      }));
  }

  @action clearAccounts() {
    this.setDdlAccountsMa([]);
    this.setDdlAccountsVAT([]);
  }

  @action setShouldRefresh() {
    this.shouldRefresh = !this.shouldRefresh;
  }

  @action setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action setSettlementType(settlementType: SettlementTypeEnum) {
    this.settlementType = settlementType;
  }

  @action resetState() {
    this.accountSettlementChangedArray = [];

    this.setCanBeSaved();
    this.setShouldRefresh();

    this.setIsModified(false);
  }

  @action
  async saveSettlements(): Promise<void> {

    this.setIsLoading(true);

    await apolloClient.mutate<SaveSettlementsMutation, SaveSettlementsMutationVariables>({
      mutation: SaveSettlementsDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        CompanyId: this.postingConfigurationState.companyId,
        AccountSettlementsChanges: this.accountSettlementChangedArray,
        FinancialYearId: this.postingConfigurationState.selectedFinancialYear,
        InvoiceTypeGroup: this.postingConfigurationState.invoiceTypeGroup,
      },
    })
      .then((res: any) => {
        if (!res?.data?.SaveSettlements.IsDone) {
          this.displayErrorAlert();
        }
        this.displaySuccessAlert();
      })
      .then(() => {
        this.applyChangesOnSettlements();
        this.resetState();
        this.postingConfigurationState.setForceRefreshFinancialYear(true);
      })
      .catch((error: ApolloError) => {
        this.displayErrorAlert(error);
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  @action
  public openPostingAccountModal(accountId: string, nodes: TreeViewDropdownModel[]): void {
    this.postingAccountModalState.handleOpen();
    this.postingAccountModalState.searchTreeViewState.setNodes(nodes);
    this.postingAccountModalState.searchTreeViewState.setInitialSelected(accountId);
  }

  @action
  public onPostingAccountModalClose(): void {
    this.postingAccountModalState.handleClose();
    this._postingAccountModalState = undefined;
  }

  @action
  public setIsModified(isModified: boolean): void {
    this.isModified = isModified;
  }

  private displayErrorAlert(error?: ApolloError) {
    addAlert({id: Tr.settlementsSaveFailed, color: ToastVariant.ERROR, duration: 10000});
  }

  private displaySuccessAlert() {
    addAlert({id: Tr.saveAutoPostingSettings, color: ToastVariant.SUCCESS, duration: 10000});
  }

  private applyChangesOnSettlements() {
    this.accountSettlementChangedArray.forEach(changed => {
      const settlementToChange = this.settlements.find(x => x.Id === changed.SettlementId);
      if (settlementToChange === undefined) {
        throw new Error('Settlement do not exist');
      }

      settlementToChange.AccountId = changed.AccountId;
    });
  }
}

