import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {IApplicationInsights} from '@microsoft/applicationinsights-web';
import {createContext} from 'react';

export interface IAppInsightsContext {
  client: IApplicationInsights | undefined;
  plugin: ReactPlugin | undefined;
}

const initialContextState: IAppInsightsContext = {
  client: undefined,
  plugin: undefined
};

export const AppInsightsContext = createContext<IAppInsightsContext>(initialContextState);
