import {action, autorun, computed, IObservableArray, makeObservable, observable, reaction} from 'mobx';
import {IPostingDetailsState} from '../../../state/IPostingDetailsState';
import {IPostingDetailsBodyListState} from './IPostingDetailsBodyListState';
import {PostingDetailsListItemModel} from '../models/PostingDetailListItemModel';
import {PostingDetailListHelper} from '../helpers/PostingDetailListHelper';
import {PostingDetailListPositionModel} from '../models/PostingDetailListItemPositionModel';
import {AccountTypeEnum, CompanyVehiclesResponse, DecreeLineTypeEnum} from '@symfonia-ksef/graphql';
import _ from 'lodash';
import {ICreateCategoryModalState} from '../../../modals/state/ICreateCategoryModalState';
import {CreateCategoryModalState} from '../../../modals/state/CreateCategoryModalState';
import {CreateCategoryStepsDefinition} from '../../../modals/components/steps/CreateCategoryStepsDefinition';
import {IChangeCategoryModalState} from '../../../modals/state/IChangeCategoryModalState';
import {ChangeCategoryModalState} from '../../../modals/state/ChangeCategoryModalState';
import {VehicleModalState} from './VehicleModalState';
import {IPostingAccountModalState} from '../../../../../../earchive/pages/Account/Posting/modals/state/IPostingAccountModalState';
import {PostingAccountModalState} from '../../../../../../earchive/pages/Account/Posting/modals/state/PostingAccountModalState';
import {MathHelpers} from '../../../../../../common/helpers/MathHelpers';

export class PostingDetailsBodyListState implements IPostingDetailsBodyListState {
  @observable
  public rows: IObservableArray<PostingDetailsListItemModel> = observable([]);

  @observable
  public openRow: IObservableArray<number> = observable([]);

  @observable
  public selectedRow: PostingDetailsListItemModel | PostingDetailListPositionModel | undefined = undefined;

  @observable
  public selectedMenuAnchorEl: HTMLElement | undefined = undefined;

  @observable
  public selectedCategoryMenuOpened = false;

  @observable
  public selectedAccountMenuOpened = false;

  @observable
  public isAccountChangeAvailable = false;

  @observable
  public isMaValueModified = false;

  @observable
  public isWnValueModified = false;

  @observable
  public changeCategoryModalState: IChangeCategoryModalState | undefined;

  @observable
  public createCategoryModalState: ICreateCategoryModalState | undefined;

  @observable
  selectedVehicle: CompanyVehiclesResponse | undefined;

  @observable
  shouldRefresh = false;

  @observable
  accountType = AccountTypeEnum.Kup;

  private isRowOpened: boolean = false;
  private isPositionOpened: boolean = false;

  public postingDetailsState: IPostingDetailsState;
  public vehicleModalState: VehicleModalState;
  public postingAccountModalState: IPostingAccountModalState | undefined;

  constructor(postingDetailsState: IPostingDetailsState) {
    makeObservable(this);

    this.postingDetailsState = postingDetailsState;
    this.vehicleModalState = new VehicleModalState(this);

    autorun(() =>
      this.postingDetailsState.postingDetailsStore.postingDetails !== undefined
        ? this.setRows(
            PostingDetailListHelper.mapDecreeLineToModel(
              this.postingDetailsState.postingDetailsStore.postingDetails.DecreeLines,
              this.postingDetailsState.postingDetailsStore.postingDetails.InvoiceTypeGroup,
            ),
          )
        : null,
    );
  }

  @action
  setShouldRefresh(p: boolean) {
    this.shouldRefresh = p;
  }

  @computed
  public get sumWn(): number {
    return _.sum(this.rows?.map(x => x.wn));
  }

  @computed
  public get sumMa(): number {
    return _.sum(this.rows?.map(x => x.ma));
  }

  @computed
  public get isSumValid() {
    return MathHelpers.areNumbersEqualToDecimalPlace(this.sumWn, this.sumMa, 2);
  }

  @computed
  public get canAccountTypeBeChangedForRow(): boolean {
    if (this.selectedRow !== undefined) {
      const row = this.selectedRow as PostingDetailListPositionModel;
      return (
        (row.accountType === AccountTypeEnum.Kup || row.accountType === AccountTypeEnum.Nkup) &&
        row.canChangeAccountType
      );
    }
    return false;
  }

  @computed
  public get canCategoryBeChangedForRow(): boolean {
    if (this.selectedRow !== undefined) {
      const row = this.selectedRow as PostingDetailListPositionModel;
      return !!row.categoryId;
    }
    return false;
  }

  @action
  public setRows(rows: PostingDetailsListItemModel[]) {
    this.rows.replace(rows);
  }

  @action
  public setOpenRow(openRow: number[]) {
    this.openRow.replace(openRow);
  }

  @action
  public setIsWnValueModified(isWnValueModified: boolean) {
    this.isWnValueModified = isWnValueModified;
  }

  @action
  public setIsMaValueModified(isMaValueModified: boolean) {
    this.isMaValueModified = isMaValueModified;
  }

  @action
  public setAccountType(type: AccountTypeEnum) {
    this.accountType = type;
    this.postingAccountModalState?.searchTreeViewState.setNodes([]);
    this.postingDetailsState.postingDetailsStore.fetchAccounts(this.accountType);
  }

  @computed
  public get canEditVehicle(): boolean {
    const row = this.selectedRow as PostingDetailListPositionModel;
    return !!row?.vehicleId && row?.canEditVehicles;
  }

  @computed
  public get canAddVehicle(): boolean {
    const row = this.selectedRow as PostingDetailListPositionModel;
    return !row?.vehicleId && row?.canEditVehicles;
  }

  @action
  public onPositionValueChange(rowId: string, positionId: string, value: number) {
    const row = this.rows?.find(r => r.id === rowId);
    if (row) {
      const position = row?.positions.find(p => p.id === positionId);
      if (position) {
        position.value = value;
        if (!this.postingDetailsState.isSalesInvoice) {
          row.wn = _.sum(row.positions.map(x => x.value));
          this.setIsWnValueModified(true);
        } else {
          row.ma = _.sum(row.positions.map(x => x.value));
          this.setIsMaValueModified(true);
        }

        this.postingDetailsState.updatePositionValueChangeArray(positionId, value);
        return;
      }
    }
    throw new Error('Cannot find position');
  }

  @action
  public onDecreeLineValueChange(decreeLineId: string, value: number) {
    const row = this.rows?.find(r => r.id === decreeLineId);
    if (row) {
      if (row.decreeLineType === DecreeLineTypeEnum.Tax) {
        if (!this.postingDetailsState.isSalesInvoice) {
          row.wn = value;
          this.setIsWnValueModified(true);
        } else {
          row.ma = value;
          this.setIsMaValueModified(true);
        }
      }

      if (row.decreeLineType === DecreeLineTypeEnum.DocumentRelated) {
        if (!this.postingDetailsState.isSalesInvoice) {
          row.ma = value;
          this.setIsMaValueModified(true);
        } else {
          row.wn = value;
          this.setIsWnValueModified(true);
        }
      }

      this.postingDetailsState.updateDecreeLineValueChangeArray(decreeLineId, value);
      return;
    }
    throw new Error('Cannot find position');
  }

  @action
  public changeAccountType() {
    if (this.isRowOpened) {
      const row = this.selectedRow as PostingDetailsListItemModel;
      row.positions.forEach(position => {
        this.postingDetailsState.updatePositionAccountChangeArray(
          position.invoicePositionId,
          row.accountType === AccountTypeEnum.Kup ? AccountTypeEnum.Nkup : AccountTypeEnum.Kup,
        );
      });
    }

    if (this.isPositionOpened) {
      const position = this.selectedRow as PostingDetailListPositionModel;
      this.postingDetailsState.updatePositionAccountChangeArray(
        position.invoicePositionId,
        position.accountType === AccountTypeEnum.Kup ? AccountTypeEnum.Nkup : AccountTypeEnum.Kup,
      );
    }
    this.closeAccountSingleActionsMenu();
  }

  @action
  public changeAccount() {
    if (this.isRowOpened) {
      const row = this.selectedRow as PostingDetailsListItemModel;
      row.positions.forEach(position => {
        this.postingDetailsState.updatePositionAccountChangeArray(
          position.invoicePositionId,
          row.accountType === AccountTypeEnum.Kup ? AccountTypeEnum.Nkup : AccountTypeEnum.Kup,
        );
      });
    }

    if (this.isPositionOpened) {
      const position = this.selectedRow as PostingDetailListPositionModel;
      this.postingDetailsState.updatePositionAccountChangeArray(
        position.invoicePositionId,
        position.accountType === AccountTypeEnum.Kup ? AccountTypeEnum.Nkup : AccountTypeEnum.Kup,
      );
    }
    this.closeAccountSingleActionsMenu();
  }

  @action
  public openSingleRowCategoryActionsMenu(row: PostingDetailsListItemModel, anchorRef?: HTMLElement) {
    this.selectedMenuAnchorEl = anchorRef;
    this.selectedCategoryMenuOpened = true;
    this.isRowOpened = true;
    this.selectedRow = row;
  }

  @action
  public openSinglePositionCategoryActionsMenu(row: PostingDetailListPositionModel, anchorRef?: HTMLElement) {
    this.selectedMenuAnchorEl = anchorRef;
    this.selectedCategoryMenuOpened = true;
    this.isPositionOpened = true;
    this.selectedRow = row;
  }

  @action
  public closeCategorySingleActionsMenu() {
    this.selectedCategoryMenuOpened = false;
    this.selectedMenuAnchorEl = undefined;
    this.isRowOpened = false;
    this.selectedRow = undefined;
    this.isPositionOpened = false;
  }

  @action
  public closeCategorySingleActionsMenuWithoutClearingSelectedRow() {
    this.selectedCategoryMenuOpened = false;
    this.selectedMenuAnchorEl = undefined;
    this.isRowOpened = false;
    this.isPositionOpened = false;
  }

  @action
  public clearSelectedRow() {
    this.selectedRow = undefined;
  }

  @action
  public openSinglePositionAccountActionsMenu(row: PostingDetailListPositionModel, anchorRef?: HTMLElement) {
    this.selectedMenuAnchorEl = anchorRef;
    this.selectedAccountMenuOpened = true;
    this.isAccountChangeAvailable = false;
    this.isPositionOpened = true;
    this.selectedRow = row;
  }

  @action
  public openSingleRowAccountActionsMenu(
    row: PostingDetailsListItemModel,
    anchorRef?: HTMLElement,
    isAccountChangeAvailable?: boolean,
  ) {
    this.selectedMenuAnchorEl = anchorRef;
    this.selectedAccountMenuOpened = true;
    this.isAccountChangeAvailable = isAccountChangeAvailable !== undefined ? isAccountChangeAvailable : true;
    this.isRowOpened = true;
    this.selectedRow = row;
  }

  @action
  public closeAccountSingleActionsMenu() {
    this.selectedAccountMenuOpened = false;
    this.selectedMenuAnchorEl = undefined;
    this.selectedRow = undefined;
    this.isPositionOpened = false;
    this.isRowOpened = false;
    this.isAccountChangeAvailable = false;
  }

  @action
  public openChangeCategoryModal() {
    this.changeCategoryModalState = new ChangeCategoryModalState(this.getExpressionName());
    this.changeCategoryModalState.setSelectedCategoryId(this.selectedRow!.categoryId!);
    this.changeCategoryModalState.handleOpen();

    this.postingDetailsState.postingDetailsStore.fetchCategories().then(() => {
      if (!this.changeCategoryModalState) throw new Error('ChangeCategoryModalState not set');
      const invoiceTypeGroup = this.postingDetailsState.postingDetailsStore.postingDetails?.InvoiceTypeGroup;
      const categories = this.postingDetailsState.postingDetailsStore.categories.filter(
        c => c.InvoiceTypeGroup === invoiceTypeGroup,
      );
      this.changeCategoryModalState.setCategories(categories);
    });
    this.selectedCategoryMenuOpened = false;
  }

  @action
  public onCloseChangeCategoryModal(): void {
    this.changeCategoryModalState = undefined;
    this.closeCategorySingleActionsMenu();
  }

  @action
  public openCreateCategoryModal(): void {
    this.createCategoryModalState = new CreateCategoryModalState(
      this.postingDetailsState.postingState.earchiveState.companyId,
      this.postingDetailsState.postingDetailsStore.postingDetails?.FinancialYearId,
      this.postingDetailsState.postingDetailsStore,
      this.getExpressionName(),
    );

    this.createCategoryModalState.handleOpen();
    this.createCategoryModalState.setSteps(CreateCategoryStepsDefinition(this.createCategoryModalState));

    const invoiceTypeGroup = this.postingDetailsState.postingDetailsStore.postingDetails?.InvoiceTypeGroup;
    if (!invoiceTypeGroup) throw new Error('InvoiceTypeGroup was not set');
    this.createCategoryModalState.setInvoiceTypeGroup(invoiceTypeGroup);

    if (!this.createCategoryModalState) throw new Error('CreateCategoryModalState not set');
    this.createCategoryModalState.setAccountType(this.accountType);

    this.postingDetailsState.postingDetailsStore.fetchCategories();

    this.selectedCategoryMenuOpened = false;
  }

  @action
  public onCloseCreateCategoryModal(): void {
    this.closeCategorySingleActionsMenu();
    this.createCategoryModalState = undefined;
  }

  @action
  public onSaveCreateChangeCategoryModal(newCategoryId: string): void {
    const positionCategoryChanges: {positionId: string; categoryId: string}[] = [];
    if (!this.selectedRow) {
      throw new Error('Cannot save if row is not selected');
    }

    if (this.isRowOpened) {
      (this.selectedRow as PostingDetailsListItemModel).positions.forEach(position => {
        positionCategoryChanges.push({
          positionId: position.invoicePositionId,
          categoryId: newCategoryId,
        });
      });
    }

    if (this.isPositionOpened) {
      positionCategoryChanges.push({
        positionId: (this.selectedRow as PostingDetailListPositionModel).invoicePositionId,
        categoryId: newCategoryId,
      });
    }

    positionCategoryChanges.forEach(change => {
      this.postingDetailsState.updatePositionCategoryChangeArray(change.positionId, change.categoryId);
    });

    this.onCloseCreateCategoryModal();
    this.onCloseChangeCategoryModal();
  }

  private getExpressionName(): string {
    let positionName = '';

    if (this.selectedRow && this.isPositionOpened) {
      positionName = (this.selectedRow as PostingDetailListPositionModel).name;
    }

    if (this.selectedRow && this.isRowOpened) {
      positionName = (this.selectedRow as PostingDetailsListItemModel).positions
        .map(position => position.name)
        .join(', ');
    }

    return positionName;
  }

  @action
  public openEditAccountModalState(): void {
    this.setAccountType(AccountTypeEnum.Kup);
    this.postingAccountModalState = new PostingAccountModalState(this.postingDetailsState.postingDetailsStore);
    this.postingAccountModalState.handleOpen();
    this.postingDetailsState.postingDetailsStore.fetchAccounts(this.accountType);
  }

  @action
  public closeEditAccountModalState(): void {
    this.postingAccountModalState?.handleClose();
    this.postingAccountModalState = undefined;
    this.closeAccountSingleActionsMenu();
  }
}
