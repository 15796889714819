import {action, computed, makeObservable, observable, override} from 'mobx';
import {
  GetPermissionForCompanyDocument,
  GetPermissionForCompanyQuery,
  GetPermissionForCompanyQueryVariables,
  KSeFAuthResult,
} from '@symfonia-ksef/graphql';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {GraphQLError} from 'graphql';
import {dateParser} from '../../modules/common';
import type {CompanyEnvironmentModel} from '../../modules/earchive/models';
import {backgroundActionsService, wsEventsRepository} from '../rootRepository';
import {CompanyState} from '@symfonia-ksef/state/EarchiveState/CompanyState';
import {Repository, RepositoryI} from '../../modules/root/services/MobXServices/Repository/Repository';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

export enum AuthType {
  Token = 'Token',
  Signature = 'Signature'
}

export interface IAuthenticationState extends RepositoryI<'CurrentEnvironment', GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables> {
  result?: string | undefined;
  errorMessage?: string | undefined;
  isUnauthorized?: boolean | undefined;
  authType: AuthType | undefined;
  loadingToken: boolean;
  loadingSignature: boolean;
  errors: readonly GraphQLError[] | undefined;
  company: CompanyEnvironmentModel | undefined;
  expiredToken: boolean | undefined;
  processMessage: string | undefined;

  setProcessMessage(processMessage: string): void;

  setAuthType(authType: AuthType | undefined): void;

  setCompany(company: CompanyEnvironmentModel | undefined | null): void;

  setUnauthorized(): void;

  updateUserAuthAndCredentials(auth: KSeFAuthResult): void;

  clearState(): void;

  setExpiredToken(expired: boolean | undefined): void;

  setAutoUnauthorizedNotificationId(notificationId: string | null): this;

  markBoundNotificationAsRead(): this;

  setLoading(b: boolean): void;
}


export class KSeFAuthenticationState extends Repository<'CurrentEnvironment', GetPermissionForCompanyQuery, GetPermissionForCompanyQueryVariables> implements IAuthenticationState {

  public result?: string | undefined;

  public errorMessage?: string | undefined;

  public isUnauthorized?: boolean | undefined;

  @observable
  public loadingToken: boolean = false;

  @observable
  public loadingSignature: boolean = false;

  @observable
  public errors: ReadonlyArray<GraphQLError> | undefined = undefined;

  public authType: AuthType | undefined = undefined;

  @observable
  public company: CompanyEnvironmentModel | undefined;

  @observable
  public processMessage: string | undefined = undefined;

  @observable
  public expiredToken: boolean | undefined = undefined;

  private autoUnauthorizedNotificationId: string | null = null;

  constructor(private companyState: CompanyState, public earchiveState: EArchiveState) {
    super('CurrentEnvironment', GetPermissionForCompanyDocument, companyState.envObserver, earchiveState);
    makeObservable(this);
  }

  @computed
  get getUser() {
    return this.company?.LastName + ' ' + this.company?.FirstName;
  }


  @override
  public override setLoading(loading: boolean): void {
    super.setLoading(loading);
    if (!this.authType) {
      this.loadingToken = false;
      this.loadingSignature = false;
      return;
    }
    this.authType === AuthType.Signature ? this.loadingSignature = loading : this.loadingToken = loading;
  }

  public setAutoUnauthorizedNotificationId(notificationId: string | null): this {
    this.autoUnauthorizedNotificationId = notificationId;
    return this;
  }

  public markBoundNotificationAsRead(): this {
    this.autoUnauthorizedNotificationId && wsEventsRepository.setMessageRead(this.autoUnauthorizedNotificationId);
    return this;
  }


  @action.bound
  public setAuthType(authType: AuthType | undefined): void {
    this.authType = authType;
  }

  @action.bound
  public setCompany(company: CompanyEnvironmentModel | undefined | null) {
    this.company = company ?? undefined;
  }

  public setUnauthorized() {
    this.setLoading(false);
    this.companyState.updateAuthorizedInKseFData(undefined);
    this.companyState.updateAutoFetchingConfig({
      Status: false,
      LastModifiedBy: this.getUser,
      LastModifiedDate: dateParser(new Date(), true),
      Minutes: undefined,
      Type: undefined,
      ExternalEnabled: false,
      InternalEnabled: false,
      DownloadLimitDate: undefined,
    });
    this.companyState.updateAutoSendingConfig({
      Minutes: undefined,
      Type: undefined,
      Status: false,
      LastModifiedBy: this.getUser,
      LastModifiedDate: dateParser(new Date(), true),
    });
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }

  public async updateUserAuthAndCredentials() {
    await this.fetch();
  }

  @action.bound
  public setProcessMessage(processMessage: string) {
    this.processMessage = processMessage;
  }

  @action.bound
  public setExpiredToken(expired: boolean | undefined) {
    this.expiredToken = expired;
  }

  @action.bound
  public clearState() {
    this.processMessage = undefined;
    this.authType = undefined;
    this.errorMessage = undefined;
    this.isUnauthorized = undefined;
    this.result = undefined;
  }

  protected override onSuccess(data: GetPermissionForCompanyQuery['CurrentEnvironment'], wholeData: GetPermissionForCompanyQuery): void | Promise<void> {
    const date = new Date();
    const parsedDate = dateParser(date, true);
    this.companyState.updateAuthorizedInKseFData(data.UserProperties?.KSeF);
    if (data.UserProperties?.KSeF.Authorized === KSeFAuthResult.NotAuthorized) {
      this.companyState.updateAutoFetchingConfig({
        Status: false,
        ExternalEnabled: false,
        InternalEnabled: false,
        Type: undefined,
        Minutes: undefined,
        LastModifiedBy: 'system',
        LastModifiedDate: parsedDate,
        DownloadLimitDate: undefined,
      });

      this.companyState.updateAutoSendingConfig({
        Type: undefined,
        Minutes: undefined,
        Status: false,
        LastModifiedBy: 'system',
        LastModifiedDate: parsedDate,
      });
      backgroundActionsService.reset();
    }
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    this.companyState.updateAuthorizationInKsef(KSeFAuthResult.NotAuthorized);
    this.companyState.updateAutoFetchingConfig({
      Status: false,
      ExternalEnabled: false,
      InternalEnabled: false,
      Type: undefined,
      Minutes: undefined,
      LastModifiedBy: this.company?.FirstName + ' ' + this.company?.LastName,
      LastModifiedDate: dateParser(new Date(), true),
      DownloadLimitDate: undefined,
    });
    this.companyState.updateAutoSendingConfig({
      Type: undefined,
      Minutes: undefined,
      Status: false,
      LastModifiedBy: this.company?.FirstName + ' ' + this.company?.LastName,
      LastModifiedDate: dateParser(new Date(), true),
    });
  }

  protected override afterFetch(): void {
    this.clearState();
  }
}
