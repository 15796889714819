import {useCallback, useEffect, useState} from 'react';
import {PostingInactivePage} from './pages/PostingInactivePage';
import {InitializationStatusEnum} from '@symfonia-ksef/graphql';
import {useParams} from 'react-router-dom';
import {PostingConfigurationModule} from './pages/PostingConfiguration/PostingConfigurationModule';
import {PostingInitializationPage} from './pages/PostingInitializationPage';
import {PostingActivationErrorPage} from './pages/PostingActivationErrorPage';
import {observer} from 'mobx-react-lite';
import {NotInitialized} from '../../../../common/components/ModuleToggle/NotInitialized/NotInitialized';
import {NotPurchased} from '../../../../common/components/ModuleToggle/NotPurchased/NotPurchased';
import {IPostingInitializationState} from './pages/IPostingInitializationState';
import {ModuleToggle} from '../../../../common/components/ModuleToggle/ModuleToggle';

export interface PostingConfigurationInitializationProps {
  state: IPostingInitializationState;
}

export const PostingConfigurationInitialization = observer(({state}: PostingConfigurationInitializationProps) => {
  const {companyId} = useParams();
  const [skip, setSkip] = useState(false);
  const [isActivated, setIsActivated] = useState<boolean>(false);

  useEffect(() => setSkip(false), [companyId, state.hasFailed]);

  useEffect(() => {
    if (isActivated) setSkip(false);
  }, [isActivated]);

  useEffect(() => {
    const moduleStatus = state.moduleStatus;
    if (moduleStatus) {
      setSkip(true);
      state.setModuleDetails();
    }
  }, [state.moduleStatus]);

  const notInitializedStateHandler = useCallback(() => {
    if (state.initializationStatus! === InitializationStatusEnum.Failed) {
      return <PostingActivationErrorPage state={state}/>;
    }

    if (state.initializationStatus === InitializationStatusEnum.Pending) {
      return <PostingInitializationPage state={state}/>;
    }

    return <PostingInactivePage state={state} setIsActivated={() => setIsActivated(true)}/>;
  }, [state.initializationStatus]);

  const actionHandler = useCallback(() => {
    if (state.moduleStatus) {
      return (
        <ModuleToggle moduleStatus={state.moduleStatus}>
          <PostingConfigurationModule state={state.postingConfigurationState}/>
          <NotInitialized>{notInitializedStateHandler()}</NotInitialized>
          <NotPurchased>
            <div/>
          </NotPurchased>
        </ModuleToggle>
      );
    }

    return <></>;
  }, [skip]);

  return <>{actionHandler()}</>;
});
