import {FC, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import Box from '@mui/material/Box';
import cx from 'classnames';

import {AuthenticationSignature} from './AuthenticationSignature';
import LoadingButton from '@mui/lab/LoadingButton';
import {DisconnectWithKSeFButton} from '../../../components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {authenticationState, removeAuthorizationInKSeFService} from '../../../../../state/rootRepository';

import {observer} from 'mobx-react-lite';
import {AuthType} from '../../../../../state/AuthenticationState/AuthenticationState';

interface DisconnectWithKSeFProps {
  onDisconnectSuccess: () => void;
  disconnectPageStatus: (status: boolean) => void;
}

const DisconnectWithKSeF: FC<DisconnectWithKSeFProps> = observer(({onDisconnectSuccess, disconnectPageStatus}) => {
  const [showAuthenticationsTab, setShowAuthenticationsTab] = useState(false);
  const loading = removeAuthorizationInKSeFService.isPending || authenticationState.loadingSignature;

  const handleDisconectClick = () => {
    setShowAuthenticationsTab(true);
    disconnectPageStatus(true);
  };

  const onSuccessDisconecting = () => {
    onDisconnectSuccess();
  };

  return (
    <>
      {!showAuthenticationsTab && <DisconnectWithKSeFButton handleButtonClick={handleDisconectClick}/>}

      {showAuthenticationsTab && (
        <>
          <Box sx={{display: 'flex', margin: '25px 0'}}>
            {/*TODO: do włączenia po ustaleniach z dostawcą usługi*/}
            {/*<AuthenticationTrustedProfile />*/}
            <AuthenticationSignature
              isAuth={true}
              onAuthorized={async (companyId, szafirService) => {
                authenticationState.setProcessMessage(Tr.pendingAuthorization);
                authenticationState.setAuthType(AuthType.Signature);
                await removeAuthorizationInKSeFService.setSzafirService(szafirService).fetch();
                onSuccessDisconecting();
              }}
            />
          </Box>
          <Box margin="10px 0" minWidth={150}>
            <LoadingButton
              className={cx(['MuiLoadingButton-indicator--no-padding'], {
                'MuiLoadingButton-loading--grey': loading,
              })}
              variant={'outlined'}
              loading={loading}
              onClick={() => {
                setShowAuthenticationsTab(false);
                disconnectPageStatus(false);
              }}
            >
              <FormattedMessage id={Tr.rejectDisconnectWithKSeF}/>
            </LoadingButton>
          </Box>
        </>
      )}
    </>
  );
});

export default DisconnectWithKSeF;
