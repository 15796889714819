import _ from 'lodash';
import {action, autorun, computed, makeObservable, observable, override} from 'mobx';
import {IPostingAccountModalState} from './IPostingAccountModalState';
import {BaseModalState} from '../../../../../../../state/modals/BaseModalState';
import {ISearchTreeViewState} from '../../../../../../auto-posting/components/AccountSelect/state/ISearchTreeViewState';
import {SearchTreeViewState} from '../../../../../../auto-posting/components/AccountSelect/state/SearchTreeViewState';
import {IPostingAccountsSubStore} from '../../../../../../auto-posting/pages/PostingDetails/state/IPostingDetailsStore';

export class PostingAccountModalState extends BaseModalState implements IPostingAccountModalState {
  @computed
  public get canSave(): boolean {
    return this.searchTreeViewState.selectedId !== undefined && this.searchTreeViewState.selectedId !== '';
  }

  @observable
  public searchTreeViewState: ISearchTreeViewState;

  constructor(public dataStore?: IPostingAccountsSubStore) {
    super();
    makeObservable(this);

    autorun(() => {
      if (this.dataStore && this.dataStore.accounts.length) {
        this.searchTreeViewState.setNodes(this.dataStore.accountsAsTreeNodes);
      }
    });

    this.searchTreeViewState = new SearchTreeViewState();
  }

  @action
  public handleSave(): void {
    this.handleClose();
  }

  @override
  public override handleClose(): void {
    super.handleClose();
    this.searchTreeViewState.reset();
  }
}
