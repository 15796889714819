import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {GetInvoicesEventConverter} from './GetInvoicesEventConverter';
import {SendingInvoicesEventConverter} from './SendingInvoicesEventConverter';
import {UpoEventConverter} from './UpoEventConverter';
import {PermissionsEventConverter} from './PermissionsEventConverter';
import {UnauthorizeEventConverter} from './UnauthorizeEventConverter';
import {UploadInvoicesEventConverter} from './UploadInvoicesEventConverter';
import {AuthorizeEventConverter} from './AuthorizeEventConverter';
import {DeleteInvoicesEventConverter} from './DeleteInvoicesEventConverter';
import {OcrEventConverter} from './OcrEventConverter';
import {TerminateSessionEventConverter} from './TerminateSessionEventConverter';
import {earchiveState, uploadInvoicesService} from '@symfonia-ksef/state/rootRepository';
import {ksefEventParserManager} from '@symfonia-ksef/state/KSeFSubscriptionServices/KSeFEventsParsersManager';
import {NotificationDataType} from '../../../../../../services/helpers/NotificationDataParsers';
import {WhiteListValidationEvent} from './WhiteListValidationEventConverter';

export abstract class KSeFEventConverterFactory {
  private static readonly converters: Readonly<Partial<Record<WebsocketNotificationType, (event: WebSocketNotificationFragment) => KSeFEventsConverter>>> = Object.freeze({
    [WebsocketNotificationType.DownloadInvoices]: (event: WebSocketNotificationFragment) => new GetInvoicesEventConverter(WebsocketNotificationType.DownloadInvoices, event),
    [WebsocketNotificationType.AutoFetchingInvoices]: (event: WebSocketNotificationFragment) => new GetInvoicesEventConverter(WebsocketNotificationType.AutoFetchingInvoices, event),
    [WebsocketNotificationType.AutoSendingInvoices]: (event: WebSocketNotificationFragment) => new SendingInvoicesEventConverter(WebsocketNotificationType.AutoSendingInvoices, event),
    [WebsocketNotificationType.SendingInvoices]: (event: WebSocketNotificationFragment) => new SendingInvoicesEventConverter(WebsocketNotificationType.SendingInvoices, event),
    [WebsocketNotificationType.GetUpo]: (event: WebSocketNotificationFragment) => new UpoEventConverter(event),
    [WebsocketNotificationType.GrantPermission]: (event: WebSocketNotificationFragment) => new PermissionsEventConverter(event),
    [WebsocketNotificationType.UnauthorizedInKsef]: (event: WebSocketNotificationFragment) => new UnauthorizeEventConverter(WebsocketNotificationType.UnauthorizedInKsef, event),
    [WebsocketNotificationType.UnauthorizedInKsefExternalToken]: (event: WebSocketNotificationFragment) => new UnauthorizeEventConverter(WebsocketNotificationType.UnauthorizedInKsefExternalToken, event),
    [WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken]: (event: WebSocketNotificationFragment) => new UnauthorizeEventConverter(WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken, event),
    [WebsocketNotificationType.UploadInvoices]: (event: WebSocketNotificationFragment) => new UploadInvoicesEventConverter(event, earchiveState.packageStatistics, uploadInvoicesService),
    [WebsocketNotificationType.AuthorizeInKsef]: (event: WebSocketNotificationFragment) => new AuthorizeEventConverter(WebsocketNotificationType.AuthorizeInKsef, event),
    [WebsocketNotificationType.AuthorizeInKsefExternalToken]: (event: WebSocketNotificationFragment) => new AuthorizeEventConverter(WebsocketNotificationType.AuthorizeInKsefExternalToken, event),
    [WebsocketNotificationType.DeleteInvoices]: (event: WebSocketNotificationFragment) => new DeleteInvoicesEventConverter(event),
    [WebsocketNotificationType.OcrInvoiceImported]: (event: WebSocketNotificationFragment) => new OcrEventConverter(event),
    [WebsocketNotificationType.TerminateSession]: (event: WebSocketNotificationFragment) => new TerminateSessionEventConverter(event),
    [WebsocketNotificationType.WhiteListValidation]: (event: WebSocketNotificationFragment) => new WhiteListValidationEvent(event),
  });

  public static create<T extends WebsocketNotificationType, P = unknown>(event: WebSocketNotificationFragment, config?: { saveAsParser: boolean, cachingDisabled?: boolean, data?: NotificationDataType<T> | undefined }): KSeFEventsConverter<T, P> | null {
    try {
      if (config?.saveAsParser) {
        const parser = ksefEventParserManager.setParser(event, config);
        config?.data && parser.set<T>(config.data);
      }
      const converter = KSeFEventConverterFactory.converters[event.Type]?.(event);
      if (!converter) {
        throw Error(`Converter for Event with ${event.Type} type is not declared`);
      }
      return converter as KSeFEventsConverter<T, P>;
    } catch (err) {
      console.error(err);
    }
    return null;
  }
}


