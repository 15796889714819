import {FC, PropsWithChildren} from 'react';
import {GlobalLoader} from '../common';
import {MyEnvironmentsError} from './components';
import {observer} from 'mobx-react-lite';
import {useModule} from './services/MobXServices/BaseModule';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export const RootEnvironmentsLoader: FC<PropsWithChildren> = observer(({children}) => {
  const environments = useModule(earchiveState.environments);

  if (environments.loading) {
    return <GlobalLoader/>;
  }

  if (environments.error) {
    return <MyEnvironmentsError/>;
  }

  return (
    <>{children}</>
  );

});
