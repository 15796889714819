import {useInvoicesViewButtonsAndFiltersMethods} from '../../../hooks/useInvoicesViewButtonsAndFiltersMethods';
import {GroupedActionTypeEnum} from '../../../models';
import * as React from 'react';
import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IEarchiveStateMobx} from '../../../IEArchiveStateMobx';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InvoicesRow} from '../../../modules/Invoices/InvoicesTable/invoicesTableConfig';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {MultipleActionsBar} from '../../../../common/components/MultipleActionsBar/MultipleActionsBar';
import {useInvoicesGroupActions} from './useInvoicesGroupActions';
import {ConfirmModal} from '@symfonia/symfonia-ksef-components';
import {intl} from '../../../../root/IntlProvider';
import {InvoicesFiltersState} from '../../../modules/Invoices/InvoicesFilters/InvoicesFiltersState';
import {KsefXsdVersion} from '@symfonia-ksef/graphql';


export const GroupInvoiceActionPanel: FC<{
  filtersState: InvoicesFiltersState,
  earchiveStateMobx: IEarchiveStateMobx,
  selectionState: SelectionI<InvoicesRow>,
  invoicesBound: { isExternal: boolean, isInternal: boolean }
}> = observer(({filtersState, earchiveStateMobx, selectionState, invoicesBound}) => {

  const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
  const hasAllInvoicesWithDeprecatedSchemaVersion = selectionState.selected.every(invoice => invoice.SchemaVersion !== KsefXsdVersion.V12648);
  
  const actions = useInvoicesGroupActions({
    selectionState,
    invoicesBound,
    hasAllInvoicesWithDeprecatedSchemaVersion,
    setModalOpen: setDeleteModalIsActive,
    filters: filtersState,
  });

  const {
    onGroupedAction,
  } = useInvoicesViewButtonsAndFiltersMethods(selectionState);

  if (selectionState.selected.length <= 1) {
    return null;
  }

  return <>
    <MultipleActionsBar selectionState={selectionState} actions={actions}/>
    <ConfirmModal
      open={deleteModalIsActive}
      title={intl.formatMessage({id: Tr.deleteInvoicesConfirmationModalTitle})}
      content={intl.formatMessage({id: Tr.deleteInvoiceConfirmationModalContent})}
      confirmButtonName={intl.formatMessage({id: Tr.removeButton})}
      cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
      onCancel={() => setDeleteModalIsActive(false)}
      onConfirm={() => {
        onGroupedAction(GroupedActionTypeEnum.deleteInvoices);
        setDeleteModalIsActive(false);
      }}/>
  </>;
});
