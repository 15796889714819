import {
  RequiresSessionTerminationForUpoDocument,
  RequiresSessionTerminationForUpoQuery,
  RequiresSessionTerminationForUpoQueryVariables,
  WebsocketNotificationStateType,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {Repository} from '../../modules/root/services/MobXServices/Repository/Repository';
import {
  BaseWsActiveEventsRepositoryI,
  WsActiveEventsRepository,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsActiveEventsRepository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export type PendingProcess = { type: WebsocketNotificationType, count: number }

export class KSeFSessionCheckerService extends Repository<'RequiresSessionTerminationForUPO', RequiresSessionTerminationForUpoQuery, RequiresSessionTerminationForUpoQueryVariables> {
  private activeEventsService: BaseWsActiveEventsRepositoryI;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, private ActiveEventsServiceClass: typeof WsActiveEventsRepository = WsActiveEventsRepository) {
    super('RequiresSessionTerminationForUPO', RequiresSessionTerminationForUpoDocument, envObserver, earchiveState);
    makeObservable(this);
    this.activeEventsService = this.createActiveEventsService();
  }

  @computed
  public get invoicesIds(): string[] {
    return this.variables.InvoicesIds ?? [];
  }

  @computed
  public get terminationRequired(): boolean | null {
    if (this.data?.Result === undefined) {
      return null;
    }
    return this.data.Result;
  }

  @computed
  public get pendingProcesses(): PendingProcess[] {
    const processesMap: Partial<Record<WebsocketNotificationType, number>> = {};
    for (const process of this.activeEventsService.pendingEvents) {
      processesMap[process.Type] = (processesMap[process.Type] ?? 0) + 1;
    }
    return Object.entries(processesMap).map(([type, count]) => ({count, type} as PendingProcess));
  }

  public override checkIsReady(): boolean {
    return super.checkIsReady() && !!this.invoicesIds.length;
  }

  public async checkSession(ids: string[]): Promise<{ terminationRequired: boolean, pendingProcesses: PendingProcess[] }> {
    await this.configure({InvoicesIds: ids}).fetch();

    if (this.terminationRequired) {
      await this.activeEventsService.fetch();
      this.set({Result: !!this.pendingProcesses.length});
    }
    return {terminationRequired: !!this.terminationRequired, pendingProcesses: this.pendingProcesses};
  }

  protected beforeFetch(): void {
    this.createActiveEventsService({onlyIfAlreadyInitialized: true, autoSet: true});
  }

  private createActiveEventsService(opt?: { onlyIfAlreadyInitialized?: boolean, autoSet?: boolean }): BaseWsActiveEventsRepositoryI {
    const service = !opt?.onlyIfAlreadyInitialized ? new this.ActiveEventsServiceClass(this.envObserver, this.earchiveState, {autoInitializeDisabled: true}) : this.activeEventsService;
    service.configure({notificationStateType: WebsocketNotificationStateType.Active});
    if (opt?.autoSet) {
      this.activeEventsService = service;
    }
    return service;
  }
}
