import {FetchResult} from '@apollo/client/link/core';
import {MutationBaseOptions} from '@apollo/client/core/watchQueryOptions';
import {ApolloClient, NormalizedCacheObject, TypedDocumentNode} from '@apollo/client';
import {AnyObject} from 'yup/es/types';

export enum InitializerType {
  Query,
  Mutation
}

export const fetchMatchedAction = <K extends string, R extends Record<K, AnyObject>, T extends AnyObject>(graphqlDocument: TypedDocumentNode, envId: string | null, variables: T | null, initializerType: InitializerType, client: ApolloClient<NormalizedCacheObject>, controller: AbortController): Promise<FetchResult<R>> => {

  if (!variables) {
    throw new Error('companyId and userId have to be settled before run job');
  }
  if (envId === null) {
    throw new Error('envId has to be settled before job run');
  }
  const sharedConfig: MutationBaseOptions<R, T> = {
    variables: variables,
    context: {envId, fetchOptions: {signal: controller.signal}},
    errorPolicy: 'all',
  };


  if (initializerType === InitializerType.Query) {
    return client.query<R, T>({
      query: graphqlDocument,
      ...sharedConfig,
    });
  }
  return client.mutate<R, T>({
    mutation: graphqlDocument,
    ...sharedConfig,
  });

};
