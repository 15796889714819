import {FC} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {Icon, IconProps, IconSize, IconSvg, IconVariant} from '../Icon/Icon';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';

export enum ButtonPrimaryVariant {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum ButtonPrimarySize {
  SM = 'SM',
  MD = 'MD',
}

export enum ButtonPrimaryWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export type ButtonPrimaryConfig = {
  variant: ButtonPrimaryVariant;
  size: ButtonPrimarySize;
  width: ButtonPrimaryWidth;
};

export type ButtonPrimaryProps = Partial<ButtonPrimaryConfig> & {
  lIcon?: IconSvg | null;
  rIcon?: IconSvg | null;
  iconOptions?: Omit<IconProps, 'svg'>;
  stickyIcons?: boolean;
  text?: string;
  onClick?: () => unknown;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
} & TestableElement;

type ButtonStyles = {
  button: string;
  icon: string;
  text: string;
};

const getStyles = (
  config: ButtonPrimaryConfig & {disabledOrLoading: boolean; hasText: boolean; stickyIcons: boolean},
): ButtonStyles => {
  const styles = {
    button: clsx(
      'relative before:absolute before:w-[calc(100%+2px)] before:h-[calc(100%+2px)] before:rounded-lg border-[1px] border-transparent',
      {
        'inline-flex whitespace-nowrap justify-center items-center rounded-lg select-none focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-primary-600 before:focus-visible:outline before:focus-visible:outline-[2px] before:focus-visible:outline-white':
          true,
        'w-full': config.hasText && config.width === ButtonPrimaryWidth.FULL,
        'min-w-[200px]': config.hasText && config.width === ButtonPrimaryWidth.BASE,
        'w-fit': config.hasText && config.width === ButtonPrimaryWidth.FIT,
        'px-[16px]': config.hasText,
        'h-[40px]': config.size === ButtonPrimarySize.SM,
        'h-[48px]': config.size === ButtonPrimarySize.MD,
        'min-w-[40px]': config.size === ButtonPrimarySize.SM && config.width !== ButtonPrimaryWidth.BASE,
        'min-w-[48px]': config.size === ButtonPrimarySize.MD && config.width !== ButtonPrimaryWidth.BASE,
        'bg-primary-500 hover:bg-primary-600 focus:bg-primary-500 active:bg-primary-700':
          config.variant === ButtonPrimaryVariant.GREEN && !config.disabledOrLoading,
        'bg-red-500 hover:bg-red-600 focus:bg-red-500 active:bg-red-700':
          config.variant === ButtonPrimaryVariant.RED && !config.disabledOrLoading,
        'bg-grey-300': config.disabledOrLoading,
        'pointer-events-none': config.disabledOrLoading,
      },
    ),
    icon: config.disabledOrLoading ? 'filter-grey-100' : 'filter-grey-0',
    text: clsx([
      'font-quicksand font-bold text-base',
      config.disabledOrLoading ? 'text-grey-100' : 'text-white',
      config.stickyIcons && 'grow',
    ]),
  };

  return styles;
};

export const ButtonPrimary: FC<ButtonPrimaryProps> = ({
  onClick = undefined,
  lIcon = null,
  rIcon = null,
  iconOptions = undefined,
  text = '',
  className = '',
  disabled = false,
  variant = ButtonPrimaryVariant.GREEN,
  size = ButtonPrimarySize.MD,
  width = ButtonPrimaryWidth.FIT,
  stickyIcons = false,
  loading = false,
  testId = undefined,
}) => {
  const disabledOrLoading = disabled || loading;
  const styles = (() => getStyles({variant, size, width, disabledOrLoading, hasText: text.length > 0, stickyIcons}))();
  const iconSize = size === ButtonPrimarySize.MD ? IconSize.LG : IconSize.MD;

  return (
    <button
      type="button"
      onClick={onClick}
      className={twMerge(clsx([styles.button, className]))}
      tabIndex={0}
      disabled={disabledOrLoading}
      data-test-element="button-primary"
      data-testid={testId}
    >
      {(lIcon || (loading && !(!lIcon && rIcon))) && (
        <Icon
          className={clsx({[styles.icon]: true, 'mr-[8px]': text.length > 0, 'animate-spin': loading})}
          svg={(loading ? IconSvg.ROTATE_RIGHT : lIcon) as IconSvg}
          size={iconSize}
          {...iconOptions}
        />
      )}
      {text && <span className={styles.text}>{text}</span>}
      {(rIcon || (loading && !lIcon && rIcon)) && (
        <Icon
          className={clsx({
            [styles.icon]: true,
            'ml-[8px]': text.length > 0,
            'animate-spin': loading && !lIcon && rIcon,
          })}
          svg={loading && !lIcon && rIcon ? IconSvg.ROTATE_RIGHT : rIcon}
          size={iconSize}
          variant={IconVariant.CONTOUR}
          {...iconOptions}
        />
      )}
    </button>
  );
};
