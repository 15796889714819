import {FC} from 'react';
import clsx from 'clsx';
import {CalendarCore, CalendarCoreProps} from './components/CalendarCore';
import {TestableElement} from '../../external/types';

export {type CalendarDateRange} from '../../external/elements/Calendar/Calendar.helper';
export type CalendarProps = Omit<
  CalendarCoreProps,
  | 'showTodayButton'
  | 'showFormerDays'
  | 'showLatterDays'
  | 'onViewChange'
  | 'view'
  | 'onSelectedChange'
  | 'onSelectedEndChange'
  | 'onHoverChange'
  | 'onEditedDateTypeChange'
  | 'updateHoverDate'
  | 'updateEditedDateType'
> &
  TestableElement;

export const Calendar: FC<CalendarProps> = ({className, ...props}) => (
  <CalendarCore
    {...props}
    className={clsx('border-grey-100 drop-shadow-lg border-[1px] rounded-[8px] overflow-hidden', className)}
  />
);
