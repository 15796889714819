import clsx from 'clsx';
import {FC} from 'react';
import {twMerge} from 'tailwind-merge';
import {TestableElement} from '../../external/types';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '../Icon/Icon';

export enum BulletListVariant {
  CHECK = 'CHECK',
  DOT = 'DOT',
}

export type BulletListProps = {
  elements: string[];
  variant?: BulletListVariant;
  className?: string;
  listItemClassName?: string;
} & TestableElement;

export const BulletList: FC<BulletListProps> = ({
  elements = [],
  variant = BulletListVariant.DOT,
  className = undefined,
  listItemClassName = undefined,
  testId = undefined,
}) => {
  const styles = {
    component: clsx(className, {'font-quicksand flex w-fit p-[8px]': true}),
    list: 'list-none',
    listElement: twMerge('flex mb-[16px] last:mb-0 h-[24px] leading-[24px]', listItemClassName),
    iconBox: clsx('mr-[8px]', {
      'w-[6px]': variant === BulletListVariant.DOT,
      'w-[16px]': variant === BulletListVariant.CHECK,
    }),
    icon: clsx({
      '-ml-[9px]': variant === BulletListVariant.DOT,
      '-ml-[4px]': variant === BulletListVariant.CHECK,
    }),
  };

  const iconSvg: IconSvg = variant === BulletListVariant.DOT ? IconSvg.DOT : IconSvg.CHECK;

  return (
    <div className={styles.component} data-test-element="bullet-list" data-testid={testId}>
      <ul className={styles.list}>
        {elements.map(el => (
          <li className={styles.listElement} key={crypto.randomUUID()}>
            <span className={styles.iconBox}>
              <Icon
                className={styles.icon}
                size={IconSize.LG}
                variant={IconVariant.FILLED}
                color={IconColor.PRIMARY_500}
                svg={iconSvg}
              />
            </span>
            <span>{el}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
