import {ExtendedTable} from "../../../../../../../../root/components/ExtendedTable";
import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {SettlementsPageService} from "../SettlementsPage.service";
import {ISettlementsState} from "../State/ISettlementsState";

interface SettlementsTableProps {
  state: ISettlementsState,
  pageService: SettlementsPageService
}

export const SettlementsTable: FC<SettlementsTableProps> = observer(({state, pageService}) => {

  return <ExtendedTable service={pageService.tableService} loaderEnabled className="w-full"/>;
});
