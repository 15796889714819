import {
    GetRecipientInvoicesDocument,
    GetRecipientInvoicesQuery,
    GetRecipientInvoicesQueryVariables,
    RecipientInvoiceFragment,
    OrderDirection,
  } from '@symfonia-ksef/graphql';
  import {InvoicesFiltersState} from './InvoicesFiltersState';
  import {computed, makeObservable} from 'mobx';
  import {BaseTableDataRepositoryService} from '../../../../root/services/TableServices/DataSourceTableService';
  import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
  
  export class RecipientsRepository extends BaseTableDataRepositoryService<'GetRecipientInvoices', GetRecipientInvoicesQuery, GetRecipientInvoicesQueryVariables> {
    constructor(filtersState: InvoicesFiltersState) {
      super('GetRecipientInvoices', GetRecipientInvoicesDocument, envObserver, earchiveState);
  
      this.configure({
        skip: 0,
        take: 0,
        SearchText: '',
        order: {
          Direction: OrderDirection.Asc,
          FieldName: 'RecipientName',
        },
        FiltersFields: {
          Foreign: filtersState.isExternal,
          Own: filtersState.isInternal,
        },
      });
      makeObservable(this);
      this.fetch();
    }
  
    @computed
    public get recipients(): RecipientInvoiceFragment[] {
      return this.data?.Items ?? [];
    }
  }
  