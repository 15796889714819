import {FC} from 'react';
import {TogglableProps, Togglable} from '../../internal/components/Togglable/Togglable';
import {CheckboxAppearance, getAppearanceInputStyles} from './components/CheckboxAppearance';

export {TogglableSize as CheckboxSize} from '../../internal/components/Togglable/Togglable';
export type CheckboxProps = Omit<TogglableProps, 'testElement' | 'appearance' | 'getAppearanceInputStyles' | 'isRadio'>;

export const Checkbox: FC<CheckboxProps> = ({...props}) => (
  <Togglable
    {...props}
    testElement="checkbox"
    isRadio={false}
    appearance={<CheckboxAppearance />}
    getAppearanceInputStyles={getAppearanceInputStyles}
  />
);
