import {useCallback} from 'react';

type Debounced<T extends Array<any> = any[]> = (...args: T) => unknown
export const useDebounce = <T extends Array<any> = any[], P = unknown>(fn: Debounced<T>, delay: number, config?: { ignore?: boolean }): Debounced<T> => {
  return useCallback<Debounced<T>>(debounce(fn, delay, config?.ignore), [fn, delay, config?.ignore]);
};

export const debounce = <T extends Array<any> = any[], P = unknown>(fn: Debounced<T>, delay: number = 300, ignore: boolean = false): Debounced<T> => {
  if (ignore) {
    return fn;
  }
  let timeoutId: NodeJS.Timeout;
  return (...args: Parameters<typeof fn>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
};
