import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {MultiSelectModel} from '../../../../../common/components/MultiSelect/MultiSelect';
import {DropdownWidth, Input, InputWidth} from '@symfonia/brandbook';
import {Dropdown} from '@symfonia/symfonia-ksef-components';
import {convertToMapDropdownListOption} from '../../../../../../modules/common/helpers/baseFilterHelpers';
import {useEffect, useState} from 'react';

export interface CategorySelectProps {
  categories: MultiSelectModel[] | [];
  selectedId: string | undefined;
  positionName: string;
  onSelect(value: MultiSelectModel | null): void;
}

export const CategorySelect = ({categories, selectedId, positionName, onSelect}: CategorySelectProps) => {
  const intl = useIntl();
  const [selectedOptionKey, setSelectedOptionKey] = useState<React.Key[]>([]);

  const categoryIntl = intl.formatMessage({id: Tr.category});
  const expressionIntl = intl.formatMessage({id: Tr.expression});

  const optionsDropdown = convertToMapDropdownListOption(categories, el => ({
    label: el.value,
    value: el.key,
  }));

  const handleSelect = (e: React.Key[]) => {
    setSelectedOptionKey(e);
    const selectedOption = optionsDropdown.get(e[0]);
    const selecedOptionObject = {
      key: selectedOption?.value,
      value: selectedOption?.label,
    };

    onSelect((selecedOptionObject as MultiSelectModel) || null);
  };
  const actualSelectedCategory = () => {
    optionsDropdown.forEach((el, key) => {
      if (el.value === selectedId) {
        setSelectedOptionKey([key]);
      }
    });
  };

  useEffect(() => {
    actualSelectedCategory();
  }, [categories]);

  return (
    <div className="flex flex-col gap-[20px]">
      <div className='max-w-[410px]'>
        <Dropdown
          label={categoryIntl}
          onChange={e => handleSelect(e)}
          options={optionsDropdown}
          placeholder={categoryIntl}
          values={new Set(selectedOptionKey)}
          width={DropdownWidth.FULL}
        />
      </div>
      <div>
        <Input
          disabled
          label={expressionIntl}
          placeholder={expressionIntl}
          value={positionName}
          width={InputWidth.FULL}
        />
      </div>
    </div>
  );
};
