import {observer} from 'mobx-react-lite';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {IPostingDetailsState} from '../state/IPostingDetailsState';
import {ClickAwayListener, Popper} from '@mui/material';
import {ButtonPrimary, ButtonSecondary, IconSvg} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {
  DropdownList,
  DropdownListOption,
} from '@symfonia/symfonia-ksef-components';

export interface PostingDetailsActionsProps {
  state: IPostingDetailsState;
}


export const PostingDetailsActions = observer(({state}: PostingDetailsActionsProps) => {
  const intl = useIntl();
  const currentUserId = earchiveState.company.userId;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const optionsDropdown = ([
    {
      label: intl.formatMessage({id: Tr.saveAndAcceptDecree}),
      value: 0,
    }, {
      label: intl.formatMessage({id: Tr.save}),
      value: 1,
    },
  ]);
  const dropdownOptions = new Map<React.Key, DropdownListOption>([['0', optionsDropdown[0]], ['1', optionsDropdown[1]]]);

  const handleMenuItemClick = (
    e?: React.Key[],
  ) => {
    const key = e === undefined ? state.selectedIndex : e[0];
    state.setSelectedIndex(parseInt(key.toString()));
    key === 1 ? state.saveChangesAndAcceptDecree() : state.saveChanges();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return state.isPageEditableByStatus ?
    <div className="flex w-full justify-end bg-white box-border p-4 px-[28px] z-10 relative">
      <ButtonSecondary
        disabled={!state.isAnyChangeInDetails || state.isSaveInProgress || (!state.canAcceptBeforeSendToFK && state.acceptBeforeSendToFKTurnOnInSettings)}
        onClick={() => state.resetState()}
        text={intl.formatMessage({id: Tr.cancelButton})}
        className={'mr-[10px]'}
      />
      {!state.displayAcceptDecreeButton || (!state.canAcceptOwn && currentUserId === state.postingState.selectedDecree?.CreatedBy) ?
        <ButtonPrimary
          disabled={!state.areChangesValid}
          onClick={() => state.saveChanges()}
          text={intl.formatMessage({id: Tr.save})}
        />
        : <div ref={anchorRef} className={'flex items-center'}>
          <ButtonPrimary
            text={optionsDropdown[state.selectedIndex]?.label}
            onClick={handleMenuItemClick}
            lIcon={state.isSaveInProgress ? IconSvg.LOOP : undefined}
            className={'rounded-r-none'}
            disabled={!state.areChangesValid}
            loading={state.isSaveInProgress}
          />
          <ButtonPrimary
            onClick={handleToggle}
            lIcon={IconSvg.ARROW_DROP_DOWN}
            className={'rounded-l-none ml-[2px]'}
            disabled={!state.areChangesValid}
          />
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={'top-start'}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <DropdownList
                isOpen={open}
                onClose={() => setOpen(false)}
                onChange={(e) => handleMenuItemClick(e)}
                className="mt-[8px]"
                anchorEl={anchorRef}
                selectable={false}
                listWithTooltip={true}
                options={dropdownOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              />
            </ClickAwayListener>
          </Popper>
        </div>}
    </div> : null;
});
