import 'reflect-metadata';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {IntlProvider} from 'react-intl';
import {ThemeProvider} from '@mui/material';

import theme from './theme';
import * as locales from './locales';
import {environment} from './environments/environment';
import App from './app';
import * as buildInfo from '../../../buildInfo/.json';

import {hotjar} from 'react-hotjar';
import {disableHotjar, isLocalDev} from './modules/common/helpers/helperFunctions';
import {MsalProvider} from '@azure/msal-react';
import {authClient} from './modules/auth/authClient';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    appBar: true;
  }
}

if (!isLocalDev) {
  console.info('Build Info:', buildInfo);
  console.info('Info 221001-1');
}

if (!disableHotjar) {
  hotjar.initialize(environment.hotjarHjid, environment.hotjarHjsv);
}

const container = document.getElementById('root');
if (!container) throw new Error('Container not found');
const root = createRoot(container);

root.render(
  <StrictMode>
    <MsalProvider instance={authClient}>
      <ThemeProvider theme={theme}>
        <IntlProvider
          messages={locales.pl}
          locale={environment.defaultLocale}
          defaultLocale={environment.defaultLocale}
        >
          <App/>
        </IntlProvider>
      </ThemeProvider>
    </MsalProvider>
  </StrictMode>,
);
