import {localStorageService} from '../helpers/storage';

const SAVED_ROWS_PER_PAGE = 'symfonia.saved-rows-per-page';

export function getRowsPerPage(): string | undefined {
  return localStorageService.getItem<string>(SAVED_ROWS_PER_PAGE);
}

export function saveRowsPerPage(rowsPerPage: string): void {
  localStorageService.setItem<string>(SAVED_ROWS_PER_PAGE, rowsPerPage);
}

export function removeRowsPerPage() {
  localStorageService.removeItem(SAVED_ROWS_PER_PAGE);
}
