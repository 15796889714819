import {FormattedMessage, useIntl} from 'react-intl';
import React, {FC, useEffect, useState} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DropdownWithLabelModel} from '../../../../../../../common/components/DropdownWithLabel/DropdownWithLabel';
import {convertToDropdownWithLabelModel} from '../../../../../../../common/helpers/baseFilterHelpers';
import {InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {IPostingDocumentTypesState} from '../DocumentTypes/state/IPostingDocumentTypesState';
import {Header} from '../../../../../../../root/components/Header';
import {Breadcrumbs, IconColor, IconSvg, Tabs, TooltipPosition} from '@symfonia/brandbook';
import {IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {FinancialYearSelect} from './FinancialYearSelect';
import {usePageTitle} from '../../../../../../../common';

interface PostingConfigurationHeaderAlternateProps {
  defaultSwitchBtnValue?: string;
  state: IPostingDocumentTypesState;
  requiredModulesPurchase?: boolean;
  requiredModulesSales?: boolean;
}

export const PostingConfigurationHeaderAlternate: FC<PostingConfigurationHeaderAlternateProps> = observer(
  ({defaultSwitchBtnValue, state, requiredModulesPurchase, requiredModulesSales}) => {
    const {setPageTitle} = usePageTitle();
    const intl = useIntl();
    const [accountsOption, setAccountsOption] = useState<DropdownWithLabelModel[] | []>([]);
    const [currentTab, setCurrentTab] = useState(0);

    const zakupoweFaktury = currentTab === 0;
    const handleChangeFinancialYear = (year: string) => {
      state.postingConfigurationState.setSelectedFinancialYear(year);
    };

    const breadcrumbs = [
      {
        label: intl.formatMessage({id: Tr.settings}),
      },
      {
        label: intl.formatMessage({id: Tr.automaticDecrees}),
      },
      {
        label: intl.formatMessage({id: Tr.typesOfDocuments}),
      },
    ];

    const tabs = [
      {text: intl.formatMessage({id: Tr.PURCHASE}), isActive: zakupoweFaktury, onClick: () => handleTabChange(0)},
      {text: intl.formatMessage({id: Tr.SALES}), isActive: !zakupoweFaktury, onClick: () => handleTabChange(1)},
    ];

    const handleTabChange = (newTab: number) => {
      setCurrentTab(newTab);

      const isNewPurchaseTab = newTab === 0;

      if (isNewPurchaseTab) {
        state.changeInvoiceTypeGroup(InvoiceTypeGroupEnum.Purchase);
        return;
      }

      state.changeInvoiceTypeGroup(InvoiceTypeGroupEnum.Sales);
    };

    useEffect(() => {
      if (
        state.postingConfigurationState.availableFinancialYears !== undefined &&
        state.postingConfigurationState.availableFinancialYears.length > 0
      ) {
        setAccountsOption(
          convertToDropdownWithLabelModel(state.postingConfigurationState.availableFinancialYears, acc => ({
            label: acc.Name,
            value: acc.Name,
            key: acc.Id,
            IsNotConfiguredAndActive: !acc.IsConfigured && acc.IsActive,
          })),
        );
        handleTabChange(state.postingConfigurationState.invoiceTypeGroup === InvoiceTypeGroupEnum.Purchase ? 0 : 1);
      }
    }, [
      state.postingConfigurationState.availableFinancialYears,
      state.postingConfigurationState.isFinancialYearActiveAndNotConfigured,
    ]);

    setPageTitle(intl.formatMessage({id: Tr.typesOfDocuments}));

    return (
      <>
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px] flex-wrap'}/>
        <div className="flex justify-between w-full lg:flex-row flex-col">
          <Header className={'flex items-start md:whitespace-normal whitespace-nowrap'}>
            <FormattedMessage id={Tr.typesOfDocuments}/>
            <IconWithTooltip
              tooltipWidth="auto"
              tooltipClass={'w-[800px]'}
              icon={{icon: IconSvg.HELP_OUTLINE, color: IconColor.GREEN_500}}
              btnClass={'flex items-start ml-[10px]'}
              tooltipPosition={TooltipPosition.RIGHT}
              tooltipContent={
                <>
                  <h1 className={'mb-[10px] font-bold'}>{intl.formatMessage({id: Tr.documentTypesTitlePopover})}</h1>
                  <p>{intl.formatMessage({id: Tr.documentTypesTitlePopoverText})}</p>
                </>
              }
            />
          </Header>
          {accountsOption.length > 0 && (
            <FinancialYearSelect
              setSelectedFinancialYear={handleChangeFinancialYear}
              state={state.postingConfigurationState}
            />
          )}
        </div>
        <Tabs tabs={tabs} fullWidthUnderline={true}/>
      </>
    );
  },
);
