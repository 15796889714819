import {useIntl} from 'react-intl';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {
  VehicleFiltersKeys,
  VehiclesUseTypes,
} from '../../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  convertToDropdownListOption,
  convertToFilterItems,
  convertToMapDropdownListOption,
  convertToMultiSelectType,
} from '../../../../../../../../common/helpers/baseFilterHelpers';
import {IVehicleState} from '../state/IVehicleState';
import {
  Accordion,
  AccordionSize,
  FilterItems,
  MultipleTextInputFilter,
  MultiSelectFilter,
} from '@symfonia/symfonia-ksef-components';
import {InputWidth} from '@symfonia/brandbook';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';

export interface VehiclesTableFiltersConfigProps {
  state: IVehicleState;
}

export const VehiclesTableFiltersConfig: FC<VehiclesTableFiltersConfigProps> = observer(({state}: VehiclesTableFiltersConfigProps) => {
  const intl = useIntl();

  const filterState = state.filterState;

  if (!filterState) {
    return <></>;
  }

  const licencePlateFilter = filterState.activeFilters.get(VehicleFiltersKeys.RegistrationNumber);
  const usageTypeFilter = filterState.activeFilters.get(VehicleFiltersKeys.VehicleUsageType);

  const vehicleUsage = convertToMultiSelectType(
    Object.values(VehiclesUseTypes),
    el => ({
      value: intl.formatMessage({id: Tr[el]}),
      key: el,
    }),
    true,
  );

  return <>
    <Accordion
      group={'registrationNumberPlate'}
      size={AccordionSize.SM}
      title={intl.formatMessage({id: Tr.registrationNumberPlate}) +
        (licencePlateFilter?.values !== undefined && licencePlateFilter?.values?.length !== 0 ? ' (' + licencePlateFilter?.values.length + ')' : '')}
    >
      <MultipleTextInputFilter
        itemLabel={intl.formatMessage({id: 'number'})}
        filterItems={licencePlateFilter?.pills !== undefined && licencePlateFilter?.pills?.length > 0
          ? convertToFilterItems(licencePlateFilter?.pills,
            el => ({
              value: el.value,
              key: el.key,
            }),
          )
          : undefined}
        setFilterItems={(items?: FilterItems) => {
          filterState.handleSelectByValue(convertToMultiSelectType(items, el => ({
              value: el?.value ?? '',
              key: el?.key.toString(),
            }),
          ), VehicleFiltersKeys.RegistrationNumber);
        }}
        itemPlaceholder={intl.formatMessage({id: 'SearchSpecificNumber'})}
        addButtonLabel={intl.formatMessage({id: 'addAnother'})}
        inputWidth={InputWidth.FULL}
      />
    </Accordion>

    <Accordion
      group={'VehicleUsageTypes'}
      size={AccordionSize.SM}
      title={intl.formatMessage({id: Tr.VehicleUsageTypes}) +
        (usageTypeFilter?.values !== undefined && usageTypeFilter?.values?.length !== 0
          ? ' (' + usageTypeFilter?.values.length + ')' : '')}
    >
      <MultiSelectFilter
        options={convertToMapDropdownListOption(vehicleUsage, el => ({
            label: el.value,
            value: el.key,
          }),
        )}
        items={convertToDropdownListOption(state.filterState.pillsList.get(VehicleFiltersKeys.VehicleUsageType)?.values, el => ({
            label: el.value,
            value: el.key,
          }),
        )}
        onSelected={(selected: (DropdownListOption<any> | undefined)[]) => state.filterState.handleSelectWithTypeConversion(selected, VehicleFiltersKeys.VehicleUsageType)}
      />
    </Accordion>

  </>;
});
