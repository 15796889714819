import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {PostedDecreeResponseFilterKeys} from '../../../earchive/pages/Account/Posting/pages/PostingConfiguration/CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {PostingTable} from './PostingTable/PostingTable';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IPostingState} from '../../state/IPostingState';
import {PostingStatusEnum, ReportFileTypeEnum} from '@symfonia-ksef/graphql';
import {Loader} from '../../../common/components/Loader/Loader';
import _ from 'lodash';
import {useModule} from '../../../root/services/MobXServices/BaseModule';
import {PostingTablePageService} from './PostingTablePage.service';
import {
  Breadcrumbs,
  ButtonTertiary,
  ButtonTertiarySize,
  DropdownListOption,
  DropdownSize,
  DropdownWidth,
  IconSvg,
} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';
import TableHeader from '../../../earchive/components/TableHeader/TableHeader';
import {DownloadReport, OnReportConfirm} from '../../../earchive/components/DownloadReport/DownloadReport';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {useDownloadPostingInvoicesReport} from './PostingTable/useDownloadPostingInvoicesReport';
import {usePageTitle} from '../../../common';
import {FiltersBar} from '../../../earchive/components/FiltersBar/FiltersBar';
enum ReportType {
  CSV = 'CSV',
  XLS = 'XLS',
}

export interface PostingPageProps {
  state: IPostingState;
}

const variantFilters = new Map<string, Array<PostingStatusEnum | boolean>>([
  [
    'allDecrees',
    [
      PostingStatusEnum.DecreeSent,
      PostingStatusEnum.Posted,
      PostingStatusEnum.Incomplete,
      PostingStatusEnum.NoAction,
      PostingStatusEnum.DecreeDownloaded,
    ],
  ],
  [
    'withoutSent',
    [PostingStatusEnum.Posted, PostingStatusEnum.Incomplete, PostingStatusEnum.NoAction, PostingStatusEnum.DecreeSent],
  ],
  ['incomplete', [PostingStatusEnum.Incomplete]],
  ['decreeSent', [PostingStatusEnum.DecreeDownloaded]],
  ['isAccepted', [false]],
]);

export const PostingPage = observer(({state}: PostingPageProps) => {
  const {setPageTitle} = usePageTitle();
  setPageTitle(intl.formatMessage({id: Tr.posting}));

  const {downloadPostingInvoicesReport} = useDownloadPostingInvoicesReport(state.postingInvoicesTableState);
  const postingService = useModule(() => new PostingTablePageService(state.postingInvoicesTableState));

  const acceptBeforeSendToFKIsActive = !!earchiveState.company.autoPosting?.AcceptBeforeSendToFK?.IsActive;

  const [mainFilterMenuOpen, setMainFilterMenuOpen] = useState<boolean>(false);
  const isAcceptedFilter = state.postingInvoicesTableState.filterState.activeFilters.get(
    PostedDecreeResponseFilterKeys.IsAccepted,
  )?.values;
  const filterValues = state.postingInvoicesTableState.filterState.activeFilters.get(
    PostedDecreeResponseFilterKeys.PostingStatus,
  )?.values;

  const currentVariant = useMemo((): string => {
    const isAcceptedFilter = state.postingInvoicesTableState.filterState.activeFilters.get(
      PostedDecreeResponseFilterKeys.IsAccepted,
    )?.values;
    if (isAcceptedFilter) return 'isAccepted';
    const filterValues = state.postingInvoicesTableState.filterState.activeFilters.get(
      PostedDecreeResponseFilterKeys.PostingStatus,
    )?.values;
    if (!filterValues) return 'allDecrees';
    const filter = [...variantFilters.entries()].find(x => _.isEqual(filterValues, x[1]));
    return filter !== undefined ? filter[0] : 'allDecrees';
  }, [isAcceptedFilter, filterValues]);

  const handleChange = (radio: string) => {
    state.postingInvoicesTableState.filterState?.setActiveFiltersWithStorage(
      PostedDecreeResponseFilterKeys.PostingStatus,
      {
        values: variantFilters.get(radio) ?? [],
        isActive: true,
      },
    );
    postingService.repository.fetch();
  };

  useEffect(() => {
    state.postingInvoicesTableState.setAcceptBeforeSendToFKIsActive(acceptBeforeSendToFKIsActive);
  }, []);

  const variantFiltersList = useMemo<DropdownListOption[]>(() => {
    const radioList = [
      {
        label: Tr.allDecrees,
        value: 'allDecrees',
      },
      {
        label: Tr.decreeReadyToSent,
        value: 'withoutSent',
      },
      {
        label: Tr.postingStatus_incomplete,
        value: 'incomplete',
      },
      {
        label: Tr.postingStatus_decree_sentFilter,
        value: 'decreeSent',
      },
    ];
    if (acceptBeforeSendToFKIsActive) radioList.push({label: Tr.decreesToAccept, value: 'isAccepted'});
    return radioList.map(({value, label}) => ({value, label: intl.formatMessage({id: label})}));
  }, [acceptBeforeSendToFKIsActive]);

  if (state.isLoading) {
    return <Loader/>;
  }

  const handleOpenAndLoad = useCallback((v: boolean) => {
    setMainFilterMenuOpen(v);
  }, []);

  const dropdownProps = {
    width: DropdownWidth.FULL,
    options: variantFiltersList,
    size: DropdownSize.SM,
    defaultValue: [currentVariant],
    onChange: ([value]: string[]) => handleChange(value),
    className: 'min-w-[220px]',
  };

  const downloadReportEnable =
    postingService.tableService.rows.length > 0 && state.postingInvoicesTableState.filterState.pillsList.size > 0;

  const onConfirm = useCallback<OnReportConfirm>(reportType => {
    downloadPostingInvoicesReport(reportType === 'CSV' ? ReportFileTypeEnum.Csv : ReportFileTypeEnum.Xlsx);
  }, []);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.symfonia}),
    },
    {
      label: intl.formatMessage({id: Tr.posting}),
    },
  ];

  setPageTitle(intl.formatMessage({id: Tr.posting}));

  return (
    <div
      className={
        'px-[28px] my-[20px] mx-auto max-w-[1920px] flex flex-col w-full ' +
        'justify-start items-start bg-white box-border'
      }
    >
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <h1 className={'text-[32px] font-bold pb-[8px] mt-[10px]'}>{intl.formatMessage({id: Tr.decreesList})}</h1>
      <TableHeader dropdown={dropdownProps} searchService={postingService.searchService} translationKey={Tr.search}>
        <div className="flex items-center mr-[8px] my-[10px]">
          <FiltersBar
            isFiltersMenuOpened={mainFilterMenuOpen}
            openFiltersMenu={() => handleOpenAndLoad(!mainFilterMenuOpen)}
            tableLength={postingService.tableService.rows.length}
            filtersApplied={state.postingInvoicesTableState.filterState.pillsList.size > 0}
          />
          <DownloadReport onConfirm={onConfirm} disabled={!downloadReportEnable}/>
          {state.postingInvoicesTableState.filterState.pillsList.size > 0 && (
            <ButtonTertiary
              className="mx-[8px] mt-[6px]"
              size={ButtonTertiarySize.SM}
              onClick={() => {
                state.postingInvoicesTableState.filterState.handleClearMenuFilter();
                postingService.repository.fetch();
              }}
              text={intl.formatMessage({id: Tr.clearFiltersLabel})}
              lIcon={IconSvg.CLOSE}
            />
          )}
        </div>
      </TableHeader>
      <PostingTable
        state={state.postingInvoicesTableState}
        pageService={postingService}
        filtersMenuIsOpen={mainFilterMenuOpen}
        setFiltersMenuIsOpen={setMainFilterMenuOpen}
      />
    </div>
  );
});
