import {
  GetIssuerInvoicesDocument,
  GetIssuerInvoicesQuery,
  GetIssuerInvoicesQueryVariables,
  IssuerInvoiceFragment,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {InvoicesFiltersState} from './InvoicesFiltersState';
import {computed, makeObservable} from 'mobx';
import {BaseTableDataRepositoryService} from '../../../../root/services/TableServices/DataSourceTableService';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export class IssuersRepository extends BaseTableDataRepositoryService<'GetIssuerInvoices', GetIssuerInvoicesQuery, GetIssuerInvoicesQueryVariables> {
  constructor(filtersState: InvoicesFiltersState) {
    super('GetIssuerInvoices', GetIssuerInvoicesDocument, envObserver, earchiveState);

    this.configure({
      skip: 0,
      take: 0,
      SearchText: '',
      order: {
        Direction: OrderDirection.Asc,
        FieldName: 'IssuerName',
      },
      FiltersFields: {
        Foreign: filtersState.isExternal,
        Own: filtersState.isInternal,
      },
    });
    makeObservable(this);
    this.fetch();
  }

  @computed
  public get issuers(): IssuerInvoiceFragment[] {
    return this.data?.Items ?? [];
  }
}
