import {PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {
  DropdownSize,
  DropdownWidth,
  Icon,
  IconSize,
  IconSvg,
  Notification,
  NotificationSize,
  NotificationVariant,
  Tooltip,
} from '@symfonia/brandbook';
import {Label, LabelSize} from 'libs/brandbook/src/internal/components/Label/Label';
import {DropdownList, DropdownListOption} from './DropdownList';

export type DropdownProps<TOptionType> = {
  options: Map<React.Key, DropdownListOption<TOptionType>>;
  values?: Set<React.Key>;
  defaultValue?: React.Key[];
  onChange?: (values: React.Key[]) => void;
  width?: DropdownWidth;
  size?: DropdownSize;
  isError?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  label?: string;
  notification?: string;
  useSearch?: boolean;
  placeholder?: string;
  required?: boolean;
  displaySelected?: boolean;
  tooltip?: ReactNode;
  listWithTooltip?: boolean;
  withClear?: boolean;
  handleClear?: () => void;
  testId?: string;
};

export const Dropdown = <TOptionType,>({
  options,
  values,
  defaultValue = undefined,
  onChange = () => undefined,
  width = DropdownWidth.BASE,
  size = DropdownSize.MD,
  isError = false,
  disabled = false,
  multiple = false,
  label = '',
  notification = '',
  useSearch = false,
  children = undefined,
  placeholder = undefined,
  required = false,
  displaySelected = true,
  tooltip = undefined,
  listWithTooltip = false,
  withClear = false,
  handleClear = undefined,
  testId,
}: PropsWithChildren<DropdownProps<TOptionType>>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const isControlled = values !== undefined;

  const [localValues, setLocalValues] = useState<Set<React.Key>>(new Set(defaultValue));

  const selectedValues = isControlled ? values : localValues;

  useEffect(() => {
    setLocalValues(new Set(defaultValue));
  }, []);

  const selectedOptionsKeys = Array.from(options.keys()).filter(key => selectedValues.has(key));
  const selectedOptionsValues = Array.from(options.entries())
    .filter(([key, _]) => selectedOptionsKeys.includes(key))
    .map(([_, value]) => value);

  const styles = {
    component: clsx({
      'inline-flex': true,
      'w-full': width === DropdownWidth.FULL,
      'w-[285px]': width === DropdownWidth.BASE,
      'w-fit': width === DropdownWidth.FIT,
      'h-[40px]': size === DropdownSize.SM,
      'h-[48px]': size === DropdownSize.MD,
      'border rounded-lg px-[16px] flex items-center text-base font-quicksand': true,
      'border-grey-300 text-grey-300': disabled,
      'focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-500': !disabled,
      'border-red-500 text-black': !disabled && isError,
      'border-grey-300 hover:border-primary-500 text-black': !disabled && !isError,
    }),
    icon: clsx({
      'filter-grey-300': disabled,
      'filter-red-500': !disabled && isError,
      'filter-primary-500': !disabled && !isError,
    }),
    contentBox: clsx('w-full whitespace-nowrap text-ellipsis max-h-[32px] overflow-hidden', {
      'text-grey-500': placeholder && selectedOptionsKeys.length === 0,
    }),
    clearIcon: clsx({
      'cursor-pointer': true,
    }),
    container: clsx({
      'h-[40px]': size === DropdownSize.SM,
      'h-[48px]': size === DropdownSize.MD,
      'w-full': width === DropdownWidth.FULL,
      'inline-flex items-center gap-[8px]': true,
    }),
  };

  const handleChange = (nextValues: React.Key[]): void => {
    if (!isControlled) {
      setLocalValues(new Set(nextValues));
    }
    onChange(nextValues);
    if (!multiple) {
      setOpen(false);
    }
  };

  const handleKeyDown = (keyCode: string) => {
    if (keyCode === 'Enter' || keyCode === 'Space') {
      setOpen(true);
    }
  };

  const render = () => (
    <Tooltip text={!multiple && selectedValues.size > 0 && tooltip}>
      <div className={styles.container}>
        <div
          onKeyDown={event => handleKeyDown(event.code)}
          ref={ref}
          tabIndex={0}
          className={styles.component}
          onClick={() => !disabled && setOpen(!isOpen)}
          data-testid={testId}
        >
          <span className={styles.contentBox}>
            {placeholder && selectedOptionsKeys.length === 0
              ? placeholder
              : displaySelected
              ? selectedOptionsValues.map(option => option.label).join(', ')
              : placeholder}
          </span>
          <Icon
            className={styles.icon}
            svg={isOpen ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
            size={IconSize.LG}
          />
        </div>
        {withClear && (
          <div className={clsx(styles.clearIcon)}>
            <Icon className={clsx(styles.icon)} onClick={handleClear} svg={IconSvg.CLOSE} size={IconSize.MD}/>
          </div>
        )}
      </div>
    </Tooltip>
  );

  const renderList = () => (
    <DropdownList
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      className="mt-[8px] max-h-[340px]"
      options={options}
      value={selectedValues}
      anchorEl={ref}
      useSearch={useSearch}
      multiple={multiple}
      listWithTooltip={listWithTooltip}
    >
      {children}
    </DropdownList>
  );

  const renderNotification = () => (
    <Notification
      text={notification}
      variant={isError ? NotificationVariant.ERROR : NotificationVariant.INFO}
      size={NotificationSize.SM}
      className="mt-[8px]"
    />
  );

  return (
    <>
      {label && (
        <Label
          className="mb-[8px]"
          text={label}
          isRequired={required}
          size={size === DropdownSize.MD ? LabelSize.MD : LabelSize.SM}
        />
      )}
      {render()}
      {renderList()}
      {notification && renderNotification()}
    </>
  );
};
