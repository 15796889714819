import {action, makeObservable, observable} from 'mobx';
import {IModuleStatusStore} from '../../state/ModuleSettings/IModuleStatusStore';
import {ModuleStatusStore} from '../../state/ModuleSettings/ModuleStatusStore';
import {IPostingState} from '../auto-posting/state/IPostingState';
import {PostingState} from '../auto-posting/state/PostingState';
import {FeatureSwitchTypeEnum} from '../common/enums/FeatureSwitchTypeEnum';
import {isFeatureEnabled} from '../common/helpers/featureSwitch';
import {IEarchiveStateMobx} from './IEArchiveStateMobx';
import {AccountState} from './pages/Account/AccountState';
import {IAccountState} from './pages/Account/IAccountState';
import {DocumentsState} from './pages/Documents/state/DocumentsState';
import {IDocumentsState} from './pages/Documents/state/IDocumentsState';
import {IFilehubState} from './pages/Documents/state/IFilehubState';
import {FilehubState} from './pages/Documents/state/FilehubState';
import {InvoiceBound} from '@symfonia-ksef/graphql';

export class EArchiveStateMobx implements IEarchiveStateMobx {
  @observable
  companyId: string = '';

  @observable
  invoiceBound: InvoiceBound = InvoiceBound.External;

  moduleStatusStore: IModuleStatusStore;

  constructor() {
    makeObservable(this);
    this.moduleStatusStore = new ModuleStatusStore(this);
  }

  private _documentsState: IDocumentsState | undefined;

  public get documentsState(): IDocumentsState {
    if (this._documentsState === undefined) {
      this._documentsState = new DocumentsState(this);
    }
    return this._documentsState;
  }

  private _accountState: IAccountState | undefined;

  public get accountState(): IAccountState {
    if (this._accountState === undefined) {
      this._accountState = new AccountState(this);
    }
    return this._accountState;
  }

  private _postingState: IPostingState | undefined;

  public get postingState(): IPostingState {
    if (isFeatureEnabled(FeatureSwitchTypeEnum.autoposting)) {
      if (this._postingState === undefined) {
        this._postingState = new PostingState(this);
      }
    } else {
      throw new Error('Autoposting feature is not enabled, state cannot be accessed');
    }

    return this._postingState;
  }

  public get filehubState(): IFilehubState {
    if (this._filehubState === undefined) {
      this._filehubState = new FilehubState(this);
    }
  
    return this._filehubState;
  }
    
  private _filehubState: IFilehubState | undefined;

  @action
  setCompanyId(companyId: string) {
    this.companyId = companyId;
    this.moduleStatusStore.resetModules();
  }

  @action
  setInvoiceBound(invoiceBound: InvoiceBound) {
    this.invoiceBound = invoiceBound;
  }

}
