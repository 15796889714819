import {
  GetDocumentTypesDocument,
  GetDocumentTypesQuery,
  GetDocumentTypesSettingsQueryVariables,
  InvoiceDocumentTypeResponse,
  InvoiceTypeGroupEnum,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetDocumentTypesQueryFunction = async (financialYearId: string, companyId: string, invTypeGroup: InvoiceTypeGroupEnum): Promise<InvoiceDocumentTypeResponse[]> => {
  return await apolloClient
    .query<GetDocumentTypesQuery, GetDocumentTypesSettingsQueryVariables>({
      query: GetDocumentTypesDocument,
      context: {
        envId: companyId,
      },
      variables: {
        InvoiceTypeGroup: invTypeGroup,
        FinancialYearId: financialYearId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetInvoiceDocumentTypes.DocumentTypeResponse as InvoiceDocumentTypeResponse[];
    });
};
