import * as React from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '../../../../../../../locales/translationKeys';
import {observer} from 'mobx-react-lite';

export interface PostingDetailsBodyListSummaryProps {
  sumWn: number;
  sumMa: number;
  isSumValid: boolean;
  isSalesInvoice: boolean;
  isWnValueModified: boolean;
  isMaValueModified: boolean;
}

export const PostingDetailsBodyListSummary = observer(
  ({
    sumWn,
    sumMa,
    isSumValid,
    isSalesInvoice,
    isWnValueModified,
    isMaValueModified,
  }: PostingDetailsBodyListSummaryProps) => {
    const intl = useIntl();

    const getValidationErrorClassForMa = () => {
      if (!isSumValid && (isMaValueModified || (!isMaValueModified && !isWnValueModified && isSalesInvoice))) {
        return 'text-red';
      }
      return '';
    };

    const getValidationErrorClassForWn = () => {
      if (!isSumValid && (isWnValueModified || (!isMaValueModified && !isWnValueModified && !isSalesInvoice))) {
        return 'text-red';
      }
      return '';
    };

    return (
      <div className={'w-full shadow-none bg-white box-border'}>
        <div
          className={
            'px-4 h-[52px] flex items-center border-solid border-0 border-y-2 border-blue-500 font-quicksand text-base'
          }
        >
          <div className={'flex flex-row w-full justify-between'}>
            {!isSumValid && <p className={'text-red m-0'}>{intl.formatMessage({id: Tr.amountsDoNotMatch})}</p>}
            <div className={'flex flex-row ml-auto justify-around float-right w-[55%]'}>
              <p className={'m-0 text-base'}>{intl.formatMessage({id: Tr.sum})}:</p>
              <p className={'font-bold m-0 ' + getValidationErrorClassForWn()}>
                {Intl.NumberFormat('pl-PL', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(sumWn)}
              </p>
              <p className={'font-bold m-0 ' + getValidationErrorClassForMa()}>
                {Intl.NumberFormat('pl-PL', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(sumMa)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
