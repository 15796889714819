import {
  CompanyVehiclesResponse,
  GetCompanyVehicleByIdDocument,
  GetCompanyVehicleByIdQuery,
  GetCompanyVehicleByIdQueryVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetVehicleById = async (vehicleId: string, companyId: string | undefined): Promise<CompanyVehiclesResponse> => {
  return await apolloClient
    .query<GetCompanyVehicleByIdQuery, GetCompanyVehicleByIdQueryVariables>({
      query: GetCompanyVehicleByIdDocument,
      context: {
        envId: companyId,
      },
      variables: {
        VehicleId: vehicleId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetCompanyVehicleById.Vehicle as CompanyVehiclesResponse;
    });
};
