import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Modal} from '@symfonia/brandbook';

interface VehicleDeleteModalProps {
  registrationNumberPlate: string;
  open: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export const VehicleDeleteModal: FC<VehicleDeleteModalProps> = ({
                                                                  registrationNumberPlate,
                                                                  open,
                                                                  onDelete,
                                                                  onCancel,
                                                                }) => {
  const intl = useIntl();
  return (<Modal show={open} onClose={onCancel}
           title={intl.formatMessage({id: Tr.deleteVehicleHeader})}
           centerButtons={true}
           okButton={{text: intl.formatMessage({id: Tr.removeButton}), onClick: onDelete}}
           cancelButton={{text: intl.formatMessage({id: Tr.cancelButton}), onClick: onCancel}}
    >
      <p>{intl.formatMessage({id: Tr.deleteVehicleDescription}, {
        registrationNumberPlate: <span>{registrationNumberPlate}</span>,
      })}</p>
    </Modal>);
};
