import {AccountResponse, AccountViewModel} from "@symfonia-ksef/graphql";
import {TreeViewDropdownModel} from "../../common/components/TreeViewDropdown/TreeViewDropdown";

export class accountMapper {
  public static mapToTreeViewDropdownModel(accounts: AccountResponse[]): TreeViewDropdownModel[] {
    return accounts.map(el => ({
      value: el?.Number + ' ' + el?.Name,
      key: el?.Id,
      isSelectable: el.IsFinal && !el.IsDisabled,
      isDisabled: el.IsDisabled,
      isHidden: false,
      parentId: el?.ParentId,
      childrenIds: el?.ChildrenIds,
    }));
  }
}
