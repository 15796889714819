import {KSeFStatus} from '@symfonia-ksef/graphql';

export interface KSeFStatusText {
  text: string;
  defaultColor: string;
}

export const InvoiceKsefStatusToText = (value: KSeFStatus): KSeFStatusText => {
  switch (value) {
    case KSeFStatus.Approved:
      return {
        text: 'Zatwierdzone',
        defaultColor: 'green',
      } as KSeFStatusText;
    case KSeFStatus.Error:
      return {text: 'Błąd wysłania', defaultColor: 'red'} as KSeFStatusText;
    case KSeFStatus.Pending:
      return {text: 'Oczekuje', defaultColor: 'orange'} as KSeFStatusText;
    case KSeFStatus.Rejected:
      return {text: 'Odrzucone', defaultColor: 'red'} as KSeFStatusText;
    case KSeFStatus.NotSent:
      return {text: 'Do wysłania', defaultColor: 'black'} as KSeFStatusText;
    case KSeFStatus.Sending:
      return {text: 'W trakcie wysyłki', defaultColor: 'black'} as KSeFStatusText;
    case KSeFStatus.InterruptedSending:
      return {text: 'Przerwana wysyłka', defaultColor: 'red'} as KSeFStatusText;
    case KSeFStatus.InterruptedPending:
      return {text: 'Przerwana weryfikacja', defaultColor: 'red'} as KSeFStatusText;
    case KSeFStatus.Ocr:
      return {text: 'Ocr', defaultColor: 'black'} as KSeFStatusText;
    default:
      return {text: '', defaultColor: 'black'} as KSeFStatusText;
  }
};
