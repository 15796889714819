import React, {FC} from 'react';
import {IconColor, IconSvg} from '@symfonia/brandbook';
import {LinearPackageLimitsGroup, LinearPackageLimitsGroupProps} from '../LinearPackageLimitsGroup/LinearPackageLimitsGroup';
import {IconWithTooltip} from '../IconWithTooltip/IconWithTooltip';
import {theme} from '../../theme';

export type IAccountLimits = {
  limitsEnable?: boolean;
  linearPackageIcon?: string | undefined;
  linearTooltipHeader?: string | undefined;
} & LinearPackageLimitsGroupProps;

export const AccountLimits: FC<IAccountLimits> = ({
                                                    limitsEnable,
                                                    linearPackageIcon,
                                                    linearTooltipHeader,
                                                    enableInheritedBackground,
                                                    ...packageLimitsProps
                                                  }) => {

  const {dateTo} = packageLimitsProps;

  return (
    dateTo && limitsEnable
      ? <IconWithTooltip
        tooltipWidth="auto"
        tooltipClass={'w-[220px]'}
        icon={{icon: IconSvg.ASSESSMENT, color: IconColor.GREY_500}}
        onClick={() => packageLimitsProps.redirect?.()}
        tooltipContent={
          <LinearPackageLimitsGroup
            tooltipHeader={linearTooltipHeader ?? ''}
            wrap={true}
            textColor={theme.palette.common.white} {...packageLimitsProps}
            enableInheritedBackground={false}
          />}
      />
      : <></>
  );
};
