import {ValidatorConfig} from './DropzoneUploaderModule/DropzoneUploaderValidator';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '../../../../locales/translationKeys';
import {DropzoneUploaderStateServiceI} from './DropzoneUploaderModule/DropzoneUploaderStateService';

export const invoicesUploaderInitialConfig: ValidatorConfig = Object.freeze({
  maxFileCount: 50,
  minFileCount: 1,
  maxFileSize: 1024 * 1024,
  fileTypes: ['application/xml', '.xml', 'text/xml'],
  messages: {
    type: ({subscriptionIsActive}: DropzoneUploaderStateServiceI) => {
      return intl.formatMessage({id: subscriptionIsActive ? Tr.uploadManyInvalidType : Tr.uploadOneInvalidType});
    },
    maxSize: ({subscriptionIsActive}: DropzoneUploaderStateServiceI) => {
      return intl.formatMessage({id: subscriptionIsActive ? Tr.uploadManyInvalidSize : Tr.uploadOneInvalidSize});
    },
    maxCount: ({subscriptionIsActive}: DropzoneUploaderStateServiceI) => {
      return intl.formatMessage({id: subscriptionIsActive ? Tr.uploadManyExceed : Tr.uploadOneExceed});
    },
    mixed: ({subscriptionIsActive}: DropzoneUploaderStateServiceI) => {
      return intl.formatMessage({id: subscriptionIsActive ? Tr.uploadManyInvalidMixedReasons : Tr.uploadOneInvalidMixedReasons});
    },
  },
});
