import {FC, ReactNode} from 'react';
import clsx from 'clsx';

export type SimpleTableHeaderProps = {
  title?: string;
  className?: string;
  headers?: ReactNode[];
};

export const SimpleTableHeader: FC<SimpleTableHeaderProps> = ({
  title = undefined,
  className = undefined,
  headers = undefined,
}) => {
  const widthClassName = `w-[${Math.round(100 / (headers?.length ?? 1))}%]`;

  return (
    <>
    {
      title && <span className={clsx('cursor-default font-quicksand', className)}>
        {title}
      </span>}
    {
      headers && headers.map((column, index) => (
        <span key={index} className={clsx(widthClassName, className, 'ml-[5px]')}>
          {column}
        </span>
      ))
    }
    </>
  );
};
