import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage} from 'react-intl';
import {FC} from 'react';

export const VersionRibbon: FC = () => {


  return (
    <div className={'w-full h-[18px] bg-blue-200 flex items-center justify-center font-quicksand text-xs'}>
      <p className={'font-quicksand text-xs font-medium'}><FormattedMessage id={Tr.serverDEMO}/></p>
    </div>
  );
};
