import {
  DropzoneUploaderValidator,
  Files,
  OnValidate,
  UploaderValidatorI,
  ValidationError,
  ValidatorConfig,
} from './DropzoneUploaderValidator';
import {action, computed, IReactionDisposer, makeObservable, observable, reaction} from 'mobx';

import {HTMLInputTypeAttribute} from 'react';
import {addAlert} from '../../../../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {BytesConverter} from '../../../../common/helpers/BytesConverter';
import {earchiveState, uploadInvoicesService} from '@symfonia-ksef/state/rootRepository';

export type InputProps = {
  multiple: boolean,
  accept: string | undefined,
  type: HTMLInputTypeAttribute,
}

export type FileKeyData = Pick<File, 'name' | 'type' | 'size'>


export interface DropzoneUploaderStateServicePublicI {

  get filesData(): FilesData;

  get rejectedFiles(): FileItem[];

  get validatedFiles(): FileItem[];

  get allFiles(): FileItem[];

  createFileKey({name, type, size}: FileKeyData): string;

  removeFile(file: FileKeyData): boolean;

  reset(chosenType: Partial<Record<keyof Files, boolean>>): void;

  configureValidator(config: Partial<ValidatorConfig>): this;

  get inputProps(): InputProps;

  get isValid(): boolean;

  get errors(): ValidationError[];

  get validationRules(): Partial<ValidatorConfig>;

  get disabled(): boolean;

  get loaded(): boolean;

  get subscriptionIsActive(): boolean;
}

export type FileItem = {
  percentagesProgress: number,
  file: File,
  validated?: boolean,
  validationErrors: ValidationError[],
  size: string
}

export type FilesData = Record<string, FileItem>

export interface DropzoneUploaderStateServiceI extends DropzoneUploaderStateServicePublicI {
  initialConfig: Partial<ValidatorConfig>;
  initialized: boolean;

  handleUpload(files: FileList | null | undefined): File[];

}

export class DropzoneUploaderStateService implements DropzoneUploaderStateServiceI {
  @observable
  public initialized: boolean = false;

  private readonly validator: UploaderValidatorI;
  private readonly disposer: IReactionDisposer;

  constructor(public initialConfig: ValidatorConfig = {}) {
    makeObservable(this);
    this.validator = new DropzoneUploaderValidator(this);
    this.matchMaxFileCount(this.subscriptionIsActive);
    this.disposer = reaction(() => this.subscriptionIsActive, (isActive) => this.matchMaxFileCount(isActive));
  }

  @observable.ref
  public _filesData: FilesData = {};

  @computed
  public get filesData(): FilesData {
    return this._filesData;
  }

  @computed
  public get allFiles(): FileItem[] {
    return Object.values(this.filesData);
  }

  @computed
  public get isValid(): boolean {
    return this.validator.isValid;
  }

  @computed
  public get loaded(): boolean {
    return earchiveState.packageStatistics.loaded;
  }

  @computed
  public get errors(): ValidationError[] {
    return this.validator.errors;
  }

  @computed
  public get validationRules(): Partial<ValidatorConfig> {
    return this.validator;
  }

  @computed
  public get inputProps(): InputProps {
    return {
      multiple: (this.validationRules.maxFileCount ?? Infinity) > 1,
      accept: (this.validationRules.fileTypes?.join?.(', ')) || undefined,
      type: 'file',
    };
  }

  @computed
  get disabled(): boolean {
    return uploadInvoicesService.jobRunner.loading;
  }

  @computed
  public get subscriptionIsActive(): boolean {
    return !!earchiveState.packageStatistics.subscriptionIsActive;
  }

  @computed
  public get rejectedFiles(): FileItem[] {
    return Object.values(this.filesData).filter(({validated}) => !validated);
  }

  @computed
  public get validatedFiles(): FileItem[] {
    return Object.values(this.filesData).filter(({validated}) => validated);
  }

  @action.bound
  public reset(chosenType: Partial<Record<keyof Files, boolean>>): void {
    this.validator.reset();
    this._filesData = {};
  }

  @action.bound
  public removeFile(file: FileKeyData, opt?: {
    rejectedIncludes: boolean
  }): boolean {
    return this.removeFileData(file);
  }

  public dispose(): void {
    this.disposer();
  }

  public configureValidator(config: Partial<ValidatorConfig>): this {
    this.validator.configure(config);
    return this;
  }

  public handleUpload(files: FileList | null | undefined): File[] {
    return this.setFileData(files, file => {
      const reader = new FileReader();
      const progressHandler = (e: ProgressEvent<FileReader>) => this.setFileProgress(e, file);
      const loadedHandler = () => {
        reader.removeEventListener('progress', progressHandler);
        reader.removeEventListener('loadend', loadedHandler);
      };
      reader.addEventListener('progress', progressHandler);
      reader.addEventListener('loadend', loadedHandler);
      reader.readAsDataURL(file);
    });
  }

  public createFileKey({name, type, size}: FileKeyData): string {
    return [name, type, size].join('.');
  }

  @action
  private removeFileData(file: FileKeyData): boolean {
    const key = this.createFileKey(file);
    if (!(key in this.filesData)) {
      return false;
    }
    const {[key]: _, ...rest} = this.filesData;
    this._filesData = rest;
    return true;
  }

  @action
  private setFileProgress({loaded, total}: ProgressEvent<FileReader>, file: File): void {
    const fileKey = this.createFileKey(file);
    const found = this.filesData[fileKey];
    if (!found) {
      return;
    }
    const newProgress: FileItem = {
      ...found,
      percentagesProgress: loaded / total * 100,
    };
    this._filesData = {...this.filesData, [fileKey]: newProgress};
  }

  @action
  private setFileData(files: FileList | null | undefined, onSet?: (file: File, errors: ValidationError[]) => void): File[] {
    const convertedFiles = this.transformFileList(files);
    const filesData: FilesData = {};
    const {validatedFiles, rejectedFiles} = this.validate(convertedFiles);

    for (const {file} of validatedFiles) {
      onSet?.(file, []);
      filesData[this.createFileKey(file)] = {
        file,
        validated: true,
        validationErrors: [],
        percentagesProgress: 0,
        size: BytesConverter.format(file.size).value,
      };
    }

    for (const {file, isValid, errors} of rejectedFiles) {
      onSet?.(file, errors);
      filesData[this.createFileKey(file)] = {
        file,
        validated: isValid,
        validationErrors: errors,
        percentagesProgress: 0,
        size: BytesConverter.format(file.size).value,
      };
    }

    this._filesData = this.subscriptionIsActive ? {...this.filesData, ...filesData} : {...filesData};

    return validatedFiles.map(({file}) => file);
  }

  @action
  private validate(files: File[], onValidate?: OnValidate): Files {
    if (!this.initialized) {
      this.initialized = true;
    }
    const validationResult = this.validator.validate(this.getUniqueFiles(files), onValidate);
    if (this.errors.length) {
      this.handleAlert();
    }
    return validationResult;
  }

  private matchMaxFileCount(isActive: boolean) {
    this.configureValidator({
      maxFileCount: isActive ? this.initialConfig?.maxFileCount : 1,
      minFileCount: isActive ? this.initialConfig.minFileCount : 0,
    });
  }

  private getUniqueFiles(files: File[]): File[] {
    const uniqueFiles: File[] = [];
    const uniqueFilesMap = new Map<string, File>();

    for (const file of files) {
      const key = this.createFileKey(file);
      if (uniqueFilesMap.get(key)) {
        continue;
      }
      uniqueFilesMap.set(key, file);
      uniqueFiles.push(file);
    }
    return uniqueFiles;
  }

  private transformFileList(files: FileList | null | undefined): File[] {
    return Array.from(files ?? []);
  }

  private handleAlert(): void {
    const reason: string = this.validator.mixedError?.message ?? this.validator.uniqueErrors
      .filter(({message}) => Boolean(message))
      .map(({message}) => message as string)
      .join(', ');
    addAlert({
      id: this.inputProps.multiple ? Tr.uploadManyError : Tr.uploadOneError,
      color: ToastVariant.ERROR,
      omitIfHasTheSameAlert: true,
      values: {reason},
    });
  }
}
