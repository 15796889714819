import {WebSocketNotificationResponseFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {
  Mapper,
  NotificationDataParser,
  NotificationDataParserI,
  NotificationDataType,
} from '../../services/helpers/NotificationDataParsers';

export interface EventParsersManagerI {
  setParser<T extends WebsocketNotificationType = WebsocketNotificationType, P = unknown>(data: WebSocketNotificationResponseFragment, opt?: { cachingDisabled?: boolean, mapper?: Mapper<T, P> }): NotificationDataParser<T>;

  getParser<T extends WebsocketNotificationType>(id: string, type: T): NotificationDataParser<typeof type> | undefined;

  setData<T extends WebsocketNotificationType>(id: string, type: T, data: NotificationDataType<T> | null): this;

  getData<T extends WebsocketNotificationType>(id: string, type: T, opt?: { reparse: boolean }): NotificationDataType<T> | null;
}

export class KSeFEventParsersManager implements EventParsersManagerI {
  private readonly parsersMap: Map<string, NotificationDataParserI> = new Map();

  public setParser<T extends WebsocketNotificationType = WebsocketNotificationType, P = unknown>(data: WebSocketNotificationResponseFragment, opt?: { cachingDisabled?: boolean, mapper?: Mapper<T, P>, }): NotificationDataParser<T> {
    const parser = new NotificationDataParser<typeof data.Type>(data, opt).setMapper(opt?.mapper);
    this.parsersMap.set(data.NotificationId, parser);
    return parser;
  }

  public getParser<T extends WebsocketNotificationType>(id: string, type: T): NotificationDataParser<typeof type> | undefined {
    const parser = this.parsersMap.get(id);
    if (parser?.event.Type === type) {
      return parser as NotificationDataParser<T>;
    }
    return;
  }

  public setData<T extends WebsocketNotificationType>(id: string, type: T, data: NotificationDataType<T> | null): this {
    this.getParser(id, type)?.set?.(data);
    return this;
  }

  public getData<T extends WebsocketNotificationType>(id: string, type: T, opt?: { reparse: boolean }): NotificationDataType<T> | null {
    return this.getParser<T>(id, type)?.parse?.(opt) ?? null;
  }
}

export const ksefEventParserManager = new KSeFEventParsersManager();
