// @TODO pobieranie kodu waluty dynamicznie z danych faktury po zaimplementowaniu tej właściwości przez backend - nieaktualne
// Implementacja bez oznaczenia waluty, sama kwota
export const invoiceCurrency = Intl.NumberFormat('pl-PL', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: true,
});

export const InvoiceCurrencyStatusCell: React.FC<{ value: number }> = ({value}) => (
  <div>{invoiceCurrency.format(value)}</div>
);

export const InvoiceCurrencyStatusCellValue = (cell: number) => (
  invoiceCurrency.format(cell)
);
