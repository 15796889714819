import React from 'react';
import {SearchInput} from '../../../root/components/SearchInput';
import {Dropdown, DropdownProps} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {SearchServiceI} from '../../../root/services/SearchService/SearchService';

type Props = {
  children?: React.ReactNode;
  dropdown?: DropdownProps;
  searchService?: SearchServiceI;
  translationKey?: Tr;
};

const TableHeader = ({children, dropdown, searchService, translationKey}: Props) => {
  return (
    <div>
      <div className="flex flex-wrap">
        {searchService && translationKey && (
          <div className="max-width-[100px] mr-[8px] my-[10px]">
            <SearchInput translationKey={translationKey} service={searchService}/>
          </div>
        )}
        {dropdown && (
          <div className="max-w-[240px] mr-[8px] my-[10px]">
            <Dropdown {...dropdown}/>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default TableHeader;
