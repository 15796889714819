import {FC, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {TestableElement} from '../../external/types';

export type ImgProps = {
  src: string;
  onLoad?: () => void;
  onError?: () => void;
  alt: string;
  className?: string;
  testElement?: string;
} & TestableElement;

export const Img: FC<ImgProps> = ({
  src,
  alt = '',
  onLoad = () => undefined,
  onError = () => undefined,
  className = undefined,
  testId = undefined,
  testElement = 'image',
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const image = useRef<HTMLImageElement>(null);

  const handleOnError = () => {
    onError();
  };

  const handleOnLoad = () => {
    setIsLoaded(true);
    onLoad();
  };

  useEffect(() => {
    setIsLoaded(false);
  }, [src]);

  useEffect(() => {
    if (image?.current?.complete) {
      setIsLoaded(true);
    }
  }, []);

  return (
    <>
      <img
        data-testid={testId}
        data-test-element={testElement}
        src={src}
        key={src}
        onLoad={handleOnLoad}
        onError={handleOnError}
        alt={alt}
        ref={image}
        className={clsx({'invisible w-0 h-0': !isLoaded, [className || '']: isLoaded})}
      />
    </>
  );
};
