import {IModuleStatus} from "../../../../../state/ModuleSettings/IModuleStatus";

export interface NotPurchasedProps extends React.PropsWithChildren {
  moduleStatus?: IModuleStatus;
}

export const NotPurchased = ({moduleStatus, children}: NotPurchasedProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {moduleStatus && !moduleStatus.isModulePurchased && children}
    </>
  );
};