import clsx from 'clsx';
import {FC} from 'react';
import {TogglableAppearanceProps, TogglableSize} from '../../../internal/components/Togglable/Togglable';

export const getAppearanceInputStyles = (size: TogglableSize) =>
  clsx('absolute rounded-full', {
    'w-[18px] h-[18px]': size === TogglableSize.SM,
    'w-[24px] h-[24px]': size === TogglableSize.MD,
  });

export const RadioButtonAppearance: FC<TogglableAppearanceProps> = ({
  isChecked = false,
  size = TogglableSize.MD,
  isError = false,
  disabled = false,
}) => {
  const styles = {
    component: 'group relative',
    outer: clsx('rounded-full', {
      'w-[18px] h-[18px]': size === TogglableSize.SM,
      'w-[24px] h-[24px]': size === TogglableSize.MD,
      'bg-primary-500 group-hover:bg-primary-600': isChecked && !disabled && !isError,
      'border border-solid border-primary-500 group-hover:border group-hover:border-solid group-hover:border-primary-600':
        !isChecked && !disabled && !isError,
      'bg-red-500': isError && isChecked,
      'border border-solid border-red-500': isError && !isChecked,
      'bg-grey-500': disabled && isChecked,
      'border border-solid border-grey-500': disabled && !isChecked,
    }),
    inner: clsx('rounded-full', {
      'w-[8px] h-[8px]': isChecked && size === TogglableSize.SM,
      'w-[10px] h-[10px]': isChecked && size === TogglableSize.MD,
      'bg-white absolute top-[7px] left-[7px]': isChecked && size === TogglableSize.MD,
      'bg-white absolute top-[5px] left-[5px]': isChecked && size === TogglableSize.SM,
    }),
  };
  return (
    <div className={styles.component}>
      <div className={styles.outer} />
      <div className={styles.inner} />
    </div>
  );
};
