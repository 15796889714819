import * as React from 'react';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {MultipleFilterItem} from './MultipleFilterItem';
import {useKeyPress, usePrevious} from '../../../hooks';
import {ButtonTertiary, ButtonTertiarySize, InputWidth} from '@symfonia/brandbook';

export enum FILTER_ACTION_TYPES {
  add = 'add',
  remove = 'remove',
  change = 'change',
}

const defaultKeyGen = () => Date.now();
const newItemCreatorFactory = (keyGen: () => React.Key) => (): FilterItem => ({
  value: '',
  key: keyGen(),
});

export type FilterItem = {value: string; key: React.Key};
export type FilterItems = Array<FilterItem>;
export type MultipleTextInputFilterType = {
  preventUpdateOnError?: boolean;
  onChange?: (nips?: FilterItems, details?: {item?: FilterItem; actionType: FILTER_ACTION_TYPES}) => void;
  keyGen?: () => React.Key;
  itemLabel: string;
  itemPlaceholder?: string;
  errorMessage?: string;
  filterItems: FilterItems | undefined;
  setFilterItems: (items?: FilterItems) => void;
  disableValidation?: boolean;
  disableErrorFeedback?: boolean;
  min?: number;
  max?: number;
  addButtonLabel: string;
  inputWidth?: InputWidth;
  testId?: string;
  validationPattern?: RegExp;
  setValidationError?: (hasError: boolean) => void;
  hasError?: boolean;
};

const findItem = (arr: FilterItems | undefined, key: React.Key | null) => arr?.find?.(item => item.key === key);

export const MultipleTextInputFilter: FC<MultipleTextInputFilterType> = ({
  inputWidth,
  itemLabel,
  itemPlaceholder,
  keyGen,
  onChange,
  preventUpdateOnError,
  filterItems,
  setFilterItems,
  disableValidation,
  disableErrorFeedback,
  min,
  max,
  addButtonLabel,
  testId,
  validationPattern,
  hasError,
  setValidationError = () => false,
}) => {
  const createNewItem = newItemCreatorFactory(keyGen ?? defaultKeyGen);
  const prevFilterItems = usePrevious(filterItems);
  const changedItemKeyRef = useRef<React.Key | null>(null);

  const [itemsList, setItemsList] = useState<FilterItems>([]);
  const [canAdd, setCanAdd] = useState<boolean>(false);

  useEffect(() => {
    filterItems !== undefined ? setItemsList(filterItems) : setItemsList([createNewItem()]);
  }, []);

  useEffect(() => {
    setCanAdd(!!itemsList?.[itemsList.length - 1]?.value);
  }, [itemsList]);

  useEffect(() => {
    if (filterItems !== undefined) {
      setItemsList(filterItems);
    } else {
      setItemsList([createNewItem()]);
    }
  }, [filterItems]);

  const onBlur = () => {
    setFilterItems(itemsList);
  };

  const addItem = useCallback(() => {
    if (canAdd) {
      const newItem = createNewItem();
      changedItemKeyRef.current = newItem.key;
      setItemsList([...itemsList, newItem]);
    }
  }, [canAdd, createNewItem, itemsList]);

  const removeItem = useCallback(
    (removingKey: React.Key) => {
      changedItemKeyRef.current = removingKey;
      const newItems = itemsList?.filter(({key}) => removingKey !== key) ?? [];
      setItemsList(newItems.length ? newItems : [createNewItem()]);
    },
    [createNewItem, itemsList],
  );

  const changeItem = useCallback(
    (changingKey: React.Key, newValue: string) => {
      changedItemKeyRef.current = changingKey;
      const newList = itemsList?.map(({value, key}) =>
        changingKey === key && value !== newValue
          ? {
              key,
              value: newValue.trim(),
            }
          : {value, key},
      );
      setItemsList(newList);
      setFilterItems(newList);
    },
    [itemsList, setItemsList, setFilterItems],
  );

  useKeyPress('Enter', addItem);

  useEffect(() => {
    if (prevFilterItems === itemsList || !onChange) {
      return;
    }

    const prevNipsLength = prevFilterItems?.length ?? 0;

    if (prevNipsLength < (itemsList?.length ?? 0)) {
      onChange?.(itemsList, {
        item: findItem(itemsList, changedItemKeyRef.current),
        actionType: FILTER_ACTION_TYPES.add,
      });
    }
    if (prevNipsLength > (itemsList?.length ?? 0)) {
      onChange?.(itemsList, {
        item: findItem(prevFilterItems, changedItemKeyRef.current),
        actionType: FILTER_ACTION_TYPES.remove,
      });
    }
    if (prevNipsLength === (itemsList?.length ?? 0)) {
      onChange?.(itemsList, {
        item: findItem(itemsList, changedItemKeyRef.current),
        actionType: FILTER_ACTION_TYPES.change,
      });
    }
    changedItemKeyRef.current = null;
  }, [itemsList, onChange, prevFilterItems]);

  return (
    <div>
      <div className={'grid grid-cols-1 gap-y-4'}>
        {itemsList?.map(({key, value}, idx) => {
          return (
            <MultipleFilterItem
              key={key}
              itemKey={key}
              idx={idx}
              removeItem={removeItem}
              value={value}
              label={itemLabel}
              hideLabel={idx !== 0}
              placeholder={itemPlaceholder}
              onChange={changeItem}
              preventUpdateOnError={preventUpdateOnError}
              disableValidation={disableValidation}
              disableErrorFeedback={disableErrorFeedback}
              min={min}
              max={max}
              inputWidth={inputWidth}
              onBlur={onBlur}
              testId={testId}
              validationPattern={validationPattern}
              setValidationError={setValidationError}
            />
          );
        })}
      </div>
      <div>
        <ButtonTertiary
          key={canAdd + 'addButton'}
          disabled={!canAdd || hasError}
          size={ButtonTertiarySize.SM}
          text={addButtonLabel}
          onClick={addItem}
          testId={`${testId}-AddButton`}
        />
      </div>
    </div>
  );
};
