import {
  AcceptDecreeDocument,
  AcceptDecreeMutation,
  AcceptDecreeMutationVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const AcceptDecreesMutationFunction = async (companyId: string, decreeIds: string[]) => {
await apolloClient
    .query<AcceptDecreeMutation, AcceptDecreeMutationVariables>({
      query: AcceptDecreeDocument,
      context: {
        envId: companyId,
      },
      variables: {
        UserId: companyId,
        DecreeIds: decreeIds,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data?.AcceptDecree.IsDone ?? false;
    });
    };
