import {useIntl} from 'react-intl';
import {GlobalLoader} from '../../../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  InitializationStatusEnum,
  InitializationTaskTypeEnum,
  useOnAutoPostingResetInitializationCompletedSubscription,
  useResetModuleInitializationMutation,
} from '@symfonia-ksef/graphql';
import React, {useCallback, useEffect, useState} from 'react';
import {ApolloError} from '@apollo/client';
import {observer} from 'mobx-react-lite';
import {IPostingInitializationState} from './IPostingInitializationState';
import {Breadcrumbs, ButtonPrimary, Icon, IconColor, IconSvg, ToastVariant} from '@symfonia/brandbook';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {Header} from '../../../../../root/components/Header';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export interface PostingActivationErrorPageProps {
  state: IPostingInitializationState;
}

export const PostingActivationErrorPage = observer(({state}: PostingActivationErrorPageProps) => {
  const [errorTitle, setErrorTitle] = useState<string>('genericError');
  const [errorContent, setErrorContent] = useState<string>('');
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [resetStarted, setResetStarted] = useState<boolean>(false);
  const [hasResetFailed, setHasResetFailed] = useState<boolean>(false);
  const addSnackBarMessage = earchiveState.alertsState.addSnackBarMessage;

  const [skip, setSkip] = useState(true);

  const [resetModuleInitialization] = useResetModuleInitializationMutation({context: {envId: earchiveState.company.companyId}});

  const {data} = useOnAutoPostingResetInitializationCompletedSubscription({
    context: {envId: earchiveState.company.tenantId ?? ''},
    variables: {CompanyId: earchiveState.company.companyId},
    skip,
  });

  useEffect(() => setSkip(false), []);

  useEffect(() => {
    if (data && data.onAutoPostingResetInitializationCompleted) {
      if (!data.onAutoPostingResetInitializationCompleted.hasFailed) {
        state.setInitializationStatus(InitializationStatusEnum.NotInitialized);
      } else {
        setHasResetFailed(true);
        setPageLoading(false);
      }
    }
  }, [data]);

  const onError = useCallback(
    (error?: ApolloError) => {
      setPageLoading(false);
      addSnackBarMessage(error?.message, ToastVariant.ERROR);
    },
    [addSnackBarMessage, setPageLoading],
  );

  const resetInitialization = useCallback(() => {
    setPageLoading(true);
    resetModuleInitialization({})
      .then((res: any) => {
        setPageLoading(true);
        setResetStarted(true);
      })
      .catch((error: ApolloError) => onError(error));
  }, [resetModuleInitialization, pageLoading]);

  useEffect(() => {
    switch (state.taskType) {
      case InitializationTaskTypeEnum.IsDesktopConnected:
        setErrorTitle(Tr.agentUnavailableErrorTitle);
        setErrorContent(Tr.agentUnavailableErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveContractors:
        setErrorTitle(Tr.externalContractorsErrorTitle);
        setErrorContent(Tr.externalContractorsErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveFinancialYears:
        setErrorTitle(Tr.externalFinancialYearsErrorTitle);
        setErrorContent(Tr.externalFinancialYearsErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveAccounts:
        setErrorTitle(Tr.externalAccountsErrorTitle);
        setErrorContent(Tr.externalAccountsErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveDictionaries:
        setErrorTitle(Tr.externalDictionariesErrorTitle);
        setErrorContent(Tr.externalDictionariesErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveDictionaryElements:
        setErrorTitle(Tr.externalDictionaryElementsErrorTitle);
        setErrorContent(Tr.externalDictionaryElementsErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveVatRegistries:
        setErrorTitle(Tr.externalVatRegistryElementsErrorTitle);
        setErrorContent(Tr.externalVatRegistryElementsErrorContent);
        return;
      case InitializationTaskTypeEnum.SaveDocumentTypes:
        setErrorTitle(Tr.externalDocumentTypeElementsErrorTitle);
        setErrorContent(Tr.externalDocumentTypeElementsErrorContent);
        return;
      default:
        setErrorTitle('genericError');
        setErrorContent('');
        return;
    }
  }, [state.initializationStatus]);

  const intl = useIntl();
  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.config}),
    },
  ];

  return (
    <>
      {pageLoading && <GlobalLoader/>}
      <PageContent>
        <div className="flex flex-col gap-2 lg:ml-[40px] lg:w-[60%]">
          <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
          <Header id={'postingActivationErrorConfigurationTitle'}
                  className={'flex items-start'}>{intl.formatMessage({id: Tr.postingConfiguration})}</Header>
          <p className="mt-[20px]">{intl.formatMessage({id: Tr.postingActivation})}</p>
          <div className={'mt-[20px] flex items-center'}>
            <Icon svg={IconSvg.CANCEL} color={IconColor.RED_500}/>
            {errorTitle !== '' &&
              <p className="text-red-400 m-0 font-bold ml-[10px]">{intl.formatMessage({id: Tr[errorTitle as Tr]})}</p>}
          </div>
          {(!resetStarted || !hasResetFailed) && errorContent !== '' &&
            <p className="text-red-400 m-0 mt-[10px]">{intl.formatMessage({id: Tr[errorTitle as Tr]}, {br: <br/>})}</p>
          }
          {!hasResetFailed &&
            <ButtonPrimary
              text={intl.formatMessage({id: Tr.repeatActivation})}
              onClick={resetInitialization}
              className={'mt-[20px]'}/>
          }
          {/*{!hasResetFailed && resetStarted &&*/}
          <p className=" m-0 mt-[10px]">{intl.formatMessage({id: Tr.resetStarted})}</p>
          {/*}*/}
          {hasResetFailed &&
            <p className=" m-0 mt-[10px]">
              {/*// @ts-ignore*/}
              {intl.formatMessage({id: Tr.resetFailed}, {b: (x) => <strong>{x}</strong>})}</p>
          }
        </div>
      </PageContent>
    </>
  );
});
