import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import {Tr} from '../../../../../../../../../../locales/translationKeys';
import {FC, useState} from "react";
import {FormattedMessage} from "react-intl";
import {MoveCategoryRulesModal} from "./MoveCategoryRulesModal";
import {ICategoryAccountState} from "../state/ICategoryAccountState";

interface DeleteCategoryWithCategoryRulesModalProps {
  categoryId: string,
  categoryName: string,
  open: boolean;
  onDelete: () => void
  onCancel: () => void;
  state: ICategoryAccountState
}

export const DeleteCategoryWithCategoryRulesModal: FC<DeleteCategoryWithCategoryRulesModalProps> = ({
    categoryId,
    categoryName,
    // categoryRules,
    open,
    onDelete,
    onCancel,
    state}) => {
  const [openMoveCategoryRulesOpenDialog, setMoveCategoryRulesOpenDialog] = useState<boolean>(false);

  const handleCancel = () => {
    onCancel();
  };

  const moveCategoryRulesAndRemoveCategory = () => {
    setMoveCategoryRulesOpenDialog(true);
    onCancel();
  };

  return (<>
    <MoveCategoryRulesModal
      categoryId={categoryId}
      categoryName={categoryName}
      // categoryRules={categoryRules}
      open={openMoveCategoryRulesOpenDialog}
      onCancel={() => setMoveCategoryRulesOpenDialog(false)}
      state={state}
    />
    {open &&
      <Dialog onClose={() => onCancel()} open={open} fullWidth={true}>
        <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', textTransform: 'uppercase'}}>
          <FormattedMessage id={Tr.deleteCategoryWithCategoryRulesHeader}/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign: 'center', color: 'black'}}>
            <Grid>
              <Grid>
                <Typography sx={{pt: 1}}>
                    <FormattedMessage id={Tr.deleteCategoryWithCategoryRulesDescription}/>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={'auto'} sx={{pt: 3}}>
              <Button className="btn" sx={{width: '35%'}}
                      onClick={onDelete}
                      variant="contained"
                      autoFocus>
                <FormattedMessage id={Tr.deleteCategoryWithCategoryRules}/>
              </Button>
              <Button className="btn" sx={{width: '35%'}}
                      onClick={moveCategoryRulesAndRemoveCategory}
                      variant="outlined"
                      autoFocus>
                <FormattedMessage id={Tr.moveCategoryRules}/>
              </Button>
              <Button className="btn"
                      onClick={handleCancel}
                      variant="outlined"
                      autoFocus>
                <FormattedMessage id={Tr.cancelButton}/>
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    }
  </>);
};
