import {BaseEventConsumer} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {envObserver, grantPermissionsService} from '../../state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';
import {FeedbackType, grantPermissionsFeedbackFactory} from '../helpers/grantPermissionsHelpers';


export class GrantPermissionsEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.GrantPermission, grantPermissionsService, {
      success: () => grantPermissionsFeedbackFactory(envObserver, FeedbackType.Success, grantPermissionsService.errorType, grantPermissionsService.result ?? undefined)(),
      error: () => grantPermissionsFeedbackFactory(envObserver, FeedbackType.Error, grantPermissionsService.errorType, grantPermissionsService.result ?? undefined)(),
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    return super.onUpdate(event, async () => await grantPermissionsService.getAsyncData(event));
  }
}
