import {
  AuthorizeInKSeFWithTokenDocument,
  AuthorizeInKSeFWithTokenMutation,
  AuthorizeInKSeFWithTokenMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {EventJobRunner} from '../EventJobRunner';
import {intl} from '../../modules/root/IntlProvider';
import {Tr} from '../../locales/translationKeys';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {AppRoutes} from '../../modules/root/Routes';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface AuthorizeInKSeFWithTokenJobRunnerI {
  setLoading(isLoading: boolean): void;
}

export class AuthorizeInKSeFWithTokenJobRunner extends EventJobRunner<AuthorizeInKSeFWithTokenMutationVariables, keyof AuthorizeInKSeFWithTokenMutation> implements AuthorizeInKSeFWithTokenJobRunnerI {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('AuthorizeInKSeFWithToken', WebsocketNotificationType.AuthorizeInKsefExternalToken, AuthorizeInKSeFWithTokenDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => {
          return {
            notification: intl.formatMessage({id: Tr.authSubTokenInProgress}, {
              link: React.createElement(NavLink, {
                children: intl.formatMessage({id: Tr.authentication}),
                to: AppRoutes.eArchive.address + '/' + this.envObserver.currentEnv.tenantId + '/company/' + this.envObserver.currentEnv.companyId + '/account/authentication',
              }),
            }),
          };
        },
        error: (errors, errorMessage) => ({
          id: Tr.authSubTokenError,
        }),
      },
    );
  }
}
