import {ButtonPrimary, ButtonPrimaryWidth} from '@symfonia/brandbook';
import {getModalMaxWidth} from '../../../helpers/windowSizeHelper';
import clsx from 'clsx';
import * as React from 'react';
import {FC, PropsWithChildren} from 'react';

export type AccessGuardFallbackAction = {name: string; action: () => void};

export type AccessGuardProps = {
  hasAccessPermission: boolean;
  title: string;
  description?: string;
  fallbackActions?: AccessGuardFallbackAction[];
} & PropsWithChildren;

export const AccessGuard: FC<AccessGuardProps> = ({
  hasAccessPermission,
  children,
  title,
  description,
  fallbackActions,
}) => {
  const modalContentSize = React.useMemo<string>(() => {
    return getModalMaxWidth();
  }, []);

  const buttons =
    fallbackActions?.length &&
    fallbackActions.map(({action, name}, index) => (
      <ButtonPrimary key={index} onClick={action} width={ButtonPrimaryWidth.BASE} text={name}/>
    ));

  if (hasAccessPermission) {
    return <>{children}</>;
  }

  return (
    <div
      className="flex items-center justify-center"
      role="presentation"
    >
      <div
        className="flex p-[1rem] m-[32px] w-auto max-w-[calc(100%-64px)] max-h-[calc(100%-64px)] rounded-[5px] shadow-[0px_11px_15px_-7px_rgba(0,0,0,0.2),0px_24px_38px_3px_rgba(0,0,0,0.14),0px_9px_46px_8px_rgba(0,0,0,0.12)]"
        role="dialog"
      >
        <div className={clsx(modalContentSize, "px-[32px] pt-[32px] w-fit min-w-[416px] overflow-x-hidden pb-[32px]")}>
          <div className="flex w-full z-[2] top-0 pb-[24px]">
            <div className="grow pr-[32px] font-bold text-[20px] leading-[24px] font-quicksand items-center">
              {title}
            </div>
          </div>
          <div className="pt-[24px] font-quicksand text-[16px] leading-[24px]">
            {description}
          </div>
          <div className="z-[0]">
            <div className="mt-[20px] flex justify-center">
              {buttons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
