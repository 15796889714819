import {action, makeObservable, observable} from 'mobx';
import {PackageStatisticsItemFragment, SubscriptionStatus} from '@symfonia-ksef/graphql';

export class PackageStatisticsModel implements PackageStatisticsInterface {

  @observable
  public Status?: SubscriptionStatus;

  @observable
  public CurrentInvoicesCount?: number;

  @observable
  public CurrentStorageCount?: number;

  @observable.ref
  public DateTo?: Date;

  @observable
  public SerialNumber?: string;

  @observable
  public SubscriptionNumber?: string;

  @observable
  public CustomerNumber?: string;

  @observable
  public Email?: string;

  @observable.ref
  public Items?: Package[];

  constructor(initialState?: PackageStatisticsState) {
    makeObservable(this);
    initialState && this.set(initialState);
  }

  @action.bound
  public set(state: PackageStatisticsState) {
    Object.entries(state).forEach(([key, value]) => {
      this[key as keyof PackageStatisticsState] = value;
    });
  }

  @action.bound
  public reset() {
    for (const key in this as PackageStatisticsState) {
      this[key as keyof PackageStatisticsState] = undefined;
    }
  }
}


export type Package = Omit<PackageStatisticsItemFragment, 'PackageValue'> & { PackageValue?: number }

export interface PackageStatisticsState {
  Status?: SubscriptionStatus;
  CurrentInvoicesCount?: number;
  CurrentStorageCount?: number;
  DateTo?: Date;
  SerialNumber?: string;
  SubscriptionNumber?: string;
  CustomerNumber?: string;
  Email?: string;
  Items?: Package[];
}

export interface PackageStatisticsInterface extends PackageStatisticsState {
  set(state: PackageStatisticsState): void;

  reset(): void;
}



