import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
  DataSourceHandlerI,
  GetVariablesParams,
} from '../../../../../../../../root/services/TableServices/DataSourceTableService';
import {
  AccountViewModel,
  CategoryViewModel,
  GetCategoriesAccountsDocument,
  GetCategoriesAccountsQuery,
  GetCategoriesAccountsQueryVariables,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {GraphQLErrorWithMessage} from '../../../../../../../../root/providers/GraphQLProvider';
import {l} from '../../../../../../../../logger/loggerInstance';
import {intl} from '../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {TableSortDirection, ToastVariant} from '@symfonia/brandbook';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {filterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {BuildFilters, BuildSortOption} from '../../../../../../../../common/filters/FilterOptionExtensions';
import {envObserver, earchiveState} from '@symfonia-ksef/state/rootRepository';

export type CategoryAccountRow = Omit<CategoryViewModel, 'Category'> & {
  AccountKup: Array<AccountViewModel>;
  AccountNKup: Array<AccountViewModel>;
};

export type CategoryAccountRepositoryI = BaseTableDataRepositoryServiceI<
    keyof GetCategoriesAccountsQuery,
    GetCategoriesAccountsQuery,
    GetCategoriesAccountsQueryVariables
  >

export class CategoryAccountRepository extends BaseTableDataRepositoryService<
  'GetCategoriesViewModel',
  GetCategoriesAccountsQuery,
  GetCategoriesAccountsQueryVariables
> {
  constructor(private readonly categoryAccountState: ICategoryAccountState) {
    super('GetCategoriesViewModel', GetCategoriesAccountsDocument, envObserver, earchiveState);
  }

  public setFilters(filterFields: Record<string, filterType>): this {
    return this.configure({FilterFields: BuildFilters(filterFields)});
  }

  protected override handleEnvIdChange(envId: string | null): void {
    super.handleEnvIdChange(envId);
    envId && this.configure({CompanyId: envId});
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.configure({
      FinancialYearId: this.categoryAccountState.postingConfigurationState.selectedFinancialYear,
    });
    this.setFilters(this.categoryAccountState.filterState.getActiveFiltersForQuery);
  }

  protected override onSuccess(data: GetCategoriesAccountsQuery['GetCategoriesViewModel']): void | Promise<void> {
    data && this.categoryAccountState.tableState.setData(data.items as CategoryViewModel[]);
    data && this.categoryAccountState.tableState.setDataWithoutFilters(data.items as CategoryViewModel[]);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    l.error('Error when loading category account table', undefined, errors);

    const {addAlert} = earchiveState.alertsState;
    addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {
      displayDuration: 1000,
      omitIfHasTheSameAlert: true,
    });
    this.categoryAccountState.setIsLoading(false);
  }
}

export class CategoryAccountDataSourceHandler
  implements
    DataSourceHandlerI<
      'GetCategoriesViewModel',
      GetCategoriesAccountsQuery,
      GetCategoriesAccountsQueryVariables,
      CategoryAccountRow
    >
{
  public readonly repository!: CategoryAccountRepository;

  constructor(state: ICategoryAccountState) {
    this.repository = new CategoryAccountRepository(state);
  }

  public dataMapper(data: GetCategoriesAccountsQuery['GetCategoriesViewModel'] | null): CategoryAccountRow[] {
    const newArray = data?.items?.map(
      ({Id, Accounts, ...rest}) =>
        ({
          Id: Id,
          AccountKup: Accounts,
          AccountNKup: Accounts,
          ...rest,
        }) as CategoryAccountRow,
    );

    return newArray ?? [];
  }

  public getTotalCount(data: GetCategoriesAccountsQuery['GetCategoriesViewModel']): number {
    return data?.totalCount ?? 0;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<GetCategoriesAccountsQueryVariables> {
    const direction = params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc;
    const name = params.sortBy?.name ?? '';

    return {
      Take: params.size,
      Skip: params.offset,
      Order: BuildSortOption(name, direction),
    };
  }
}
