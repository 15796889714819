import {observer} from 'mobx-react-lite';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {InvoiceTypeGroupEnum, PostingStatusEnum} from '@symfonia-ksef/graphql';
import {PostingDetailsHeader} from './components/PostingDetailsHeader/PostingDetailsHeader';
import {PostingDetailsBody} from './components/PostingDetailsBody/PostingDetailsBody';
import {IPostingState} from '../../state/IPostingState';
import {UnavailableSendToFKModal} from './modals/UnavailableSendToFKModal';
import {
  Breadcrumbs,
  ButtonPrimary,
  ButtonPrimaryWidth,
  ButtonTertiary,
  DropdownWidth,
  IconColor,
  IconSize,
  IconSvg,
  Tooltip,
} from '@symfonia/brandbook';
import {Dropdown} from '@symfonia/symfonia-ksef-components';
import {PostingDetailsActions} from './components/PostingDetailsActions';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {usePageTitle} from '../../../common';

export interface PostingDetailsProps {
  state: IPostingState;
}

export const PostingDetails = observer(({state}: PostingDetailsProps) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();
  const navigate = useNavigate();
  const postingDetailsState = state.postingDetailsState;
  const decreeStatusEnum = [PostingStatusEnum.Posted, PostingStatusEnum.Incomplete];
  const selectedDecreeStatus = postingDetailsState.postingDetailsStore.postingDetails?.PostingStatus;
  const onClickBack = useCallback(() => navigate('../'), []);
  const [showDetails, setShowDetails] = useState(false);
  const {posting, company} = earchiveState;

  const displayDownloadToFkButton = () => {
    return postingDetailsState.isPageEditableByStatus && !postingDetailsState.displayAcceptDecreeButton;
  };

  useEffect(() => {
    return () => {
      posting.set({postingId: null});
      postingDetailsState.resetButtons();
    };
  }, []);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.posting}),
    },
    {
      label: intl.formatMessage({id: Tr.decreeDetails}),
    },
  ];

  setPageTitle(intl.formatMessage({id: Tr.posting}));

  return (
    <>
      <div
        className={
          'px-[28px] mt-[20px] mx-auto max-w-[1920px] flex flex-col ' +
          "w-full justify-start items-start bg-white box-border ',\n" +
          "        {'lg:h-[calc(100vh_-_9rem)]': height > 780})"
        }
      >
        <UnavailableSendToFKModal
          open={postingDetailsState.isUnavailableSendToFKModal}
          onClose={() => postingDetailsState.setIsUnavailableSendToFKModal(false)}
        />

        <Breadcrumbs breadcrumbs={breadcrumbs}/>
        <ButtonTertiary
          className="my-[10px]"
          onClick={onClickBack}
          text={intl.formatMessage({id: Tr.backToDecreeList})}
          lIcon={IconSvg.ARROW_BACK_IOS}
          iconOptions={{size: IconSize.SM, className: 'mr-[5px]', color: IconColor.GREEN_500}}
          testId="postingDetails-backButton"
        />
        <div className={'flex w-full md:flex-row flex-col md:items-end items-start justify-between mb-[20px]'}>
          <h1 className={'text-[32px] font-bold pb-[8px]'}>
            <FormattedMessage
              id={Tr.invoiceWithTypeGroupAndNumber}
              values={{
                strong: (chunks: any) => <strong>{chunks}</strong>,
                invoiceTypeTranslation:
                  postingDetailsState.postingDetailsStore.postingDetails?.InvoiceTypeGroup ===
                  InvoiceTypeGroupEnum.Sales
                    ? intl.formatMessage({id: Tr.invoiceSALES})
                    : intl.formatMessage({id: Tr.invoicePURCHASE}),
                number: postingDetailsState.postingDetailsStore.postingDetails?.InvoiceNumber,
              }}
            />
          </h1>

          <div className="flex md:justify-start justify-between items-end md:w-auto w-full relative">
            <div className="flex justify-start items-start flex-col flex-nowrap mr-[20px]">
              {selectedDecreeStatus === PostingStatusEnum.Incomplete ? (
                <Dropdown
                  width={DropdownWidth.FULL}
                  label={intl.formatMessage({id: Tr.decreeStatus})}
                  defaultValue={[postingDetailsState.defaultValueDropdownStatus]}
                  disabled={postingDetailsState.isSaveInProgress}
                  multiple={false}
                  options={postingDetailsState.dropdownStatusOptions}
                  placeholder="Wybierz z listy"
                  onChange={value => {
                    postingDetailsState.setIsAnyChangeInDetails(true);
                    postingDetailsState.changeStatus(decreeStatusEnum[value[0] as number] as PostingStatusEnum);
                  }}
                  testId="postingDetails-decreeStatusDropdown"
                />
              ) : (
                <p className={'font-quicksand text-base font-medium'}>
                  {intl.formatMessage({id: Tr.decreeStatus})}
                  <span className={'whitespace-nowrap ml-[20px] font-bold'}>
                    {intl.formatMessage({id: Tr[selectedDecreeStatus ?? 'gridNoData']})}
                  </span>
                </p>
              )}
            </div>
            {displayDownloadToFkButton() && (
              <ButtonPrimary
                disabled={postingDetailsState.isAnyChangeInDetails || postingDetailsState.isSaveInProgress}
                text={intl.formatMessage({id: Tr.downloadToFK})}
                onClick={() => postingDetailsState.onSendToFK()}
                width={ButtonPrimaryWidth.BASE}
                lIcon={IconSvg.LOOP}
                testId="postingDetails-sendToFKButton"
              />
            )}
            {postingDetailsState.displayAcceptDecreeButton && (
              <Tooltip
                text={<FormattedMessage id={Tr.cantAcceptOwnDecree} values={{br: <br/>}}/>}
                position={'bottom-end'}
              >
                <div>
                  <ButtonPrimary
                    disabled={
                      (!postingDetailsState.canAcceptOwn && company.userId === state.selectedDecree?.CreatedBy) ||
                      postingDetailsState.isAnyChangeInDetails
                    }
                    text={intl.formatMessage({id: Tr.acceptDecree})}
                    onClick={() => postingDetailsState.acceptDecrees()}
                    width={ButtonPrimaryWidth.FULL}
                    testId="postingDetails-acceptDecreesButton"
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <PostingDetailsHeader state={postingDetailsState} setShowDetails={setShowDetails} showDetails={showDetails}/>
        <PostingDetailsBody state={postingDetailsState} showDetails={showDetails}/>
      </div>
      <PostingDetailsActions state={postingDetailsState}/>
    </>
  );
});
