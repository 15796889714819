import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker as MuiDatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs, {Dayjs} from 'dayjs';
import {Box, Button, TextField} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {FC, useCallback} from 'react';
import CalendarIcon from '../../assets/img/ic_calendar.svg';
import {DateValidationError} from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import {observer} from 'mobx-react-lite';

export type OnChange = (newDate: dayjs.Dayjs | null) => void
export type ErrorTypes = DateValidationError | 'outOfRange' | 'emptyDate'

export interface DateInputProps {
  leftArrowButtonText?: string | undefined,
  rightArrowButtonText?: string | undefined,
  enableReturnButton?: boolean | undefined,
  showReturn?: boolean | undefined,
  disabled?: boolean | undefined,
  returnLabel?: string | undefined,
  resetDate?: (value: string | null, e: React.MouseEvent) => void | undefined,
  value: string | null,
  dateInputLabel?: string,
  onChange: OnChange,
  cantBeEmpty?: boolean
  minDate?: dayjs.Dayjs | undefined,
  maxDate?: dayjs.Dayjs | undefined,
  onError?: (reason: ErrorTypes, value: string | null) => void,
  errorMessage?: string | undefined,
  isError?: boolean,
  onOpen?: (data?: { value: string | null, minDate: Dayjs | undefined, maxDate: Dayjs | undefined }) => void
}

function DateIcon() {
  return (
    <img alt="calendar" src={CalendarIcon} style={{width: 20}}/>
  );
}


export const DateInput: FC<DateInputProps> = observer(({
                                                         leftArrowButtonText,
                                                         rightArrowButtonText,
                                                         enableReturnButton,
                                                         showReturn,
                                                         resetDate,
                                                         returnLabel,
                                                         disabled,
                                                         onError,
                                                         errorMessage,
                                                         value,
                                                         minDate,
                                                         maxDate,
                                                         dateInputLabel,
                                                         onChange,
                                                         isError,
                                                         onOpen,
                                                         cantBeEmpty,
                                                       }) => {

  const handleOnOpen = useCallback(() => {
    if (onOpen) {
      onOpen({value, minDate, maxDate});
    }
    if (!value) {
      return;
    }
    if (minDate && dayjs(value).isBefore(minDate)) {
      onChange(minDate);
      return;
    }
    if (maxDate && dayjs(value).isAfter(maxDate)) {
      onChange(maxDate);
      return;
    }
  }, [onOpen, minDate, maxDate, value, onChange]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
      <MuiDatePicker
        onOpen={handleOnOpen}
        leftArrowButtonText={leftArrowButtonText}
        rightArrowButtonText={rightArrowButtonText}
        disabled={disabled}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onError={onError}
        inputFormat="DD-MM-YYYY"
        onChange={onChange}
        openTo="day"
        views={['year', 'month', 'day']}
        components={{
          OpenPickerIcon: DateIcon,
        }}
        InputProps={{
          placeholder: dateInputLabel,
        }}
        renderInput={(params) => (
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', pl: 0}}>
            <TextField
              {...params}
              size="small"
              id="start-date"
              type="date"
              helperText={errorMessage}
              classes={{root: 'TextFieldInput'}}
              error={isError || params.error}
              inputProps={{
                ...params.inputProps, placeholder: dateInputLabel,
              }}
            />
            {enableReturnButton && showReturn && <Button variant="text" sx={{ml: 0, pl: 0}}
                                                         className="mx-0 px-0 font-green-500"
                                                         onClick={(e) => resetDate?.(value, e)}>{returnLabel}</Button>
            }
          </Box>
        )}
      />
    </LocalizationProvider>
  );
});
