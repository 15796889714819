import {UPODataModel} from "../../../../../services/helpers/NotificationDataParsers";
import {UPOModelToMap} from "../services/KSeFEventsConverters/UpoEventConverter";
import {UpoFileType} from "@symfonia-ksef/graphql";

export class UpoModelMapper {
    static upoFileType: Record<UpoFileType, number> = {
        [UpoFileType.Pdf]: 0,
        [UpoFileType.Xml]: 1,
      };
      
    static upoFileTypeMap = new Map<number, UpoFileType>(Object.entries(UpoModelMapper.upoFileType).map(entry => entry.reverse() as [number, UpoFileType]));

    public static map({
      fileType,
      ...rest
    }: UPOModelToMap): UPODataModel {
      return ({
        ...rest,
        fileType: UpoModelMapper.getMappedUpoFileType(fileType),
      });
    }

    private static getMappedUpoFileType(key: number): UpoFileType {
      return UpoModelMapper.upoFileTypeMap.get(key) ?? UpoFileType.Pdf;
    }    
}