import {FC, useState} from 'react';
import {DocumentTypeSettingTypeEnum, InvoiceDocumentTypeResponse} from '@symfonia-ksef/graphql';
import {intl} from '../../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonTertiary, Dropdown, DropdownListOption, IconSvg, Input, InputWidth} from '@symfonia/brandbook';

interface DocumentTypeSettingsListElementProps {
  documentTypes: InvoiceDocumentTypeResponse | undefined;
  options: DropdownListOption[];
  label: string;
  documentTypeSettingType: DocumentTypeSettingTypeEnum;
  documentTypeSetting?: DropdownListOption;
  required?: boolean;
  disabled?: boolean;
  onDocumentTypeChange: (settingType: DocumentTypeSettingTypeEnum, documentTypeId: string | null) => void;
}

export const DocumentTypeSettingsListElement: FC<DocumentTypeSettingsListElementProps> = ({
  disabled,
  documentTypes,
  documentTypeSetting,
  documentTypeSettingType,
  label,
  onDocumentTypeChange,
  options,
  required,
}) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>(documentTypeSetting?.value ?? '');
  
  if (options.length === 0) return null;

  const handleClear = () => {
    onDocumentTypeChange(documentTypeSettingType, null);
    setCurrentValue(undefined);
  };

  return (
    <div className="flex sm:flex-row flex-col items-center my-3 w-full gap-y-4">
      <div className="lg:w-1/2 sm:w-[180px] w-full items-end flex md:mr-8 sm:mr-12">
        <Dropdown
          disabled={disabled}
          label={intl.formatMessage({id: label})}
          options={options}
          onChange={(value: string[]) => {
            onDocumentTypeChange(documentTypeSettingType, options.find(x => x.value === value[0])?.value ?? null);
            setCurrentValue(value[0]);
          }}
          value={[currentValue ?? '']}
          placeholder={intl.formatMessage({id: Tr.DocumentTypes})}
          required={required}
          className={'md:w-[250px] sm:w-[170px] w-full'}
        />
        <ButtonTertiary
          lIcon={IconSvg.CLEAR}
          onClick={handleClear}
          className={!required && documentTypeSetting?.value !== undefined && currentValue !== undefined ? 'mb-[10px] ml-[3px] mr-[3px] lg:mr-0 ' : 'hidden'}
        />
      </div>
      <div className="lg:w-1/2 sm:w-[180px] w-full self-end">
        <Input
          disabled
          label={intl.formatMessage({id: Tr.vatRegister})}
          placeholder={intl.formatMessage({id: Tr.vatRegister})}
          className={'md:w-[250px] sm:w-[170px] w-full'}
          width={InputWidth.FULL}
          value={intl.formatMessage({id: documentTypes?.VatRegistryName ?? Tr.noaction})}
        />
      </div>
    </div>
  );
};