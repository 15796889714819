import {FC, useState} from 'react';
import {Controller, useForm, useFormState} from 'react-hook-form';
import {useIntl} from 'react-intl';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Tr} from '../../../locales/translationKeys';
import {Input, InputWidth} from '@symfonia/brandbook';
import {DialogModal} from '@symfonia/symfonia-ksef-components';


const TokenValidationSchema = Yup.object().shape({
  token: Yup.string()
    .trim()
    .required('Pole wymagane')
    .min(64, 'Nieprawidłowy format tokena')
    .max(64, 'Nieprawidłowy format tokena')
    .matches(/[0-9A-Fa-f]{64}/, 'Nieprawidłowy format tokena'),
});

export interface AuthorizationByTokenModalProps {
  onConfirm: (token: string) => Promise<void>;
  onCancel: () => void;
  open: boolean;
}

export const AuthorizationByTokenModal: FC<AuthorizationByTokenModalProps> = ({onConfirm, onCancel, open}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const {control, handleSubmit, getValues, reset} = useForm({
    defaultValues: {token: ''},
    mode: 'onChange',
    resolver: yupResolver(TokenValidationSchema),
  });
  const {isValid} = useFormState({control});

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm(getValues('token'));
    reset();
    setLoading(false);
  };

  const handleCancel = async () => {
    reset();
    onCancel();
  };

  return (
    <DialogModal 
      onCancel={onCancel} 
      open={open} 
      title={intl.formatMessage({id: Tr.writeToken})}
      loadingButton={{text: intl.formatMessage({id: Tr.connect}), onClick: handleSubmit(handleConfirm), disabled: !isValid, loading: loading}}
      cancelButton={{text: intl.formatMessage({id: Tr.cancelButton}), onClick: handleCancel, disabled: loading}}
      >
      <Controller
        name="token"
        control={control}
        render={({field}) => (
          <Input
            width={InputWidth.FULL}
            onInput={value => {
              field.onChange(value);
            }}
            value={getValues().token}
          />
        )}
      />

    </DialogModal>
  );
};
