import {FC} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonSecondary} from '@symfonia/brandbook';

export interface DisconnectWithKSeFButtonProps {
  handleButtonClick: () => void;
}

export const DisconnectWithKSeFButton: FC<DisconnectWithKSeFButtonProps> = ({handleButtonClick}) => {
  const intl = useIntl();

  return (
    <div className="my-[10px] w-[150px]">
      <ButtonSecondary onClick={handleButtonClick} text={intl.formatMessage({id: Tr.disconnectWithKSeF})}/>
    </div>
  );
};
