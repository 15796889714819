export const europeanDateFormatter = (europeanDateString: string) => {
  const [dayStr, monthStr, yearStr] = europeanDateString.split('-');

  const day: number = parseInt(dayStr, 10);
  const month: number = parseInt(monthStr, 10);
  const year: number = (yearStr === undefined || yearStr.length < 4 ? NaN : parseInt(yearStr, 10));

  const europeanDate: Date = new Date(year, month - 1, day);

  return europeanDate;
};
