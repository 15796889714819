import {MenuAction} from '../../types/MenuActions';
import React, {FC, useMemo} from 'react';
import {Bottomsheet} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {MenuItemBtn} from '../MenuItem/MenuItem';

export type MobileContextualMenuProps = {
  isOpen: boolean,
  actions: Omit<MenuAction, 'tooltipProps'>[]
  onClose: () => void,
  header?: string
}

export const MobileContextualMenu: FC<MobileContextualMenuProps> = ({isOpen, actions, onClose, header}) => {

  const menuItems = useMemo<JSX.Element[]>(() => actions.filter(action => !action.hidden).map<JSX.Element>(({
                                                                                                              onClick,
                                                                                                              disabled,
                                                                                                              icon,
                                                                                                              label,
                                                                                                              loading,
                                                                                                              key,
                                                                                                            }) =>
    <MenuItemBtn
      key={key}
      disabled={disabled}
      icon={icon}
      text={label}
      loading={loading}
      onClick={() => {
        onClick();
        onClose();
      }}
    />,
  ), [actions, onClose]);

  return <>
    {isOpen && <Bottomsheet
      header={header ?? intl.formatMessage({id: Tr.bulkActions})}
      content={<>{menuItems}</>}
      onClose={onClose}
      className="top-0 left-0 z-[10000]"
    />}
  </>;
};
