import {IReactionDisposer, IReactionOptions, IReactionPublic, reaction} from 'mobx';
import {v4} from 'uuid';

export type StateSelector<T> = () => T
export type StateReactionListener<T, FireImmediately extends boolean = false> = (state: T, prevState: FireImmediately extends true ? T | undefined : T, reaction: IReactionPublic) => void

//Pozwala subskrybować się na zmianę dowlonego stanu z dowolnego miejsca aplikacji.
//W przeciwieństwie do useEffect nie wymaga wpięcia w cykl życia komponentu (może być użyte poza komponentem reactowym np w zwykłej klasie).
//Pozwala zarządzać subskrypcjami stanu i je monitorować - dodawać je, usuwać, anulować, uruchamiać itp
//Korzysta z interfejsu IReactionDisposer zwracanego z mobX-owej funkcji reaction()
export interface ReactionsManagerI {

  //liczba wszystkich aoraz aktywnych subskrypcji
  get count(): { active: number, all: number };

  //Dodaje i uruchamia subskrypcję stanu.
  //stateSelector: Obserwowana część stanu
  //listener: callback, który wowoła się gdy obserwowana część stanu zostanie zmieniona
  //opt: konfiguracja mobcowej funkcji 'reaction'
  // Wrappuje mobXową funkcję 'reaction'.
  // Zwraca disposer do anulowania subskrypcji
  register<T, FireImmediately extends boolean = false>(stateSelector: StateSelector<T>, listener: StateReactionListener<T, FireImmediately>, opt?: IReactionOptions<T, FireImmediately>): IReactionDisposer;

  //przerwanie subskrypcji o podanym kluczu
  dispose(key: string): boolean;

  //przerwanie wszystkich subskrypcji
  disposeAll(): void;

  //dodanie subskrypcji (nie powoduje jej uruchomienia!)
  //stateSelector: Obserwowana część stanu
  //listener: callback, który wowoła się gdy obserwowana część stanu zostanie zmieniona
  //opt: konfiguracja mobcowej funkcji 'reaction'
  // Wrappuje mobXową funkcję 'reaction'.
  //zwraca klucz subskrypcji potrzebny do zarządzania nią i monitorowania
  add<T, FireImmediately extends boolean = false>(stateSelector: StateSelector<T>, listener: StateReactionListener<T, FireImmediately>, opt?: IReactionOptions<T, FireImmediately>): string;

  //zupełne usunięcie subskrypcji o podanym kluczu
  remove(key: string): boolean;

  //usuwa wszystkie subskrypcje (akrywne i nieaktywne)
  removeAll(): void;

  //uruchamia subskrypcje o podanym kluczu
  run(key: string): boolean;

  //uruchamia wszystkie subskrypcje
  runAll(): void;

  //sprawdza czy subskrypcja o podanym kluczu istnieje
  has(id: string): boolean;

  //sprawdza czy subskrypcja o podanym kluczu jest aktywna
  isActive(id: string): boolean;
}

export class ReactionsManager implements ReactionsManagerI {
  private reactions: Record<string, () => IReactionDisposer> = {};
  private disposers: Record<string, IReactionDisposer> = {};

  constructor() {

  }

  public get count(): { active: number, all: number } {
    return {
      active: Object.keys(this.disposers).length,
      all: Object.keys(this.reactions).length,
    };
  }

  public register<T, FireImmediately extends boolean = false>(stateSelector: StateSelector<T>, listener: StateReactionListener<T, FireImmediately>, opt?: IReactionOptions<T, FireImmediately>): IReactionDisposer {
    const key = this.add(stateSelector, listener, opt);
    this.run(key);
    return this.disposers[key];
  }

  public dispose(key: string): boolean {
    if (key in this.disposers) {
      this.disposers[key]();
      delete this.disposers[key];
      return true;
    }
    return false;
  }

  public disposeAll(): void {
    for (const key in this.disposers) {
      this.dispose(key);
    }
  }

  public isActive(id: string): boolean {
    return id in this.disposers;
  }

  public has(id: string): boolean {
    return id in this.reactions;
  }

  public add<T, FireImmediately extends boolean = false>(stateSelector: StateSelector<T>, listener: StateReactionListener<T, FireImmediately>, opt?: IReactionOptions<T, FireImmediately>): string {
    const key = v4();
    this.reactions[key] = () => reaction<T, FireImmediately>(stateSelector, listener, opt);
    return key;
  }

  public run(key: string): boolean {
    if (key in this.reactions && !(key in this.disposers)) {
      this.disposers[key] = this.reactions[key]();
      return true;
    }
    return false;
  }

  public removeAll(): void {
    for (const key in this.reactions) {
      this.remove(key);
    }
  }

  public remove(key: string): boolean {
    this.dispose(key);
    if (key in this.reactions) {
      delete this.reactions[key];
      return true;
    }
    return false;
  }

  public runAll(): void {
    this.disposeAll();
    Object.keys(this.reactions).forEach(key => this.run(key));
  }
}
