import {FC} from 'react';
import {TogglableGroup, TogglableGroupProps} from '../../internal/components/Togglable/TogglableGroup';
import {getAppearanceInputStyles, RadioButtonAppearance} from './components/RadioButtonAppearance';

export {
  TogglableGroupOrientation as RadioButtonGroupOrientation,
  TogglableGroupSize as RadioButtonGroupSize,
} from '../../internal/components/Togglable/TogglableGroup';

export type RadioButtonGroupProps = Omit<
  TogglableGroupProps,
  'appearance' | 'getAppearanceInputStyles' | 'isRadio' | 'testElement'
>;

/** testElement is "radio-button" as "-group" suffix is added by TogglableGroup */
export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({...props}) => (
  <TogglableGroup
    {...props}
    isRadio
    testElement="radio-button"
    appearance={<RadioButtonAppearance />}
    getAppearanceInputStyles={getAppearanceInputStyles}
  />
);
