import {ExtendedTableServiceI} from '../../../../root/services/TableServices/ExtendedTableService';
import {
  GetUserKSeFRequestForCredentialsQuery,
  GetUserKSeFRequestForCredentialsQueryVariables,
  KSeFRequestForCredentialsProcessingStatus,
  OrderDirection,
  RequestedCredentialsFragment,
} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DataSourceHandlerI, GetVariablesParams} from '../../../../root/services/TableServices/DataSourceTableService';
import React from 'react';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {PermissionsRegisterRepositoryI} from '../PermissionsRegister.repository';
import {DateRenderer} from '../../../../common';
import {StatusPill, StatusPillColor} from '../../../components/StatusPill/StatusPill';
import {TableSortDirection} from '@symfonia/brandbook';
import {PermissonsTableList} from './PermissonsTableList';

export type PermissionsRegisterRow = RequestedCredentialsFragment & { Id: string }

export type PermissionsRegisterColumns = Columns<PermissionsRegisterRow>

export type PermissionsRegisterTableI = ExtendedTableServiceI<PermissionsRegisterRow, 'GetUserKSeFRequestForCredentials', GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>

export class PermissionsRegisterDataSourceHandler implements DataSourceHandlerI<'GetUserKSeFRequestForCredentials', GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables, PermissionsRegisterRow> {
  constructor(public repository: PermissionsRegisterRepositoryI) {
  }

  public dataMapper(data: GetUserKSeFRequestForCredentialsQuery['GetUserKSeFRequestForCredentials'] | null) {
    if (data?.RequestedCredentials) {
      return data.RequestedCredentials.map(credential => ({
        ...credential,
        Id: credential.Identifier + credential.CredentialsSelfInvoicing,
      }));
    }
    return [];
  }

  public getTotalCount(data: GetUserKSeFRequestForCredentialsQuery['GetUserKSeFRequestForCredentials']): number {
    return data.FilteredCount;
  }

  public prepareVariablesConfig(params: GetVariablesParams) {
    const variables: Partial<GetUserKSeFRequestForCredentialsQueryVariables> = {};
    variables.skip = params.offset;
    variables.take = params.size;
    if (params.sortBy?.name) {
      variables.order = {
        FieldName: params.sortBy.name ?? '',
        Direction: params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc,
      };
    }
    return variables;
  }
}

class Helpers {
  public static getRequestedCredentials(row: PermissionsRegisterRow) {
    const permissionsList: string[] = [];
    Object.entries(row).map(([key, val]) => {
      if (val === true) permissionsList.push(intl.formatMessage({id: key}));
    });
    return permissionsList;
  }

  public static getStatus(row: PermissionsRegisterRow) {
    const statusSuccess = row.ProcessingStatus === KSeFRequestForCredentialsProcessingStatus.Finished;
    const isPendingStatus = [
      KSeFRequestForCredentialsProcessingStatus.SanityCheck,
      KSeFRequestForCredentialsProcessingStatus.Authorise,
      KSeFRequestForCredentialsProcessingStatus.Security,
      KSeFRequestForCredentialsProcessingStatus.Process,
      KSeFRequestForCredentialsProcessingStatus.Finalize,
    ].includes(row.ProcessingStatus);
    return {statusSuccess, isPendingStatus};
  }
}

export const permissionsRegisterTableKeysFactory = (row: PermissionsRegisterRow): string => row.Id;

export const permissionsRegisterInitialColumnsFactory = (): PermissionsRegisterColumns => ({
  Identifier: {
    header: intl.formatMessage({id: Tr.forWho}),
    width: 'w-[200px]',
    sortable: true,
    order: 1,
    cell: (row) => {
      return <span>{row.Identifier}</span>;
    },
  },
  CredentialsSelfInvoicing: {
    header: intl.formatMessage({id: Tr.permissions}),
    order: 2,
    sortable: false,
    width: 'w-[800px]',
    cell: (row) => {
      return <PermissonsTableList val={row}/>;
    },
  },
  RequestType: {
    header: intl.formatMessage({id: Tr.operation}),
    width: 'w-[200px]',
    sortable: true,
    order: 3,
    cell: (row) => {
      return <span>{intl.formatMessage({id: `permissionRegisterOperation${row.RequestType}`})}</span>;
    },
  },
  CreatedAt: {
    header: intl.formatMessage({id: Tr.dateOfOperation}),
    width: 'w-[150px]',
    sortable: true,
    order: 4,
    cell: (row) => {
      return <span>{DateRenderer({value: row.CreatedAt})}</span>;
    },
  },
  ProcessingStatus: {
    header: intl.formatMessage({id: Tr.KSeFStatuses}),
    sortable: true,
    width: 'w-[250px]',
    order: 5,
    cell: (row) => {
      const {statusSuccess, isPendingStatus} = Helpers.getStatus(row);
      return <StatusPill Id={row.Id}
                         color={statusSuccess ? StatusPillColor.Success : (isPendingStatus ? StatusPillColor.Warning : StatusPillColor.Error)}
                         label={
                           statusSuccess ? Tr.permissionRegisterStatus_SendToKsef : (isPendingStatus ? Tr.permissionRegisterStatus_Pending : Tr.permissionRegisterStatus_RejectedByKsef)
                         }/>;
    },
  },
});
