import {FC} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {TestableElement} from '../../external/types';

export enum BadgeSize {
  MD = 'MD',
  SM = 'SM',
}

export type BadgeProps = {
  text: string;
  size?: BadgeSize;
  className?: string;
} & TestableElement;

export const Badge: FC<BadgeProps> = ({text = '', size = BadgeSize.MD, className = undefined, testId = undefined}) => {
  return (
    <span
      data-test-element="badge"
      data-testid={testId}
      className={twMerge(
        clsx(
          'bg-red-500 whitespace-pre border-2 border-solid border-white box-content inline-flex justify-center items-center leading-none font-quicksand text-white font-bold overflow-hidden rounded-full',
          {
            'text-xs min-w-[16px] h-[16px] ': size === BadgeSize.MD,
            'text-[10px] min-w-[12px] h-[12px] ': size === BadgeSize.SM,
            'px-[4px]': size === BadgeSize.MD && text.length >= 3,
            'px-[2px]': size === BadgeSize.MD && text.length === 2,
            'px-[3px]': size === BadgeSize.SM && text.length >= 2,
          },
          className,
        ),
      )}
    >
      {text}
    </span>
  );
};
