import {EventJobRunner} from '../EventJobRunner';
import {
  WebsocketNotificationType,
  WhiteListCheckDocument,
  WhiteListCheckMutation,
  WhiteListCheckMutationVariables,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {Tr} from '@symfonia-ksef/locales/keys';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class CheckWhiteListJobRunner extends EventJobRunner<WhiteListCheckMutationVariables, keyof WhiteListCheckMutation> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('SubscribeWhiteListCheck', WebsocketNotificationType.WhiteListValidation, WhiteListCheckDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
      error: {
        id: Tr.whiteListCheckRunError,
      },
      success: {
        id: Tr.whiteListCheckInProgress,
      },
    });
  }

  public override checkIsReady(): boolean {
    super.checkIsReady();
    return !!this.variables.InvoiceIds?.length;
  }

}
