import {ReactNode} from 'react';
import {PrimitiveType} from 'react-intl';
import {Tr} from '../../locales/translationKeys';
import {intl} from '../../modules/root/IntlProvider';
import {ToastVariant} from '@symfonia/brandbook';
import {AlertType, GlobalAlertModel} from '@symfonia-ksef/state/EarchiveState/AlertsState';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export type AlertConfig = {
  notification?: ReactNode,
  id?: Tr,
  color?: ToastVariant,
  duration?: number | null,
  values?: Record<string, PrimitiveType | ReactNode>,
  snackbarMode?: boolean,
  onClick?: () => void,
  onClose?: () => void,
  moveToTopIfAlreadyExist?: boolean,
  type?: AlertType,
  preventAutoOnClose?: boolean,
  actions?: GlobalAlertModel['actions'],
  closeOnClick?: boolean,
  ignore?: boolean,
  timestamp?: number,
  omitIfHasTheSameAlert?: boolean,
  title?: string,
}

export const addAlert = ({
                           id,
                           color = ToastVariant.ERROR,
                           duration,
                           values,
                           notification,
                           snackbarMode,
                           ignore,
                           ...rest
                         }: AlertConfig): string | undefined => {
  if (ignore) {
    return;
  }
  const {addAlert, addSnackBarMessage} = earchiveState.alertsState;
  const message = notification ?? intl.formatMessage({id}, values);
  const alertId = snackbarMode ? addSnackBarMessage(message, color) : addAlert(message, color, {
    displayDuration: duration,
    ...rest,
  });
  return alertId ?? undefined;
};

export const removeAlert = (alertId: string) => earchiveState.alertsState.removeAlert(alertId);
