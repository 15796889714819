import {FC, PropsWithChildren, ReactNode, useMemo} from 'react';
import {ButtonPrimaryProps, ButtonSecondary, ButtonSecondaryProps, ButtonSecondaryWidth, Modal, ModalCloseActionSource} from '@symfonia/brandbook';
import clsx from 'clsx';
import {LoadingButton, LoadingButtonProps, LoadingButtonWidth} from '../LoadingButton/LoadingButton';
import {getModalMaxWidth} from '../../../helpers/windowSizeHelper';

export interface DialogModalProps extends PropsWithChildren {
  onConfirm?: () => void;
  onCancel: () => void;
  open: boolean;
  title?: string | ReactNode;
  content?: string;
  okButton?: ButtonPrimaryProps;
  cancelButton?: ButtonSecondaryProps;
  loadingButton?: LoadingButtonProps;
  centerButtons?: boolean;
  stickyButtons?: boolean;
  className?: string;
  setMaxWidth?: boolean;
  testId?: string;
}

export const DialogModal: FC<DialogModalProps> = ({
  onCancel,
  open,
  title = undefined,
  content = undefined,
  children,
  okButton = undefined,
  cancelButton = undefined,
  loadingButton = undefined,
  centerButtons = true,
  stickyButtons = false,
  className = undefined,
  setMaxWidth = true,
  testId = undefined,
}) => {
  const styles = {
    buttons: clsx('pt-[24px] flex gap-[16px]', {
      'pb-[32px] sticky bottom-0 bg-white': stickyButtons,
      'justify-end': !centerButtons,
      'justify-center': centerButtons
    }),
  };

  const onClose = (reason: string) => {
    if (reason !== "Backdrop") {
      onCancel();
    }
  };
  const handleButtonClick = (actionSource: ModalCloseActionSource, onClick?: () => void) => {
    if (onClick) {
      onClick();
    }
    onClose(actionSource);
  };

  const modalContentSize = useMemo<string>(() => {
    return setMaxWidth ? getModalMaxWidth() : '';
  }, [setMaxWidth]);

  return (
    <Modal onClose={onClose}
      testId={testId}
      show={open}
      title={title}
      text={content}
      className={modalContentSize + ' ' + className}
      centerButtons={centerButtons}
      stickyButtons={stickyButtons}
      okButton={loadingButton ? undefined : okButton}
      cancelButton={loadingButton ? undefined : cancelButton}>
        {children}
        {loadingButton && (
        <div className={styles.buttons}>
          {cancelButton && (
            <ButtonSecondary
              {...cancelButton}
              onClick={() => handleButtonClick(ModalCloseActionSource.CancelButton, cancelButton.onClick)}
              width={ButtonSecondaryWidth.BASE}
            />
          )}
          {loadingButton && (
            <LoadingButton
              {...loadingButton}
              onClick={() => handleButtonClick(ModalCloseActionSource.OkButton, loadingButton.onClick)}
              width={LoadingButtonWidth.BASE}
            />
          )}
        </div>
      )}
      </Modal>
  );
};
