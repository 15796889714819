import {FC} from 'react';
import clsx from 'clsx';
import {
  StepSize as StepsSize,
  ConnectingLine,
  Step,
  StepState,
  StepDirection as StepsDirection,
} from './components/Step';
import {TestableElement} from '../../external/types';

export {StepSize as StepsSize, StepDirection as StepsDirection} from './components/Step';

export type StepsProps = {
  steps: string[];
  currentStep: number;
  size?: StepsSize;
  direction?: StepsDirection;
  className?: string;
} & TestableElement;

export const Steps: FC<StepsProps> = ({
  steps,
  currentStep,
  size = StepsSize.MD,
  direction = StepsDirection.HORIZONTAL,
  className = '',
  testId = undefined,
}) => {
  const getConnectingLines = (i: number): ConnectingLine => {
    if (i === 1) {
      return ConnectingLine.AFTER;
    }
    if (i === steps.length) {
      return ConnectingLine.BEFORE;
    }
    return ConnectingLine.BOTH;
  };

  const getStepStates = (i: number): StepState => {
    if (i < currentStep) {
      return StepState.FINISHED;
    }
    if (i === currentStep) {
      return StepState.ACTIVE;
    }
    return StepState.INACTIVE;
  };

  const styles = {
    component: clsx(className, 'flex', {'flex-col': direction === StepsDirection.VERTICAL}),
  };

  return (
    <div className={styles.component} data-test-element="steps" data-testid={testId}>
      {steps.map((step, i) => (
        <Step
          key={step}
          title={step}
          stepNumber={i + 1}
          size={size}
          connectingLine={getConnectingLines(i + 1)}
          state={getStepStates(i + 1)}
          direction={direction}
        />
      ))}
    </div>
  );
};
