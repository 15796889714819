import {SubscriptionType} from '@symfonia-ksef/graphql';
import {buildUrl} from '../../../services/helpers/buildUrl';
import {SHOP_BASE_URL} from '../../../environments/environment.common';
import {
  PackageStatisticsRepositoryInterface,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';

export type ShopUrlParams = {
  tenantid: string,
  stype: SubscriptionType,
  packageleft: number,
  sourceid: string,
  utm_source: string,
  utm_medium: string,
  utm_campaign: string
}

const DEFAULT_SHOP_UPR_PARAMS: Readonly<Omit<ShopUrlParams, 'tenantid' | 'stype' | 'packageleft'>> = Object.freeze({
  utm_source: 'produkt',
  utm_medium: 'referral',
  utm_campaign: 'ksef',
  sourceid: 'symfoniaksef',
});

export function createSymfoniaShopUrl(packageStatisticsRepository: PackageStatisticsRepositoryInterface): string {
  const params: ShopUrlParams = {
    ...DEFAULT_SHOP_UPR_PARAMS,
    tenantid: packageStatisticsRepository.tenantId,
    packageleft: packageStatisticsRepository.availableInvoicesSize,
    stype: packageStatisticsRepository.subscriptionType,
  };
  return buildUrl(SHOP_BASE_URL, {path: ['s', 'redirect'], params});
}

export function redirectToSymfoniaShop(packageStatisticsRepository: PackageStatisticsRepositoryInterface): void {
  window?.open?.(createSymfoniaShopUrl(packageStatisticsRepository), '_blank')?.focus();
}
