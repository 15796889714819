import {FormattedMessage} from 'react-intl';
import {CircularChart} from './CircularChart';
import {ReactNode} from 'react';

interface Props {
  chartData: { color: string; name: string; numberValue: number; stringValue?: string }[];
  width?: number;
  testId?: string;
  insideText?: ReactNode;
  titleElement: ReactNode;
}

export const CircularPackageLimit = ({chartData, insideText, width = 130, titleElement, testId}: Props) => {

  const totalValue = chartData.filter(data => data.name === 'package')[0].numberValue;
  const usedValue = chartData.filter(data => data.name === 'used')[0].numberValue;

  return (
    <div className="flex shadow-lg rounded-xl w-full gap-[14px]">
      <div className={`max-w-[${width}px] p-2`}>
        <CircularChart total={totalValue} usedValue={usedValue} insideText={insideText}/>
      </div>
      <div className="items-center mt-[18px]">
        <div>
          {titleElement}
        </div>
        <div className="mt-[8px]">
          {chartData.map(({color, name, numberValue, stringValue}) => (
            <span key={`${name}-${numberValue}`} className="inline-flex items-center gap-[6px]" data-testid={`${testId}-${name}`}>
              <div className="w-4 h-4 rounded" style={{backgroundColor: color}}/>
              <FormattedMessage id={name}/>: {stringValue ?? numberValue}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
