import {EventConsumer} from '../../../services/EventConsumer';
import {SubscriptionStateEnum, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../../../services/SubscriptionEventsService';
import {
  PendingEventsI,
  UpdateActiveEventsI,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsActiveEventsRepository';
import {keysTransformers} from '../../../services/ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {DownloadInvoicesProgressTrackerI} from './DownloadInvoicesProgressTracker';

export interface EventsI extends UpdateActiveEventsI, PendingEventsI {

}


export type DownloadInvoicesVariant =
  WebsocketNotificationType.DownloadInvoices
  | WebsocketNotificationType.AutoFetchingInvoices

export class DownloadInvoicesProgressConsumer extends EventConsumer {
  constructor(private readonly progressTracker: DownloadInvoicesProgressTrackerI, private readonly notificationType: DownloadInvoicesVariant) {
    super(notificationType, [SubscriptionStateEnum.Processing, SubscriptionStateEnum.Enqueued, SubscriptionStateEnum.CancellationInProgress]);
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    if (event.state === SubscriptionStateEnum.Cancelled || event.state === SubscriptionStateEnum.Finished) {
      this.progressTracker.setCancellationInProgress(false);
    }
    if (this.notificationType === event.type && event.notificationId === this.progressTracker.pendingNotification?.NotificationId && this.shouldTrigger(event.state)) {
      if (event.state === SubscriptionStateEnum.CancellationInProgress) {
        this.progressTracker.updateEvent(keysTransformers.uppercase(event));
      }
      const data = await this.progressTracker.getAsyncData(event, undefined, 1000);
      if (data?.refreshNeeded) {
        this.progressTracker.refreshInvoices();
      }
    }
  }
}
