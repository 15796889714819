import {ReportFileTypeEnum, useGetPermissionsKSeFReportQueryQuery} from '@symfonia-ksef/graphql';
import {useParams} from 'react-router-dom';
import {useCallback, useRef, useState} from 'react';
import {base64ToBlob} from '../../common';
import fileSaver from 'file-saver';
import {PermissionsFiltersState} from '../modules/Permissions/PermissionsFIlters/PermissionsFiltersState';

export type DownloadReport = (options: { reportFileType: 'CSV' | 'XLS' }) => void;

export const useDownloadPermissionsReport = (filters: PermissionsFiltersState, searchText: string) => {
  const {companyId} = useParams();

  const [skip, setSkip] = useState(true);
  const reportFilesTypeRef = useRef<ReportFileTypeEnum | null>(null);
  const {loading, error} = useGetPermissionsKSeFReportQueryQuery({
    skip,
    onCompleted: ({GetPermissionsKSeFReport: {FileContent, MimeType, FileName}}) => {
      if (FileContent && MimeType) {
        fileSaver(base64ToBlob(FileContent, MimeType), FileName);
        setSkip(true);
        reportFilesTypeRef.current = null;
      } else {
        return;
      }
    },
    context: {envId: companyId},
    variables: {
      CredentialFiltersFields: filters.filtersForQuery,
      SearchText: searchText,
      take: 0,
      skip: 0,
      ReportFileType: reportFilesTypeRef.current as ReportFileTypeEnum,
    },
  });

  const download = useCallback<DownloadReport>((options) => {
    reportFilesTypeRef.current = options.reportFileType === 'CSV' ? ReportFileTypeEnum.Csv : ReportFileTypeEnum.Xlsx;
    (searchText || filters.filtersIsActive) && setSkip(false);
  }, [searchText, filters.filtersIsActive]);

  return {loading, error, enabled: filters.filtersIsActive, download};
};
