import {useIntl} from 'react-intl';
import {FC} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {IPostingConfigurationState} from '../IPostingConfigurationState';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import {Breadcrumbs, Icon, IconColor, IconSize, IconSvg, Tabs, Tooltip} from '@symfonia/brandbook';
import {Header} from '../../../../../../../root/components/Header';
import {FinancialYearSelect} from './FinancialYearSelect';
import {CategoryAccountService} from '../CategoryAccounts/CategoryAccountsTable/CategoryAccount.service';
import {useDeviceViewport} from '@symfonia/symfonia-ui-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

interface PostingConfigurationHeaderProps {
  title: string;
  text: string;
  tooltipTitle?: string;
  setSelectedFinancialYear: (year: string) => void;
  changeInvoiceTypeGroup: (invoiceTypeGroup: InvoiceTypeGroupEnum) => void;
  defaultSwitchBtnValue: string;
  service: CategoryAccountService;
  state: IPostingConfigurationState;
  requiredModulesPurchase?: boolean;
  requiredModulesSales?: boolean;
}

export const PostingConfigurationHeader: FC<PostingConfigurationHeaderProps> = observer(
  ({
     title,
     text,
     tooltipTitle,
     setSelectedFinancialYear,
     changeInvoiceTypeGroup,
     defaultSwitchBtnValue,
     service,
     state,
     requiredModulesPurchase,
     requiredModulesSales,
   }) => {
    const intl = useIntl();
    const companyName = earchiveState.company.name;

    const {companyId, tenantId} = useParams<{ companyId: string; tenantId: string }>();

    const handleChangeFinancialYear = async (year: string) => {
      setSelectedFinancialYear(year);

      await service.tableService.fetch();
    };

    const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
    const breadcrumbs = [
      {
        label: intl.formatMessage({id: Tr.settings}),
      },
      {
        label: isPhone ? '...' : intl.formatMessage({id: Tr.automaticDecrees}),
      },
      {
        label: intl.formatMessage({id: Tr.config}),
      },
    ];

    const changeTypeGroup = async (invoiceType: InvoiceTypeGroupEnum) => {
      changeInvoiceTypeGroup(invoiceType);

      await service.tableService.fetch();
    };

    const tabs = [
      {
        isActive: defaultSwitchBtnValue === InvoiceTypeGroupEnum.Purchase,
        text: intl.formatMessage({id: Tr.PURCHASE}),
        onClick: () => changeTypeGroup(InvoiceTypeGroupEnum.Purchase),
      },
      {
        isActive: defaultSwitchBtnValue === InvoiceTypeGroupEnum.Sales,
        text: intl.formatMessage({id: Tr.SALES}),
        onClick: () => changeTypeGroup(InvoiceTypeGroupEnum.Sales),
      },
    ];

    return (
      <div className="flex flex-col gap-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} className="mb-[10px] flex-wrap"/>
        <div className="flex lg:flex-row flex-col justify-between">
          <Header>
            <div className="flex justify-between w-full">
              <div>
                {intl.formatMessage({id: title})}
                <Tooltip
                  text={intl.formatMessage(
                    {id: Tr[text as Tr]},
                    {
                      b: chunks => <strong>{chunks}</strong>,
                      br: <br/>,
                      nameCompany: companyName,
                      title: intl.formatMessage({id: Tr[tooltipTitle as Tr]}),
                    },
                  )}
                >
                  <Icon
                    color={IconColor.GREEN_600}
                    className="relative left-[10px]"
                    svg={IconSvg.HELP_OUTLINE}
                    size={IconSize.LG}
                  />
                </Tooltip>
              </div>
            </div>
          </Header>
          <FinancialYearSelect setSelectedFinancialYear={handleChangeFinancialYear} state={state}/>
        </div>
        <Tabs tabs={tabs}/>
      </div>
    );
  },
);
