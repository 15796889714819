import {IdentifierType} from "../../../../../graphql-hot-chocolate/graphql";

export const PERMISSIONS_PAGE_CONSTS = {
  DEFAULT_PERMISSIONS_MODEL: {
    Identifier: '',
    IdentifierType: IdentifierType.Nip,
    CredentialsInvoiceRead: false,
    CredentialsInvoiceWrite: false,
    CredentialsRead: false,
    CredentialsManage: false,
    CredentialsSelfInvoicing: false,
  },
  MOCKED_PERMISSION_MODEL: {
    Identifier: '5340456977',
    IdentifierType: IdentifierType.Nip,
    CredentialsInvoiceRead: true,
    CredentialsInvoiceWrite: false,
    CredentialsRead: true,
    CredentialsManage: false,
    CredentialsSelfInvoicing: false,
  },
};
