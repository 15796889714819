export type IAppRoutes = {
  [route in 'root' | 'hub' | 'administration' | 'administrationTenant' | 'administrationTenantCreate' | 'newAdministrationTenantCreate' |
    'account' | 'accountAuthentication' | 'administrationCompanyCreate' | 'bok' | 'eArchive' | 'tenants'
    | 'autoPosting' | 'posting' | 'categoryDetails' | 'settlements' | 'shop' | 'eBok' | 'ksefInfo' | 'postingSettings' | 'documentTypes' |
    'postingVehicles' | 'uploadXML' | 'ocr' | 'ksefSettings' | 'ksefContractors']: {
    address: string;
    title: string;
  };
};

export const AppRoutes: IAppRoutes = {
  root: {
    address: '/',
    title: 'Symfonia KSeF',
  },
  hub: {
    address: '/earchive/hub',
    title: 'Administracja',
  },
  administration: {
    address: '/admin',
    title: 'Administracja',
  },
  administrationTenant: {
    address: '/admin/modules/settings',
    title: 'Tenant settings',
  },
  administrationTenantCreate: {
    address: '/administration/tenant/create',
    title: 'Create Tenant',
  },
  account: {
    address: '/account',
    title: 'account',
  },
  accountAuthentication: {
    address: '/account/authentication',
    title: 'authentication',
  },
  administrationCompanyCreate: {
    address: '/administration/company/create',
    title: 'Create Company',
  },
  bok: {
    address: '/bok',
    title: 'Symfonia KSeF',
  },
  eArchive: {
    address: '/earchive',
    title: 'KSeF',
  },
  tenants: {
    address: '/bok/tenants',
    title: 'Organizacje',
  },
  autoPosting: {
    address: '/autoposting',
    title: 'Dekretacje',
  },
  posting: {
    address: '/posting',
    title: 'Dekretacje',
  },
  categoryDetails: {
    address: '/category-details',
    title: 'Category Details',
  },
  settlements: {
    address: '/settlements',
    title: 'Settlements and VAT',
  },
  shop: {
    address: 'https://symfonia.pl/oprogramowanie/ksef#ksef_cennik',
    title: 'Sklep Symfonia',
  },
  eBok: {
    address: 'https://ebok.symfonia.pl',
    title: 'eBok Symfonia',
  },
  ksefInfo: {
    address: 'https://ksef.podatki.gov.pl/aplikacja-podatnika-ksef-i-inne-narzedzia/',
    title: 'KSeF',
  },
  postingSettings: {
    address: '/settings',
    title: 'Ustawienia dekretacji',
  },
  documentTypes: {
    address: '/documentTypes',
    title: 'Typ dokumentów',
  },
  postingVehicles: {
    address: '/vehicles',
    title: 'Konfiguracja pojazdów',
  },
  newAdministrationTenantCreate: {
    address: '/modules/new-user',
    title: 'Create Tenant',
  },
  uploadXML: {
    address: '/upload/invoices',
    title: 'Dodaj faktury XML',
  },
  ocr: {
    address: '/ocr',
    title: 'Dodaj faktury PDF, JPG, PNG lub TIFF',
  },
  ksefSettings: {
    address: '/account/ksef_settings',
    title: 'Ustawienia KSeF'
  },
  ksefContractors: {
    address: '/account/contractors',
    title: 'Kontrahenci'
  }
};
