import clsx from 'clsx';
import {FC} from 'react';
import {TogglableAppearanceProps, TogglableSize} from '../../../internal/components/Togglable/Togglable';

export const getAppearanceInputStyles = (size: TogglableSize) =>
  clsx('absolute rounded-full', {
    'w-[64px] h-[32px]': size === TogglableSize.MD,
    'w-[48px] h-[24px]': size === TogglableSize.SM,
  });

export const ToggleAppearance: FC<TogglableAppearanceProps> = ({
  isChecked = false,
  size = TogglableSize.MD,
  isError = false,
  disabled = false,
}) => {
  const styles = {
    inner: clsx('block rounded-full', {
      'w-[64px] h-[32px]': size === TogglableSize.MD,
      'w-[48px] h-[24px]': size === TogglableSize.SM,
      'bg-primary-500 group-hover:bg-primary-600': !isError && !disabled && isChecked,
      'border border-solid border-primary-500 group-hover:border-primary-600': !isChecked && !isError && !disabled,
      'bg-red-500 cursor-default': isError && isChecked,
      'border-2 border-solid border-red-500 cursor-default': isError && !isChecked,
      'bg-grey-400 cursor-default': disabled && isChecked,
      'border border-solid border-grey-500 cursor-default': disabled && !isChecked,
    }),
    circle: clsx('absolute rounded-full top-[4px]', {
      'right-[4px]': isChecked,
      'left-[4px]': !isChecked,
      'w-[16px] h-[16px]': size === TogglableSize.SM,
      'w-[24px] h-[24px]': size === TogglableSize.MD,
      'bg-white': isChecked,
      'bg-primary-500': !isChecked && !isError && !disabled,
      'bg-grey-500': disabled && !isChecked,
      'bg-red-500': isError && !isChecked,
      'group-hover:bg-primary-600': !isChecked && !isError && !disabled,
    }),
  };
  return (
    <div className="group relative">
      <div className={styles.inner} />
      <div className={styles.circle} />
    </div>
  );
};
