import {AbstractNotificationErrorService, ErrorEvent} from './NotificationErrorService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '../../../locales/translationKeys';

class KSeFDefaultErrorService extends AbstractNotificationErrorService {
  constructor(errorType: WebsocketErrorType.KsefError) {
    super(errorType);
  }


  protected _createAlert(event: ErrorEvent): AlertConfig {
    return {
      id: Tr.ksefOperationError,
      values: {ksefDescription: this.getExceptionDescription(event.errorContent)},
    };
  }
}

export const kSeFDefaultErrorService = new KSeFDefaultErrorService(WebsocketErrorType.KsefError);
