import {
  GetVatRegistriesDocument,
  GetVatRegistriesQuery,
  GetVatRegistriesQueryVariables,
  VatRegistriesResponse,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const GetVatRegistriesQueryFunction = async (companyId: string): Promise<VatRegistriesResponse[]> => {
  return await apolloClient
    .query<GetVatRegistriesQuery, GetVatRegistriesQueryVariables>({
      query: GetVatRegistriesDocument,
      context: {
        envId: companyId,
      },
      variables: {
        CompanyId: companyId,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.GetVatRegistries.VatRegistries as VatRegistriesResponse[];
    });
};
