import {FC, useMemo} from 'react';
import {getUnique} from '../../../helpers/getUnique';
import {AccountNumber} from './AccountNumber';
import classnames from 'classnames';

export const AccountsNumbersList: FC<{ accounts: { number?: string | number }[], fullWidth?: boolean }> = ({
                                                                                                             accounts,
                                                                                                             fullWidth,
                                                                                                           }) => {
  const list = useMemo(() => getUnique(accounts, ({number}) => number).map(({number}) => <AccountNumber
    className={classnames('mb-[8px]', {'w-full': fullWidth})} key={number}
    account={String(number)}/>), [accounts, fullWidth]);
  return <div className="flex flex-col flex-nowrap items-end">
    {list}
  </div>;
};
