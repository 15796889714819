import clsx from 'clsx';
import {FC, isValidElement, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import {isEqual} from 'lodash';
import {useOnClickOutside} from 'usehooks-ts';
import {Label, LabelProps, LabelSize} from '../Label/Label';
import {Notification, NotificationSize, NotificationVariant} from '../../../components/Notification/Notification';
import {Togglable, TogglableLabelPosition, TogglableProps, TogglableSize} from './Togglable';

export {TogglableSize as TogglableGroupSize} from './Togglable';

export enum TogglableGroupOrientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

type TogglablePropsPick = Pick<TogglableProps, 'label' | 'value' | 'disabled'>;

export type TogglableGroupProps = {
  label?: string | ReactElement<LabelProps>;
  checkboxes: TogglablePropsPick[];
  notification?: string;
  orientation?: TogglableGroupOrientation;
  size?: TogglableSize;
  isError?: boolean;
  disabled?: boolean;
  className?: string;
  value?: TogglableProps['value'][];
  defaultValue?: TogglableProps['value'][];
  onChange?: (checked: TogglableProps['value'][]) => void;
  required?: boolean;
  name?: string;
  isRadio?: boolean;
  labelPosition?: TogglableLabelPosition;
  onBlur?: () => void;
  onFocus?: () => void;
} & Pick<TogglableProps, 'appearance' | 'getAppearanceInputStyles' | 'testElement' | 'testId'>;

export const TogglableGroup: FC<TogglableGroupProps> = ({
  label = '',
  notification = '',
  checkboxes = [],
  orientation = TogglableGroupOrientation.VERTICAL,
  size = TogglableSize.MD,
  isError = false,
  disabled = false,
  className = undefined,
  value = undefined,
  defaultValue = undefined,
  onChange = () => undefined,
  required = false,
  appearance,
  getAppearanceInputStyles,
  name = undefined,
  isRadio = false,
  labelPosition = TogglableLabelPosition.RIGHT,
  onFocus = undefined,
  onBlur = undefined,
  testElement,
  testId = undefined,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isFocusIn = useRef<boolean>(false);

  const handleRefClick = () => {
    if (isFocusIn.current === false) {
      if (onFocus) {
        onFocus();
      }
      isFocusIn.current = true;
    }
  };

  useOnClickOutside(ref, () => {
    if (isFocusIn.current === true) {
      if (onBlur) {
        onBlur();
      }
      isFocusIn.current = false;
    }
  });

  const groupName = useMemo(() => name || crypto.randomUUID(), []);
  const [selectedValues, setSelectedValues] = useState(defaultValue !== undefined ? defaultValue : value || []);

  const checkboxesWithUuid = useMemo(() => {
    return checkboxes.map(checkbox => ({...checkbox, uuid: crypto.randomUUID()}));
  }, [checkboxes]);

  const handleChange = (checkboxValue: TogglableProps['value'], checkboxState: boolean) => {
    const getNextCheckboxValues = () =>
      checkboxState ? [...selectedValues, checkboxValue] : selectedValues.filter(v => v !== checkboxValue);

    const nextSelectedValues = isRadio ? [checkboxValue] : getNextCheckboxValues();

    setSelectedValues(nextSelectedValues);

    onChange(nextSelectedValues);
  };

  useEffect(() => {
    if (defaultValue !== undefined && value !== undefined) {
      console.error(`Symfonia: checkbox-group posiada ustawione wartości value i defaultValue`);
    }
  }, [defaultValue, value]);

  useEffect(() => {
    if (defaultValue === undefined && value !== undefined && !isEqual(value, selectedValues)) {
      setSelectedValues(value);
    }
  }, [value]);

  const styles = {
    component: clsx(['font-quicksand', className], {
      'text-base': size === TogglableSize.MD,
      'text-sm': size === TogglableSize.SM,
    }),
    checkboxes: clsx('flex', {
      'flex-row items-center gap-[24px]': orientation === TogglableGroupOrientation.HORIZONTAL,
      'flex-col': orientation === TogglableGroupOrientation.VERTICAL,
    }),
    checkbox: (index: number) =>
      clsx({
        'pb-[12px]':
          size === TogglableSize.SM &&
          orientation === TogglableGroupOrientation.VERTICAL &&
          index < checkboxes.length - 1,
        'pb-[16px]':
          size === TogglableSize.MD &&
          orientation === TogglableGroupOrientation.VERTICAL &&
          index < checkboxes.length - 1,
      }),
  };

  const renderNotification = () => (
    <Notification
      text={notification}
      variant={!disabled && isError ? NotificationVariant.ERROR : NotificationVariant.INFO}
      size={NotificationSize.SM}
      className={size === TogglableSize.MD ? 'mt-[14px]' : 'mt-[10px]'}
    />
  );

  return (
    <div className={styles.component} data-test-element={`${testElement}-group`} data-testid={testId}>
      {label &&
        (isValidElement(label) ? (
          label
        ) : (
          <Label
            className={size === TogglableSize.MD ? 'mb-[12px]' : 'mb-[8px]'}
            text={label}
            isRequired={required}
            size={size === TogglableSize.MD ? LabelSize.MD : LabelSize.SM}
          />
        ))}
      <div className={styles.checkboxes} ref={ref} onClick={handleRefClick}>
        {checkboxesWithUuid.map((checkbox, index) => (
          <Togglable
            testElement={testElement}
            className={styles.checkbox(index)}
            key={checkbox.uuid}
            disabled={checkbox.disabled || disabled}
            isError={isError}
            label={checkbox.label}
            value={checkbox.value}
            ordinal={index}
            size={size === TogglableSize.MD ? TogglableSize.MD : TogglableSize.SM}
            checked={selectedValues.includes(checkbox.value)}
            onChange={nextState => handleChange(checkbox.value, nextState)}
            appearance={appearance}
            getAppearanceInputStyles={getAppearanceInputStyles}
            isRadio={isRadio}
            name={groupName}
            labelPosition={labelPosition}
          />
        ))}
      </div>
      {notification && renderNotification()}
    </div>
  );
};
