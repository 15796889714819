import React, {FC, PropsWithChildren, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {PackageStatus, PackageUsage} from '@symfonia/symfonia-ui-components';
import LinearPackageMenuIcon from '../../../../assets/ic_usage.svg';
import {PackageType} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {getPackageEndingTooltip} from '../../../common/helpers/packageStatisticsHelper';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {IconColor, IconSvg} from '@symfonia/brandbook';
import {AccountLimits, IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {dateParser} from '../../../common';
import {redirectToSymfoniaShop} from '../../../common/helpers/redirectToSymfoniaShop';
import {
  PackageStatisticsRepositoryInterface,
} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


type PackageSubscriptionProps =
  PropsWithChildren
  & { packageStatistics: PackageStatisticsRepositoryInterface, isAdmin?: boolean, isDemo: boolean, packageUsageFeedbackEnabled: boolean }

function getIcon(status: Partial<PackageStatus>) {
  switch (status) {
    case PackageStatus.Expired:
      return {icon: IconSvg.WARNING, color: IconColor.RED_500};
    case PackageStatus.Expiring:
      return {icon: IconSvg.WARNING, color: IconColor.ORANGE_500};
    default:
      return;
  }
}

export const PackageSubscription: FC<PackageSubscriptionProps> = observer(({
                                                                             packageStatistics,
                                                                             isAdmin,
                                                                             isDemo,
                                                                             packageUsageFeedbackEnabled,
                                                                           }) => {
  const {formatMessage} = useIntl();
  const {
    state: {DateTo, Items: Packages},
    subscriptionIsActive,
    packageDateIsEnding,
    invoicePackageIsEnding,
    invoicesLeft,
    availableStorageSize,
    storagePackageIsEnding,
    isPackageUsed,
    isPackageNotActive,
    deactivationDate,
    subscriptionIsInactive,
    enabled,
    loading,
    currentStorageUsage,
    maxStorageSize,
    currentInvoicesUsage,
    maxInvoicesSize,
    availableInvoicesSize,
  } = packageStatistics;
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const packageUsage = useMemo<PackageUsage[]>(() => Packages?.filter?.(({PackageTypeEnum: Type}) => Type !== PackageType.Service)?.map?.(({
                                                                                                                                             PackageTypeEnum: Type,
                                                                                                                                           }) => ({
    limit: Type === PackageType.Invoice ? maxInvoicesSize : maxStorageSize.bytes,
    usage: Type === PackageType.Invoice ? currentInvoicesUsage ?? 0 : currentStorageUsage.bytes,
    formatted: Type === PackageType.Storage ? {
      usage: availableStorageSize.value,
      limit: maxStorageSize.value,
    } : {
      usage: availableInvoicesSize,
      limit: maxInvoicesSize,
    },
    lineLabel: Type === PackageType.Invoice ? intl.formatMessage({id: 'availableInvoices'}) : intl.formatMessage({id: 'availableSpace'}),
  })) ?? [], [Packages, currentStorageUsage, maxStorageSize, maxInvoicesSize, currentInvoicesUsage, intl]);

  const tooltip = getPackageEndingTooltip(isPackageUsed, subscriptionIsActive, invoicePackageIsEnding, storagePackageIsEnding, packageDateIsEnding);
  const isPackageExpiring = invoicePackageIsEnding || storagePackageIsEnding || packageDateIsEnding;
  const {tenantId, companyId} = earchiveState.company;


  const redirect = () => {
    const redirectionLocation = `/earchive/${tenantId}/company/${companyId}/account/tenant`;
    tenantId && companyId && redirectionLocation !== location.pathname && navigate(redirectionLocation);
  };

  return <>
    {enabled && <>
      <AccountLimits
        dateTo={!subscriptionIsActive && deactivationDate ? deactivationDate : DateTo}
        dateLabel={intl.formatMessage({id: subscriptionIsActive ? Tr.activeUntil : Tr.inactiveFrom})}
        inactive={!subscriptionIsActive}
        packageUsage={packageUsage}
        redirect={redirect}
        linearPackageIcon={LinearPackageMenuIcon}
        linearTooltipHeader={intl.formatMessage({id: Tr.packageUsageTooltipHeader})}
        limitsEnable={isAdmin && !subscriptionIsInactive}
        loading={loading}
      />
      {isAdmin &&
        <IconWithTooltip
          tooltipWidth="auto"
          icon={{icon: IconSvg.LOCAL_GROCERY_STORE, color: IconColor.GREY_500}}
          tooltipContent={intl.formatMessage({id: Tr.buyPackage})}
          onClick={() => redirectToSymfoniaShop(earchiveState.packageStatistics)}
        />
      }
    </>}
    <div className="flex-separator"/>
    {enabled && isPackageExpiring && packageUsageFeedbackEnabled && !subscriptionIsInactive &&
      <IconWithTooltip
        tooltipWidth="auto"
        icon={getIcon(isPackageNotActive || isPackageUsed ? PackageStatus.Expired : PackageStatus.Expiring)}
        tooltipContent={tooltip ? formatMessage({id: tooltip.content}, {
          expiryDate: packageDateIsEnding && DateTo ? dateParser(DateTo) : null,
          invoiceCount: invoicePackageIsEnding ? invoicesLeft : null,
          diskSpace: storagePackageIsEnding ? availableStorageSize?.value : null,
        }) : ''}
      />
    }
  </>;
});

