import {Tr} from '../../../../../../../../../../locales/translationKeys';
import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {
  ButtonPrimary,
  ButtonPrimaryWidth,
  ButtonSecondary,
  ButtonSecondaryWidth,
  Input,
  InputWidth,
  Label,
  LabelSize,
  Modal,
} from '@symfonia/brandbook';

interface ChangeCategoryNameModalProps {
  existingName: string;
  open: boolean;
  onOk: (name: string) => void;
  onCancel: () => void;
}

export const ChangeCategoryNameModal: FC<ChangeCategoryNameModalProps> = ({existingName, open, onOk, onCancel}) => {
  const intl = useIntl();
  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [validation, setValidation] = useState<string | undefined>(undefined);

  useEffect(() => {
    setNewName(existingName);
  }, [existingName]);

  useEffect(() => {
    if (newName && newName.length > 70) {
      setValidation(intl.formatMessage({id: Tr.categoryNameLengthValidation}));
    } else {
      setValidation(undefined);
    }
  }, [newName]);

  const handleOk = () => {
    if (!newName) {
      return;
    }
    onOk(newName);
    onCancel();
  };
  const handleCancel = () => {
    onCancel();
  };

  const isBtnDisabled = newName === existingName;

  return (
    <Modal
      onClose={handleCancel}
      title={intl.formatMessage({id: Tr.changeCategoryNameHeader})}
      show={open}
      centerButtons={true}
    >
      <div>
        <Label className="mb-[8px]" text={intl.formatMessage({id: Tr.category})} size={LabelSize.MD}/>
        <Input
          width={InputWidth.FULL}
          onInput={e => setNewName(e)}
          value={newName ?? ''}
          placeholder={intl.formatMessage({id: Tr.writeCategoryName})}
          isError={!!validation}
          notification={validation}
        />
      </div>
      <div className="flex gap-[16px] pt-[24px]">
        <ButtonSecondary
          onClick={handleCancel}
          text={intl.formatMessage({id: Tr.cancelButton})}
          width={ButtonSecondaryWidth.BASE}
        />
        <ButtonPrimary
          onClick={handleOk}
          disabled={isBtnDisabled || !!validation || !newName}
          text={intl.formatMessage({id: Tr.saveChanges})}
          width={ButtonPrimaryWidth.BASE}
        />
      </div>
    </Modal>
  );
};
