import clsx from 'clsx';
import {FC} from 'react';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '../../Icon/Icon';

export enum StepDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum ConnectingLine {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  BOTH = 'BOTH',
}

export enum StepSize {
  SM = 'SM',
  MD = 'MD',
}

export enum StepState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
}

export type StepProps = {
  stepNumber: number;
  title?: string;
  state: StepState;
  size?: StepSize;
  connectingLine?: ConnectingLine;
  direction: StepDirection;
};

export const Step: FC<StepProps> = ({
  stepNumber,
  title = '',
  state,
  size = StepSize.MD,
  connectingLine = ConnectingLine.BOTH,
  direction,
}) => {
  const styles = {
    component: clsx('flex font-quicksand w-fit items-center', {
      'flex-col': direction === StepDirection.HORIZONTAL,
      'flex-row': direction === StepDirection.VERTICAL,
      'font-medium text-green-700': state === StepState.ACTIVE,
      'text-grey-500': state === StepState.INACTIVE,
      'text-[14px]': size === StepSize.SM,
      'text-[16px]': size === StepSize.MD,
      'pl-[16px]': connectingLine === ConnectingLine.BEFORE && direction === StepDirection.HORIZONTAL,
      'pr-[16px]': connectingLine === ConnectingLine.AFTER && direction === StepDirection.HORIZONTAL,
      'px-[16px]': connectingLine === ConnectingLine.BOTH && direction === StepDirection.HORIZONTAL,
    }),
    bubbleBox: clsx(
      'flex w-[calc(100%+32px)] justify-center before:flex after:flex before:flex-grow after:flex-grow items-center',
      {
        'before:h-[2px] after:h-[2px]': direction === StepDirection.HORIZONTAL,
        'before:w-[2px] after:w-[2px] flex flex-col': direction === StepDirection.VERTICAL,
        'h-[96px]':
          direction === StepDirection.VERTICAL && size === StepSize.MD && connectingLine === ConnectingLine.BOTH,
        'h-[100px]':
          direction === StepDirection.VERTICAL && size === StepSize.SM && connectingLine === ConnectingLine.BOTH,
        'h-[64px]':
          direction === StepDirection.VERTICAL &&
          size === StepSize.MD &&
          (connectingLine === ConnectingLine.BEFORE || connectingLine === ConnectingLine.AFTER),
        'h-[76px]':
          direction === StepDirection.VERTICAL &&
          size === StepSize.SM &&
          (connectingLine === ConnectingLine.BEFORE || connectingLine === ConnectingLine.AFTER),
        'before:hidden': direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.AFTER,
        'after:hidden': direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.BEFORE,
        'after:invisible': connectingLine === ConnectingLine.BEFORE,
        'before:invisible': connectingLine === ConnectingLine.AFTER,
        'before:bg-grey-200 after:bg-grey-200': state === StepState.INACTIVE,
        'before:bg-green-500 after:bg-grey-200': state === StepState.ACTIVE,
        'before:bg-green-500 after:bg-green-500': state === StepState.FINISHED,
      },
    ),
    bubble: clsx('flex justify-center items-center rounded-full border-[2px]', {
      'bg-white border-grey-200': state === StepState.INACTIVE,
      'bg-primary-100 border-none': state === StepState.ACTIVE,
      'bg-white border-green-500': state === StepState.FINISHED,
      'min-w-[24px] h-[24px]': size === StepSize.SM,
      'min-w-[32px] h-[32px]': size === StepSize.MD,
    }),
    title: clsx('flex whitespace-nowrap', {
      'pt-[4px]':
        direction === StepDirection.HORIZONTAL ||
        (direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.AFTER && size === StepSize.MD),
      'pt-[1.5px]':
        direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.AFTER && size === StepSize.SM,
      'pl-[8px]': direction === StepDirection.VERTICAL,
      'self-start': direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.AFTER,
      'self-end': direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.BEFORE,
      'pb-[4px]':
        direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.BEFORE && size === StepSize.MD,
      'pb-[1.5px]':
        direction === StepDirection.VERTICAL && connectingLine === ConnectingLine.BEFORE && size === StepSize.SM,
    }),
  };

  return (
    <div className={styles.component}>
      <div className={styles.bubbleBox}>
        <div className={styles.bubble} data-testid="step-bubble">
          {state === StepState.FINISHED ? (
            <Icon
              svg={IconSvg.CHECK}
              size={IconSize.SM}
              variant={IconVariant.FILLED}
              color={IconColor.GREEN_500}
              ariaLabel="check-icon"
            />
          ) : (
            stepNumber
          )}
        </div>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};
