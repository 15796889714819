import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {PermissionsService} from '../Permissions.service';
import {computed, makeObservable} from 'mobx';
import {FilterScopeType, TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {Pills} from '../../../../root/components/FiltersPills';
import {intl} from '../../../../root/IntlProvider';
import {CredentialFiltersFieldsInput} from '@symfonia-ksef/graphql';
import {
  convertToDropdownListOption,
  convertToMapDropdownListOption,
} from '../../../../common/helpers/baseFilterHelpers';
import {Tr} from '@symfonia-ksef/locales/keys';

export enum Credentials {
  CredentialsInvoiceRead = 'CredentialsInvoiceRead',
  CredentialsInvoiceWrite = 'CredentialsInvoiceWrite',
  CredentialsManage = 'CredentialsManage',
  CredentialsRead = 'CredentialsRead',
  CredentialsSelfInvoicing = 'CredentialsSelfInvoicing',
}

export enum PermissionsFilterKeys {
  Credentials = 'Credentials',
  Identifiers = 'Identifiers',
}

export class PermissionsFiltersState extends BaseSideMenuFilterState<PermissionsService, PermissionsFilterKeys> {
  private readonly translationsMap: Record<PermissionsFilterKeys, Tr> = {
    Identifiers: Tr.forWho,
    Credentials: Tr.permissions,
  };

  constructor(permissionsService: PermissionsService) {
    super(permissionsService);
    makeObservable(this);
    this.defaultFilters = [
      {
        key: PermissionsFilterKeys.Identifiers,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.String,
          isPageFilter: true,
        },
      },
      {
        key: PermissionsFilterKeys.Credentials,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.Enum,
          isPageFilter: true,
        },
      },
    ];
    this.setDefaultValues();
  }

  @computed
  public get filtersIsActive(): boolean {
    return Object.keys(this.pills).length > 0;
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};
    for (const [filterKey, value] of this.pillsList.entries()) {
      const {values} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: this.translationsMap[filterKey as PermissionsFilterKeys]}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.clearSingleFilter(filterKey, key, value);
            this.parent.repository.fetch();
          },
        })),
      };
    }
    return convertedPills;
  }

  @computed
  public get credentialsDropdownMap() {
    return convertToMapDropdownListOption(Object.values(Credentials), credential => ({
      value: credential,
      label: intl.formatMessage({id: credential}),
    }));
  }

  @computed
  public get filtersForQuery(): CredentialFiltersFieldsInput {
    const filters: Pick<CredentialFiltersFieldsInput, 'Identifiers'> = {
      Identifiers: this.getActiveFiltersForQuery.Identifiers?.values,
    };

    if (!this.getActiveFiltersForQuery.Credentials?.values?.length) {
      return filters;
    }

    const credentials = Object.keys(Credentials).reduce<Omit<CredentialFiltersFieldsInput, 'Identifiers'>>((acc, credential) => {
      acc[credential as Credentials] = this.getActiveFiltersForQuery.Credentials.values.includes(credential);
      return acc;
    }, {});
    return {...credentials, ...filters};
  }

  protected setDefaultValues() {
    let filterToCreate = this.storage.loadFromStorage();

    if (!filterToCreate?.length) {
      filterToCreate = this.defaultFilters;
    }

    filterToCreate.forEach(f => {
      if (Object.keys(PermissionsFilterKeys).find(c => c === f.key)) {
        this.setActiveFilters(f.key, f.value);
      }
    });

    this.createPills();
  }
}
