import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {AppRoutes} from '../../root/Routes';
import {observer} from 'mobx-react-lite';
import {DialogModal} from '@symfonia/symfonia-ksef-components';

export const ExpiredTokenModal = observer(() => {

  const {company: {companyId, tenantId}, ksefAuthentication} = earchiveState;
  const authRoute = AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + AppRoutes.accountAuthentication.address;

  async function handleExpiredToken() {
    ksefAuthentication.setExpiredToken(false);
    ksefAuthentication.markBoundNotificationAsRead();
  }

  const intl = useIntl();
  return (
    <DialogModal
      onCancel={handleExpiredToken}
      open={!!ksefAuthentication.expiredToken}
      title={intl.formatMessage({id: Tr.expiredTokenTitle})}
      okButton={{
        text: intl.formatMessage({id: Tr.expiredTokenGoToAuthorize}), onClick: () => {
          handleExpiredToken();
          window.open(authRoute, '_self');
        },
      }}
      cancelButton={{text: intl.formatMessage({id: Tr.acknowledge}), onClick: handleExpiredToken}}
    >
      <FormattedMessage id={Tr.expiredTokenDescription}/>
    </DialogModal>
  );
});
