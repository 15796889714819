import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {computed, makeObservable} from 'mobx';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables} from '@symfonia-ksef/graphql';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import {PermissionsRegisterRepository, PermissionsRegisterRepositoryI} from './PermissionsRegister.repository';
import {PermissionsRegisterDataSourceHandler, PermissionsRegisterRow, PermissionsRegisterTableI, permissionsRegisterInitialColumnsFactory, permissionsRegisterTableKeysFactory} from './PermissionsTable/PermissionsRegisterTableConfig';


export class PermissionsRegisterService extends BaseModule {
  public readonly repository!: PermissionsRegisterRepositoryI;

  constructor() {
    super();

    this.repository = new PermissionsRegisterRepository();

    this.createTableService();

    makeObservable(this);
  }

  private _tableService!: PermissionsRegisterTableI;

  public get tableService(): PermissionsRegisterTableI {
    return this._tableService;
  }

  @computed
  public get permissionsRegister(): PermissionsRegisterRow[] {
    return this.tableService.rows;
  }

  protected _onMount(): void {
    this.tableService.onMount();
    this.repository.fetch();
  }

  protected _onUnmount(): void {
    this.tableService.onUnmount();
  }

  private getColumns() {
    return permissionsRegisterInitialColumnsFactory();
  }

  private createTableService(): void {
    this._tableService = TableBuilder.create<PermissionsRegisterRow, 'GetUserKSeFRequestForCredentials', GetUserKSeFRequestForCredentialsQuery, GetUserKSeFRequestForCredentialsQueryVariables>()
      .connectKeyFactory(permissionsRegisterTableKeysFactory)
      .connectColumns(this.getColumns())
      .connectSortBy({persistKey: 'permissionsRegisterSortBy'})
      .connectDataSource(new PermissionsRegisterDataSourceHandler(this.repository))
      .connectPagination({
        lifeCycle: {mode: PaginationMode.serverSide},
        persistKey: 'permissionsRegisterPagination',
      })
      .buildExtended();
  }
}
