import {
  GetWebsocketNotificationModelDocument,
  GetWebsocketNotificationModelQuery,
  GetWebsocketNotificationModelQueryVariables,
  WebsocketErrorType,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {
  Mapper,
  NotificationDataParser,
  NotificationDataParserI,
  NotificationDataType,
} from './helpers/NotificationDataParsers';
import {action, observable, runInAction} from 'mobx';
import {Notification} from './NotificationDataService';
import {EventParams} from './SubscriptionEventsService';
import {Repository, RepositoryI} from '../modules/root/services/MobXServices/Repository/Repository';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export interface BaseKsefEventContextDataHandlerI {

  errorType: WebsocketErrorType | undefined;

  setErrorType(errorType: WebsocketErrorType | undefined): this;
}

export interface KsefEventContextDataHandlerI<T extends WebsocketNotificationType = WebsocketNotificationType> extends BaseKsefEventContextDataHandlerI, RepositoryI<'GetWebsocketNotificationModel', GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables> {

  //Notyfikacja otrzymana z eventu websocketowego (lub archiwalna)
  notification: Notification;

  errorType: WebsocketErrorType | undefined;

  //dane kontekstowe pobrane dla danego eventu
  result: NotificationDataType<T> | null;

  //Mówi o tym czy porces się zakończył (przyszedł event) a jego dane kontekstowe są dostępne
  resultIsAvailable: boolean;

  setErrorType(errorType: WebsocketErrorType | undefined): this;


  //ustawia dane kontekstowe dla procesu
  //pozwala oznaczyć dane jako ustawione ręcznie
  setResult(result: NotificationDataType<T> | null, config?: { manualSettled?: boolean }): this;

  setResultIsAvailable(isAvailable: boolean): this;

  //Pozwala pobrać i zwrócić dane kontekstowe dla eventu
  getAsyncData(event: Pick<EventParams, 'errorType' | 'notificationId'>, onEventError?: () => void): Promise<NotificationDataType<T> | null>;

  setNotification(notification: Notification): this;

}

export abstract class KsefEventContextDataHandler<T extends WebsocketNotificationType = WebsocketNotificationType, U = unknown> extends Repository<'GetWebsocketNotificationModel', GetWebsocketNotificationModelQuery, GetWebsocketNotificationModelQueryVariables> implements KsefEventContextDataHandlerI<T> {
  @observable.ref
  public result: NotificationDataType<T> | null = null;

  @observable
  public resultIsAvailable: boolean = false;

  public errorType: WebsocketErrorType | undefined;

  @observable.ref
  public notification: Notification = null;

  private parser?: NotificationDataParserI<T, U>;

  protected constructor(public eventType: WebsocketNotificationType, private readonly mapper?: Mapper<T, U>) {
    super('GetWebsocketNotificationModel', GetWebsocketNotificationModelDocument, envObserver, earchiveState);
  }

  @action.bound
  public setErrorType(errorType: WebsocketErrorType | undefined): this {
    this.errorType = errorType;
    return this;
  }

  @action.bound
  public setResult(result: NotificationDataType<T> | null, config?: { manualSettled?: boolean }): this {
    this.result = result;
    this.onResultSettled(this.result, config?.manualSettled ?? false);
    return this;
  }

  @action.bound
  public setResultIsAvailable(isAvailable: boolean): this {
    this.resultIsAvailable = isAvailable;
    return this;
  }

  public async getAsyncData(event: Pick<EventParams, 'errorType' | 'notificationId'>, onEventError: (() => void) | undefined = undefined, delay: number = 3000): Promise<NotificationDataType<T> | null> {
    if (!this.envId || event.errorType) {
      onEventError?.();
      this.setErrorType(event.errorType);
      return null;
    }
    this.configure({NotificationId: event.notificationId}).setResultIsAvailable(true);
    const success = await new Promise<boolean>((resolve) => {
      setTimeout(() => {
        this.fetch().then(() => resolve(true)).catch(() => resolve(false));
      }, delay);
    });

    return success ? this.result : null;
  }

  @action.bound
  public setNotification(notification: Notification): this {
    this.notification = notification;
    return this;
  }


  protected onResultSettled(result: NotificationDataType<T> | null, manualSettled: boolean): void {
    return;
  }

  protected createDataParser(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel'], opt?: {
    cachingDisabled: boolean
  }): NotificationDataParserI<T> {
    return new NotificationDataParser<T>(data, opt).setMapper(this.mapper);
  }


  //gdy dane kontekstowe dla eventu (zakończonego procesu) zostaną pobrane zostają sparsowane i zrzutowane do odpowiedniego typu zależnego od typu eventu
  //ustawiana jest notyfikacja
  //sparsowane dane są ustawiane jako result
  protected override onSuccess(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel']): void {
    this.parser = this.createDataParser(data, {cachingDisabled: true});
    const {ResponseJson, ...notification} = data;
    runInAction(() => {
      this.parser && this.setResult(this.parser.parse());
      this.setNotification(notification);
      this.set(null);
    });
  }
}
