import {InvoicePreviewInterface} from './InvoicePreviewRepository';
import {apolloClient} from '../../modules/root/providers/GraphQLProvider';
import {InvoicePreviewState} from './InvoicePreviewModel';
import {action, makeObservable, observable} from 'mobx';
import {GetInvoiceFileContentSingleDocument, GetInvoiceFileContentSingleQuery} from '@symfonia-ksef/graphql';
import {base64ToBlob} from '../../modules/common';
import {downloadFile} from '../../modules/common/helpers/downloadFile';
import {authState, envObserver} from '@symfonia-ksef/state/rootRepository';

export class InvoicePdfService implements InvoicePdfServiceInterface {
  @observable
  public loading = false;

  @observable
  public error = false;

  constructor(private invoicePreview: InvoicePreviewInterface) {
    makeObservable(this);
  }

  private static mapResponseToState(response: GetInvoiceFileContentSingleQuery): InvoicePreviewState {
    return response.GetInvoiceFileContentSingle;
  }

  @action.bound
  public async load() {
    const envId = envObserver.currentEnv.companyId;

    if (!this.invoicePreview.currentInvoiceId) {
      throw new Error('currentInvoiceId have to be settled before load invoice pdf');
    }
    if (envId === null) {
      throw new Error('envId has to be settled before load invoice pdf');
    }

    this.setLoading(true);
    const {data, errors} = await apolloClient.query<GetInvoiceFileContentSingleQuery>({
      query: GetInvoiceFileContentSingleDocument,
      context: {envId},
      variables: {InvoiceId: this.invoicePreview.currentInvoiceId, Token: authState.accessToken},
    });
    if (errors) {
      this.setError(true);
      return this;
    }
    if (data) {
      this.setError(false);
      this.invoicePreview.setState(InvoicePdfService.mapResponseToState(data));
    }

    this.setLoading(false);
    return this;
  }

  public async download() {
    if (!this.invoicePreview.state.PdfContent || !this.invoicePreview.state.MimeType) {
      throw Error('PdfContent has to be loaded before download pdf');
    }

    const blob = base64ToBlob(this.invoicePreview.state.PdfContent, this.invoicePreview.state.MimeType);
    downloadFile(blob, this.invoicePreview.state.PdfName || 'faktura');
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
  }

  private setError(error: boolean): void {
    this.error = error;
  }
}

export interface InvoicePdfServiceInterface {
  loading: boolean;
  error: boolean;

  load(): Promise<InvoicePdfServiceInterface>;

  download(): Promise<void>;
}
