import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {
  UpoFileType,
  UpoMissingInvoiceReason,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {getUpoResultService} from '@symfonia-ksef/state/rootRepository';
import {Tr} from '@symfonia-ksef/locales/keys';
import DownloadIcon from '../../../../../../assets/ic_download.svg';
import {UPODataModel} from '../../../../../../services/helpers/NotificationDataParsers';
import {ReactNode} from 'react';
import {UpoModelMapper} from '../../helpers/UpoModelMapper';

export type UPOModelToMap =
  Omit<UPODataModel, 'fileType'>
  & { fileType: number}

const upoMissingInvoiceReason: Record<UpoMissingInvoiceReason, number> = {
  [UpoMissingInvoiceReason.NotExists]: 0,
  [UpoMissingInvoiceReason.NotSent]: 1,
  [UpoMissingInvoiceReason.Processing]: 2,
  [UpoMissingInvoiceReason.UpoNotGenerated]: 3,
  [UpoMissingInvoiceReason.ValidationError]: 4,
  [UpoMissingInvoiceReason.Error]: 5,
  [UpoMissingInvoiceReason.InterruptedSending]: 6,
  [UpoMissingInvoiceReason.InterruptedPending]: 7,
  [UpoMissingInvoiceReason.Ocr]: 8,
  [UpoMissingInvoiceReason.SchemaError]: 9,
  [UpoMissingInvoiceReason.NoSessionReferenceNumber]: 10,
};

export const upoMissingInvoicesReasonMap = new Map<number, UpoMissingInvoiceReason>(Object.entries(upoMissingInvoiceReason).map(entry => entry.reverse() as [number, UpoMissingInvoiceReason]));

export class UpoEventConverter extends KSeFEventsConverter<WebsocketNotificationType.GetUpo, UPOModelToMap> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.GetUpo, event);
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    const data = this.parser.parse();
    if (!data?.returnedInvoices || data?.missingInvoices?.length === data?.requestedInvoices?.length) {
      return undefined;
    }
    return {
      icon: DownloadIcon,
      tooltipText: Tr.downloadUPO,
    };
  }

  public get notification(): ReactNode {
    return this.createNotification(this.model.hasError ? {id: Tr.getUpoSubError} : !this.model.data?.missingInvoices?.length ? {id: Tr.getUpoSubSuccess} : {id: Tr.getUpoSubPartialSuccess, values: {count: this.model.data?.returnedInvoices.length, total: this.model.data?.requestedInvoices.length}});
  }

  public get model(): { data: UPODataModel | null, hasError: boolean } {
    const data = this.parser.parse();
    const hasError = !!this.event.ErrorType || (!data?.returnedInvoices || data?.missingInvoices?.length === data?.requestedInvoices?.length);
    return {hasError, data};
  }

  public async action(): Promise<void> {
    const data = this.parser.parse();
    if (data) {
      getUpoResultService.setNotification(this.event).setArchivedResult(data, this.event.NotificationId).setResultIsAvailable(true);
    }
    getUpoResultService.downloadUpoFile().hasMissed && getUpoResultService.handleMissingInvoices().setModalIsActive(true);
  }

  protected override mapper({
    missingInvoices,
    fileType,
    ...rest
  }: UPOModelToMap): UPODataModel {
    return UpoModelMapper.map({
      missingInvoices,
      fileType,
      ...rest
    });
  }
}
