import {v4} from 'uuid';
import {LogLevel} from './logLevel';

export class LogEvent {
  public readonly id = v4();

  constructor(
    public readonly level: LogLevel,
    public readonly message: string,
    public readonly data?: any,
    public readonly error?: Error | any,
  ) {}

  public get isException(): boolean {
    return this.level === LogLevel.Error || this.level === LogLevel.Critical;
  }
}
