import {FC, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';
import {DialogModal} from '@symfonia/symfonia-ksef-components';

export interface PostingPartlyNotAvailableModalProps {
  title: string;
  message: string;
  unavailable: number;
  selected: number;
  open: boolean;
  onConfirm: () => void;
}

export const ActionPartlyNotAvailableModal: FC<PostingPartlyNotAvailableModalProps> = ({
                                                                                          title,
                                                                                          message,
                                                                                          unavailable,
                                                                                          selected,
                                                                                          open,
                                                                                          onConfirm
                                                                                        }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };

  const intl = useIntl();

  return <DialogModal 
    open={open} 
    onCancel={() => null} 
    title={intl.formatMessage({id: title})}
    okButton={{text: intl.formatMessage({id: Tr.acknowledge}), onClick: handleConfirm}}
    >
      <FormattedMessage id={message} values={{unavailable, selected}}/>
  </DialogModal>;
};
