import {
  DeleteInvoicesDataModel,
  InvoiceNonRemovableReason,
} from '../../../../../services/helpers/NotificationDataParsers';
import {DeleteInvoicesModelToMap} from '../services/KSeFEventsConverters/DeleteInvoicesEventConverter';

export class DeleteInvoicesModelMapper {
  static invoiceNonRemovableReason: Record<InvoiceNonRemovableReason, number> = {
    [InvoiceNonRemovableReason.None]: 0,
    [InvoiceNonRemovableReason.NotOwner]: 1,
    [InvoiceNonRemovableReason.WrongType]: 2,
    [InvoiceNonRemovableReason.WrongStatus]: 3,
    [InvoiceNonRemovableReason.AlreadyExported]: 4,
    [InvoiceNonRemovableReason.ServerError]: 5,
    [InvoiceNonRemovableReason.NotFound]: 6,
  };

  static invoiceNonRemovableReasonMap = new Map<number, InvoiceNonRemovableReason>(
    Object.entries(DeleteInvoicesModelMapper.invoiceNonRemovableReason).map(entry => entry.reverse() as [number, InvoiceNonRemovableReason]),
  );

  public static map({errorItems, ...rest}: DeleteInvoicesModelToMap): DeleteInvoicesDataModel | null {
    return {
      ...rest,
      errorItems: errorItems?.map?.(({reason, ...restItems}) => ({
        reason: this.getMappedInvoiceNonRemovableReason(reason),
        ...restItems,
      })),
    };
  }

  private static getMappedInvoiceNonRemovableReason(key: number): InvoiceNonRemovableReason {
    return DeleteInvoicesModelMapper.invoiceNonRemovableReasonMap.get(key) ?? InvoiceNonRemovableReason.None;
  }
}
