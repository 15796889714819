import React, {ReactNode} from 'react';

interface CircularChartConfig {
  dangerThreshold: number;
  percentageColor: string;
  availableLeftColor: string;
  outerLineColor: string;
  dangerOuterLineColor: string;
  dangerPercentageColor: string;
  dangerAvailableLeftColor: string;
  size: number;
  strokeWidth: number;
  outerLineStrokWidth: number;
}

interface CircularChartProps {
  config?: CircularChartConfig;
  total: number;
  usedValue: number;
  insideText?: string | ReactNode;
}

const defaultConfig: CircularChartConfig = {
  size: 130,
  outerLineColor: '#8CD28C',
  percentageColor: '#009A00',
  availableLeftColor: '#D9F0D9',
  dangerOuterLineColor: '#E7B282',
  dangerPercentageColor: '#DA893E',
  dangerAvailableLeftColor: '#F8E7D8',
  dangerThreshold: 10,
  strokeWidth: 10,
  outerLineStrokWidth: 5,
};

export const CircularChart = ({config = defaultConfig, total, usedValue, insideText}: CircularChartProps) => {
  const {size, strokeWidth, outerLineStrokWidth, dangerThreshold} = config;
  const radius = (size - 10) / 2;
  const percentageLeft = 100 - (usedValue / total) * 100;
  const colorsSet = percentageLeft < dangerThreshold ?
    {
      outerLineColor: config.dangerOuterLineColor,
      availableLeftColor: config.dangerAvailableLeftColor,
      percentageColor: config.dangerPercentageColor,
    } : 
    {
      outerLineColor: config.outerLineColor,
      availableLeftColor: config.availableLeftColor,
      percentageColor: config.percentageColor,
    };
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (usedValue / total) * circumference;

  return (
    <div className={`relative flex items-center justify-center w-[${size}px] h-[${size}px]`}>
      <svg width={config.size} height={config.size}>
        <circle
          stroke={colorsSet.outerLineColor}
          fill="transparent"
          strokeWidth={outerLineStrokWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={colorsSet.availableLeftColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius - 5}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={colorsSet.percentageColor}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          r={radius - 5}
          cx={size / 2}
          cy={size / 2}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div className="absolute w-3/4 text-xs text-center">
        {insideText}
      </div>
    </div>
  );
};