import clsx from 'clsx';
import {FC, ReactNode} from 'react';

export type FooterLinkProps = {
  label: string;
  href: string;
};

export type FooterProps = {
  links: FooterLinkProps[];
  reactAppVersion?: string;
  withCornerWidget?: boolean;
  bottomContent?: ReactNode;
  className?: string;
};

export const Footer: FC<FooterProps> = ({
  links,
  bottomContent = undefined,
  reactAppVersion = '',
  withCornerWidget = false,
  className = undefined,
}) => {
  const styles = {
    footerWrapper: clsx('text-center lg:text-left font-quicksand mt-16 relative bottom-0 mt-auto pt-8', className),
    footerContainer: clsx('bg-grey-100 p-4 text-center text-neutral-700', {
      'flex items-center': withCornerWidget,
      'grid lg:grid-cols-3 md:grid-cols-[auto_1fr_1fr] items-center': !withCornerWidget,
    }),
    systemVersionContainer: clsx(
      'lg:pl-24 md:pl-10 md:pr-2 justify-center md:justify-start lg:text-left text-sm flex md:order-1 md:leading-2',
      {
        '!pr-[8vw]': withCornerWidget,
      },
    ),
  };

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerContainer}>
        <div className="md:order-3 mb-[2px]">
          <ul className="list-none flex flex-col md:flex-row gap-4 justify-evenly items-center">
            {links.map(link => (
              <li key={link.href}>
                <a href={link.href} target="_blank" rel="noreferrer">
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:pb-0 md:mr-8 md:justify-self-end lg:justify-self-center md:order-2">
          Copyright © Symfonia {new Date().getFullYear()}
        </div>
        <div className={styles.systemVersionContainer}>
          {reactAppVersion ? `Wersja systemu: ${reactAppVersion}` : null}
        </div>
      </div>
      {bottomContent && <div className="bg-grey-50 p-2 text-grey-500">{bottomContent}</div>}
    </footer>
  );
};
