import {BaseModule} from '../../../../../../../../root/services/MobXServices/BaseModule';
import {ExtendedTableServiceI} from '../../../../../../../../root/services/TableServices/ExtendedTableService';
import {TableBuilder} from '../../../../../../../../root/services/TableServices/TableBuilder';
import {categoryDetailsColumns} from './CategoryDetailsTableConfig';
import {ICategoryDetailsState} from '../state/ICategoryDetailsState';
import {
  CategoryDetailsDataSourceHandler,
  CategoryDetailsRepositoryI,
  CategoryDetailsRow,
} from './CategoryDetailsRepository';
import {GetCategoryRulesQuery, GetCategoryRulesQueryVariables} from '@symfonia-ksef/graphql';
import {TableSortDirection} from '@symfonia/brandbook';
import {PaginationMode} from '../../../../../../../../root/services/TableServices/PaginationTableService';
import {SearchService, SearchServiceI} from '../../../../../../../../root/services/SearchService/SearchService';
import {computed, makeObservable} from 'mobx';
import {Pills} from '../../../../../../../../root/components/FiltersPills';
import {TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {intl} from '../../../../../../../../root/IntlProvider';

export type CategoryDetailsTableType = ExtendedTableServiceI<
  CategoryDetailsRow,
  'GetCategoryRules',
  GetCategoryRulesQuery,
  GetCategoryRulesQueryVariables,
  ICategoryDetailsState
>;

export class CategoryDetailsService extends BaseModule {
  public readonly tableService!: CategoryDetailsTableType;
  public readonly repository!: CategoryDetailsRepositoryI;
  public readonly searchService!: SearchServiceI;

  constructor(private readonly state: ICategoryDetailsState) {
    super();

    const dataSourceHandler = new CategoryDetailsDataSourceHandler(state);
    this.repository = dataSourceHandler.repository;
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);
    this.tableService = TableBuilder.create<
      CategoryDetailsRow,
      'GetCategoryRules',
      GetCategoryRulesQuery,
      GetCategoryRulesQueryVariables,
      ICategoryDetailsState
    >()
      .connectKeyFactory(row => row.Id)
      .connectColumns(categoryDetailsColumns)
      .connectSortBy({
        sortBy: {name: 'PositionNames', direction: TableSortDirection.ASC},
        persistKey: 'categoryDetailsTableSortBy',
      })
      .connectContext(state)
      .connectDataSource(dataSourceHandler)
      .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}, persistKey: 'categoryDetailsTablePagination'})
      .buildExtended();
    makeObservable(this);
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};

    for (const [filterKey, value] of this.state.filterState.pillsList.entries()) {
      const {values, translationKey} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: translationKey ?? filterKey.replace('.', '')}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.state.filterState.clearSingleFilter(filterKey, key, value);
            this.repository.fetch();
          },
        })),
      };
    }

    return convertedPills;
  }

  protected async _onMount(): Promise<void> {
    this.tableService.onMount();
    this.searchService.onMount();
    await this.repository.fetch();
  }

  protected _onUnmount() {
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }
}
