import {observer} from 'mobx-react-lite';
import {useIntl} from 'react-intl';
import React, {useState} from 'react';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {Icon, IconColor, IconSvg, Tabs} from '@symfonia/brandbook';
import {PostingDetailsHeaderGrid} from './PostingDetailsHeaderGrid';
import {VatRegistryTable} from '../VatRegistryTable/VatRegistryTable';

export interface PostingDetailsSubHeaderProps {
  state: IPostingDetailsState;
}

export const PostingDetailsSubHeader = observer(({state}: PostingDetailsSubHeaderProps) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('details');

  return (
    <div className="flex flex-col w-full md:min-h-[280px] min-h-[auto] px-[20px] pb-[10px]">
        <div className={'flex w-full items-end font-normal'}>
          <Tabs
            className={'grow'}
            fullWidthUnderline={true}
            tabs={[
              {
                text: <span className={'flex item-center'}>{intl.formatMessage({id: 'details'})}
                  {!state.validationVatRegisterIsNotNull
                    ? <Icon svg={IconSvg.ERROR_OUTLINE} color={IconColor.RED_500} className={'ml-[5px]'}/>
                    : null} </span>,
                isActive: activeTab === 'details',
                onClick: () => setActiveTab('details'),
              },
              {
                text: intl.formatMessage({id: 'vatRates'}),
                isActive: activeTab === 'vatRates',
                onClick: () => setActiveTab('vatRates'),
              },
            ]}
          />
        </div>
        {activeTab === 'details' && (
          <div className="flex w-full items-start justify-between">
            <PostingDetailsHeaderGrid state={state}/>
          </div>
        )}

        {activeTab === 'vatRates' && (
          <div className="flex w-full">
            <VatRegistryTable state={state}/>
          </div>
        )}
      </div>
  );
});
