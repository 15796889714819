import {AnyObject} from 'yup/es/types';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {BaseExtendedTableServiceI, Matrix, Row} from './BaseTableService';
import {action, computed, makeObservable, observable} from 'mobx';

export type FilterFactory<T extends AnyObject, C extends AnyObject = AnyObject> = (row: Row<T>, context?: C) => void
export type Filter<T extends AnyObject, C extends AnyObject = AnyObject> = { factory: FilterFactory<T, C> }

export interface FiltersTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModuleI {
  get isActive(): boolean;

  get filter(): Filter<T, C>;

  get matrix(): Matrix<T, C>;

  get rows(): Row<T>[];

  getMatrix(matrix: Matrix<T, C>, filter: Filter<T, C>, isActive: boolean): Matrix<T, C>;

  setIsActive(isActive: boolean): this;

  setFilter(filter: FilterFactory<T, C> | Filter<T, C>): this;
}

export class FiltersTableService<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModule implements FiltersTableServiceI<T, C> {

  constructor(protected wrapper: BaseExtendedTableServiceI<T, C>, filter: FilterFactory<T, C> | Filter<T, C>, isActive: boolean = true) {
    super();
    this._setFilter(filter);
    this._isActive = isActive;
    makeObservable(this);
  }

  @observable.ref
  private _filter!: Filter<T, C>;

  @computed
  public get filter(): Filter<T, C> {
    return this._filter;
  }

  @observable
  private _isActive!: boolean;

  @computed
  public get isActive(): boolean {
    return this._isActive;
  }

  @computed
  public get matrix(): Matrix<T, C> {
    return this.getMatrix(this.wrapper.matrix, this._filter, this.isActive);
  }

  @computed
  public get rows(): Row<T>[] {
    return this.wrapper.rows.filter(row => this._filter.factory(row, this.wrapper.context));
  }

  @action.bound
  public setIsActive(isActive: boolean): this {
    this._isActive = isActive;
    return this;
  }

  @action.bound
  public setFilter(filter: FilterFactory<T, C> | Filter<T, C>): this {
    this._setFilter(filter);
    return this;
  }

  public getMatrix(matrix: Matrix<T, C>, filter: Filter<T, C>, isActive: boolean): Matrix<T, C> {
    return isActive ? matrix.filter(item => filter.factory(item.row, this.wrapper.context)) : matrix;
  }

  private _setFilter(filter: FilterFactory<T, C> | Filter<T, C>): void {
    if (typeof filter === 'function') {
      this._filter = {factory: filter};
      return;
    }
    this._filter = filter;
  }
}
