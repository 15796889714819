import {useIntl} from 'react-intl';
import {PageMessage} from '../../../common';
import {Tr} from '../../../../locales/translationKeys';

export const MyEnvironmentsError = () => {
  const intl = useIntl();

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.myEnvironmentsErrorTitle})}
      description={intl.formatMessage({id: Tr.myEnvironmentsErrorDesc})}
    />
  );
};
