import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {VehicleFiltersKeys} from '../../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {autorun, makeObservable} from 'mobx';
import {VehicleState} from './VehicleState';
import {FilterScopeType, ListFilterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {IVehicleFilterState} from './IVehicleFilterState';

export class VehicleFilterState
  extends BaseSideMenuFilterState<VehicleState, VehicleFiltersKeys>
  implements IVehicleFilterState {

  defaultFilters = [
    {
      key: VehicleFiltersKeys.RegistrationNumber,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: VehicleFiltersKeys.VehicleUsageType,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.Enum,
        isPageFilter: true,
      },
    },
    {
      key: VehicleFiltersKeys.Description,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
  ];

  constructor(parent: VehicleState) {
    super(parent);
    makeObservable(this);
    this.setDefaultValues();
    autorun(() => {
      return this.parent.postingConfigurationState.companyId !== '' ? this.setDefaultValues() : null;
    });
  }

  protected override setDefaultValues() {
    let filterToCreate: ListFilterType = this.storage.loadFromStorage();

    if (!filterToCreate || !filterToCreate.length) {
      filterToCreate = this.defaultFilters;
    }

    filterToCreate.filter(f => f.value.isActive).map(f => {
      if (Object.keys(VehicleFiltersKeys).find(c => c === f.key)) {
        this.activeFilters.set(f.key as VehicleFiltersKeys, f.value);
      }
    });
    this.createPills();
  }
}
