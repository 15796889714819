import {AccountTypeEnum} from '@symfonia-ksef/graphql';
import {SearchTreeView} from '../../../../../components/AccountSelect/SearchTreeView';
import {ICreateCategoryModalState} from '../../state/ICreateCategoryModalState';
import {observer} from 'mobx-react-lite';
import {useEffect} from 'react';
import {AccountTypeRadioButtons} from '../../../../../components/AccountTypeRadioButtons/AccountTypeRadioButtons';
import {SearchInputForTree} from '../../../../../components/AccountSelect/SearchInputForTree';

export interface CreateCategorySecondStepProps {
  state: ICreateCategoryModalState;
}

export const CreateCategorySecondStep = observer(({state}: CreateCategorySecondStepProps): JSX.Element => {
  useEffect(() => {
    state.setAccountType(AccountTypeEnum.Kup);
  }, []);

  return (
    <>
      <AccountTypeRadioButtons onRadioBtnChange={radio => state.setAccountType(radio)}/>
      <SearchInputForTree state={state.searchTreeViewState}/>
      <div className='max-h-[200px] overflow-auto'>
        <SearchTreeView state={state.searchTreeViewState} isLoading={state.dataStore.isLoading}/>
      </div>
    </>
  );
});
