import {AnyObject} from 'yup/es/types';
import React, {createElement, Key, MouseEventHandler, ReactElement, ReactNode} from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import {isEmptyObj} from '../../../common/helpers/helperFunctions';
import {TableHeaderProps, TableSort, TableSortDirection} from '@symfonia/brandbook';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {ObserverTableCell, TableCell, TableCellProps} from '../../components/TableCell';
import {BasePersistService, BasePersistServiceI} from '../PersistServices/BasePersistService';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export type Row<T extends AnyObject> = { key: Key } & T

export type EventClick = Parameters<MouseEventHandler>[0]

export type Cell<T extends AnyObject, C extends AnyObject = AnyObject> = {
  content: ReactNode,
  tooltipContent?: ReactNode,
  className?: string,
  onClick?: MouseEventHandler<HTMLSpanElement>,
  ellipsisEnabled?: boolean,
  alignRight?: boolean
}
export type Col<T extends AnyObject, C extends AnyObject = AnyObject> = {
  width?: string,
  cell?: ReactNode | ((row: Row<T>, column: Col<T>, key: Key, context: C) => ReactNode),
  tooltipContent?: ReactNode | ((row: Row<T>, column: Col<T>, key: Key, context: C) => ReactNode),
  header: string | ((column: Col<T, C>, key: Key, sortBy: TableSort | undefined, setSortBy: (sortBy: TableSort) => void) => string),
  content?: ReactElement;
  headerClassName?: string
  sortable?: boolean,
  hidden?: boolean,
  order?: number,
  cellClassName?: string,
  sortingCompare?: (a: Row<T>, b: Row<T>, direction: TableSortDirection) => number
  isObserver?: boolean,
  cellAsTooltip?: boolean,
  ellipsisEnabled?: boolean,
  alignRight?: boolean,
  asAction?: boolean
}

export type Matrix<T extends AnyObject, C extends AnyObject = AnyObject> = {
  widths: string[],
  row: Row<T>,
  cells: ReactElement[],
  rowClassName?: string;
  onClick?: (focusedRow: Row<T>, context: C, e?: EventClick) => void;
}[]

export type Columns<T extends AnyObject, C extends AnyObject = AnyObject> = Partial<Record<keyof T, Col<T, C>>>

export type Column<T extends AnyObject, C extends AnyObject = AnyObject> = Col<T, C> & { field: string }

export interface BaseExtendedTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseTableServiceI<T, C> {
  // posortowane wiersze tabeli - do użytku w klasach dziedziczących
  get sortRows(): Row<T>[];

  //tworzy wiersz tabeli czyli listę komórek tabeli jako elementó reactowych - do użytku w klasach dziedziczących
  createTableRow(row: Row<T>, columns: Columns<T, C>, props?: Partial<TableCellProps<T, C>>): ReactElement[];
}

export interface BaseTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModuleI {

  //ustawia wiersze (ręczne ustawienie danych tabeli)
  setRows(rows: Array<T> | ((currentRows: Array<T>) => Array<T>)): void;

  //ustawia kolumny
  setColumns(columns: Columns<T, C> | ((currentColumns: Columns<T, C>) => Columns<T, C>)): void;

  //zawiera kontekst danej tabeli (może to być dowolny obiekt np. obiekt stanu) dostępny jako argument dla funkcji renderującej komórki w konfigu kolumn
  get context(): C;

  //Biekt zawierający dane do wyrenderowania tabeli wraz z macierzą samej tabeli
  get matrix(): Matrix<T, C>;

  //Lista danych potrzebnych do wyrenderowania nagłówków tabeli
  get headers(): TableHeaderProps[];

  //lista klas szerokości kolumn tabeli (kolejnośc pokrywa się z kolejnością kolumn)
  get widths(): string[];

  //konfiguruje wiersze tabeli
  configureRows(rowsConfig: Record<Key, T>): void;

  //konfiguruje kolumny tabeli
  configureColumns(columnsConfig: Partial<Record<keyof T, Partial<Omit<Col<T, C>, 'field' | 'cell'>>>>): void;

  //lista wierszy
  get rows(): Row<T>[];

  //Rekord aktywnych kolumn
  get columns(): Columns<T, C>;

  //lista kolumn
  get columnsList(): Col<T, C>[];

  //bierząca konfiguracja sortowania
  get sortBy(): TableSort | undefined;

  //mói o tym czy tabela zawiera kolumnę z akcją przypiętą do ekranu
  get actionIsSticky(): boolean;

  //konfiguruje warunki sortowania
  setSortBy(sortBy: Partial<TableSort>): void;

}

export type OnSortByChange = (sortBy: TableSort | undefined, prevTableSort: TableSort | undefined) => Promise<void> | void

export type Config<T extends AnyObject, C extends AnyObject = AnyObject> = {
  columns: Columns<T, C>,
  keyFactory: KeyFactory<T>,
  sortBy?: TableSort,
  context?: C,
  persistKey?: string,
  onSortByChange?: OnSortByChange
}

export type KeyFactory<T extends AnyObject> = (row: T) => Key

export class BaseTableService<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModule implements BaseExtendedTableServiceI<T, C> {

  private storage?: BasePersistServiceI<TableSort>;

  private readonly _context?: C;

  private readonly keyFactory!: KeyFactory<T>;

  private readonly onSortByChange?: OnSortByChange;

  constructor(config: Config<T, C>) {
    super();
    this.keyFactory = config.keyFactory;
    this._columns = config.columns;
    this._sortBy = config.sortBy;
    this._context = config.context;
    this.onSortByChange = config.onSortByChange;
    this._rows = [];
    makeObservable(this);
    if (config.persistKey) {
      this.storage = new BasePersistService<TableSort>(config.persistKey, earchiveState.envObserver);
    }
    this.onSortByChange && this.reactionsManager.add(() => this.sortBy, (sortBy, prevSortBy) => this.onSortByChange?.(sortBy, prevSortBy));
  }


  public get context(): C {
    return this._context ?? {} as C;
  }

  @computed
  public get actionIsSticky(): boolean {
    const maxOrder = Math.max(...this.columnsList.map(column => column.order ?? 0));
    const columnsAsActions = this.columnsList.filter(column => column.asAction);
    for (const column of columnsAsActions) {
      if (column.order === maxOrder) {
        return true;
      }
    }
    return false;
  }

  @observable.ref
  protected _sortBy?: TableSort;

  @computed
  public get sortBy(): TableSort | undefined {
    return this._sortBy;
  }

  @observable.ref
  protected _columns!: Columns<T, C>;

  @computed
  public get columns(): Columns<T, C> {
    return this._columns;
  }

  @observable.ref
  protected _rows!: Row<T>[];

  @computed
  public get rows(): Row<T>[] {
    return this._rows;
  }

  @computed
  public get columnsList(): Array<Column<T, C>> {
    return Object.entries(this._columns).filter(([_, column]) => !!column).map(([field, column]) => ({
      ...column,
      field,
    })) as Array<Column<T, C>>;
  }

  @computed
  public get sortRows(): Row<T>[] {
    if (!this.sortBy) {
      return this.rows;
    }
    const {name, direction} = this.sortBy;
    const {sortingCompare} = this.columns[name as keyof T] ?? {};
    return sortingCompare ? [...this.rows].sort((a, b) => sortingCompare(a, b, direction)) : this.rows;
  }

  @computed
  public get matrix(): Matrix<T, C> {
    return this.sortRows.map(row => {
      return ({
        widths: this.widths,
        cells: this.createTableRow(row, this.columns),
        row,
      });
    });
  }

  @computed
  public get headers(): TableHeaderProps[] {
    return this.columnsList
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .filter(column => !column.hidden)
      .map<TableHeaderProps>(column => {
        const {header, sortable, width, headerClassName, field, content} = column;
        const title = typeof header === 'function' ? header(column, String(field), this.sortBy, this.setSortBy.bind(this)) : header;
        const props = {
          name: String(field),
          title,
          sortable,
          width,
          className: headerClassName,
          content
        };
        return Object.keys(props).reduce<TableHeaderProps>((definedProps, key) => {
          if (definedProps[key as keyof TableHeaderProps] === undefined) {
            delete definedProps[key as keyof TableHeaderProps];
          }
          return definedProps;
        }, props);
      });
  }

  @computed
  public get widths(): string[] {
    return this.columnsList
      .filter(column => !column.hidden)
      .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
      .map(column => column.width ?? '');
  }

  public createTableRow(row: Row<T>, columns: Columns<T, C>, props?: Partial<TableCellProps<T, C>>): ReactElement[] {
    return Object.keys(row)
      .filter(field => {
        const column: Col<T, C> | undefined = columns[field];
        return column && !column.hidden;
      })
      .sort((fieldA, fieldB) => {
        const orderA: number = columns[fieldA]?.order ?? 0;
        const orderB: number = columns[fieldB]?.order ?? 0;
        return orderA - orderB;
      })
      .map(field => {
        const column: Col<T> = this.columns[field] as Col<T>;
        const {
          cell,
          cellClassName,
          isObserver,
          tooltipContent,
          cellAsTooltip,
          ...columnProps
        } = column;

        const key = `${row.key}.${field}`;
        const content = typeof cell === 'function' ? cell(row, column, key, this.context) : (cell ?? String(row[field]));
        const tooltip = typeof tooltipContent === 'function' ? tooltipContent(row, column, key, this.context) : tooltipContent;
        return createElement(isObserver ? ObserverTableCell : TableCell, {
          ...props,
          ...columnProps,
          row,
          content,
          key,
          className: cellClassName,
          tooltipContent: cellAsTooltip ? content : tooltip,
        });
      });
  }

  @action.bound
  public setSortBy(sortBy: Partial<TableSort>, persistEnabled: boolean = true): void {
    const {name, direction} = this._sortBy ?? {};
    this._sortBy = {name: name ?? '', direction: direction ?? TableSortDirection.ASC, ...sortBy};
    persistEnabled && this._sortBy && this.storage?.save?.(this._sortBy);
  }

  @action.bound
  public setRows(rows: Array<T> | ((currentRows: Array<T>) => Array<T>)): void {
    this._rows = this.createKeysWithRows(getValue(rows, this._rows));
  }

  @action.bound
  public setColumns(columns: Columns<T, C> | ((currentColumns: Columns<T, C>) => Columns<T, C>)): void {
    this._columns = getValue<Columns<T, C>>(columns, this._columns);
  }

  @action.bound
  public configureRows(rowsConfig: Record<Key, T>): void {
    if (isEmptyObj(rowsConfig)) {
      return;
    }

    this._rows = this._rows.map(row => {
      const newRow = rowsConfig[row.key];
      return newRow ? {...row, ...newRow} : row;
    });
  }

  public configureColumns(columnsConfig: Partial<Record<keyof (T), Partial<Omit<Col<T, C>, 'field' | 'cell'>>>>): void {
    if (isEmptyObj(columnsConfig)) {
      return;
    }
    const newColumns: Columns<T, C> = {...this._columns};
    for (const field in columnsConfig) {
      const col = {...this._columns[field], ...columnsConfig[field]};
      (newColumns[field] as typeof col) = col;
    }
    this.setColumns(newColumns);
  }

  protected override _onMount(): void {
    this.storage?.subscribe(sortBy => this.setSortBy(sortBy, false), {load: true, clear: true})?.onMount?.();
  }

  protected override _onUnmount(): void {
    this.storage?.onUnmount?.();
  }

  private createKeysWithRows(rows: Array<T>): Row<T>[] {
    return rows?.map?.(row => ({...row, key: this.keyFactory(row)}));
  }
}

const getValue = <T>(value: T | ((current: T) => T), current: T): T => typeof value === 'function' ? (value as (v: T) => T)(current) : value;
