import {IFiltersState} from './IFiltersState';
import {IInvoicePageState} from './IInvoicePageState';

export class FilterState implements IFiltersState {
  public invoicePageState: IInvoicePageState;

  constructor(invoicePageState: IInvoicePageState) {
    this.invoicePageState = invoicePageState;
  }
}
