import {FC, PropsWithChildren, Suspense} from 'react';
import {GlobalLoader} from '../GlobalLoader/GlobalLoader';

export const LazyComponent: FC<PropsWithChildren> = ({children}) => {
  return (
    <Suspense fallback={<GlobalLoader/>}>
      {children}
    </Suspense>
  );
};
