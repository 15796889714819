import {action, makeObservable, ObservableMap} from 'mobx';
import {localStorageService} from '../../modules/common/helpers/storage';
import {filterType, ListFilterType} from './BaseFilterState';
import {IBaseFilterStateStorage} from './IBaseFilterStateStorage';

export class BaseFilterStateStorage<T, K extends string>
  implements IBaseFilterStateStorage<T, K> {

  constructor() {
    makeObservable(this);
  }

  @action.bound
  mapToSave(filters?: ObservableMap<K, filterType>) {
    let x: ListFilterType = [];
    filters?.forEach((v, k) => {
      if (v.isActive) {
        x = [...x, {key: k, value: v}];
      }
    });
    return [...x];
  }

  @action.bound
  saveToStorage(filters: ObservableMap<K, filterType>) {
    localStorageService.setItem(String('Filters' + location.pathname), this.mapToSave(filters));
  }

  @action.bound
  loadFromStorage() {
    return localStorageService.getItem(String('Filters' + location.pathname)) as ListFilterType<K>;
  }

  @action.bound
  removeFromStorage(filters: ObservableMap<K, filterType>) {
    this.saveToStorage(filters);
  }
}

