import {BaseEventConsumer} from '../EventConsumer';
import {KSeFAuthResult, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../SubscriptionEventsService';
import {Tr} from '../../locales/translationKeys';
import {authenticationState} from '../../state/rootRepository';

export class RemoveAuthorizationInKSeFEventConsumer extends BaseEventConsumer {

  constructor() {
    super(WebsocketNotificationType.UnauthorizedInKsef, undefined, {
      success: {id: Tr.unAuthSubSuccess},
      error: {id: Tr.unAuthSubError},
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    !event.errorType && await authenticationState.updateUserAuthAndCredentials(KSeFAuthResult.NotAuthorized);
    return super.onUpdate(event);
  }
}
