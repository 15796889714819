import React, {useCallback} from 'react';
import {useApolloClient} from '@apollo/client';
import {
  GetPostingInvoicesReportDocument,
  GetPostingInvoicesReportFilterInput,
  GetPostingInvoicesReportQuery,
  ReportFileTypeEnum,
  SortEnumType,
} from '@symfonia-ksef/graphql';
import {FormattedMessage} from 'react-intl';
import fileSaver from 'file-saver';
import {BuildFilters} from '../../../../common/filters/FilterOptionExtensions';
import {l} from '../../../../logger/loggerInstance';
import {Tr} from '@symfonia-ksef/locales/keys';
import {base64ToBlob} from '../../../../common';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {IPostingTableState} from './State/IPostingTableState';

export const useDownloadPostingInvoicesReport = (state: IPostingTableState) => {
  const addAlert = earchiveState.alertsState.addAlert;
  const apolloClient = useApolloClient();

  const downloadPostingInvoicesReport = useCallback(
    async (reportFileType: ReportFileTypeEnum): Promise<void> => {
      const activeFilters = state.filterState.getActiveFiltersForQuery;

      const {GrossValue, ...rest} = activeFilters;

      const filters = {
        ...rest,
        Gross: GrossValue,
      };

      const response = await apolloClient.query<GetPostingInvoicesReportQuery>({
        query: GetPostingInvoicesReportDocument,
        context: {envId: state.postingState.earchiveState.companyId},
        variables: {
          reportFileType: reportFileType,
          where: activeFilters ? (BuildFilters(filters) as GetPostingInvoicesReportFilterInput) : undefined,
          order: [{InvoiceNumber: SortEnumType.Desc}],
        },
      });

      if (response.error || !response.data) {
        l.error('Error when downloading posting invoices report', undefined, response.errors);
        addAlert(<FormattedMessage id={Tr.postingInvoicesActionError}/>, ToastVariant.ERROR);
        return;
      }

      const file = response.data.GetPostingInvoicesReport;
      fileSaver(base64ToBlob(file.FileContent, file.MimeType), file.FileName);
    },
    [state.postingState.earchiveState.companyId],
  );

  return {
    downloadPostingInvoicesReport,
  };
};
