import * as React from 'react';


export interface OnOffFeatureProps extends React.PropsWithChildren {
  isEnabled?: boolean;
}

export const On = ({isEnabled, children}: OnOffFeatureProps) => (
    <>
      {isEnabled && children}
    </>
   );

   
export const Off = ({isEnabled, children}: OnOffFeatureProps) => (
  <>
    {!isEnabled && children}
  </>
 );