import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {usePostingConfigurationsMethods} from '../../../../hooks/usePostingConfigurationsMethods';
import {observer} from 'mobx-react-lite';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {PostingConfigurationActions} from '../../components/PostingConfigurationActions';

export interface CategoryAccountsTableActionsProps {
  state: ICategoryAccountState;
}

export const CategoryAccountsTableActions = observer(({state}: CategoryAccountsTableActionsProps) => {
  const intl = useIntl();
  const {getAccounts, saveChangesOnCategoryAccount} = usePostingConfigurationsMethods(state.postingConfigurationState);

  const handleSave = () => {
    saveChangesOnCategoryAccount();
  };

  const handleReset = () => {
    state.resetState();
    if (state.postingConfigurationState.selectedFinancialYear !== undefined)
      getAccounts(state.postingConfigurationState.selectedFinancialYear);
  };

  const disableButtons =
    !state.postingConfigurationState.isFinancialYearActive || state.categoryAccountChangedArray.length === 0;
  const emptyAccountsValidation = state.checkIfAccountsAreNotEmptyOnPurchase;

  const isTooltipOpen = !emptyAccountsValidation;

  const tooltipElement = (
    <>
      {intl.formatMessage({id: Tr.saveConfigurationBlockedExplanation})}
      <br/>
      {intl.formatMessage(
        {id: Tr.whyCantSaveConfigurationTxt},
        {
          br: <br/>,
        },
      )}
    </>
  );

  return (
    <div className="mt-[12px]">
      <PostingConfigurationActions
        secondaryButtonDisabled={disableButtons}
        primaryButtonDisabled={disableButtons || !emptyAccountsValidation}
        primaryButtonText={intl.formatMessage({id: Tr.save})}
        onPrimaryButtonClick={handleSave}
        onSecondaryButtonClick={handleReset}
        secondaryButtonText={intl.formatMessage({id: Tr.cancelButton})}
        primaryButtonTooltipOpen={isTooltipOpen}
        primaryButtonWithTooltip={true}
        primaryButtonTooltipText={tooltipElement}
      />
    </div>
  );
});
