import {FC, ReactNode, useMemo} from 'react';
import {Grid} from '@mui/material';
import {dateParser} from '../../../helpers/dateParser';
import {LinearPackageLimit} from '../LinearPackageLimit/LinearPackageLimit';
import {Thresholds} from '../LinearPackageLimit/LinearPackageLimitType';


export type LinearPackageLimitProps = {
  usage: number,
  limit: number,
  lineLabel?: string;
  unit?: string,
  inactive?: boolean,
  label?: string,
  thresholds: Thresholds,
  loading?: boolean,
  enableInheritedBackground?: boolean,
  formatted?: { usage?: string | number, limit?: string | number }
}
export type PackageUsage = Omit<LinearPackageLimitProps, 'inactive' | 'thresholds' | 'enableInheritedBackground'>

export type LinearPackageLimitsGroupProps = {
  loading?: boolean,
  inactive?: boolean,
  dateTo: Date | undefined,
  packageUsage: PackageUsage[],
  dateLabel: string,
  enableInheritedBackground?: boolean,
  redirect?: () => void,
  textColor?: string | undefined,
  wrap?: boolean | undefined
  tooltipHeader?: string;
}

export const LinearPackageLimitsGroup: FC<LinearPackageLimitsGroupProps> = ({
                                                                              inactive,
                                                                              dateTo,
                                                                              packageUsage,
                                                                              dateLabel,
                                                                              enableInheritedBackground,
                                                                              redirect,
                                                                              wrap,
                                                                              loading,
                                                                              tooltipHeader,
                                                                            }) => {
  const thresholds = useMemo<Thresholds>(() => [0, 80, 97], []);
  const renderedPackageLimitsItems = useMemo<ReactNode[]>(() => packageUsage.map((props, index) => (
    <Grid
      item xs={wrap ? 12 : 6}
      key={index}>
      <LinearPackageLimit
        {...props}
        label={props.label}
        usage={props.usage}
        limit={props.limit}
        unit={props.unit}
        loading={loading}
        inactive={inactive}
        thresholds={thresholds}
        enableInheritedBackground={enableInheritedBackground}
      />
    </Grid>),
  ), [packageUsage, inactive, thresholds, enableInheritedBackground, wrap]);

  return <>
    <div className={'flex flex-col font-quicksand text-sm'}>
      <div className={'mb-[15px]'}>{tooltipHeader}</div>
      <div className={'mb-[5px]'}>
        <span>{dateLabel}</span> <span>{dateTo && dateParser(dateTo)}</span>
      </div>
      <div className={(inactive ? 'opacity-50' : '')}>
        {renderedPackageLimitsItems}
      </div>
    </div>
  </>;
};
