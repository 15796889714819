import {FC, PropsWithChildren, memo, useCallback, useEffect, useState} from 'react';
import 'dayjs/locale/pl';
import {Input, InputType, InputWidth} from '@symfonia/brandbook';
import {Labels, isGreaterOrEqual, isLessOrEqual} from '../../../helpers/numbersRangeUtils';
import {InputNumberRange, NumberRange} from '../InputNumberRange/InputNumberRange';
import {useDebounce, usePrevious} from '../../../hooks';

type InputErrors = { from?: boolean, to?: boolean, specific?: boolean }

const checkFromValue = (from?: number, to?: number): boolean => to === undefined || from === undefined || isLessOrEqual(from, to);
const checkToValue = (to?: number, from?: number): boolean => from === undefined || to === undefined || isGreaterOrEqual(to, from);
const checkFromToValues = (values?: ValueType): InputErrors => ({
  from: !checkFromValue(values?.from, values?.to),
  to: !checkToValue(values?.to, values?.from),
  specific: false,
});
type ValueType = { from?: number, to?: number, specific?: number }
type ValueStringType = { from?: string, to?: string, specific?: string }

export interface NumberPickerProps extends PropsWithChildren {
  setValue: (value?: ValueType | undefined) => void;
  labelSpecific: string,
  labelRange: string,
  placeholders?: Partial<Labels>,
  valueFrom?: number,
  valueTo?: number,
  valueSpecific?: number,

  setValidationError?: (hasError: boolean) => void;
  testId?: string;
}

export const NumberPicker: FC<NumberPickerProps> = ({
                                                      labelSpecific,
                                                      labelRange,
                                                      setValue,
                                                      placeholders,
                                                      valueFrom,
                                                      valueTo,
                                                      valueSpecific,
                                                      setValidationError,
                                                      testId,
                                                    }) => {

  const [inputValues, setInputValues] = useState<ValueStringType>({
    from: valueFrom?.toString(),
    to: valueTo?.toString(),
    specific: valueSpecific?.toString(),
  });
  const [specValues, setSpecValues] = useState<string>('');
  const debouncedSetValue = useDebounce(setValue, 300);
  const debouncedSetInputValue = useDebounce(setInputValues, 300);
  const prevInputValues = usePrevious({from: valueFrom, to: valueTo, specific: valueSpecific});
  const [inputErrors, setInputErrors] = useState<InputErrors>({});

  useEffect(() => {
    setSpecValues(inputValues?.specific ?? '');
  }, [inputValues]);

  useEffect(() => {
    if (prevInputValues &&
      (valueFrom !== prevInputValues.from || valueTo !== prevInputValues.to || valueSpecific !== prevInputValues?.specific)) {
      setInputValues({
        from: valueFrom ? valueFrom?.toString() : undefined,
        to: valueTo?.toString() !== '' ? valueTo?.toString() : undefined,
        specific: valueSpecific ? valueSpecific?.toString() : undefined
      });
    }
  }, [prevInputValues]);

  useEffect(() => {
    if (!valueFrom && !valueTo && !valueSpecific) {
      setInputValues({
        from: undefined,
        to: undefined,
        specific: undefined
      });
    }

  }, [valueTo, valueFrom, valueSpecific]);

  const clearErrors = () => {
    setInputErrors({from: false, to: false, specific: false});
  };

  const handleValidateRange = (from: string, to: string) => {
    const fromValue = parseFloat(from);
    const toValue = parseFloat(to);
    if (!valueSpecific && fromValue > toValue) {
      setInputErrors({from: true, to: true, specific: false});
      return;
    }
    clearErrors();
  };

  const handleSpecificChange = useCallback((value: string) => {
    const createNewValue = (state: ValueStringType | undefined) => {
      return {
        specific: value,
        from: value === undefined ? state?.from : undefined,
        to: value === undefined ? state?.to : undefined,
      };
    };
    setInputValues(createNewValue);
    const val = String(value).replace(/,/g, '.');
    const specific = val ? parseFloat(val) : undefined;
    setValue({
      specific,
      from: 0,
      to: 0,
    });
    clearErrors();
  }, [debouncedSetValue]);

  useEffect(() => {
    const foundError = Object.values(inputErrors).includes(true);
    if (foundError) {
      setValidationError?.(true);
    } else {
      setValidationError?.(false);
    }
  }, [inputErrors, setValidationError]);

  const onChangeHandler = useCallback((value: NumberRange) => {
    const from = String(value.from).replace(/,/g, '.');
    const to = String(value.to).replace(/,/g, '.');
    setInputValues(value);
    setValue({
      specific: undefined,
      from: parseFloat(from),
      to: parseFloat(to),
    });
    handleValidateRange(from, to);
  }, [setValue]);

  return <div>
    <Input
      label={labelSpecific}
      placeholder={placeholders?.specific}
      width={InputWidth.FULL}
      value={specValues}
      onChange={handleSpecificChange}
      type={InputType.NUMBER}
      testId={`${testId}-SpecificValue`}
    />
    <div className={'mb-4'}/>
    <InputNumberRange
      isError={inputErrors.from || inputErrors.to}
      onChange={onChangeHandler}
      label={labelRange}
      placeholderFrom={placeholders?.from}
      placeholderTo={placeholders?.to}
      width={InputWidth.FULL}
      value={{
        from: inputValues.from ?? '0',
        to: inputValues.to ?? '0',
      }}
      min={0}
      testId={testId}
    />

  </div>;
};
