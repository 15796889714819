import {BaseEventConsumer} from '../EventConsumer';
import {KSeFAuthResult, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../SubscriptionEventsService';
import {authenticationState} from '../../state/rootRepository';

export class AutoRemoveAuthorizationInKSeFWithTokenEventConsumer extends BaseEventConsumer {

  constructor() {
    super(WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken);
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event);
    if (event.errorType) {
      return;
    }
    authenticationState.setAutoUnauthorizedNotificationId(event.notificationId);
    authenticationState.setExpiredToken(true);
    authenticationState.updateUserAuthAndCredentials(KSeFAuthResult.NotAuthorized);
    authenticationState.setUnauthorized();
  }
}
