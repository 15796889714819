import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useMemo} from 'react';
import {EnvironmentType} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {dateParser} from '../helpers';
import {ButtonPrimaryProps} from '@symfonia/brandbook';
import {redirectToSymfoniaShop} from '../helpers/redirectToSymfoniaShop';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {AccessGuard} from '@symfonia/symfonia-ksef-components';

type PackageSubscriptionAccessGuardProps = { forceAccess?: boolean }

export const PackageSubscriptionAccessGuard: FCC<PackageSubscriptionAccessGuardProps> = observer(({
                                                                                                    forceAccess,
                                                                                                    children,
                                                                                                  }) => {

  const {
    loadingError,
    loading,
    deactivationDate,
    subscriptionNotFound,
    subscriptionIsInactive,
  } = earchiveState.packageStatistics;


  const hasAccessPermission: boolean = useMemo(() => !!forceAccess || ((!subscriptionIsInactive || !!loadingError || loading) && !subscriptionNotFound), [forceAccess, loading, loadingError, subscriptionIsInactive, subscriptionNotFound]);
  const subscriptionDateTo = dateParser(deactivationDate ?? undefined);
  const {tenant: {state: {rootTenant}}, environments: {environments}} = earchiveState;
  const {formatMessage} = useIntl();

  const isAdmin = useMemo<boolean>(() => !!environments.find(env => env.Type === EnvironmentType.Tenant) && rootTenant?.Type === EnvironmentType.Tenant, [rootTenant, environments]);
  
  const confirmButton = useMemo<ButtonPrimaryProps | undefined>(() => isAdmin ? {
    text: formatMessage({id: Tr.buyPackage}),
    onClick: () => redirectToSymfoniaShop(earchiveState.packageStatistics),
  } : undefined, [formatMessage, isAdmin]);

  const message = useMemo<string>(() => subscriptionNotFound
    ? formatMessage({id: Tr.SubscriptionNotFoundMessage}, {
      messageForNonAdminUser: !isAdmin
        ? formatMessage({id: Tr.PackageInactiveContactWithAdmin})
        : '',
    })
    : formatMessage({id: Tr.PackageInactiveClarification}, {
      date: subscriptionDateTo,
      buyPackageMessage: formatMessage({id: Tr.PackageInactiveBuyPackage}),
      messageForNonAdminUser: !isAdmin
        ? formatMessage({id: Tr.PackageInactiveContactWithAdmin})
        : '',
    }).trim(), [subscriptionDateTo, subscriptionNotFound, isAdmin, formatMessage]);

  return <AccessGuard
    hasAccessPermission={hasAccessPermission}
    title={formatMessage({id: subscriptionNotFound ? Tr.SubscriptionNotFound : Tr.AccessDenied})}
    description={message}
    fallbackActions={confirmButton ? [
      {
        name: confirmButton.text as string,
        action: confirmButton.onClick as () => void,
      },
    ] : undefined}
  >
    {children}
  </AccessGuard>;


  // const modalContentSize = useMemo<string>(() => {
  //   return getModalMaxWidth();
  // }, []);
  //
  // return <>{hasAccessPermission ? children : <Modal
  //   show={true}
  //   title={formatMessage({id: subscriptionNotFound ? Tr.SubscriptionNotFound : Tr.AccessDenied})}
  //   text={message}
  //   okButton={confirmButton}
  //   centerButtons={true}
  //   showCloseIcon={false}
  //   className={modalContentSize}
  // />}</>;
});
