import {action, computed, makeObservable, observable} from 'mobx';
import type {DateValidationError} from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import {Tr} from '../../locales/translationKeys';
import {normalizeDate} from '../../modules/common/helpers/baseFilterHelpers';
import {
  DateTimeRangeInput,
  InvoiceBound,
  QuerySubscribeDownloadInvoicesFromKSeFArgs,
} from '@symfonia-ksef/graphql';
import {intl} from '../../modules/root/IntlProvider';
import {localStorageService} from '../../modules/common/helpers/storage';
import {
  getDateRangesLocalStorageKey,
  IDownloadedInvoicesResultService,
} from '../KSeFSubscriptionServices/DownloadedInvoicesResultService';
import dayjs from 'dayjs';

export type DateRangeFilterType = { from?: Date | null, to?: Date };

type ValidationError = Partial<Record<string, any>>

export type ValidationDateError = DateValidationError | 'emptyDate' | 'outOfRange' | 'changedDefaultDate';

export type DateType = 'start' | 'end' | undefined;

export type DateErrorMessage = { start: string | undefined, end: string | undefined };

export interface DatePickerStateInterface {
  date: DateRangeFilterType | undefined;
  validationErrors: ValidationError[] | undefined;
  dateRange: DateTimeRangeInput;
  startDate: Date | null;
  endDate: Date;
  errorMessage: DateErrorMessage | undefined;
  hasErrors: boolean | undefined;
  checked: boolean | undefined;
  setValidationError: (key: string, isInvalid: boolean, reason?: ValidationDateError | undefined, value?: string | null | undefined, type?: DateType) => void;
  resetValidationError: (type: DateType) => void;
  shouldBeDisabled: boolean;
  isDirtyAndValid: boolean;
  isMaxDateRangeExceeded: boolean;
  warning: string | undefined;

  get currentDate(): Date;

  setDate(date: DateRangeFilterType | undefined): void;

  setErrorMessage(e: string | undefined, type: DateType): void;

  resetDate(hasAlreadyDownloaded?: boolean): void;

  setChecked(checked: boolean | undefined): void;

  onDateInputError(reason: ValidationDateError, value: string | null | undefined, type: DateType, options?: {
    omit: boolean
  }): void;

}

export class DatePickerState implements DatePickerStateInterface {

  @observable
  invoiceType: InvoiceBound | undefined = undefined;

  @observable.ref
  public date: DateRangeFilterType | undefined = {
    from: this.defaultStartDate,
    to: this.currentDate,
  };

  @observable
  public warning: string | undefined = undefined;

  @observable
  public validationErrors: ValidationError[] | undefined = [];

  @observable.ref
  public errorMessage: DateErrorMessage = {start: undefined, end: undefined};

  @observable
  public checked: boolean | undefined = true;

  constructor(type: InvoiceBound, private dateService: IDownloadedInvoicesResultService) {
    makeObservable(this);
    this.invoiceType = type;
  }

  public get currentDate(): Date {
    return dayjs(new Date()).startOf('d').toDate();
  }

  public get defaultStartDate(): Date {
    return new Date(this.currentDate.setMonth(this.currentDate.getMonth() - 3));
  }

  public get noInvoicesStartDate(): Date {
    const dateCopy = new Date(this.currentDate.getTime());
    const twoYears = new Date(dateCopy.setFullYear(dateCopy.getFullYear() - 2));
    return new Date(twoYears.setDate(twoYears.getDate() + 1));
  }

  @computed
  get startDate() {
    return (this.date?.from ?? null);
  }

  @computed
  get endDate() {
    return this.date?.to ?? new Date(Date.now());
  }

  @computed
  get checkEmptyFromDate() {
    return this.date?.from === undefined;
  }

  @computed.struct
  public get dateRange() {
    const fromRawDate = this.date?.from ? this.date?.from : (this.date?.to ? this.date?.to.setMonth(this.date?.to.getMonth() - 3) : new Date().setMonth(new Date().getMonth() - 3));
    return {
      DateFrom: normalizeDate(true, new Date(fromRawDate)),
      DateTo: normalizeDate(false, this.date?.to) ?? null,
    };
  }

  @computed.struct
  public get hasErrors() {
    return this.validationErrors && this.validationErrors.length > 0;
  }

  @computed
  get shouldBeDisabled(): boolean {
    return !!(this.checked && this.validationErrors && (this.validationErrors.length > 0 || (!this.date?.from || !this.date?.to)));
  }

  @computed
  public get isDirtyAndValid(): boolean {
    return !!this.checked && (!!this.startDate && !!this.endDate);
  }

  @computed
  public get isMaxDateRangeExceeded(): boolean {
    const dateCopy = new Date(this.endDate.getTime());
    const twoYearsAgo = new Date(dateCopy.setFullYear(dateCopy.getFullYear() - 2));
    const minPossibleDate = new Date(twoYearsAgo.setDate(twoYearsAgo.getDate() + 1));
    const actualStartDate = this.startDate ? this.startDate : new Date(Date.now());
    return !!this.checked && (minPossibleDate > actualStartDate);
  }

  @action.bound
  public setDate(date: DateRangeFilterType) {
    if (!this.dateService.companyId) return;
    const localDates = localStorageService.getItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(this.dateService.companyId));

    const key = this.invoiceType === 'INTERNAL' ? 'internal' : 'external';
    if (!localDates) {
      this.date = date;
      localStorageService.setItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(this.dateService.companyId),
        {
          [key]: {DateFrom: date.from, DateTo: date.to},
          externalEnabled: this.invoiceType === InvoiceBound.External ? !!this.checked : true,
          internalEnabled: this.invoiceType === InvoiceBound.Internal ? !!this.checked : true,
        });
      return;
    }

    localStorageService.setItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(
      getDateRangesLocalStorageKey(this.dateService.companyId),
      {
        ...localDates,
        [key]: {DateFrom: date.from, DateTo: date.to},
      });
    this.date = date;
  }

  @action.bound
  public resetDate(hasAlreadyDownloaded?: boolean) {
    this.date = {
      from: dayjs(hasAlreadyDownloaded ? this.defaultStartDate : this.noInvoicesStartDate).toDate(),
      to: dayjs(this.dateService.maxDate).toDate(),
    };
  }

  @action.bound
  public setValidationError(key: string, isInvalid: boolean, reason: ValidationDateError | undefined, value: string | null | undefined, type?: DateType) {
    if (reason) {
      this.validationErrors?.push({isInvalid, reason, value, type});
      this.setErrorMessage(reason, type);
    } else {
      this.validationErrors = [];
      this.setErrorMessage(undefined, type);
    }
  }

  @action.bound
  public resetValidationError(type: DateType): void {
    if (!type) this.setErrorMessage(undefined, undefined);
    this.warning = undefined;
    this.validationErrors = [];
    this.setErrorMessage(undefined, type);
  }

  @action.bound
  public setErrorMessage(e: string | undefined, type: DateType) {
    if (!type) {
      this.errorMessage = {end: undefined, start: undefined};
      return;
    }
    this.errorMessage = {...this.errorMessage, [type]: e};
  }

  @action.bound
  public warnUser(e: string) {
    this.warning = e;
  }

  @action.bound
  public setChecked(checked: boolean | undefined) {
    if (!this.dateService.companyId) return;
    const localDates = localStorageService.getItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(this.dateService.companyId));
    if (!localDates) {
      this.checked = checked;
      localStorageService.setItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(getDateRangesLocalStorageKey(this.dateService.companyId),
        {
          externalEnabled: this.invoiceType === InvoiceBound.Internal ? true : !!checked,
          internalEnabled: this.invoiceType === InvoiceBound.External ? true : !!checked,
          internal: this.invoiceType === InvoiceBound.Internal ? {
            DateFrom: this.startDate,
            DateTo: this.endDate,
          } : undefined,
          external: this.invoiceType === InvoiceBound.External ? {
            DateFrom: this.startDate,
            DateTo: this.endDate,
          } : undefined,
        });
      return;
    }
    localStorageService.setItem<QuerySubscribeDownloadInvoicesFromKSeFArgs>(
      getDateRangesLocalStorageKey(this.dateService.companyId), this.invoiceType === InvoiceBound.Internal
        ?
        {...localDates, internalEnabled: !!checked}
        :
        {...localDates, externalEnabled: !!checked});
    this.checked = checked;
  }

  @action.bound
  public onDateInputError = (reason: ValidationDateError, value: string | null | undefined, type: 'start' | 'end' | undefined, options?: {
    omit: boolean
  } | undefined) => {
    if (options && options.omit && reason) {
      this.warnUser(intl.formatMessage({id: translateError(reason)}));
      return;
    }
    if (reason === 'emptyDate') {
      this.setValidationError('InvoicesDate', true, reason, value, type);
    } else if (reason && value && value.length === 10) {
      this.setValidationError('InvoicesDate', true, reason, value, type);
    } else {
      this.setValidationError('InvoicesDate', false, reason, value, type);
    }
  };

}

function translateError(e: string | undefined) {
  switch (e) {
    case 'emptyDate': {
      return Tr.completeTheDate;
    }
    case 'minDate': {
      return Tr.minDate;
    }
    case 'maxDate': {
      return Tr.maxDate;
    }
    case 'invalidDate': {
      return Tr.invalidDate;
    }
    case 'outOfRange': {
      return Tr.dateLessThenStart;
    }
    case 'changedDefaultDate': {
      return Tr.changingDefaultDate;
    }
    default:
      return undefined;
  }
}
