import {FC, useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import {Autocomplete, InputAdornment, InputLabel, PopperProps, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {styled} from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import {AutocompleteChangeReason} from '@mui/base';
import {ArrowDropDownOutlined} from '@mui/icons-material';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const StyledTextField = styled(TextField)(({}) => ({
  '& .MuiSvgIcon-root.MuiChip-deleteIcon': {
    display: 'none',
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& .MuiAutocomplete-popper': {
    borderRadius: '0 !important',
  },

}));

const StyledMenuItem = styled(MenuItem)(({}) => ({
  border: '1px solid rgba(0,0,0, 0.3) !important',
  borderRadius: '4px !important',
  background: 'white !important',
  padding: '4px 15px !important',
}));

export type MultiSelectModel = {
  value: string,
  key: string
}

type PopperComponetProps = {
  param: PopperProps;
  checkAllChange: () => void;
  checkedAll: boolean;
  checkAllLabel: string;
  popperOpen: boolean;
  setPopperOpen: (b: boolean) => void;
};

const PopperComponent: FC<PopperComponetProps> = ({
                                                    param,
                                                    checkAllChange,
                                                    checkedAll,
                                                    checkAllLabel,
                                                    popperOpen,
                                                    setPopperOpen
                                                  }) => {
  const {children, className, style} = param;

  return <Popper {...param} open={popperOpen} onBlur={() => setPopperOpen(!popperOpen)} placement="bottom-start">
    <StyledMenuItem key={'PopperStyledComponent' + param.itemID}
                    className="multiselect-option"
                    onClick={() => checkAllChange()}
                    onMouseDown={(e) => e.preventDefault()}>
      <Checkbox
        icon={icon}
        id="check-all"
        checkedIcon={checkedIcon}
        style={{marginRight: 8}}
        checked={checkedAll}
        onMouseDown={(e) => e.preventDefault()}
      />
      {checkAllLabel}
    </StyledMenuItem>
    {/*// @ts-ignore*/}
    <Box className={className} style={style} sx={{border: '1px red', borderRadius: 0}}>{children}</Box>
  </Popper>;
};

export interface MultiSelectProps {
  label?: string | undefined;
  checkAllLabel: string;
  optionsList: MultiSelectModel[];
  disabled: boolean;
  itemsList: MultiSelectModel[];
  placeholder?: string;
  setParentItemsSelected: (data: MultiSelectModel[] | []) => void;
}

export const MultiSelect = ({
                              optionsList,
                              disabled,
                              itemsList,
                              setParentItemsSelected,
                              label,
                              checkAllLabel,
                              placeholder,
                            }: MultiSelectProps) => {
  const [openInput, setOpenInput] = useState<boolean>(false);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<MultiSelectModel[] | undefined>(undefined);

  const checkAllChange = () => {
    setCheckedAll(!checkedAll);
    setParentItemsSelected(checkedAll ? [] : [...optionsList]);
    setSelectedItems(checkedAll ? [] : [...optionsList]);
  };

  const handleOnChange = (val: MultiSelectModel | undefined, reason: AutocompleteChangeReason) => {
    if (val === undefined) {
      return;
    }
    const newItems = selectedItems?.some(e => e.key === val?.key) ? selectedItems?.filter(i => i.key !== val.key) : [...(selectedItems ?? []), val];

    if (reason === 'removeOption') {
      setCheckedAll(false);
    } else if (optionsList.length === newItems.length) {
      setCheckedAll(true);
    }
    setSelectedItems(newItems);
  };

  const handleSelect = () => {
    setOpenInput(false);
    setParentItemsSelected(selectedItems ?? []);
  };

  useEffect(() => {
    setSelectedItems(itemsList);
    itemsList.length === optionsList.length ? setCheckedAll(true) : setCheckedAll(false);
  }, [itemsList, optionsList.length]);


  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      {label && <InputLabel
        required
        sx={{
          lineHeight: '2.5rem',
          position: 'absolute',
          transform: 'none',
        }}>{label}</InputLabel>}

      {!!selectedItems &&
        <Autocomplete
          onBlur={() => setPopperOpen(false)}
          placeholder={placeholder}
          disabled={disabled}
          sx={{width: '100%', backgroundColor: 'white'}}
          multiple
          disableCloseOnSelect={true}
          id="combo-box-demo"
          size="small"
          options={optionsList}
          limitTags={3}
          open={popperOpen}
          value={selectedItems}
          getOptionLabel={option => option.value}
          onChange={(event, value, reason, details) => {
            handleOnChange(details?.option, reason);
          }}
          onClose={handleSelect}
          noOptionsText={'Brak wyników'}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          PopperComponent={(param) => {
            return <PopperComponent param={param} setPopperOpen={setPopperOpen} popperOpen={popperOpen}
                                    checkAllChange={checkAllChange} checkedAll={checkedAll}
                                    checkAllLabel={checkAllLabel}/>;
          }}
          renderOption={(props, option, {}) => {
            return <MenuItem {...props} key={props.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{marginRight: 8}}
                checked={selectedItems.some(e => e.key === option.key)}
              />
              {option.value}
            </MenuItem>;
          }}

          popupIcon={
            <InputAdornment onClick={() => {
              setOpenInput(!openInput);
              setPopperOpen(!popperOpen);
            }}
                            sx={{p: 0.1, m: 0, width: '1rem', height: '1rem'}}
                            title={openInput ? 'Zamknij' : 'Otwórz'} position="end">
              <ArrowDropDownOutlined sx={{textAlign: 'center', width: '1rem', height: '1rem'}}/>
            </InputAdornment>
          }
          style={{width: 500}}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onKeyDown={(e) => {
                setPopperOpen(true);
              }}
              size={'small'}
              placeholder={(itemsList && itemsList.length > 0) || (selectedItems && selectedItems.length > 0) ? '' : placeholder}/>
          )}
        />
      }
    </Box>
  );
};
