import React, {FC, useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useForm, useFormState} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ItemPermissions} from '../../../models';
import validationSchema from './selfInvoicingValidationSchema';
import {Tr} from '@symfonia-ksef/locales/keys';
import {earchiveState, grantPermissionsService} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {PermissionAlertType} from '../../../../../state/KSeFSubscriptionServices/GrantPermissionsService';
import {KSeFIdentifierType} from '@symfonia-ksef/graphql';
import {
  Breadcrumbs,
  ButtonSecondary,
  ButtonTertiary,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  IconVariant,
  Input,
  ToastVariant,
  Tooltip,
} from '@symfonia/brandbook';
import {ConfirmModal, LoadingButton, PageContent} from '@symfonia/symfonia-ksef-components';
import {Header} from '../../../../root/components/Header';

export interface AddEditSelfInvoicingProps {
  isEditMode?: boolean;
  onSubmit: (permissionAlertType: PermissionAlertType) => void;
  handleBack: () => void;
}

export const AddEditSelfInvoicing: FC<AddEditSelfInvoicingProps> = observer(({isEditMode, onSubmit, handleBack}) => {
  const {
    jobRunner: {permissions, loading},
  } = grantPermissionsService;
  const intl = useIntl();
  const {company: {currentEnvironment, ksefCredentials}} = earchiveState;
  if (!currentEnvironment) {
    throw new Error('selectedCompanyEnvironment not set');
  }

  const addAlert = earchiveState.alertsState.addAlert;
  const {control, getValues, reset, setValue, watch} = useForm<ItemPermissions>({
    defaultValues: permissions,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [usedNIP, setUsedNIP] = useState<string | undefined>(undefined);
  const {isDirty, isValid, isSubmitting, isSubmitted} = useFormState({
    control,
  });

  const isLoading =
    (!!permissions.Identifier && grantPermissionsService.checkIsPending(permissions.Identifier)) || loading;
  const resetPermissionToDefaultValues = useCallback(
    () => grantPermissionsService.reset(permissions => reset(permissions)),
    [],
  );

  useEffect(() => {
    !isEditMode && resetPermissionToDefaultValues();
  }, [isEditMode, resetPermissionToDefaultValues]);

  const handleDeleteClick = () => {
    setOpenDeleteModal(true);
  };

  const onCancelDeletePermission = () => {
    setOpenDeleteModal(false);
  };

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.permissions}),
      onClick: () => handleBack(),
    },
    {
      label: intl.formatMessage({id: Tr.CredentialsSelfInvoicing}),
    },
  ];

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px] w-[50%]">
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <ButtonTertiary
        test-id="backToPermissionsSelfInvoicingButton"
        onClick={handleBack}
        text={intl.formatMessage({id: Tr.backToPermissions})}
        lIcon={IconSvg.CHEVRON_LEFT}
        className="mt-[10px]"
      />
      <Header>{intl.formatMessage({id: Tr.SelfInvoicing})}</Header>
      <ConfirmModal
        title={intl.formatMessage({id: Tr.deleteSelfInvoicingActionTitle})}
        test-id="deleteSelfInvoicingConfirmModal"
        content={
          <FormattedMessage
            id={Tr.deleteSelfInvoicingActionContent}
            values={{
              identifier: permissions.Identifier,
              br: <br/>,
            }}
          />
        }
        confirmButtonName={intl.formatMessage({id: Tr.continue})}
        cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
        open={openDeleteModal}
        onCancel={onCancelDeletePermission}
        onConfirm={async () => {
          await grantPermissionsService.delete(KSeFIdentifierType.InstitutionalNip, onSubmit);
          setOpenDeleteModal(false);
        }}
      />

      <div className="max-w-[650px] py-[30px]">
        <FormattedMessage id={Tr.selfInvoicingDescription}/>
      </div>
      <div className="flex">
        <Input
          test-id="nipSelfInvoicingInput"
          isError={!isValid && watch('Identifier') !== ''}
          notification={!isValid && watch('Identifier') !== '' ? intl.formatMessage({id: Tr.wrongNip}) : undefined}
          label={
            <div className="flex flex-row">
              <FormattedMessage id={Tr.PermissionsFor}/>
              {!isEditMode && (
                <Tooltip text={intl.formatMessage({id: Tr.nipTooltip_Content})}>
                  <Icon
                    className={'ml-[10px]'}
                    svg={IconSvg.INFO}
                    color={IconColor.GREEN_500}
                    variant={IconVariant.FILLED}
                    size={IconSize.MD}
                  />
                </Tooltip>
              )}
            </div>
          }
          disabled={isEditMode}
          onInput={value => {
            setValue('Identifier', value, {shouldValidate: true, shouldDirty: true});
          }}
          required={true}
          value={watch('Identifier')}
        />
      </div>
      <div className="mt-[30px] flex flex-row justify-end items-center buttonFixed">
        {!isEditMode && (
          <ButtonSecondary
            test-id="selfInvoicingCancelButton"
            text={intl.formatMessage({id: Tr.cancelButton})}
            disabled={!isDirty || isSubmitting || isSubmitted}
            onClick={resetPermissionToDefaultValues}
            className="mr-[10px]"
          />
        )}

        {!isEditMode && (
          <LoadingButton
            test-id="selfInvoicingSaveButton"
            className="mr-[10px]"
            loading={isLoading}
            text={intl.formatMessage({id: isEditMode ? Tr.save : Tr.AddPermission})}
            onClick={() => {
              setValue('Identifier', getValues().Identifier.split('-').join(''));
              grantPermissionsService.setPermissions({...getValues(), CredentialsSelfInvoicing: true, IsNew: true});
              grantPermissionsService.add(KSeFIdentifierType.InstitutionalNip, {
                onSuccess: onSubmit,
                onError: errors => {
                  setUsedNIP(permissions.Identifier);
                  addAlert(intl.formatMessage({id: Tr.addSelfInvoicingToExistingNIPError}), ToastVariant.ERROR);
                },
              });
            }}
            disabled={!isDirty || !isValid || getValues().Identifier === usedNIP}
          />
        )}

        {isEditMode && (
          <LoadingButton
            test-id="selfInvoicingDeleteButton"
            text={intl.formatMessage({id: isEditMode ? Tr.removeSelfInvoicing : Tr.removeButton})}
            loading={isLoading}
            onClick={handleDeleteClick}
          />
        )}
      </div>
      </div>
    </PageContent>
  );
});
