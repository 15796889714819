import {DateInput, ErrorTypes, OnChange} from '../DateInput/DateInput';
import dayjs, {Dayjs} from 'dayjs';
import {FormLabel, Grid} from '@mui/material';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {usePrevious} from '../../hooks';

type DateType = 'start' | 'end' | undefined;

type OnDateInputError = (reason: ErrorTypes, value: string | null, type: DateType, options?: {
  omit: boolean
}) => void

export interface DateRangeProps {
  leftArrowButtonText?: string | undefined,
  rightArrowButtonText?: string | undefined,
  cantBeEmpty?: { from?: boolean, to?: boolean }
  defaultValue?: Readonly<{
    from?: Date | null, to?: Date, specific?: Date
  }>;
  startDate?: Date | null | undefined;
  specificDate?: Date;
  endDate?: Date;
  disabled?: boolean | undefined;
  singleDateLabel: string;
  rangeStartDateLabel: string;
  rangeEndDateLabel: string;
  singleDateInputLabel: string;
  rangeStartDateInputLabel: string;
  rangeEndDateInputLabel: string;
  setValidationError?: (hasError: boolean) => void;
  onChangeClearValidation: (type: DateType) => void;
  onDateInputError: OnDateInputError;
  setDate: (value?: {
    from?: Date | null, to?: Date, specific?: Date
  }) => void;
  enableReturnButton?: boolean | undefined;
  returnLabel?: string | undefined;
  startDateErrorMessage?: string | undefined;
  endDateErrorMessage?: string | undefined;
  maxDate?: dayjs.Dayjs | undefined;
  minDate?: dayjs.Dayjs;
  relativeRangeDisabled?: boolean
}

export const DateRange: FC<DateRangeProps> = observer(({
                                                         leftArrowButtonText,
                                                         rightArrowButtonText,
                                                         defaultValue,
                                                         returnLabel,
                                                         startDate,
                                                         endDate,
                                                         setDate,
                                                         disabled,
                                                         onChangeClearValidation,
                                                         rangeStartDateLabel,
                                                         rangeStartDateInputLabel,
                                                         rangeEndDateInputLabel,
                                                         rangeEndDateLabel,
                                                         onDateInputError,
                                                         enableReturnButton,
                                                         startDateErrorMessage,
                                                         endDateErrorMessage,
                                                         maxDate,
                                                         minDate,
                                                         cantBeEmpty,
                                                         relativeRangeDisabled,
                                                       }) => {

      const [startShowReturn, setStartShowReturn] = useState<boolean>(false);
      const [endShowReturn, setEndShowReturn] = useState<boolean>(false);
      const todayDate = useRef<Date>(new Date(new Date().toLocaleDateString('en-US'))).current;


      const minEndDate = useBoundaryRange({
        relativeRangeDisabled,
        currentDate: startDate,
        boundaryDate: minDate,
        variant: Boundary.end,
      });

      const maxStartDate = useBoundaryRange({
        relativeRangeDisabled,
        currentDate: endDate,
        boundaryDate: maxDate,
        variant: Boundary.end,
      });

      const {
        extendedError: extendedStartDateError,
        setInternalError: setStartInternalError,
      } = useExtendedValidation(startDate, endDate, onDateInputError, {
        boundary: Boundary.start,
        maxDate,
        minDate,
        cantBeEmpty: cantBeEmpty?.from,
        onClearError: () => onChangeClearValidation('start'),
      });

      const {
        extendedError: extendedEndDateError,
        setInternalError: setEndInternalError,
      } = useExtendedValidation(endDate, startDate, onDateInputError, {
        boundary: Boundary.end,
        maxDate,
        minDate,
        cantBeEmpty: cantBeEmpty?.to,
        onClearError: () => onChangeClearValidation('end'),
      });

      const handleStartDateChange = useCallback<OnChange>((newDate: dayjs.Dayjs | null) => {
        onChangeClearValidation('start');
        if (!newDate) {
          setDate({to: endDate ?? todayDate, from: undefined});
          return;
        }
        if (defaultValue?.from && !dayjs(defaultValue.from).isSame(newDate)) {
          setStartShowReturn(true);
        }
        setDate({from: newDate.toDate(), to: endDate ?? (cantBeEmpty?.to ? todayDate : defaultValue?.to ?? undefined)});
        if (!endDate) {
          onChangeClearValidation('end');
        }
      }, [onChangeClearValidation, defaultValue?.from, setDate, endDate, cantBeEmpty?.to]);

      const handleEndDateChange = useCallback<OnChange>((newDate: dayjs.Dayjs | null) => {
        onChangeClearValidation('end');
        if (!newDate) {
          setDate({from: startDate, to: undefined});
          return;
        }
        if (defaultValue?.to && !dayjs(defaultValue.to).isSame(newDate)) {
          setEndShowReturn(true);
        }
        setDate({
          from: startDate ?? (cantBeEmpty?.from ? newDate.subtract(1, 'months').toDate() : defaultValue?.from ?? undefined),
          to: newDate.toDate(),
        });
        if (!startDate) {
          onChangeClearValidation('start');
        }
      }, [startDate, defaultValue?.to, setDate, onChangeClearValidation, cantBeEmpty?.from]);

      return (
        <Grid item xs={12} alignItems="baseline" display="flex">
          <Grid item xs={2} sx={{mr: 2}}>
            <FormLabel>{rangeStartDateLabel}</FormLabel>
          </Grid>
          <Grid item xs={4}>
            <DateInput
              cantBeEmpty={cantBeEmpty?.from}
              leftArrowButtonText={leftArrowButtonText}
              rightArrowButtonText={rightArrowButtonText}
              disabled={disabled}
              onChange={handleStartDateChange}
              value={startDate?.toLocaleDateString() ? dayjs(startDate).toString() : null}
              dateInputLabel={rangeStartDateInputLabel}
              maxDate={maxStartDate}
              minDate={minDate}
              isError={!!extendedStartDateError}
              onError={(reason, value) => {
                setStartInternalError(reason ? {reason, value} : undefined);
                onDateInputError(reason, value, 'start');
              }}
              enableReturnButton
              showReturn={startShowReturn}
              returnLabel={returnLabel}
              errorMessage={startDateErrorMessage}
              resetDate={() => {
                if (defaultValue) {
                  setDate({
                    from: defaultValue?.from,
                    to: endDate?.toLocaleDateString() ? dayjs(endDate).toDate() : undefined,
                  });
                  onChangeClearValidation('start');
                  setStartShowReturn(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{m: 1, ml: 4, mr: 0.5}}>
            <FormLabel>{rangeEndDateLabel}</FormLabel>
          </Grid>
          <Grid item xs={4}>
            <DateInput
              cantBeEmpty={cantBeEmpty?.to}
              leftArrowButtonText={leftArrowButtonText}
              rightArrowButtonText={rightArrowButtonText}
              disabled={disabled}
              onChange={handleEndDateChange}
              dateInputLabel={rangeEndDateInputLabel}
              value={endDate?.toLocaleDateString() ? dayjs(endDate).toString() : null}
              minDate={minEndDate}
              maxDate={maxDate}
              isError={!!extendedEndDateError}
              onError={(reason, value) => {
                setEndInternalError(reason ? {reason, value} : undefined);
                onDateInputError(reason, value, 'end');
              }}
              errorMessage={endDateErrorMessage}
              enableReturnButton
              showReturn={endShowReturn}
              returnLabel={returnLabel}
              resetDate={() => {
                if (defaultValue) {
                  setDate({
                    from: startDate?.toLocaleDateString() ? dayjs(startDate).toDate() : undefined,
                    to: defaultValue?.to,
                  });
                  onChangeClearValidation('end');
                  setEndShowReturn(false);
                }
              }}
            />
          </Grid>
        </Grid>
      );
    },
  )
;

export enum Boundary {
  start = 'start',
  end = 'end',
}

type BoundaryRangeProps = { relativeRangeDisabled?: boolean, boundaryDate?: Dayjs, currentDate?: Date | null, variant: Boundary }

const useBoundaryRange = ({relativeRangeDisabled, boundaryDate, currentDate, variant}: BoundaryRangeProps) => {
  return useMemo(() => {
    if (relativeRangeDisabled) {
      return boundaryDate;
    }
    if (boundaryDate) {
      if (currentDate) {
        return dayjs(boundaryDate[variant === Boundary.end ? 'isBefore' : 'isAfter'](currentDate) ? boundaryDate : currentDate);
      }
      return dayjs(currentDate);
    }
    return boundaryDate;
  }, [relativeRangeDisabled, currentDate, boundaryDate]);
};

type Error = { reason: ErrorTypes, value: string | null } | undefined

const useExtendedValidation = (date: Date | null | undefined, relatedDate: Date | null | undefined, onError: OnDateInputError, opt: {
  minDate?: Dayjs, maxDate?: Dayjs, cantBeEmpty?: boolean, boundary: Boundary, onClearError?: () => void
}) => {
  const {minDate, cantBeEmpty, maxDate, boundary} = opt;
  const [extendedError, setExtendedError] = useState<Error>();
  const [internalError, setInternalError] = useState<Error>();
  const prevDate = usePrevious(date);
  const prevRelatedDate = usePrevious(relatedDate);
  const prevExtendedError = usePrevious(extendedError);
  const prevInternalError = usePrevious(internalError);

  const handleEmptyError = useCallback((value: Dayjs | null): Error => {
    return !value && cantBeEmpty ? {reason: 'emptyDate', value} : undefined;
  }, [cantBeEmpty]);

  const handleInvalidDateError = useCallback((value: Dayjs | null): Error => {
    return (value && !dayjs(value).isValid()) ? {
      reason: 'invalidDate',
      value: value.toString(),
    } : undefined;
  }, []);

  const validateBoundary = useCallback((value: Dayjs | undefined | null, boundary: Dayjs | undefined, variant: Boundary): boolean => {
    return value && boundary ? value[variant === Boundary.start ? 'isAfter' : 'isBefore'](boundary) || value.isSame(boundary) : true;
  }, []);

  const validateRange = useCallback((newDate: Dayjs | null, related: boolean = false): Error => {
    let hasError: boolean = false;
    if (!relatedDate) {
      return undefined;
    }
    const date = dayjs(relatedDate).startOf('d');
    const boundaryValidations: boolean[] = [
      validateBoundary(newDate, minDate, Boundary.start),
      validateBoundary(newDate, maxDate, Boundary.end),
    ];
    if (boundaryValidations.every(Boolean)) {
      switch (boundary) {
        case Boundary.end: {
          hasError = !!newDate?.isBefore(date);
          break;
        }
        case Boundary.start: {
          hasError = !!newDate?.isAfter(date);
        }
      }
    }

    if (related) {
      return !hasError && extendedError?.reason === 'outOfRange' ? undefined : extendedError;
    }

    return hasError ? {
      reason: 'outOfRange',
      value: newDate?.toString() ?? null,
    } : undefined;
  }, [relatedDate, boundary, minDate, maxDate, extendedError]);

  const runExtendedValidation = useCallback((newDate: Dayjs | null) => {
    const [error]: Error[] = [handleEmptyError(newDate), handleInvalidDateError(newDate), validateRange(newDate)].filter(Boolean);
    setExtendedError(error);
  }, [validateRange, handleEmptyError, handleInvalidDateError]);

  useEffect(() => {
    if (date === prevDate) {
      return;
    }
    const toValidate = date ? dayjs(new Date(date).toLocaleDateString('en-US')) : null;
    runExtendedValidation(toValidate);
  }, [runExtendedValidation, date]);

  useEffect(() => {
    if (date === prevDate && relatedDate !== prevRelatedDate && extendedError?.reason === 'outOfRange' && relatedDate && !handleEmptyError(dayjs(relatedDate))) {
      const dateToValid = date ? dayjs(new Date(date).toLocaleDateString('en-US')) : null;
      setExtendedError(validateRange(dateToValid, true));
    }
  }, [validateRange, relatedDate, date, extendedError, opt.onClearError, handleEmptyError]);

  useEffect(() => {
    if (extendedError && extendedError !== prevExtendedError) {
      onError(extendedError?.reason, extendedError?.value, boundary);
    }
  }, [extendedError, boundary, onError]);

  useEffect(() => {
    if (!extendedError && !internalError && (prevExtendedError || prevInternalError)) {
      opt?.onClearError?.();
    }
  }, [extendedError, internalError, opt?.onClearError]);

  return {extendedError, setInternalError, internalError};
};
