import {ButtonPrimary, ButtonPrimaryVariant, ButtonSecondary, IconSvg, Modal} from '@symfonia/brandbook';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {useState} from 'react';
import {intlFunction} from '../utils/utils';

type Props = {
  clearSelection: () => void;
  state: IFilehubState;
};

const DeleteAttachmentModal = observer(({clearSelection, state}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {deleteAttachmentAction} = useFilehubMethods(state, clearSelection);
  const moreThanOne = state.deleteAttachmentsList.length > 1;

  const handleModalClose = () => {
    state.setIsDeleteAttachmentModalOpen(false);
  };

  const deleteAttachments = async () => {
    setIsLoading(true);
    await deleteAttachmentAction(state.deleteAttachmentsList);
    setIsLoading(false);
    state.setIsDeleteAttachmentModalOpen(false);
  };

  return (
    <Modal
      className="w-[600px]"
      onClose={handleModalClose}
      show={state.isDeleteAttachmentModalOpen}
      title={intl.formatMessage({id: Tr.deletingAttachments})}
    >
      <div className="flex flex-col gap-[24px]">
        <div>{intlFunction(moreThanOne ? Tr.confirmDeletingAttachments : Tr.confirmDeletingAttachment)}</div>
        <div>
          {state.deleteAttachmentsList.map(attachment => (
            <>
              <strong>{attachment}</strong>
              <br/>
            </>
          ))}
        </div>
        <div className="flex justify-center gap-[16px]">
          <ButtonSecondary
            className="w-full"
            disabled={isLoading}
            onClick={handleModalClose}
            text={intl.formatMessage({id: Tr.cancelButton})}
          />
          <ButtonPrimary
            className="w-full"
            loading={isLoading}
            onClick={deleteAttachments}
            text={intl.formatMessage({id: Tr.removeButton})}
            variant={ButtonPrimaryVariant.RED}
          />
        </div>
      </div>
    </Modal>
  );
});

export default DeleteAttachmentModal;
