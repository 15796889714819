import {createTheme} from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  export interface PaletteOptions {
    custom?: {
      white: string;
    };
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    infoBox?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    infoBox?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    infoBox: true;
  }
}

export const adminTheme = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(','),
    fontSize: 16,
    body1: {
      fontWeight: 'normal',
      fontSize: 16,
    },
    body2: {
      fontWeight: 'normal',
      fontSize: 16,
    },
    infoBox: {
      fontWeight: 'normal',
      fontSize: 18
    }
  },
  palette: {
    primary: {
      main: '#009a00',
    },
    secondary: {
      main: '#003349',
    },
    custom: {
      white: '#FFFFFF',
    },
  },
  components: {},
});
