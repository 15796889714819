import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {PostingStatusEnum} from '@symfonia-ksef/graphql';
import {SelectionI} from '../../../../root/services/TableServices/ExtendedTableService';
import {MultipleActionsBar} from '../../../../common/components/MultipleActionsBar/MultipleActionsBar';
import {Action} from '../../../../common/types/MenuActions';
import {IconSvg} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {IPostingTableState} from './State/IPostingTableState';
import {PostingRow} from './State/PostingTableRepository';

type PostingTableMenuProps = {
  state: IPostingTableState;
  selectionState: SelectionI<PostingRow>;
};

export const PostingTableGroupActionMenu: FCC<PostingTableMenuProps> = observer(({state, selectionState}) => {
  const intl = useIntl();
  const userId = earchiveState.company.userId ?? '';
  const canAcceptOwnDecrees = earchiveState.company.autoPosting?.AcceptBeforeSendToFK?.CanAcceptOwn;
  const decreesBeforeSendToFKStatus = state.postingState.selectedDecrees.every(
    x => x.PostingStatus === PostingStatusEnum.Posted || x.PostingStatus === PostingStatusEnum.Incomplete,
  );
  const acceptDecreeDisabled =
    (!canAcceptOwnDecrees &&
      state.postingState.selectedDecrees.filter(x => !x.isAccepted && x.CreatedBy !== userId).length < 2) ||
    state.postingState.selectedDecrees.filter(x => !x.isAccepted).length < 2 ||
    !decreesBeforeSendToFKStatus;

  const handleExportToSymfoniaFinancesAndAccountancy = (selected: PostingRow[]) => {
    const invalidDecreesCount = selected.filter(
      x =>
        x.PostingStatus === PostingStatusEnum.DecreeSent ||
        x.PostingStatus === PostingStatusEnum.DecreeDownloaded ||
        (state.acceptBeforeSendToFKIsActive && !x.IsAccepted),
    ).length;

    if (invalidDecreesCount === 0) state.postingState.publishInvoices(selected.map(x => x.InvoiceDocumentId));
    else state.postingState.setSelectedDecrees(selected);
    state.setShowExportToFKPartlyNotAvailableModal(true);
  };

  useEffect(() => {
    return () => state.postingState.setSelectedDecrees([]);
  }, []);

  const actions = useMemo<Action<PostingRow>[]>(() => {
    return [
      {
        key: Tr.acceptDecree,
        hidden: !state.acceptBeforeSendToFKIsActive,
        label: intl.formatMessage({id: Tr.acceptDecree}),
        icon: IconSvg.DONE,
        tooltip: intl.formatMessage({id: Tr.acceptDecreeDisableTooltip}),
        disabled: acceptDecreeDisabled,
        onClick: selection => state.validateAndAcceptDecrees(selection.selected, userId, canAcceptOwnDecrees ?? true),
      },
      {
        key: Tr.exportToSymfoniaFinancesAndAccountancy,
        label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
        icon: IconSvg.SAVE_ALT,
        onClick: selection => handleExportToSymfoniaFinancesAndAccountancy(selection.selected),
      },
    ];
  }, [acceptDecreeDisabled, state, userId, canAcceptOwnDecrees, state.acceptBeforeSendToFKIsActive]);

  return <MultipleActionsBar selectionState={selectionState} actions={actions}/>;
});
