import {useIntl} from 'react-intl';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Tr} from '../../../../../../../../locales/translationKeys';
import {CategoryDetailsTableFilters} from './CategoryDetailsTableFilters';
import {CategoryDetailsTable} from './CategoryDetailsTable/CategoryDetailsTable';
import {CategoryRulesFiltersKeys} from '../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';

import {observer} from 'mobx-react-lite';
import {ICategoryDetailsState} from './state/ICategoryDetailsState';
import {Breadcrumbs, ButtonTertiary, Icon, IconColor, IconSize, IconSvg, Tooltip} from '@symfonia/brandbook';
import {Header} from '../../../../../../../root/components/Header';
import {useModule} from '../../../../../../../root/services/MobXServices/BaseModule';
import {CategoryDetailsService} from './CategoryDetailsTable/CategoryDetails.service';

export interface CategoryDetailsProps {
  state: ICategoryDetailsState;
}
export const CategoryDetails = observer(({state}: CategoryDetailsProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {companyId, tenantId} = useParams<{companyId: string; tenantId: string}>();
  const filterState = state.filterState;
  const selectedCategory = state.postingConfigurationState.categoryAccountState.selectedCategory;

  const service = useModule(() => new CategoryDetailsService(state));

  useEffect(() => {
    if (selectedCategory !== undefined) {
      filterState?.setActiveFilters(CategoryRulesFiltersKeys.CategoryNames, {
        values: [selectedCategory?.Name],
        isActive: true,
        pills: [
          {
            value: selectedCategory?.Name ?? '',
            key: selectedCategory?.Id ?? '',
          },
        ],
      });
      filterState.createPills();
    }
  }, [selectedCategory]);

  const settingsHref = `/earchive/${tenantId}/company/${companyId}/account/posting/category-details`;

  const breadcrumbs = [
    {
      href: `${settingsHref}/`,
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      href: '',
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      href: `${settingsHref}/posting`,
      label: intl.formatMessage({id: Tr.config}),
    },
    {
      href: `${settingsHref}/posting/category-details`,
      label: intl.formatMessage({id: Tr.categoryContent}),
    },
  ];

  return (
    <div className="flex flex-col gap-2 lg:ml-[40px]">
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <ButtonTertiary
        className="md:flex hidden my-[4px]"
        lIcon={IconSvg.CHEVRON_LEFT}
        onClick={() => navigate('../')}
        text={intl.formatMessage({id: Tr.goBackToConfiguration})}
      />
      <Header>
        <div className='my-[12px]'>
          {`${intl.formatMessage({id: Tr.contentOfCategory})} "${selectedCategory?.Name}"`}
          <Tooltip text={intl.formatMessage({id: Tr.categoryContentTxt})}>
            <Icon
              className="relative left-[10px]"
              color={IconColor.GREEN_600}
              size={IconSize.LG}
              svg={IconSvg.HELP_OUTLINE}
            />
          </Tooltip>
        </div>
      </Header>
      <CategoryDetailsTable service={service} state={state}/>
    </div>
  );
});
