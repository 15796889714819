import classnames from 'classnames';
import {FC} from 'react';
import {WarningIcon, WarningIconProps, WarningIconType} from './WarningIcon';
import {WhiteListStatusDtoModel} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../../root/IntlProvider';
import {Tooltip} from '@symfonia/brandbook';

export type ContractorProps = {
  modalIsActive: boolean;
  contractorNip: string;
  contractorName: string;
  invoiceId?: string;
};

function getWhiteListInfo(whiteListStatus?: WhiteListStatusDtoModel | null): WarningIconProps {
  if (!whiteListStatus || !whiteListStatus.IsValidated) {
    return {hidden: true};
  }
  if (!whiteListStatus.Accounts || !whiteListStatus.Accounts.length) {
    return {type: WarningIconType.NoAccounts};
  }
  if (whiteListStatus.Accounts.every((account) => !!account?.IsWhiteListed)) {
    return {type: WarningIconType.Validated};
  }
  if (whiteListStatus.Accounts.every((account) => !account?.IsWhiteListed)) {
    return {type: WarningIconType.Error};
  }
  return {type: WarningIconType.Warn};
}

const matchWhiteListTooltipContent = (issuer: WarningIconProps & { name: string }): { content: string } => {
  if (issuer.type === WarningIconType.NoAccounts) {
    return {content: intl.formatMessage({id: Tr.issuerWithoutAccounts})};
  }
  if (issuer.type === WarningIconType.Error) {
    return {
      content: intl.formatMessage({id: Tr.allIssuerAccountsIsNotValidatedInWhiteList}),
    };
  }
  return {
    content: intl.formatMessage({id: issuer.type === WarningIconType.Warn ? Tr.someIssuerAccountsIsValidatedInWhiteList : Tr.allIssuerAccountsIsValidatedInWhiteList}),
  };
};

export const ContractorNameCell: FC<{
  contractorNip: string,
  contractorName: string,
  whiteListStatus: WhiteListStatusDtoModel | null,
  invoiceId?: string,
  setContractorProps: React.Dispatch<React.SetStateAction<{
    modalIsActive: boolean;
    contractorNip: string;
    contractorName: string;
    invoiceId?: string;
  } | undefined>>
}> = ({setContractorProps, contractorNip, contractorName, whiteListStatus, invoiceId}) => {
  const whiteListParams = getWhiteListInfo(whiteListStatus);
  const {content} = matchWhiteListTooltipContent({...whiteListParams, name: contractorName ?? ''});
  const tooltip = whiteListParams.hidden ? contractorName : <div>
    {content}
  </div>;
  return (
    <Tooltip text={tooltip}>
    <div
      data-testid={`${ contractorNip }ContractorNameCell`}
      onClick={e => {
        e.stopPropagation();
        setContractorProps({
          modalIsActive: true,
          contractorNip: contractorNip,
          contractorName: contractorName,
          invoiceId,
        });
      }}
      className={classnames('w-full flex flex-row flex-nowrap items-center cursor-pointer')}
    >
      <span className="w-full underline hover:text-primary-green overflow-hidden text-ellipsis">
        {contractorName}
      </span>
      <WarningIcon {...whiteListParams}/>
    </div>
    </Tooltip>
  );
};
