import {
  InvoiceBound,
  KSeFStatus,
  UpoFileType,
  UpoMissingInvoiceReason,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';

export enum KSeFRequestForCredentialResultType {
  None, Revoke, Grant, Edit
}

export enum RequestType {
  Undefined,
  Revoke,
  Grant
}

export enum UPOMimeType {
  pdf = 'application/pdf',
  xml = 'text/xml',
  zip = 'application/zip'
}

export enum InvoiceNonRemovableReason {
  None = 0,
  NotOwner = 1,
  WrongType = 2,
  WrongStatus = 3,
  AlreadyExported = 4,
  ServerError = 5,
  NotFound = 6,
}


export type UPODataModel = {
  fileName?: string,
  fileContent?: string,
  mimeType?: UPOMimeType,
  returnedInvoices: string[],
  requestedInvoices: string[],
  fileType: UpoFileType,
  missingInvoices?: Array<{
    invoiceId: string,
    invoiceNumber?: string,
    reason: number
  }>
}

/*
public enum DownloadStateUserMessage
{
    Pending,
    Downloading,
 }

oprócz tego co jest dochodzą pola:
OnDownloadInvoicesResultUserMessage
{
    DownloadStateUserMessage state,
    pendingTimeInSeconds
    stateTime //data i godzina zmiany stanu
    refreshNeeded [bool]
}
 */

export enum DownloadStateUserMessage {
  Pending = 'Pending',
  Downloading = 'Downloading'
}

export type DownloadInvoicesDataModel = {
  pendingTimeInSeconds: number,
  stateTime?: Date,
  state?: DownloadStateUserMessage,
  refreshNeeded?: boolean,
  dateFrom?: string,
  dateTo?: string,
  imported: number,
  external: { DateFrom?: string, DateTo?: string },
  internal: { DateFrom?: string, DateTo?: string },
  externalEnabled: boolean,
  errorItems?: Array<{
    kSeFNumber?: string,
    kSeFDate: string,
    errorMessage?: string,
  }>,
  successItems?: Array<{
    kSeFNumber?: string,
    kSeFDate: string,
    invoiceBound?: InvoiceBound
  }>
}

export type WhiteListItem = {
  invoiceId: string,
  identifier: string,
  issuerName: string,
  whiteListResult: {
    Account: number,
    IsWhiteListed: boolean,
    ErrorMessage: string | null
  }[]
}

export type CheckWhiteListDataModel = {
  date: Date,
  errorItems: WhiteListItem[],
  whiteListed: WhiteListItem[],
  notWhiteListed: WhiteListItem[],
  invoiceIds: string[]
}

export type DeleteInvoicesDataModel = {
  errorItems?: Array<{
    invoiceId: string,
    invoiceNumber: string,
    invoiceDate?: string,
    reason: InvoiceNonRemovableReason,
  }>,
  invoicesId: Array<string>,
  deleted: number
}

export type GrantPermissionsDataModel = {
  identifier: string,
  completed: boolean,
  operationType: KSeFRequestForCredentialResultType,
  permissionList: Array<{
    referenceNumber: string,
    requestType: RequestType,
    success: boolean,
    status: number,
    description?: string
  }>
}

export type SendInvoice = {
  invoiceId: string,
  kSeFStatus: KSeFStatus,
  kSeFDate: string,
  kSeFNumber: string,
  kSeFStatusErrorDescription?: string
}

export type SendInvoicesDataModel = {
  invoices?: Array<SendInvoice>
}

export type UploadInvoicesDataModel = {
  successItems: Array<{
    fileName: string
  }>
  errorItems: Array<{
    FileName: string,
    errorMessage: string
  }>
}

export type Event = Pick<WebSocketNotificationFragment, 'ResponseJson' | 'Type'>

export type NotificationDataType<T extends WebsocketNotificationType = WebsocketNotificationType> =
  T extends WebsocketNotificationType.GetUpo
    ? UPODataModel
    : T extends WebsocketNotificationType.GrantPermission
      ? GrantPermissionsDataModel
      : T extends (WebsocketNotificationType.DownloadInvoices | WebsocketNotificationType.AutoFetchingInvoices)
        ? DownloadInvoicesDataModel
        : T extends (WebsocketNotificationType.SendingInvoices | WebsocketNotificationType.AutoSendingInvoices)
          ? SendInvoicesDataModel
          : T extends WebsocketNotificationType.UploadInvoices
            ? UploadInvoicesDataModel
            : T extends WebsocketNotificationType.DeleteInvoices
              ? DeleteInvoicesDataModel
              : T extends WebsocketNotificationType.WhiteListValidation
                ? CheckWhiteListDataModel
                : undefined


//Parser danych kontekstowych dla eventu zakończonego procesu subskrypcyjnego
//Na podstawie typu notyfikacji przekazanego w typie generycznym dobiera model danych (NotificationDataType<T>)
export interface NotificationDataParserI<T extends WebsocketNotificationType = WebsocketNotificationType, P = unknown> {
  data: NotificationDataType<T> | null;

  parse<P extends T = T>(opt?: { reparse: boolean }): NotificationDataType<P> | null;

  set<P extends T = T>(data: NotificationDataType<P> | null): this;

  setMapper<R extends P = P>(mapper?: Mapper<T, R>): this;

  setEvent(event: Event): this;

  get event(): Event;
}

export type Mapper<P extends WebsocketNotificationType = WebsocketNotificationType, T = any> = (fromModel: T) => NotificationDataType<P> | null

export class NotificationDataParser<P extends WebsocketNotificationType = WebsocketNotificationType> implements NotificationDataParserI<P> {

  private _mapper?: Mapper<P>;

  private readonly cachingDisabled: boolean;

  constructor(private _event: Event, opt?: { cachingDisabled?: boolean }) {
    // makeObservable(this);
    this.cachingDisabled = !!opt?.cachingDisabled;
  }

  // @observable.ref
  private _data: NotificationDataType<P> | null = null;

  // @computed
  public get data(): NotificationDataType<P> | null {
    return this._data;
  }

  public get event(): Event {
    return this._event;
  }

  public setMapper<R>(mapper?: Mapper<P, R>): this {
    this._mapper = mapper;
    return this;
  }

  // @action.bound
  public set<T extends P = P>(data: NotificationDataType<T> | null): this {
    this._data = data;
    return this;
  }

  public setEvent(event: Event): this {
    this._event = event;
    return this;
  }

  public parse<T extends P = P>(opt?: { reparse: boolean }): NotificationDataType<T> | null {
    if ((this._data && !opt?.reparse) || !this._event.ResponseJson) {
      return this._data;
    }
    try {
      const data = this.map(JSON.parse(this._event.ResponseJson));
      if (this.cachingDisabled) {
        return data;
      }
      this._data = data;
    } catch (err) {
      if (opt?.reparse) {
        this._data = null;
      }
      console.error(err);
    }
    return this._data;
  }

  private map(fromModel: any): NotificationDataType<P> {
    return this._mapper?.(fromModel) ?? fromModel ?? null as NotificationDataType<P> | null;
  }
}





