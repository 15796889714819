import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {dateParser} from '../../../../../common';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {observer} from 'mobx-react-lite';
import {MultiSelectModel} from '@symfonia/symfonia-ui-components';
import {PaymentTypeEnum, PostingStatusEnum} from '@symfonia-ksef/graphql';
import React, {useCallback, useEffect, useState} from 'react';
import {Dropdown} from '@symfonia/symfonia-ksef-components';
import {DropdownWidth} from '@symfonia/brandbook';
import {convertToMapDropdownListOption} from '../../../../../common/helpers/baseFilterHelpers';


export interface PostingDetailsHeaderGridProps {
  state: IPostingDetailsState;
}

export const PostingDetailsHeaderGrid = observer(({state}: PostingDetailsHeaderGridProps) => {
  const intl = useIntl();
  const decree = state.postingDetailsStore.postingDetails;
  const decreeStatusDisable = decree?.PostingStatus === PostingStatusEnum.DecreeSent || decree?.PostingStatus === PostingStatusEnum.DecreeDownloaded;
  const [selectedOptionKey, setSelectedOptionKey] = useState<React.Key[]>([]);

  const changeSelectedValue = (v: MultiSelectModel | null) => {
    if (v !== null) state.changeDocumentType(v);
  };

  const documentTypesOptions = convertToMapDropdownListOption(state.documentTypesOptions, el => ({
    label: el.value ?? '',
    value: el.key,
  }));

  const handleClickOnListItem = useCallback((e: React.Key[]) => {
    setSelectedOptionKey(e);
    const selectedVal = documentTypesOptions.get(e[0]);
    const convertedValue: MultiSelectModel = {
      value: selectedVal?.label ?? '',
      key: selectedVal?.value ?? '',
    };
    state.setIsAnyChangeInDetails(true);
    changeSelectedValue(selectedVal !== undefined ? convertedValue : null);
  }, []);

  useEffect(() => {
    documentTypesOptions.forEach((el, key) => {
      if (el.value === state.selectedDocumentType?.key) {
        setSelectedOptionKey([key]);
      }
    });
  }, [state.selectedDocumentType]);

  const postingDetailsConfig = [
    {
      key: decree?.Contractor?.Name,
      value: Tr.contractorWithName,
    },
    {
      key: decree?.Contractor?.Identifier,
      value: Tr.identifierWithValue,
    },
    {
      key: decree?.Contractor?.Street,
      value: Tr.addressWithValue,
    },
    {
      key: decree?.Contractor?.Place,
      value: Tr.cityWithValue,
    },
    {
      key: decree?.Contractor?.PostCode,
      value: Tr.zipCodeWithValue,
    },
    {
      key: dateParser(decree?.DateOfIssue),
      value: Tr.dateOfIssueWithValue,
    },
    {
      key: dateParser(decree?.KSeFDate),
      value: Tr.KSeFDateWithValue,
    },
    {
      key: dateParser(decree?.DateOfIssue),
      value: Tr.goodsServicesRecivedDateWithValue,
    },
    {
      key: intl.formatMessage({id: decree?.PaymentType !== null && decree?.PaymentType !== PaymentTypeEnum.Undefined ? Tr[decree?.PaymentType as PaymentTypeEnum] : '-'}),
      value: Tr.paymentTypeWithValue,
    },
    {
      key: dateParser(decree?.PaymentDate),
      value: Tr.paymentDateWithValue,
    },
    {
      key: decree?.KSeFNumber ?? '-',
      value: Tr.KSeFNumberWithValue,
    },
    {
      key: decree?.CurrencyCode,
      value: Tr.currencyRateWithValue,
    },
    {
      key: decree?.InvoiceType,
      value: Tr.invoiceTypeWithValue,
    },
  ];


  return (<div
        className={'mt-[20px] grid md:grid-cols-3 grid-cols-2 gap-4 font-quicksand text-base font-medium w-full'}>
        <div className={'flex flex-col'}>
          {postingDetailsConfig.map((p, idx) => {
            return <>
              {idx <= 4 &&
                <p key={p.value} className={'truncate my-[6px]'}>
                  <FormattedMessage
                    id={p.value}
                    values={{[p.value]: <span className={'font-bold truncate'}>{p.key ? p.key : '-'}</span>}}/>
                </p>
              }</>;
          })}
        </div>
        <div className={'flex flex-col'}>
          {postingDetailsConfig.map((p, idx) => {
            return <>
              {idx > 4 && idx <= 9 &&
                <p key={p.value} className={'truncate my-[6px]'}>
                  <FormattedMessage
                    id={p.value}
                    values={{[p.value]: <span className={'font-bold truncate'}>{p.key ? p.key : '-'}</span>}}/>
                </p>
              }</>;
          })}
        </div>
        <div className={'flex flex-col md:mt-0 mt-[20px]'}>
          {postingDetailsConfig.map((p, idx) => {
            return <>
              {idx > 9 &&
                <p key={p.value} className={'truncate my-[6px]'}>
                  <FormattedMessage
                    id={p.value}
                    values={{[p.value]: <span className={'font-bold truncate'}>{p.key ? p.key : '-'}</span>}}/>
                </p>
              }
            </>;
          })}
        <div className="flex flex-col justify-start items-start pt-0 mt-[6px]">
          <p>{intl.formatMessage({id: Tr.invoiceDocumentType})}</p>
          <div className="w-60 mt-[10px]">
            <Dropdown
              width={DropdownWidth.BASE}
              useSearch={false}
              defaultValue={selectedOptionKey}
              listWithTooltip={false}
              values={new Set(selectedOptionKey)}
              options={documentTypesOptions}
              displaySelected={true}
              onChange={(e) => handleClickOnListItem(e)}
              disabled={decreeStatusDisable}
              placeholder={intl.formatMessage({id: Tr.invoiceDocumentType})}
            />
          </div>
        </div>
        </div>
      </div>
  );
});

