import {AppRoutes} from '../../root/Routes';

export function isEarchive() {
  return document.location.pathname.includes(AppRoutes.eArchive.address);
}

export function isAdmin() {
  return document.location.pathname.includes(AppRoutes.administration.address);
}

export function isLoginHub() {
  return document.location.pathname.includes(AppRoutes.hub.address);
}
