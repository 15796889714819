import {FC, useState} from 'react';
import {AuthorizationByTokenModal} from '../../../modals';
import AuthenticationOption from './AuthenticationOption';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';

import {AuthType} from '../../../../../state/AuthenticationState/AuthenticationState';
import {CompanyEnvironmentModel} from '../../../models';
import {
  authenticationState,
  authorizationInKSeFService,
  authorizationInKSeFWithTokenService,
  earchiveState,
} from '@symfonia-ksef/state/rootRepository';

interface AuthenticationByTokenProps {
  company: CompanyEnvironmentModel;
}

export const AuthenticationByToken: FC<AuthenticationByTokenProps> = observer(({company}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    removeAuthorizationRunner: {isPending: removingIsPending, loading: removingLoading},
    authorizationRunner: {isPending, loading},
  } = authorizationInKSeFWithTokenService;
  const {loadingSignature} = authenticationState;
  const loadingToken = removingIsPending || isPending || removingLoading || loading;
  const activeEvent = authorizationInKSeFService.isPending;
  const handleClick = () => {
    setModalOpen(true);
  };

  const handleCancelClick = () => setModalOpen(false);

  const handleConfirmClick = async (token: string) => {
    authenticationState.setAuthType(AuthType.Token);
    await authorizationInKSeFWithTokenService.authorizationRunner.configure({Token: token}).fetch();
    setModalOpen(false);
  };

  return (
    <>
      <AuthorizationByTokenModal onCancel={handleCancelClick} onConfirm={handleConfirmClick} open={modalOpen}/>
      <AuthenticationOption
        titleMessageId={Tr.authByTokenHeader}
        descMessageId={Tr.authByTokenContent}
        clicked={handleClick}
        showLoader={loadingToken}
        disabled={loadingToken || loadingSignature || activeEvent || !earchiveState.packageStatistics.subscriptionIsActive || earchiveState.packageStatistics.outOfLimit}
      />
    </>
  );
});

export default AuthenticationByToken;
