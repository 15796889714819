import {InvoicePreviewModel, InvoicePreviewModelInterface, InvoicePreviewState} from './InvoicePreviewModel';
import {action, computed, IReactionDisposer, makeObservable, observable, reaction} from 'mobx';
import {apolloClient} from '../../modules/root/providers/GraphQLProvider';
import {InvoicePdfService, InvoicePdfServiceInterface} from './InvoicePdfService';
import {InvoicePreviewPersistInterface, InvoicePreviewPersistService, ItemsKeys} from './InvoicePreviewPersistService';
import {
  GetInvoiceHtmlContentSingleDocument,
  GetInvoiceHtmlContentSingleQuery,
  GetInvoiceHtmlContentSingleQueryVariables,
} from '@symfonia-ksef/graphql';
import {InvoiceModel} from '../../modules/earchive/models';
import {authState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {ApolloError} from '@apollo/client';

type SetZoomRatioArg = number | ((zoomRatio: number) => number)

type DisableSave = { disableSaveToStorage?: boolean }

export type ConstructorOptions = { persistImmediately?: boolean, sessionStorageKey: string }

export type DefaultItem = { Id: string, Description: string }

export interface InvoicePreviewInterface<T extends DefaultItem = DefaultItem> {
  state: InvoicePreviewState;
  initialized: boolean;
  loading: boolean;
  error: boolean;
  currentInvoiceId: string | null;
  enableInvoicePreview: boolean;
  zoomRation: number;
  envId: string | null;
  tenantId: string | null;
  pdfService: InvoicePdfServiceInterface;
  canZoomIn: boolean;
  canZoomOut: boolean;
  shouldShowZoomRatio: boolean;

  get currentItem(): T | undefined;

  setEnableInvoicePreview(enable: boolean, opt?: DisableSave): void;

  setState(state: InvoicePreviewState, opt?: DisableSave): void;

  reset(items?: ItemsKeys): void;

  load(): Promise<void>;

  configure(options: { envId?: string, tenantId?: string }): void;

  setCurrentInvoiceId(invoiceId: string | null | undefined, opt?: DisableSave): void;

  getCurrentInvoiceIndex(invoices: InvoiceModel[]): number;

  setZoomRatio(ratio: SetZoomRatioArg, opt?: DisableSave): void;

  zoomOut(): void;

  zoomIn(): void;

  persist(): () => void;

  disposeReactions(): void;

  setShouldShowZoomRatio(shouldShowZoom: boolean): void;
}

export class InvoicePreviewRepository<T extends DefaultItem = DefaultItem> implements InvoicePreviewInterface<T> {
  @observable
  public initialized = false;

  @observable
  public loading = false;

  @observable
  public error = false;

  @observable.ref
  public currentInvoiceId: string | null = null;

  @observable
  public enableInvoicePreview = false;

  @observable
  public zoomRation;

  @observable
  public envId: string | null = null;

  @observable
  public tenantId: string | null = null;

  @observable
  public shouldShowZoomRatio: boolean = false;

  public pdfService: InvoicePdfServiceInterface;

  protected persistService: InvoicePreviewPersistInterface;

  private disposers: IReactionDisposer[] = [];

  private readonly DEFAULT_ZOOM_RATIO = 100;

  private readonly ZOOM_RATIO_STEP = this.DEFAULT_ZOOM_RATIO / 4;

  private readonly MAX_ZOOM_RATIO = this.DEFAULT_ZOOM_RATIO * 2;

  private readonly MIN_ZOOM_RATIO = this.DEFAULT_ZOOM_RATIO / 2;

  private abortController?: AbortController;

  protected constructor(opt: ConstructorOptions, protected repository?: { items: T[] }) {
    makeObservable(this);
    this._state = new InvoicePreviewModel();
    this.persistService = new InvoicePreviewPersistService(opt.sessionStorageKey, this, sessionStorage);
    this.pdfService = new InvoicePdfService(this);
    this.zoomRation = this.DEFAULT_ZOOM_RATIO;
    opt.persistImmediately && this.persistService.load();

    reaction(
      () => this.currentInvoiceId,
      async (currentInvoiceId) => {
        if (currentInvoiceId !== null) {
          await this.load();
        }
      }
    );
  }

  protected _state: InvoicePreviewModelInterface;

  @computed
  public get state(): InvoicePreviewState {
    return this._state;
  }

  @computed
  public get canZoomIn() {
    return this.zoomRation + this.ZOOM_RATIO_STEP <= this.MAX_ZOOM_RATIO;
  }

  @computed
  public get canZoomOut() {
    return this.zoomRation - this.ZOOM_RATIO_STEP >= this.MIN_ZOOM_RATIO;
  }

  public get currentItem(): T | undefined {
    return this?.repository?.items?.find?.(item => item.Id === this.currentInvoiceId);
  }

  public static create<T extends DefaultItem = DefaultItem>(opt: ConstructorOptions, repo?: {
    items: T[]
  }): InvoicePreviewInterface<T> {
    return new InvoicePreviewRepository<T>(opt, repo);
  }

  public abort(): this {
    this.setLoading(false);
    this.abortController?.abort?.();
    this.abortController = undefined;
    return this;
  }

  public async load(): Promise<void> {
    const envId = envObserver.currentEnv.companyId;

    const token = authState.accessToken;

    if (!this.currentInvoiceId || !token) {
      return;
    }
    this.setLoading(true);
    this.abortController = new AbortController();
    try {
      const {
        data,
        errors,
      } = await apolloClient.query<GetInvoiceHtmlContentSingleQuery, GetInvoiceHtmlContentSingleQueryVariables>({
        query: GetInvoiceHtmlContentSingleDocument,
        context: {envId, fetchOptions: {signal: this.abortController.signal}},
        variables: {InvoiceId: this.currentInvoiceId, Token: token},
        errorPolicy: 'all',
      });

      if (errors) {
        this._state.reset();
        this.setError(true);
        this.setLoading(false);
        return;
      }

      if (data) {
        this.setError(false);
        this.setState(this.mapResponseToState(data));
      }
      this.setLoading(false);
    } catch (err) {
      if ((err as ApolloError).networkError?.name === 'AbortError') {
        this.abort();
        return;
      }
      this.setLoading(false);
    }
  }

  @action.bound
  public setCurrentInvoiceId(invoiceId: string | null | undefined, opt?: DisableSave): void {
    this.currentInvoiceId = invoiceId ?? null;
    !opt?.disableSaveToStorage && this.persistService.save({currentInvoiceId: true});
  }

  public setState(state: InvoicePreviewState, opt?: DisableSave): void {
    this._state.set(state);
    this.initialize();
    !opt?.disableSaveToStorage && this.persistService.save({state: true});
  }

  public reset(items?: ItemsKeys): void {
    if (items?.state) {
      this._state.reset();
      this.initialize(false);
      this.setError(false);
    }
    items?.currentInvoiceId && this.setCurrentInvoiceId(null);
    items?.zoomRation && this.setZoomRatio(this.DEFAULT_ZOOM_RATIO);
    items?.enableInvoicePreview && this.setEnableInvoicePreview(false);
    this.persistService.remove(items ?? {});
  }

  @action.bound
  public configure(options: { tenantId?: string, envId?: string }): void {
    this.envId = options?.envId ?? this.envId;
    this.tenantId = options?.tenantId ?? this.tenantId;
  }

  public persist(): () => void {
    const load = () => this.persistService.load({
      onCurrentInvoiceIdEmpty: (preview) => preview.reset({currentInvoiceId: true}),
      onStateEmpty: (preview) => preview.reset({state: true}),
      onZoomRatioEmpty: (preview) => preview.reset({zoomRation: true}),
    });
    const effect = (current: string | null, prev: string | null) => current !== prev && load();
    this.disposers.push(reaction(() => this.envId, effect), reaction(() => this.tenantId, effect));
    load();
    return () => this.disposeReactions();
  }

  public disposeReactions(): void {
    this.disposers.forEach(disposer => disposer());
    this.disposers.length = 0;
  }

  public zoomIn(): void {
    this.canZoomIn && this.setZoomRatio(ratio => ratio + this.ZOOM_RATIO_STEP);
  }

  public zoomOut(): void {
    this.canZoomOut && this.setZoomRatio(ratio => ratio - this.ZOOM_RATIO_STEP);
  }

  @action.bound
  public setEnableInvoicePreview(enable: boolean, opt?: DisableSave): void {
    this.enableInvoicePreview = enable;
    !opt?.disableSaveToStorage && this.persistService.save({enableInvoicePreview: true});
  }

  public getCurrentInvoiceIndex(invoices: InvoiceModel[]): number {
    return invoices.findIndex(({Id}) => Id === this.currentInvoiceId);
  }

  @action.bound
  public setZoomRatio(ratio: SetZoomRatioArg, opt?: DisableSave): void {
    this.zoomRation = typeof ratio === 'function' ? ratio(this.zoomRation) : ratio;
    !opt?.disableSaveToStorage && this.persistService.save({zoomRation: true});
  }

  @action.bound
  public setShouldShowZoomRatio(shouldShowZoom: boolean): void {
    this.shouldShowZoomRatio = shouldShowZoom;
  }

  @action.bound
  protected setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @action.bound
  protected initialize(initialized?: boolean): void {
    this.initialized = initialized ?? true;
  }

  @action.bound
  protected setError(error: boolean): void {
    this.error = error;
  }

  protected mapResponseToState(response: GetInvoiceHtmlContentSingleQuery): InvoicePreviewState {
    return response.GetInvoiceFileContentSingle;
  }
}

