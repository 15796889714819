import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FC, useCallback} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useNavigate} from 'react-router-dom';
import {PostingStatusEnum} from '@symfonia-ksef/graphql';
import {ContextualMenu} from '../../../../common/components/ContextualMenu/ContextualMenu';
import {IconSvg} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {IPostingTableState} from './State/IPostingTableState';
import {PostingRow} from './State/PostingTableRepository';

interface PostingTableMenuProps {
  state: IPostingTableState;
  displayedForPostingStatus: boolean;
}

export const PostingTableActionMenu: FC<PostingTableMenuProps> = observer(({state, displayedForPostingStatus}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const canAcceptOwnDecrees = earchiveState.company.autoPosting?.AcceptBeforeSendToFK?.CanAcceptOwn;
  const userId = earchiveState.company.userId;
  const decreeBeforeSendToFKStatus = state.postingState.selectedDecree?.PostingStatus === PostingStatusEnum.Posted || state.postingState.selectedDecree?.PostingStatus === PostingStatusEnum.Incomplete;
  const acceptDecreeTooltipEnabled = !canAcceptOwnDecrees && state.postingState.selectedDecree?.CreatedBy === userId && decreeBeforeSendToFKStatus;
  const acceptDecreeDisabled = acceptDecreeTooltipEnabled || state.postingState.selectedDecree?.IsAccepted === true || !decreeBeforeSendToFKStatus;

  const closeSingleActionsMenu = () => {
    state.postingState.setSelectedDecree(undefined);
    state.setSelectedMenuOpened(false);
    state.setSelectedMenuAnchorEl(undefined);
  };

  const handleExportToSymfoniaFinancesAndAccountancy = () => {
    state.setSelectedMenuOpened(false);
    state.postingState.publishInvoices([state.postingState.selectedDecree?.InvoiceDocumentId]);
  };

  const previewDecree = useCallback(() => {
    if (state.postingState.postingDetailsState.decreeId) state.postingState.postingDetailsState.setDecreeId(state.postingState.selectedDecree?.Id);
    navigate(`./${state.postingState.selectedDecree?.Id}`);
    state.setSelectedMenuOpened(false);
  }, []);

  const acceptDecree = () => {
    state.validateAndAcceptDecrees([
      {
        Id: state.postingState.selectedDecree?.Id,
        InvoiceDocumentId: state.postingState.selectedDecree?.InvoiceDocumentId,
        Number: state.postingState.selectedDecree?.InvoiceNumber,
        PostingStatus: state.postingState.selectedDecree?.PostingStatus,
        IsAccepted: !!state.postingState.selectedDecree?.IsAccepted,
        CreatedBy: state.postingState.selectedDecree?.CreatedBy,
      } as unknown as PostingRow], userId ?? '', canAcceptOwnDecrees ?? true);
    state.setSelectedMenuOpened(false);
  };

  const actions = [
    {
      hidden: !state.acceptBeforeSendToFKIsActive,
      key: Tr.acceptDecree,
      label: intl.formatMessage({id: Tr.acceptDecree}),
      tooltipProps: {
        tooltipTitle: intl.formatMessage({id: Tr.acceptDecreeDisableTooltip}),
      },
      disabled: acceptDecreeDisabled,
      onClick: acceptDecree,
      icon: IconSvg.DONE,
    },
    {
      hidden: !displayedForPostingStatus,
      key: Tr.previewDecree,
      label: intl.formatMessage({id: Tr.previewDecree}),
      onClick: previewDecree,
      icon: IconSvg.REMOVE_RED_EYE,
    },
    {
      hidden: displayedForPostingStatus,
      key: Tr.editDecree,
      label: intl.formatMessage({id: Tr.editDecree}),
      onClick: previewDecree,
      icon: IconSvg.EDIT,
    },
    {
      hidden: displayedForPostingStatus,
      key: Tr.exportToSymfoniaFinancesAndAccountancy,
      label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
      disabled: !state.postingState.selectedDecree?.Id || (state.acceptBeforeSendToFKIsActive && !state.postingState.selectedDecree.IsAccepted),
      onClick: handleExportToSymfoniaFinancesAndAccountancy,
      icon: IconSvg.SAVE_ALT,
    },
  ];

  return <ContextualMenu
    isOpen={state.selectedMenuOpened}
    actions={actions}
    anchorEl={state.selectedMenuAnchorEl ?? null}
    onClose={closeSingleActionsMenu}
  />;
});
