import {EventJobRunner} from '../EventJobRunner';
import {
  SubscribeDownloadInvoicesFromKSeFDocument,
  SubscribeDownloadInvoicesFromKSeFQuery,
  SubscribeDownloadInvoicesFromKSeFQueryVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class FetchInvoicesFromKSeFJobRunner extends EventJobRunner<SubscribeDownloadInvoicesFromKSeFQueryVariables, keyof SubscribeDownloadInvoicesFromKSeFQuery> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('SubscribeDownloadInvoicesFromKSeF', WebsocketNotificationType.DownloadInvoices, SubscribeDownloadInvoicesFromKSeFDocument, envObserver, earchiveState);
    this.configureAlerts({
        success: (status) => ({
          id: Tr.downloadInvoicesSubInProgress,
        }),
        error: (errors, errorMessage) => ({
          id: Tr.subscriptionError,
        }),
      },
    );
  }
}
