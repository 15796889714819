import React, {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';

import {SzafirSdkError as SzafirSdkErrorType} from '@symfonia/szafir-react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SzafirService} from 'libs/szafir-react/src/lib/services';

interface SzafirSdkErrorProps {
  szafirSdkError: SzafirSdkErrorType;
  szafirService: SzafirService | undefined;
}

const SzafirSdkError: FC<SzafirSdkErrorProps> = ({szafirSdkError, szafirService}) => {
  const getExternalLink = (label: ReactNode[], url?: string) => (
    <a href={url} target="_blank" rel="noreferrer">
      {label}
    </a>
  );

  return (
    <FormattedMessage
      id={`szafirError${szafirSdkError}`}
      values={{
        extLink: (label) => getExternalLink(label, szafirService?.getExtLink()),
        hostAppLink: (label) => getExternalLink(label, szafirService?.getHostAppLink()),
      }}
    />
  );
};

export default SzafirSdkError;
