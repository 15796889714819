import {FC} from 'react';
import {WhiteListModelFragment} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../../root/IntlProvider';
import {EmptyTable, Table, TableHeaderProps, TableRow, TableRowHeight, Tag, TagColor} from '@symfonia/brandbook';
import {AccountNumber} from './AccountNumber';
import dayjs from 'dayjs';
import classnames from 'classnames';

export const ContractorAccountsNumbersList: FC<{model: WhiteListModelFragment | undefined}> = ({model}) => {
  const validAccounts = model?.Accounts?.filter(account => account !== undefined) as {
    Account?: string | undefined;
    Date: any;
    ErrorMessage?: string | undefined;
    IsWhiteListed: boolean;
  }[];

  const header: TableHeaderProps[] = [{name: Tr.contractorAccountsNumbersListAccountHeader, title: intl.formatMessage({id: Tr.contractorAccountsNumbersListAccountHeader}), sortable: true, width: 'w-[250px]'}, 
                  {name: Tr.contractorAccountsNumbersListWhitelistHeader, title: intl.formatMessage({id: Tr.contractorAccountsNumbersListWhitelistHeader}), sortable: true, width: 'w-[125px]'}, 
                  {name: Tr.contractorAccountsNumbersListDateHeader, title: intl.formatMessage({id: Tr.contractorAccountsNumbersListDateHeader}), sortable: true, width: 'w-[125px]'}];
  const content = validAccounts?.length && validAccounts.length > 0 ? (
          validAccounts.map?.(account => 
          <TableRow
            key={String(account.Account)}
            children={[<AccountNumber account={account.Account}/>,
                    account.IsWhiteListed ? <Tag color={TagColor.GREEN_LIGHT} text={intl.formatMessage({id: Tr.isWhiteListed})}/> : <Tag color={TagColor.RED_LIGHT} text={intl.formatMessage({id: Tr.isNotWhiteListed})}/>,
                   <span className='mr-[10px]'>{dayjs(account?.Date).format('YYYY-MM-DD')}</span>]}
          />))
          : 
          (<TableRow caption>
            <EmptyTable/>
          </TableRow>
        );
  return <Table className='min-w-[500px]' tableRowHeight={TableRowHeight.SM} children={content} header={header}/>;
};
