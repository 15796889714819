import {BaseModule} from '../../../root/services/MobXServices/BaseModule';
import {ExtendedTableServiceI} from '../../../root/services/TableServices/ExtendedTableService';
import {GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables} from '@symfonia-ksef/graphql';
import {TableBuilder} from '../../../root/services/TableServices/TableBuilder';
import {TableSortDirection} from '@symfonia/brandbook';
import {PaginationMode} from '../../../root/services/TableServices/PaginationTableService';
import {postingColumns} from './PostingTable/PostingTableColumnsConfig';
import {SearchService, SearchServiceI} from '../../../root/services/SearchService/SearchService';
import {computed, makeObservable} from 'mobx';
import {intl} from '../../../root/IntlProvider';
import {Pills} from '../../../root/components/FiltersPills';
import {TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {PostingRow, PostingTableDataSourceHandler, PostingTableRepository} from './PostingTable/State/PostingTableRepository';
import {IPostingTableState} from './PostingTable/State/IPostingTableState';

export type PostingTableType = ExtendedTableServiceI<PostingRow, 'GetPostingInvoicesViewModel', GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables, IPostingTableState>

export class PostingTablePageService extends BaseModule {
  public readonly tableService!: PostingTableType;
  public readonly repository!: PostingTableRepository;
  public readonly searchService!: SearchServiceI;


  constructor(private readonly state: IPostingTableState) {
    super();
    const dataSourceHandler = new PostingTableDataSourceHandler(state);
    this.repository = dataSourceHandler.repository;
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);
    this.tableService = TableBuilder.create<PostingRow, 'GetPostingInvoicesViewModel', GetPostingInvoicesQuery, GetPostingInvoicesQueryVariables, IPostingTableState>()
      .connectKeyFactory(row => row.Id)
      .connectColumns(postingColumns)
      .connectSortBy({
        sortBy: {name: 'CreatedAt', direction: TableSortDirection.DESC},
        persistKey: 'postingTableSortBy',
      })
      .connectContext(state)
      .connectDataSource(dataSourceHandler)
      .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}, persistKey: 'postingTablePagination'})
      .connectClickableRow({persistKey: 'postingTableFocusedInvoiceId'})
      .connectSelection()
      .buildExtended();
    makeObservable(this);
  }


  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};

    for (const [filterKey, value] of this.state.filterState.pillsList.entries()) {
      const {values, translationKey} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: translationKey ?? filterKey.replace('.', '')}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.state.filterState.clearSingleFilter(filterKey, key, value);
            this.repository.fetch();
          },
        })),
      };
    }

    return convertedPills;
  }


  protected async _onMount(): Promise<void> {
    this.tableService.onMount();
    this.searchService.onMount();
    await this.repository.fetch();
  }

  protected _onUnmount(): void {
    this.tableService.onUnmount();
  }

}
