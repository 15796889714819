export const dateParser = (value: Date | string, withTime = false) => {
  function containsTimeZoneSigns(value: string): boolean {
    return value.includes('+') || value.includes('-');
  }

  if (value) {
    if (withTime && typeof value === 'string') {
      const timePart = value.substring(value.indexOf('T'));
      if (!containsTimeZoneSigns(timePart) && !value.endsWith('Z')) {
        value += 'Z';
      }
    }
    const date = new Date(value);
    if (!withTime)
      return date
        ?.toLocaleString('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        ?.replace(/\./g, '-');
    return (
      date
        ?.toLocaleString('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        })
        ?.replace(/\./g, '-')
    );
  }

  return '';
};
