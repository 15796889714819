import {SubscriptionStateEnum, WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {KsefEventContextDataHandler} from '../../../services/KsefEventContextDataHandler';
import {SubscriptionEventsServiceI} from '../../../services/SubscriptionEventsService';
import {action, computed, IReactionDisposer, makeObservable, observable} from 'mobx';
import {EventConsumerI} from '../../../services/EventConsumer';
import {
  DownloadInvoicesProgressConsumer,
  DownloadInvoicesVariant,
  EventsI,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressConsumer';
import {KsefEventCancellationService} from '@symfonia-ksef/state/KSeFSubscriptionServices/KsefEventCancellationService';
import {
  DownloadInvoicesChecker,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesChecker/DownloadInvoicesChecker';
import {DownloadStateUserMessage} from '../../../services/helpers/NotificationDataParsers';
import {intl} from '../../../modules/root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  DownloadInvoicesModelToMap
} from '../../../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/GetInvoicesEventConverter';
import {UpdateActiveEventsI} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsActiveEventsRepository';
import {Notification} from '../../../services/NotificationDataService';
import {DownloadInvoicesModelMapper} from '../../../modules/earchive/modules/KSeFEvents/helpers/DownloadInvoicesModelMapper';

export interface InvoicesListRenderKeyI {
  get renderKey(): number;
}

export interface CancellationProgressI {
  setCancellationInProgress(inProgress: boolean): void;

  get cancellationInProgress(): boolean;
}

export interface DownloadInvoicesProgressTrackerI extends UpdateActiveEventsI, CancellationProgressI {
  getAsyncData: KsefEventContextDataHandler<WebsocketNotificationType.DownloadInvoices>['getAsyncData'];

  refreshInvoices(): void;

  get notification(): Notification;

  get pendingNotification(): WebSocketNotificationFragment | null;
}

export interface InvoicesDownloadCheckerI {
  checkDownloadInvoicesIsRunning(): Promise<boolean>;

  get isRunning(): boolean;

  hideAlert(): void;

  get checkerIsLoading(): boolean;

  get isActive(): boolean;
}


export class DownloadInvoicesProgressTracker extends KsefEventContextDataHandler<WebsocketNotificationType.DownloadInvoices, DownloadInvoicesModelToMap> implements DownloadInvoicesProgressTrackerI, UpdateActiveEventsI {
  private eventConsumer?: EventConsumerI;
  private disposer?: IReactionDisposer;
  private readonly ksefEventCancellationHandler!: KsefEventCancellationService;
  private readonly downloadInvoicesChecker!: DownloadInvoicesChecker;

  constructor(private readonly events: EventsI, private readonly eventsProvider: SubscriptionEventsServiceI) {
    super(WebsocketNotificationType.DownloadInvoices, DownloadInvoicesModelMapper.map);
    this.ksefEventCancellationHandler = new KsefEventCancellationService(this);
    this.downloadInvoicesChecker = new DownloadInvoicesChecker(this);
    this.start();
    makeObservable(this);
  }

  @observable
  private _cancellationInProgress: boolean = false;

  @computed
  public get cancellationInProgress(): boolean {
    return this._cancellationInProgress || this.cancellationIsPending || this.pendingNotification?.State === SubscriptionStateEnum.CancellationInProgress;
  }

  @observable
  private _renderKey: number = 0;

  @computed
  public get renderKey(): number {
    return this._renderKey;
  }

  @computed
  public get isRunning(): boolean {
    return this.downloadInvoicesChecker.isRunning;
  }

  @observable
  private _isActive: boolean = false;

  @computed
  public get isActive(): boolean {
    return this._isActive;
  }

  @computed
  public get importedInvoices(): number {
    return this.result?.imported ?? 0;
  }

  @computed
  public get checkerIsLoading(): boolean {
    return this.downloadInvoicesChecker.loading;
  }

  @computed
  public get pendingNotificationId(): string | undefined {
    return this.pendingNotification?.NotificationId;
  }

  @computed
  public get pendingNotification(): WebSocketNotificationFragment | null {
    return this.events.pendingEvents.find(event => event.Type === WebsocketNotificationType.DownloadInvoices || event.Type === WebsocketNotificationType.AutoFetchingInvoices) ?? null;
  }

  @computed
  public get cancellationIsPending(): boolean {
    return this.ksefEventCancellationHandler.loading;
  }

  @computed
  public get isAutoDownload(): boolean {
    return this.pendingNotification?.Type === WebsocketNotificationType.AutoFetchingInvoices;
  }

  @computed
  public get isManualDownload(): boolean {
    return this.pendingNotification?.Type === WebsocketNotificationType.DownloadInvoices;
  }

  @computed
  public get needInvoicesRefresh(): boolean {
    return !!this.result?.refreshNeeded;
  }

  @computed
  public get trackerStatus(): DownloadStateUserMessage | null {
    return this.result?.state ?? null;
  }

  @computed
  public get nextUpdateTime(): string {
    if (this.result?.stateTime && typeof this.result?.pendingTimeInSeconds === 'number') {
      return new Date(this.result.stateTime.getTime() + this.result.pendingTimeInSeconds * 1000).toLocaleTimeString();
    }
    return '';
  }

  @computed
  public get userNotification(): string | undefined {

    if (this.cancellationInProgress || !this.result) {
      return;
    }

    if (this.trackerStatus === DownloadStateUserMessage.Pending) {
      return intl.formatMessage({id: Tr.downloadInvoicesPending}, {time: this.nextUpdateTime});
    }

    let translationKey: Tr | null = null;

    if (this.isManualDownload) {
      translationKey = Tr.downloadInvoicesProgress;

    } else if (this.isAutoDownload) {
      translationKey = Tr.autoFetchingInvoicesProgress;

    }

    if (!translationKey) {
      return;
    }

    return intl.formatMessage({id: translationKey}, {count: this.importedInvoices});
  }

  public updateEvent(event: WebSocketNotificationFragment): void {
    this.events.updateEvent(event);
  }

  @action.bound
  public refreshInvoices(): void {
    this._renderKey = this._renderKey + 1;
  }

  public async checkDownloadInvoicesIsRunning(): Promise<boolean> {
    await this.downloadInvoicesChecker.fetch();
    return this.downloadInvoicesChecker.isRunning;
  }

  @action.bound
  public hideAlert(): void {
    this.downloadInvoicesChecker.hideAlert();
  }

  public async cancelDownload(): Promise<void> {
    await this.ksefEventCancellationHandler.fetch();
  }

  public dispose() {
    this.disposer?.();
  }

  public start(): () => void {
    this.dispose();
    this.subscribeToNotificationIdChange();

    return () => this.dispose();
  }

  @action
  public setCancellationInProgress(inProgress: boolean): void {
    this._cancellationInProgress = inProgress;
  }

  @action
  private setIsActive(isActive: boolean): void {
    this._isActive = isActive;
  }

  private _initializeConsumer(notification: WebSocketNotificationFragment): void {
    this._resetTracking();
    this.eventConsumer = new DownloadInvoicesProgressConsumer(this, notification.Type as DownloadInvoicesVariant);
  }

  private _resetTracking() {
    this.setIsActive(false);
    this.setResult(null);
    this.eventConsumer?.cancel?.();
  }

  private _registerConsumer() {
    if (this.eventConsumer && !this.eventConsumer.registered) {
      this.eventConsumer.register(this.eventsProvider);
      this.setIsActive(true);
    }
  }

  private subscribeToNotificationIdChange() {
    this.disposer = this.reactionsManager.register(() => this.pendingNotificationId, () => {
      if (!this.pendingNotification) {
        this._resetTracking();
        return;

      } else if (this.pendingNotification.Type !== this.eventConsumer?.type) {
        this._initializeConsumer(this.pendingNotification);
      }

      this.getAsyncData({
        notificationId: this.pendingNotification.NotificationId,
      }, undefined, 0);

      this.ksefEventCancellationHandler.configure({NotificationId: this.pendingNotification.NotificationId});

      if (!this.eventConsumer) {
        return;
      }

      this._registerConsumer();

    }, {fireImmediately: true});
  }
}
