import {IMask} from 'react-imask';
import {DateObj, dateObjToDate, getOffsetDate, isAfterDate, isBeforeDate} from './Calendar.helper';
import {FactoryOpts} from 'imask';

type Format = 'Y-M-D' | 'D-M-Y'
type Formatters<T extends string | Date> = Record<Format, (date: T extends string ? DateObj : string, pattern: Format) => T>
const defaultMaskPattern = 'D-M-Y';
const dateFromStringStrategies: { toDate: Formatters<Date>, toString: Formatters<string> } = {
  toString: {
    'Y-M-D': (dateObj) => {
      const {year, month, day} = datePartsToStrings(dateObj);
      return `${year}-${month}-${day}`;
    },
    'D-M-Y': (dateObj) => {
      const {year, month, day} = datePartsToStrings(dateObj);
      return `${day}-${month}-${year}`;
    },
  },
  toDate: {
    'Y-M-D': (str) => {
      const yearMonthDay = str.split('-');
      return new Date(parseInt(yearMonthDay[0], 10), parseInt(yearMonthDay[1], 10) - 1, parseInt(yearMonthDay[2], 10));
    },
    'D-M-Y': (str) => {
      const [day, month, year] = str.split('-');
      return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    },
  },
};

export const parseDate = (str: string, pattern?: Format): Date => {
  const validatedPattern = getValidatedPattern(pattern);
  return dateFromStringStrategies.toDate[validatedPattern](str, validatedPattern);
};

export const formatDate = (dateObj: DateObj, pattern?: Format): string => {
  const validatedPattern = getValidatedPattern(pattern);
  return dateFromStringStrategies.toString[validatedPattern](dateObj, validatedPattern);
};

export const toDateParts = (date: string | Date, pattern?: Format): DateObj => {
  date = typeof date === 'string' ? parseDate(date, pattern) : date;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return {year, month, day};
};

export const datePartsToStrings = ({day, month, year}: DateObj): Record<keyof DateObj, string> => {
  return {
    year: String(year),
    month: month >= 10 ? String(month) : `0${month}`,
    day: day >= 10 ? String(day) : `0${day}`,
  };
};

export const isValidDateFormat = (dateStr: string, pattern?: Format): boolean => {
  if (dateStr.length === 0) {
    return false;
  }
  const {year, month, day} = toDateParts(dateStr, pattern);
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }
  return `${year}`.length === 4 && [1, 2].includes(`${month}`.length) && [1, 2].includes(`${day}`.length);
};

export const toDateObj = (dateStr: string | null, pattern?: Format): DateObj | null => {
  if (dateStr === null || !isValidDateFormat(dateStr, pattern)) {
    return null;
  }
  return toDateParts(dateStr, pattern);
};

export const toDateStr = (dateObj: DateObj, pattern?: Format): string => {
  return formatDate(dateObj, pattern);
};

type fixDateValueFn = {
  (nextValue: string, _: { minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number, pattern?: Format }): string;
};

export const fixDateValue: fixDateValueFn = (nextValue, {minDate, maxDate, pattern, dayOffset = 1}) => {
  const valueFix = nextValue.substring(0, 10);
  const minDateRef = dateObjToDate(minDate);
  const maxDateRef = dateObjToDate(maxDate);

  const {year, month, day} = toDateParts(valueFix, pattern);
  let nextDate = new Date(year, month - 1, day);

  if (isBeforeDate(nextDate, minDateRef)) {
    nextDate = getOffsetDate(minDateRef, dayOffset);
  }

  if (isAfterDate(nextDate, maxDateRef)) {
    nextDate = getOffsetDate(maxDateRef, -dayOffset);
  }

  return adjustToPattern(nextDate, pattern);
};

const validatePattern = (str: Format | undefined): boolean => {
  if (!str) {
    return false;
  }
  const validateChar = (char: 'Y' | 'M' | 'D') => str.split('').reduce((sum, currentChar, i, arr) => {
    return currentChar === char ? sum + 1 : sum;
  }, 0) === 1;
  const matched = /[YMD]-[YMD]-[YMD]/.test(str);
  return matched && [validateChar('Y'), validateChar('M'), validateChar('D')].every(Boolean);
};

const getValidatedPattern = (pattern?: Format) => validatePattern(pattern) ? pattern as Format : defaultMaskPattern;

export const adjustToPattern = (date: Date, pattern?: Format): string => {
  const {year, month, day} = datePartsToStrings(toDateParts(date, pattern));
  return getValidatedPattern(pattern).replace('Y', year).replace('M', month).replace('D', day);
};


export const getIMaskConfig = ({
                                 value,
                                 minDate,
                                 maxDate,
                                 disabled,
                               }: {
  value: string;
  minDate: Date | DateObj;
  maxDate: Date | DateObj;
  disabled?: boolean
}): FactoryOpts | undefined => {

  if (disabled) {
    return undefined;
  }

  const minDateRef = dateObjToDate(minDate);
  const maxDateRef = dateObjToDate(maxDate);

  const pattern = (() => {
    if (value.length <= 2) {
      return 'd-`m-`Y';
    }

    if (value.length > 2 && value.length <= 4) {
      return 'd{-}`m-`Y';
    }

    return 'd{-}`m{-}`Y';
  })();

  return {
    mask: Date,
    pattern,
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
        placeholderChar: 'D',
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
        placeholderChar: 'M',
      },
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: 'R',
        from: minDateRef.getFullYear(),
        to: maxDateRef.getFullYear(),
      },
    },
    autofix: true,
    lazy: false,
    overwrite: true,
    min: minDateRef,
    max: maxDateRef,
    parse: (str: string) => {
      const yearMonthDay = str.split('-');
      return new Date(parseInt(yearMonthDay[2], 10), parseInt(yearMonthDay[1], 10) - 1, parseInt(yearMonthDay[0], 10));
    },
    format: (date: Date | null) => {
      if (date === null) {
        return '';
      }

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return [day >= 10 ? day : `0${day}`, month >= 10 ? month : `0${month}`, year].join('-');
    },
  };
};
