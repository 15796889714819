import {
  CompanyVehiclesResponse,
  CreateCompanyVehicleDocument,
  CreateCompanyVehicleMutation,
  CreateCompanyVehicleMutationVariables,
  UpdateCompanyVehicleDocument,
  UpdateCompanyVehicleMutation,
  UpdateCompanyVehicleMutationVariables,
  VehicleUsageTypeEnum,
} from '@symfonia-ksef/graphql';
import {Tr} from '../../../../../../../locales/translationKeys';
import {intl} from '../../../../../../root/IntlProvider';
import {apolloClient} from '../../../../../../root/providers/GraphQLProvider';
import {action, makeObservable, observable} from 'mobx';
import {GetVehicleById} from '../../../queries/GetVehicleById';
import {CheckIfLicensePlateExists} from '../../../queries/checkIfLicensePlateExists';
import {
  IVehicleState,
} from '../../../../../../earchive/pages/Account/Posting/pages/PostingConfiguration/Vehicles/state/IVehicleState';
import {IPostingDetailsBodyListState} from './IPostingDetailsBodyListState';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export enum VehicleModalType {
  EditVehicle,
  AddVehicle
}

export class VehicleModalState {

  parent: IVehicleState | IPostingDetailsBodyListState;

  @observable
  isEditModelType = false;

  @observable
  modalTitle = intl.formatMessage({id: Tr.addVehicle});

  @observable
  modalAddEditOpen = false;

  @observable
  modalType: VehicleModalType = VehicleModalType.AddVehicle;

  @observable
  modalDeleteOpen = false;

  @observable
  licencePlateError: Tr | undefined = undefined;

  @observable
  shouldRefresh = false;

  @observable
  isLoading = false;

  @observable
  selectedVehicle: CompanyVehiclesResponse | undefined;

  @observable
  editedVehicle: CompanyVehiclesResponse = {
    Id: undefined,
    RegistrationNumber: '',
    VehicleUsageType: VehicleUsageTypeEnum.Mixed,
    Description: '',
  };

  @observable
  error: string | null = null;

  constructor(p: IVehicleState | IPostingDetailsBodyListState) {
    makeObservable(this);
    this.parent = p;
  }

  @action setOpenModal(o: boolean) {
    this.modalAddEditOpen = o;
  }

  @action setUsageType(p: VehicleUsageTypeEnum) {
    this.editedVehicle.VehicleUsageType = p;
  }

  @action setDescription(p: string) {
    if (p.length > 70) {
      p = p.substring(0, 70);
    }
    this.editedVehicle.Description = p;
  }

  @action setIsLoading(l: boolean) {
    this.isLoading = l;
  }

  @action setShouldRefresh(p: boolean) {
    this.shouldRefresh = p;
  }

  @action setModalTitle(p: Tr, edit: boolean) {
    this.modalTitle = intl.formatMessage({id: p});
    this.isEditModelType = edit;
  }

  @action setModalType(t: VehicleModalType) {
    this.modalType = t;
  }

  @action
  public setSelectedVehicle(veh: CompanyVehiclesResponse) {
    this.selectedVehicle = veh;
    this.editedVehicle.Id = veh.Id;
    this.editedVehicle.RegistrationNumber = veh.RegistrationNumber;
    this.setDescription(veh.Description ?? '');
    this.editedVehicle.VehicleUsageType = veh.VehicleUsageType ?? VehicleUsageTypeEnum.Mixed;
  }

  @action
  public setVehicleToEdit() {
    this.setModalTitle(Tr.editVehicle, true);
    this.setOpenModal(true);
  }


  @action
  public resetVehicle() {
    this.editedVehicle.Id = undefined;
    this.editedVehicle.RegistrationNumber = '';
    this.editedVehicle.VehicleUsageType = VehicleUsageTypeEnum.Mixed;
    this.editedVehicle.Description = '';
    this.selectedVehicle = undefined;
    this.setOpenModal(false);
  }

  @action
  async saveVehicle(envId: string | undefined, companyId: string | undefined, shouldResetVehicle = true) {
    if (companyId === null) {
      throw new Error('CompanyId has to be settled before load data for invoice preview');
    }
    if (envId === null) {
      throw new Error('EnvironmentId has to be settled before load data for invoice preview');
    }

    this.setLoading(true);
    const {
      data,
      errors,
    } = await apolloClient.query<CreateCompanyVehicleMutation, CreateCompanyVehicleMutationVariables>({
      query: CreateCompanyVehicleDocument,
      context: {envId},
      variables: {
        CompanyId: companyId,
        RegistrationNumber: this.editedVehicle.RegistrationNumber,
        VehicleUsageType: this.editedVehicle.VehicleUsageType,
        Description: this.editedVehicle.Description,
      },
    });

    if (errors) {
      this.setError(Tr.vehicleSaveError);
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.vehicleSaveError}), ToastVariant.ERROR, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.setLoading(false);
      this.setShouldRefresh(false);
      return;
    }

    if (data.CreateCompanyVehicle.CompanyVehicle) {
      this.setError(null);
      this.setSelectedVehicle(data.CreateCompanyVehicle.CompanyVehicle);
      const registrationNumber = data.CreateCompanyVehicle.CompanyVehicle.RegistrationNumber;
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.vehicleSaveSuccess}, {registrationNumberPlate: registrationNumber}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.modalAddEditOpen = false;
      this.parent.setShouldRefresh(true);
      this.setLoading(false);
      this.setShouldRefresh(true);
      if (shouldResetVehicle) this.resetVehicle();
    }
  }

  @action
  async updateVehicle(envId: string | undefined, shouldResetVehicle = true) {
    if (envId === null) {
      throw new Error('EnvironmentId has to be settled before load data for invoice preview');
    }
    this.setLoading(true);
    const {
      data,
      errors,
    } = await apolloClient.mutate<UpdateCompanyVehicleMutation, UpdateCompanyVehicleMutationVariables>({
      mutation: UpdateCompanyVehicleDocument,
      context: {
        envId,
      },
      variables: {
        CompanyId: envId,
        Id: this.editedVehicle!.Id,
        RegistrationNumber: this.editedVehicle.RegistrationNumber,
        VehicleUsageType: this.editedVehicle.VehicleUsageType,
        Description: this.editedVehicle.Description,
      },
    });

    if (errors || !data?.UpdateCompanyVehicle?.IsDone) {
      this.setError(Tr.updateVehicleFailed);
      earchiveState.alertsState.addAlert(intl.formatMessage(
        {id: Tr.updateVehicleFailed},
        {registrationNumber: this.editedVehicle.RegistrationNumber}), ToastVariant.ERROR, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.setLoading(false);
      return;
    }

    if (data.UpdateCompanyVehicle.IsDone) {
      this.setError(null);
      this.setShouldRefresh(true);
      earchiveState.alertsState.addAlert(intl.formatMessage(
        {id: Tr.updateVehicleSuccess},
        {registrationNumber: this.editedVehicle.RegistrationNumber}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.modalAddEditOpen = false;
      this.parent.setShouldRefresh(true);
      if (shouldResetVehicle) this.resetVehicle();
    }
  }


  @action.bound
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @action.bound
  setError(error: string | null | undefined) {
    this.error = error ?? null;
  }

  @action
  public async findVehicle(vehicleId: string, companyId: string | undefined): Promise<void> {
    return await GetVehicleById(vehicleId, companyId)
      .then(v => {
        if (v !== undefined) {
          this.setSelectedVehicle(v);
        }
      });
  }

  @action
  public async checkIfLicensePlateExist(registrationNumber: string, companyId: string | undefined): Promise<boolean> {
    return await CheckIfLicensePlateExists(registrationNumber, companyId)
      .then(v => {
        if (v !== undefined) {
          return v.LicensePlateExists;
        }
        return false;
      });
  }
}
