import {FC} from 'react';
import {DesktopContextualMenu, DesktopContextualMenuProps} from './DesktopContextualMenu';
import {MobileContextualMenu, MobileContextualMenuProps} from './MobileContextualMenu';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';

export type ContextualMenuProps =
  DesktopContextualMenuProps
  & Omit<MobileContextualMenuProps, 'isOpen' | 'actions' | 'onClose'>

export const ContextualMenu: FC<ContextualMenuProps> = observer((props) => {
  return useDeviceViewport(earchiveState.layoutState.setViewport).isPhone ? <MobileContextualMenu {...props}/> : <DesktopContextualMenu {...props}/>;
});
