/* eslint-disable no-console */
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {LogEvent} from './logEvent';
import {LogLevel} from './logLevel';
import {isLocalDev} from '../helpers/helperFunctions';

export interface LoggerInterface {
  verbose: (message: string, data?: any) => void;
  debug: (message: string, data?: any) => void;
  info: (message: string, data?: any) => void;
  warning: (message: string, data?: any) => void;
  error: (message: string, error: Error | any, data?: any) => void;
  critical: (message: string, error: Error | any, data?: any) => void;
}

export class Logger implements LoggerInterface {
  public appInsights: ApplicationInsights;

  public reactPlugin: ReactPlugin;

  private readonly logLevel: LogLevel;

  private readonly logToConsoleEnabled: boolean;

  constructor(instrumentationKey: string, logLevel: LogLevel, logToConsoleEnabled: boolean) {
    this.logLevel = logLevel;
    this.logToConsoleEnabled = logToConsoleEnabled;

    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking: false,
        enableUnhandledPromiseRejectionTracking: true,
        enableAutoRouteTracking: true,
        extensions: [this.reactPlugin],
      },
    });

    if (!isLocalDev) {
      this.appInsights.loadAppInsights();
    }
  }

  private logLevelEnabled(logLevel: LogLevel) {
    return this.logLevel <= logLevel;
  }

  // eslint-disable-next-line
  private logToConsole(event: LogEvent) {
    switch (event.level) {
      case LogLevel.Verbose:
        console.debug(event.message, event);
        break;
      case LogLevel.Debug:
        console.debug(event.message, event);
        break;
      case LogLevel.Info:
        console.info(event.message, event);
        break;
      case LogLevel.Warning:
        console.warn(event.message, event);
        break;
      case LogLevel.Error:
        console.error(event.message, event);
        break;
      case LogLevel.Critical:
        console.error(event.message, event);
        break;
      default:
        console.debug(event.message, event);
    }
  }

  private log(event: LogEvent) {
    if (this.logToConsoleEnabled || event.level > LogLevel.Error) {
      this.logToConsole(event);
    }

    if (this.logLevelEnabled(event.level) && !isLocalDev) {
      if (event.isException) {
        this.appInsights?.trackException?.({
          id: event.id,
          exception: event.error as Error,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          properties: event.data,
          severityLevel: event.level,
        });
      } else {
        this.appInsights?.trackEvent?.({
          name: event.message,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          properties: event.data,
        });
      }

      this.appInsights?.flush();
    }
  }

  critical(message: string, error: any, data: any): void {
    this.log(new LogEvent(LogLevel.Critical, message, data, error));
  }

  debug(message: string, data: any): void {
    this.log(new LogEvent(LogLevel.Debug, message, data));
  }

  error(message: string, error?: any, data?: any): void {
    this.log(new LogEvent(LogLevel.Error, message, data, error));
  }

  info(message: string, data?: any): void {
    this.log(new LogEvent(LogLevel.Info, message, data));
  }

  verbose(message: string, data: any): void {
    this.log(new LogEvent(LogLevel.Verbose, message, data));
  }

  warning(message: string, data: any): void {
    this.log(new LogEvent(LogLevel.Warning, message, data));
  }
}
