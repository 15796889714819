import clsx from 'clsx';
import {FC} from 'react';
import {Img} from '../Image/Image';
import * as _icons from './assets';
import {TestableElement} from '../../external/types';

const icons = _icons as Record<keyof typeof _icons, string>;

export enum SpinnerSize {
  SM = 'SM',
  LG = 'LG',
}

export type SpinnerProps = {
  size?: SpinnerSize;
  className?: string;
} & TestableElement;

export const Spinner: FC<SpinnerProps> = ({size = SpinnerSize.LG, className = undefined, testId = undefined}) => (
  <Img
    testId={testId}
    testElement="spinner"
    src={size === SpinnerSize.LG ? icons.spinnerLg : icons.spinnerSm}
    className={clsx(
      'animate-spin',
      {
        'w-[72px] h-[72px]': size === SpinnerSize.LG,
        'w-[54px] h-[54px]': size === SpinnerSize.SM,
      },
      className,
    )}
    alt="spinner"
  />
);
