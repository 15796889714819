import {Tr} from '@symfonia-ksef/locales/keys';
import {SupplierTypeEnum} from '../../../../common/enums/SupplierType';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {CheckboxSize, Toggle} from '@symfonia/brandbook';

export const ContractorTypeSwitches: FC<{
  supplierType: number;
  setSupplierType: React.Dispatch<React.SetStateAction<number | undefined>>;
  testId?: string;
}> = ({supplierType, setSupplierType, testId = undefined}) => {
    return (
      <div>
        <div><FormattedMessage id={Tr.ContractorTypeSwitchesHeader}/>:</div>
        <div className='flex items-center mt-[15px]'>
          <FormattedMessage id={Tr.ContractorTypeSwitchesCostLabel}/>
          <Toggle
              testId={`${ testId }SupplierTypeCostSwitch`}
              size={CheckboxSize.SM}
              className='mx-[10px]'
              value={SupplierTypeEnum.Cost}
              checked={(supplierType & SupplierTypeEnum.Cost) === SupplierTypeEnum.Cost}
              onChange={() => setSupplierType(supplierType ^ SupplierTypeEnum.Cost)}
            />
          <div className='mx-[10px]'/>
          <FormattedMessage id={Tr.ContractorTypeSwitchesTradingLabel}/>
          <Toggle
              testId={`${ testId }SupplierTypeTradingSwitch`}
              size={CheckboxSize.SM}
              className='mx-[10px]'
              value={SupplierTypeEnum.Trading}
              checked={(supplierType & SupplierTypeEnum.Trading) === SupplierTypeEnum.Trading}
              onChange={() => setSupplierType(supplierType ^ SupplierTypeEnum.Trading)}
            />
        </div>
      </div>
  );
};
