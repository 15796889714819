import {action, makeObservable, observable} from "mobx";

export class PostingTableRefreshService {
    @observable 
    public refreshCount: number = 0;

    constructor() {
        makeObservable(this);
    }

   @action
   public incrementRefreshCounter() {
    this.refreshCount = ++this.refreshCount;
   }
}