import {FactoryOpts} from 'imask';
import {IMask} from 'react-imask';
import {
  DateObj,
  dateObjToDate,
  dateToString,
  getOffsetDate,
  isAfterDate,
  isBeforeDate,
} from '../../external/elements/Calendar/Calendar.helper';
import {DATE_DMY} from '../../external/types';

export const escapeRegExp = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const isValidDateFormat = (dateStr: string): boolean => {
  if (typeof dateStr !== 'string') {
    return false;
  }

  if (dateStr.length === 0) {
    return false;
  }
  const [day, month, year] = dateStr.split('-').map(v => parseInt(v, 10));
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }
  return `${year}`.length === 4 && [1, 2].includes(`${month}`.length) && [1, 2].includes(`${day}`.length);
};

export const isValidYearMonthFormat = (dateStr: string): boolean => {
  if (dateStr.length === 0) {
    return false;
  }
  const [year, month] = dateStr.split('-').map(v => parseInt(v, 10));
  if (Number.isNaN(year) || Number.isNaN(month)) {
    return false;
  }
  return `${year}`.length === 4 && [1, 2].includes(`${month}`.length);
};

export const toDateObj = (dateStr: string | null): DateObj | null => {
  if (dateStr === null || isValidDateFormat(dateStr) === false) {
    return null;
  }

  const [day, month, year] = dateStr.split('-');
  return {
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    day: parseInt(day, 10),
  };
};

export enum DateStrFormat {
  YYYY_MM_DD = 'YYYY-MM-DD',
  DD_MM_YYYY = 'DD-MM-YYYY',
}

export const toDateStr = ({year, month, day}: DateObj, format: DateStrFormat = DateStrFormat.DD_MM_YYYY): DATE_DMY => {
  if (format === DateStrFormat.YYYY_MM_DD) {
    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}` as DATE_DMY;
  }
  return `${`0${day}`.slice(-2)}-${`0${month}`.slice(-2)}-${year}` as DATE_DMY;
};

type fixDateValueFn = {
  (nextValue: string, _: {minDate: Date | DateObj; maxDate: Date | DateObj; dayOffset?: number}): DATE_DMY;
};

export const fixDateValue: fixDateValueFn = (nextValue, {minDate, maxDate, dayOffset = 1}) => {
  const valueFix = nextValue.substring(0, 10);
  const minDateRef = dateObjToDate(minDate);
  const maxDateRef = dateObjToDate(maxDate);

  const [year, month, day] = valueFix.split('-').map(s => parseInt(s, 10) || 1);
  let nextDate = new Date(year, month - 1, day);

  if (isBeforeDate(nextDate, minDateRef)) {
    nextDate = getOffsetDate(minDateRef, dayOffset);
  }

  if (isAfterDate(nextDate, maxDateRef)) {
    nextDate = getOffsetDate(maxDateRef, -dayOffset);
  }

  return dateToString(nextDate) as DATE_DMY;
};

export const getIMaskConfig = ({
  value,
  minDate,
  maxDate,
}: {
  value: string;
  minDate: Date | DateObj;
  maxDate: Date | DateObj;
}): FactoryOpts & {pattern: string} => {
  const minDateRef = dateObjToDate(minDate);
  const maxDateRef = dateObjToDate(maxDate);

  const pattern = (() => {
    if (value.length <= 2) {
      return 'd-`m-`Y';
    }

    if (value.length > 2 && value.length <= 4) {
      return 'd{-}`m-`Y';
    }

    return 'd{-}`m{-}`Y';
  })();

  return {
    mask: Date,
    pattern,
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
        placeholderChar: 'D',
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
        placeholderChar: 'M',
      },
      Y: {
        mask: IMask.MaskedRange,
        placeholderChar: 'R',
        from: minDateRef.getFullYear() - 100,
        to: maxDateRef.getFullYear() + 100,
      },
    },
    autofix: true,
    lazy: false,
    overwrite: true,
    min: minDateRef,
    max: maxDateRef,
    parse: (str: string) => {
      const yearMonthDay = str.split('-');
      return new Date(parseInt(yearMonthDay[2], 10), parseInt(yearMonthDay[1], 10) - 1, parseInt(yearMonthDay[0], 10));
    },
    format: (date: Date | null) => {
      if (date === null) {
        return '';
      }

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return [day >= 10 ? day : `0${day}`, month >= 10 ? month : `0${month}`, year].join('-');
    },
  };
};
