import {observer} from 'mobx-react-lite';
import {FC, PropsWithChildren, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {IPostingState} from './state/IPostingState';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface PostingInitializationProps {
  state: IPostingState;
}

export const PostingInitialization: FC<PropsWithChildren<PostingInitializationProps>> = observer(({
                                                                                                    state,
                                                                                                    children,
                                                                                                  }) => {
  const {autopostingId} = useParams();
  const {posting, company: {autoPosting}} = earchiveState;
  useEffect(() => {
    state.postingDetailsState.setDecreeId(autopostingId);
    state.postingDetailsState.setAcceptBeforeSendToFK(!!autoPosting?.AcceptBeforeSendToFK?.IsActive);
    state.postingDetailsState.setAcceptBeforeSendToFKTurnOnInSettings(!!autoPosting?.AcceptBeforeSendToFK?.IsActive);
    state.postingDetailsState.setAcceptOwn(!!autoPosting?.AcceptBeforeSendToFK?.CanAcceptOwn);
    posting.set({postingId: autopostingId});
  }, [autopostingId]);

  return <>{children}</>;
});
