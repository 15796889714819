import {GetSettlementsQuery, GetSettlementsQueryVariables} from "@symfonia-ksef/graphql";
import {ExtendedTableServiceI} from "../../../../../../../root/services/TableServices/ExtendedTableService";
import {SettlementsDataSourceHandler, SettlementsRepositoryI, SettlementsRow} from "./State/Settlements.repository";
import {ISettlementsState} from "./State/ISettlementsState";
import {TableBuilder} from "../../../../../../../root/services/TableServices/TableBuilder";
import {settlementsColumns} from "./SettlementsTable/SettlementsTableConfig";
import {BaseModule} from "../../../../../../../root/services/MobXServices/BaseModule";


export type SettlementsTableType = ExtendedTableServiceI<SettlementsRow, 'GetSettlements', GetSettlementsQuery, GetSettlementsQueryVariables, ISettlementsState>;

export class SettlementsPageService extends BaseModule {
  public readonly tableService!: SettlementsTableType;
  public readonly repository!: SettlementsRepositoryI;

  constructor(private readonly state: ISettlementsState) {
    super();

    const dataSourceHandler = new SettlementsDataSourceHandler(state);
    this.repository = dataSourceHandler.repository;
    this.tableService = TableBuilder.create<SettlementsRow, 'GetSettlements', GetSettlementsQuery, GetSettlementsQueryVariables, ISettlementsState>()
      .connectKeyFactory(row => row.Id)
      .connectColumns(settlementsColumns)
      // .connectSortBy({
      //   sortBy: {name: 'CreatedAt', direction: TableSortDirection.DESC},
      //   persistKey: 'postingTableSortBy',
      // })
      .connectContext(state)
      .connectDataSource(dataSourceHandler)
      // .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}, persistKey: 'postingTablePagination'})
      //.connectSelection()
      .buildExtended();
  }

  protected async _onMount(): Promise<void> {
    this.tableService.onMount();
    await this.repository.fetch();
  }

  protected _onUnmount() {
    this.tableService.onUnmount();
  }
}
