import {
  WebSocketNotificationFragment,
  WebSocketNotificationResponseFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {JobRunnerManager, JobRunnerManagerI, OnChange} from './JobRunnerManager';
import {BaseRequestVariables, EventJobRunnerI} from './EventJobRunner';
import {Mapper, NotificationDataType} from './helpers/NotificationDataParsers';
import {
  KSeFEventConverterFactory,
} from '../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/KSeFEventsConvertersManager';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {KsefEventContextDataHandler, KsefEventContextDataHandlerI} from './KsefEventContextDataHandler';

export type Notification = Omit<WebSocketNotificationFragment, 'ResponseJson'> | null

export type ActiveEvent<T extends WebsocketNotificationType = WebsocketNotificationType> = (WebSocketNotificationFragment & {
  data: NotificationDataType<T> | null
})


//Służy do zarządzania stanem procesu subskrypcyjnego - spina cały proces w jedno (uruchomienie procesu, otrzymanie eventu, pobranie danych kontekstowych dla eventu)
//zarządza cyklem życia JobRunnera procesu danego typu, pozwala go skonfigurować i uruchomić
//Pobiera dane kontekstowe dla notyfikacji, łączy je z notyfikacją
export interface NotificationServiceI<T extends WebsocketNotificationType = WebsocketNotificationType, P extends BaseRequestVariables = BaseRequestVariables, R = unknown> extends KsefEventContextDataHandlerI<T> {

  //JobRunner pozwalający uruchomić i monitorować proces
  get jobRunner(): EventJobRunnerI<P> & R;

  //aktywne procesy danego typu w danym kontekście użytkownika i firmy
  get activeEvents(): ActiveEvent<T>[];

  //pozwala ustawić opcjonalny callback wykonywany w momencie zresetowania jobRunnera (gdy zmieni się kontekst firmy i użytkownika)
  registerOnChangeHandler(onChange: OnChange<P, R>): void;

}

export abstract class NotificationDataService<T extends WebsocketNotificationType = WebsocketNotificationType, P extends BaseRequestVariables = BaseRequestVariables, R = unknown, U = unknown> extends KsefEventContextDataHandler<T, U> implements NotificationServiceI<T, P, R> {


  //zarządza cyklem życia jobRunnera (resetuje go przy zmianie kontekstu usera i firmy)
  private jobRunnerManager: JobRunnerManagerI<P, R>;

  protected constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, createJobRunner: () => EventJobRunnerI<P> & R, mapper?: Mapper<T, U>) {
    const jobRunnerManager = new JobRunnerManager<P, R>(createJobRunner, envObserver);
    super(jobRunnerManager.jobRunner.type, mapper);
    makeObservable(this);
    this.jobRunnerManager = jobRunnerManager;
  }

  public get jobRunner(): EventJobRunnerI<P> & R {
    return this.jobRunnerManager.jobRunner;
  }

  @computed
  public get activeEvents(): ActiveEvent<T>[] {
    const events: Array<WebSocketNotificationFragment & { data: NotificationDataType<T> | null }> = [];

    for (const event of this.jobRunner.activeEvents) {
      const data = this.convertNotificationData(event);
      const {ResponseJson, ...rest} = event;
      events.push({...rest, data});
    }
    return events;
  }

  public registerOnChangeHandler(onChange: OnChange<P, R>): void {
    this.jobRunnerManager.registerOnChangeHandler(onChange);
  }

  protected convertNotificationData(data: WebSocketNotificationResponseFragment) {
    return KSeFEventConverterFactory.create<T, U>(data, {saveAsParser: true})?.model?.data ?? null;
  }
}
