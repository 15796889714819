import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {ReactNode} from 'react';
import {GrantPermissionsDataModel} from '../../../../../../services/helpers/NotificationDataParsers';
import {
  FeedbackType,
  grantPermissionsFeedbackFactory,
} from '../../../../../../services/helpers/grantPermissionsHelpers';
import {envObserver} from '@symfonia-ksef/state/rootRepository';

export class PermissionsEventConverter extends KSeFEventsConverter<WebsocketNotificationType.GrantPermission> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.GrantPermission, event);
  }

  public get actionElement(): undefined {
    return;
  }

  public get notification(): ReactNode {
    return this.createNotification(grantPermissionsFeedbackFactory(envObserver, this.model.hasError ? FeedbackType.Error : FeedbackType.Success, this.event.ErrorType, this.model.data ?? undefined)());
  }

  public get model(): { data: GrantPermissionsDataModel | null; hasError: boolean } {
    const data = this.parser.parse();
    return {data, hasError: !!this.event.ErrorType};
  }

  public action(): void {
    return;
  }
}
