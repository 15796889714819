import {observer} from 'mobx-react-lite';
import {FC} from 'react';
import {Checkbox, CheckboxProps} from '@symfonia/brandbook';

export const ObserverCheckbox: FC<Omit<CheckboxProps, 'checked'> & { checkIsSelected(): boolean }> = observer(({
                                                                                                                 checkIsSelected,
                                                                                                                 ...props
                                                                                                               }) => {
  return <Checkbox checked={checkIsSelected()} {...props}/>;
});
