import {observer} from 'mobx-react-lite';
import {VatRegistryTableColumnsConfig} from './VatRegistryTableColumnsConfig';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {VatRegistryChangeModal} from '../../modals/VatRegistryChangeModal';
import {Icon, IconColor, IconSvg, Table, TableRow} from '@symfonia/brandbook';
import {intl} from '../../../../../root/IntlProvider';

export interface VatRegistryTableProps {
  state: IPostingDetailsState;
}

export const VatRegistryTable = observer(({state}: VatRegistryTableProps) => {
  const editable = state.isPageEditableByStatus;
  const vatRegistryTableColumnsConfig = VatRegistryTableColumnsConfig();

  const tableHeaders = vatRegistryTableColumnsConfig.map(e => {
    return {name: e.field, title: e.headerName, width: e.width !== undefined ? e.width : 'w-[260px]', sortable: true};
  });

  return <div className="py-4 w-full">

    {state.vatRegistryChangeModalState && state.vatRegistryChangeModalState.isOpen && (
      <VatRegistryChangeModal
        state={state.vatRegistryChangeModalState}
        onClose={() => state.onCloseVatRegistryChangeModal()}
        onFinished={selectedVatRegistryId => state.onSaveVatRegistryChangeModal(selectedVatRegistryId)}
      />
    )}
    <div>
      <Table header={tableHeaders} className={'w-full font-normal'}>
        {state.postingDetailsStore.postingDetails?.VatSummaries !== undefined
          ? (state.postingDetailsStore.postingDetails?.VatSummaries.map((row, idx) => {
              return <TableRow key={idx}>
                <button
                  disabled={!editable}
                  className={'w-full flex justify-between border-b-primary-500 border-b text-base font-quicksand'}
                  onClick={() => editable ? state.openVatRegisteriesModal(row.VatRegisterId, row.Id) : null}>
                  {row.VatRegisterName}
                  {editable && <Icon svg={IconSvg.EDIT} color={IconColor.GREEN_700} className={'ml-[10px]'}/>}
                </button>
                <span>{intl.formatMessage({id: row.TaxRate})}</span>
                <span>{Intl.NumberFormat('pl-PL', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(row.Net)}</span>
                <span>{Intl.NumberFormat('pl-PL', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(row.Vat)}</span>
                <span>{Intl.NumberFormat('pl-PL', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(row.Gross)}</span>
                <span>{row.GTUItems !== undefined && row.GTUItems.length > 0
                  ? row.GTUItems.map((e, idx) => (idx > 0 ? ', ' + e : e))
                  : '-'
                }</span>
              </TableRow>;
            })
          ) : <></>
        }
      </Table>
    </div>
  </div>;
});
