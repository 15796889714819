import {observer} from 'mobx-react-lite';
import {FC, useEffect, useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import FilehubTableMenu from '../components/FilehubTableMenu';
import {Checkbox, CheckboxSize, IconSvg, Table, TableRowHeight, Tag, TagColor, TagSize} from '@symfonia/brandbook';
import {filehubTableConfig} from './FilehubTableConfig';
import {TableRow} from '@symfonia/brandbook';
import {Button} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import {convertBytesToMBs} from '@symfonia/utils';
import {DateRenderer} from '../../common';
import {Attachment} from '@symfonia-ksef/graphql';
import FilehubMultipleActionsBar from '../components/FilehubMultipleActionsBar';
import FilehubFooterButtons from '../components/FilehubFooterButtons';
import {useFilehubMethods} from './useFilehubMethods';
import _ from 'lodash';
import {MultipleActionsRequestTypeEnum} from '../../earchive/pages/Documents/state/FilehubState';
import {FilehubNoAttachments} from '../components/FilehubNoAttachments';
import DeleteAttachmentModal from '../modals/DeleteAttachmentModal';
import DownloadAttachmentModal from '../modals/DownloadAttachmentModal';

interface FilehubTableProps {
  state: IFilehubState;
}

export const FilehubTable: FC<FilehubTableProps> = observer(({state}) => {
  const [allSelectedAttachments, setAllSelectedAttachments] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const attachments = state.attachments;
  const attachmentsWithoutFilters = state.attachmentsWithoutFilters;
  const selectedAttachmentsLength = selectedAttachments.length;
  const showFooterButtons = state.areFooterButtonsShown;
  const attachmentsLength = attachments.length;
  const isMultipleActionsBarVisible = selectedAttachmentsLength > 0;
  const hasAttachments = state.attachments.length > 0;

  const clearSelection = () => {
    setAllSelectedAttachments(false);
    setSelectedAttachments([]);
  };

  const {changeAttachmentStatusAction, getAttachmentsAction} = useFilehubMethods(state, clearSelection);

  const handleHeaderCheckboxSelect = (checked: boolean) => {
    if (checked) setSelectedAttachments(attachmentIds);
    else setSelectedAttachments([]);
    setAllSelectedAttachments(checked);
  };

  const filehubTableHeaders = filehubTableConfig(handleHeaderCheckboxSelect, allSelectedAttachments);

  const attachmentIds = attachments.map(attachment => attachment.Id);

  const undoFooterButton = () => {
    const deepAttachmentsWithoutFilters = _.cloneDeep(attachmentsWithoutFilters);
    state.setAttachments(deepAttachmentsWithoutFilters);
    state.setAreFooterButtonsShown(false);
    clearSelection();
  };

  const saveFooterButton = async () => {
    setLoading(true);

    if (state.multipleActionsRequestType === MultipleActionsRequestTypeEnum.Private) {
      await changeAttachmentStatusAction(selectedAttachments, false);
    }

    if (state.multipleActionsRequestType === MultipleActionsRequestTypeEnum.Public) {
      await changeAttachmentStatusAction(selectedAttachments, true);
    }

    if (state.multipleActionsRequestType === MultipleActionsRequestTypeEnum.Download) {
      console.log('Download');
    }

    await getAttachmentsAction(state.invoiceId);

    setLoading(false);
  };

  const handleCheckboxSelect = (selected: boolean, id: string) => {
    if (selected) setSelectedAttachments(prev => [...prev, id]);
    else setSelectedAttachments(prev => prev.filter(attachmentId => attachmentId !== id));
    setAllSelectedAttachments(false);
  };

  useEffect(() => {
    if (selectedAttachmentsLength !== 0 && attachmentsLength === selectedAttachmentsLength) {
      setAllSelectedAttachments(true);
    } else {
      setAllSelectedAttachments(false);
    }
  }, [selectedAttachments, attachmentIds]);

  return (
    <>
      <DeleteAttachmentModal clearSelection={clearSelection} state={state}/>
      <DownloadAttachmentModal selectedAttachments={selectedAttachments} state={state}/>
      {isMultipleActionsBarVisible && (
        <FilehubMultipleActionsBar
          clearSelection={clearSelection}
          selectedAttachments={selectedAttachments}
          state={state}
        />
      )}
      <FilehubTableMenu state={state}/>
      <Table header={filehubTableHeaders} tableRowHeight={TableRowHeight.MD}>
        {attachments.map((row: Attachment) => {
          const isPublic = row.IsPublic;

          return (
            <TableRow>
              <span>
                <Checkbox
                  checked={selectedAttachments.includes(row.Id)}
                  size={CheckboxSize.SM}
                  onChange={selected => handleCheckboxSelect(selected, row.Id)}
                  value={`filehubTableCheckbox-${row.Id}`}
                />
              </span>
              <span>{row.FileName}</span>
              <span>{DateRenderer({value: row.CreatedAt})}</span>
              <span>{row.CreatedBy}</span>
              <span>
                <Tag
                  color={isPublic ? TagColor.GREEN_LIGHT : TagColor.ORANGE_LIGHT}
                  lIcon={isPublic ? IconSvg.PUBLIC : IconSvg.VPN_LOCK}
                  size={TagSize.SM}
                  text={intlFunction(isPublic ? Tr.publicStatus : Tr.privateStatus)}
                />
              </span>
              <span>{`${convertBytesToMBs(row.Size)} MB`}</span>
              <span>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    state.setSelectedInvoiceMenuAnchorEl(e.currentTarget);
                    state.setSelectedInvoiceMenuOpened(true);
                    state.setSelectedRow(row);
                  }}
                  size="small"
                >
                  <MoreVert className="IconWithTransform"/>
                </Button>
              </span>
            </TableRow>
          );
        })}
      </Table>
      {!hasAttachments && <FilehubNoAttachments/>}
      {showFooterButtons && (
        <FilehubFooterButtons loading={loading} onClose={undoFooterButton} onSave={saveFooterButton}/>
      )}
    </>
  );
});
