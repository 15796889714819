import * as Yup from 'yup';
import {validateNip} from '@symfonia/utils';

export default Yup.object().shape({
  Identifier: Yup.string()
    .required('Pole wymagane')
    .test('is-valid-nip', 'Podaj prawidłowy NIP.', value => {
      return value ? validateNip(value.split('-').join('')) : false;
    }),
});
