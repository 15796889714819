import {NotificationDataService} from '../../services/NotificationDataService';
import {
  GetWebsocketNotificationModelQuery,
  KSeFStatus,
  MultipleSendToKSeFMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {SendInvoice, SendInvoicesDataModel} from '../../services/helpers/NotificationDataParsers';
import {action, computed, makeObservable, observable} from 'mobx';
import {SendInvoicesToKSeFJobRunner} from '../../services/KSeFJobRunners/SendInvoicesToKSeFJobRunner';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {SendingInvoicesModelMapper} from '../../modules/earchive/modules/KSeFEvents/helpers/SendingInvoicesModelMapper';

export type SendInvoicesModelToMap =
  Omit<SendInvoicesDataModel, 'invoices'>
  & { invoices: Array<Omit<SendInvoice, 'kSeFStatus'> & { kSeFStatus: number }> }


export class SendInvoicesResultService extends NotificationDataService<WebsocketNotificationType.SendingInvoices, MultipleSendToKSeFMutationVariables, unknown, SendInvoicesModelToMap> {

  @observable
  public modalIsActive: boolean = false;

  @observable
  public downloadType: WebsocketNotificationType | undefined = undefined;

  protected dataResultManager: NotificationDataResultManagerI<SendInvoicesDataModel>;

  constructor(public earchiveState: EArchiveState) {
    super(earchiveState.company.envObserver, earchiveState, () => new SendInvoicesToKSeFJobRunner(earchiveState.company.envObserver, earchiveState), SendingInvoicesModelMapper.map);
    makeObservable(this);
    this.dataResultManager = new NotificationDataResultManager<SendInvoicesDataModel>(this);
  }

  @computed
  public get currentResult(): SendInvoicesDataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get everyIsApproved(): boolean {
    return !!this.currentResult?.invoices?.every?.(({kSeFStatus}) => kSeFStatus === KSeFStatus.Approved);
  }

  @computed
  public get someIsApproved(): boolean {
    return !!this.currentResult?.invoices?.some?.(({kSeFStatus}) => kSeFStatus === KSeFStatus.Approved);
  }

  @computed
  public get returnedInvoicesCount(): number | undefined {
    return this.currentResult?.invoices?.length;
  }

  @action.bound
  public setArchivedResult(result: SendInvoicesDataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  public override checkIsReady(): boolean {
    return this.envId && this.variables.NotificationId && this.resultIsAvailable;
  }

  public checkIsPending(invoiceId: string): boolean {
    return this.activeEvents.some(event => event.data?.invoices?.some?.(invoice => invoice.invoiceId === invoiceId));
  }

  public setModalIsActive(isActive: boolean): this {
    this.modalIsActive = isActive;
    return this;
  }

  @action.bound
  public setDownloadType(downloadType: WebsocketNotificationType | undefined): this {
    this.downloadType = downloadType;
    return this;
  }

  protected override onSuccess(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel']) {
    super.onSuccess(data);
    this.dataResultManager.setFetchingResult({...this.result}, this.notification);
  }

  protected override async afterFetch(data: GetWebsocketNotificationModelQuery['GetWebsocketNotificationModel'] | null): Promise<void> {
    await this.earchiveState.packageStatistics.load();
  }
}


