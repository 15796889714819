import {observer} from 'mobx-react-lite';
import {CreateCategoryStepper} from './components/CreateCategoryStepper';
import {ICreateCategoryModalState} from './state/ICreateCategoryModalState';
import {
  ButtonPrimary,
  ButtonPrimaryWidth,
  ButtonSecondary,
  ButtonSecondaryWidth,
  Modal,
  Steps,
} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';

export interface CreateCategoryModalProps {
  state: ICreateCategoryModalState;
  onClose(): void;
  onFinished(categoryId: string): void;
}

export const CreateCategoryModal = observer(({state, onClose, onFinished}: CreateCategoryModalProps) => {
  const intl = useIntl();

  const handleClose = () => {
    state.handleClose();
    onClose();
  };

  useEffect(() => {
    if (state.isFinished) {
      onFinished(state?.newCategoryId ?? '');
    }
  }, [state.isFinished, onFinished]);

  const steps = ['Kategoria', 'Konto'];

  const isFirstStep = state.currentStepIndex === 0;

  return (
    <Modal onClose={handleClose} title={<Steps currentStep={state.currentStepIndex + 1} steps={steps}/>} show={true}>
      <div className="max-w-[400px]">
        <CreateCategoryStepper state={state}/>
      </div>
      <div className="flex gap-[16px] pt-[24px]">
        <ButtonSecondary
          disabled={!state.isBackButtonActive}
          onClick={() => state.handlePrevStep()}
          text={intl.formatMessage({id: isFirstStep ? Tr.closeLabel : Tr.backButton})}
          width={ButtonSecondaryWidth.BASE}
        />
        <ButtonPrimary
          disabled={!state.isNextButtonActive}
          onClick={() => state.handleNextStep()}
          text={intl.formatMessage({id: state.isLastStep ? Tr.add : Tr.next})}
          width={ButtonPrimaryWidth.BASE}
        />
      </div>
    </Modal>
  );
});
