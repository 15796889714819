import {TableServiceDecorator} from './TableServiceDecorator';
import {AnyObject} from 'yup/es/types';
import {BaseExtendedTableServiceI, EventClick, Matrix, Row} from './BaseTableService';
import {action, computed, makeObservable, observable, override} from 'mobx';
import {ClassNamesService} from './ExtendedTableService';
import {BasePersistService, BasePersistServiceI} from '../PersistServices/BasePersistService';
import {BaseModuleI} from '../MobXServices/BaseModule';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export interface ClickableTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModuleI {
  get focusedRow(): Row<T> | undefined;

  setFocusedRow(row: Row<T> | undefined): void;

  handleClick(focusedRow: Row<T>, e?: EventClick): void;

  rowClassName(row: Row<T>): string | undefined;

  focusedRowClassName(row: Row<T>): string | undefined;

  get matrix(): Matrix<T, C>;

  get focusedRowIndex(): number;

  checkIsFocused(row: Row<T>): boolean;

  getMatrix(matrix: Matrix<T, C>, tableService?: ClassNamesService<T>): Matrix<T, C>;
}

export type ClassName<T extends AnyObject, C extends AnyObject = AnyObject> =
  string
  | ((row: Row<T>, context?: C) => string | undefined)

export interface ClickableConfig<T extends AnyObject, C extends AnyObject = AnyObject> {
  rowClassName?: ClassName<T, C>;
  focusedRowClassName?: ClassName<T, C>;
  onClick?: (focusedRow: Row<T>, context?: C, e?: EventClick) => void;
  persistKey?: string;
}

export const DEFAULT_CONFIG = {
  rowClassName: 'hover:bg-green-50 cursor-pointer',
  focusedRowClassName: 'bg-green-100',
};

export class ClickableTableService<T extends AnyObject, C extends AnyObject = AnyObject> extends TableServiceDecorator<T, C> implements ClickableTableServiceI<T, C> {

  private storage?: BasePersistServiceI<Row<T>>;

  private readonly config!: ClickableConfig<T, C>;

  constructor(baseTable: BaseExtendedTableServiceI<T, C>, config?: Partial<ClickableConfig<T, C>>) {
    super(baseTable);
    this.config = {...DEFAULT_CONFIG, ...config};
    if (this.config?.persistKey) {
      this.storage = new BasePersistService<Row<T>>(this.config.persistKey, earchiveState.envObserver);
    }
    makeObservable(this);
  }

  @observable.ref
  private _focusedRow?: Row<T>;

  @computed
  public get focusedRow(): Row<T> | undefined {
    return this._focusedRow;
  }

  @computed
  public get focusedRowIndex(): number {
    return this.focusedRow ? this.baseTableService.rows.indexOf(this.focusedRow) : -1;
  }

  @override
  public get matrix(): Matrix<T, C> {
    return this.getMatrix(this.baseTableService.matrix, this);
  }

  public rowClassName(row: Row<T>): string | undefined {
    return typeof this.config.rowClassName === 'function' ? this.config.rowClassName(row, this.context) : this.config.rowClassName;
  }

  public focusedRowClassName(row: Row<T>): string | undefined {
    return typeof this.config.focusedRowClassName === 'function' ? this.config.focusedRowClassName(row, this.context) : this.config.focusedRowClassName;
  }

  @action.bound
  public setFocusedRow(row: Row<T> | undefined, persistEnabled: boolean = true): void {
    this._focusedRow = row;
    persistEnabled && this._focusedRow && this.storage?.save?.(() => this._focusedRow as Row<T>);
  }

  public checkIsFocused(row: Row<T>): boolean {
    return this.focusedRow?.key === row.key;
  }

  public handleClick(focusedRow: Row<T>, e?: EventClick): void {
    this.setFocusedRow(focusedRow);
    this.config.onClick?.(focusedRow, this.baseTableService.context, e);
  }

  public getMatrix(matrix: Matrix<T, C>, tableService?: ClassNamesService<T>): Matrix<T, C> {

    return matrix.map(item => {
      const cells = this.baseTableService.createTableRow(item.row, this.baseTableService.columns, {tableService: tableService ?? this});
      return ({
        ...item,
        cells,
        onClick: (row, event, e) => this.handleClick(row, e),
      });
    });
  }

  protected override _onMount(): void {
    this.storage?.subscribe?.(data => this.setFocusedRow(data, false), {load: true, clear: true})?.onMount?.();
  }

  protected override _onUnmount(): void {
    this.storage?.onUnmount?.();
  }
}
