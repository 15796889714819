import React, {FC, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {IVatRegistryChangeModalState} from './state/IVatRegistryChangeModalState';
import {VatRegistrySelect} from './components/VatRegistrySelect';
import {toJS} from 'mobx';
import {MultiSelectModel} from '../../../../common/components/MultiSelect/MultiSelect';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';

export interface VatRegistryChangeModalProps {
  state: IVatRegistryChangeModalState;

  onClose(): void;

  onFinished(selectedVatRegistryId: string): void;
}

export const VatRegistryChangeModal: FC<VatRegistryChangeModalProps> = observer(({state, onClose, onFinished}) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [list, setList] = useState<MultiSelectModel[]>(state.vatRegistries);
  const handleClose = () => {
    state.handleClose();
    onClose();
  };

  const handleSearch = (event: string) => {
    setSearch(event.toLowerCase());
    if (search === '' || !search) {
      setList(state.vatRegistries);
    } else {
      setList(state.vatRegistries.filter(v => v.value.toLowerCase().includes(search)));
    }
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    const {current: descriptionElement} = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (<Modal
        onClose={handleClose}
        title={intl.formatMessage({id: Tr.chooseVatRegistries})}
        show={true}
      >
        <VatRegistrySelect
          handleSearch={handleSearch}
          search={search}
          vatRegistries={toJS(list)}
          selectedId={state.selectedVatRegistryId}
          onSelect={(event, value) => state.onSelect(event, value)}/>
        <div className="flex gap-[16px] pt-[24px]">
          <ButtonSecondary
            onClick={() => handleClose()}
            text={intl.formatMessage({id: Tr.closeLabel})}
            width={ButtonSecondaryWidth.BASE}
          />
          <ButtonPrimary
            disabled={!state.selectedVatRegistryId}
            onClick={() => onFinished(state.selectedVatRegistryId)}
            text={intl.formatMessage({id: Tr.use})}
            width={ButtonPrimaryWidth.BASE}
          />
        </div>
      </Modal>
  );
});
