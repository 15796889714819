// Not contains polish diacritic signs, starts with at least one letter, contains only alphanumeric signs
const licencePlateNumberRegex = /^[a-zA-Z]+[a-zA-Z0-9]*$/;

export function isCorrectLicencePlateNumber(value: string) {
  return licencePlateNumberRegex.test(value);
}





