import {IModuleStatus} from "../../../../../state/ModuleSettings/IModuleStatus";

export interface NotInitializedProps extends React.PropsWithChildren {
  moduleStatus?: IModuleStatus;
}

export const NotInitialized = ({moduleStatus, children}: NotInitializedProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {moduleStatus && moduleStatus.isModulePurchased && !moduleStatus.isModuleInitialized && children }
    </>
  );
};