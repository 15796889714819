export enum CategoryTypesFilterOptions {
  All = 'all',
  Default = 'default',
  Custom = 'custom',
}

export enum CategoriesAccountsFiltersKeys {
  AccountKUPIds = 'AccountKUPIds',
  AccountNKUPIds = 'AccountNKUPIds',
  Id = 'Id',
  Type = 'Type',
  InvoiceTypeGroup = 'InvoiceTypeGroup'
}

export enum CategoryRulesFiltersKeys {
  PositionName = 'PositionName',
  CategoryNames = 'CategoryNames',
  InvoiceTypeGroup = 'InvoiceTypeGroup',
  IsActive = 'IsActive',
  ModifiedAt = 'ModifiedAt',
  UserIds = 'UserIds',
}

export enum VehicleFiltersKeys {
  RegistrationNumber = 'RegistrationNumber',
  VehicleUsageType = 'VehicleUsageType',
  Description = 'Description',
}

export enum VehiclesUseTypes {
  Company = 'COMPANY',
  Mixed = 'MIXED',
  Private = 'PRIVATE',
}

export enum PostedDecreeResponseFilterKeys {
  ContractorId = 'ContractorId',
  GrossValue = 'GrossValue',
  Nip = 'Contractor.Identifier',
  InvoiceTypeGroup = 'InvoiceTypeGroup',
  CreatedAt = 'CreatedAt',
  PostingStatus = 'PostingStatus',
  IsAccepted = 'IsAccepted'
}
