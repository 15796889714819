import {
  LicensePlateExistsDocument,
  LicensePlateExistsQuery,
  LicensePlateExistsQueryVariables,
  LicensePlateExistsResponse,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const CheckIfLicensePlateExists = async (registrationNumber: string, companyId: string | undefined): Promise<LicensePlateExistsResponse> => {
  return await apolloClient
    .query<LicensePlateExistsQuery, LicensePlateExistsQueryVariables>({
      query: LicensePlateExistsDocument,
      context: {
        envId: companyId,
      },
      variables: {
        CompanyId: companyId,
        RegistrationNumber: registrationNumber,
      },
    })
    .then(response => {
      if (response.errors) {
        throw new Error(response.errors.toString());
      }
      return response.data.LicensePlateExists as LicensePlateExistsResponse;
    });
};
