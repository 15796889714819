import {AnyObject} from 'yup/es/types';
import {isObject} from 'lodash';

const processArray = <T>(source: T[], process: (elem: T) => unknown): unknown[] => {
  const arr = [];
  for (const elem of source) {
    if (Array.isArray(elem)) {
      arr.push(processArray(elem, process));
      continue;
    }
    if (isObject(elem)) {
      arr.push(process(elem));
      continue;
    }
    arr.push(elem);
  }
  return arr;
};

export const transformKeys = <T>(
  source: AnyObject,
  transformer: (key: string) => string,
  checked = new WeakMap<AnyObject, AnyObject>()
): T => {
  if (checked.has(source)) {
    return checked.get(source) as T;
  }

  const target: AnyObject = {};
  checked.set(source, target);

  for (const key in source) {
    const transformedKey = transformer(key);
    const value = source[key];

    if (Array.isArray(value)) {
      target[transformedKey] = value.map((elem) => 
        isObject(elem) ? transformKeys(elem, transformer, checked) : elem
      );
    } else if (isObject(value)) {
      target[transformedKey] = transformKeys(value, transformer, checked);
    } else {
      target[transformedKey] = value;
    }
  }
  return target as T;
};
