import {observer} from 'mobx-react-lite';
import {PostingDetailsSubHeader} from './PostingDetailsSubHeader';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IPostingDetailsState} from '../../state/IPostingDetailsState';
import {ButtonTertiary, IconSvg, Toggle} from '@symfonia/brandbook';

export interface PostingDetailsHeaderProps {
  state: IPostingDetailsState;
  setShowDetails: (v: boolean) => void;
  showDetails: boolean;
}

export const PostingDetailsHeader = observer(({state, setShowDetails, showDetails}: PostingDetailsHeaderProps) => {
  const intl = useIntl();
  const showDetailsHandle = () => {
    setShowDetails(!showDetails);
  };

  const setPreviewOnOff = (e: boolean) => {
    state.setInvoicePreview(e);
  };

  return (
    <div
      className={
        'w-full border border-grey-50 border-solid ' +
        'flex items-center justify-between flex-col rounded-lg shadow-lg bg-white box-border mb-2'
      }
    >
      <div className={'w-full p-[20px] h-[56px] flex items-center justify-between'}>
        <div className={'flex items-center justify-between'}>
          <ButtonTertiary
            lIcon={showDetails ? IconSvg.EXPAND_LESS : IconSvg.EXPAND_MORE}
            rIcon={
              !state.validationVatRegisterIsNotNull && !showDetails && !state.isLoading ? IconSvg.ERROR_OUTLINE : null
            }
            onClick={showDetailsHandle}
            disabled={state.isLoading}
            className={'mr-[20px]'}
            text={intl.formatMessage({id: showDetails ? Tr.hideDetails : Tr.showDetails})}
            testId="postingDetails-showDetailsButton"
          />
          <p className={'font-quicksand text-base mb-[4px] font-medium ' + (showDetails ? 'uppercase' : '')}>
            {showDetails ? (
              <FormattedMessage
                id={Tr.invoiceWithTypeGroup}
                values={{invoiceWithTypeGroup: state.postingDetailsStore.postingDetails?.DocumentType?.Name}}
              />
            ) : (
              <FormattedMessage
                id={Tr.contractorWithName}
                values={{
                  contractorWithName: (
                    <span className={'font-bold'}>
                      {state.postingDetailsStore.postingDetails?.Contractor?.Name ?? '-'}
                    </span>
                  ),
                }}
              />
            )}
          </p>
        </div>
        <div className={'flex items-center'}>
          <p className={'font-quicksand text-base font-medium mr-[10px]'}>{intl.formatMessage({id: Tr.previewMode})}</p>
          <Toggle
            onChange={checked => setPreviewOnOff(checked)}
            checked={state.invoicePreview}
            value={''}
            testId="postingDetails-showPreviewToggle"
          />
        </div>
      </div>
      {showDetails && <PostingDetailsSubHeader state={state}/>}
    </div>
  );
});
