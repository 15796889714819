import {TypedDocumentNode} from '@apollo/client';
import {GraphQLErrorWithMessage} from '../modules/root/providers/GraphQLProvider';
import {computed, makeObservable} from 'mobx';
import {SubscriptionStateEnum, WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {addAlert, AlertConfig} from './helpers/AlertService';
import {InitializerType} from './helpers/fetchMatchedAction';
import {wsActiveEventsRepository} from '../state/rootRepository';
import {
  graphqlErrorEventAdapterManager,
} from './ErrorHandlerServices/NotificationErrorServices/ErrorEventAdapters/GraphQLErrorEventAdapterManager';
import {errorsManager} from './ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {ToastVariant} from '@symfonia/brandbook';
import {Repository, RepositoryI} from '../modules/root/services/MobXServices/Repository/Repository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {AlertType} from '@symfonia-ksef/state/EarchiveState/AlertsState';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';


export type BaseResponse<Key extends string> = Record<Key, { Result: SubscriptionStateEnum, NotificationModel: WebSocketNotificationFragment }>

export type BaseRequestVariables = Record<string, unknown>

type Alerts = { success?: AlertConfig | ((status: SubscriptionStateEnum) => AlertConfig), error?: AlertConfig | ((errors: readonly GraphQLErrorWithMessage[], error: string | null) => AlertConfig) }


//Służy do uruchamiania i obsługi jobów dla procesów subskrypcyjnych na backendzie - wykonuje zapytanie inicjujące joba
//Działa na takiej zasadzie jak BaseRepositoryService
//Zawiera informację o trwających procesach danego typu
//Umozliwia konfigurację alertów sukcesu i errora zapytania
//Synchronizuje listę aktywnych procesów
//NIE ZAWIERA OBSŁUGI ZAKOŃCZENIA JOBA! Odpowiada jedynie za jego wystartowanie
//Joba jest procesem websocketowym, obsługą jego zakończenia zajmuje się SubscriptionEventsService przy pomocy BaseEventConsumerI
export interface EventJobRunnerI<T extends BaseRequestVariables, K extends string = string, R extends BaseResponse<K> = BaseResponse<K>> extends RepositoryI<K, R, T> {

  //typ procesu
  readonly type: WebsocketNotificationType;

  //status procesu
  get status(): SubscriptionStateEnum | null;

  //Mówi o tym czy chociaż jeden proces danego typu, dla bierzącego EnvironmentId jest aktywny
  get isPending(): boolean;

  // zwraca timestamp ostatniej aktualizacji listy trwających procesów
  get activeEventsUpdateTimestamp(): number | undefined;

  // zwraca listę trwających procesów dla danego typu i aktualnego EnvironmentId
  get activeEvents(): WebSocketNotificationFragment[];


  //Konfiguruje alert sukcesu i errora wyświetlane w UI w momencie gdy zapytanie uruchamiące joba się zakończy
  configureAlerts(alerts: Alerts): this;
}

export abstract class EventJobRunner<T extends BaseRequestVariables, K extends string = string, R extends BaseResponse<K> = BaseResponse<K>> extends Repository<K, R, T> implements EventJobRunnerI<T> {

  private readonly defaultAlertsConfig: {
    error: AlertConfig,
    success: AlertConfig
  } = {
    error: {color: ToastVariant.ERROR, duration: 8000, type: AlertType.WsNotification},
    success: {color: ToastVariant.INFO, duration: 8000, type: AlertType.WsNotification},
  };

  private alerts: Alerts = {};

  protected constructor(key: K, public readonly type: WebsocketNotificationType, graphqlDocument: TypedDocumentNode, envObserver: EnvObserverI, earchiveState: EArchiveState, initializerType: InitializerType = InitializerType.Query) {
    super(key, graphqlDocument, envObserver, earchiveState, initializerType);
    makeObservable(this);
  }

  @computed
  public get status(): SubscriptionStateEnum | null {
    return this.data?.Result ?? null;
  }

  @computed
  public get isPending(): boolean {
    return wsActiveEventsRepository.pendingEvents.some((notification) => notification?.Type === this.type && this.envObserver.currentEnv.companyId === notification.CompanyId);
  }

  @computed
  public get activeEvents(): WebSocketNotificationFragment[] {
    return wsActiveEventsRepository.pendingEvents.filter(event => event.Type === this.type && this.envId === event.CompanyId);
  }

  @computed
  public get activeEventsUpdateTimestamp(): number | undefined {
    return wsActiveEventsRepository.updateTimestamp;
  }

  public configureAlerts(alerts: Alerts): this {
    this.alerts = alerts;
    return this;
  }

  public override checkIsReady(): boolean {
    return !!this.variables && !Object.values(this.variables).some(value => value === undefined);
  }

  protected override onSuccess(data: R[K]): void {
    data.NotificationModel && wsActiveEventsRepository.addEvent(data.NotificationModel);
    this.alerts.success && ([SubscriptionStateEnum.Enqueued, SubscriptionStateEnum.Processing].includes(data.Result)) && addAlert({...this.defaultAlertsConfig.success, ...typeof this.alerts.success === 'function' ? this.alerts.success(data.Result) : this.alerts.success});
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[]): void {
    const {alertConfig} = errorsManager.get(graphqlErrorEventAdapterManager.createAdapter(errors[0]), {...this.defaultAlertsConfig.error, ...typeof this.alerts.error === 'function' ? this.alerts.error(errors, this.mapResponseErrors(errors)) : this.alerts.error});
    addAlert(alertConfig);
  }

  protected override mapResponseErrors(errors?: readonly GraphQLErrorWithMessage[]): string | null {
    const [error] = errors ?? [];
    return error?.extensions?.message ?? error?.extensions?.data?.empty ?? error?.extensions?.data?.Identifier?.[0] ?? null;
  }
}

