import {FinancialYearResponse, useGetFinancialYearsQuery} from '@symfonia-ksef/graphql';
import {GlobalLoader} from '../../../../../../common';
import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {l} from '../../../../../../logger/loggerInstance';
import {usePostingConfigurationsMethods} from '../../hooks/usePostingConfigurationsMethods';
import {useSettlementsMethods} from './Settlements/useSettlementsMethods';
import {IPostingConfigurationState} from './IPostingConfigurationState';
import {observer} from 'mobx-react-lite';

export interface FinancialYearsInitializationProps {
  state: IPostingConfigurationState
}

export const FinancialYearsInitialization: FC<PropsWithChildren<FinancialYearsInitializationProps>> = observer(({children, state}) => {
  const [skip, setSkip] = useState(false);
  const {companyId} = useParams();
  const {getSettlementAccounts} = useSettlementsMethods(state.settlementsState);
  const {getAccounts} = usePostingConfigurationsMethods(state);

  useEffect(() => {
    setSkip(false);
  }, [state.forceRefreshFinancialYear]);

  const {loading, error, data} = useGetFinancialYearsQuery({
    context: {envId: companyId},
    variables: {CompanyId: companyId},
    skip,
  });

  const findNewestActiveFinancialYear = (years: FinancialYearResponse[]) => {
    return years.sort((a,b) => Date.parse(b.BeginDate) - Date.parse(a.BeginDate)).filter(e => e.IsActive);
  };

  useEffect(() => {
    if (!loading && data && data.GetFinancialYears && companyId) {
      state.setAvailableFinancialYears(data.GetFinancialYears.FinancialYears);
      if (data.GetFinancialYears.FinancialYears.length > 0) {
        const newestFinancialYear = findNewestActiveFinancialYear(data.GetFinancialYears.FinancialYears)[0].Id;
        state.loadSelectedFinancialYearFromStorage(newestFinancialYear);
        const financialYear = state.documentTypesState.postingConfigurationState.selectedFinancialYear;
        getSettlementAccounts(financialYear ?? newestFinancialYear);
        getAccounts(financialYear ?? newestFinancialYear);
      }
      setSkip(true);
      state.setForceRefreshFinancialYear(false);
    }
  }, [companyId, data, loading, state.setAvailableFinancialYears, state.forceRefreshFinancialYear]);

  if (loading || !skip) {
    return <GlobalLoader/>;
  }

  if (error) {
    l.error('Financial Years not found', error);
  }

  return <>{children}</>;
});
