import clsx from 'clsx';
import {Icon, IconColor, IconSize, IconState, IconSvg, IconVariant} from '../../../components/Icon/Icon';

export type renderCalendarNavBarFn = {
  (_: {
    onArrowLeft?: () => void;
    onDoubleArrowLeft?: () => void;
    onArrowRight?: () => void;
    onDoubleArrowRight?: () => void;
    onLabel?: (key: string) => void;
    labels: {key: string; text: string}[];
    disableArrowLeft?: boolean;
    disableDoubleArrowLeft?: boolean;
    disableArrowRight?: boolean;
    disableDoubleArrowRight?: boolean;
    iconSize?: IconSize;
    iconColor?: IconColor;
    className?: string;
  }): void;
};

export const renderCalendarNavBar: renderCalendarNavBarFn = ({
  onLabel = () => undefined,
  labels,
  onArrowLeft,
  onArrowRight,
  onDoubleArrowLeft,
  onDoubleArrowRight,
  disableDoubleArrowLeft = false,
  disableDoubleArrowRight = false,
  disableArrowLeft = false,
  disableArrowRight = false,
  iconColor = IconColor.PRIMARY_500,
  iconSize = IconSize.SM,
  className = 'h-[40px]',
}) => {
  const arrowButton = clsx(iconSize, 'p-0 m-0 flex items-center justify-center border-none cursor-pointer relative');

  return (
    <div className={clsx(className, 'flex items-center border-b-grey-100 border-b-solid border-b shrink-0 relative')}>
      <div className="absolute flex left-[4px]">
        {onDoubleArrowLeft && (
          <button
            disabled={disableDoubleArrowLeft}
            type="button"
            className={clsx(arrowButton, 'ml-[4px]')}
            onClick={onDoubleArrowLeft}
          >
            <Icon
              className="absolute top-0"
              svg={IconSvg.ARROW_LEFT_DOUBLE}
              size={iconSize}
              appearance={{
                [IconState.DEFAULT]: {
                  color: disableDoubleArrowLeft ? IconColor.GREY_300 : iconColor,
                  variant: IconVariant.FILLED,
                },
              }}
            />
          </button>
        )}

        {onArrowLeft && (
          <button
            disabled={disableArrowLeft}
            type="button"
            className={clsx(arrowButton, 'ml-[4px]')}
            onClick={onArrowLeft}
          >
            <Icon
              className="absolute top-0"
              size={iconSize}
              svg={IconSvg.KEYBOARD_ARROW_LEFT}
              appearance={{
                [IconState.DEFAULT]: {
                  color: disableArrowLeft ? IconColor.GREY_300 : iconColor,
                  variant: IconVariant.FILLED,
                },
              }}
            />
          </button>
        )}
      </div>

      {labels.map(label => (
        <span key={label.key} className="flex justify-center items-center grow shrink border-none font-bold text-base">
          <button className="cursor-pointer" type="button" onClick={() => onLabel(label.key)}>
            {label.text}
          </button>
        </span>
      ))}

      <div className="absolute flex right-[4px]">
        {onArrowRight && (
          <button
            disabled={disableArrowRight}
            type="button"
            className={clsx(arrowButton, 'mr-[4px]')}
            onClick={onArrowRight}
          >
            <Icon
              className="absolute top-0"
              size={iconSize}
              svg={IconSvg.KEYBOARD_ARROW_RIGHT}
              appearance={{
                [IconState.DEFAULT]: {
                  color: disableArrowRight ? IconColor.GREY_300 : iconColor,
                  variant: IconVariant.FILLED,
                },
              }}
            />
          </button>
        )}
        {onDoubleArrowRight && (
          <button
            disabled={disableDoubleArrowRight}
            type="button"
            className={clsx(arrowButton, 'mr-[4px]')}
            onClick={onDoubleArrowRight}
          >
            <Icon
              className="absolute top-0"
              size={iconSize}
              svg={IconSvg.ARROW_RIGHT_DOUBLE}
              appearance={{
                [IconState.DEFAULT]: {
                  color: disableDoubleArrowRight ? IconColor.GREY_300 : iconColor,
                  variant: IconVariant.FILLED,
                },
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};
