import {
  useSetSupplierTypeMutation,
} from '@symfonia-ksef/graphql';
import {FormattedMessage, useIntl} from 'react-intl';
import {useCallback, useState} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {SupplierTypeEnum} from '../../common/enums/SupplierType';
import {ContractorSwitchTypeModalProps} from '../modules/Contractors/ContractorsTable/ContractorsSwitchTypeModal';

export const useContractorsTableMethods = () => {
  const [setSupplierType] = useSetSupplierTypeMutation({context: {envId: earchiveState.company.companyId}});
  const [groupSwitchSupplierTypeModalProps, setGroupSwitchSupplierTypeModalProps] = useState<ContractorSwitchTypeModalProps>();

	const updateSupplierType = useCallback(async (identifiers: string[], supplierType: number, refreshData?: () => void) => {
		const isMultiple = identifiers.length > 1;
		const elementsToModify = identifiers.map(el => ({
			Identifier: el,
			SupplierType: supplierType,
		}));
    await setSupplierType({
        variables: {
        SupplierTypeItems: elementsToModify,
			},
    });
		refreshData?.();
    if (supplierType === SupplierTypeEnum.Undefined) {
        earchiveState.alertsState.addAlert(<FormattedMessage id={Tr.ContractorTypeSavedEmptyAlert}/>, ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
        });
    }
    else if (supplierType === SupplierTypeEnum.Trading) {
        earchiveState.alertsState.addAlert(<FormattedMessage id={isMultiple ? Tr.ContractorMultipleTypeSavedTradingAlert : Tr.ContractorTypeSavedTradingAlert}/>, ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
        });
    }
    else if (supplierType === SupplierTypeEnum.Cost) {
        earchiveState.alertsState.addAlert(<FormattedMessage id={isMultiple ? Tr.ContractorMultipleTypeSavedCostAlert : Tr.ContractorTypeSavedCostAlert}/>, ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
        });
    }
    else {
        earchiveState.alertsState.addAlert(<FormattedMessage id={isMultiple ? Tr.ContractorMultipleTypeSavedTradingAndCostAlert : Tr.ContractorTypeSavedTradingAndCostAlert}/>, ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
        });
    }
  }, [setSupplierType]);

  return {
    updateSupplierType,
		groupSwitchSupplierTypeModalProps,
		setGroupSwitchSupplierTypeModalProps
  };
};

