import {createTheme} from '@mui/material';
import {common, green, red} from '@mui/material/colors';
// noinspection ES6UnusedImports
import {} from '@mui/lab/themeAugmentation';
import {gridClasses} from '@mui/x-data-grid/constants/gridClasses';

declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    footer: true;
  }
}

export default createTheme({
  typography: {
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      //'"Segoe UI"',
      'Roboto',
      // 'Roboto',x11
      // '"Helvetica Neue"',
      // 'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      //'"Segoe UI Emoji"',
      //'"Segoe UI Symbol"',
    ].join(','),
    fontSize: 12,
    body1: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      main: '#009c10',
      dark: '#009c10',
      light: '#009c10',
    },
    secondary: {
      main: '#4D4F53',
      dark: '#4D4F53',
      light: '#4D4F53',
    },
    error: {
      main: red[500],
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: 12,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          padding: 0,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#d8fddc',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: 'transparent',
          'circle': {
            stroke: '#d8fddc',
            r: '11',
          },
          height: '38px',
          width: '38px',
          p: 0,
          '&.Mui-completed': {
            //color: 'transparent',
            'circle': {
              stroke: '#d8fddc',
              r: '11',
            },
            height: '38px',
            width: '38px',
          },
          '&.Mui-active': {
            // color: 'transparent',
            'circle': {
              fill: '#d8fddc',
              stroke: '#d8fddc',
              r: '11',
            },
            height: '38px',
            width: '38px',
          },
        },
        text: {
          fill: 'black',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: '#009c10',
          position: 'relative',
          paddingRight: 5,
          left: 0,
        },
        root: {
          '&.MuiLoadingButton-loading--white': {
            color: 'white',
          },
          '&.MuiLoadingButton-loading--grey': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
          '&.MuiLoadingButton-indicator--no-padding': {
            '.MuiLoadingButton-loadingIndicator': {
              paddingRight: 0,
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '48px',
          minHeight: '48px',
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
      variants: [
        {
          props: {variant: 'footer'},
          style: {
            height: '32px',
            minHeight: '32px',
            '@media (min-width: 600px)': {
              minHeight: '32px',
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          marginRight: '0.5rem',
          borderRadius: '5px',
          // minWidth: '7rem',
          // padding: '7px 21px'
        },
      },
      variants: [
        {
          props: {variant: 'appBar'},
          style: {
            textTransform: 'none',
            color: common.white,
          },
        },
        {
          props: {variant: 'text'},
          style: {
            minWidth: '2rem',
          },
        },
        {
          props: {variant: 'outlined'},
          style: {
            minWidth: '7rem',
          },
        },

      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#009c10',
            '& .MuiSvgIcon-root': {
              fill: '#009c10',
              },
              '& .IconRedOnHover': {
                fill: 'red',
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&:hover': {
            fill: '#009c10',
          },
          '&.IconWithTransform': {
            boxShadow: 'none',
            transitionDuration: '0.2',
            transitionProperty: 'all',
            transitionTimingFunction: 'ease-in-out',
            '&:hover': {
              transform: 'rotate(-90deg)',
            },
          },
          '&.IconRedOnHover': {
            fill: 'red',
            '&:hover': {
              fill: 'red',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            background: '#ffffff',
            color: green['900'],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            minHeight: 56
          }
        },
        indicator: {
          indicatorColor: green['400'],
          height: 4,
          backgroundColor: '#009C10',
          borderRadius: '5px',
        },
        flexContainer: {},
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#4D4F53',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          [`& .${gridClasses.row}.even`]: {
            backgroundColor: '#f5f5f5',
            '&:hover, &.Mui-hovered': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
          [`& .${gridClasses.row}.odd`]: {
            '&:hover, &.Mui-hovered': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '60px !important',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
      variants: [
        {
          props: {variant: 'elevation'},
          style: {
            width: 'auto',
          },
        },
      ],
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: '#009C10',
          '& .MuiSvgIcon-root': {
            fill: '#009C10',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '1rem',
          padding: 0,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontSize: '0.575rem',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '.74rem !important',
        },
      },
    },

  },
  shape: {
    borderRadius: 5,
  },
});
