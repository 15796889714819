import {authorizationInKSeFWithTokenService} from '../../state/rootRepository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';

export type ExternalException =
  { exception?: { exceptionDetailList?: ExceptionDetails[] } }
  | undefined

export type ExceptionDetails = { exceptionDescription?: string, exceptionCode?: number }
type DetectedErrorTypes = { error?: boolean, externalException?: boolean }

export interface IExternalExceptionService {
  handle(exception?: string | null, errorData?: string | null): DetectedErrorTypes;

  parseException(jsonString: string): ExceptionDetails | undefined;

  matchJsonString(exception?: string | null): string | undefined;
}

export interface WithExternalException {
  error: string | null;
  externalException: ExceptionDetails | null;
  externalExceptionMessage?: string;
  subscribed: boolean;

  setExternalExtension(extension: ExceptionDetails | null): void;

  setError(error: string | null): void;

}

export class ExternalExceptionService implements IExternalExceptionService {
  constructor(private readonly envObserver: EnvObserverI, private parent?: WithExternalException, private fallbackErrorMessage?: string) {
  }

  public handle(exception?: string | null, errorData?: string | null): DetectedErrorTypes {
    const jsonString = this.matchJsonString(exception, errorData);
    if (!jsonString) {
      return this.handleError(exception);
    }
    return this.handleExternalException(jsonString);
  }

  public parseException(jsonString?: string): ExceptionDetails | undefined {
    //próba sparsowania jsona
    if (!jsonString) {
      return;
    }
    try {
      return JSON.parse(jsonString);
    } catch (err) {
      return;
    }
  }

  public matchJsonString(exception?: string | null, errorData?: string | null): string | undefined {
    //próba znalezienia jsona w zawartości exception (może być sparsowanym response lub stringiem z komunikatem)
    if (errorData) {
      const exceptionMatch = errorData?.match?.(/{.*}/)?.[0].replace(/^\s+|\s+$|\s+(?=\s)/g, '');
      //check if there is nested object recursively
      if (exceptionMatch?.match?.(/{.*}/)) {
        const newObject = JSON.parse(exceptionMatch) as Record<string, string | null | undefined>;
        this.matchJsonString(exception, Object.values(newObject)[0]);
      }
      if (!exceptionMatch) return;
      const finalObject = JSON.parse(exceptionMatch);
      if (finalObject && finalObject.exception && finalObject.exception.exceptionDetailList[0].exceptionCode === 21303) {
        this.envObserver.currentEnv.companyId && authorizationInKSeFWithTokenService.removeAuthorizationRunner.fetch();
        return;
      }
    }

    return exception?.match?.(/{.*}/)?.[0];
  }

  protected handleError(exception?: string | null): DetectedErrorTypes {
    this.parent?.setError(exception ?? null);
    return {error: !!exception};
  }

  protected handleExternalException(jsonString: string): DetectedErrorTypes {
    const exception = this.parseException(jsonString);
    if (!exception) {
      this.parent?.setError(this.fallbackErrorMessage ?? null);
      return {externalException: false};
    }
    this.parent?.setExternalExtension(exception ?? null);
    return {externalException: !!exception};
  }
}



