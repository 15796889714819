import {useCallback, useState} from 'react';
import {WhiteListModelFragment} from '@symfonia-ksef/graphql';
import {useContractorsTableMethods} from './useContractorsTableMethods';

export interface ContractorModalProps {
  modalIsActive: boolean;
  contractorNip: string;
  contractorName: string;
  whiteListStatus?: WhiteListModelFragment;
  supplierType?: number;
  invoiceId?: string;
}

export const useInvoicesTableColumnsConfig = () => {
  const {
		updateSupplierType,
	} = useContractorsTableMethods();
  
  const [contractorModalProps, setContractorModalProps] = useState<ContractorModalProps>();
  const closeContractorModal = useCallback(() => setContractorModalProps(current => {
    if (!current) {
      return current;
    }
    return {...current, modalIsActive: false};
  }), []);

  const confirmContractorModal = useCallback(async (supplierType: number, afterUpdateAction?: () => void) => setContractorModalProps(current => {
    if (!current) {
      return current;
    }
    updateSupplierType([current.contractorNip], supplierType).then(res => afterUpdateAction?.());
    return {...current, modalIsActive: false};
  }), []);

  return {closeContractorModal, confirmContractorModal, contractorModalProps, setContractorModalProps};
};
