import {useIntl} from 'react-intl';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {CategoriesAccountsFiltersKeys} from '../models/CategoryTypesFilterOptions';
// import {MultiSelectFilter} from '../../../../../../../../../common/filters/MultiSelectFilter';
import {Tr} from '../../../../../../../../../../locales/translationKeys';
import {ICategoryAccountState} from '../state/ICategoryAccountState';
import {Accordion, AccordionSize, MultiSelectFilter} from '@symfonia/symfonia-ksef-components';
import {
  convertToDropdownListOption,
  convertToMapDropdownListOption,
} from '../../../../../../../../../common/helpers/baseFilterHelpers';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';

export interface CategoryAccountsFiltersConfigProps {
  state: ICategoryAccountState;
}

export const CategoryAccountsFiltersConfig: FC<CategoryAccountsFiltersConfigProps> = observer(({state}) => {
  const intl = useIntl();

  const filterState = state.filterState;

  if (!filterState) {
    return <></>;
  }

  const categoryIdsFilter = filterState.activeFilters.get(CategoriesAccountsFiltersKeys.Id);
  const accountsKUPIdsFilter = filterState.activeFilters.get(CategoriesAccountsFiltersKeys.AccountKUPIds);
  const accountsNKUPIdsFilter = filterState.activeFilters.get(CategoriesAccountsFiltersKeys.AccountNKUPIds);

  const accountsKUPDropdownOptions = convertToMapDropdownListOption(state.filterState.accountsKUP, el => ({
    label: el.value,
    value: el.key,
  }));

  const accountsNKUPDropdownOptions = convertToMapDropdownListOption(state.filterState.accountsNKUP, el => ({
    label: el.value,
    value: el.key,
  }));

  const categoriesDropdownOptions = convertToMapDropdownListOption(state.filterState.categories, el => ({
    label: el.value,
    value: el.key,
  }));

  return (
    <>
      <Accordion
        group={'categoryName'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.categoryName}) +
          (categoryIdsFilter?.values !== undefined && categoryIdsFilter?.values?.length !== 0
            ? ' (' + categoryIdsFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={categoriesDropdownOptions}
          disabled={!categoriesDropdownOptions.size}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoriesAccountsFiltersKeys.Id)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoriesAccountsFiltersKeys.Id)
          }
        />
      </Accordion>
      <Accordion
        group={'accountKUP'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.accountKUP}) +
          (accountsKUPIdsFilter?.values !== undefined && accountsKUPIdsFilter?.values?.length !== 0
            ? ' (' + accountsKUPIdsFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={accountsKUPDropdownOptions}
          disabled={!accountsKUPDropdownOptions.size}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoriesAccountsFiltersKeys.AccountKUPIds)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoriesAccountsFiltersKeys.AccountKUPIds)
          }
        />
      </Accordion>
      <Accordion
        group={'accountNKUP'}
        size={AccordionSize.SM}
        title={
          intl.formatMessage({id: Tr.accountNKUP}) +
          (accountsNKUPIdsFilter?.values !== undefined && accountsNKUPIdsFilter?.values?.length !== 0
            ? ' (' + accountsNKUPIdsFilter?.values.length + ')'
            : '')
        }
      >
        <MultiSelectFilter
          options={accountsNKUPDropdownOptions}
          disabled={!accountsNKUPDropdownOptions.size}
          items={convertToDropdownListOption(
            state.filterState.pillsList.get(CategoriesAccountsFiltersKeys.AccountNKUPIds)?.values,
            el => ({
              label: el.value,
              value: el.key,
            }),
          )}
          onSelected={(selected: (DropdownListOption<any> | undefined)[]) =>
            state.filterState.handleSelectWithTypeConversion(selected, CategoriesAccountsFiltersKeys.AccountNKUPIds)
          }
        />
      </Accordion>
    </>
  );
});
