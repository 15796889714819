import {addAlert, AlertConfig} from './helpers/AlertService';
import {envObserver, wsEventsRepository} from '@symfonia-ksef/state/rootRepository';
import {Tr} from '../locales/translationKeys';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {AppRoutes} from '../modules/root/Routes';
import {intl} from '../modules/root/IntlProvider';
import {ToastVariant} from '@symfonia/brandbook';
import {AlertType} from '@symfonia-ksef/state/EarchiveState/AlertsState';

//Zarządza wyświetlaniem listy alertów. Kolejkuje przychodzące alrty w celu ich batchowania i uniknięcia spamu w UI
export class NotificationsAlertQueue {
  private notificationAlerts: (AlertConfig & { notificationId: string })[] = [];
  private timeoutId?: ReturnType<typeof setTimeout>;

  constructor(private readonly batchedNotificationsAlert: AlertConfig | ((length: number) => AlertConfig), private readonly targetInterval: number = 1000, private readonly alertsLimit: number = 3) {
  }

  public addAlert(newAlert: AlertConfig & { notificationId: string }) {
    this.notificationAlerts.push({...newAlert, type: AlertType.WsNotification, closeOnClick: true});
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      if (this.notificationAlerts.length > this.alertsLimit) {
        addAlert({
          ...(typeof this.batchedNotificationsAlert === 'function' ? this.batchedNotificationsAlert(this.notificationAlerts.length) : this.batchedNotificationsAlert),
          type: AlertType.WsNotification,
        });
        this.notificationAlerts.splice(0, this.notificationAlerts.length);
        return;
      }

      // eslint-disable-next-line space-in-parens
      while ( this.notificationAlerts.length ) {
        const alert = this.notificationAlerts.pop();
        if (alert) {
          const alertId = addAlert(alert);
          alertId && wsEventsRepository.bindToAlert({alertId, notificationId: alert.notificationId});
        }
      }
    }, this.targetInterval);
  }
}

export const notificationsManager = new NotificationsAlertQueue((length) => {
  return ({
    id: Tr.unreadMessages,
    values: {
      count: length,
      link: React.createElement(NavLink, {
        to: AppRoutes.eArchive.address + '/' + envObserver.currentEnv.tenantId + '/company/' + envObserver.currentEnv.companyId + '/wsevents',
        children: intl.formatMessage({id: Tr.wsNotificationsHistory}),
      }),
    },
    color: ToastVariant.INFO,
    duration: 8000,
  });
});
