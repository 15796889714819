import {Columns} from "../../../../../../../../root/services/TableServices/BaseTableService";
import {SettlementsRow} from "../State/Settlements.repository";
import {ISettlementsState} from "../State/ISettlementsState";
import {intl} from "../../../../../../../../root/IntlProvider";
import {cutTextLength} from "../../../../../../../../common/helpers/tableInputTextCutLenght";
import {Tr} from "@symfonia-ksef/locales/keys";
import {SettlementAccountInput} from "./components/SettlementAccountInput";

const requiredSign = <span className="font-bold text-black text-base">*</span>;

export const settlementsColumns: Columns<SettlementsRow, ISettlementsState> = Object.freeze({
  Name: {
    sortable: false,
    header: intl.formatMessage({id: 'categoryName'}),
    cell: (row) => {
      return <p className={'truncate whitespace-nowrap'}>{row.Name} {row.IsRequired ? requiredSign : ''}</p>;
    },
    order: 1,
    isObserver: true,
    width: 'w-[280px] truncate',
  },
  AccountName: {
    sortable: false,
    header: intl.formatMessage({id: Tr.account}),
    cell: (row, __, key, state) => {
      return <SettlementAccountInput
        accountId={row.AccountId}
        settlementId={row?.Id}
        accountName={
          (row?.AccountNumber ?? intl.formatMessage({id: Tr.postringConfiguration_no_assignment})) +
          ' ' +
          (row?.AccountName ?? '')
        }
        settlementType={row?.SettlementType}
        state={state}
        disabledDeleteRow={row.IsRequired}
      />;
    },
    order: 2,
    isObserver: true,
    width: 'w-[280px]',
  },

});

