import {Icon} from '@symfonia/brandbook';
import {IAction} from './FilehubMultipleActionsBar';

type Props = {
  actions: IAction[];
};

const FilehubDesktopMultipleActionsItems = ({actions}: Props) => {
  return (
    <div className="flex flex-inline gap-[16px] content-center		">
      {actions.map(({color, key, icon, onClick}) => (
        <div key={key}>
          <Icon className="cursor-pointer" color={color} svg={icon} onClick={onClick}/>
        </div>
      ))}
    </div>
  );
};

export default FilehubDesktopMultipleActionsItems;
