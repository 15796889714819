import {SzafirSdk, SzafirTask, SzafirSignedTask} from '../models';
import {TaskListMapper, SignedTaskListMapper} from '../mappers';

export class SzafirService {
  constructor(
    private readonly szafirSdk: SzafirSdk,
    private readonly taskListMapper: TaskListMapper = new TaskListMapper(),
    private readonly signedTaskListMapper: SignedTaskListMapper = new SignedTaskListMapper()
  ) {}

  public getExtLink(): string {
    return this.szafirSdk.getExtLink();
  }

  public getHostAppLink(): string {
    return this.szafirSdk.getAppLink();
  }

  public loadSdk(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.szafirSdk.loadSzafir(resolve, reject);
    });
  }

  public unloadSdk(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.szafirSdk.unloadSzafir(resolve, reject);
    });
  }

  public loadSettings(settingsXmlUri: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.szafirSdk.startComponentURI(settingsXmlUri, resolve, reject);
    });
  }

  public signSingleTask(task: SzafirTask): Promise<SzafirSignedTask> {
    const taskListXmlString = this.taskListMapper.mapFromModel([task]);
    return new Promise((resolve, reject) => {
      this.szafirSdk.doTaskList(taskListXmlString, (taskList: string) => {
        const tasks = this.signedTaskListMapper.mapFromString(taskList);
        resolve(tasks[0]);
      }, (error: string) => {
        reject(error);
      });
    });
  }
}
