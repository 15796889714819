import {
  ApolloCache,
  ApolloClient,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MutationFunctionOptions,
  MutationResult,
} from '@apollo/client';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {
  AddAttachmentDocument,
  Attachment,
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
  GenerateZippedInvoiceAttachmentsMutation,
  GenerateZippedInvoiceAttachmentsMutationVariables,
  UpdateAttachmentsAccessibilityMutation,
  UpdateAttachmentsAccessibilityMutationVariables,
  useDeleteAttachmentMutation,
  useGenerateZippedInvoiceAttachmentsMutation,
  useGetAttachmentLinkLazyQuery,
  useGetAttachmentsLazyQuery,
  useUpdateAttachmentsAccessibilityMutation,
} from '@symfonia-ksef/graphql';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {createUploadLink} from 'apollo-upload-client';
import {environment} from '../../../environments/environment.prod';
import {getBarerToken} from '../../root/middlewares';
import {downloadFromLink} from '../utils/utils';

export const useFilehubMethods = (
  state: IFilehubState,
  clearSelection?: () => void,
): {
  deleteAttachmentAction: (attachmentIds: string[]) => Promise<void>;
  downloadAttachmentAction: (id: string) => Promise<void>;
  downloadAttachmentsAction: (
    attachmentIds: string[],
    invoiceId: string,
    isXML: boolean,
    isPDF: boolean,
  ) => Promise<void>;
  getAttachmentsAction: (invoiceId: string) => Promise<void>;
  changeAttachmentStatusAction: (attachmentIds: string[], isPublic: boolean) => Promise<void>;
} => {
  const {
    company: {companyId},
  } = earchiveState;

  const [changeAttachmentStatus]: [
    (
      options?: MutationFunctionOptions<
        UpdateAttachmentsAccessibilityMutation,
        UpdateAttachmentsAccessibilityMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<UpdateAttachmentsAccessibilityMutation>>,
    MutationResult<UpdateAttachmentsAccessibilityMutation>,
  ] = useUpdateAttachmentsAccessibilityMutation({
    context: {envId: companyId},
  });

  const changeAttachmentStatusAction = (attachmentIds: string[], isPublic: boolean): Promise<void> => {
    return changeAttachmentStatus({
      variables: {
        Ids: attachmentIds,
        IsPublic: isPublic,
      },
    })
      .then(res => {
        clearSelection && clearSelection();
        state.setSuccessfulMultipleActionsRequest(res.data?.UpdateAttachmentsAccessibility.IsDone as boolean | null);
        state.setAreFooterButtonsShown(false);
      })
      .catch(err => {
        console.error(err);
        state.setSuccessfulMultipleActionsRequest(false);
      })
      .finally(() => {
        setTimeout(() => {
          state.setSuccessfulMultipleActionsRequest(null);
        }, 5000);
      });
  };

  const [deleteAttachment]: [
    (
      options?: MutationFunctionOptions<
        DeleteAttachmentMutation,
        DeleteAttachmentMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<DeleteAttachmentMutation>>,
    MutationResult<DeleteAttachmentMutation>,
  ] = useDeleteAttachmentMutation({context: {envId: companyId}});

  const deleteAttachmentAction = (attachmentIds: string[]): Promise<void> => {
    state.setSuccessfulDeleteRequest(null);

    return deleteAttachment({
      variables: {
        Id: attachmentIds,
      },
    })
      .then(res => {
        clearSelection && clearSelection();
        getAttachmentsAction(state.invoiceId);
        state.setAreFooterButtonsShown(false);
        state.setSuccessfulDeleteRequest(res.data?.DeleteAttachment.IsDone as boolean | null);
      })
      .catch(err => {
        console.error(err);
        state.setSuccessfulDeleteRequest(false);
      })
      .finally(() => {
        setTimeout(() => {
          state.setSuccessfulDeleteRequest(null);
        }, 5000);
      });
  };

  const [downloadAttachment] = useGetAttachmentLinkLazyQuery({context: {envId: companyId}});

  const downloadAttachmentAction = (attachmentId: string): Promise<void> => {
    return downloadAttachment({
      variables: {
        Id: attachmentId,
      },
    })
      .then(x => {
        const downloadLink = x.data?.GetAttachmentLink.Link;

        if (downloadLink) {
          state.setSuccessfulDownloadSingleAttachmentRequest(true);

          downloadFromLink(downloadLink, 'Załączniki');
        } else {
          throw new Error('No download link available');
        }
      })
      .catch(err => {
        console.error(err);

        state.setSuccessfulDownloadSingleAttachmentRequest(false);
      })
      .finally(() => {
        setTimeout(() => {
          state.setSuccessfulDownloadSingleAttachmentRequest(null);
        }, 5000);
      });
  };

  const [downloadAttachments]: [
    (
      options?: MutationFunctionOptions<
        GenerateZippedInvoiceAttachmentsMutation,
        GenerateZippedInvoiceAttachmentsMutationVariables,
        DefaultContext,
        ApolloCache<any>
      >,
    ) => Promise<FetchResult<GenerateZippedInvoiceAttachmentsMutation>>,
    MutationResult<GenerateZippedInvoiceAttachmentsMutation>,
  ] = useGenerateZippedInvoiceAttachmentsMutation({context: {envId: companyId}});

  const downloadAttachmentsAction = (
    attachmentIds: string[],
    invoiceId: string,
    isPDF: boolean,
    isXML: boolean,
  ): Promise<void> => {
    return downloadAttachments({
      variables: {
        Ids: attachmentIds,
        IncludeXML: isXML,
        IncludePDF: isPDF,
        InvoiceId: invoiceId,
      },
    })
      .then(x => {
        const downloadLink = x.data?.GenerateZippedInvoiceAttachments.Link;

        if (downloadLink) {
          state.setSuccessfulDownloadMultipleAttachmentsRequest(true);

          downloadFromLink(downloadLink, 'Załączniki');
        } else {
          throw new Error('No download link available');
        }
      })
      .catch(err => {
        console.error(err);
        state.setSuccessfulDownloadMultipleAttachmentsRequest(false);
      })
      .finally(() => {
        setTimeout(() => {
          state.setSuccessfulDownloadMultipleAttachmentsRequest(null);
        }, 5000);
      });
  };

  const [getAttachments] = useGetAttachmentsLazyQuery({context: {envId: companyId}});

  const getAttachmentsAction = (invoiceId: string): Promise<void> => {
    return getAttachments({
      variables: {
        InvoiceId: invoiceId,
      },
    })
      .then(({data}) => {
        const filteredArray = data?.GetAttachments.Attachments?.filter(
          (item): item is Attachment => item !== undefined,
        );

        data && state.setAttachments(filteredArray!);
        data && state.setAttachmentsWithoutFilters(filteredArray!);
      })
      .catch(err => console.error(err));
  };

  return {
    deleteAttachmentAction,
    downloadAttachmentsAction,
    downloadAttachmentAction,
    getAttachmentsAction,
    changeAttachmentStatusAction,
  };
};

export const addAttachments = async (
  files: any[],
  state: IFilehubState,
  setFiles: React.Dispatch<React.SetStateAction<any[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  state.setAddAttachmentsList(files);
  setIsLoading(true);

  const uploadLink = createUploadLink({
    uri: environment.apiUrl,
    headers: {
      Authorization: getBarerToken(),
      'GraphQL-preflight': '1',
      Environmentid: earchiveState.company.companyId!,
    },
  });

  const client = new ApolloClient({
    link: uploadLink,
    cache: new InMemoryCache(),
  });

  try {
    for (const file of files) {
      await client.mutate({
        mutation: AddAttachmentDocument,
        variables: {
          input: {
            File: file.file,
            IsPublic: false,
            InvoiceId: state.invoiceId,
          },
        },
      });
    }
    state.setSuccessfulAddRequest(true);
    setFiles([]);
  } catch (err) {
    console.error(err);
    state.setSuccessfulAddRequest(false);
  } finally {
    setIsLoading(false);
  }
};
