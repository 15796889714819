/* eslint-disable no-useless-escape */
import {SzafirTask} from '../models';

export class TaskListMapper {
  public mapFromModel(taskList: SzafirTask[]): string {
    return `<?xml version=\"1.0\" encoding=\"UTF-8\"?>
            <TaskList>
              ${taskList.map((task: SzafirTask) => {
                return `
                  <SigningTask>
                    <Format>XAdES-BES</Format>
                    <DataEmbedding>true</DataEmbedding>
                    <EncodeToBase64>false</EncodeToBase64>
                    <SigningTaskItem Id="${task.id}">
                      <DataToSign>
                        <Base64Binary>
                          ${task.dataToSign}
                        </Base64Binary>
                      </DataToSign>
                      <SignatureToCreate>
                        <Base64Binary></Base64Binary>
                      </SignatureToCreate>
                    </SigningTaskItem>
                  </SigningTask>
                `;
              }).join()}
            </TaskList>`;
  }
}
