import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FC} from 'react';
import {Menu} from '@mui/material';
import {AccountTypeEnum, DecreeLineTypeEnum} from '@symfonia-ksef/graphql';
import {PostingDetailListPositionModel} from '../models/PostingDetailListItemPositionModel';
import {IPostingDetailsBodyListState} from '../state/IPostingDetailsBodyListState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {PostingChooseAccountModal} from '../../../../../../earchive/pages/Account/Posting/modals/PostingChooseAccountModal';
import {TreeViewDropdownModel} from '../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {IconSvg} from '@symfonia/brandbook';
import {intl} from '../../../../../../root/IntlProvider';
import {MenuItemBtn} from '../../../../../../common/components/MenuItem/MenuItem';

interface PostingDetailsBodyListAccountMenuProps {
  state: IPostingDetailsBodyListState;
}

export const PostingDetailsBodyListAccountActionMenu: FC<PostingDetailsBodyListAccountMenuProps> = observer(
  ({state}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const handleOnClick = () => {
      setOpen(true);
      state.openEditAccountModalState();
    };

    const handleClose = () => {
      setOpen(false);
      state.closeEditAccountModalState();
    };

    const getAccountTypeToChange = () => {
      const row = state.selectedRow as PostingDetailListPositionModel;
      return row?.accountType === AccountTypeEnum.Kup ? 'NKUP' : 'KUP';
    };

    if (state.selectedMenuAnchorEl === undefined) {
      return null;
    }

    const handleSelect = (selected: TreeViewDropdownModel) => {
      setOpen(false);
      state.postingDetailsState.updateCategoryAccountChangeArray(
        selected.key,
        state.selectedRow?.categoryId ?? null,
        state.selectedRow?.decreeLineType ?? DecreeLineTypeEnum.Undefined,
        state.accountType,
      );
      state.closeAccountSingleActionsMenu();
    };

    return (
      <>
        {open && state.postingAccountModalState?.isOpen && (
          <PostingChooseAccountModal
            state={state.postingAccountModalState}
            onClose={handleClose}
            onSelected={selected => handleSelect(selected)}
            withRadioBtns={true}
            onRadioBtnChange={type => state.setAccountType(type)}
          />
        )}
        <Menu
          id="details-account-actions"
          onClose={() => state.closeAccountSingleActionsMenu()}
          anchorEl={state.selectedMenuAnchorEl}
          open={state.selectedAccountMenuOpened}
          sx={{p: 0}}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
        >
          <MenuItemBtn
            text={intl.formatMessage({id: Tr.changeAccountType}, {accountType: getAccountTypeToChange()})}
            disabled={!state.canAccountTypeBeChangedForRow}
            key={'menuItemPostingDetailsChangeAccountType'}
            onClick={() => state.changeAccountType()}
            icon={IconSvg.SWAP_VERT}
          />

          {state.isAccountChangeAvailable && (
            <MenuItemBtn
              text={intl.formatMessage({id: Tr.configureAutodecree})}
              disabled={!state.canCategoryBeChangedForRow}
              key={'menuItemPostingDetailsChangeAccountType'}
              onClick={handleOnClick}
              icon={IconSvg.SETTINGS}
            />
          )}
        </Menu>
      </>
    );
  },
);
