import {BaseRequestVariables, EventJobRunnerI} from './EventJobRunner';
import {EnvObserverI, EnvSubscriber} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {BaseModule, BaseModuleI} from '../modules/root/services/MobXServices/BaseModule';

//Synchronizuje jobRunnera ze zmianą EnvironmentId
export interface JobRunnerManagerI<T extends BaseRequestVariables = BaseRequestVariables, P = unknown> {
  //instancja job runnera dla bierzącego kontekstu (EnvironmentId)
  jobRunner: EventJobRunnerI<T> & P;

  //umożliwia zarejestrowania opcjonalnej akcji, która wykona się przy zmianie kontekstu i utworzeniu nowej instancji jobRunnera
  registerOnChangeHandler(onChange: OnChange<T, P> | undefined): void;
}

export type OnChange<T extends BaseRequestVariables = BaseRequestVariables, P = unknown> = (jobRunner: EventJobRunnerI<T> & P) => void

export class JobRunnerManager<T extends BaseRequestVariables = BaseRequestVariables, P = unknown> extends BaseModule implements JobRunnerManagerI<T>, BaseModuleI {
  public jobRunner: EventJobRunnerI<T> & P;
  private handlers: { onChange?: OnChange<T, P> } = {};

  constructor(private readonly createJobRunner: () => EventJobRunnerI<T> & P, private envObserver: EnvObserverI) {
    super();
    this.jobRunner = createJobRunner();
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
  }

  public registerOnChangeHandler(onChange: OnChange<T, P> | undefined): void {
    this.handlers.onChange = onChange;
  }

  protected override _onMount(): void {
    this.envObserver.unsubscribeCompanyId(this.envIdSubscriber);
    this.envObserver.subscribeCompanyId(this.envIdSubscriber, true);
  }

  protected override _onUnmount() {
    this.envObserver.unsubscribeCompanyId(this.envIdSubscriber);
  }

  private readonly envIdSubscriber: EnvSubscriber = () => {
    this.jobRunner = this.createJobRunner();
    this.handlers.onChange?.(this.jobRunner);
  };
}

