import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FC} from 'react';
import {Menu} from '@mui/material';
import {PostingDetailListPositionModel} from '../models/PostingDetailListItemPositionModel';
import {IPostingDetailsBodyListState} from '../state/IPostingDetailsBodyListState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {VehicleAddEditModal} from '../../../../../../common/components/VehicleAddEditModal/VehicleAddEditModal';
import {CompanyVehiclesResponse} from '@symfonia-ksef/graphql';
import {ChangeCategoryModal} from '../../../modals/ChangeCategoryModal';
import {CreateCategoryModal} from '../../../modals/CreateCategoryModal';
import {IconSvg} from '@symfonia/brandbook';
import {intl} from '../../../../../../root/IntlProvider';
import {MenuItemBtn} from '../../../../../../common/components/MenuItem/MenuItem';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


interface PostingDetailsBodyListCategoryMenuProps {
  state: IPostingDetailsBodyListState;
}

export const PostingDetailsBodyListCategoryActionMenu: FC<PostingDetailsBodyListCategoryMenuProps> = observer(({state}) => {
  const vehicleModalState = state.vehicleModalState;

  const envId = earchiveState.company.envId ?? '';
  const companyId = earchiveState.company.currentEnvironment?.Company?.Id ?? '';

  //TODO: przeniesć do state VehicleModalState
  const handleAddEditVehicle = (title: Tr, editVehicle: boolean) => {
    const row = state.selectedRow as PostingDetailListPositionModel;
    if (editVehicle && row.canEditVehicles && row.vehicleId !== undefined) {
      if (row.vehicleId !== null) vehicleModalState.findVehicle(row.vehicleId, companyId);
    }
    vehicleModalState.setModalTitle(title, editVehicle);
    state.closeCategorySingleActionsMenuWithoutClearingSelectedRow();
    vehicleModalState.setOpenModal(true);
  };

  const saveVehicle = async (companyVehicle: CompanyVehiclesResponse) => {
    const vehicleUsageTypeBeforeSave = vehicleModalState.selectedVehicle?.VehicleUsageType;
    state.vehicleModalState.setSelectedVehicle(companyVehicle);
    state.vehicleModalState.isEditModelType
      ? await vehicleModalState.updateVehicle(companyId, false)
      : await vehicleModalState.saveVehicle(envId, companyId, false);

    if (vehicleModalState.selectedVehicle?.VehicleUsageType !== vehicleUsageTypeBeforeSave) {
      state.postingDetailsState.updatePositionVehicleUsageTypeChangeArray(vehicleModalState.selectedVehicle?.Id);
    }

    state.postingDetailsState.postingDetailsStore.fetchRecalculatedDecree();
    vehicleModalState.resetVehicle();
    state.clearSelectedRow();
    vehicleModalState.setOpenModal(false);
  };

  const closeVehicleAddEditModal = () => {
    state.clearSelectedRow();
    vehicleModalState.resetVehicle();
  };


  return <>
    <VehicleAddEditModal
      open={vehicleModalState.modalAddEditOpen}
      title={state.vehicleModalState.modalTitle}
      isDecreeDetails={false}
      companyVehicle={vehicleModalState.editedVehicle}
      selectedVehicle={vehicleModalState.selectedVehicle}
      onSave={(v) => saveVehicle(v)}
      onClose={closeVehicleAddEditModal}
      checkIfLicensePlateExist={(r: string) => state.vehicleModalState.checkIfLicensePlateExist(r, companyId)}
    />
    {state.changeCategoryModalState && state.changeCategoryModalState.isOpen && (
      <ChangeCategoryModal
        state={state.changeCategoryModalState}
        onClose={() => state.onCloseChangeCategoryModal()}
        onFinished={selectedCategoryId => state.onSaveCreateChangeCategoryModal(selectedCategoryId)}
      />
    )}

    {state.createCategoryModalState && state.createCategoryModalState.isOpen && (
      <CreateCategoryModal
        state={state.createCategoryModalState}
        onClose={() => state.onCloseCreateCategoryModal()}
        onFinished={categoryId => state.onSaveCreateChangeCategoryModal(categoryId)}
      />
    )}
    {state.selectedMenuAnchorEl !== undefined &&
      <Menu
        id="details-category-actions"
        onClose={() => state.closeCategorySingleActionsMenu()}
        anchorEl={state.selectedMenuAnchorEl}
        open={state.selectedCategoryMenuOpened}
        sx={{p: 4}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <MenuItemBtn
          text={intl.formatMessage({id: Tr.changeCategory})}
          key={'menuItemPostingDetailsChangeCategory'}
          onClick={() => state.openChangeCategoryModal()}
          icon={IconSvg.SWAP_VERT}
        />

        <MenuItemBtn
          text={intl.formatMessage({id: Tr.addCategory})}
          key={'menuItemPostingDetailsAddCategory'}
          onClick={() => state.openCreateCategoryModal()}
          icon={IconSvg.ADD}
        />

        {state.canEditVehicle &&
          <MenuItemBtn
            text={intl.formatMessage({id: Tr.editVehicle})}
            key={'menuItemPostingDetailsEditVehicle'}
            onClick={() => handleAddEditVehicle(Tr.editVehicle, true)}
            icon={IconSvg.EDIT}
          />
        }

        {state.canAddVehicle &&
          <MenuItemBtn
            text={intl.formatMessage({id: Tr.addNewVehicle})}
            key={'menuItemPostingDetailsEditVehicle'}
            onClick={() => handleAddEditVehicle(Tr.addVehicle, false)}
            // TODO: do zmiany jak będzie ikona ADD_VEHICLE
            icon={IconSvg.ADD_CIRCLE}
          />
        }

      </Menu>}
  </>;
});
