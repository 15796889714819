import {Tr} from '../../../../locales/translationKeys';
import {useIntl} from 'react-intl';
import {PageMessage, usePageTitle} from '../../../common';

export const CompanyInactive = ({companyName}: { companyName: string }) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();

  setPageTitle(companyName);

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.companyInactiveTitle})}
      description={intl.formatMessage({id: Tr.companyInactiveDesc})}
    />
  );
};
