import {IPostingDocumentTypesState} from './IPostingDocumentTypesState';
import {
  DocumentTypeSettingTypeEnum,
  DocumentTypesSettingsRequestInput,
  DocumentTypesSettingsResponse,
  GetDocumentTypesSettingsDocument,
  GetDocumentTypesSettingsQuery,
  GetDocumentTypesSettingsQueryVariables,
  GetInvoiceDocumentTypesDocument,
  GetInvoiceDocumentTypesQuery,
  GetInvoiceDocumentTypesQueryVariables,
  InvoiceDocumentTypeResponse,
  InvoiceTypeGroupEnum,
  SaveDocumentTypesSettingsDocument,
  SaveDocumentTypesSettingsMutation,
  SaveDocumentTypesSettingsMutationVariables,
} from '@symfonia-ksef/graphql';
import {action, autorun, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {apolloClient} from '../../../../../../../../root/providers/GraphQLProvider';
import {IPostingConfigurationState} from '../../IPostingConfigurationState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {convertToDropdownListOption} from '../../../../../../../../common/helpers/baseFilterHelpers';
import {DropdownListOption, ToastVariant} from '@symfonia/brandbook';
import {addAlert} from '../../../../../../../../../services/helpers/AlertService';

export class PostingDocumentTypesState implements IPostingDocumentTypesState {
  @observable
  public documentTypeSettings: IObservableArray<DocumentTypesSettingsResponse> = observable([]);

  @observable
  public documentTypes: IObservableArray<InvoiceDocumentTypeResponse> = observable([]);

  @observable
  public isValid = false;

  @observable
  public isModified = false;

  @computed
  public get documentTypesOptions(): DropdownListOption[] {
    return convertToDropdownListOption(this.documentTypes, e => ({
      label: e.Symbol + ' - ' + e.Name,
      value: e.Id,
    }));
  }

  public postingConfigurationState: IPostingConfigurationState;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.postingConfigurationState = postingConfigurationState;

    autorun(() => {
        if (this.postingConfigurationState.companyId !== undefined &&
          this.postingConfigurationState.selectedFinancialYear !== undefined) {
          this.loadDocumentTypes();
          this.loadDocumentTypesVatRegistries();
        }
      },
    );
  }

  @action
  public changeSelectedFinancialYear(year: string) {
    if (year === this.postingConfigurationState.selectedFinancialYear) {
      return;
    }

    this.postingConfigurationState.setSelectedFinancialYear(year);
    this.resetChanges();
  }

  @action
  public changeInvoiceTypeGroup(invoiceTypeGroup: InvoiceTypeGroupEnum) {
    if (invoiceTypeGroup === this.postingConfigurationState.invoiceTypeGroup) {
      return;
    }

    this.postingConfigurationState.setInvoiceTypeGroup(invoiceTypeGroup);
    this.resetChanges();
  }

  @action
  public setDocumentTypeRegistries(settings: DocumentTypesSettingsResponse[]) {
    this.documentTypeSettings.replace(settings);
  }

  @action
  public setDocumentTypes(documentTypes: InvoiceDocumentTypeResponse[]) {
    this.documentTypes.replace(documentTypes);
  }

  @action
  public changeDocumentType(settingType: DocumentTypeSettingTypeEnum, documentTypeId: string | null) {
    const changed = this.documentTypeSettings.find(x => x.SettingType === settingType);

    if (changed != null || changed !== undefined) {
      changed.InvoiceDocumentTypeId = documentTypeId;
    } else {
      this.documentTypeSettings.push({
        SettingType: settingType,
        InvoiceDocumentTypeId: documentTypeId,
      });
    }

    this.isModified = true;

    this.validate();
  }

  @action
  public changeRegistryType(settingType: DocumentTypeSettingTypeEnum, vatRegistryId: string) {
    const changed = this.documentTypeSettings.find(x => x.SettingType === settingType);

    this.documentTypeSettings.push({
      SettingType: settingType,
      InvoiceDocumentTypeId: '',
    });

    this.isModified = true;

    this.validate();
  }

  @action
  public async loadDocumentTypes() {
    await apolloClient.query<GetInvoiceDocumentTypesQuery, GetInvoiceDocumentTypesQueryVariables>({
      query: GetInvoiceDocumentTypesDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        FinancialYearId: this.postingConfigurationState.selectedFinancialYear,
      },
    })
      .then(result => this.setDocumentTypes(result.data.GetInvoiceDocumentTypes.DocumentTypeResponse))
      .catch(err => this.displayErrorAlert());
    return this.documentTypes;
  }


  @action
  public async loadDocumentTypesVatRegistries() {
    await apolloClient.query<GetDocumentTypesSettingsQuery, GetDocumentTypesSettingsQueryVariables>({
      query: GetDocumentTypesSettingsDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        InvoiceTypeGroup: this.postingConfigurationState.invoiceTypeGroup,
        FinancialYearId: this.postingConfigurationState.selectedFinancialYear,
      },
    }).then(result => this.setDocumentTypeRegistries(result.data.GetDocumentTypesSettings.DocumentTypesVatRegistries)).catch(err => this.displayErrorAlert());
    return this.documentTypeSettings;
  }

  @action
  public async saveDocumentTypesSettings() {
    await apolloClient.mutate<SaveDocumentTypesSettingsMutation, SaveDocumentTypesSettingsMutationVariables>({
      mutation: SaveDocumentTypesSettingsDocument,
      context: {
        envId: this.postingConfigurationState.companyId,
      },
      variables: {
        InvoiceTypeGroup: this.postingConfigurationState.invoiceTypeGroup,
        FinancialYearId: this.postingConfigurationState.selectedFinancialYear,
        DocumentTypesSettings: this.documentTypeSettings.map<DocumentTypesSettingsRequestInput>(x => ({
          DocumentTypeId: x.InvoiceDocumentTypeId,
          SettingsType: x.SettingType,
        })),
      },
    }).then(() => {
      this.displaySuccessAlert();
      this.resetChanges();
      this.postingConfigurationState.setForceRefreshFinancialYear(true);
    }).catch(err => this.displayErrorAlert());
  }

  @action
  public resetChanges() {
    this.setDocumentTypeRegistries([]);
    this.setDocumentTypes([]);
    this.isModified = false;
    this.isValid = false;
    this.loadDocumentTypes();
    this.loadDocumentTypesVatRegistries();
  }

  @action
  findOptionBySettingType(settingType: DocumentTypeSettingTypeEnum): DropdownListOption | undefined {
    return this.documentTypesOptions.find(
      x => x.value ===
        this.documentTypeSettings.find(setting => setting.SettingType === settingType)?.InvoiceDocumentTypeId);
  }

  private displayErrorAlert() {
    addAlert({id: Tr.genericError, color: ToastVariant.ERROR, duration: 10000});
  }

  private displaySuccessAlert() {
    addAlert({id: Tr.operationSuccess, color: ToastVariant.SUCCESS, duration: 10000});
  }

  private validate() {
    const vat = this.documentTypeSettings.find(x => x.SettingType === DocumentTypeSettingTypeEnum.Vat);
    const corrective = this.documentTypeSettings.find(x => x.SettingType === DocumentTypeSettingTypeEnum.Corrective);

    const isVatValid = vat !== null && vat !== undefined && vat.InvoiceDocumentTypeId !== null;
    const isCorrectiveValid = corrective !== null && corrective !== undefined && corrective.InvoiceDocumentTypeId !== null;

    this.isValid = isVatValid && isCorrectiveValid;
  }
}
