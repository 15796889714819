import {Tr} from '@symfonia-ksef/locales/keys';
import {withClearButton} from '../../../common/HOCs/withClearButton';
import {NonFunction} from '../../../common/types/NonFunction';
import {intl} from '../../../root/IntlProvider';
import classnames from 'classnames';
import {Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import React from 'react';

export interface UnderlineInputButtonProps<T> {
  disabled?: boolean;
  value?: NonFunction<T>;
  onClick(event: React.MouseEvent): void;
  fullWidth?: boolean;
}


export const UnderlineInputButton = <TInputType extends object | string | number | undefined | null>({
                                                                                                       ...props
                                                                                                     }: UnderlineInputButtonProps<TInputType>): JSX.Element => {
  return (
    <div
      className={classnames(`flex flex-row justify-between items-center
        box-border border-solid border-0 border-b-[2px] border-green-600
        focus:border-primary-green
        hover:border-green-700
        cursor-pointer`,
        {'w-[200px]': !props.fullWidth},
        {'w-full': props.fullWidth},
      )}
      onClick={event => props.onClick(event)}
    >
      <button
        className="appearance-none py-1
        bg-transparent
        w-auto
        border-none
        text-sm font-quicksand truncate
        text-gray-700  leading-tight
        focus:outline-none
        cursor-pointer"
        disabled={props.disabled}>
        {props.value ? props.value.toString() : intl.formatMessage({id: Tr.postringConfiguration_no_assignment})}
      </button>
      <Icon
        svg={IconSvg.EDIT}
        color={props.disabled ? IconColor.GREY_500 : IconColor.GREEN_600}
        size={IconSize.MD}
        className={'min-w-[25px]'}
      />
    </div>
  );
};

export const UnderlineInputButtonWithClear = withClearButton(UnderlineInputButton);
