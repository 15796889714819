import {BaseModule} from '../../../../../../../../root/services/MobXServices/BaseModule';
import {ExtendedTableServiceI} from '../../../../../../../../root/services/TableServices/ExtendedTableService';
import {TableBuilder} from '../../../../../../../../root/services/TableServices/TableBuilder';
import {categoryAccountsColumns} from './CategoryAccountsTableConfig';
import {ICategoryAccountState} from './state/ICategoryAccountState';
import {
  CategoryAccountDataSourceHandler,
  CategoryAccountRepositoryI,
  CategoryAccountRow,
} from './CategoryAccountRepository';
import {GetCategoriesAccountsQuery, GetCategoriesAccountsQueryVariables} from '@symfonia-ksef/graphql';
import {TableSortDirection} from '@symfonia/brandbook';
import {PaginationMode} from '../../../../../../../../root/services/TableServices/PaginationTableService';
import {SearchService, SearchServiceI} from '../../../../../../../../root/services/SearchService/SearchService';
import {computed, makeObservable} from 'mobx';
import {intl} from '../../../../../../../../root/IntlProvider';
import {TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {Pills} from '../../../../../../../../root/components/FiltersPills';

export type CategoryAccountTableType = ExtendedTableServiceI<
  CategoryAccountRow,
  'GetCategoriesViewModel',
  GetCategoriesAccountsQuery,
  GetCategoriesAccountsQueryVariables,
  ICategoryAccountState
>;

export class CategoryAccountService extends BaseModule {
  public readonly tableService!: CategoryAccountTableType;
  public readonly repository!: CategoryAccountRepositoryI;
  public readonly searchService!: SearchServiceI;

  constructor(private readonly state: ICategoryAccountState) {
    super();

    const dataSourceHandler = new CategoryAccountDataSourceHandler(state);
    this.repository = dataSourceHandler.repository;
    this.searchService = new SearchService(this.repository, () => this.tableService.setPagination({page: 1}), true);
    this.tableService = TableBuilder.create<
      CategoryAccountRow,
      'GetCategoriesViewModel',
      GetCategoriesAccountsQuery,
      GetCategoriesAccountsQueryVariables,
      ICategoryAccountState
    >()
      .connectKeyFactory(row => row.Id)
      .connectColumns(categoryAccountsColumns)
      .connectSortBy({
        sortBy: {name: 'Name', direction: TableSortDirection.ASC},
        persistKey: 'categoryAccountTableSortBy',
      })
      .connectContext(state)
      .connectDataSource(dataSourceHandler)
      .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}, persistKey: 'categoryAccountTablePagination'})
      .buildExtended();
    makeObservable(this);
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};

    for (const [filterKey, value] of this.state.filterState.pillsList.entries()) {
      const {values, translationKey} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: translationKey ?? filterKey.replace('.', '')}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.state.filterState.clearSingleFilter(filterKey, key, value);
            this.repository.fetch();
          },
        })),
      };
    }

    return convertedPills;
  }

  protected async _onMount(): Promise<void> {
    this.tableService.onMount();
    this.searchService.onMount();
    await this.repository.fetch();
  }

  protected _onUnmount() {
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }
}
