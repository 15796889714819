import {AnyObject} from 'yup/es/types';
import {ImmutableStateProducer as StateProducer, State, StateI} from '../State';
import {apolloClient, GraphQLErrorWithMessage} from '../../../providers/GraphQLProvider';
import {BaseModule, BaseModuleI} from '../BaseModule';
import {action, computed, makeObservable, observable, override} from 'mobx';
import {ApolloError, TypedDocumentNode} from '@apollo/client';
import {fetchMatchedAction, InitializerType} from '../../../../../services/helpers/fetchMatchedAction';
import {isEmpty} from 'ramda';
import {FetchResult} from '@apollo/client/link/core';
import produce from 'immer';

export type ImmutableVariablesProducer<Variables extends AnyObject> = StateProducer<Partial<Variables>>

export type ImmutableStateProducer<Key extends string, Data extends Record<Key, AnyObject>> = (currentState: Data[Key] | null) => void

export type SetStateArgType<Key extends string, Data extends Record<Key, AnyObject>> =
  Data[Key]
  | null
  | ImmutableStateProducer<Key, Data>

//Callbacki mozliwe do przekazania z poziomu wywołania fetch()
export type FetchOptions<Key extends string = string, Data extends Record<Key, AnyObject> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> = {
  onSuccess?: (data: Data[Key]) => void,
  onError?: (errors: readonly GraphQLErrorWithMessage[]) => void,
  //umożliwia przeforsowanie zapytania z podanymi zmiennymi (zignorowane zostanie checkIsReady() i ustawione zmienne) a przekazane zmienne nie zostaną zapisane do variables
  forceWith?: Variables,
  onFetchRejected?: () => void,
  onFetched?: (data: Data[Key] | null, error: string | null) => void
}

//Służy do integracji stanu ze źródłem danych oraz konfiguracji i wykonywania zapytań do backendu
//Pod maską używa skonfigurowanego apolloClienta do wykonywania zapytań
//typ generyczny Data jest typem otrzymywanym z response
//typ generyczny Variables jest typem zmiennych przekazywanych do requestu
//typ generyczny Key jest kluczem w obiekcie danych otrzymywanych z response, które mają być zapisane do stanu
export interface BaseRepositoryI<Key extends string = string, Data extends Record<Key, AnyObject> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> extends BaseModuleI {
  loading: boolean;
  initialized: boolean;
  error: string | null;
  isNotEmpty: boolean;

  get envId(): string | null;

  //reaktywne dane otrzymane z response (są zapisane jako stan)
  get responseData(): Data | null;

  //część odpowiedzi znajdująca się pod właściwością response zgodną z przekazanym Key
  get data(): Data[Key] | null;

  //służy to konfiguracji zapytania - zapisuje zmienne zapytania
  //działa za zasadzie połączenia metod State.set, State.override i State.reset. NP dla {partial1?:string,partial2?:{nested:number}} umożliwia:
  // zmianę części variables np. configure({partial1:"newValue"}) zmieni wartość 'partial1' bez zmieniania reszty i spowoduje trigger observera
  // nadpisanie całości ze zmianą części zmiennych (przydatne przy zagnieżdżeniach) np. configure(currentVariables=> { currentVariables.partial2.nested = 4 }) zmieni jedynie wartość currentVariables.partial2.nested, ustawi nową referencję dla variables, spowoduje trigger observera
  // zresetuje całość variables do wartości pustych configure(null)
  // zresetuje część variables do wartości pustych configure({ partial1:undefined })
  configure(variables: Partial<Variables> | ImmutableVariablesProducer<Variables> | null): this;

  //Ustawia envId w headerze zapytania - wymagane dla większości zapytań
  setEnvId(envId: string): this;

  //Anuluje trwające zapytanie
  abort(): this;

  //Uruchamia implementacje metody _fetch przy użyciu skonfigurowanych variables - domyślnie wykonuje zapytanie z apolloClient
  //Uruchamia cykl zapytania:
  // zmienia flagę isLoading
  // uruchomienie customowych implementacji metod: beforeFetch, afterFetch, onSuccess, onError, onFetchRejected
  // ustawia flagę initialized
  // ustawia errory
  // zapisuje pobrane dane do stanu
  //Jeśli checkIsReady() === false a options?.forceWith === undefined wgl zapytanie nie zostanie wykonane
  fetch(options?: FetchOptions<Key, Data, Variables>): Promise<void>;

  //Sprawdza czy zapytanie może być wykonane, możliwe do nadpisania customową implementacją
  //Domyślnie zwraca true jeśli ustawimy niepuste envId i niepuste variables
  checkIsReady(): boolean;


  //Służy do ręcznego ustawienia stanu z pominięciem wykonywania requestu
  //Uruchamia customową implementację metody onDataChange
  //Umożliwia nadpisanie całości stanu -> set(wholeState)
  //lub zmiany jego części w sposób niemutowalny -> set(currentState=>{ currentState.part1.nested = 'nestedStateValue' })
  //set(data: SetStateArgType<Key, Data>, opt?: { resetVariables?: boolean, variables?: Partial<Variables> }): this;
}


export abstract class BaseRepository<Key extends string = string, Data extends Record<Key, any> = Record<Key, AnyObject>, Variables extends AnyObject = AnyObject> extends BaseModule implements BaseRepositoryI<Key, Data, Variables>, BaseModuleI {
  @observable
  public error: string | null = null;

  @observable
  public variablesChanged: boolean = false;

  @observable
  private _dataInitialized: boolean = false;
  
  private abortController?: AbortController;

  protected constructor(protected readonly key: Key, private readonly graphqlDocument: TypedDocumentNode, private readonly initializerType: InitializerType = InitializerType.Query) {
    super();
    this._variables = new State<Partial<Variables>>({});

    makeObservable(this);
  }

  @observable
  private _envId: string | null = null;

  @computed
  public get envId(): string | null {
    return this._envId;
  }

  @observable
  private _loading: boolean = false;

  @computed
  public get loading() {
    return this._loading;
  }

  //key: klucz w obiekcie danych zwracanych z response któe mają być zapisane do stanu
  //graphqlDocument: schemat zapytania któe chcemy wykonać
  //initializerType: decyduje o tym czy wykonywać będziemy Query czy Mutation

  @observable.ref
  private _data: StateI<Data> | null = null;

  @computed
  public get data(): Data[Key] | null {
    return this._data?.state[this.key] ?? null;
  }

  //final
  @computed
  public get isNotEmpty(): boolean {
    return !!this.data;
  }

  @override
  public get initialized(): boolean {
    return this._dataInitialized && super.initialized;
  }

  @computed
  public get responseData(): Data | null {
    return this._data?.state ?? null;
  }

  protected _variables!: StateI<Partial<Variables>>;

  @computed
  protected get variables(): Partial<Variables> {
    return this._variables.state;
  }

  @action.bound
  public setEnvId(envId: string | null): this {
    this._envId = envId;
    return this;
  }

  //final
  @action.bound
  public configure(variables: Partial<Variables> | ImmutableVariablesProducer<Variables> | null): this {
    if (!variables) {
      this._variables.override({});
      this.setVariablesChanged(true);
      return this;
    }
    this.isImmutableVariablesProducer(variables) ? this._variables.override(variables) : this._variables.set(variables);

    if (!isEmpty(variables)) {
      this.setVariablesChanged(true);
    }
    return this;
  }

  //final
  public async fetch(options?: FetchOptions<Key, Data, Variables>): Promise<void> {
    if (!this.checkIsReady() && !options?.forceWith) {
      return;
    }
    this.setLoading(true);
    try {

      const rejected = !((await this.beforeFetch()) ?? true);

      if (rejected) {
        options?.onFetchRejected?.();
        throw undefined;
      }

      const {
        data,
        errors,
      } = await this._fetch(options);

      if (errors) {
        this.setLoading(false);
        this.setError(this.mapResponseErrors(errors));
        this.onError(errors, this.error);
        options?.onError?.(errors);
      }

      if (data && data[this.key]) {
        const newData = this.mapResponseData(data[this.key]);
        this.setState(data);
        this.onSuccess(newData, data);
        options?.onSuccess?.(newData);
      }

    } catch (err: unknown) {
      if ((err as ApolloError).networkError?.name === 'AbortError') {
        this.onAbort();
        return;
      }
      this.setError((err as ApolloError).message);
      this.onFetchRejected();
    } finally {
      this.setLoading(false);
      await this.afterFetch(this.data, this._data?.state ?? null, !!options?.forceWith);
      options?.onFetched?.(this.data, this.error);
      this.initialize(true);
    }
  }

  //possible to override
  public checkIsReady(): boolean {
    return !!this.envId && !isEmpty(this.variables) && !Object.values(this.variables).some(variable => variable === undefined);
  }

  //final
  @action.bound
  public setLoading(loading: boolean): void {
    this._loading = loading;
  }

  public abort(): this {
    this.abortController?.abort();
    this.abortController = undefined;
    return this;
  }

  public set(data: SetStateArgType<Key, Data>, opt?: {
    resetVariables?: boolean,
    variables?: Partial<Variables>
  }): this {
    opt?.resetVariables ? this.configure(null) : opt?.variables && this.configure(opt.variables);

    this.isImmutableStateProducer(data) ? this.setImmutable(data) : this.setState({[this.key]: data} as Data);
    if (!this.data) {
      this.initialize(false);
    }
    return this;
  }


  //possible to override
  protected onFetchRejected(): void {
    return;
  }

  @action
  protected setVariablesChanged(changed: boolean): void {
    this.variablesChanged = changed;
  }

  // possible to override
  protected onAbort(): void {
    return;
  }

  //possible to override
  protected async _fetch(options?: FetchOptions<Key, Data, Variables>): Promise<FetchResult<Data>> {
    this.abortController = new AbortController();
    return fetchMatchedAction<Key, Data, Variables>(this.graphqlDocument, this.envId, options?.forceWith ?? this.variables as Variables, this.initializerType, apolloClient, this.abortController);
  }

  //final
  @action
  protected setError(error: string | null): void {
    this.error = error;
  }

  //final
  @override
  protected override initialize(initialized?: boolean): void {
    super.initialize(initialized);
    this._dataInitialized = initialized ?? true;
  }

  //possible to override
  protected mapResponseErrors(errors: readonly GraphQLErrorWithMessage[]): string | null {
    const [error] = errors;
    return error.extensions?.message ?? error.message;
  }

  //possible to override
  protected mapResponseData(data: Data[Key]): Data[Key] {
    return data;
  }

  //possible to override
  protected onSuccess(data: Data[Key], wholeData: Data): void | Promise<void> {
    return;
  }

  //possible to override
  protected onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    return;
  }

  //possible to override
  protected onDataChange(data: Data[Key] | null): void | Promise<void> {
    return;
  }

  //possible to override
  protected beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    return;
  }

  //possible to override
  protected afterFetch(data: Data[Key] | null, wholeData: Data | null, forced: boolean): void | Promise<void> {
    return;
  }


  private isImmutableVariablesProducer(variables: Partial<Variables> | ImmutableVariablesProducer<Variables> | null): variables is ImmutableVariablesProducer<Variables> {
    return typeof variables === 'function';
  }

  private setImmutable(immutableSetter: ImmutableStateProducer<Key, Data>) {
    const data = produce(immutableSetter)(this.data);
    if (!this._data) {
      return;
    }

    this.setState({...this._data.state, [this.key]: data});
  }

  private isImmutableStateProducer(data: Data[Key] | null | ImmutableStateProducer<Key, Data>): data is ImmutableStateProducer<Key, Data> {
    return typeof data === 'function';
  }

  @action
  private setState(state: Data | null): this {
    try {
      if (this.data !== state?.[this.key]) {
        this.setVariablesChanged(false);
      }

      if (state === null) {
        this._data = null;
        return this;
      }

      if (this._data === null) {
        this._data = new State<Data>(state);
        return this;
      }
      this._data.override(state);
      return this;

    } finally {
      this.onDataChange(this.data);
    }
  }
}
