import {intl} from '../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CreateCategoryStep, ICreateCategoryModalState} from '../../state/ICreateCategoryModalState';
import {CreateCategoryFirstStep} from './CreateCategoryFirstStep';
import {CreateCategorySecondStep} from './CreateCategorySecondStep';

export const CreateCategoryStepsDefinition = (state: ICreateCategoryModalState): CreateCategoryStep[] =>
[
  {
    label: intl.formatMessage({id: Tr.category}),
    title: intl.formatMessage({id: Tr.createCategory}),
    completed: false,
    element: <CreateCategoryFirstStep state={state}/>,
    stepRequirementCheck: () => state.checkFirstStepRequirements
  },
  {
    label: intl.formatMessage({id: Tr.account}),
    title: intl.formatMessage({id: Tr.chooseAccount}),
    completed: true,
    element: <CreateCategorySecondStep state={state}/>,
    stepRequirementCheck: () => state.checkSecondStepRequirements
  }
];
