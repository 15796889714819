import {PostingConfigurationHeader} from '../components/PostingConfigurationHeader';
import {CategoryAccountsFilters} from './CategoryAccountsTable/CategoryAccountsFilters';
import {CategoryAccountsTableActions} from './CategoryAccountsTable/CategoryAccountsTableActions';
import {Tr} from '@symfonia-ksef/locales/keys';
import {usePostingConfigurationsMethods} from '../../../hooks/usePostingConfigurationsMethods';
import {CategoryTypeEnum, InvoiceTypeGroupEnum, RequiredModulesToFillEnum} from '@symfonia-ksef/graphql';
import * as React from 'react';
import {useEffect} from 'react';
import {
  CategoriesAccountsFiltersKeys,
  CategoryTypesFilterOptions,
} from './CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {
  mapCategoryTypesToOptions,
  mapCategoryTypeToActiveFilters,
} from './CategoryAccountsTable/filters/filtersHelpers';
import {Loader} from '../../../../../../../common/components/Loader/Loader';
import {observer} from 'mobx-react-lite';
import {ICategoryAccountState} from './CategoryAccountsTable/state/ICategoryAccountState';
import {DecreeConfigurationRequiredModal} from '../../../modals/DecreeConfigurationRequiredModal';
import {usePostingConfigurationStatus} from '../../../../../../hooks/usePostingConfigurationStatus';
import {earchiveStateMobx} from '../../../../../../EArchiveModule';
import {FilterScopeType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {CategoryAccountsTable} from './CategoryAccountsTable/CategoryAccountsTable';
import {useModule} from '../../../../../../../root/services/MobXServices/BaseModule';
import {CategoryAccountService} from './CategoryAccountsTable/CategoryAccount.service';
import TableHeader from '../../../../../../components/TableHeader/TableHeader';
import CategoryAccountTableHeader from './CategoryAccountsTable/CategoryAccountTableHeader';
import {ButtonTertiary, ButtonTertiarySize, IconSvg} from '@symfonia/brandbook';
import {intl} from '../../../../../../../root/IntlProvider';
import {usePageTitle} from '../../../../../../../common';

export interface CategoryAccountsProps {
  state: ICategoryAccountState;
}

export const CategoryAccounts = observer(({state}: CategoryAccountsProps) => {
  const {setPageTitle} = usePageTitle();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const {getAccounts} = usePostingConfigurationsMethods(state.postingConfigurationState);
  const {getRequiredConfigurationStatus} = usePostingConfigurationStatus();
  const currentCategoryType = mapCategoryTypesToOptions(
    state.filterState?.activeFilters.get(CategoriesAccountsFiltersKeys.Type)?.values ?? [
      CategoryTypeEnum.Default,
      CategoryTypeEnum.Custom,
    ],
  );

  const service = useModule(() => new CategoryAccountService(state));

  useEffect(() => {
    if (state.postingConfigurationState.isFinancialYearActive) getRequiredConfigurationStatus();
  }, []);

  useEffect(() => {
    const isPostingConfigured = earchiveStateMobx.postingState.isPostingConfigured;
    if (isPostingConfigured !== undefined) setOpenModal(!isPostingConfigured);
  }, [earchiveStateMobx.postingState.isPostingConfigured]);

  const handleYearChange = (year: string) => {
    state.postingConfigurationState.setSelectedFinancialYear(year);
    state.tableState.triggerRefreshDataWithoutFilters();
    getAccounts(year);
  };

  const handleInvoiceTypeGroupChange = (group: InvoiceTypeGroupEnum) => {
    const x = state.filterState.activeFilters.get(CategoriesAccountsFiltersKeys.InvoiceTypeGroup)?.values.toString();
    if (x !== group) {
      state.postingConfigurationState.setInvoiceTypeGroup(group);
      state.filterState?.setActiveFiltersWithStorage(CategoriesAccountsFiltersKeys.InvoiceTypeGroup, {
        values: [group],
        type: FilterScopeType.Enum,
        isActive: true,
      });
    }
    state.tableState.triggerRefreshDataWithoutFilters();
    getAccounts();
  };

  const handleChange = (radio: string) =>
    state.filterState?.setActiveFiltersWithStorage(CategoriesAccountsFiltersKeys.Type, {
      values: mapCategoryTypeToActiveFilters(radio as CategoryTypesFilterOptions),
      type: FilterScopeType.Enum,
      isActive: true,
    });

  if (!state || !state?.filterState) {
    return <Loader/>;
  }

  setPageTitle(intl.formatMessage({id: Tr.config}));

  return (
    <>
      <DecreeConfigurationRequiredModal open={openModal} onClose={() => setOpenModal(false)}/>
      <div className="flex flex-col gap-2 lg:ml-[40px] managmentPage">
        <PostingConfigurationHeader
          title={Tr.config}
          text={Tr.postingActive}
          tooltipTitle={Tr.whatIfind}
          service={service}
          setSelectedFinancialYear={year => handleYearChange(year)}
          changeInvoiceTypeGroup={group => {
            service.repository.fetch();
            return handleInvoiceTypeGroupChange(group);
          }}
          defaultSwitchBtnValue={state.postingConfigurationState.invoiceTypeGroup}
          state={state.postingConfigurationState}
          requiredModulesPurchase={earchiveStateMobx.postingState.requiredModules.includes(
            RequiredModulesToFillEnum.Category,
          )}
        />
        <TableHeader>
          <CategoryAccountTableHeader
            dropdownOnChange={v => {
              handleChange(v);
              service.tableService.setPagination({page: 1});
              service.repository.fetch();
            }}
            dropdownValue={[currentCategoryType]}
            service={service}
            state={state}
          >
            <CategoryAccountsFilters pageService={service} state={state}/>
            {state.filterState.pillsList.size > 0 && (
              <ButtonTertiary
                size={ButtonTertiarySize.SM}
                onClick={() => {
                  state.filterState.handleClearMenuFilter();
                  service.repository.fetch();
                }}
                text={intl.formatMessage({id: Tr.clearFiltersLabel})}
                lIcon={IconSvg.CLOSE}
              />
            )}
          </CategoryAccountTableHeader>
        </TableHeader>
        <CategoryAccountsTable service={service}/>
      </div>
      <CategoryAccountsTableActions state={state}/>
    </>
  );
});
