import NoInvoice from '../../../../assets/empty_invoice.svg';
import {CircularProgress} from '@mui/material';
import {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {InvoicePreviewInterface} from '@symfonia-ksef/state/InvoicePreviewState/InvoicePreviewRepository';
import {Text} from '../../../root/components/Text';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';

interface IInvoiceHTMLProvider {
  invoicePreviewRepository: InvoicePreviewInterface;
}

export const InvoiceHTMLPreview: FC<IInvoiceHTMLProvider> = observer(({invoicePreviewRepository}) => {
  const {loading, zoomRation, state: {HtmlContent}} = invoicePreviewRepository;
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!HtmlContent) {
      return;
    }
    const handler = () => setIsLoaded(true);
    ref.current?.addEventListener?.('load', handler);
    return () => ref.current?.removeEventListener('load', handler);
  }, [HtmlContent]);

  useEffect(() => {
    loading && setIsLoaded(false);
  }, [loading]);

  useEffect(() => {
    if (isLoaded && ref.current?.contentDocument) {
      ref.current.contentDocument.body.style.padding = '0';
      ref.current.contentDocument.body.style.margin = '0';
      ref.current.contentDocument.body.style.transformOrigin = 'top left';
      ref.current.contentDocument.body.style.fontSize = zoomRation + '%';
      ref.current?.contentDocument.getElementsByTagName('html')?.item(0)?.setAttribute('style', 'width: fit-content; position: absolute; top:0; left:0');
      ref.current?.contentDocument.styleSheets[0].insertRule('::-webkit-scrollbar {width: 0.25rem; height: 0.25rem; margin: 0.25rem}', 0);
      ref.current?.contentDocument.styleSheets[0].insertRule('::-webkit-scrollbar-thumb {background-color: rgba(0,125,0,1)}', 1);
      ref.current?.contentDocument.styleSheets[0].insertRule('::-webkit-scrollbar-track {background: #F4F4F4}', 2);
    }
  }, [zoomRation, isLoaded]);

  const wrapperClasses = 'mx-[6px] mb-[12px] w-full h-full flex items-center justify-center';

  if (loading) {
    return <div className={wrapperClasses}>
      <CircularProgress size={80} color="success"/>
    </div>;
  }

  return (
    <div className={wrapperClasses}>
      {!HtmlContent ? <div>
          <img
            className="w-[360px]"
            src={NoInvoice}
            alt="noInvoice"
          />
          <Text block
                className="text-center text-[18px] font-medium">{intl.formatMessage({id: Tr.invoicePreviewEnabled})}</Text>
        </div> :
        <iframe
          ref={ref}
          srcDoc={HtmlContent}
          className="full-size"
          width="100%"
          height="100%"
        />}
    </div>
  );
});
