export class MathHelpers {
  static areNumbersEqualToDecimalPlace(first: number, second: number, decimalPlaces: number = 0): boolean {
    return MathHelpers.epsilonRound(first, decimalPlaces) === MathHelpers.epsilonRound(second, decimalPlaces);
  }

  static epsilonRound = (num: number , precision: number) => { 
    if (!Number.isInteger(precision)) throw new Error("Precision must be integer");
    
    const multiplier = Math.pow(10, precision);
    return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
  };
}

