import {Columns} from "../../../../../../../../root/services/TableServices/BaseTableService";
import {intl} from "../../../../../../../../root/IntlProvider";
import {cutTextLength} from "../../../../../../../../common/helpers/tableInputTextCutLenght";
import {Tr} from "@symfonia-ksef/locales/keys";
import {VehiclesRow} from "../state/Vehicles.repository";
import {IVehicleState} from "../state/IVehicleState";
import * as React from "react";
import Button from "@mui/material/Button";
import MoreVert from "@mui/icons-material/MoreVert";

export const vehiclesColumns: Columns<VehiclesRow, IVehicleState> = Object.freeze({
  RegistrationNumber: {
    sortable: true,
    header: intl.formatMessage({id: Tr.registrationNumber}),
    cell: (row) => cutTextLength(row.RegistrationNumber, false, false, true)?.truncatedText,
    order: 1,
    isObserver: true,
    width: 'w-[180px]',
  },
  VehicleUsageType: {
    sortable: true,
    header: intl.formatMessage({id: Tr.VehicleUsageTypes}),
    cell: (row) => cutTextLength(intl.formatMessage({id: row.VehicleUsageType}), false, false, true)?.truncatedText,
    order: 2,
    isObserver: true,
    width: 'w-[180px]',
  },
  Description: {
    sortable: true,
    header: intl.formatMessage({id: Tr.description}),
    cell: (row) => cutTextLength(row.Description, false, false, true)?.truncatedText,
    order: 3,
    isObserver: true,
    width: 'w-[180px]',
  },
  Id: {
    asAction: true,
    order: 4,
    isObserver: true,
    width: 'w-[70px]',
    header: intl.formatMessage({id: 'actions'}),
    cell: (row, _, __, context) =>
      <Button size="small" onClick={e => {
        e.stopPropagation();
        const state = context as IVehicleState;
        state.openPositionActionMenu(e.currentTarget, row);
      }}>
        <MoreVert className={'IconWithTransform'}/>
      </Button>,

  },
});
