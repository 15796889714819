import {BaseEventConsumer} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {postingTableRefreshService} from '@symfonia-ksef/state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';

export class RegistrationNumberCreatedEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.RegistrationNumberCreated, undefined, {
      success: {id: Tr.registrationNumberCreatedMessage},
      error: {id: Tr.registrationNumberCreatedErrorMessage},
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    postingTableRefreshService.incrementRefreshCounter();
    
    await super.onUpdate(event);
  }
}
