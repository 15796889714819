import {FC, useEffect, useState} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {Badge, BadgeSize} from '../Badge/Badge';
import * as _icons from './assets';
import {Icon, IconColor, IconSize, IconSvg} from '../Icon/Icon';
import {Img} from '../Image/Image';
import {TestableElement} from '../../external/types';

const icons = _icons as Record<keyof typeof _icons, string>;

export enum AvatarWatermarkDisplay {
  NEVER = 'NEVER',
  ALWAYS = 'AWLAYS',
  ON_HOVER = 'ON_HOVER',
}

export enum AvatarSize {
  MD = 'MD',
  SM = 'SM',
}

export enum AvatarVariant {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  EMPTY_GREEN = 'EMPTY_GREEN',
  EMPTY_GREY = 'EMPTY_GREY',
}

type AvatarUserName = {
  userName?: string;
  userSurname?: string;
};

export type AvatarProps = AvatarUserName & {
  imgUrl?: string;
  text?: string;
  variant?: AvatarVariant | null;
  size: AvatarSize;
  className?: string;
  badge?: string;
  watermark?: IconSvg;
  watermarkDisplay?: AvatarWatermarkDisplay;
  onClick?: () => void | undefined;
} & TestableElement;

const getVariant = ({imgUrl, text}: Partial<AvatarProps>): AvatarVariant => {
  if (imgUrl !== undefined && imgUrl.length) {
    return AvatarVariant.IMAGE;
  }
  if (text !== undefined && text.length) {
    return AvatarVariant.TEXT;
  }
  return AvatarVariant.EMPTY_GREEN;
};

const getInitials = ({userName = '', userSurname = ''}: AvatarUserName): string => {
  const userNameArr = userName?.split(' ') || [''];

  const firstLetter = userNameArr.length > 1 ? userNameArr[0][0].toUpperCase() : userSurname[0]?.toUpperCase() || '';
  const secondLetter = userNameArr.length > 1 ? userNameArr[1][0]?.toUpperCase() : userName[0]?.toUpperCase() || '';

  return `${firstLetter || ''}${secondLetter || ''}`;
};

export const Avatar: FC<AvatarProps> = ({
  imgUrl = undefined,
  text = undefined,
  userName = undefined,
  userSurname = undefined,
  variant: _variant = null,
  size = AvatarSize.MD,
  className = undefined,
  badge = undefined,
  watermark = undefined,
  watermarkDisplay = AvatarWatermarkDisplay.ON_HOVER,
  onClick = undefined,
  testId = undefined,
}) => {
  const abbreviation: string = text || getInitials({userName, userSurname});

  const [isHover, setIsHover] = useState<boolean>(false);
  const [variant, setVariant] = useState<AvatarVariant>(_variant || getVariant({imgUrl, text: abbreviation}));

  const icon = () => {
    if (variant === AvatarVariant.EMPTY_GREEN) {
      return icons.emptyGreen;
    }
    if (variant === AvatarVariant.EMPTY_GREY) {
      return icons.emptyGrey;
    }
    return undefined;
  };

  useEffect(() => {
    setVariant(_variant || getVariant({imgUrl, text: abbreviation}));
  }, [_variant, imgUrl, text, userName, userSurname]);

  const handleImageLoadError = () => {
    if (variant === AvatarVariant.IMAGE) {
      setVariant(getVariant({text: abbreviation}));
    }
  };

  return (
    <span
      data-test-element="avatar"
      data-testid={testId}
      className={clsx('inline-flex relative', {'cursor-pointer': !!onClick})}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClick && onClick()}
    >
      {watermark &&
        (watermarkDisplay === AvatarWatermarkDisplay.ALWAYS ||
          (watermarkDisplay === AvatarWatermarkDisplay.ON_HOVER && isHover)) && (
          <>
            <div className="rounded-full bg-white opacity-70 absolute top-0 left-0 right-0 bottom-0"> </div>
            <Icon
              svg={watermark}
              size={size === AvatarSize.MD ? IconSize.LG : IconSize.MD}
              color={IconColor.GREY_800}
              className={clsx({
                absolute: true,
                'top-[calc(50%-10px)] left-[calc(50%-10px)]': size === AvatarSize.SM,
                'top-[calc(50%-12px)] left-[calc(50%-12px)]': size === AvatarSize.MD,
              })}
            />
          </>
        )}
      {badge && (
        <Badge
          className={clsx('absolute', {
            'left-5 -top-1': size === AvatarSize.SM,
            'left-6 -top-2': size === AvatarSize.MD,
          })}
          text={badge}
          size={size === AvatarSize.MD ? BadgeSize.MD : BadgeSize.SM}
        />
      )}
      <span
        className={twMerge(
          clsx(
            'inline-flex items-center justify-center font-quicksand text-primary-700 font-bold overflow-hidden rounded-full box-border border-2 border-solid border-white bg-primary-100',
            {
              'w-[40px] h-[40px] text-sm': size === AvatarSize.MD,
              'w-[32px] h-[32px] text-xs': size === AvatarSize.SM,
            },
            className,
          ),
        )}
      >
        {variant !== AvatarVariant.TEXT && (
          <Img
            className="w-full h-full object-cover"
            src={(variant === AvatarVariant.IMAGE && imgUrl) || icon() || ''}
            alt="icon"
            onError={handleImageLoadError}
          />
        )}
        {variant === AvatarVariant.TEXT && <span>{abbreviation}</span>}
      </span>
    </span>
  );
};
