import {FC, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '../../../../locales/translationKeys';
import {DialogModal} from '@symfonia/symfonia-ksef-components';

export interface DisconnectWithKSeFTokenModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DisconnectWithKSeFTokenModal: FC<DisconnectWithKSeFTokenModalProps> = ({open, onCancel, onConfirm}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };
  const intl = useIntl();
  return (
    <DialogModal 
      onCancel={onCancel} 
      open={open}
      title={intl.formatMessage({id: Tr.disconnectWithKSeFTitle})}
      cancelButton={{text: intl.formatMessage({id: Tr.cancelButton}), onClick: onCancel, disabled: loading}}
      loadingButton={{text: intl.formatMessage({id: Tr.continue}), onClick: handleConfirm, loading: loading}}
      >
        <FormattedMessage id={Tr.disconnectWithKSeFContent} values={{br: <br/>}}/>
    </DialogModal>
  );
};
