import {FC, PropsWithChildren} from 'react';
import Drawer from '@mui/material/Drawer';
import {
  ButtonSecondary,
  ButtonSecondarySize,
  ButtonSecondaryWidth,
  ButtonTertiary,
  ButtonTertiarySize,
  Separator,
  SeparatorSize,
} from '@symfonia/brandbook';
import {
  LoadingButton,
  LoadingButtonSize,
  LoadingButtonVariant,
  LoadingButtonWidth,
} from '@symfonia/symfonia-ksef-components';

export interface FiltersDrawerProps extends PropsWithChildren {
  isFiltersMenuOpened: boolean;
  openFiltersMenu: (isFiltersMenuOpened: boolean) => void;
  handleFilter: () => void;
  clearFilters: () => void;
  tableLabel: string;
  filtersTouched?: boolean;
  filtersModified?: boolean;
  clearButtonLabel: string;
  closeLabel: string;
  filterLabel: string;
  hasError?: boolean;
  onClose: () => void;
  hidden?: boolean;
  loading?: boolean;
}

export const FiltersDrawer: FC<FiltersDrawerProps> = ({
                                                        children,
                                                        tableLabel,
                                                        filtersTouched,
                                                        filtersModified,
                                                        clearButtonLabel,
                                                        closeLabel, filterLabel,
                                                        isFiltersMenuOpened,
                                                        openFiltersMenu,
                                                        handleFilter,
                                                        clearFilters,
                                                        hasError,
                                                        onClose,
                                                        hidden,
                                                        loading,
                                                      }) => {
  if (hidden) {
    return null;
  }

  return <Drawer
    anchor="left"
    open={isFiltersMenuOpened}
    onClose={() => {
      openFiltersMenu(!isFiltersMenuOpened);
      onClose();
    }}>
    <div className="drawer p-6 w-80">
      <div className="centered-flex mb-3">
        <h1 className={'text-xl font-normal'}>{tableLabel}</h1>
        <ButtonTertiary
          disabled={!filtersTouched}
          text={clearButtonLabel}
          size={ButtonTertiarySize.SM}
          onClick={clearFilters}
          testId="filtersDrawerClearFiltersButton"
        />
      </div>
      {children}
      <Separator size={SeparatorSize.SM}/>
    </div>
    <div className="flex flex-col px-6">
      <Separator size={SeparatorSize.SM} className={'px-6'}/>
      <div className={'my-1 mx-auto w-full flex justify-around py-3'}>
        <ButtonSecondary
          text={closeLabel}
          width={ButtonSecondaryWidth.FULL}
          size={ButtonSecondarySize.SM}
          onClick={() => {
            openFiltersMenu(!isFiltersMenuOpened);
            onClose();
          }}
          testId="filtersDrawerCloseButton"
        />
        <LoadingButton
          text={filterLabel}
          className="ml-4"
          size={LoadingButtonSize.SM}
          width={LoadingButtonWidth.FULL}
          variant={filtersModified ? LoadingButtonVariant.GREEN : LoadingButtonVariant.RED}
          disabled={!filtersModified || hasError}
          loading={loading}
          onClick={() => handleFilter()}
          testId="filtersDrawerFiltersSubmitButton"
        />
      </div>
    </div>
  </Drawer>;
};
