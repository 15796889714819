import {FC, ReactElement, useMemo} from 'react';
import {ButtonPrimary, ButtonPrimaryWidth, ButtonSecondary, ButtonSecondaryWidth, Modal} from '@symfonia/brandbook';
import {getModalMaxWidth} from '../../../helpers/windowSizeHelper';
import clsx from 'clsx';

export interface ConfirmModalProps {
  contentClassName?: string | undefined;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: string;
  content: string | ReactElement;
  confirmButtonName: string;
  cancelButtonName: string;
  testId?: string;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
                                                      contentClassName,
                                                      onConfirm,
                                                      onCancel,
                                                      title,
                                                      content,
                                                      open,
                                                      cancelButtonName,
                                                      confirmButtonName,
                                                      testId = undefined,
                                                    }) => {

  const modalContentSize = useMemo<string>(() => {
    return getModalMaxWidth();
  }, []);

  return (
    <Modal onClose={onCancel}
      testId={testId}
      centerButtons={true}
      show={open}
      title={title}
      className={clsx(contentClassName, modalContentSize)}
      >
        {content}
      <div className="flex flex-row justify-center gap-[16px] mt-[30px] w-full">
        <ButtonSecondary
          text={cancelButtonName}
          onClick={onCancel}
          width={ButtonSecondaryWidth.FULL}
        />
        <ButtonPrimary
          text={confirmButtonName}
          onClick={onConfirm}
          width={ButtonPrimaryWidth.FULL}
        />
      </div>
      </Modal>
  );
};
