import React, {FC, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Breadcrumbs, Icon, IconColor, IconSize, IconSvg, Tabs, TooltipPosition} from '@symfonia/brandbook';
import {Header} from '../../../../../../../root/components/Header';
import {useModule} from '../../../../../../../root/services/MobXServices/BaseModule';
import {SettlementsTable} from './SettlementsTable/SettlementsTable';
import {SettlementsPageService} from './SettlementsPage.service';
import {ISettlementsState} from './State/ISettlementsState';
// import Tabs from '@mui/material/Tabs';
import {InvoiceTypeGroupEnum, RequiredModulesToFillEnum} from '@symfonia-ksef/graphql';
import {FinancialYearSelect} from '../components/FinancialYearSelect';
import {PostingConfigurationActions} from '../components/PostingConfigurationActions';
import {usePostingConfigurationStatus} from '../../../../../../hooks/usePostingConfigurationStatus';
import {earchiveStateMobx} from '../../../../../../EArchiveModule';
import {observer} from 'mobx-react-lite';
import {IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {usePageTitle} from '../../../../../../../common';

export interface SettlementsPageProps {
  state: ISettlementsState;
}

export const SettlementsPage: FC<SettlementsPageProps> = observer(({state}) => {
  const {setPageTitle} = usePageTitle();
  const intl = useIntl();

  const {name} = earchiveState.company;
  const settlementsService = useModule(() => new SettlementsPageService(state));
  const {getRequiredConfigurationStatus, getRequiredConfigurationStatusForFinancialYearId} =
    usePostingConfigurationStatus();

  const findSettlementsModules = (type: RequiredModulesToFillEnum): boolean => {
    return earchiveStateMobx.postingState.requiredModules.includes(type);
  };

  const [isRequiredConfiguration, setIsRequiredConfiguration] = useState<boolean>(
    findSettlementsModules(RequiredModulesToFillEnum.SettlementPurchase) ||
      findSettlementsModules(RequiredModulesToFillEnum.SettlementSales),
  );
  const [activeTab, setActiveTab] = useState(state.postingConfigurationState.invoiceTypeGroup);

  const handleTabChange = async (newValue: InvoiceTypeGroupEnum) => {
    setActiveTab(newValue);
    state.postingConfigurationState.setInvoiceTypeGroup(newValue);
    state.resetState();

    await settlementsService.tableService.fetch();
  };

  const handleYearChange = async (year: string) => {
    state.postingConfigurationState.setSelectedFinancialYear(year);

    await settlementsService.tableService.fetch();
  };

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.settlements}),
    },
  ];

  const isElementsOnTabRequired = (value: RequiredModulesToFillEnum) => {
    return findSettlementsModules(value) ? 'filter-red-400' : '';
  };

  const handleSave = async () => {
    await state.saveSettlements();
    getRequiredConfigurationStatus();
    await settlementsService.tableService.fetch();
    if (state.postingConfigurationState.isFinancialYearActive) getRequiredConfigurationStatusForFinancialYearId();
  };

  setPageTitle(intl.formatMessage({id: Tr.settlements}));

  return (
    <>
      {/*PostingConfigurationHeader*/}
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px] flex-wrap'}/>
        <div className="flex justify-between w-full md:flex-nowrap flex-wrap">
          <div className="flex items-start">
            <Header className={'flex items-start'}>
              {intl.formatMessage({id: Tr.settlements})}
              <IconWithTooltip
                tooltipWidth="auto"
                tooltipClass={'w-[800px]'}
                icon={{icon: IconSvg.HELP_OUTLINE, color: IconColor.GREEN_500}}
                btnClass={'flex items-start ml-[10px]'}
                tooltipPosition={TooltipPosition.RIGHT}
                tooltipContent={
                  <>
                    <h1 className={'mb-[10px] font-bold'}>{intl.formatMessage({id: Tr.whatIConfigure})}</h1>
                    <p>
                      {intl.formatMessage(
                        {id: Tr.settlementsTxt},
                        {
                          nameCompany: <span className={'font-bold'}>{name}</span>,
                        },
                      )}
                    </p>
                  </>
                }
              />
              {isRequiredConfiguration && (
                <Icon
                  color={IconColor.RED_400}
                  className="ml-2 left-[10px]"
                  svg={IconSvg.ERROR_OUTLINE}
                  size={IconSize.LG}
                />
              )}
            </Header>
          </div>

          <div className="flex items-end">
            <FinancialYearSelect setSelectedFinancialYear={handleYearChange} state={state.postingConfigurationState}/>
          </div>
        </div>

        {/*PageContent*/}
        <main className="managmentPage">
          <Tabs
            className={'mb-[20px]'}
            fullWidthUnderline={true}
            tabs={[
              {
                text: intl.formatMessage({id: Tr.PURCHASE}),
                isActive: activeTab === InvoiceTypeGroupEnum.Purchase,
                onClick: () => handleTabChange(InvoiceTypeGroupEnum.Purchase),
              },
              {
                text: intl.formatMessage({id: Tr.SALES}),
                isActive: activeTab === InvoiceTypeGroupEnum.Sales,
                onClick: () => handleTabChange(InvoiceTypeGroupEnum.Sales),
              },
            ]}
          />
          <SettlementsTable state={state} pageService={settlementsService}/>
        </main>

        {/*PostingConfigurationActions*/}
        <PostingConfigurationActions
          onPrimaryButtonClick={handleSave}
          onSecondaryButtonClick={() => state.resetState()}
          primaryButtonText={intl.formatMessage({id: Tr.save})}
          secondaryButtonText={intl.formatMessage({id: Tr.cancelButton})}
          primaryButtonDisabled={!state.isModified}
          secondaryButtonDisabled={!state.isModified}
          buttonFixed={true}
        />
      </div>
    </>
  );
});
