import {Route, Routes} from 'react-router-dom';
import {PostingInitialization} from './PostingInitialization';
import {IPostingState} from './state/IPostingState';
import {observer} from 'mobx-react-lite';
import {PostingPage} from './pages/PostingTablePage/PostingPage';
import {PostingDetails} from './pages/PostingDetails/PostingDetails';
import {PostingNotConfiguredPage} from './pages/PostingTablePage/PostingNotConfiguredPage';

export interface PostingProps {
  state: IPostingState;
}

export const Posting = observer(({state}: PostingProps) => {

  return (
    <Routes>
      <Route path="/*" element={state.earchiveState.postingState.isPostingConfigured
        ? <PostingPage state={state}/>
        : <PostingNotConfiguredPage/>}/>
      <Route
        path=":autopostingId"
        element={
          <PostingInitialization state={state}>
            <PostingDetails state={state}/>
          </PostingInitialization>
        }
      />
    </Routes>
  );
});
export default Posting;
