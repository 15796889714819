import {AccountTypeEnum, CategoryViewModel, AccountViewModel} from '@symfonia-ksef/graphql';
import {IPostingAccountModalState} from '../../../../../modals/state/IPostingAccountModalState';
import {ICategoryAccountState} from './ICategoryAccountState';
import {ICategoryAccountTableState} from './ICategoryAccountTableState';
import {PostingAccountModalState} from '../../../../../modals/state/PostingAccountModalState';
import {IObservableArray, action, autorun, computed, makeObservable, observable} from 'mobx';
import {TreeViewDropdownModel} from '../../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';

export class CategoryAccountTableState implements ICategoryAccountTableState {
  @observable
  public data: IObservableArray<CategoryViewModel> = observable([]);

  @observable
  public dataWithoutFilters: IObservableArray<CategoryViewModel> = observable([]);

  @observable
  public refreshCount = 0;

  @observable
  public refreshCountNoQuery = 0;

  @observable
  private shouldRefreshDataWithoutFilters: boolean = true;

  @computed
  get initAccountCount(): number {
    return this.dataWithoutFilters.map(x => x.Accounts.length).reduce((sum, current) => sum + current, 0);
  }

  @observable
  public isPostingAccountModalOpen: boolean = false;

  private activeCategoryId: string | undefined;

  private activeAccountType: AccountTypeEnum | undefined;

  public categoryAccountState: ICategoryAccountState;

  private _postingAccountModalState: IPostingAccountModalState | undefined;

  public get postingAccountModalState() {
    if (this._postingAccountModalState === undefined) {
      this._postingAccountModalState = new PostingAccountModalState();
    }
    return this._postingAccountModalState;
  }

  constructor(categoryAccountState: ICategoryAccountState) {
    makeObservable(this);

    this.categoryAccountState = categoryAccountState;

    autorun(() => {
      if (
        this.isPostingAccountModalOpen &&
        this.categoryAccountState.isLoading === false &&
        this.activeAccountType !== undefined &&
        this.categoryAccountState.categoryAccountStore.dropdownAccountsAll.length > 0
      ) {
        this.pushAccountsToPostingAccountModal(this.activeAccountType);
      }
    });
  }

  @action
  public setData(data: CategoryViewModel[]) {
    this.data.replace(data);
  }

  @action
  public setDataWithoutFilters(data: CategoryViewModel[]) {
    if (this.shouldRefreshDataWithoutFilters) {
      this.dataWithoutFilters.replace(data);
      this.shouldRefreshDataWithoutFilters = false;
    }
  }

  @action
  public triggerTableRefresh() {
    this.refreshCount = this.refreshCount + 1;
  }

  @action
  private triggerTableRefreshNoQuery() {
    this.refreshCountNoQuery = this.refreshCountNoQuery + 1;
  }

  @action
  public triggerRefreshDataWithoutFilters(): void {
    this.shouldRefreshDataWithoutFilters = true;
  }

  @action
  public handleAccountInputClick(categoryId: string, accountId: string, accountType: AccountTypeEnum): void {
    this.openPostingAccountModal(accountId);
    this.activeCategoryId = categoryId;
    this.activeAccountType = accountType;
  }

  @action
  public handleAccountInputClear(categoryId: any, accountType: AccountTypeEnum): void {
    this.categoryAccountState.updateCategoryAccountChangedArray(undefined, categoryId, accountType);
    this.clearDataRow(categoryId, accountType);
  }

  @action
  public handlePostingAccountModalSelect(selected: TreeViewDropdownModel): void {
    if (this.activeCategoryId === undefined) throw new Error('Cannot save changes because categoryId is undefined');
    if (this.activeAccountType === undefined) throw new Error('Cannot save changes because accountType is undefined');

    this.categoryAccountState.updateCategoryAccountChangedArray(
      selected.key,
      this.activeCategoryId,
      this.activeAccountType,
    );
    this.updateDataRow(this.activeCategoryId, selected.key, this.activeAccountType);
    this.closePostingAccountModal();
  }

  @action
  public closePostingAccountModal(): void {
    this.postingAccountModalState.handleClose();
    this.isPostingAccountModalOpen = false;
    this._postingAccountModalState = undefined;
    this.activeCategoryId = undefined;
    this.activeAccountType = undefined;
  }

  @action
  private openPostingAccountModal(accountId: string): void {
    this.isPostingAccountModalOpen = true;
    this.postingAccountModalState.handleOpen();
    this.postingAccountModalState.searchTreeViewState.setInitialSelected(accountId);
  }

  @action
  private pushAccountsToPostingAccountModal(accountType: AccountTypeEnum): void {
    let accounts: TreeViewDropdownModel[];
    switch (accountType) {
      case AccountTypeEnum.Kup:
        accounts = this.categoryAccountState.categoryAccountStore.dropdownAccountsKUP;
        break;
      case AccountTypeEnum.Nkup:
        accounts = this.categoryAccountState.categoryAccountStore.dropdownAccountsNKUP;
        break;
      default:
        throw new Error(`AccountType ${accountType} is not supported`);
    }
    this.postingAccountModalState.searchTreeViewState.setNodes(accounts);
  }

  @action
  private updateDataRow(categoryId: string, accountId: string, accountType: AccountTypeEnum): void {
    const row = this.data.find(x => x.Id === categoryId);
    if (row === undefined) throw new Error('Cannot find row by categoryId: ' + categoryId);
    const assignedAccount = row?.Accounts.find(x => x.AccountType === accountType);

    //If account is already used by any category reuse that object
    const storedAccount = this.categoryAccountState.categoryAccountStore.accountsInUse.find(x => x.Id === accountId);
    if (storedAccount !== undefined) {
      if (assignedAccount) {
        const index = row.Accounts.findIndex(x => x.AccountType === accountType);
        if (index >= 0) row.Accounts[index] = storedAccount;
      } else {
        row?.Accounts.push(storedAccount);
      }
      return this.triggerTableRefreshNoQuery();
    }

    //Create temporary account object to be used in row
    const dropdownAccount = this.categoryAccountState.categoryAccountStore.dropdownAccountsAll.find(
      x => x.key === accountId,
    );
    if (dropdownAccount !== undefined) {
      const name = dropdownAccount.value.split('-').slice(-1).join('').trim();
      const number = dropdownAccount.value.split('-').slice(0, -1).join('-').trim();
      const temporaryAccount: AccountViewModel = {
        AccountType: accountType,
        Categories: [],
        Children: [],
        CompanyId: undefined,
        FinancialYearId: undefined,
        Id: dropdownAccount?.key,
        IsDisabled: false,
        IsFinal: false,
        Name: name,
        Number: number,
        Settlements: [],
      };
      if (assignedAccount) {
        const index = row?.Accounts.findIndex(x => x.AccountType === accountType);
        if (index >= 0) row!.Accounts[index] = temporaryAccount;
      } else {
        row?.Accounts.push(temporaryAccount);
      }
    }

    return this.triggerTableRefreshNoQuery();
  }

  @action
  private clearDataRow(categoryId: string, accountType: AccountTypeEnum): void {
    const row = this.data.find(x => x.Id === categoryId);
    if (row === undefined) throw new Error('Cannot find row');
    row.Accounts = row?.Accounts.filter(x => x.AccountType !== accountType);
    return this.triggerTableRefreshNoQuery();
  }
}
