import {EventJobRunner} from '../EventJobRunner';
import {
  AuthorizeInKSeFDocument,
  AuthorizeInKSeFMutation,
  AuthorizeInKSeFMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {intl} from '../../modules/root/IntlProvider';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {AppRoutes} from '../../modules/root/Routes';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class AuthorizeInKSeFJobRunner extends EventJobRunner<AuthorizeInKSeFMutationVariables, keyof AuthorizeInKSeFMutation> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('AuthorizeInKSeF', WebsocketNotificationType.AuthorizeInKsef, AuthorizeInKSeFDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => {
          return {
            notification: intl.formatMessage({id: Tr.authSubSignatureInProgress}, {
              link: React.createElement(NavLink, {
                children: intl.formatMessage({id: Tr.authentication}),
                to: AppRoutes.eArchive.address + '/' + this.envObserver.currentEnv.tenantId + '/company/' + this.envObserver.currentEnv.companyId + '/account/authentication',
              }),
            }),
          };
        },
        error: (errors, errorMessage) => ({
          id: Tr.authSubSignatureError,
        }),
      },
    );
  }
}
