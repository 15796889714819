import {computed, makeObservable, override} from 'mobx';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  GetInvoicesTotalCountDocument,
  GetInvoicesTotalCountQuery,
  GetInvoicesTotalCountQueryVariables,
  InvoiceBound,
} from '@symfonia-ksef/graphql';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {GraphQLErrorWithMessage} from '../../../../root/providers/GraphQLProvider';
import {Repository} from '../../../../root/services/MobXServices/Repository/Repository';
import {
  IDownloadedInvoicesResultService,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadedInvoicesResultService';
import {
  InvoicesDownloadCheckerI,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressTracker';

export class DownloadInvoicesButtonState extends Repository<keyof GetInvoicesTotalCountQuery, GetInvoicesTotalCountQuery, GetInvoicesTotalCountQueryVariables> {
  constructor(private readonly invoicesResultHandler: IDownloadedInvoicesResultService, private readonly downloadInvoicesChecker: InvoicesDownloadCheckerI) {
    super('PurchaseCount', GetInvoicesTotalCountDocument, envObserver, earchiveState);
    makeObservable(this);
  }

  @computed
  get isPackageNotActive(): boolean {
    return earchiveState.packageStatistics.isPackageNotActive;
  }


  @computed
  get actions() {
    return [
      {
        title: intl.formatMessage({id: Tr.downloadFromKsef}),
        onClick: async () => {
          if (await this.downloadInvoicesChecker.checkDownloadInvoicesIsRunning()) {
            return;
          }
          await this.fetch();
        },
      },
    ];
  }

  @override
  public override get loading(): boolean {
    return super.loading || this.invoicesResultHandler.loading || this.isPending || this.downloadInvoicesChecker.checkerIsLoading || this.downloadInvoicesChecker.isActive;
  }

  @computed
  get isPending(): boolean {
    return this.invoicesResultHandler.jobRunner.isPending;
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }

  protected override onSuccess(data: GetInvoicesTotalCountQuery[keyof GetInvoicesTotalCountQuery], wholeData: GetInvoicesTotalCountQuery): void | Promise<void> {
    const salesCount = wholeData.SellCount.TotalCount;
    const purchaseCount = wholeData.PurchaseCount.TotalCount;
    this.invoicesResultHandler.setCount(InvoiceBound.External, purchaseCount ?? 0);
    this.invoicesResultHandler.setCount(InvoiceBound.Internal, salesCount ?? 0);
  }

  protected override afterFetch(data: GetInvoicesTotalCountQuery[keyof GetInvoicesTotalCountQuery] | null, wholeData: GetInvoicesTotalCountQuery | null, forced: boolean): void | Promise<void> {
    this.invoicesResultHandler.resetDates();
    this.invoicesResultHandler.setOpen();
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    this.invoicesResultHandler.setCount(InvoiceBound.External, 0);
    this.invoicesResultHandler.setCount(InvoiceBound.Internal, 0);
  }


}
