import * as React from 'react';
import {FC} from 'react';
import {ButtonPrimary, ButtonSecondary, Tooltip} from '@symfonia/brandbook';

interface PostingConfigurationActionsProps {
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;

  primaryButtonText: string;
  secondaryButtonText: string;

  primaryButtonDisabled: boolean;
  secondaryButtonDisabled: boolean;

  primaryButtonWithTooltip?: boolean;
  primaryButtonTooltipOpen?: boolean;
  primaryButtonTooltipText?: React.ReactNode;
  buttonFixed?: boolean;
}

export const PostingConfigurationActions: FC<PostingConfigurationActionsProps> = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonDisabled,
  secondaryButtonDisabled,
  primaryButtonWithTooltip,
  primaryButtonTooltipOpen,
  primaryButtonTooltipText,
  buttonFixed,
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(open => !open);
  };

  return (
    <div className={'flex justify-end mb-[20px] bg-transparent h-full' + (buttonFixed ? ' buttonFixedWithMargin' : '')}>
      <ButtonSecondary
        disabled={secondaryButtonDisabled}
        onClick={() => onSecondaryButtonClick()}
        text={secondaryButtonText}
        className="bg-white"
      />
      {!primaryButtonWithTooltip && (
        <ButtonPrimary
          disabled={primaryButtonDisabled}
          onClick={() => onPrimaryButtonClick()}
          text={primaryButtonText}
          className={'ml-[10px]'}
        />
      )}
      {primaryButtonWithTooltip && (
        <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipOpen}>
          <Tooltip open={tooltipOpen && primaryButtonTooltipOpen} text={primaryButtonTooltipText}>
            <div>
              <ButtonPrimary
                disabled={primaryButtonDisabled}
                onClick={() => onPrimaryButtonClick()}
                text={primaryButtonText}
                className={'ml-[10px]'}
              />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
