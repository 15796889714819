import React, {FC, PropsWithChildren} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';

const NotAuthorizedInKSeF: FC<PropsWithChildren> = observer(() => {
  const {company: {currentEnvironment}} = earchiveState;
  if (!currentEnvironment) {
    return <>No selected company</>;
  }
  const intl = useIntl();
  const companyName = currentEnvironment.Company.Name;
  return (
    <p className={'font-quicksand text-base font-medium'}>
      {intl.formatMessage({id: Tr.authorizedKSeFPermissionPageDesc}, {
        companyName: <b>{companyName || ''}</b>,
        br: <br/>,
      })}
    </p>
  );
});

export default NotAuthorizedInKSeF;
