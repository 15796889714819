import {PageMessage} from '../../common/components/PageMessage/PageMessage';
import {useIntl} from 'react-intl';
import {Tr} from '../../../locales/translationKeys';

export const AccessDenied = () => {
  const intl = useIntl();

  return (
    <PageMessage
      title={intl.formatMessage({id: Tr.accessDeniedDesktopTitle})}
      description={intl.formatMessage({id: Tr.accessDeniedDesktopDescription})}
    />
  );
};
