import {FC} from 'react';
import {Togglable, TogglableProps} from '../../internal/components/Togglable/Togglable';
import {getAppearanceInputStyles, RadioButtonAppearance} from './components/RadioButtonAppearance';

export type RadioButtonProps = Omit<
  TogglableProps,
  'appearance' | 'getAppearanceInputStyles' | 'isRadio' | 'testElement'
>;

export const RadioButton: FC<RadioButtonProps> = ({...props}) => (
  <Togglable
    {...props}
    isRadio
    testElement="radio-button"
    appearance={<RadioButtonAppearance />}
    getAppearanceInputStyles={getAppearanceInputStyles}
  />
);
