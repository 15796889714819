import {ErrorEventAdapter, ErrorEventAdapterI, ErrorEventMapperI} from './ErrorEventAdapter';
import {GraphQLErrorWithMessage} from '../../../../modules/root/providers/GraphQLProvider';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {ErrorContent} from '../../../SubscriptionEventsService';
import {GraphQLErrorEventDefaultMapper} from './GraphQLErrorEventMappers/GraphQLErrorEventDefaultMapper';

export type GraphQLErrorParsed = GraphQLErrorWithMessage & { ParsedResponse?: ErrorContent }

export class GraphQLErrorEventAdaptersManager {

  constructor(private MapperClass: typeof GraphQLErrorEventDefaultMapper) {

  }

  public createAdapter(error: GraphQLErrorWithMessage): ErrorEventAdapterI {
    const parsedError = this.parse(error);
    return new ErrorEventAdapter<GraphQLErrorParsed>(parsedError, this.createMapper(parsedError));
  }

  private createMapper(error: GraphQLErrorParsed): ErrorEventMapperI<GraphQLErrorParsed> | undefined {
    const {code: type} = error.extensions;
    return type ? new this.MapperClass(type as WebsocketErrorType) : undefined;
  }

  private parse(error: GraphQLErrorWithMessage): GraphQLErrorParsed {
    const responseJson = error.extensions.data?.KSeFErrorJsonResponse;
    if (!responseJson) {
      return error;
    }
    try {
      const ParsedResponse = JSON.parse(responseJson) as ErrorContent;
      return {...error, ParsedResponse};
    } catch (err) {
      return error;
    }
  }
}

export const graphqlErrorEventAdapterManager = new GraphQLErrorEventAdaptersManager(GraphQLErrorEventDefaultMapper);
