import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {TreeViewDropdownModel} from '../../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {PostingChooseAccountModal} from '../../../../../modals/PostingChooseAccountModal';
import {observer} from 'mobx-react-lite';
import {SettlementTypeEnum} from '@symfonia-ksef/graphql';
import {ISettlementsState} from '../../State/ISettlementsState';
import {intl} from '../../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {UnderlineInputButtonWithClear} from '../../../../../../../../../auto-posting/components/UnderlineInputButton/UnderlineInputButton';
import {Tooltip, TooltipPosition} from '@symfonia/brandbook';

interface PostingSettlementTreeDropdownProps {
  accountId: string;
  accountName: string;
  settlementId: string;
  settlementType: SettlementTypeEnum;
  state: ISettlementsState;
  disabledDeleteRow: boolean;
}

export const SettlementAccountInput: FC<PostingSettlementTreeDropdownProps> = observer(
  ({accountId, accountName, settlementId, settlementType, state, disabledDeleteRow}) => {
    const [account, setAccount] = useState<string | null>(accountId ?? '');
    const [nameNumber, setNameNumber] = React.useState<string>('');
    const [open, setOpen] = React.useState<boolean>(false);
    const hasAccount = account !== null;

    useEffect(() => {
      const categoryAccountChanged = state.accountSettlementChangedArray.find(a => a.SettlementId === settlementId);
      state.setSettlementType(settlementType);
      setAccount(categoryAccountChanged !== undefined ? categoryAccountChanged.AccountId : accountId);
      setNameNumber(accountName);
    }, [state.accountSettlementChangedArray]);

    const handleSelect = (nodeIds: TreeViewDropdownModel) => {
      setAccount(nodeIds.key);
      setNameNumber(nodeIds.value);
      state.updateAccountSettlementChangedArray(settlementId, nodeIds.key);
      state.setCanBeSaved();
      state.onPostingAccountModalClose();
      setOpen(false);
    };

    const handleOpen = (event: React.MouseEvent) => {
      if (!state.isEditable) return;
      if (settlementType === SettlementTypeEnum.Vat) state.openPostingAccountModal(accountId, state.ddlAccountsVAT);
      else state.openPostingAccountModal(accountId, state.ddlAccountsMa);
      setOpen(true);
      event.stopPropagation();
    };

    const handleRemove = (event: React.MouseEvent) => {
      if (!state.isEditable) return;
      state.updateAccountSettlementChangedArray(settlementId, undefined);
      setNameNumber(intl.formatMessage({id: Tr.postringConfiguration_no_assignment}));
      state.setCanBeSaved();
      setAccount(null);
      event.stopPropagation();
    };

    return (
      <>
        {open && state.postingAccountModalState && state.postingAccountModalState.isOpen && (
          <PostingChooseAccountModal
            state={state.postingAccountModalState}
            onClose={() => state.onPostingAccountModalClose()}
            onSelected={handleSelect}
          />
        )}
        <Tooltip text={nameNumber} position={TooltipPosition.LEFT}>
          <div className={'mr-2'}>
            <UnderlineInputButtonWithClear
              disabled={!state.isEditable}
              disabledClearButton={!state.isEditable || disabledDeleteRow || !hasAccount}
              value={nameNumber}
              onClick={event => handleOpen(event)}
              handleClear={event => handleRemove(event)}
              fullWidth={true}
            />
          </div>
        </Tooltip>
      </>
    );
  },
);
