export enum GroupedActionTypeEnum {
  sendToKSeF = 'sendToKSeF',
  getUpoPdf = 'getUpoPdf',
  getUpoXml = 'getUpoXml',
  exportToSymfoniaTrade = 'exportToSymfoniaTrade',
  exportToSymfoniaFinancesAndAccountancy = 'exportToSymfoniaFinancesAndAccountancy',
  publishToSymfoniaFinancesAndAccountancy = 'publishToSymfoniaFinancesAndAccountancy',
  post = 'post',
  downloadInvoicesZip = 'downloadInvoicesZip',
  deleteInvoices = 'deleteInvoices',
  whiteListCheck = 'whiteListCheck'
}
