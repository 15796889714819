import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';

import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../SubscriptionEventsService';
import {Tr} from '../../locales/translationKeys';
import {deleteInvoiceService} from '../../state/rootRepository';
import {AlertConfig} from '../helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';


export class DeleteInvoicesEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {

  constructor() {
    super(WebsocketNotificationType.DeleteInvoices, deleteInvoiceService, {
      error: () => !deleteInvoiceService.currentResult?.errorItems ? this.createSuccessAlert({
        color: ToastVariant.SUCCESS,
        duration: 8000,
      }) : this.createErrorAlert(),
      success: () => deleteInvoiceService.currentResult?.deleted ? this.createSuccessAlert() : this.createErrorAlert({
        color: ToastVariant.SUCCESS,
        duration: undefined,
      }),
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await deleteInvoiceService.getAsyncData(event));
    const {currentResult} = deleteInvoiceService;
    if (deleteInvoiceService.currentResult?.errorItems?.length) {
      deleteInvoiceService.setModalIsActive(true);
    }
  }

  private createErrorAlert(additionalConfig?: AlertConfig): AlertConfig {
    return {id: Tr.deleteInvoicesError, ...additionalConfig};
  }

  private createSuccessAlert(additionalConfig?: AlertConfig): AlertConfig {
    if (deleteInvoiceService.currentResult?.deleted === 1 && deleteInvoiceService.currentResult?.invoicesId.length === 1) {
      return {id: Tr.deleteInvoiceSuccess, ...additionalConfig};
    }
    if (deleteInvoiceService.currentResult?.deleted === deleteInvoiceService.currentResult?.invoicesId.length) {
      return {id: Tr.deleteInvoicesSuccess, ...additionalConfig};
    }
    return {
      id: Tr.deleteInvoicesPartialSuccess, ...additionalConfig,
      values: {count: deleteInvoiceService.currentResult?.deleted},
    };
  }
}
