import {Repository} from '../../modules/root/services/MobXServices/Repository/Repository';
import {KSeFSessionInfoDocument, KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables} from '@symfonia-ksef/graphql';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {ExternalExceptionService} from '../../services/ExternalExceptionService/ExternalExceptionService';
import {action, computed, makeObservable, observable} from 'mobx';
import {EXTENDED_IGNORE_ERRORS_PATTERNS} from '@symfonia-ksef/state/PackageStatisticsState/PackageStatisticsRepository';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class KsefSessionState extends Repository<'KSeFSessionInfo', KSeFSessionInfoQuery, KSeFSessionInfoQueryVariables> {

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('KSeFSessionInfo', KSeFSessionInfoDocument, envObserver, earchiveState);
    makeObservable(this);
  }

  @computed
  public get isActive(): boolean {
    return !!this.data?.Active;
  }

  @computed
  public get expirationTime() {
    return this.data?.ExpirationTime;
  }

  @observable.ref
  private _errorMessages: string[] = [];

  @computed
  public get errorMessages(): string[] {
    return this._errorMessages;
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    const messages: string[] = [];
    for (const error of errors) {
      const {
        message,
        data: errorData,
      } = error.extensions ?? {};

      if (errorData?.KSeFErrorJsonResponse) {
        const {parseException, matchJsonString} = new ExternalExceptionService(this.envObserver);

        let errorMessage = parseException(matchJsonString(errorData.KSeFErrorJsonResponse))?.exceptionDescription;
        errorMessage ??= message ?? errorData?.empty;
        if (!this.checkShouldIgnoreError(errorMessage) && errorMessage) {
          messages.push(errorMessage);
        }
      }
    }
    this.setErrorMessages(messages);
  }

  @action
  private setErrorMessages(messages: string[]): void {
    this._errorMessages = messages;
  }

  private checkShouldIgnoreError(message?: string): boolean {
    return EXTENDED_IGNORE_ERRORS_PATTERNS.some(pattern => message?.includes?.(pattern));
  }
}
