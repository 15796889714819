import {action, makeObservable, observable} from 'mobx';
import {Tr} from '@symfonia-ksef/locales/keys';
import {VehicleFilterState} from './VehicleFilterState';
import {
  CompanyVehiclesResponse,
  RemoveCompanyVehicleDocument,
  RemoveCompanyVehicleMutation,
  RemoveCompanyVehicleMutationVariables,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../../../../../root/providers/GraphQLProvider';
import {intl} from '../../../../../../../../root/IntlProvider';
import {IVehicleState} from './IVehicleState';
import {IPostingConfigurationState} from '../../IPostingConfigurationState';
import {IVehicleFilterState} from './IVehicleFilterState';
import {
  VehicleModalState,
} from '../../../../../../../../auto-posting/pages/PostingDetails/components/PostingDetailsList/state/VehicleModalState';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export enum VehicleModalType {
  EditVehicle,
  AddVehicle
}

export class VehicleState implements IVehicleState {

  @observable
  vehicles: CompanyVehiclesResponse[] = [];

  @observable
  modalTitle = Tr.addVehicle;

  @observable
  modalAddEditOpen = false;

  @observable
  modalType: VehicleModalType = VehicleModalType.AddVehicle;

  @observable
  modalDeleteOpen = false;

  @observable
  licencePlateError: Tr | undefined = undefined;

  @observable
  shouldRefresh = false;

  @observable
  isLoading = false;

  @observable
  selectedRowMenuAnchorEl: HTMLElement | undefined;

  @observable
  selectedRowMenuOpen = false;

  @observable
  error: string | null = null;

  @observable
  isModified: boolean = false;

  public filterState: IVehicleFilterState;
  public vehicleModalState: VehicleModalState;

  public postingConfigurationState: IPostingConfigurationState;

  constructor(postingConfigurationState: IPostingConfigurationState) {
    makeObservable(this);
    this.postingConfigurationState = postingConfigurationState;
    this.filterState = new VehicleFilterState(this);
    this.vehicleModalState = new VehicleModalState(this);
  }

  @action
  resetState(): void {
    throw new Error('Method not implemented.');
  }

  @action
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @action
  setShouldRefresh(p: boolean) {
    this.shouldRefresh = p;
  }

  @action
  setModalDeleteOpen(p: boolean) {
    this.modalDeleteOpen = p;
  }

  @action setVehicles(vehicles: CompanyVehiclesResponse[]) {
    this.vehicles = vehicles;
  }

  @action
  setSelectedRowMenuAnchorEl(selectedInvoiceMenuAnchorEl: HTMLElement | undefined) {
    this.selectedRowMenuAnchorEl = selectedInvoiceMenuAnchorEl;
  }

  @action
  setSelectedRowMenuOpen(selectedInvoiceMenuOpened: boolean) {
    this.selectedRowMenuOpen = selectedInvoiceMenuOpened;
  }

  @action
  public setVehicleToDelete() {
    this.setSelectedRowMenuAnchorEl(undefined);
    this.setSelectedRowMenuOpen(false);
    this.setModalDeleteOpen(true);
  }

  @action
  openPositionActionMenu(anchorRef?: HTMLElement, vehicle?: any) {
    this.vehicleModalState.setSelectedVehicle(vehicle);
    this.setSelectedRowMenuAnchorEl(anchorRef);
    this.setSelectedRowMenuOpen(true);
  }

  @action
  closePositionActionMenu() {
    this.setSelectedRowMenuAnchorEl(undefined);
    this.setSelectedRowMenuOpen(false);
  }


  async deleteVehicle(envId: string | undefined) {
    if (envId === null) {
      throw new Error('EnvironmentId has to be settled before load data for invoice preview');
    }

    this.setLoading(true);
    const registrationNumber = this.vehicleModalState.editedVehicle.RegistrationNumber;

    const {
      data,
      errors,
    } = await apolloClient.mutate<RemoveCompanyVehicleMutation, RemoveCompanyVehicleMutationVariables>({
      mutation: RemoveCompanyVehicleDocument,
      context: {
        envId,
      },
      variables: {
        Id: this.vehicleModalState.selectedVehicle!.Id,
      },
    });

    if (errors || !data?.RemoveCompanyVehicle?.IsDone) {
      this.setModalDeleteOpen(false);
      this.setError(Tr.updateVehicleFailed);
      earchiveState.alertsState.addAlert(intl.formatMessage(
        {id: Tr.deleteVehicleFailed},
        {registrationNumberPlate: registrationNumber}), ToastVariant.ERROR, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.setLoading(false);
      return;
    }

    if (data.RemoveCompanyVehicle.IsDone) {
      this.vehicleModalState.resetVehicle();
      this.setModalDeleteOpen(false);
      this.setError(null);
      this.setShouldRefresh(true);
      earchiveState.alertsState.addAlert(intl.formatMessage(
        {id: Tr.deleteVehicleSuccess},
        {registrationNumberPlate: registrationNumber}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    }

    this.setLoading(false);
  }

  @action.bound
  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @action.bound
  setError(error: string | null | undefined) {
    this.error = error ?? null;
  }
}
