import {FC} from 'react';
import {Togglable, TogglableProps} from '../../internal/components/Togglable/Togglable';
import {getAppearanceInputStyles, ToggleAppearance} from './components/ToggleAppearance';

export {TogglableSize as ToggleSize} from '../../internal/components/Togglable/Togglable';
export type ToggleProps = Omit<TogglableProps, 'appearance' | 'getAppearanceInputStyles' | 'isRadio' | 'testElement'>;

export const Toggle: FC<ToggleProps> = ({...props}) => (
  <Togglable
    {...props}
    isRadio={false}
    testElement="toggle"
    appearance={<ToggleAppearance />}
    getAppearanceInputStyles={getAppearanceInputStyles}
  />
);
