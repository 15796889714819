import {BaseEventConsumer} from '../EventConsumer';
import {KSeFAuthResult, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {EventParams} from '../SubscriptionEventsService';
import {
  authenticationState,
  authorizationInKSeFService,
  removeAuthorizationInKSeFService,
} from '../../state/rootRepository';


export class AuthorizeInKSeFEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.AuthorizeInKsef, authorizationInKSeFService, {
      success: {id: Tr.authSubSignatureSuccess},
      error: {id: Tr.authSubSignatureError},
    });
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    !event.errorType ? await authenticationState.updateUserAuthAndCredentials(KSeFAuthResult.Authorized) : authenticationState.setUnauthorized();
    event.type === 'AUTHORIZE_IN_KSEF' ? authorizationInKSeFService.setLoading(false) : removeAuthorizationInKSeFService.setLoading(false);
    await super.onUpdate(event);
  }
}
