import {ApolloClient, ApolloLink, ApolloProvider, from, HttpLink, InMemoryCache, split} from '@apollo/client';
import {environment} from '../../../environments/environment';
import {authorizationMiddleware, environmentIdMiddleware} from '../middlewares';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {Tr} from '@symfonia-ksef/locales/keys';
import {createIntl, createIntlCache, IntlShape} from 'react-intl';
import {pl} from '../../../locales';
import {FC, PropsWithChildren} from 'react';
import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from '@apollo/client/link/ws';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {GraphQLError, GraphQLErrorExtensions} from 'graphql/error/GraphQLError';
import {ToastVariant} from '@symfonia/brandbook';
import {AnyObject} from 'yup/es/types';
import {earchiveState, ksefEventsService} from '@symfonia-ksef/state/rootRepository';
import {AuthState} from '@symfonia-ksef/state/EarchiveState/AuthState';
import {authClient} from '../../auth/authClient';
import {observer} from 'mobx-react-lite';

export const auth = new AuthState(authClient);

export type GraphQLErrorWithMessage =
  Omit<GraphQLError, 'extensions'>
  & {
  extensions: GraphQLErrorExtensions & {
    message?: string,
    code?: string,
    data?: Record<string, unknown> & {
      Identifier?: string[],
      empty?: string,
      '-empty-'?: string[],
      KSeFErrorJsonResponse?: string
    }
  }
}

export type Context = { headers?: { authorization?: string, environmentId?: string } & AnyObject } & AnyObject

const intl = intlFactory();
export const apolloClient = apolloClientFactory();

export const GraphQLProvider: FC<PropsWithChildren> = observer(({children}) => <ApolloProvider client={apolloClient}
                                                                                               children={children}/>);

function apolloClientFactory() {
  const client = new ApolloClient({
    link: createMiddlewares(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache({
      addTypename: false,
      resultCaching: false,
    }),
  });
  subscribeToTokenChange();
  return client;
}

function reconnectApolloMiddlewares() {
  apolloClient.setLink(createMiddlewares());
}

function createMiddlewares(): ApolloLink {
  return from([
    authorizationMiddleware(),
    environmentIdMiddleware(),
    errorHandlingMiddleware(),
    matchProtocolMiddleware(),
  ]);
}

function matchProtocolMiddleware() {
  const httpLink = new HttpLink({
    uri: environment.apiUrl,
    fetch,
  });

  const absoluteUrlRegex = /^https?:\/\//i;
  let subscriptionWSUrl = '';
  if (absoluteUrlRegex.test(environment.apiUrl)) {
    subscriptionWSUrl = environment.apiUrl.replace(/^http/, 'ws') + '/ws';
  } else {
    subscriptionWSUrl = (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host + environment.apiUrl + '/ws';
  }


  //TODO: Po zmianie na hot chockolate 13 powinniśmy zacząć używać grapqhl-ws
  const wsLink = new WebSocketLink(
    new SubscriptionClient(subscriptionWSUrl, {
      reconnect: true,
      connectionParams: {authToken: auth.accessToken},
    }),
  );

  return split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
}

function errorHandlingMiddleware() {
  return onError(res => {
    //zwraca nam albo ApolloError albo Error
    const status: number = (res as any).status || (res as any).networkError?.statusCode;
    const messages = graphqlErrorMessage(res);
    messages?.map(error => {
      if (error === intl.formatMessage({id: Tr.requestLimit})) {
        earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.requestLimitContent}), ToastVariant.ERROR);
      }
    });

    if (status === 401) {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.tokenExpiredAlert}), ToastVariant.ERROR, {displayDuration: 6000});
      auth.acquireToken();
    }

    console.error('apolloError', {
      operation: res.operation,
      response: res.response,
      graphQLErrors: res.response,
      networkError: res.response,
      status,
    });
    // Do your job
  });
}

function graphqlErrorMessage(res: ErrorResponse) {
  return res?.graphQLErrors
    ?.filter?.(e => e.extensions?.code === 'validation')
    .map(e => e.extensions?.data as Record<string, string[]>)
    .map(data => Object.keys(data).reduce((prev: string[], key: string) => [...prev, ...data[key]], []))
    .reduce((prev, next) => [...prev, ...next], []);
}

function intlFactory(): IntlShape {
  return createIntl({
    locale: environment.defaultLocale,
    messages: pl,
  }, createIntlCache());
}

function subscribeToTokenChange(): void {
  auth.subscribeToTokenChange(async () => {
    reconnectApolloMiddlewares();
    await ksefEventsService.reconnect(false);
  });
}


