import {action, IObservableArray, makeObservable, observable} from 'mobx';
import {ModulesEnum} from '../../modules/common/enums/ModulesEnum';
import {IEarchiveStateMobx} from '../../modules/earchive/IEArchiveStateMobx';
import {IModuleStatus} from './IModuleStatus';
import {IModuleStatusStore} from './IModuleStatusStore';

export class ModuleStatusStore implements IModuleStatusStore {

  @observable
  public modules: IObservableArray<IModuleStatus> = observable([]);

  public rootState: IEarchiveStateMobx;

  private registeredModules = new Map<ModulesEnum, () => Promise<IModuleStatus>>();

  constructor(rootState: IEarchiveStateMobx) {
    makeObservable(this);
    this.rootState = rootState;
  }

  @action
  public registerModule(name: ModulesEnum, query: () => Promise<IModuleStatus>) {
    if (this.registeredModules.has(name)) {
      return;
    }

    this.registeredModules.set(name, query);
    this.fetchModules();
  }

  @action
  public fetchModules() {
    if (this.registeredModules.size <= this.modules.length) {
      return this.modules;
    }

    this.registeredModules.forEach(async (query, name) => {
      if (this.modules.find(x => x.moduleType === name)) {
        return;
      } else {
        await query().then(x => this.addModule(x))
          .catch(() => {
            return;
          });
      }
    });

    return this.modules;
  }

  @action
  public getModule(moduleName: ModulesEnum) {
    return this.modules.find(x => x.moduleType === moduleName);
  }

  @action
  public resetModules() {
    this.modules.clear();
    this.registeredModules.clear();
  }

  @action
  public softResetModules() {
    this.modules.clear();
    this.fetchModules();
  }

  @action
  private addModule(module: IModuleStatus) {
    const existingModule = this.getModule(module.moduleType);
    if (existingModule) {
      this.modules.remove(existingModule);
      this.modules.push(module);
    }
    else {
      this.modules.push(module);
    }
  }
}
