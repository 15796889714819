import React, {useEffect, useState} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  InitializationTaskTypeEnum,
  ProcessTaskStatusEnum,
  useOnAutoPostingInitializationStatusChangedSubscription,
} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {useIntl} from 'react-intl';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {IPostingInitializationState} from './IPostingInitializationState';
import {Breadcrumbs, Spinner, SpinnerSize} from '@symfonia/brandbook';
import {Header} from '../../../../../root/components/Header';
import {useParams} from 'react-router-dom';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface PostingInitializationPageProps {
  state: IPostingInitializationState;
}

export const PostingInitializationPage = observer(({state}: PostingInitializationPageProps) => {
  const intl = useIntl();
  const [processPercentage, setProcessPercentage] = useState<number>(0);
  const [processTitle, setProcessTitle] = useState<string>(Tr.desktopConnectionCheck);
  const [skip, setSkip] = useState(true);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.config}),
    },
  ];

  const changeCurrentProcessStatus = (taskType: InitializationTaskTypeEnum) => {
    switch (taskType) {
      case InitializationTaskTypeEnum.IsDesktopConnected:
        setProcessPercentage(10);
        setProcessTitle(Tr.desktopConnectionCheck);
        break;
      case InitializationTaskTypeEnum.SaveContractors:
        setProcessPercentage(20);
        setProcessTitle(Tr.externalContractorsPending);
        break;
      case InitializationTaskTypeEnum.SaveFinancialYears:
        setProcessPercentage(40);
        setProcessTitle(Tr.externalFinancialYearsPending);
        break;
      case InitializationTaskTypeEnum.SaveAccounts:
        setProcessPercentage(50);
        setProcessTitle(Tr.externalAccountsPending);
        break;
      case InitializationTaskTypeEnum.SaveDictionaries:
        setProcessPercentage(60);
        setProcessTitle(Tr.externalDictionariesPending);
        break;
      case InitializationTaskTypeEnum.MapAccountsToNewValues:
      case InitializationTaskTypeEnum.SaveDictionaryElements:
        setProcessPercentage(70);
        setProcessTitle(Tr.externalDictionaryElementsPending);
        break;
      case InitializationTaskTypeEnum.SaveVatRegistries:
        setProcessPercentage(80);
        setProcessTitle(Tr.externalVatRegistryElementsPending);
        break;
      case InitializationTaskTypeEnum.SaveDocumentTypes:
        setProcessPercentage(90);
        setProcessTitle(Tr.externalDocumentTypeElementsPending);
        break;
      case InitializationTaskTypeEnum.CompleteInitialization:
        setProcessPercentage(99);
        setProcessTitle(Tr.initializationCompleting);
        break;
      default:
        setProcessPercentage(0);
        setProcessTitle(Tr.desktopConnectionCheck);
        break;
    }
  };

  const {data} = useOnAutoPostingInitializationStatusChangedSubscription({
    context: {envId: earchiveState.company.tenantId ?? ''},
    variables: {CompanyId: earchiveState.company.companyId},
    skip,
  });

  useEffect(() => setSkip(false), []);

  useEffect(() => {
    if (data && data.onAutoPostingInitializationStatusChanged) {
      state.setTaskStatus(data.onAutoPostingInitializationStatusChanged.status);
      state.setInitializationTaskType(data.onAutoPostingInitializationStatusChanged.taskType);

      if (ProcessTaskStatusEnum.Error === data.onAutoPostingInitializationStatusChanged.status) {
        state.setHasFailed(data.onAutoPostingInitializationStatusChanged.errorType);
        state.accountState.earchiveState.moduleStatusStore.softResetModules();
      }

      // if Completed - implementation of softResetModules is triggered
      // on WS subscription on EArchiveModuleInitialization
      // to refresh whole app
    }
    changeCurrentProcessStatus(state.taskType!);
  }, [data]);


  return <PageContent>
    <div className="flex flex-col gap-2 lg:ml-[40px] lg:w-[60%]">
      <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
      <Header id={'postingActivationErrorConfigurationTitle'}
              className={'flex items-start'}>{intl.formatMessage({id: Tr.postingConfiguration})}</Header>
      <p className="mt-[20px]">{intl.formatMessage({id: Tr.postingActivation})}</p>
      <div className={'flex items-center mt-[40px]'}>
        <Spinner size={SpinnerSize.SM} className={'mr-[20px]'}/>
        <h1 className={'font-bold text-green-600 mr-[10px]'}>{processPercentage}%</h1>
        <p>{intl.formatMessage({id: Tr[processTitle as Tr]})}</p>
      </div>
    </div>
  </PageContent>;
});
