import {useEffect} from 'react';
import {throttle} from './useThrottle';

export enum KeyboardEvents {
  keypress = 'keypress',
  keyup = 'keyup',
  keydown = 'keydown'
}

export enum ArrowKeys {
  left = 'ArrowLeft',
  up = 'ArrowUp',
  right = 'ArrowRight',
  down = 'ArrowDown'
}

export type KeyboardEventConfig = {
  eventType?: KeyboardEvents,
  key?: string,
  keyCode?: string,
  arrowKey?: ArrowKeys,
  preventDefault?: boolean,
  stopPropagation?: boolean,
  timeThreshold?: number,
  skipUntilTheEnd?: boolean,
  skip?: boolean
} & AddEventListenerOptions

export const useKeyboard = (callback: (e: KeyboardEvent) => void, config: KeyboardEventConfig = {}) => {
  const {
    eventType,
    key,
    arrowKey,
    preventDefault,
    stopPropagation,
    keyCode,
    timeThreshold,
    skipUntilTheEnd,
    skip,
    ...options
  } = config;

  useEffect(() => {
    if (skip) {
      return;
    }

    let pushed: boolean = false;

    const event: KeyboardEvents = !eventType && arrowKey ? KeyboardEvents.keydown : eventType ?? KeyboardEvents.keydown;
    const code: string | undefined = keyCode ?? arrowKey;
    const skippingEnabled: boolean = event === KeyboardEvents.keydown && !!skipUntilTheEnd;

    const checkShouldSkip = () => pushed && skippingEnabled;

    const handler = throttle((e: KeyboardEvent) => {

      if (checkShouldSkip()) {
        return;
      }

      pushed = true;

      if (stopPropagation) {
        e.stopPropagation();
      }

      if (key && key !== e.key) {
        return;
      }

      if (code && code !== e.code) {
        return;
      }

      if (preventDefault) {
        e.preventDefault();
      }

      callback(e);
    }, timeThreshold ?? 0, !timeThreshold);

    const disposeSkipping = throttle(() => {
      if (checkShouldSkip()) {
        pushed = false;
      }
    }, timeThreshold ?? 0, !timeThreshold);

    addEventListener(event, handler, options);

    if (skippingEnabled) {
      addEventListener(KeyboardEvents.keyup, disposeSkipping, options);
    }

    return () => {
      removeEventListener(event, handler, options);

      if (skippingEnabled) {
        removeEventListener(KeyboardEvents.keyup, disposeSkipping, options);
      }
    };

  }, Object.values(config));
};
