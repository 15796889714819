import {InvoiceBound, InvoiceDto, InvoicePostingStatus, KSeFStatus, SymfoniaSystem} from '@symfonia-ksef/graphql';
import {InvoiceKsefStatusToText, InvoicePostingStatusToText} from '../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {MultiSelectModel} from '@symfonia/symfonia-ui-components';
import {isSymfoniaFinancesAndAccountancy} from "../../common/helpers/checkCurrentPlatform";

export function isPostingAvailableForInvoice(invoice: InvoiceDto) {
  if (invoice.Downloaded === SymfoniaSystem.Fk) {
    return false;
  }

  if (invoice.InvoicePostingStatus === InvoicePostingStatus.Undefined ||
    invoice.InvoicePostingStatus === InvoicePostingStatus.Posting ||
    invoice.InvoicePostingStatus === InvoicePostingStatus.PostingIncomplete ||
    invoice.InvoicePostingStatus === InvoicePostingStatus.Posted ||
    invoice.InvoicePostingStatus === InvoicePostingStatus.DecreeSent ||
    invoice.InvoicePostingStatus === InvoicePostingStatus.NotAllowed) {
    return false;
  }

  if (invoice.InvoiceBound === InvoiceBound.Internal) {
    if (invoice.KSeFStatus !== KSeFStatus.Approved &&
      invoice.KSeFStatus !== KSeFStatus.NotSent &&
      invoice.KSeFStatus !== KSeFStatus.Ocr
    ) {
      return false;
    }
  }

  return true;
}

export function isExportToSymfoniaFKAvailable(invoice?: InvoiceDto, acceptBeforeSendToFKIsActive?: boolean) : boolean {
  if (invoice) {
    if (acceptBeforeSendToFKIsActive) {
      if ([InvoicePostingStatus.Posted, InvoicePostingStatus.PostingIncomplete].includes(invoice.InvoicePostingStatus) && !invoice?.IsDecreeAccepted) {
        return false;
      }
    }

    // enabled only in WebView (desktop) to allow download invoice by API
    if (invoice.InvoicePostingStatus === InvoicePostingStatus.ReadyForPosting && isSymfoniaFinancesAndAccountancy()) {
      return true;
    }

    const disallowedPostingStatuses = [
      InvoicePostingStatus.NotAllowed,
      InvoicePostingStatus.ReadyForPosting,
      InvoicePostingStatus.PostingFailed,
      InvoicePostingStatus.Posting,
      InvoicePostingStatus.DecreeDownloaded,
      InvoicePostingStatus.DecreeSent,
      InvoicePostingStatus.Undefined];
    if (disallowedPostingStatuses.includes(invoice.InvoicePostingStatus)) {
      return false;
    }

    // PostingIncomplete or Posted
    return true;
  }
  return false;
}

export const decreeInvoices = (selectedInvoices: InvoiceDto[]) => {
  const validationArray = [
    KSeFStatus.Approved,
    KSeFStatus.NotSent,
    KSeFStatus.Ocr];

  const selectedAndFilteredInvoices = selectedInvoices.filter(e => {
    return ![InvoicePostingStatus.ReadyForPosting].includes(e.InvoicePostingStatus) ||
      (e.InvoiceBound === InvoiceBound.Internal && !validationArray.includes(e.KSeFStatus));
  });
  const convertedInvoices: MultiSelectModel[] = selectedAndFilteredInvoices?.map((x) => ({
    value: x.InvoicePostingStatus === InvoicePostingStatus.ReadyForPosting
      ? InvoiceKsefStatusToText(x.KSeFStatus).text
      : InvoicePostingStatusToText(x.InvoicePostingStatus).text,
    key: x.Number ?? '',
  }));
  return convertedInvoices;
};

export const exportToFKWithoutDecree = (selectedInvoices: InvoiceDto[]) => {
  const validationArray = [
    InvoicePostingStatus.Posting,
    InvoicePostingStatus.PostingFailed,
    InvoicePostingStatus.ReadyForPosting,
    InvoicePostingStatus.Undefined];
  const selectedAndFilteredInvoices = selectedInvoices.filter(x =>
    validationArray.includes(x.InvoicePostingStatus));

  const convertedInvoices: MultiSelectModel[] = selectedAndFilteredInvoices?.map((x) => ({
    value: validationArray.includes(x.InvoicePostingStatus)
      ? InvoicePostingStatusToText(x.InvoicePostingStatus).text
      : intl.formatMessage({id: Tr.noDecree}),
    key: x.Number ?? '',
  }));
  return convertedInvoices;
};

export const exportToFKPartlyUnavailableList = (selectedInvoices: InvoiceDto[], acceptBeforeSendToFKIsActive: boolean) => {
  const validationArray = [InvoicePostingStatus.DecreeDownloaded, InvoicePostingStatus.DecreeSent];
  const selectedAndFilteredInvoices = selectedInvoices.filter(x =>
    validationArray.includes(x.InvoicePostingStatus) || (acceptBeforeSendToFKIsActive && !x.IsDecreeAccepted));
  const convertedInvoices: MultiSelectModel[] = selectedAndFilteredInvoices?.map((x) => ({
    value: validationArray.includes(x.InvoicePostingStatus)
      ? InvoicePostingStatusToText(x.InvoicePostingStatus).text
      : intl.formatMessage({id: Tr.noAcceptedDecree}),
    key: x.Number ?? '',
  }));
  return convertedInvoices;
};
