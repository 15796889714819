import {FormattedMessage} from 'react-intl';
import {AppRoutes} from '../../../../root/Routes';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {ButtonTertiary, Icon, IconColor, IconSize, IconSvg, Spinner, SpinnerSize} from '@symfonia/brandbook';
import {Text} from '../../../../root/components/Text';
import classnames from 'classnames';

export interface AuthenticationOptionProps {
  titleMessageId: string;
  descMessageId: string;
  showLoader?: boolean;
  disabled?: boolean;
  clicked: () => void;
  processMessage?: string | undefined;
}

export const AuthenticationOption = observer(
  ({clicked, descMessageId, disabled, processMessage, showLoader, titleMessageId}: AuthenticationOptionProps) => {
    const handleClick = () => {
      if (!disabled) {
        clicked();
      }
    };

    return (
      <div className={classnames('p-6 shadow-xl rounded-md max-w-[500px] mb-[16px]', {
        'cursor-pointer': !disabled,
        'cursor-not-allowed': disabled,
        'opacity-50': disabled,
      })} onClick={handleClick}>
        <div className="flex gap-2">
          <div>
            <div className="font-bold text-xl">
              <FormattedMessage id={titleMessageId}/>
            </div>
            <div className="mt-[8px]">
              <FormattedMessage
                id={descMessageId}
                values={{
                  link: (
                    <a
                      onClick={e => e.stopPropagation()}
                      href={AppRoutes.ksefInfo.address}
                      target={'_blank'}
                      rel="noreferrer"
                      className="font-bold text-primary-500 underline"
                    >
                      {AppRoutes.ksefInfo.title}
                    </a>
                  ),
                }}
              />
            </div>
          </div>
          <div className="self-center">
            <div className="flex gap-2">
              <div>
                <ButtonTertiary
                  className={classnames({'cursor-pointer': !disabled, 'cursor-not-allowed': disabled})}
                  text={intl.formatMessage({id: Tr.authorize})}
                  disabled={disabled}
                />
              </div>
              <div className="pt-1">
                <Icon color={disabled ? IconColor.GREY_300 : IconColor.GREEN_600} svg={IconSvg.ARROW_FORWARD_IOS}
                      size={IconSize.SM}/>
              </div>
            </div>
          </div>
        </div>
        {showLoader && (
          <div className="flex items-center justify-center w-full mt-4 gap-4">
            <Spinner size={SpinnerSize.SM}/>
            <Text>
              {processMessage
                ? intl.formatMessage({id: processMessage})
                : intl.formatMessage({id: Tr.pendingAuthorization})}
            </Text>
          </div>
        )}
      </div>
    );
  },
);

export default AuthenticationOption;
