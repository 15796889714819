import dayjs, {Dayjs} from 'dayjs';

export const dateParser = (value?: Date | string, withTime = false, timeFirst = false) => {
  function containsTimeZoneSigns(value: string): boolean {
    return value.includes('+') || value.includes('-');
  }

  if (value) {
    if (withTime && typeof value === 'string') {
      const timePart = value.substring(value.indexOf('T'));
      if (!containsTimeZoneSigns(timePart) && !value.endsWith('Z')) {
        value += 'Z';
      }
    }
    const date = new Date(value);
    if (timeFirst) {
      return `${date
        ?.toLocaleString('pl-PL', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        })
        ?.replace(/\./g, '-')}, ${date
        ?.toLocaleString('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        ?.replace(/\./g, '-')}`;
    }
    if (!withTime)
      return date
        ?.toLocaleString('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        ?.replace(/\./g, '-');
    return (
      date
        ?.toLocaleString('pl-PL', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        })
        ?.replace(/\./g, '-')
    );
  }

  return '';
};

export const toDateTimeOffset = (value?: Date): string => value ? dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : '';

export const toDateOnly = (value?: Date | Dayjs): string => dayjs(value).format('YYYY-MM-DD');
