import {List, ListItem, ListItemText, Typography} from '@mui/material';
import {FC, ReactNode} from 'react';
import {observer} from 'mobx-react-lite';

/** Typy subskrypcji websocket */
export enum WebsocketNotificationType {
  /** Autoryzacja podpisem */
  AuthorizeInKsef = 'AUTHORIZE_IN_KSEF',
  /** Autoryzacja zewnętrznym tokenem */
  AuthorizeInKsefExternalToken = 'AUTHORIZE_IN_KSEF_EXTERNAL_TOKEN',
  /** Automatyczne pobieranie faktur */
  AutoFetchingInvoice = 'AUTO_FETCHING_INVOICE',
  /** Pobieranie faktur */
  DownloadInvoices = 'DOWNLOAD_INVOICES',
  /** Pobieranie UPO */
  GetUpo = 'GET_UPO',
  /** Nadawanie uprawnień */
  GrantPermission = 'GRANT_PERMISSION',
  /** Kasowanie sesji */
  TerminateSession = 'TERMINATE_SESSION',
  /** Usunięcie autoryzacji podpisem */
  UnauthorizedInKsef = 'UNAUTHORIZED_IN_KSEF',
  /** Usunięcie autoryzacji zewnętrznym tokenem */
  UnauthorizedInKsefExternalToken = 'UNAUTHORIZED_IN_KSEF_EXTERNAL_TOKEN'
}

export type IWebsocketNotification = {
  id: string,
  message: ReactNode;
}

type IEventsShortList = {
  events: IWebsocketNotification[],
  eventAction: string | undefined,
  noDataLabel: string | undefined,
}

export const EventsShortList: FC<IEventsShortList> = observer(({eventAction, events, noDataLabel}) => {
  return (
    <List sx={{width: '100%', maxWidth: 360}}>
      {events && events.length > 0 ? events.map(n =>
          <ListItem divider={true} sx={{justifyContent: 'flex-start', pb: 3}} key={n.id}
                    alignItems="flex-start">
            <ListItemText
              secondary={
                <Typography
                  sx={{display: 'inline', pb: 3}}
                  component="p"
                  color="text.primary"
                >{n.message}</Typography>
              }
            />
          </ListItem>,
        )
        :
        <ListItem divider={true} sx={{pb: 3}}>
          <ListItemText
            secondary={
              <Typography
                sx={{display: 'inline', pb: 3}}
                component="p"
                color="text.primary"
              >{noDataLabel}</Typography>
            }
          />
        </ListItem>
      }

      <ListItem><Typography
        sx={{display: 'inline', fontStyle: 'italic', justifyContent: 'center', mt: 3, fontSize: '0.75rem'}}
        component="p"
        variant="body2"
        color="text.primary">{eventAction}</Typography></ListItem>
    </List>
  );
});
