import clsx from 'clsx';
import {FC, useMemo} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {Tab, TabInternal, TabProps} from './Tab';
import {ComponentWithDropdown} from '../Dropdown/WithDropdownList';
import {TestableElement} from '../../external/types';

export type TabsProps = {
  tabs: ComponentWithDropdown<TabProps>[];
  isActive?: (tab: ComponentWithDropdown<TabProps>, index: number) => boolean;
  className?: string;
  fullWidthUnderline?: boolean;
} & TestableElement;

export const Tabs: FC<TabsProps> = ({
  tabs,
  isActive = undefined,
  className = undefined,
  fullWidthUnderline = false,
  testId = undefined,
}) => {
  const {pathname} = useLocation();

  const tabsWithKeys = useMemo<[ComponentWithDropdown<TabProps>, string][]>(
    () => tabs.map((tab, index) => [tab, crypto.randomUUID()]),
    [tabs],
  );

  const styles = {
    component: twMerge(clsx('flex relative items-end', className)),
  };

  const isActiveUrl = (tab: ComponentWithDropdown<TabProps>): boolean => {
    return !!(
      (tab.href && matchPath(tab.href, pathname)) ||
      tab.dropdown?.options.some(option => option.href && matchPath(option.href, pathname))
    );
  };

  const isActiveTab = (tabProps: ComponentWithDropdown<TabProps>, index: number): boolean => {
    if ('isActive' in tabProps) {
      return !!tabProps.isActive;
    }
    if (isActive) {
      return isActive(tabProps, index);
    }
    return isActiveUrl(tabProps);
  };

  return (
    <div className={styles.component} data-test-element="tabs" data-testid={testId}>
      {tabsWithKeys.map(([tabProps, key], index) => (
        <Tab {...tabProps} key={key} isActive={isActiveTab(tabProps, index)} />
      ))}
      {fullWidthUnderline && <TabInternal className="w-full grow" />}
    </div>
  );
};
