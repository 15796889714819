import {action, computed, makeObservable, observable} from 'mobx';
import {DropzoneUploaderStateServiceI} from './DropzoneUploaderStateService';

export enum ErrorReasons {
  minSize = 'minSize',
  maxSize = 'maxSize',
  minCount = 'minCount',
  maxCount = 'maxCount',
  type = 'type',
  empty = 'empty',
  unknown = 'unknown',
  mixed = 'mixed'
}

export interface ValidatorConfig {
  maxFileSize?: number,
  minFileSize?: number,
  maxFileCount?: number,
  minFileCount?: number,
  fileTypes?: string[],
  messages?: Partial<Record<ErrorReasons, Message>>,
  validateOnDragOver?: boolean
}

export type ValidatedFile = { file: File, isValid: boolean }
export type RejectedFile = ValidatedFile & { errors: ValidationError[] }

type Message = string | ((uploader: DropzoneUploaderStateServiceI, config?: ValidatorConfig, file?: File) => string)

export type ValidationError = {
  file?: File,
  reason: ErrorReasons,
  message?: string,
  config?: ValidatorConfig
}

export type Files = {
  rejectedFiles: RejectedFile[];
  validatedFiles: ValidatedFile[];
}

export type OnValidate = (file: File, validated: boolean, validationErrors: ValidationError[]) => void

export interface UploaderValidatorI extends ValidatorConfig {
  errors: ValidationError[];

  get isValid(): boolean;

  validateSingle(file: File, onValidate?: OnValidate): { validated: boolean, errors: ValidationError[] };

  configure(config: Partial<ValidatorConfig>): this;

  validate(files: File[], onValidate?: OnValidate): Files;

  reset(): void;

  removeErrorsForFile({name, type}: File): number;

  get uniqueErrors(): ValidationError[];

  get mixedError(): ValidationError | null;
}

export class DropzoneUploaderValidator implements UploaderValidatorI {
  @observable
  public maxFileSize?: number;

  @observable
  public minFileSize?: number;

  @observable
  public maxFileCount?: number;

  @observable
  public minFileCount?: number;

  @observable.ref
  public fileTypes?: string[];

  @observable.ref
  public messages?: Partial<Record<ErrorReasons, Message>>;

  @observable.ref
  public errors: ValidationError[] = [];

  constructor(private uploader: DropzoneUploaderStateServiceI) {

    this.uploader.initialConfig && this.configure(this.uploader.initialConfig);
    makeObservable(this);
  }

  @computed
  public get isValid(): boolean {
    if (!this.uploader.initialized) {
      return true;
    }
    return !!this.uploader.validatedFiles.length;
  }

  @computed
  public get uniqueErrors(): ValidationError[] {
    const errorsTypeMap = new Map<ErrorReasons, ValidationError>();
    const uniqueErrors: ValidationError[] = [];
    for (const error of this.errors) {
      if (errorsTypeMap.get(error.reason)) {
        continue;
      }
      errorsTypeMap.set(error.reason, error);
      uniqueErrors.push(error);
    }
    return uniqueErrors;
  }

  @computed
  public get mixedError(): ValidationError | null {
    return this.uniqueErrors.length > 1 ? {
      reason: ErrorReasons.mixed,
      message: this.handleMessage(ErrorReasons.mixed),
      config: this,
    } : null;
  }

  @action.bound
  public configure(config: Partial<ValidatorConfig>): this {
    for (const key in config) {
      const value = config[key as keyof ValidatorConfig];
      ((this as ValidatorConfig)[key as keyof ValidatorConfig] as typeof value) = value;
    }
    return this;
  }

  public reset() {
    this.setErrors([]);
  }

  public removeErrorsForFile({name, type}: File): number {
    const withoutRemoved = this.errors.filter(({file}) => !(file?.name === name && file?.type === type));
    const removedCount = this.errors.length - withoutRemoved.length;
    this.setErrors(withoutRemoved);
    return removedCount;
  }

  public validateSingle(file: File, onValidate?: OnValidate): { validated: boolean, errors: ValidationError[] } {
    const errorsInFile: ValidationError[] = [...this.validateSize(file), ...this.validateType(file)];
    onValidate?.(file, !errorsInFile.length, errorsInFile);
    return {
      validated: !errorsInFile.length,
      errors: errorsInFile,
    };
  }

  public validate(files?: File[], onValidate?: OnValidate): Files {
    const errors: ValidationError[] = [];
    const newRejectedFiles: RejectedFile[] = [];
    const newValidatedFiles: ValidatedFile[] = [];
    if (!files) {
      errors.push({reason: ErrorReasons.empty, config: this, message: this.handleMessage(ErrorReasons.empty)});
      return {rejectedFiles: newRejectedFiles, validatedFiles: newValidatedFiles};
    }
    try {
      const countErrors: ValidationError[] = this.validateCount(files);
      const hasCountError = !!countErrors.length;
      errors.push(...countErrors);
      for (const file of files) {
        const errorsInFile: ValidationError[] = [...this.validateSize(file), ...this.validateType(file)];
        const hasError = errorsInFile.length || hasCountError;
        onValidate?.(file, !hasError, errorsInFile);
        hasError ? newRejectedFiles.push({file, isValid: false, errors: errorsInFile}) : newValidatedFiles.push({
          file,
          isValid: true,
        });
        errors.push(...errorsInFile);
      }

    } catch (err) {
      errors.push({reason: ErrorReasons.unknown, config: this, message: this.handleMessage(ErrorReasons.unknown)});
    }
    this.setErrors(errors);
    return {rejectedFiles: newRejectedFiles, validatedFiles: newValidatedFiles};
  }

  @action
  private setErrors(errors: ValidationError[]): void {
    this.errors = errors;
  }

  private validateSize(file: File): ValidationError[] {
    const errors: ValidationError[] = [];
    if (file.size > (this?.maxFileSize ?? Infinity)) {
      errors.push({
        reason: ErrorReasons.maxSize,
        config: this,
        file,
        message: this.handleMessage(ErrorReasons.maxSize, file),
      });
    }
    if (file.size < (this?.minFileSize ?? 0)) {
      errors.push({
        reason: ErrorReasons.minSize,
        config: this,
        file,
        message: this.handleMessage(ErrorReasons.minSize, file),
      });
    }
    return errors;
  }

  private validateCount(files: File[]): ValidationError[] {
    const errors: ValidationError[] = [];
    if (files.length > (this?.maxFileCount ?? Infinity)) {
      errors.push({reason: ErrorReasons.maxCount, config: this, message: this.handleMessage(ErrorReasons.maxCount)});
    }

    if (files.length < (this?.minFileCount ?? 0)) {
      errors.push({reason: ErrorReasons.minCount, config: this, message: this.handleMessage(ErrorReasons.minCount)});
    }
    return errors;
  }

  private validateType(file: File): ValidationError[] {
    const errors: ValidationError[] = [];
    if (!(this.fileTypes?.includes?.(file.type) ?? true)) {
      errors.push({
        reason: ErrorReasons.type,
        config: this,
        file,
        message: this.handleMessage(ErrorReasons.type, file),
      });
    }
    return errors;
  }

  private handleMessage(reason: ErrorReasons, file?: File): string | undefined {
    const message = this?.messages?.[reason];
    return typeof message === 'function' ? message(this.uploader, this, file) : message;
  }
}
