import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';

export class OcrInvoiceUploadedKSeFEventConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.OcrInvoiceImported, undefined, {
      success: {id: Tr.ocrImportedToKSeFSuccess},
      error: {id: Tr.ocrImportedToKSeFError},
    });
  }
}
