import clsx from 'clsx';
import {FC} from 'react';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '../../Icon/Icon';
import {TogglableAppearanceProps, TogglableSize} from '../../../internal/components/Togglable/Togglable';

export const getAppearanceInputStyles = (size: TogglableSize) =>
  clsx('absolute rounded-[6px]', {
    'h-[24px] w-[24px]': size === TogglableSize.MD,
    'h-[18px] w-[18px]': size === TogglableSize.SM,
  });

export const CheckboxAppearance: FC<TogglableAppearanceProps> = ({
  isChecked = false,
  moderate = false,
  size = TogglableSize.MD,
  isError = false,
  disabled = false,
}) => {
  const styles = {
    box: clsx('rounded-[6px] relative inline-block', {
      'bg-white': !isError && !disabled && !isChecked && !moderate,
      'bg-primary-500': !isError && !disabled && (isChecked || moderate),
      'border border-solid border-primary-500 group-hover:border-primary-600 group-hover:border-[2px]':
        !isError && !disabled && !(isChecked || moderate),
      'border border-solid border-red-500 group-hover:border-red-600 group-hover:border-[2px]':
        isError && !disabled && !(isChecked || moderate),
      'group-hover:bg-primary-600': !isError && (isChecked || moderate) && !disabled,
      'bg-red-500': isError && (isChecked || moderate),
      'border border-solid border-red-500': isError && !(isChecked || moderate),
      'bg-grey-500': (isChecked || moderate) && disabled,
      'border border-solid border-grey-500 bg-grey-100': !(isChecked || moderate) && disabled,
      'h-[24px] w-[24px]': size === TogglableSize.MD,
      'h-[18px] w-[18px]': size === TogglableSize.SM,
    }),
    icon: clsx('absolute', {
      'left-[1px] top-[1px]': size === TogglableSize.SM,
      'left-[2px] top-[2px]': size === TogglableSize.MD,
    }),
  };

  return (
    <span className={styles.box}>
      {(moderate || isChecked) && (
        <Icon
          svg={moderate ? IconSvg.REMOVE : IconSvg.CHECK}
          color={IconColor.GREY_50}
          variant={IconVariant.FILLED}
          size={IconSize[size]}
          className={styles.icon}
        />
      )}
    </span>
  );
};
