import clsx from 'clsx';
import {FC, ReactNode, useMemo} from 'react';
import {SimpleTableRow} from './SimpleTableRow';
import {SimpleTableHeader} from './SimpleTableHeader';
import {Separator, SeparatorDirection, SeparatorSize} from '@symfonia/brandbook';
import {SimpleTableError} from './SimpleTableError';

export interface SimpleTableProps {
  title?: string;
  rows?: ReactNode[][];
  headers?: ReactNode[];
  className?: string;
}

export const SimpleTable: FC<SimpleTableProps> = ({
                                                    title = undefined,
                                                    headers = undefined,
                                                    rows = undefined,
                                                    className = undefined,
                                                  }) => {
  const styles = useMemo(
    () => {
      return ({
        component: clsx('flex min-w-full w-fit flex-col', className),
        title: 'font-quicksand text-[16px] leading-[24px]',
        column: `leading-[24px] font-quicksand items-center inline-flex min-h-[24px]`,
        header: clsx(`flex`, headers !== undefined && `bg-grey-50 font-quicksand font-bold min-h-[32px] items-center`),
        cell: 'text-[16px] ml-[5px]',
      });
    }, [className, headers]);

  const children = useMemo(
    () => {
      if (!rows || rows.length === 0) {
        return <SimpleTableError/>;
      }
      const expectedColumnCount = rows[0].length;
      const equal = rows.every((val) => val.length === expectedColumnCount);
      if (!equal) {
        return <SimpleTableError error="Liczba kolumn w wierszach jest różna"/>;
      }
      const widthClassName = `w-[${Math.round(100 / expectedColumnCount)}%]`;
      return rows.map((row, i) => <SimpleTableRow key={i} children={row} width={widthClassName}
                                                  className={clsx(styles.column, styles.cell)}/>);
    }, [rows, styles],
  );

  return (
    <div className={styles.component}>
      <div className={clsx(styles.header)}>
        <SimpleTableHeader title={title} headers={headers} className={clsx(styles.title)}/>
      </div>
      <Separator direction={SeparatorDirection.HORIZONTAL} size={SeparatorSize.SM}/>
      <div className="mt-[6px]">{children}</div>
      <Separator direction={SeparatorDirection.HORIZONTAL} size={SeparatorSize.SM}/>
    </div>
  );
};
