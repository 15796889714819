import {BaseEventConsumer, IEventConsumerPublic} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {EventParams} from '../SubscriptionEventsService';
import {downloadedInvoicesResultService} from '../../state/rootRepository';
import {Tr} from '../../locales/translationKeys';

export class DownloadedInvoicesResultConsumer extends BaseEventConsumer implements IEventConsumerPublic {
  constructor() {
    super(WebsocketNotificationType.DownloadInvoices, downloadedInvoicesResultService, {
      success: () => downloadedInvoicesResultService.successNotification,
      error: {id: Tr.getInvoicesFromKSeFError},
    });

  }

  public override async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await downloadedInvoicesResultService.getAsyncData(event, () => downloadedInvoicesResultService.setDownloadType(event.type)));
    const {currentResult} = downloadedInvoicesResultService;
    if (currentResult?.errorItems?.length) {
      downloadedInvoicesResultService.setModalIsActive(true);
    }
  }
}
