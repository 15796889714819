import {FC, PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';

interface PageContentProps extends PropsWithChildren {
  styles?: React.CSSProperties;
  paddingDisabled?: boolean;
  className?: string;
}

export const PageContent: FC<PageContentProps> = observer(({children, styles, className, paddingDisabled}) => {
return <div className={(className ? className : "") + (paddingDisabled ? "p-[0px]" : "p-[24px]")}
            style={styles}>{children}</div>;
});
