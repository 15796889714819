import {EventJobRunner} from '../EventJobRunner';
import {
  MultipleSendToKSeFDocument,
  MultipleSendToKSeFMutation,
  MultipleSendToKSeFMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class SendInvoicesToKSeFJobRunner extends EventJobRunner<MultipleSendToKSeFMutationVariables, keyof MultipleSendToKSeFMutation> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('MultipleSendToKSeF', WebsocketNotificationType.SendingInvoices, MultipleSendToKSeFDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => {
          const count = this.variables.InvoiceIds?.length ?? 0;
          return ({
            id: count > 1 ? Tr.sendManyToKSeFInProgress : Tr.sendToKSeFInProgress,
            values: {count},
          });
        },
        error: (errors, errorMessage) => {
          const count = this.variables.InvoiceIds?.length ?? 0;
          return ({
            id: count > 1 ? Tr.sendManyToKSeFError : Tr.sendToKSeFError,
          });
        },
      },
    );
  }

  public override checkIsReady(): boolean {
    super.checkIsReady();
    return !!this.variables.InvoiceIds?.length;
  }
}
