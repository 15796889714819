import {AnyObject} from 'yup/es/types';
import {PaginationConfig, PaginationService, PaginationServiceI} from '../PaginationServices/PaginationService';
import {BaseTableServiceI, Matrix, Row} from './BaseTableService';
import {action, computed, makeObservable} from 'mobx';
import {TableHeaderProps} from '@symfonia/brandbook';
import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {BaseTableDataRepositoryServiceI, DefaultVariables} from './DataSourceTableService';
import {BasePersistService, BasePersistServiceI} from '../PersistServices/BasePersistService';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export type OnChangeParams = {
  size: number,
  prevSize: number,
  prevPage: number,
  page: number,
  pagesCapacity: number | undefined,
  prevPagesCapacity: number | undefined,
  offset: number,
  prevOffset: number,
  changed: boolean
}

export type OnPaginationChange = (params: OnChangeParams) => Promise<void> | void

export enum PaginationMode {
  clientSide,
  serverSide
}

interface Wrapper<T extends AnyObject, C extends AnyObject = AnyObject> {
  rows: BaseTableServiceI<T, C>['rows'];
  matrix: BaseTableServiceI<T, C>['matrix'];
  headers: BaseTableServiceI<T, C>['headers'];
}

interface WrapperExtended<T extends AnyObject, C extends AnyObject = AnyObject> extends Wrapper<T, C> {
  sortBy: BaseTableServiceI<T, C>['sortBy'];
}

export interface PaginationTableServiceI<T extends AnyObject, C extends AnyObject = AnyObject> extends Wrapper<T, C>, BaseModuleI {
  get offset(): number;

  get pages(): number | undefined;

  get current(): number;

  get size(): number;

  setPage(page: number): void;

  getMatrix(matrix?: Matrix<T, C>): Matrix<T, C>;

  setSize(size: number): void;

  setPagesCapacity(capacity: number): void;

  set(arg: SetterPaginationArg): void;

  get serverModeEnabled(): boolean;
}

export interface PaginationTableLifeCycleI {
  readonly mode: PaginationMode;
  onChange?: OnPaginationChange;
  onPageChange?: OnPaginationChange;
  onSizeChange?: OnPaginationChange;
  onCapacityChange?: OnPaginationChange;
}

export type DefaultPaginationVariables = Omit<DefaultVariables, 'Order'>

export class DefaultPaginationLifeCycle<Key extends string = string, Data extends AnyObject = AnyObject, Variables extends DefaultPaginationVariables = DefaultPaginationVariables> implements PaginationTableLifeCycleI {

  constructor(private readonly repository?: BaseTableDataRepositoryServiceI<Key, Data, Variables>, public readonly mode = PaginationMode.serverSide) {
  }

  async onPageChange(params: OnChangeParams): Promise<void> {
    await this.handleChange(params);
  }

  async onSizeChange(params: OnChangeParams): Promise<void> {
    await this.handleChange(params);
  }

  private async handleChange(params: OnChangeParams): Promise<void> {
    params.changed && await this.repository?.configure?.({
      Take: params.size,
      Skip: params.offset,
    } as Partial<Variables>);
    this.repository?.initialized && this.repository?.fetch?.();
  }
}

export type PersistType = {
  pages?: number,
  current?: number,
  offset?: number,
  size?: number
}

export type SetterPaginationParams = { page?: number, size?: number, capacity?: number }

export type SetterPaginationArg =
  SetterPaginationParams
  | ((currentParams: SetterPaginationParams) => SetterPaginationParams)

export class PaginationTableService<T extends AnyObject, C extends AnyObject = AnyObject> extends BaseModule implements PaginationTableServiceI<T, C> {
  protected lifeCycle?: PaginationTableLifeCycleI;
  private readonly _pagination!: PaginationServiceI;
  private readonly persistService?: BasePersistServiceI<PersistType>;

  constructor(protected wrapped: WrapperExtended<T, C>, persistKey?: string, lifeCycle?: PaginationTableLifeCycleI, config?: PaginationConfig) {
    super();
    this._pagination = new PaginationService(config);
    this.setLifeCycle(lifeCycle);
    makeObservable(this);
    this.adjustCapacity();

    if (persistKey) {
      this.persistService = new BasePersistService<PersistType>(persistKey, earchiveState.envObserver)
        .subscribe(({
                      current: page,
                      size,
                      pages: capacity,
                    }) => this.set({
          page,
          size,
          capacity,
        }), {load: true});
    }
    this.reactionsManager.add(() => this.rows, () => this.adjustCapacity());
  }

  @computed
  public get pages(): number | undefined {
    return this._pagination.pages;
  }

  @computed
  public get current(): number {
    return this._pagination.current;
  }

  @computed
  public get offset(): number {
    return this.size * (this.current - 1);
  }

  @computed
  public get size(): number {
    return this._pagination.size;
  }

  @computed
  public get rows(): Row<T>[] {
    return this.serverModeEnabled ? this.wrapped.rows : this.wrapped.rows.slice(this.offset, this.offset + this.size);
  }

  @computed
  public get headers(): TableHeaderProps[] {
    return this.wrapped.headers;
  }

  @computed
  public get matrix(): Matrix<T, C> {
    return this.getMatrix(this.wrapped.matrix);
  }

  public get serverModeEnabled(): boolean {
    return this.lifeCycle?.mode === PaginationMode.serverSide;
  }

  @action.bound
  public set(params: SetterPaginationArg): void {
    const {page, size, capacity} = typeof params === 'function' ? params({
      page: this.current,
      size: this.size,
      capacity: this.pages,
    }) : params;
    page && this.setPage(page);
    size && this.setSize(size);
    capacity && this.setPagesCapacity(capacity);
  }

  public getMatrix(matrix?: Matrix<T, C>): Matrix<T, C> {
    matrix = matrix ?? this.wrapped.matrix;
    return this.serverModeEnabled ? matrix : matrix.slice(this.offset, this.offset + this.size);
  }

  public setLifeCycle(lifeCycle?: PaginationTableLifeCycleI): void {
    this.lifeCycle = lifeCycle;
  }

  public setPage(page: number, persistEnabled: boolean = true, preventReaction: boolean = false): void {
    const prevPage = this.current;
    const prevOffset = this.offset;
    this._pagination.configure({current: page});
    const params = this.getOnChangeParams({prevPage, prevOffset});
    if (!preventReaction) {
      this.lifeCycle?.onPageChange?.(params);
      this.lifeCycle?.onChange?.(params);
    }
    persistEnabled && this.persistService && this.persistService.save(data => ({
      ...data,
      current: page,
    }));
  }

  public setSize(size: number, persistEnabled: boolean = true, preventReaction: boolean = false): void {
    const prevSize = this.size;
    this._pagination.configure({size});
    const params = this.getOnChangeParams({prevSize});
    if (!preventReaction) {
      this.lifeCycle?.onSizeChange?.(params);
      this.lifeCycle?.onChange?.(params);
    }
    persistEnabled && this.persistService && this.persistService.save(data => ({
      ...data,
      size,
    }));
  }

  public setPagesCapacity(capacity: number, persistEnabled: boolean = true, preventReaction: boolean = false): void {
    const prevPagesCapacity = this.pages;
    this._pagination.configure({pages: capacity});
    const params = this.getOnChangeParams({prevPagesCapacity});
    if (!preventReaction) {
      this.lifeCycle?.onCapacityChange?.(params);
      this.lifeCycle?.onChange?.(params);
    }
    persistEnabled && this.persistService && this.persistService.save(data => ({
      ...data,
      capacity,
    }));
  }

  protected override _onMount(): void {
    this.persistService?.onMount?.();
  }

  protected override _onUnmount(): void {
    this.persistService?.onUnmount?.();
  }

  private getOnChangeParams(params: Partial<Pick<OnChangeParams, 'prevSize' | 'prevOffset' | 'prevPagesCapacity' | 'prevPage'>>): OnChangeParams {
    const {current: page, pages: pagesCapacity, offset, size} = this;
    const result = {
      page,
      pagesCapacity,
      offset,
      size,
      prevPage: page,
      prevPagesCapacity: pagesCapacity,
      prevOffset: offset,
      prevSize: size,
      ...params,
    };
    return Object.keys(result).reduce<OnChangeParams>((acc, key) => {
      type KeyType = keyof typeof params
      if (key.includes('prev')) {
        let comparingKey = key.replace('prev', '');
        const firstChar = comparingKey[0];
        comparingKey = comparingKey.replace(firstChar, firstChar.toLowerCase());
        acc.changed = acc[comparingKey as KeyType] !== acc[key as KeyType];
      }
      return acc;
    }, {...result, changed: false});
  }

  private adjustCapacity() {

    if (!this.serverModeEnabled && this.wrapped.rows.length) {
      this._pagination.configure({pages: Math.ceil(this.wrapped.rows.length / this.size)});
    }
  }
}
