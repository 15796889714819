import {ICreateCategoryModalState} from '../state/ICreateCategoryModalState';
import {observer} from 'mobx-react-lite';
import {Spinner, SpinnerSize} from '@symfonia/brandbook';

export interface CreateCategoryStepperProps {
  state: ICreateCategoryModalState;
}

export const CreateCategoryStepper = observer(({state}: CreateCategoryStepperProps) => {

  return state.isLoading ? (
    <div className="flex flex-nowrap min-w-[380px] justify-center">
      <Spinner size={SpinnerSize.LG}/>
    </div>
  ) : (
    <div className="flex flex-col gap-4 min-w-[380px] h-full">
      <span className="font-bold text-[20px]">{state.currentStep.title}</span>
      {state.currentStep.element}
    </div>
  );
});
