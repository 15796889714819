import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import MenuItem from '@mui/material/MenuItem';
import {Autocomplete, InputAdornment, InputLabel, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {styled} from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import {AutocompleteChangeReason} from '@mui/base';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ArrowDropDownOutlined} from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const StyledTextField = styled(TextField)(({theme}) => ({
  overflow: 'hidden',
  '& .MuiSvgIcon-root.MuiChip-deleteIcon': {
    display: 'none',
  },
  '& .MuiAutocomplete-clearIndicator': {
    display: 'none',
  },
  '& .MuiAutocomplete-popper': {
    borderRadius: '0 !important',
  },

}));

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
  border: '1px solid rgba(0,0,0, 0.3) !important',
  borderRadius: '4px !important',
  background: 'white !important',
  padding: '4px 15px !important',
}));

export type MultiSelectModel = {
  value: string,
  key: string,
}

export interface MultiSelectProps {
  listToSelect: MultiSelectModel[];
  disabled: boolean;
  userItems: MultiSelectModel[];
  setParentItemsSelected: (data: MultiSelectModel[]) => void;
}

export const MultiSelect = ({
                              listToSelect,
                              disabled,
                              userItems,
                              setParentItemsSelected,
                            }: MultiSelectProps) => {
  const intl = useIntl();
  const defaultPadding = '76px';

  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<MultiSelectModel[] | undefined>(undefined);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);

  const checkAllChange = useCallback(() => {
    setCheckedAll(!checkedAll);
    !checkedAll
      ? setParentItemsSelected(listToSelect)
      : setSelectedItems([]);
  }, [checkedAll, listToSelect, setParentItemsSelected]);

  const handleOnChange = (val: MultiSelectModel | undefined, reason: AutocompleteChangeReason) => {
    if (val === undefined) {
      return;
    }
    if (reason === 'removeOption') setCheckedAll(false);
    selectedItems?.some(e => e.key === val?.key)
      ? setSelectedItems(selectedItems?.filter(i => i.key !== val.key))
      : setSelectedItems([...selectedItems ?? [], val]);
  };

  const handleSelect = useCallback(() => {
    setParentItemsSelected(selectedItems ?? []);
  }, [selectedItems, setParentItemsSelected]);

  useEffect(() => {
    setSelectedItems(userItems);
    userItems.length === listToSelect.length ? setCheckedAll(true) : setCheckedAll(false);
  }, [listToSelect.length, userItems]);

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <InputLabel
        required
        sx={{
          lineHeight: '2.5rem',
          position: 'absolute',
          transform: 'none',
          width: defaultPadding,
        }}>{intl.formatMessage({id: Tr.addCompanyButton})}</InputLabel>

      {!!selectedItems && <Autocomplete
          onBlur={() => setPopperOpen(false)}
          disabled={disabled}
          sx={{marginLeft: defaultPadding, width: '100%', backgroundColor: 'white'}}
          multiple
          disableCloseOnSelect={true}
          id="combo-box-demo"
          size="small"
          options={listToSelect}
          value={selectedItems}
          open={popperOpen}
          limitTags={3}
          getOptionLabel={option => option.value}
          onChange={(event, value, reason, details) => {
            handleOnChange(details?.option, reason);
          }}
          onClose={handleSelect}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          popupIcon={<InputAdornment onClick={() => setPopperOpen(!popperOpen)}
                                     sx={{p: 0.1, m: 0, width: '1rem', height: '1rem'}}
                                     title={popperOpen ? 'Zamknij' : 'Otwórz'} position="end">
            <ArrowDropDownOutlined sx={{textAlign: 'center', width: '1rem', height: '1rem'}}/>
          </InputAdornment>}
          PopperComponent={(param) => {
            const {children, className, style} = param;
            return <Popper {...param} open={popperOpen} onBlur={() => setPopperOpen(false)}>
              <StyledMenuItem key={'PopperStyledComponent' + param.itemID}
                              onClick={() => checkAllChange()}
                              onMouseDown={(e) => e.preventDefault()}>
                <Checkbox
                  icon={icon}
                  id="check-all"
                  checkedIcon={checkedIcon}
                  style={{marginRight: 8}}
                  checked={checkedAll}
                  onMouseDown={(e) => e.preventDefault()}
                />
                  {intl.formatMessage({id: Tr.checkAll})}
              </StyledMenuItem>
              {/*// @ts-ignore*/}
              <Box className={className} style={style} sx={{border: '1px red', borderRadius: 0}}>{children}</Box>
            </Popper>;
          }}
          renderOption={(props, option, {selected}) => {
            return <MenuItem {...props} key={props.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{marginRight: 8}}
                checked={selectedItems.some(e => e.key === option.key)}
              />
              {option.value}
            </MenuItem>;
          }}
          style={{width: 500}}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') setPopperOpen(true);
              }}

              size={'small'} required/>
          )}
        />}
    </Box>
  );
};
