import clsx from 'clsx';
import {FC, ReactNode, useMemo} from 'react';

export type SimpleTableRowProps = {
  children: ReactNode[];
  width?: string;
  className?: string;
};

export const SimpleTableRow: FC<SimpleTableRowProps> = ({
                                                          children,
                                                          width = undefined,
                                                          className = undefined,
                                                        }) => {
  const columns = useMemo(
    () => children,
    [children],
  );

  return (
    <div className="flex w-full justify-between mb-[6px]" data-testid="table-row">
      {columns.map((column, index) => (
        <span key={index} className={clsx(width, className)}>
          {column}
        </span>
      ))}
    </div>
  );
};
