import {BaseResponse, EventJobRunner} from '../EventJobRunner';
import {
  GrantPermissionsInKsefDocument,
  GrantPermissionsInKsefMutation,
  GrantPermissionsInKsefMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {PERMISSIONS_PAGE_CONSTS} from '../../modules/earchive/pages/Account/Permissions/consts';
import {action, computed, makeObservable} from 'mobx';
import {Tr} from '@symfonia-ksef/locales/keys';
import {NavLink} from 'react-router-dom';
import React from 'react';
import {AppRoutes} from '../../modules/root/Routes';
import {intl} from '../../modules/root/IntlProvider';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface GrantPermissionKSeFI {
  permissions: Partial<GrantPermissionsInKsefMutationVariables>;

  operationContext: GrantPermissionContext;

  addForcingPermissionIdentifier(): void;

  set(data: BaseResponse<keyof GrantPermissionsInKsefMutation>['SubscribeGrantPermissionsInKSeF'] | null): void;

  reset(): void;

  setOperationContext(context: GrantPermissionContext): void;
}

export enum GrantPermissionContext {
  Add,
  Edit,
  Remove,
  None
}

const messagesMap: Record<GrantPermissionContext, Tr> = {
  [GrantPermissionContext.Add]: Tr.addKSeFPermissionInProgress,
  [GrantPermissionContext.Edit]: Tr.editKSeFPermissionInProgress,
  [GrantPermissionContext.Remove]: Tr.removeKSeFPermissionInProgress,
  [GrantPermissionContext.None]: Tr.grantKSeFPermissionSubInProgress,
};

export class GrantPermissionsJobRunner extends EventJobRunner<GrantPermissionsInKsefMutationVariables, keyof GrantPermissionsInKsefMutation> implements GrantPermissionKSeFI {
  public operationContext: GrantPermissionContext = GrantPermissionContext.None;
  private forcingPermissionsIdentifiers: string[] = [];

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('SubscribeGrantPermissionsInKSeF', WebsocketNotificationType.GrantPermission, GrantPermissionsInKsefDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.configureAlerts({
        success: (status) => {
          const messageId = messagesMap[this.operationContext];
          this.setOperationContext(GrantPermissionContext.None);
          return ({
            id: messageId,
            values: {
              link: React.createElement(NavLink, {
                children: intl.formatMessage({id: Tr.CurrentKSeFPermissions}),
                to: AppRoutes.eArchive.address + '/' + this.envObserver.currentEnv.tenantId + '/company/' + this.envObserver.currentEnv.companyId + '/account/permissions',
              }),
            },
          });
        },
        error: (errors, errorMessage) => ({
          id: Tr.grantKSeFPermissionSubError,
        }),
      },
    );
    makeObservable(this);
    this.configure(PERMISSIONS_PAGE_CONSTS.DEFAULT_PERMISSIONS_MODEL);
  }

  @computed
  public get permissions(): Partial<GrantPermissionsInKsefMutationVariables> {
    return this.variables;
  }

  public setOperationContext(context: GrantPermissionContext): void {
    this.operationContext = context;
  }

  public addForcingPermissionIdentifier(): void {
    this.permissions.Identifier = this.permissions.Identifier?.split('-').join('');
    this.permissions.Identifier && this.forcingPermissionsIdentifiers.push(this.permissions.Identifier);
  }


  @action.bound
  public reset(): void {
    this.set(null);
    this.initialize(false);
    this.setError(null);
    this.configure(PERMISSIONS_PAGE_CONSTS.DEFAULT_PERMISSIONS_MODEL);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[]) {
    if (errors.some(error => error.extensions.code === 'validation') && this.checkPermissionIsForcing()) {
      return;
    }
    super.onError(errors);
  }

  protected override afterFetch(data: BaseResponse<keyof GrantPermissionsInKsefMutation>['SubscribeGrantPermissionsInKSeF'] | null, response: BaseResponse<keyof GrantPermissionsInKsefMutation> | null, forced: boolean): void | Promise<void> {
    this.removeForcingIdentifier();
    return super.afterFetch(data, response, forced);
  }

  private removeForcingIdentifier(): void {
    this.forcingPermissionsIdentifiers = this.permissions.Identifier ? this.forcingPermissionsIdentifiers.filter(id => id !== this.permissions.Identifier) : [];

  }

  private checkPermissionIsForcing(): boolean {
    return this.forcingPermissionsIdentifiers.some(identifier => identifier === this.permissions.Identifier);
  }
}
