import {FC} from 'react';

export interface SimpleTableErrorProps {
  error?: string;
}

export const SimpleTableError: FC<SimpleTableErrorProps> = ({
  error = 'Brak elementów do wyświetlenia'
}) => (
  <div className="flex justify-center min-h-[48px] items-center">{error}</div>
);
