import {BaseModule, BaseModuleI} from '../MobXServices/BaseModule';
import {action, computed, makeObservable, observable} from 'mobx';
import {BaseVariables} from '../TableServices/DataSourceTableService';

export interface SearchServiceI extends BaseModuleI {
  get searchValue(): string;

  get loading(): boolean;

  get isValidated(): boolean;

  setSearchValue(value: string): void;
}

export interface SearchRepository {
  loading: boolean;

  configure(config: Partial<BaseVariables>): this;

  fetch(): Promise<void>;
}

export class SearchService extends BaseModule implements SearchServiceI {
  private timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  constructor(
    private readonly repository: SearchRepository,
    private onSearch?: (searchValue: string) => void,
    private autoFetching: boolean = true,
    private delay: number = 1000,
    private minLength: number = 1,
    private maxLength = 500,
  ) {
    super();
    makeObservable(this);
    this.reactionsManager.add(
      () => this.searchValue,
      () => this.handleSearch(),
    );
  }

  @observable
  private _searchValue: string = '';

  @computed
  public get searchValue(): string {
    return this._searchValue;
  }

  @computed
  public get loading(): boolean {
    return this.repository.loading;
  }

  @computed
  public get isValidated(): boolean {
    return (
      !this.searchValue.length ||
      (this.searchValue.length >= this.minLength && this.searchValue.length <= this.maxLength)
    );
  }

  @action.bound
  public setSearchValue(value: string): void {
    this._searchValue = value;
  }

  private handleSearch(): void {
    clearTimeout(this.timeoutId);
    if (this.isValidated) {
      this.timeoutId = setTimeout(() => {
        this.repository.configure({SearchText: this.searchValue.trim()});
        this.onSearch?.(this.searchValue);
        this.autoFetching && this.repository.fetch();
      }, this.delay);
    }
  }
}
