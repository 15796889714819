import {pipe} from 'ramda';
import {
  LinearPackageLimitProps,
  Pipeable,
  Range,
  StatusColorEnum,
  Thresholds,
} from './LinearPackageLimitType';

export const roundFactory = (roundTo?: number): Pipeable => (value: number) => +value.toFixed(roundTo);

export const minmaxFactory = ({min, max}: Range) => (value: number) => Math.max(Math.min(max, value), min);

export const normaliseFactory = ({
                                   min,
                                   max,
                                 }: Range, normaliseTo: number = 100): Pipeable => (value: number) => pipe(Math.floor, minmaxFactory({
  min: 0,
  max: normaliseTo,
}))(((value - min) * normaliseTo) / (max - min));

export const isFloat = (value: number) => value % 1 !== 0;

export const sortThresholds = (thresholds: Thresholds): Thresholds => [...thresholds].sort((a, b) => a - b) as Thresholds;

export const matchMessage = (params: Pick<LinearPackageLimitProps, 'usage' | 'unit' | 'limit' | 'formatted'>) => {
  if (params.formatted?.limit !== undefined || params.formatted?.usage !== undefined) {
    return createFormattedMessage({
      limit: params.formatted?.limit ?? params.limit,
      usage: params.formatted?.usage ?? params.usage,
    });
  }
  return createMessage(params);
};

export const createMessage = (opt: { usage?: number, limit?: number, unit?: string, }) => `${withComma(opt.usage?.toFixed?.(isFloat(opt.usage) ? 2 : 0))}${opt.unit ?? ''}${opt.limit === undefined ? '' : `/${withComma(opt.limit.toFixed(isFloat(opt.limit) ? 2 : 0))}${opt.unit ?? ''}`}`;

export const createFormattedMessage = (opt: { usage: number | string, limit: number | string, }) => `${opt.usage}/${opt.limit}`;

export const withComma = (value?: number | string) => value ? value.toString().replace('.', ',') : '';

export const matchBarColor = (usage: number, thresholds: Thresholds, opt?: {
  isInactive?: boolean, sort?: boolean, loading?: boolean
}): StatusColorEnum => {
  if (opt?.loading) {
    return StatusColorEnum.Normal;
  }
  if (opt?.isInactive) {
    return StatusColorEnum.Inactive;
  }
  const [, criticalUsage, exceededUsage] = opt?.sort ? sortThresholds(thresholds) : thresholds;

  if (usage > exceededUsage) {
    return StatusColorEnum.Exceeded;
  }

  if (usage > criticalUsage) {
    return StatusColorEnum.Critical;
  }
  return StatusColorEnum.Normal;
};

