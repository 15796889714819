import {ExtendedTable} from '../../../../../../../../root/components/ExtendedTable';
import {CategoryAccountService} from './CategoryAccount.service';
import {FC} from 'react';
import {observer} from 'mobx-react-lite';

interface CategoryTableProps {
  service: CategoryAccountService;
}

export const CategoryAccountsTable: FC<CategoryTableProps> = observer(({service}) => {
  return <ExtendedTable service={service.tableService} loaderEnabled className="w-full"/>;
});
