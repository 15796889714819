import {FC, useEffect} from 'react';
import {EnvironmentStatus} from '@symfonia-ksef/graphql';
import {useParams} from 'react-router-dom';
import {HubView} from '../modules/HubModule/Hub.view';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const LoginHub: FC = observer(() => {
  const {
    environments: {
      tenants,
    },
    tenant: {
      setSelectedTenantForHub,
      setRootTenantOrEnvironment,
      setRootTenant,
      state: {
        rootTenant,
        selectedTenantForHub,
      },
    },
  } = earchiveState;

  // eslint-disable-next-line no-unsafe-optional-chaining
  const tenantId = earchiveState.tenant.state.rootTenant?.Tenant?.Id;
  const setHeader = earchiveState.layoutState.setHeader;
  const setHeaderSecondLine = earchiveState.layoutState.setHeaderSecondLine;
  const setIsDemo = earchiveState.layoutState.setIsDemo;

  useEffect(() => {
    // earchiveState.company.set(null);
    setHeader({name: ''});
    setIsDemo(false);
    // read selectedTenantForHub if user was logged in or was redirected from earchive,
    // then check, if user had rootTenant set with admin privilege,
    // if not - set default tenant from the list
    let selectedTenant;
    //check url tenantId param on return from /administration
    if (tenantId) {

      selectedTenant = earchiveState.tenant.findTenantById(tenantId, earchiveState.environments);
      setRootTenantOrEnvironment(selectedTenant?.Id, earchiveState.environments);
      setSelectedTenantForHub(tenantId, earchiveState.environments);
    } else {
      selectedTenant = rootTenant?.Status === EnvironmentStatus.Active ? rootTenant?.Tenant : tenants[0];
      setHeaderSecondLine(selectedTenant?.Name ?? '');
      setRootTenantOrEnvironment(selectedTenant?.Id, earchiveState.environments);
      setSelectedTenantForHub(selectedTenant?.Id, earchiveState.environments);
    }

  }, [earchiveState.environments, tenantId]);

  return <HubView/>;
});
