import {ConfirmModal, Dropdown, useDeviceViewport, TagSize} from '@symfonia/symfonia-ksef-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {EnvironmentListItemFragment, EnvironmentType, IdentifierType} from '@symfonia-ksef/graphql';
import {convertToMapDropdownListOption} from '../../../common/helpers/baseFilterHelpers';
import {DropdownWidth, TagColor} from '@symfonia/brandbook';
import {isAdmin} from '../../../common/helpers/checkCurrentPath';
import {Tr} from '@symfonia-ksef/locales/keys';
import {AppRoutes} from '../../../root/Routes';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {isDesktop} from '../../../common/helpers/checkCurrentPlatform';
import {Text} from '../../../root/components/Text';

export type EnvironmentItem = EnvironmentListItemFragment & { displayText: string };

export const CompanyDropdownComponent = observer(() => {
  const intl = useIntl();

  const [filteredListItems, setFilteredListItems] = useState<EnvironmentItem[]>([]);
  const [selectedOptionKey, setSelectedOptionKey] = useState<React.Key[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const {
    company,
    environments: {environments},
    tenant: {state: {moduleEnvironmentType, selectedTenantForHub, rootTenant, selectedEnvForConfirmation}},
  } = earchiveState;

  const onChange = earchiveState.layoutState.setOpenDrawer;
  const {isPhone, isSmallPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);

  const listItems = useMemo<EnvironmentItem[]>(() => {
    let listItemsList: EnvironmentListItemFragment[] = [];
    if (moduleEnvironmentType === EnvironmentType.Tenant) {
      listItemsList = environments.filter(
        el =>
          el.Tenant &&
          el.Tenant.Id &&
          rootTenant?.EnvironmentId !== el.EnvironmentId &&
          el.Type === EnvironmentType.Tenant,
      );
    }
    if (moduleEnvironmentType === EnvironmentType.Company) {
      listItemsList = environments.filter(el => {
        return (
          el.Tenant &&
          el.Tenant.Id &&
          el.Type === EnvironmentType.Company &&
          (rootTenant?.Tenant?.Id === el.Tenant.Id || selectedTenantForHub?.Id === el.Tenant.Id)
        );
      });
    }
    return listItemsList.map(listItem => ({
      ...listItem,
      displayText:
        listItem.Company && listItem.Company.Identifier && listItem.Name
          ? `${listItem.Company.Identifier} - ${listItem.Name}`
          : listItem.Name ?? '',
    }));
  }, [
    environments,
    moduleEnvironmentType,
    rootTenant?.EnvironmentId,
    rootTenant?.Tenant?.Id,
    selectedTenantForHub?.Id,
  ]);

  const filterData = useCallback(
    (query?: string) => {
      if (!query) {
        setFilteredListItems(listItems);
        return;
      }
      setFilteredListItems(
        listItems.filter(({displayText, Company}) => {
          const nameMatched = displayText.toLowerCase().includes(query.toLowerCase().trim());
          const nipMatched =
            Company?.IdentifierType === IdentifierType.Nip ? displayText.includes(query.toLowerCase().trim()) : false;
          return nameMatched || nipMatched;
        }),
      );
    },
    [listItems],
  );

  const optionsConverted = convertToMapDropdownListOption(listItems, el => ({
    label: el.displayText ?? '',
    value: el.EnvironmentId,
    tooltip: el.displayText,
    tag: el.IsDemo ? {color: TagColor.BLUE1, text: 'DEMO', size: TagSize.VSM} : undefined,
  }));

  useEffect(() => {
    filterData();
  }, [filterData]);

  const actualSelectedEnv = useCallback(() => {
    optionsConverted.forEach((el, key) => {
      if (el.value === company.companyId) {
        setSelectedOptionKey([key]);
      }
    });
  },[company.companyId, optionsConverted]);

  useEffect(() => {
    actualSelectedEnv();
  }, [company.companyId]);

  const handleClickOnListItem = useCallback(
    (e: React.Key[]) => {
      setSelectedOptionKey(e);
      const selectedOption = optionsConverted.get(e[0])?.value;
      const selectedEnv = listItems.find(e => e.EnvironmentId === selectedOption);
      if (selectedEnv !== undefined) {
        earchiveState.tenant.set({selectedEnvForConfirmation: selectedEnv});
      }
      setModalOpen(true);
    },
    [listItems, optionsConverted],
  );

  const switchContextItem = useCallback(
    async (envToSwitch?: EnvironmentListItemFragment) => {
      if (!envToSwitch) {
        return;
      }
      setModalOpen(false);
      await company.setEnvId(envToSwitch.EnvironmentId).fetch();
      earchiveState.tenant.set({rootTenant: envToSwitch});

      if (envToSwitch?.Type === EnvironmentType.Tenant) {
        earchiveState.tenant.storeTenantId(envToSwitch?.Tenant?.Id);
        company.removeStoredCompanyId();
        onChange(false);
        navigate(AppRoutes.administration.address + '/modules/users');
      }

      if (envToSwitch?.Type === EnvironmentType.Company) {
        earchiveState.tenant.storeTenantId(envToSwitch?.Tenant?.Id);
        company.storeCompanyId(envToSwitch.EnvironmentId);
        onChange(false);
        navigate(
          AppRoutes.eArchive.address +
          '/' +
          envToSwitch?.Tenant?.Id +
          '/company/' +
          envToSwitch?.EnvironmentId +
          '/documents/purchase',
        );
      }
    },
    [navigate, onChange, company],
  );

  const prepareConfirmModalContent = () => {
    if (isAdmin()) {
      return (
        <FormattedMessage
          id={Tr.switchTenantPermissionContent}
          values={{
            company: (
              <span className={'ellipsis-w-modal'} style={{fontWeight: 'bold'}}>
                {selectedEnvForConfirmation?.Name}
              </span>
            ),
            br: <br/>,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        id={Tr.switchCompanyPermissionContent}
        values={{
          company: (
            <span className={'ellipsis-w-modal'} style={{fontWeight: 'bold'}}>
              {selectedEnvForConfirmation?.Name}
            </span>
          ),
          br: <br/>,
        }}
      />
    );
  };

  return (
    <>
      <ConfirmModal
        key={selectedEnvForConfirmation?.EnvironmentId}
        title={
          isAdmin()
            ? intl.formatMessage({id: Tr.switchTenantPermissionTitle})
            : intl.formatMessage({id: Tr.switchCompanyPermissionTitle})
        }
        content={prepareConfirmModalContent()}
        confirmButtonName={intl.formatMessage({id: Tr.switchTenantContinueButton})}
        cancelButtonName={intl.formatMessage({id: Tr.switchTenantCancelButton})}
        open={modalOpen}
        contentClassName="contextSwitcherContent"
        onCancel={() => {
          setModalOpen(false);
          actualSelectedEnv();
        }}
        onConfirm={() => switchContextItem(selectedEnvForConfirmation)}
      />
      <div className={(!isPhone && !isSmallPhone) ? 'w-[217px] mr-[10px] max-w-[217px]' : 'w-full'}>
      {!isDesktop ?
          <Dropdown
            width={DropdownWidth.FULL}
            useSearch={true}
            listWithTooltip={true}
            values={new Set(selectedOptionKey)}
            options={optionsConverted}
            displaySelected={true}
            onChange={e => handleClickOnListItem(e)}
          />
          : <Text className="text-center font-sm">{company.identifier} - {company.name}</Text>
        }
      </div>
    </>
  );
});
