import {InvoicePostingStatus} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';

export interface PostingStatusText {
  text: string;
  defaultColor: string;
}

export const InvoicePostingStatusToText = (value: InvoicePostingStatus) => {

  switch (value) {
    case InvoicePostingStatus.DecreeSent:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_decree_sent}),
        defaultColor: 'green',
      } as PostingStatusText;
    case InvoicePostingStatus.DecreeDownloaded:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_decree_downloaded}),
        defaultColor: 'green',
      } as PostingStatusText;
    case InvoicePostingStatus.NotAllowed:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_notAllowed}),
        defaultColor: 'black',
      } as PostingStatusText;
    case InvoicePostingStatus.Posted:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_posted}),
        defaultColor: 'orange',
      } as PostingStatusText;
    case InvoicePostingStatus.Posting:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_posting}),
        defaultColor: 'black',
      } as PostingStatusText;
    case InvoicePostingStatus.PostingFailed:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_postingFailed}),
        defaultColor: 'black',
      } as PostingStatusText;
    case InvoicePostingStatus.PostingIncomplete:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_postingIncomplete}),
        defaultColor: 'black',
      } as PostingStatusText;
    case InvoicePostingStatus.ReadyForPosting:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_readyForPosting}),
        defaultColor: 'black',
      } as PostingStatusText;
    case InvoicePostingStatus.Undefined:
      return {
        text: intl.formatMessage({id: Tr.postingStatus_undefined}),
        defaultColor: 'black',
      } as PostingStatusText;
    default:
      return {text: '', defaultColor: 'black'} as PostingStatusText;
  }
};
