import React, {FC, ReactNode} from 'react';
import {Icon, IconColor, IconSvg, Tooltip} from '@symfonia/brandbook';

export interface ModalHeaderWithIconProps {
  title: string;
  tooltipContent: string | ReactNode;
}

export const ModalHeaderWithIcon: FC<ModalHeaderWithIconProps> = ({title, tooltipContent}) => {

  return (
    <div className="relative">
      {title}
      <Tooltip text={tooltipContent}>
        <div className="absolute top-0 right-0">
          <Icon svg={IconSvg.INFO} color={IconColor.GREEN_500}/>
        </div>
      </Tooltip>
    </div>
  );
};
