/* eslint camelcase: 0 */
import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import {Popover} from '@mui/material';
import {
  Icon,
  IconSize,
  IconSvg,
  Input,
  InputProps,
  InputSize,
  InputType,
  InputWidth,
  Notification,
  NotificationSize,
  NotificationVariant,
} from '@symfonia/brandbook';

export type NumberRange = {
  from: string;
  to: string;
};

export type InputNumberRangeProps = Omit<InputProps,
  | 'min'
  | 'max'
  | 'type'
  | 'leftIcon'
  | 'rightIcon'
  | 'onIconClick'
  | 'noOutline'
  | 'mask'
  | 'autocomplete'
  | 'clearable'
  | 'value'
  | 'defaultValue'
  | 'onInput'
  | 'onChange'> & {
  defaultValue?: NumberRange;
  value?: NumberRange;
  onChange: (output: NumberRange) => unknown;
  min?: number;
  max?: number;
  revertToValue?: NumberRange;
  placeholderFrom?: string;
  placeholderTo?: string;
  testId?: string;
};

export const InputNumberRange: FC<InputNumberRangeProps> = ({
                                                              placeholder = '',
                                                              label = '',
                                                              notification = '',
                                                              disabled = false,
                                                              isError = false,
                                                              defaultValue = undefined,
                                                              value = undefined,
                                                              onChange = () => undefined,
                                                              width = InputWidth.BASE,
                                                              size = InputSize.MD,
                                                              revertToValue = undefined,
                                                              placeholderFrom,
                                                              placeholderTo,
                                                              testId,
                                                            }) => {
  const ref = useRef<HTMLDivElement>(null);

  const isControlledInput = (() => {
    if (defaultValue !== undefined && value !== undefined) {
      throw new Error('Symfonia:brandbook:Input: mutually exclusive parameters');
    }
    return value !== undefined;
  })();


  const inputValue_From = defaultValue?.from || value?.from || '';
  const inputValue_To = defaultValue?.to || value?.to || '';

  const [isMouseDown_From, setIsMouseDown_From] = useState<boolean>(false);
  const [isFocus_From, setIsFocus_From] = useState<boolean>(false);

  const [isMouseDown_To, setIsMouseDown_To] = useState<boolean>(false);
  const [isFocus_To, setIsFocus_To] = useState<boolean>(false);


  const handleInputChange_From = (_nextFrom: string): void => {
    onChange({from: _nextFrom, to: inputValue_To});
  };

  const handleInputBlur_From = () => {
    setIsMouseDown_From(false);
    setIsFocus_From(false);
  };

  const handleInputChange_To = (_nextTo: string): void => {
    onChange({from: inputValue_From, to: _nextTo});
  };

  const handleInputBlur_To = () => {
    setIsMouseDown_To(false);
    setIsFocus_To(false);
  };

  const isFocus = useMemo(() => isFocus_From || isFocus_To, [isFocus_From, isFocus_To]);
  const isMouseDown = useMemo(() => isMouseDown_From || isMouseDown_To, [isMouseDown_From, isMouseDown_To]);

  const renderNotification = () => (
    <Notification
      text={notification}
      variant={isError ? NotificationVariant.ERROR : NotificationVariant.INFO}
      size={NotificationSize.SM}
      className="mt-2"
    />
  );

  return (
    <div
      className={clsx({
        'inline-flex': true,
        'w-full': width === InputWidth.FULL,
        'w-80': width === InputWidth.BASE,
        'w-fit': width === InputWidth.FIT,
      })}
      ref={ref}
    >
      <div className="flex flex-col w-full">
        {label && <div className="text-normal leading-normal mb-[8px] font-quicksand">{label}</div>}
        <div
          className={clsx({
            'h-10': size === InputSize.SM,
            'h-12': size === InputSize.MD,
            'border rounded-lg flex items-center text-base font-quicksand': true,
            'border-grey-300 hover:border-primary-400': !isError && !disabled && !(isFocus),
            'border-red-500': isError && !disabled,
            'border-primary-400': !isError && !disabled && (isFocus_From),
            'outline outline-2 outline-yellow-500': !isError && !disabled && isFocus && !isMouseDown,
            'border-grey-300': disabled,
            'border-red-500 focus-within:outline-yellow-500': isError,
          })}
        >
          <div className={clsx('grow shrink relative inline-flex items-center')}>
            <Input
              type={InputType.NUMBER}
              width={InputWidth.FULL}
              value={inputValue_From.toString()}
              onMouseDown={() => setIsMouseDown_From(true)}
              onFocus={() => setIsFocus_From(true)}
              onBlur={handleInputBlur_From}
              disabled={disabled}
              onChange={nextFrom => handleInputChange_From(nextFrom)}
              placeholder={placeholderFrom}
              noBorder
              noOutline
              testId={`${testId}-FromValue`}
            />
            {revertToValue?.from && (
              <Icon
                svg={IconSvg.UNDO}
                size={IconSize.MD}
                className={clsx(' grow-0 shrink-0', {
                  'filter-primary-500 cursor-pointer': !disabled,
                  'filter-grey-300': disabled,
                })}
              />
            )}
          </div>
          <Icon
            svg={IconSvg.KEYBOARD_ARROW_RIGHT}
            size={IconSize.MD}
            className={clsx({
              'grow-0 shrink-0 mx-2': true,
              'filter-grey-300': disabled,
              'text-grey-500': !disabled,
              'text-grey-800':
                !disabled,
            })}
          />
          <div className={clsx('grow shrink relative inline-flex items-center')}>
            <Input
              type={InputType.NUMBER}
              width={InputWidth.FULL}
              value={inputValue_To.toString()}
              onMouseDown={() => setIsMouseDown_To(true)}
              onFocus={() => setIsFocus_To(true)}
              onBlur={handleInputBlur_To}
              disabled={disabled}
              onChange={nextTo => handleInputChange_To(nextTo)}
              placeholder={placeholderTo}
              noBorder
              noOutline
              className="pl-0"
              testId={`${testId}-ToValue`}
            />
            {/*{revertToValue?.to && (*/}
            {/*  <Icon*/}
            {/*    svg={IconSvg.UNDO}*/}
            {/*    size={IconSize.MD}*/}
            {/*    className={clsx('m-2 grow-0 shrink-0', {*/}
            {/*      'filter-primary-500 cursor-pointer': !disabled,*/}
            {/*      'filter-grey-300': disabled,*/}
            {/*    })}*/}
            {/*    onClick={() => updateInputValue_To(revertToValue?.to)}*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>
        {notification && renderNotification()}
      </div>
      <Popover
        open={false}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '580px',
            height: '320px',
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {'popover'}
      </Popover>
    </div>
  );
};
