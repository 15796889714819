import {Icon, IconColor, IconSvg} from '@symfonia/brandbook';

type InputProps = {
  value: any;
};

type ClearButtonProps = {
  handleClear(event: React.MouseEvent): void;
  disabledClearButton?: boolean;
};

type ComponentWithClearButtonType<T> = React.ComponentType<T & ClearButtonProps & InputProps>;

export const withClearButton =
  <TBaseComponentProps extends object>(
    InputComponent: React.ComponentType<TBaseComponentProps & InputProps>,
  ): ComponentWithClearButtonType<TBaseComponentProps> | React.ComponentType<TBaseComponentProps> =>
    (props: TBaseComponentProps & ClearButtonProps & InputProps) => {

      if (props.value === null || props.value === undefined) return (
        <div className="pr-[calc(1rem_+_4px)]">
          <InputComponent {...props}/>
        </div>
      );

      return (
        <div className="flex flex-row items-center w-full">
          <InputComponent {...props}/>
          <button
            onClick={(event) => props.disabledClearButton ? null : props.handleClear(event)}
          >
            <Icon
              svg={IconSvg.CLOSE}
              color={props.disabledClearButton ? IconColor.GREY_200 : IconColor.GREEN_600}
              className={'cursor-pointer'}
            />
          </button>
        </div>
      );
    };
