import {observer} from 'mobx-react-lite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AnyObject} from 'yup/es/types';
import {FlattenAction, MenuAction, MultipleActionsConfig} from '../../types/MenuActions';
import React, {useCallback, useMemo, useState} from 'react';
import {MobileContextualMenu} from '../ContextualMenu/MobileContextualMenu';

export const MobileMultipleActionsItems = observer(<T extends AnyObject = AnyObject>({
                                                                                       selectionState,
                                                                                       actions,
                                                                                     }: MultipleActionsConfig<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(() => setIsOpen(false), []);
  const menuItems = useMemo<MenuAction[]>(() => {
    return actions
      .filter(action => !action.hidden)
      .reduce<FlattenAction<T>[]>((flatten, current) => {
        if (!current.children?.length) {
          flatten.push(current);
        }
        flatten.push(...current?.children ?? []);
        return flatten;
      }, [])
      .map<MenuAction>(({onClick, ...flattenActions}) => ({
        onClick: () => onClick?.(selectionState),
        ...flattenActions,
      }));
  }, [actions]);
  return <>
    <MoreVertIcon onClick={() => setIsOpen(true)}/>
    <MobileContextualMenu isOpen={isOpen} actions={menuItems} onClose={onClose}/>
  </>;
});
