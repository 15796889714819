import {
  makeObservable,
  action,
  observable,
  IObservableArray,
  ObservableMap,
  computed,
  autorun,
  reaction,
  when,
} from 'mobx';
import {ISearchTreeViewState} from './ISearchTreeViewState';
import {TreeViewDropdownModel} from '../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {treeNodeHelper} from '../../../helpers/TreeNodeHelper';
import _ from 'lodash';

export class SearchTreeViewState implements ISearchTreeViewState {
  @observable
  public searchTerm: string = '';

  @observable
  public nodesMap: ObservableMap<string, TreeViewDropdownModel> = new ObservableMap();

  @observable
  public expandedIds: IObservableArray<string> = observable([]);

  @observable
  public selectedId: string | undefined;

  @observable
  private initialSelectedId: string | undefined;

  @computed
  public get selected(): TreeViewDropdownModel | undefined {
    return this.selectedId !== undefined ? this.nodesMap.get(this.selectedId) : undefined;
  }

  @observable
  public nodes: IObservableArray<TreeViewDropdownModel> = observable([]);

  @computed
  public get hasDataToDisplay(): boolean {
    return this.nodesMapValues.length > 0 && this.nodesMapValues.some(x => x.isHidden === false);
  }

  @computed
  private get nodesMapValues(): TreeViewDropdownModel[] {
    return Array.from(this.nodesMap.values());
  }

  constructor() {
    makeObservable(this);
  }

  @action
  public setSearchTerm(term: string): void {
    this.searchTerm = term;

    if (term === '') {
      this.nodesMap.forEach(value => {
        value.isHidden = false;
      });
      this.setExpanded([]);
      return;
    }

    this.debounceSearch();
  }

  @action
  public setNodes(nodes: TreeViewDropdownModel[]) {
    this.nodesMap.clear();
    nodes.forEach(node => this.nodesMap.set(node.key, node));
    this.reconstructNodesTree();
  }

  @action
  public setSelected(selectedId: string): void {
    if (this.nodesMap.get(selectedId)?.isSelectable) {
      this.selectedId = selectedId;
      return;
    }
    this.selectedId = undefined;
  }

  @action
  public setExpanded(expandedIds: string[]): void {
    this.expandedIds.replace(expandedIds);
  }

  @action
  public setInitialSelected(selectedId: string | undefined): void {
    if (selectedId === null || selectedId === undefined) {
      return;
    }

    this.setSelected(selectedId);
    this.initialSelectedId = selectedId;
    this.setExpanded(this.addAllAncestorsToList([selectedId]));
  }

  @action
  public reset() {
    this.setSearchTerm('');
    if (this.initialSelectedId !== undefined) {
      this.setInitialSelected(this.initialSelectedId);
    }
  }

  @action
  private search() {
    let matchingElementsIds: string[] = [];

    this.nodesMap.forEach(value => {
      value.isHidden = true;
    });

    this.nodesMap.forEach(value => {
      if (value.value.toLowerCase().includes(this.searchTerm.toLowerCase())) {
        matchingElementsIds.push(value.key);
        const pushChildrenIds = (val: TreeViewDropdownModel | undefined) => {
          if (val) {
            val.childrenIds?.forEach(childrenId => {
              if (!matchingElementsIds.includes(childrenId)) {
                matchingElementsIds.push(childrenId);
              }
            });

            val.children?.forEach(valChild => {
              pushChildrenIds(valChild);
            });
          }
        };
        pushChildrenIds(value);
      }
    });

    matchingElementsIds = this.addAllAncestorsToList(matchingElementsIds);

    matchingElementsIds.forEach(key => {
      const node = this.nodesMap.get(key);
      if (node) {
        node.isHidden = false;
      }
    });
    this.setExpanded(matchingElementsIds);
  }

  @action
  private reconstructNodesTree() {
    this.nodes.replace(treeNodeHelper.mapListToTree(this.nodesMapValues, this.nodesMap));
  }

  private debounceSearch = _.debounce(async () => this.search(), 500);

  private addAllAncestorsToList(inputList: string[]): string[] {
    let outputList: TreeViewDropdownModel[] = [];

    inputList.forEach(current => {
      const node = this.nodesMap.get(current);
      if (node) {
        outputList = outputList.concat(treeNodeHelper.findAllNodeAncestors(node, this.nodesMap));
      }
    });

    return _.uniqBy(outputList, x => x.key).map(x => x.key);
  }
}
