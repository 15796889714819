import {CSSProperties, ReactElement, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper, {PopperPlacementType} from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Info from '@mui/icons-material/Info';
import {SxProps} from '@mui/material';

export interface TextFieldTooltipProps {
  onClickedBlocked?: boolean;
  tooltipHeaderKey?: string;
  tooltipContentKey: string | ReactElement;
  tooltipContentLink?: {
    linkPath: string,
    content: string
  };
  children?: ReactElement;
  placement?: PopperPlacementType;
  infoColor?: boolean;
  iconButtonStyle?: CSSProperties;
  preventOpen?: boolean;
  hoverMode?: boolean;
  zIndex?: number | undefined;
  width?: string | undefined;
  infoCustomColor?: SxProps;
  disabled?: boolean;
}

export const TextFieldTooltip = ({
                                   children,
                                   tooltipHeaderKey,
                                   tooltipContentKey,
                                   tooltipContentLink,
                                   placement,
                                   infoColor,
                                   iconButtonStyle,
                                   onClickedBlocked,
                                   preventOpen,
                                   hoverMode,
                                   zIndex,
                                   width,
                                   infoCustomColor,
                                   disabled,
                                 }: TextFieldTooltipProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleClick = () => {
    if (onClickedBlocked) return;
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClickAway = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleHover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!hoverMode) {
      return;
    }
    if (e.type === 'mouseleave') {
      setOpen(false);
    }
    if (e.type === 'mouseenter') {
      !preventOpen && setOpen(true);
    }
  };

  const intl = useIntl();
  const linkTranslation = tooltipContentLink !== undefined ? intl.formatMessage({id: tooltipContentLink?.content}) : undefined;

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <>
      {children ? (
        <div ref={anchorRef} onMouseEnter={handleHover} onMouseLeave={handleHover}
             onClick={handleClick} className={'flex'}>
          {children}
        </div>
      ) : (
        <IconButton aria-label="toggle password visibility" onMouseEnter={handleClick}
                    onMouseLeave={() => setOpen(false)} onClick={handleClick} edge="end" ref={anchorRef}
                    style={iconButtonStyle}>
          <Info color={infoColor ? 'info' : 'primary'} sx={infoCustomColor}/>
        </IconButton>
      )}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement={placement || 'right'} transition
              sx={{zIndex: zIndex ? zIndex : 1}}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left bottom',
            }}
          >
            <Paper
              elevation={3}
              sx={{
                m: 2,
                p: 2.5,
                zIndex: 10,
                width: width ? width : 274,
              }}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  {tooltipHeaderKey && <Typography>
                    <strong>
                      <FormattedMessage id={tooltipHeaderKey}/>
                    </strong>
                  </Typography>}

                  {typeof tooltipContentKey === 'string' ? (
                    <Typography className="pre-line-typography" sx={{paddingTop: tooltipHeaderKey ? '1rem' : 0}}>
                      <FormattedMessage
                        id={tooltipContentKey}
                        values={tooltipContentLink !== undefined ? {
                          link: <a href={tooltipContentLink?.linkPath} target="_blank"
                                   rel="noreferrer">{linkTranslation}</a>,
                        } : {br: <br/>}}/>
                    </Typography>
                  ) : (
                    tooltipContentKey
                  )}

                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
