import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import {FC} from 'react';

export enum WarningIconType {
  NoAccounts,
  Validated,
  Warn,
  Error
}

export type WarningIconProps = { type?: WarningIconType, hidden?: boolean }

export const WarningIcon: FC<WarningIconProps> = ({type = WarningIconType.Warn, hidden}) => {

  if (hidden) {
    return null;
  }

  if (type === WarningIconType.Validated) {
    return <DoneIcon color="success"/>;
  }

  if (type === WarningIconType.NoAccounts) {
    return <ErrorIcon fontSize="small" color="success"/>;
  }

  const color = 'error';
  const style = {color: '#FF9F47'};

  return <ErrorIcon fontSize="small" className='pointer-events-none' {...(type === WarningIconType.Error ? {color} : {style})}/>;
};
