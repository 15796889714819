import {TableHeader, TableHeaderProps, TableRowProps} from '@symfonia/brandbook';
import {Children, FC, memo, ReactElement, useCallback, useEffect, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {TableRowReflection} from './TableRowReflection';

export enum TableRowHeight {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

export enum TableSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TableSort = {
  name: string;
  direction: TableSortDirection;
};

export type TableProps = {
  sortBy?: TableSort;
  onSortChange?: (sortBy: TableSort) => void;
  header: TableHeaderProps[];
  headerClassName?: string;
  innerClassName?: string;
  children: ReactElement<TableRowProps> | ReactElement<TableRowProps>[];
  className?: string;
  tableRowHeight?: TableRowHeight;
  lastColumnSticky?: boolean;
};

const rowStyles = {
  [TableRowHeight.SM]: {
    header: 'text-[12px]',
    column: 'min-h-[32px]',
    cell: 'text-[14px]',
  },
  [TableRowHeight.MD]: {
    header: 'text-[14px]',
    column: 'min-h-[40px]',
    cell: 'text-[14px]',
  },
  [TableRowHeight.LG]: {
    header: 'text-[16px]',
    column: 'min-h-[48px]',
    cell: 'text-[16px]',
  },
};

export const Table: FC<TableProps> = memo(
  ({
    header,
    headerClassName = '',
    innerClassName = '',
    children,
    tableRowHeight = TableRowHeight.LG,
    className = undefined,
    sortBy = undefined,
    onSortChange = () => undefined,
    lastColumnSticky = false,
  }) => {
    useEffect(() => {
      header.forEach(hr => {
        if (header.filter(_hr => _hr.name === hr.name).length > 1) {
          throw new Error('Symfonia: Nazwa kolumny tabeli musi być unikatowa');
        }
      });
    }, [header]);

    const widthClassNames: string[] = useMemo(
      () =>
        header.map(({width}) =>
          width !== undefined && /^w-.*?/.test(width) ? width : `wp-${Math.round(100 / header.length)}`,
        ),
      [header],
    );

    const styles = useMemo(
      () => ({
        component: twMerge(clsx('overflow-auto border border-solid border-grey-100 rounded-[2px]', className)),
        table: twMerge(clsx('flex min-w-full w-fit flex-col', innerClassName)),
        column: `grow shrink leading-[24px] pl-[16px] items-center inline-flex ${rowStyles[tableRowHeight].column}`,
        header: twMerge(
          clsx(`flex bg-grey-50 font-quicksand font-bold`, rowStyles[tableRowHeight].header, headerClassName),
        ),
        headerStickyLastColumnElement: 'sticky right-0 bg-grey-50',
        rowStickyLastColumn: 'last:sticky last:right-0 last:bg-white',
        cell: rowStyles[tableRowHeight].cell,
      }),
      [className, rowStyles, tableRowHeight, headerClassName, innerClassName],
    );

    const rows = useMemo(
      () =>
        Children.map(children, child => (
          <TableRowReflection
            {...child.props}
            widths={widthClassNames}
            columnClassName={twMerge(
              clsx(
                styles.column,
                styles.cell,
                {[styles.rowStickyLastColumn]: lastColumnSticky},
                child.props.columnClassName,
              ),
            )}
            header={header}
          />
        )),
      [children, widthClassNames, lastColumnSticky, styles.column, styles.cell, styles.rowStickyLastColumn],
    );

    const handleSortChange = useCallback(
      (name: string, defaultDirection: TableSortDirection) => {
        if (!onSortChange) {
          return;
        }
        if (sortBy && sortBy.name === name) {
          if (sortBy.direction === TableSortDirection.ASC) {
            onSortChange({name, direction: TableSortDirection.DESC});
          } else {
            onSortChange({name, direction: TableSortDirection.ASC});
          }
        } else {
          onSortChange({name, direction: defaultDirection});
        }
      },
      [sortBy?.name, sortBy?.direction, onSortChange],
    );

    const headerComponent = useMemo(
      () => (
        <div className={clsx(styles.header, {hidden: header.every(el => !el.title?.length)})}>
          {header.map(({className: headerColClassName, ...headerProps}, index) => {
            const direction = headerProps.sortable ? sortBy?.direction : undefined;
            return (
              <TableHeader
                key={headerProps.name}
                className={clsx(
                  styles.column,
                  widthClassNames[index],
                  {
                    [styles.headerStickyLastColumnElement]: lastColumnSticky && index === header.length - 1,
                  },
                  headerColClassName,
                )}
                {...headerProps}
                onSortChange={() =>
                  handleSortChange(headerProps.name, headerProps.defaultSortDirection || TableSortDirection.DESC)
                }
                direction={sortBy?.name === headerProps.name ? direction : undefined}
              />
            );
          })}
        </div>
      ),
      [
        header,
        widthClassNames,
        sortBy?.name,
        sortBy?.direction,
        styles.headerStickyLastColumnElement,
        styles.column,
        styles.header,
        lastColumnSticky,
      ],
    );

    return (
      <div className={styles.component}>
        <div className={styles.table}>
          {headerComponent}
          <div>{rows}</div>
        </div>
      </div>
    );
  },
);
