import {
  GetInvoicesDocument,
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  InvoiceBound,
  InvoiceItemFragment,
} from '@symfonia-ksef/graphql';
import {
  BaseTableDataRepositoryService,
  BaseTableDataRepositoryServiceI,
} from '../../../root/services/TableServices/DataSourceTableService';
import {computed, makeObservable} from 'mobx';
import {InvoicesFiltersState} from './InvoicesFilters/InvoicesFiltersState';
import {intl} from '../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';

export type InvoiceItemType = InvoiceItemFragment & { Description: string }

export interface InvoicesRepositoryI extends BaseTableDataRepositoryServiceI<keyof GetInvoicesQuery, GetInvoicesQuery, GetInvoicesQueryVariables> {
  get items(): Array<InvoiceItemType>;
}

export class InvoicesRepository extends BaseTableDataRepositoryService<'GetInvoices', GetInvoicesQuery, GetInvoicesQueryVariables> implements InvoicesRepositoryI {
  constructor(private readonly filters: InvoicesFiltersState) {
    super('GetInvoices', GetInvoicesDocument, envObserver, earchiveState);
    this.configure({SearchText: ''});
    makeObservable(this);
  }

  @computed
  public get items(): Array<InvoiceItemType> {
    if (!this.data?.Items) {
      return [];
    }
    return this.data.Items.map(item => ({
      ...item,
      Description: this.createInvoiceDescription(item),
    }));
  }

  public override checkIsReady(): boolean {
    return !!(super.checkIsReady() && typeof this.variables.Skip === 'number' && this.variables.Take);
  }

  protected override beforeFetch(): void {
    this.configure({FiltersFields: this.filters.filtersForQuery});
  }

  private createInvoiceDescription(invoice: InvoiceItemFragment): string {
    const isExternal = invoice.InvoiceBound === InvoiceBound.External;
    const type = intl.formatMessage({id: isExternal ? Tr.invoicePURCHASE : Tr.invoiceSALES});
    const number = invoice.Number;
    return `${type}${number ? ': ' : ''}${number ?? ''}`;
  }
}
