export type LinearPackageLimitProps = {
  usage: number,
  limit: number,
  unit?: string,
  inactive?: boolean,
  label?: string,
  thresholds: Thresholds,
  loading?: boolean,
  enableInheritedBackground?: boolean,
  formatted?: { usage?: string | number, limit?: string | number }
}
export type Thresholds = [number, number, number]

export type Pipeable = (value: number) => number

export type Range = { min: number, max: number }

export enum StatusColorEnum {
  Normal = 'success',
  Critical = 'warning',
  Exceeded = 'error',
  Inactive = 'inherit'
}
