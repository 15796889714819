import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {IPostingConfigurationState} from "../IPostingConfigurationState";
import {useIntl} from "react-intl";
import {FinancialYearResponse} from "@symfonia-ksef/graphql";
import {dateParser} from "../../../../../../../common";
import {Dropdown, DropdownListOption, DropdownWidth, Icon, IconColor, IconSize, IconSvg} from "@symfonia/brandbook";
import {Tr} from "@symfonia-ksef/locales/keys";

interface FinancialYearSelectProps {
  state: IPostingConfigurationState;
  setSelectedFinancialYear: (year: string) => void;
}

export const FinancialYearSelect: FC<FinancialYearSelectProps> = observer(({state, setSelectedFinancialYear}) => {
  const intl = useIntl();
  const [actualizationDate, setActualizationDate] = useState<string | undefined>(undefined);
  const [financialYearActive, setIsFinancialYearActive] = useState<boolean | undefined>(undefined);
  const [financialYearOptions, setFinancialYearOptions] = useState<DropdownListOption[] | []>([]);
  const handleChangeFinancialYear = (options: string[]) => {
    const selectedOption = options[0];
    const selectedFinancialYear = state.availableFinancialYears?.find((e: FinancialYearResponse) => e.Id === selectedOption);
    setActualizationDate(dateParser(selectedFinancialYear?.LastSyncDate, true));
    setSelectedFinancialYear(selectedOption);
  };

  useEffect(() => {
    if (
      state.availableFinancialYears !== undefined &&
      state.availableFinancialYears.length > 0
    ) {
      setActualizationDate(
        !state.isFinancialYearActiveAndNotConfigured
          ? dateParser(state.availableFinancialYears[0].LastSyncDate, false)
          : undefined,
      );
      setFinancialYearOptions(
        state.availableFinancialYears.map(value => ({
          value: value.Id,
          label: `${intl.formatMessage({id: Tr.accounts})} ${value.Name}`,
        }))
      );
      setIsFinancialYearActive(state.availableFinancialYears[0].IsActive);
    }
  }, [
    state.availableFinancialYears,
    state.isFinancialYearActiveAndNotConfigured,
  ]);

  return <div className={'flex flex-col items-start lg:items-end md:mt-0 sm:mt-[20px] mt-0'}>
    <Dropdown
      width={DropdownWidth.BASE}
      options={financialYearOptions}
      value={[state.selectedFinancialYear!] ?? ['']}
      onChange={options => handleChangeFinancialYear(options)}/>
    <div className={'mt-[10px] flex items-center'}>
      {actualizationDate &&
        <>
          <Icon
            color={IconColor.BLUE1_400}
            className="mr-[5px]"
            svg={IconSvg.INFO}
            size={IconSize.SM}
          />
          <span>{`${intl.formatMessage({id: Tr.lastActualizationFromFK})} ${actualizationDate.slice(0,10)}`}</span>
        </>
      }
    </div>
  </div>;
});

