import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';

export enum FilehubAttachmentStatus {
  Public = 'Public',
  Private = 'Private',
}

export const filehubTableConfig = (
  handleHeaderCheckboxSelect: (checked: boolean) => void,
  allSelectedAttachments: boolean,
) => [
  {
    name: 'checkbox',
    checkbox: {
      name: 'checkbox-1',
      value: 'checkbox-1',
      checked: allSelectedAttachments,
      onChange: (checked: boolean) => {
        handleHeaderCheckboxSelect(checked);
      },
    },
    width: 'w-[50px]',
  },
  {
    name: Tr.invoiceName,
    title: intl.formatMessage({id: Tr.invoiceName}),
    width: 'w-[230px]',
  },
  {
    name: Tr.dateOfAdding,
    title: intl.formatMessage({id: Tr.dateOfAdding}),
    width: 'w-[100px]',
  },
  {
    name: Tr.addedBy,
    title: intl.formatMessage({id: Tr.addedBy}),
    width: 'w-[200px]',
  },
  {
    name: Tr.status,
    title: intl.formatMessage({id: Tr.status}),
    width: 'w-[100px]',
  },
  {
    name: Tr.size,
    title: intl.formatMessage({id: Tr.size}),
    width: 'w-[75px]',
  },
  {
    name: Tr.actions,
    title: intl.formatMessage({id: Tr.actions}),
    width: 'w-[50px]',
  },
];
