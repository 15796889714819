import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {CategoryRulesFiltersKeys} from '../../CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {FilterScopeType, ListFilterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {autorun, computed, IObservableArray, observable} from 'mobx';
import {ICategoryDetailsState} from './ICategoryDetailsState';
import {ICategoryDetailsFiltersState} from './ICategoryDetailsFiltersState';
import {MultiSelectModel} from '@symfonia/symfonia-ui-components';

export class CategoryDetailsFiltersState
  extends BaseSideMenuFilterState<ICategoryDetailsState, CategoryRulesFiltersKeys>
  implements ICategoryDetailsFiltersState {

  @observable
  categories: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  users: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  positions: IObservableArray<MultiSelectModel> = observable([]);

  constructor(parent: ICategoryDetailsState) {
    super(parent);
    this.defaultFilters = [
      {
        key: CategoryRulesFiltersKeys.IsActive,
        value: {
          values: [true],
          isActive: true,
        },
      },
      {
        key: CategoryRulesFiltersKeys.ModifiedAt,
        value: {
          values: [],
          isActive: false,
          isRangeFilter: true,
          type: FilterScopeType.Date,
        },
      },
      {
        key: CategoryRulesFiltersKeys.UserIds,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.String,
          isPageFilter: true,
        },
      },
      {
        key: CategoryRulesFiltersKeys.CategoryNames,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.String,
          isPageFilter: true,
        },
      },
      {
        key: CategoryRulesFiltersKeys.InvoiceTypeGroup,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.Enum,
          isPageFilter: true,
        },
      },
      {
        key: CategoryRulesFiltersKeys.PositionName,
        value: {
          values: [],
          isActive: false,
          type: FilterScopeType.String,
          isPageFilter: true,
        },
      },
    ];

    autorun(() => {
      return this.parent.postingConfigurationState?.companyId !== '' ? this.setDefaultValues() : null;
    });

    autorun(() => {
      if (
        this.parent.postingConfigurationState?.companyId !== '' &&
        this.parent.postingConfigurationState?.selectedFinancialYear &&
        this.activeFilters.get(CategoryRulesFiltersKeys.IsActive)
      ) {
        return this.parent.categoryDetailsStore.fetchCategoryRulesFilterData();
      } else {
        return null;
      }
    });
  }

  @computed
  public get isFiltersModified() {
    let counter = 0;
    this.activeFilters.forEach((f, k) => {
      if (this.pageFilters.includes(k)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        f.values.length > 0 ? counter++ : null;
      }
    });
    return counter >= 1 || this.datePills.size > 0;
  }

  protected override setDefaultValues() {
    let filterToCreate: ListFilterType = [];
    const storedFilters = this.storage !== undefined &&
    this.storage.loadFromStorage() &&
    this.storage.loadFromStorage().length > 0
      ? this.storage.loadFromStorage()
      : undefined;

    storedFilters
      ? filterToCreate = storedFilters
      : filterToCreate = this.defaultFilters;

    filterToCreate.filter(f => f.value.isActive).map(f => {
      if (Object.keys(CategoryRulesFiltersKeys).find(c => c === f.key)) {
        this.activeFilters.set(f.key as CategoryRulesFiltersKeys, f.value);
      }
    });
    this.createPills();
  }
}
