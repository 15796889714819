import {
    GetPostedInvoicesFiltersDataDocument,
    GetPostedInvoicesFiltersDataQuery,
    GetPostedInvoicesFiltersDataQueryVariables
} from '@symfonia-ksef/graphql';
import {apolloClient} from "../../../../root/providers/GraphQLProvider";

export const GetPostedInvoicesFiltersDataQueryFunction = async(companyId: string) =>
    await apolloClient
        .query<GetPostedInvoicesFiltersDataQuery, GetPostedInvoicesFiltersDataQueryVariables>({
            query: GetPostedInvoicesFiltersDataDocument,
            context: {
                envId: companyId,
            },
            variables: {
                CompanyId: companyId
            },
        })
        .then(response => {
            if (response.errors) {
                throw new Error(response.errors.toString());
            }
            return {
                Contractors: response.data.Contractors?.items
            };
        });
