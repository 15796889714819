import {
  GetMissedWebsocketSubscriptionsDocument,
  GetMissedWebsocketSubscriptionsQuery,
  GetMissedWebsocketSubscriptionsQueryVariables,
} from '@symfonia-ksef/graphql';
import {Repository} from '../modules/root/services/MobXServices/Repository/Repository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

//Służy do wzbudzenia eventów na backendzie, które wydarzyły się podczas gdy użytkownik był poza kontekstem firmy
export class ConsumeMissedNotifications extends Repository<'GetMissedWebsocketSubscriptions', GetMissedWebsocketSubscriptionsQuery, GetMissedWebsocketSubscriptionsQueryVariables> {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('GetMissedWebsocketSubscriptions', GetMissedWebsocketSubscriptionsDocument, envObserver, earchiveState);
  }

  public checkIsReady(): boolean {
    return !!this.envId;
  }
}


