import {Children, FC, PropsWithChildren, ReactElement, ReactNode} from 'react';
import MuiTooltip, {TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';

export type TooltipProps = {
  text?: ReactNode;
  position?: MuiTooltipProps['placement'];
  onOpen?: () => void;
  onClose?: () => void;
  /** Usually tooltip works on :hover, however certain state can be forced
   * by setting this flag */
  open?: boolean;
  tooltipClass?: string;
  arrowClass?: string;
};

export enum TooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  text = '',
  position = TooltipPosition.TOP,
  onOpen = () => undefined,
  onClose = () => undefined,
  open = undefined,
  children = undefined,
  tooltipClass = undefined,
  arrowClass = undefined,
}) => {
  return (
    <MuiTooltip
      componentsProps={{
        tooltip: {
          className: tooltipClass,
          sx: {
            fontFamily: 'quicksand',
            bgcolor: '#202020',
            px: '12px',
            py: '8px',
            fontSize: '14px',
            fontWeight: '400',
          },
        },
        arrow: {
          className: arrowClass,
          sx: {
            color: '#202020',
          },
        },
      }}
      title={text}
      placement={position}
      onOpen={onOpen}
      open={open}
      onClose={onClose}
      arrow
    >
      {Children.only(children) as ReactElement}
    </MuiTooltip>
  );
};
