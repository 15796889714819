import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {NotificationEvent} from '@symfonia-ksef/state/KSeFSubscriptionServices/WsEventsRepository';
import {
  DeleteInvoicesDataModel,
  InvoiceNonRemovableReason,
} from '../../../../../../services/helpers/NotificationDataParsers';
import ShowPopupIcon from '../../../../../../assets/ic_show_popup.svg';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ReactNode} from 'react';
import {deleteInvoiceService} from '@symfonia-ksef/state/rootRepository';
import {DeleteInvoicesModelMapper} from '../../helpers/DeleteInvoicesModelMapper';

export type DeleteInvoicesModelToMap =
  Omit<DeleteInvoicesDataModel, 'errorItems'>
  & {
  errorItems?: {
    invoiceId: string,
    invoiceNumber: string,
    invoiceDate?: string,
    reason: number
  }[]
}

export class DeleteInvoicesEventConverter extends KSeFEventsConverter<WebsocketNotificationType.DeleteInvoices, DeleteInvoicesModelToMap> {
  constructor(event: WebSocketNotificationFragment) {
    super(WebsocketNotificationType.DeleteInvoices, event);
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    const data = this.parser.parse();
    return data?.errorItems?.length ? {
      icon: ShowPopupIcon,
      tooltipText: Tr.showMessage,
    } : undefined;
  }

  public get notification(): ReactNode {
    if (this.model.hasError) {
      return this.createNotification({id: Tr.deleteInvoicesError});
    }
    if (!this.model.data?.deleted) {
      return null;
    }
    const {deleted, errorItems, invoicesId} = this.model.data;
    if (deleted === 1 && !errorItems?.length) {
      return this.createNotification({id: Tr.deleteInvoiceSuccess});
    }
    if (deleted === invoicesId?.length) {
      return this.createNotification({id: Tr.deleteInvoicesSuccess});
    }
    return this.createNotification({id: Tr.deleteInvoicesPartialSuccess, values: {count: deleted}});
  }

  public get model(): { data: DeleteInvoicesDataModel | null, hasError: boolean } {
    const data = this.parser.parse();
    const hasError = !!(this.event.ErrorType || (!!data?.errorItems?.length && !data?.deleted));
    return {data, hasError};
  }

  public async action(): Promise<void> {
    const data = this.parser.parse({reparse: true});
    deleteInvoiceService.setDownloadType(this.event.Type);
    if (data) {
      deleteInvoiceService.setArchivedResult(data, this.event.NotificationId).setResultIsAvailable(true);
    }
    deleteInvoiceService.setModalIsActive(true);
  }

  protected override mapper({
                              errorItems,
                              ...rest
                            }: DeleteInvoicesModelToMap): DeleteInvoicesDataModel | null {
    return DeleteInvoicesModelMapper.map({
      errorItems,
      ...rest
    });
  }
}
