import {BaseEventConsumer} from '../EventConsumer';
import {WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {uploadInvoicesService} from '../../state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';

export class UploadKSeFInvoicesEventConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.UploadInvoices, uploadInvoicesService, uploadInvoicesService.createAlert());
  }

  public override async onUpdate(event: EventParams): Promise<void> {
    return super.onUpdate(event, async () => await uploadInvoicesService.getAsyncData(event));
  }
}
