import {
  CreateCategoryDocument,
  CreateCategoryMutation,
  CreateCategoryMutationVariables,
  MutationCreateCategoryArgs,

} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../root/providers/GraphQLProvider';

export const CreateCategoryMutationFunction = async ({...mutationArgs}: MutationCreateCategoryArgs):Promise<string | undefined> => 
  await apolloClient.mutate<CreateCategoryMutation, CreateCategoryMutationVariables>({
    mutation: CreateCategoryDocument,
    context:{
      envId: mutationArgs.CompanyId,
    },
    variables: {
      ...mutationArgs
    }
  })
  .then(response => {
    if (response.errors) {
      throw new Error(response.errors.toString());
    }
    if (!response.data?.CreateCategory.IsDone) return undefined;

    return response.data.CreateCategory.NewCategoryId as string;
  });