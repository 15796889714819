import {InitializerType} from '../../services/helpers/fetchMatchedAction';
import {Repository} from '../../modules/root/services/MobXServices/Repository/Repository';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {
  CancelWebsocketNotificationDocument,
  CancelWebsocketNotificationMutation,
  CancelWebsocketNotificationMutationVariables,
} from '@symfonia-ksef/graphql';
import {
  CancellationProgressI,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressTracker';


export class KsefEventCancellationService extends Repository<'CancelWebsocketNotification', CancelWebsocketNotificationMutation, CancelWebsocketNotificationMutationVariables> {
  constructor(private readonly cancellationProgress: CancellationProgressI) {
    super('CancelWebsocketNotification', CancelWebsocketNotificationDocument, envObserver, earchiveState, InitializerType.Mutation);
  }

  public override checkIsReady(): boolean {
    return !!this.envId && !!this.variables.NotificationId;
  }

  protected override onSuccess(): void {
    this.cancellationProgress.setCancellationInProgress(true);
  }
}
