import {useIntl} from 'react-intl';
import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Tr} from '@symfonia-ksef/locales/keys';
import {CompanyVehiclesResponse, VehicleUsageTypeEnum} from '@symfonia-ksef/graphql';
import {isCorrectLicencePlateNumber} from '../../../earchive/helpers/regexHelpers';
import _ from 'lodash/fp';
import {
  ButtonPrimary,
  ButtonPrimaryWidth,
  ButtonSecondary,
  ButtonSecondaryWidth,
  Dropdown,
  DropdownListOption,
  Input,
  InputSize,
  InputWidth,
} from '@symfonia/brandbook';
import {DialogModal} from '@symfonia/symfonia-ksef-components';

export interface VehicleAddEditModalProps {
  open: boolean;
  isDecreeDetails: boolean;
  title: string;
  companyVehicle: CompanyVehiclesResponse | undefined;
  selectedVehicle?: CompanyVehiclesResponse | undefined;
  onSave: (companyVehicle: CompanyVehiclesResponse) => void;
  onClose: () => void;
  checkIfLicensePlateExist: (r: string) => Promise<boolean>;
}

export const VehicleAddEditModal: FC<VehicleAddEditModalProps> = observer(
  ({
    open,
    isDecreeDetails,
    title,
    companyVehicle,
    selectedVehicle,
    onSave,
    onClose,
    checkIfLicensePlateExist,
  }: VehicleAddEditModalProps) => {
    const MinRegistrationNumberLength = 4;
    const MaxRegistrationNumberLength = 8;
    const MaxVehicleDescriptionLength = 70;
    const intl = useIntl();
    const [licensePlateError, setLicencePlateError] = useState<string | undefined>(undefined);
    const [vehicleDescriptionError, setVehicleDescriptionError] = useState<string | undefined>(undefined);
    const [descriptionInput, setDescriptionInput] = useState<string>(companyVehicle?.Description ?? '');
    const editedCompanyVehicle = companyVehicle ?? ({} as CompanyVehiclesResponse);

    useEffect(() => {
      return () => setLicencePlateError(undefined);
    }, []);

    const isEmptyObject = (obj: any) => {
      return Object.keys(obj).length === 0;
    };

    const setSelectedUseTypeOnChange = (keys: string[]) => {
      editedCompanyVehicle.VehicleUsageType = keys[0] as VehicleUsageTypeEnum;
    };

    const setLicencePlate = (p: string) => {
      editedCompanyVehicle.RegistrationNumber = p.toUpperCase();
      setLicencePlateError(undefined);
      validateLicensePlate(p);
    };

    const validateLicensePlate = (p: string) => {
      if (p.length < MinRegistrationNumberLength || p.length > MaxRegistrationNumberLength) {
        setLicencePlateError(Tr.licencePlateLengthInputError);
        return false;
      } else if (!isCorrectLicencePlateNumber(p) || p == '') {
        setLicencePlateError(Tr.licencePlateValueInputError);
        return false;
      }
      return true;
    };

    const validateVehicleDescription = (p: string) => {
      if (p.length > MaxVehicleDescriptionLength) {
        setVehicleDescriptionError(Tr.vehicleDescriptionLengthInputError);
        return false;
      }
      return true;
    };

    const setOnSave = () => {
      if (
        !validateLicensePlate(companyVehicle?.RegistrationNumber ?? '') ||
        !validateVehicleDescription(companyVehicle?.Description ?? '')
      ) {
        return;
      }
      if (!_.isEqual(selectedVehicle?.RegistrationNumber, companyVehicle?.RegistrationNumber)) {
        checkIfLicensePlateExist(editedCompanyVehicle.RegistrationNumber).then(v => {
          if (v) {
            setLicencePlateError(Tr.licencePlateExistsInputError);
          } else {
            onSave(editedCompanyVehicle);
            setLicencePlateError(undefined);
            setDescriptionInput('');
          }
        });
      } else onSave(editedCompanyVehicle);
    };

    const setOnClose = () => {
      setLicencePlateError(undefined);
      setVehicleDescriptionError(undefined);
      setDescriptionInput('');
      onClose();
    };

    const setDescription = (p: string) => {
      setVehicleDescriptionError(undefined);
      editedCompanyVehicle.Description = p;
      validateVehicleDescription(p);
    };

    const isDisable =
      isEmptyObject(editedCompanyVehicle) ||
      licensePlateError !== undefined ||
      vehicleDescriptionError !== undefined ||
      (companyVehicle !== undefined ? _.isEqual(selectedVehicle, companyVehicle) : false);

    const enumList = [VehicleUsageTypeEnum.Mixed, VehicleUsageTypeEnum.Company, VehicleUsageTypeEnum.Private];

    const usageTypeOptions = enumList.map(
      value =>
        ({
          value: value,
          label: `${intl.formatMessage({id: value})}`,
        }) as DropdownListOption,
    );

    return (
      <DialogModal title={title} onCancel={setOnClose} onConfirm={setOnSave} open={open}>
          <div className="flex flex-col w-full mb-[20px]">
            <Input
              width={InputWidth.FULL}
              size={InputSize.MD}
              onChange={value => setLicencePlate(value)}
              value={companyVehicle?.RegistrationNumber ?? ''}
              label={intl.formatMessage({id: Tr.registrationNumber})}
              placeholder={intl.formatMessage({id: Tr.writeLicencePlate})}
              isError={licensePlateError !== undefined}
            />
            {licensePlateError && (
              <p className={'pt-1 text-red-500 text-sm font-semibold font-quicksand mb-[10px]'}>
                {intl.formatMessage({id: licensePlateError}, {licensePlate: companyVehicle?.RegistrationNumber})}
              </p>
            )}
            <Dropdown
              className={'w-full my-[10px]'}
              label={intl.formatMessage({id: Tr.VehicleUsageType})}
              placeholder={intl.formatMessage({id: Tr.VehicleUsageType})}
              options={usageTypeOptions}
              value={[companyVehicle?.VehicleUsageType as string]}
              onChange={k => setSelectedUseTypeOnChange(k)}
            />
            <Input
              width={InputWidth.FULL}
              value={companyVehicle?.Description ?? ''}
              label={intl.formatMessage({id: Tr.description})}
              placeholder={intl.formatMessage({id: Tr.writeDescription})}
              max={70}
              onChange={value => setDescription(value)}
              isError={vehicleDescriptionError !== undefined}
            />
            {vehicleDescriptionError && (
              <p className={'pt-1 text-red-500 text-sm font-semibold font-quicksand mb-[10px]'}>
                {intl.formatMessage({id: vehicleDescriptionError})}
              </p>
            )}
          </div>
          <ButtonSecondary
            text={intl.formatMessage({id: Tr.cancelButton})}
            onClick={setOnClose}
            width={ButtonSecondaryWidth.BASE}
            className={' mr-[10px] '}
          />
          <ButtonPrimary
            text={intl.formatMessage({id: Tr.save})}
            onClick={setOnSave}
            disabled={isDisable}
            width={ButtonPrimaryWidth.BASE}
          />
        </DialogModal>
    );
  },
);
