import classnames from 'classnames';


interface ITextProps {
  block?: boolean
  className?: string,
  small?: boolean
  testId?: string;
}

export const Text: FCC<ITextProps> = ({children, className, block, small, testId}) => {


  return <span
    className={classnames(className, {'block': block}, `text-[${small ? '14px' : '16px'}]`)} data-testid={testId}>{children}</span>;
};
