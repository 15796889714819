import * as React from 'react';
import {FC, useCallback, useEffect} from 'react';
import {FormControl, IconButton, Input, InputAdornment, Popover} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {AccountResponse} from '@symfonia-ksef/graphql';


export interface ITreeNodeWithChildren {
  key: string;
  parentId?: string;
  parent?: ITreeNodeWithChildren;
  childrenIds?: string[];
  children?: ITreeNodeWithChildren[];
}

export class TreeViewDropdownModel implements ITreeNodeWithChildren {
  public value: string = '';
  public key: string = '';
  public isSelectable: boolean = false;
  //TODO: wyszarzyć elementy, które mają disabled = true
  public isDisabled?: boolean;
  public isHidden?: boolean = false;
  public parentId?: string;
  public parent?: TreeViewDropdownModel;
  public childrenIds?: string[];
  public children?: TreeViewDropdownModel[];
}

interface TreeViewDropdownProps {
  value: string;
  updateCategorySelect: (nodeIds: string) => void;
  dropdownList: TreeViewDropdownModel[] | undefined;
}

export const TreeViewDropdown: FC<TreeViewDropdownProps> = (
  {
    value,
    updateCategorySelect,
    dropdownList,
  }) => {
  const [nameNumber, setNameNumber] = React.useState<string>('');
  const [selected, setSelected] = React.useState<string>('');
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const findNameOfSelectedElement = useCallback((valueID: string) => {
    const selectedElement = dropdownList?.find(e => e.key === valueID);
    setNameNumber(selectedElement?.value ?? '');
  }, [dropdownList]);

  useEffect(() => {
    setSelected(value);
    const parentValue = dropdownList?.find(e => e.key === value)?.parentId;
    setExpanded([value, parentValue ?? '']);
    findNameOfSelectedElement(value);
  }, [findNameOfSelectedElement, value]);


  const handleToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);

  const handleSelect = useCallback((event: React.SyntheticEvent, nodeIds: string) => {
    if (dropdownList?.find(a => a.key === nodeIds)?.isSelectable) {
      updateCategorySelect(nodeIds);
      findNameOfSelectedElement(nodeIds);
      setSelected(nodeIds);
      setAnchorEl(null);
    }
  }, [dropdownList, findNameOfSelectedElement, updateCategorySelect]);

  const handleClickPop = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const openPop = Boolean(anchorEl);

  const renderTree = useCallback((nodes: TreeViewDropdownModel) => {
    return <TreeItem key={nodes.key} nodeId={nodes.key} label={nodes.value} sx={{p: 0}}>
      {dropdownList?.filter(e => e.parentId === nodes.key).map(x => renderTree(x))}
    </TreeItem>;
  }, [dropdownList]);

  return (
    <>
      <FormControl variant="standard" className="fullClean">
        <Input
          id="standard-adornment-password"
          type="text"
          value={nameNumber}
          onClick={handleClickPop}
          color="primary"
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle visibility" className="fullClean">
                {!openPop ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
              </IconButton>
            </InputAdornment>
          }/>
      </FormControl>
      <Popover
        id={'simple-popover'}
        open={openPop}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        //TODO: do poprawienia
        sx={{
          maxHeight: '12rem',
        }}
      >
        <TreeView
          aria-label="controlled"
          defaultCollapseIcon={<ExpandMoreIcon/>}
          defaultExpandIcon={<ChevronRightIcon/>}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          {!!dropdownList && dropdownList.map(n => {
            return n.parentId === null ? renderTree(n) : null;
          })}
        </TreeView>
      </Popover>
    </>
  );
};
