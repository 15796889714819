import {action, IObservableArray, makeObservable, observable} from 'mobx';
import {MultiSelectModel} from '../../../../../common/components/MultiSelect/MultiSelect';
import {BaseModalState} from '@symfonia-ksef/state/modals/BaseModalState';
import {VatRegistriesResponse} from '@symfonia-ksef/graphql';
import {convertToMultiSelectType} from '../../../../../common/helpers/baseFilterHelpers';
import {SyntheticEvent} from 'react';
import {IVatRegistryChangeModalState} from './IVatRegistryChangeModalState';

export class VatRegistryChangeModalState extends BaseModalState implements IVatRegistryChangeModalState {
  @observable
  public vatRegistries: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  public selectedVatRegistryId = '';

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  public onSelect(event: SyntheticEvent, value: MultiSelectModel | null) {
    this.selectedVatRegistryId = value?.key ?? '';
  }

  @action
  public setVatRegistries(vatRegistries: VatRegistriesResponse[]) {
    this.vatRegistries.replace(
      convertToMultiSelectType(vatRegistries, el => ({
        value: el.Name,
        key: el.Id,
      })),
    );
  }

  @action
  public setSelectedVatRegistryId(id: string) {
    this.selectedVatRegistryId = id;
  }

  @action
  public handleSave() {
    this.handleClose();
  }
}
