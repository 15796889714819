import {action, computed, makeObservable, observable} from 'mobx';
import {
  InitializationStatusEnum,
  InitializationTaskTypeEnum,
  ModuleSubscriptionStatusEnum,
  ProcessTaskStatusEnum,
} from '@symfonia-ksef/graphql';
import {IAccountState} from '../../IAccountState';
import {IPostingInitializationState} from './IPostingInitializationState';
import {IPostingConfigurationState} from './PostingConfiguration/IPostingConfigurationState';
import {PostingConfigurationState} from './PostingConfiguration/postingConfigurationState';
import {ModulesEnum} from "../../../../../common/enums/ModulesEnum";
import {AutoPostingModuleStatus} from '../../../../../auto-posting/AutopostingModuleStatus';

export class PostingInitializationState implements IPostingInitializationState {
  @observable
  subscriptionStatus: ModuleSubscriptionStatusEnum | undefined;

  @observable
  initializationStatus: InitializationStatusEnum | undefined;

  @observable
  taskType: InitializationTaskTypeEnum | undefined;

  @observable
  taskStatus: ProcessTaskStatusEnum | undefined;

  @observable
  hasFailed: boolean;

  @observable
  errorType: string | undefined;

  @observable
  completed: boolean;

  accountState: IAccountState;

  private _postingConfigurationState: IPostingConfigurationState | undefined;

  get postingConfigurationState(): IPostingConfigurationState {
    if (this._postingConfigurationState === undefined) {
      this._postingConfigurationState = new PostingConfigurationState(this);
    }
    return this._postingConfigurationState;
  }

  constructor(rootState: IAccountState) {
    makeObservable(this);
    this.accountState = rootState;
    this.hasFailed = false;
    this.completed = false;
  }

  @computed
  get moduleStatus() : AutoPostingModuleStatus | undefined {
    if (this.accountState.earchiveState.moduleStatusStore.modules.length) {
      return this.accountState.earchiveState.moduleStatusStore.getModule(ModulesEnum.autoPosting) as AutoPostingModuleStatus;
    }
    return undefined;
  }

  @action setSubscriptionStatus(status: ModuleSubscriptionStatusEnum | undefined) {
    this.subscriptionStatus = status;
  }

  @action setInitializationStatus(status: InitializationStatusEnum | undefined) {
    this.initializationStatus = status;
  }

  @action setInitializationTaskType(taskType: InitializationTaskTypeEnum | undefined) {
    this.taskType = taskType;
  }

  @action setTaskStatus(taskStatus: ProcessTaskStatusEnum | undefined) {
    this.taskStatus = taskStatus;
  }

  @action setModuleDetails() {
    if (this.moduleStatus) {
      this.setSubscriptionStatus(this.moduleStatus.subscriptionsStatus);
      this.setInitializationStatus(this.moduleStatus.initializationStatus);
      this.setInitializationTaskType(this.moduleStatus.initializationTaskType);
    }
  }

  @action setHasFailed(errorType: string | undefined) {
    this.hasFailed = true;
    this.initializationStatus = InitializationStatusEnum.Failed;
    this.errorType = errorType;
  }

  @action setCompleted() {
    this.completed = true;
    this.setInitializationStatus(InitializationStatusEnum.InitializationCompleted);
  }
}
