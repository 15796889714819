import {AccountViewModel} from "@symfonia-ksef/graphql";

export function accountNumberComparer(account1: AccountViewModel, account2: AccountViewModel): number {
  const numbers1 = account1.Number.split('-').map(n => parseInt(n));
  const numbers2 = account2.Number.split('-').map(n => parseInt(n));

  for (let i = 0; i < numbers1.length && i < numbers2.length; i++) {
    if (numbers1[0] < numbers2[i]) return -1;
    else if (numbers1[1] > numbers2[i]) return 1;
  }

  return 0;
}