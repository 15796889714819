import React, {FC, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {
  Alert,
  AlertDirection,
  AlertVariant,
  AlertWidth,
  ButtonTertiary,
  Icon,
  IconSvg,
  NotificationSize,
  NotificationVariant,
} from '@symfonia/brandbook';
import {Img} from '@symfonia/brandbook';
import image from '../images/icon.png';
import {Notification} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {convertBytesToMBs} from '@symfonia/utils';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';

export enum FilehubUploadFileWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
}

export type FilehubUploadFileProps = {
  className?: string;
  width?: FilehubUploadFileWidth;
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
  files: any[];
  setInvalidFiles: React.Dispatch<React.SetStateAction<any[]>>;
  invalidFiles: any[];
  state: IFilehubState;
};

const forbiddenExtentions = ['.exe', '.com', '.bat', '.app', '.bin'];

const isValid = (icon: any) => {
  return !forbiddenExtentions.some(el => icon.endsWith(el));
};

export const FilehubUploadFile: FC<FilehubUploadFileProps> = observer(
  ({
    className = undefined,
    setFiles,
    setInvalidFiles,
    files,
    invalidFiles,
    state,
    width = FilehubUploadFileWidth.BASE,
  }) => {
    const [dragActive, setDragActive] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {length: allFilesLength} = files;
    const {length: allInvalidFilesLength} = invalidFiles;
    const areFiles = allFilesLength > 0;
    const areInvalidFiles = allInvalidFilesLength !== 0;
    const moreThanOneInvalidFile = allInvalidFilesLength > 1;
    const attachmentAddedHasStatus = state.successfulAddRequest !== null;
    const attachmentAddedSuccess = state.successfulAddRequest === true;
    const moreThanOneFile = files.length > 1;

    useEffect(() => {
      const invalidFiles = files.filter(file => file.isValid === false);
      setInvalidFiles(invalidFiles);
    }, [files]);

    const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (areInvalidFiles) return;

      setDragActive(false);

      if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
        const addedFiles = Array.from(e.dataTransfer.files).map(file => ({
          file,
          id: crypto.randomUUID(),
          isValid: isValid(file.name),
        }));
        setFiles(filesInState => [...filesInState, ...addedFiles]);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.target.files && e.target.files[0]) {
        const addedFiles = Array.from(e.target.files).map(file => ({
          file,
          id: crypto.randomUUID(),
          isValid: isValid(file.name),
        }));
        setFiles(filesInState => [...filesInState, ...addedFiles]);
      }
    };

    const onButtonClick = () => {
      inputRef.current?.click();
    };

    const handleRemoveFile = (idDeleted: string) => {
      setFiles(files => files.filter(({id}) => id !== idDeleted));
    };

    const clearRequestAttachments = () => {
      state.setAddAttachmentsList([]);
      state.setSuccessfulAddRequest(null);
    };

    const styles = {
      component: clsx(className, {
        'w-full': width === FilehubUploadFileWidth.FULL,
        'w-fit': width === FilehubUploadFileWidth.FIT,
        'w-[419px]': width === FilehubUploadFileWidth.BASE,
      }),
    };

    return (
      <div className={styles.component}>
        <div
          className="py-4 flex flex-col items-center border border-dashed border-grey-300 rounded-2xl font-quicksand mt-2"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          id="drag-file-element"
        >
          <img src={image} alt=""/>
          <span className="text-base font-bold">Przeciągnij plik i upuść tutaj</span>
          <span>
            <form id="form-file-upload" onDragEnter={e => handleDrag(e)} onSubmit={e => e.preventDefault()}>
              <input
                disabled={areInvalidFiles}
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple
                onChange={e => handleChange(e)}
                style={{display: 'none'}}
              />
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                lub <ButtonTertiary disabled={areInvalidFiles} text="Wybierz plik" onClick={onButtonClick}/>
              </label>
            </form>
          </span>
        </div>
        {areFiles && (
          <div className="flex justify-end my-[12px]">
            <div>
              {intl.formatMessage(
                {id: Tr.numberOfAddedDocuments},
                {
                  countDocuments: `${allFilesLength - allInvalidFilesLength}/${allFilesLength}`,
                },
              )}
            </div>
          </div>
        )}
        <div className="mt-[12px]">
          {areFiles &&
            files.map(({file, id, isValid}) => (
              <>
                <div
                  key={id}
                  className={`mt-2 py-1 px-3 border border-solid ${
                    isValid ? 'border-grey-200' : 'border-red-500'
                  } flex justify-between items-center rounded-xl`}
                >
                  <div className="flex flex-row gap-[12px] items-center">
                    <div className="bg-grey-100 w-[40px] h-[40px] rounded-full overflow-hidden flex items-center justify-center">
                      <Img alt="Uploaded image" src={URL.createObjectURL(file)} className="h-full w-full"/>
                    </div>
                    <div>
                      <div>{file.name}</div>
                      <div>{convertBytesToMBs(file.size)} MB</div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Icon onClick={() => handleRemoveFile(id)} svg={IconSvg.CLOSE}/>
                  </div>
                </div>
                {!isValid && (
                  <Notification
                    className="my-[6px]"
                    size={NotificationSize.SM}
                    text={`${intl.formatMessage({id: Tr.formatOfFile})} ${file.name.slice(-4)}`}
                    variant={NotificationVariant.ERROR}
                  />
                )}
              </>
            ))}
        </div>
        {areInvalidFiles && (
          <Alert
            className="mt-[16px]"
            description={
              intl.formatMessage(
                {id: moreThanOneInvalidFile ? Tr.cantAddFiles : Tr.cantAddFile},
                {
                  br: <br/>,
                  files: `${invalidFiles.map(x => x.file.name)}`,
                  format: `${invalidFiles.map(x => x.file.name.slice(-4))}`,
                },
              ) as string
            }
            title={intl.formatMessage({id: Tr.formatOfFile})}
            variant={AlertVariant.ERROR}
            direction={AlertDirection.VERTICAL}
            width={AlertWidth.FULL}
          />
        )}
        {attachmentAddedHasStatus && (
          <Alert
            className="mt-[16px]"
            description={`${state.addAttachmentsList.map(({file}) => file.name)}`}
            direction={AlertDirection.VERTICAL}
            onClose={clearRequestAttachments}
            title={
              attachmentAddedSuccess
                ? moreThanOneFile
                  ? intl.formatMessage({id: Tr.attachmentsAddedSuccess})
                  : intl.formatMessage({id: Tr.attachmentAddedSuccess})
                : moreThanOneFile
                  ? intl.formatMessage({id: Tr.attachmentsAddedError})
                  : intl.formatMessage({id: Tr.attachmentAddedError})
            }
            variant={attachmentAddedSuccess ? AlertVariant.SUCCESS : AlertVariant.ERROR}
            width={AlertWidth.FULL}
          />
        )}
      </div>
    );
  },
);
