import {FC} from 'react';
import clsx from 'clsx';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '../../Icon/Icon';
import {TableSortDirection} from '../Table.types';

export type TableSortIconProps = {
  direction?: TableSortDirection;
  className?: string;
};

export const TableSortIcon: FC<TableSortIconProps> = ({direction = undefined, className = undefined}) => {
  return (
    <div className={clsx('relative inline-block ml-[8px] w-[24px] h-[24px]', className)}>
      <Icon
        color={direction ? IconColor.PRIMARY_500 : IconColor.GREY_300}
        variant={IconVariant.CONTOUR}
        svg={direction === TableSortDirection.ASC ? IconSvg.ARROW_DROP_UP : IconSvg.ARROW_DROP_DOWN}
        size={IconSize.LG}
        className="absolute top-0"
      />
    </div>
  );
};
