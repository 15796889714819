import {BaseEventConsumer} from '../EventConsumer';
import {SubscriptionStateEnum, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {downloadedInvoicesResultService} from '../../state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';
import {ToastVariant} from '@symfonia/brandbook';
import {intl} from '../../modules/root/IntlProvider';

export class KSeFAutoFetchingInvoicesConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.AutoFetchingInvoices, downloadedInvoicesResultService, {
      success: () => {
        const count = downloadedInvoicesResultService.currentResult?.imported;
        const isCancelled = downloadedInvoicesResultService?.notification?.State === SubscriptionStateEnum.Cancelled;
        const manualCancellation = isCancelled ? intl.formatMessage({id: Tr.getInvoicesFromKSeFCancelled}) : '';
        return {
          id: downloadedInvoicesResultService.hasError ? Tr.ksefAutoFetchingInvoicesSubError : count ? Tr.ksefAutoFetchingInvoicesSubSuccess : isCancelled ? Tr.getInvoicesFromKSeFNoData : Tr.getInvoicesFromKSeFUpToDate,
          values: {count, manualCancellation},
          color: downloadedInvoicesResultService.hasError ? ToastVariant.ERROR : ToastVariant.SUCCESS,
        };
      },
      error: {id: Tr.ksefAutoFetchingInvoicesSubError},
    });
  }

  public async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await downloadedInvoicesResultService.getAsyncData(event, () => downloadedInvoicesResultService.setDownloadType(event.type)));
    const {currentResult} = downloadedInvoicesResultService;
    if (currentResult?.errorItems?.length) {
      downloadedInvoicesResultService.setModalIsActive(true);
    }
  }
}
