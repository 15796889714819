import {DownloadInvoicesDataModel, DownloadStateUserMessage} from "../../../../../services/helpers/NotificationDataParsers";
import {DownloadInvoicesModelToMap} from "../services/KSeFEventsConverters/GetInvoicesEventConverter";
import {InvoiceBound} from "@symfonia-ksef/graphql";

export class DownloadInvoicesModelMapper {
  public static map = ({
    successItems,
    state,
    stateTime,
    ...rest
  }: DownloadInvoicesModelToMap): DownloadInvoicesDataModel => {
    return {
      ...rest,
      state: state === 0 ? DownloadStateUserMessage.Pending : DownloadStateUserMessage.Downloading,
      stateTime: stateTime ? new Date(stateTime) : undefined,
      successItems: successItems?.map?.(({invoiceBound, ...restItems}) => ({
        invoiceBound: invoiceBound === 0 ? InvoiceBound.Internal : invoiceBound ? InvoiceBound.External : undefined,
        ...restItems,
      })),
    };
  };
}
