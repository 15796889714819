import {action, computed, makeObservable, observable} from 'mobx';
import {EArchiveState} from './EarchiveState';
import {BaseModule} from '../../modules/root/services/MobXServices/BaseModule';

export class LayoutState extends BaseModule {
    constructor(private globalState: EArchiveState) {
        super();
        this.openDrawer = false;
        this.openNavMenu = false;
        this.header = undefined;
        this.headerSecondLine = '';
        this.isDemo = false;
        this.viewport = {isDesktop: false, isTablet: false, isPhone: false, isLargeDesktop: false};
        makeObservable(this);
    }

    @observable openDrawer: boolean;
    @observable openNavMenu: boolean;
    @observable header: {name: string, identifier?: string} | undefined;
    @observable headerSecondLine: string;
    @observable isDemo: boolean;
    @observable viewport: {isDesktop: boolean, isTablet: boolean, isPhone: boolean, isLargeDesktop: boolean};

    @action.bound setOpenDrawer(openDrawer: boolean) {
      this.openDrawer = openDrawer;
      this.openNavMenu = false;
    }

    @action.bound setOpenNavMenu(openNavMenu: boolean) {
      this.openNavMenu = openNavMenu;
      this.openDrawer = false;
    }

    @action.bound setHeader(header: {name: string, identifier?: string}) {
        this.header = header;
    }

    @action.bound setHeaderSecondLine(headerSecondLine: string) {
        this.headerSecondLine = headerSecondLine;
    }

    @action.bound setIsDemo(isDemo: boolean) {
        this.isDemo = isDemo;
    }

    @action.bound setViewport(viewport: {isDesktop: boolean, isTablet: boolean, isPhone: boolean, isLargeDesktop: boolean}) {
        this.viewport = viewport;
    }

    @computed get HeaderNameWithIdentifier(): string {
        return this.header?.identifier ? `${this.header?.identifier} - ${this.header?.name}` : this.header?.name ?? '';
    }

    @computed get HeaderName(): string {
        return this.header?.name ?? '';
    }

    public getHeaderName(withIdentifier: boolean): string {
        return withIdentifier ? this.HeaderNameWithIdentifier : this.HeaderName;
    }
}
