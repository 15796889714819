import {AlertsItem} from './AlertsItem';
import {SnackBar} from './SnackBar';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';

export const Alerts = observer(() => {
  const alertsState = earchiveState.alertsState;

  return (
    <>
      <div className="fixed right-2 flex flex-col-reverse items-end z-50"
           style={{...(!earchiveState.layoutState.viewport.isPhone && {maxWidth: 340})}}>
        {alertsState.alerts.map((alert) => (
          <AlertsItem key={alert.uid} alert={alert} onClose={() => alertsState.removeAlert(alert.uid)}/>
        ))}
      </div>
      {alertsState.messages && alertsState.messages.length > 0 && alertsState.messages.map((message) => (
        <SnackBar key={message.uid} message={message}
                  onClose={() => alertsState.removeSnackBarMessage(message.uid)}/>
      ))}
    </>
  );
});
