import {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {SzafirSdkProvider, SzafirSdkProviderProps} from '@symfonia/szafir-react';
import {environment} from '../../../../../environments/environment';
import {IdentifierType, KSeFAuthResult} from '@symfonia-ksef/graphql';
import {dateParser, PageMessageWithModal, usePageTitle} from '../../../../common';
import {AuthenticationSignature} from './AuthenticationSignature';
import DisconnectWithKSeF from './DisconnectWithKSeF';
import AuthenticationByToken from './AuthenticationByToken';
import {DisconnectWithKSeFToken} from './DisconnectWithKSeFToken';
import {Tr} from '@symfonia-ksef/locales/keys';
import {authenticationState, authorizationInKSeFService, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {AuthType} from '../../../../../state/AuthenticationState/AuthenticationState';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {Breadcrumbs} from '@symfonia/brandbook';
import {Text} from '../../../../../modules/root/components/Text';
import {Header} from '../../../../../modules/root/components/Header';

export const AuthenticationPage = observer(() => {
  const intl = useIntl();
  const {setPageTitle} = usePageTitle();
  setPageTitle(intl.formatMessage({id: Tr.authentication}));
  const {company: {currentEnvironment, ksefAuthorizedData}} = earchiveState;
  const [disconnectPage, setDisconnectPage] = useState(false);
  const authorizationWithNip =
    currentEnvironment?.Company.IdentifierType === IdentifierType.Nip &&
    !!currentEnvironment?.Company.Identifier;
  const getLocationUrl = (path: string) => `${window.location.origin}/${path}`;
  const authDate = dateParser(ksefAuthorizedData?.AuthorizedAt, true);
  const authorizedInKSeF = ksefAuthorizedData?.Authorized;  

  const szafirSdkProviderProps: Omit<SzafirSdkProviderProps, 'children'> = {
    debug: environment.szafirDebug,
    sdkLocationUrl: getLocationUrl('assets/szafirsdk_web/sdk_builds/build/'),
    versionsFile: getLocationUrl('assets/szafirsdk_web/sdk_builds/build/versions.xml'),
    webModuleLocationUrl: getLocationUrl('assets/szafirsdk_web/'),
  };

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.authentication}),
    },
  ];

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
        <div className="max-w-[650px]">
          <Header>{intl.formatMessage({id: Tr.authentication})}</Header>
          <div className="mt-[40px]">
            {authorizationWithNip && (
              <div className="flex">
                <div>
                  <div>
                    {!disconnectPage && (
                      <>
                        <div>
                          <Text className="font-bold">
                            <FormattedMessage id={Tr.authorizationKSeF}/>
                          </Text>
                        </div>
                        <div className="mt-[16px]">
                          <Text>
                            <FormattedMessage
                              id={
                                authorizedInKSeF !== KSeFAuthResult.NotAuthorized
                                  ? Tr.authorizedKSeFDesc
                                  : Tr.authorizationKSeFDesc
                              }
                              values={{
                                br: <br/>,
                                authorizedFullName: currentEnvironment.UserProperties.KSeF.AuthorizedBy,
                                companyName: <b className="font-bold">{currentEnvironment.Company.Name}</b>,
                                authorizedName: (
                                  <h1>
                                    {authorizedInKSeF !== KSeFAuthResult.AuthorizedWithExternalToken
                                      ? intl.formatMessage({id: Tr.signAuthDesc})
                                      : intl.formatMessage({id: Tr.tokenAuthDesc})}
                                  </h1>
                                ),
                              }}
                            />
                          </Text>
                        </div>
                      </>
                    )}
                    {authorizedInKSeF !== KSeFAuthResult.NotAuthorized && (
                      <Text className="italic">
                        <FormattedMessage
                          id={Tr.authorizedByDesc}
                          values={{
                            authorizedFullName: ksefAuthorizedData?.AuthorizedBy,
                            authorizedDate: authDate,
                          }}
                        />
                      </Text>
                    )}
                    {disconnectPage && (
                      <>
                        <div>
                          <Text className="py-1">
                            <FormattedMessage id={Tr.disconnectKSeF}/>
                          </Text>
                        </div>
                        <Text className="py-1">
                          <FormattedMessage
                            id={Tr.disconnectKSeFDesc}
                            values={{
                              br: <br/>,
                            }}
                          />
                        </Text>
                      </>
                    )}

                    {authorizedInKSeF === KSeFAuthResult.AuthorizedWithExternalToken && <DisconnectWithKSeFToken/>}

                    {authorizedInKSeF === KSeFAuthResult.Authorized && (
                      <SzafirSdkProvider {...szafirSdkProviderProps}>
                        <DisconnectWithKSeF
                          disconnectPageStatus={setDisconnectPage}
                          onDisconnectSuccess={() => {
                            setDisconnectPage(false);
                          }}
                        />
                      </SzafirSdkProvider>
                    )}

                    {authorizedInKSeF === KSeFAuthResult.NotAuthorized && (
                      <div>
                        {/*TODO: do włączenia po ustaleniach z dostawcą usługi*/}
                        {/*<AuthenticationTrustedProfile />*/}
                        <SzafirSdkProvider {...szafirSdkProviderProps}>
                          <AuthenticationSignature
                            onAuthorized={async (companyId, szafirService) => {
                              authenticationState.setProcessMessage(Tr.pendingAuthorization);
                              authenticationState.setAuthType(AuthType.Signature);
                              await authorizationInKSeFService
                                .setSzafirService(szafirService)
                                .fetch();
                            }}
                          />
                        </SzafirSdkProvider>
                        <AuthenticationByToken company={currentEnvironment}/>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!authorizationWithNip && (
              <PageMessageWithModal
                width={500}
                title={intl.formatMessage({id: Tr.authImpossibleWithoutNip})}
                description={intl.formatMessage({id: Tr.authImpossibleWithoutNipTEXT})}
                showClose
              />
            )}
          </div>
        </div>
      </div>
    </PageContent>
  );
});
