import {IconButton} from '@mui/material';
import {copyText} from '../../../helpers/copyText';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {FC} from 'react';
import classnames from 'classnames';

export const AccountNumber: FC<{ account?: string, className?: string }> = ({account, className}) => {
  return <span className={classnames('flex items-center', className)}>
        <span>{account}</span>
        <IconButton onClick={(e) => {
          e.stopPropagation();
          account && copyText(account);
        }}>
         <ContentCopyRoundedIcon color="primary" fontSize="small"/>
        </IconButton>
      </span>;
};
