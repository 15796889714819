import {useEffect, useRef} from 'react';

type Prev<T> = T | undefined

export const usePrevious = <T>(value: Prev<T>): Prev<T> => {
  const previousRef = useRef<Prev<T>>(value);
  useEffect(() => {
    previousRef.current = value;
  }, [value]);
  return previousRef.current;
};
