import {environmentCommon} from './environment.common';
import {LogLevel} from '@symfonia/utils';

export const environment = {
  ...environmentCommon,
  production: true,
  logLevel: LogLevel.Warning,
  logToConsoleEnabled: true,
  szafirDebug: false
};
