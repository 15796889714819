import {Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import {intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';

export const FilehubNoAttachments = () => {
  return (
    <div className="flex flex-col items-center gap-y-[16px] pt-[16px]">
      <Icon
        svg={IconSvg.NOTE_ADD}
        className="min-w-[64px] min-h-[64px]"
        size={IconSize.LG}
        color={IconColor.GREY_100}
      />
      <div className="font-quicksand text-xl text-grey-500">{intlFunction(Tr.filehubNoAttachments)}</div>
    </div>
  );
};
