import {observer} from 'mobx-react-lite';
import {checkWhiteListService} from '@symfonia-ksef/state/rootRepository';
import {MissingInvoicesModal} from './MissingInvoices';
import {FormattedMessage} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useMemo} from 'react';
import {MissingInvoiceModel} from '../../../models';
import {AccountsNumbersList} from '../../../components/InvoicesTable/Components/AccountsNumbersList';
import {intl} from '../../../../root/IntlProvider';

export const CheckWhiteListModal = observer(() => {
  const {notWhiteListed, whiteListed, errorItems} = checkWhiteListService;
  const tableList = useMemo(() => {
    const result: MissingInvoiceModel[] = [];

    for (const item of [...notWhiteListed, ...whiteListed, ...errorItems]) {
      const accountsList = item.whiteListResult
        .filter(({IsWhiteListed}) => !IsWhiteListed)
        .map(({Account}) => ({number: Account}));
      accountsList.length && result.push({
        invoiceNumber: item.issuerName,
        accounts: item.whiteListResult,
        message: <AccountsNumbersList accounts={accountsList}/>,
      });

    }
    const flattenedAccounts = result.flatMap(item =>
      item.accounts?.map(account => ({...account, invoiceNumber: item.invoiceNumber, parent: item}))
    );
    const uniqueAccountsMap = new Map();
    
    flattenedAccounts.forEach(account => {
    const uniqueKey = `${account?.Account}-${account?.invoiceNumber}`;
      uniqueAccountsMap.set(uniqueKey, account);
    });
    
    const uniqueParentItems = Array.from(uniqueAccountsMap.values()).map(account => account.parent);
    
    return Array.from(new Set(uniqueParentItems));
  }, [whiteListed, notWhiteListed, errorItems]);

  return <MissingInvoicesModal
  title={<FormattedMessage
  id={Tr.whiteListModalTitle}/>}
  showButtonLabel={intl.formatMessage({id: Tr.whiteListModalShowIssuersButton})}
  content={
  <>
    <FormattedMessage id={Tr.whiteListModalSubTitle}/>
    <br/><br/>
    {checkWhiteListService.notWhiteListedInvoices.length > 0 &&
    <><FormattedMessage id={Tr.whiteListModalContent} values={{
      range: <span className="font-bold">{checkWhiteListService.notWhiteListedInvoices.length} z {checkWhiteListService.allInvoicesCount}</span>,
    }}/><br/><br/></>}
    <FormattedMessage id={Tr.whiteListModalTableTitle}/>
  </>
    
  }
  resolveButtonLabel={intl.formatMessage({id: Tr.acknowledge})}
  open={checkWhiteListService.modalIsActive}
  alignTop
  missingInvoices={tableList}
  onClose={() => {
    checkWhiteListService.setModalIsActive(false);
  }}
  tableHeaders={[intl.formatMessage({id: Tr.contractor}), intl.formatMessage({id: Tr.account})]}
/>;
});
