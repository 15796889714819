import * as React from 'react';
import {FC, MouseEvent} from 'react';
import {CircularProgress, MenuItem} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import {Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';

export type MenuItemWithLoaderProps = {
  onClick: (e?: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => void;
  title: string;
  loading?: boolean | (() => boolean);
  icon?: IconSvg;
  disabled?: boolean;
  'test-id'?: string;
  hidden?: boolean,
};

export const MenuItemWithLoader: FC<MenuItemWithLoaderProps> = observer(({
                                                                           onClick,
                                                                           title,
                                                                           loading: checkIsLoading,
                                                                           icon,
                                                                           disabled,
                                                                           hidden,
                                                                           'test-id': testId,
                                                                         }) => {
  if (hidden) {
    return null;
  }

  const loading = typeof checkIsLoading === 'function' ? checkIsLoading() : !!checkIsLoading;

  return (
    <MenuItem test-id={testId} disabled={loading || disabled} sx={{padding: 0, margin: 0}} onClick={e => onClick(e)}>
      <Box sx={{padding: 2}} className="flex">
        {icon && <Icon svg={icon} color={IconColor.GREY_500} size={IconSize.SM}/>}
        <ListItemText>{title}</ListItemText>
      </Box>
      {loading && (
        <ListItemIcon>
          <CircularProgress size={16}/>
        </ListItemIcon>
      )}
    </MenuItem>
  );
});
