import {useIntl} from 'react-intl';
import {usePageTitle} from '../../../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useActivateAutoPostingModuleMutation} from '@symfonia-ksef/graphql';
import {useParams} from 'react-router-dom';
import React, {useCallback, useState} from 'react';
import {IPostingInitializationState} from './IPostingInitializationState';
import {Breadcrumbs, ButtonPrimary, IconSvg, ToastVariant} from '@symfonia/brandbook';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {Header} from '../../../../../root/components/Header';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export interface PostingInactivePageProps {
  setIsActivated: () => void;
  state: IPostingInitializationState;
}

export const PostingInactivePage = ({setIsActivated, state}: PostingInactivePageProps) => {
  const intl = useIntl();
  const {setPageTitle} = usePageTitle();
  setPageTitle(intl.formatMessage({id: Tr.posting}));

  const {companyId, tenantId} = useParams<{ companyId: string; tenantId: string }>();
  const [activateAutoPostingModule] = useActivateAutoPostingModuleMutation({context: {envId: companyId}});
  const addAlert = earchiveState.alertsState.addAlert;
  const [requestIsActive, setRequestIsActive] = useState<boolean>(false);

  const activateModule = useCallback(async () => {
    setRequestIsActive(true);
    try {
      const result = await activateAutoPostingModule();
      const response = result.data?.ActivateAutoPostingModule.IsDone;
      if (response) {
        setIsActivated();
        state.accountState.earchiveState.moduleStatusStore.softResetModules();
      }
      if (!response) addAlert(intl.formatMessage({id: Tr.emailNotificationError}), ToastVariant.ERROR);
    } catch (e) {
      console.debug(e);
    } finally {
      setRequestIsActive(false);
    }
  }, [activateAutoPostingModule, addAlert, intl]);

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.automaticDecrees}),
    },
    {
      label: intl.formatMessage({id: Tr.config}),
    },
  ];


  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px] lg:w-[60%]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'}/>
        <Header id={'postingInactiveConfigurationTitle'}
                className={'flex items-start'}>
          {intl.formatMessage({id: Tr.postingConfiguration})}
        </Header>
        <p id={'postingNotActive'} className="mt-[20px]">
          {intl.formatMessage({
              id: Tr.postingNotActive,
            },
            {
              b: (chunks) => <strong>{chunks}</strong>,
              br: <br/>,
              companyName: <span style={{fontWeight: 'bold'}}>{earchiveState.company.name}</span>,
            },
          )}
        </p>
        <ButtonPrimary
          className={'mt-[10px]'}
          text={intl.formatMessage({id: Tr.activateTenantButton})}
          loading={requestIsActive}
          lIcon={requestIsActive ? IconSvg.LOOP : undefined}
          onClick={() => activateModule()}
        />
      </div>
    </PageContent>
  );
};
