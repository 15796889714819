import {Navigate, useParams} from 'react-router-dom';
import {EnvironmentStatus} from '@symfonia-ksef/graphql';
import {CompanyInactive} from './CompanyInactive&PendingApproval/CompanyInactive';
import {AccessDenied} from './AccessDenied';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const DesktopRedirect = observer(() => {
  const {companyId} = useParams();
  const company = earchiveState.environments.getEnvironmentById(companyId ?? '');

  if (!companyId || !company) {
    return <AccessDenied/>;
  }

  if (company.Status === EnvironmentStatus.Removed) {
    return <CompanyInactive companyName={company.Name ?? ''}/>;
  }

  if (company.Status === EnvironmentStatus.Inactive) {
    return <CompanyInactive companyName={company.Name ?? ''}/>;
  }

  return <Navigate
    to={'../' + company?.Tenant?.Id + '/company/' + company.EnvironmentId + '/documents/purchase'}
    replace/>;

});
