import clsx from 'clsx';
import {FC, KeyboardEventHandler, PropsWithChildren, useEffect, useState} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {Subject} from '../../external/helpers/Subject';
import {Icon, IconSize, IconSvg} from '../Icon/Icon';
import {Checkbox} from '../Checkbox/Checkbox';
import {TestableElement} from '../../external/types';

export enum AccordionSize {
  SM = 'SM',
  MD = 'MD',
}

type AccordionEvent = {
  group?: symbol | string | number;
  id?: symbol | string | number;
  expanded?: boolean;
  expandable?: boolean;
  onClick?: () => void;
} & TestableElement;

export type AccordionProps = AccordionEvent & {
  title?: string;
  href?: string;
  size?: AccordionSize;
  icon?: IconSvg;
  titleCheckbox?: {onChange: (e: boolean) => void; value?: string | number; checked?: boolean};
  disabled?: boolean;
  addMargin?: boolean;
  isNavigation?: boolean;
  preventCloseOnContentClick?: boolean;
};

const accordionSubject = new Subject<AccordionProps>();

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
  children = undefined,
  group = '',
  id = Symbol('Accordion ID'),
  expanded = false,
  expandable = true,
  title = '',
  href = '',
  size = AccordionSize.MD,
  icon = undefined,
  titleCheckbox = undefined,
  disabled = false,
  addMargin = false,
  isNavigation = false,
  preventCloseOnContentClick = false,
  onClick = () => undefined,
  testId = undefined,
}) => {
  const [accordionId] = useState(id);
  const [accordionGroup] = useState(group);
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const [isMouse, setIsMouse] = useState<boolean>(false);

  useEffect(() => {
    return accordionSubject.subscribe(event => {
      if (event.group === accordionGroup) {
        setIsExpanded(event.id === accordionId ? event.expanded || false : false);
      }
    });
  }, []);

  const toggleExpanded = () => accordionSubject.next({id: accordionId, group: accordionGroup, expanded: !isExpanded});

  const mouseDownHandler = () => setIsMouse(true);

  const clickHandler = () => {
    if (!disabled) {
      toggleExpanded();
      onClick();
    }
  };

  const blurHandler = () => setIsMouse(false);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event): void => {
    if (event.code === 'Enter') {
      toggleExpanded();
    }
  };

  const renderLink = () => {
    if (href && href.includes('https://')) {
      return (
        <Link to={href} className="w-full">
          <span className={clsx('grow', {'ml-[8px]': !isNavigation})}>{title}</span>
        </Link>
      );
    }
    if (href) {
      return (
        <NavLink to={href} onClick={onClick} className="w-full">
          {({isActive}) => (
            <span className={clsx('grow', {'ml-[8px]': !isNavigation, 'font-bold text-primary-500': isActive})}>
              {title}
            </span>
          )}
        </NavLink>
      );
    }
    return <span className={clsx('grow', {'ml-[8px]': !isNavigation})}>{title}</span>;
  };

  return (
    <div
      data-test-element="accordion"
      data-testid={testId}
      onMouseDown={mouseDownHandler}
      onClick={clickHandler}
      onBlur={blurHandler}
      onKeyDown={handleKeyDown}
    >
      <div
        className={clsx('flex items-center', {
          'mb-[8px]': addMargin,
          'border-b': !isNavigation,
          'border-none': isNavigation,
          'h-[48px] text-base': size === AccordionSize.SM && !isNavigation,
          'h-[56px] text-xl': size === AccordionSize.MD && !isNavigation,
          'h-[56px] text-base font-medium': isNavigation,
          'border-b-grey-500': !disabled && !isExpanded && !isNavigation,
          'border-b-primary-500': !disabled && isExpanded && !isNavigation,
          'cursor-default border-grey-300 text-grey-300': disabled && !isNavigation,
        })}
      >
        {titleCheckbox && (
          <Checkbox
            onChange={e => titleCheckbox.onChange(e)}
            value={
              typeof titleCheckbox.value === 'number' || typeof titleCheckbox.value === 'string'
                ? titleCheckbox.value
                : 0
            }
            disabled={disabled}
            checked={typeof titleCheckbox.checked === 'boolean' ? titleCheckbox.checked : undefined}
          />
        )}
        <div
          className={clsx('w-full group', {
            'focus:outline focus:outline-2 focus:outline-yellow-500 focus:rounded-lg focus:border-none':
              !disabled && !isMouse,
          })}
          tabIndex={0}
        >
          <div
            className={clsx({
              'cursor-pointer group-hover:text-primary-500 group-hover:border-b-primary-500 text-black': !disabled,
              'flex font-quicksand items-center w-full': true,
            })}
          >
            {icon && (
              <Icon
                className={clsx({
                  'mx-[8px]': true,
                  'group-hover:filter-primary-500 filter-grey-900': !disabled,
                  'filter-grey-300': disabled,
                })}
                svg={icon}
                size={IconSize.LG}
              />
            )}
            {renderLink()}
            {expandable && (
              <span className="ml-[8px] justify-end inline-flex">
                <Icon
                  className={clsx({
                    'mx-[8px]': true,
                    'group-hover:filter-primary-500 filter-grey-900': !disabled,
                    'filter-grey-300': disabled,
                  })}
                  svg={isExpanded ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
                  size={IconSize.LG}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div
          className={clsx({
            'pt-[24px] pb-[28px] outline-none font-roboto': !isNavigation,
            'text-sm font-quicksand font-normal': isNavigation,
          })}
          onClick={e => (preventCloseOnContentClick ? e.stopPropagation() : undefined)}
        >
          {children}
        </div>
      )}
    </div>
  );
};
