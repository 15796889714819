import {useMemo, useRef, useState} from 'react';
import {ButtonTertiary, ButtonTertiarySize, IconSvg, Tag, TagColor, TagProps, TagSize, TagVariant, Tooltip} from '@symfonia/brandbook';
import {useIsOverflowing} from '../../../hooks/useIsOverflowing';
import {useHover} from 'usehooks-ts';
import {cutTextLength} from '../../../helpers/cutTextLength';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';

export type TagCloudProps = {
  tags: Map<React.Key, TagProps>;
  onTagClicked?: (key: React.Key) => void;
  maxVisiblePills?: number;
};

export const TagCloud: React.FC<TagCloudProps> = ({...props}: TagCloudProps) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const rest = Math.max(props.tags.size - (props.maxVisiblePills ?? props.tags.size), 0);
  const pills = Array.from(props.tags.entries());

  const expandedPills = useMemo(
    () => (expanded ? pills : pills.slice(0, props.maxVisiblePills)),
    [pills, expanded, props.maxVisiblePills],
  );

  return (
    <div className="flex flex-row gap-2 flex-wrap shrink">
      {expandedPills.map(([key, value]) => {
        return (
          <TagCloudElement
            key={key}
            tag={value}
            onClick={props.onTagClicked ? () => props.onTagClicked?.(key) : undefined}
          />
        );
      })}
       {!!rest && (
          <ButtonTertiary
            size={ButtonTertiarySize.SM}
            onClick={() => setExpanded(expanded => !expanded)}
            text={intl.formatMessage({id: expanded ? Tr.less : Tr.more}, {prefix: rest ? `+${rest} ` : ''})}
            rIcon={expanded ? IconSvg.ARROW_DROP_UP : IconSvg.ARROW_DROP_DOWN}
          />
        )}
    </div>
  );
};

type TagCloudElementProps = {
  key: React.Key;
  tag: TagProps;
  onClick?: (key: React.Key) => void;
};

const TagCloudElement: React.FC<TagCloudElementProps> = ({...props}: TagCloudElementProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isOverflowing = useIsOverflowing(ref);
  const isHover = useHover(ref);

  const keyAsValue = (): string => {
    console.warn(`Tag text was not provided! Using key: ${props.key}!`);
    return props.key.toString();
  };

  const tagText = props.tag.text ?? keyAsValue();

  const handleClick = (tag: TagProps): (() => void) | undefined => {
    //if tag has custom function onClick then call it otherwise call global default function if exist
    return () => (tag.onClick ? tag.onClick() : props.onClick?.(props.key));
  };

  const shouldDisplayTooltip = isOverflowing && isHover;

  return (
    <Tooltip text={tagText} open={shouldDisplayTooltip}>
      <div ref={ref} className="">
        <Tag
          key={props.key}
          text={cutTextLength(tagText)?.truncatedText}
          size={props.tag.size ?? TagSize.SM}
          rIcon={props.tag.rIcon ?? IconSvg.CLEAR}
          lIcon={props.tag.lIcon ?? undefined}
          onClick={handleClick(props.tag)}
          color={props.tag.color ?? TagColor.DEFAULT}
          disabled={props.tag.disabled ?? false}
          variant={props.tag.variant ?? TagVariant.FILLED}
          className="h-auto"
        />
      </div>
    </Tooltip>
  );
};
