import {FC} from 'react';
import {DocumentTypeSettingsListElement} from './DocumentTypeSettingsListElement';
import {DocumentTypeSettingTypeEnum} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {DropdownListOption} from '@symfonia/brandbook';
import {IPostingDocumentTypesState} from '../../state/IPostingDocumentTypesState';

interface DocumentTypeSettingsListProps {
  state: IPostingDocumentTypesState;
  disabled?: boolean;
}

interface DocumentTypeSettingsObject {
  id: number;
  label: Tr;
  documentTypeSettingType: DocumentTypeSettingTypeEnum;
  documentTypeSetting: DropdownListOption | undefined;
  required?: boolean;
}

export const DocumentTypeSettingsList: FC<DocumentTypeSettingsListProps> = observer(
  ({
     disabled,
     state,
   }: DocumentTypeSettingsListProps) => {

    const documentTypeSettingsArray: DocumentTypeSettingsObject[] = [
      {
        id: 0,
        label: Tr.VAT,
        documentTypeSettingType: DocumentTypeSettingTypeEnum.Vat,
        documentTypeSetting: state.findOptionBySettingType(DocumentTypeSettingTypeEnum.Vat),
        required: true,
      },
      {
        id: 1,
        label: Tr.CORRECTIVE,
        documentTypeSettingType: DocumentTypeSettingTypeEnum.Corrective,
        documentTypeSetting: state.findOptionBySettingType(DocumentTypeSettingTypeEnum.Corrective),
        required: true,
      },
      {
        id: 2,
        label: Tr.SIMPLIFIED,
        documentTypeSettingType: DocumentTypeSettingTypeEnum.Simplified,
        documentTypeSetting: state.findOptionBySettingType(DocumentTypeSettingTypeEnum.Simplified),
      },
      {
        id: 3,
        label: Tr.ADVANCE_BILLING,
        documentTypeSettingType: DocumentTypeSettingTypeEnum.AdvanceBilling,
        documentTypeSetting: state.findOptionBySettingType(DocumentTypeSettingTypeEnum.AdvanceBilling),
      },
      {
        id: 4,
        label: Tr.CORRECTIVE_ADVANCE_BILLING,
        documentTypeSettingType: DocumentTypeSettingTypeEnum.CorrectiveAdvanceBilling,
        documentTypeSetting: state.findOptionBySettingType(DocumentTypeSettingTypeEnum.CorrectiveAdvanceBilling),
      },
    ];

    return (
      <div className="flex flex-col items-start gap-2 gap-x-12 w-full max-w-[600px]">
        {documentTypeSettingsArray.map((documentTypeElement) => {
          return <DocumentTypeSettingsListElement
              documentTypes={state.documentTypes.find(x => x.Id === documentTypeElement.documentTypeSetting?.value)}
              disabled={disabled}
              key={documentTypeElement.id}
              onDocumentTypeChange={(settingsType, documentTypeId) => {
                state.changeDocumentType(settingsType, documentTypeId);
              }}
              options={state.documentTypesOptions}
              required={documentTypeElement.required ?? false}
              documentTypeSettingType={documentTypeElement.documentTypeSettingType}
              documentTypeSetting={documentTypeElement.documentTypeSetting}
              label={documentTypeElement.label}
            />;
          },
        )}
      </div>
    );
  },
);
