import {AbstractNotificationErrorService, ErrorEvent} from './NotificationErrorService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';

class EarchiveValidationErrorService extends AbstractNotificationErrorService {
  constructor() {
    super(WebsocketErrorType.Validation);
  }

  protected _createAlert(event: ErrorEvent): AlertConfig {
    return {
      id: Tr.systemErrorMessage,
      values: {errorMessage: event.errorContent?.find(x => x.key === 'InnerMessage')?.value},
    };
  }
}

export const earchiveValidationErrorService = new EarchiveValidationErrorService();
