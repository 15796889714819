import {FC, useRef, useState, cloneElement, ReactElement} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {IconSvg} from '../Icon/Icon';
import {DropdownList, DropdownListProps} from './DropdownList';

export type ComponentWithDropdown<T> = T & {dropdown?: DropdownListProps};
export type ChildComponentWithDropdown<T> = T & {dropdownIcon?: IconSvg; dropdownIsOpen?: boolean};

export type WithDropdownListProps = {
  dropdown?: DropdownListProps;
  disabled?: boolean;
  children: ReactElement;
  className?: string;
};

export const WithDropdownList: FC<WithDropdownListProps> = ({
  dropdown = undefined,
  disabled = false,
  children,
  className = undefined,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef(null);

  const dropdownIcon: IconSvg | null = (() => {
    if (dropdown) {
      return !disabled && isOpen ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN;
    }
    return null;
  })();

  const handleClick = () => {
    if (!disabled && dropdown) {
      setIsOpen(!isOpen);
    }
  };

  const renderList = () =>
    dropdown && (
      <DropdownList
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className="mt-[8px]"
        anchorEl={ref}
        selectable={false}
        {...dropdown}
      />
    );

  const renderChildren = () => {
    return cloneElement(children, {
      dropdownIcon,
      dropdownIsOpen: isOpen,
    });
  };

  return (
    <span ref={ref} className={twMerge(clsx('w-fit h-fit', className))} onClick={handleClick}>
      {renderList()}
      {renderChildren()}
    </span>
  );
};
