import {action, computed, makeObservable, observable} from 'mobx';
import {ReactionsManager, ReactionsManagerI} from './ReactionsManager';
import {useEffect, useState} from 'react';


//Pozwala na zarządzanie cyklem życia stanu oraz wpięcie go do cyklu życia komponentów reactowych.
//W zamyśle przeznaczony do użycia z hookiem 'useModule' (dla komponentów) lub wywołania w klasie rodzica lub dziedziczącej
export interface BaseModuleI {

  //Uruchamia kod inicjalizujący stan - zmienia flagę initialized na true.
  //Uruchamia wszystkie dodane do reactionManagera subskrypcję stanu
  //Uruchamia customową implementację metody _onMount klasy dziedziczącej
  onMount(): Promise<void>;

  //Przeznaczona do sprzątania po tym jak cykl życia się zakończy (np komponent używający modułu zostanie odmontowany)
  //Zmienia flagę initialized na false
  //Anuluje wszystkie trwające subsktypcje stanu (zapobiega wyciekom pamięci)
  //Uruchamia customową implementację metody _onUnmount klasy dziedziczącej
  onUnmount(): void;

  get initialized(): boolean;
}

export abstract class BaseModule implements BaseModuleI {
  protected readonly reactionsManager!: ReactionsManagerI;

  protected constructor(private readonly autoReactionsManagementEnabled: boolean = true) {
    //Umożliwia zarządzanie subskrypcjami stanu (więcej info w ReactionsManagerI)
    //Subskrypcje można dodać w dowolnym momencie miesjcu kodu ale najlepiej zrobić to w konstruktorze klasy dziedzizcącej
    //Subskrypcje zostaną automatycznie uruchmione i anulowane po 'podpięciu stanu' w cykl życia (uruchomienie onMount i onUnmount w komponencie reactowym lub klasie rodzica)
    this.reactionsManager = new ReactionsManager();
    makeObservable(this);
  }

  @observable
  private _initialized: boolean = false;

  @computed
  public get initialized(): boolean {
    return this._initialized;
  }

  public async onMount(): Promise<void> {
    await this._onMount();
    this.autoReactionsManagementEnabled && this.reactionsManager.runAll();
    this.initialize();
  }

  public onUnmount(): void {
    this._onUnmount();
    this.autoReactionsManagementEnabled && this.reactionsManager.disposeAll();
    this.initialize(false);
  }

  protected _onMount(): Promise<void> | void {
    return;
  }

  protected _onUnmount(): void {
    return;
  }

  @action
  protected initialize(initialized?: boolean): void {
    this._initialized = initialized ?? true;
  }
}

//Wpina moduł ze stanem w cykl życia komponentu reactowego:
//tworzy instancję modułu i go inicjalizuje na czas życia komponentu
//uruchamia onMount przy zamontowaniu komponentu
//uruchamia onUnmount przy odmontowaniu komponentu
export const useModule = <T extends BaseModuleI>(moduleFactory: (() => T) | T): T => {
  const [module] = useState<T>(moduleFactory);
  useEffect(() => {
    module.onMount();
    return () => {
      module.onUnmount();
    };
  }, []);

  return module;
};
