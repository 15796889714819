import {KSeFEventsConverter} from './AbstractKSeFEventsConverters';
import {WebSocketNotificationFragment, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {authenticationState} from '@symfonia-ksef/state/rootRepository';
import {Tr} from '@symfonia-ksef/locales/keys';
import ShowPopupIcon from '../../../../../../assets/ic_show_popup.svg';
import {ReactNode} from 'react';

type UnauthorizeTypes =
  WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken
  | WebsocketNotificationType.UnauthorizedInKsefExternalToken
  | WebsocketNotificationType.UnauthorizedInKsef

export class UnauthorizeEventConverter extends KSeFEventsConverter<UnauthorizeTypes> {
  private isAutounauthorizeByToken!: boolean;

  constructor(type: UnauthorizeTypes, event: WebSocketNotificationFragment) {
    super(type, event);
    this.isAutounauthorizeByToken = type === WebsocketNotificationType.AutoUnauthorizedInKseFExternalToken;
  }

  public get notification(): ReactNode {
    return this.createNotification(this.model.hasError ? {id: Tr.unAuthSubError} : {id: Tr.unAuthSubSuccess});
  }

  public get actionElement(): { icon: string; tooltipText: Tr } | undefined {
    if (this.event.ErrorType || !this.isAutounauthorizeByToken) {
      return undefined;
    }
    return {
      icon: ShowPopupIcon,
      tooltipText: Tr.showMessage,
    };
  }

  public get model() {
    return {data: null, hasError: !!this.event.ErrorType};
  }

  public action(): void {
    this.isAutounauthorizeByToken && !this.event.ErrorType && authenticationState.setExpiredToken(true);
  }
}
