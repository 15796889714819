import clsx from 'clsx';
import {FC} from 'react';

export enum SeparatorDirection {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum SeparatorSize {
  SM = 'SM',
  MD = 'MD',
}

export type SeparatorProps = {
  size?: SeparatorSize;
  direction?: SeparatorDirection;
  className?: string;
  dashed?: boolean
};

export const SeparatorAlt: FC<SeparatorProps> = ({
                                                   size = SeparatorSize.MD,
                                                   direction = SeparatorDirection.HORIZONTAL,
                                                   className = '',
                                                   dashed = false,
                                                 }) => {
  const styles = {
    component: clsx(className, {
      'border-grey-200': true,
      'border-dashed': dashed,
      'w-full border-t-[1px]': size === SeparatorSize.SM && direction === SeparatorDirection.HORIZONTAL,
      'w-full border-t-[2px]': size === SeparatorSize.MD && direction === SeparatorDirection.HORIZONTAL,
      'h-full border-l-[1px]': size === SeparatorSize.SM && direction === SeparatorDirection.VERTICAL,
      'h-full border-l-[2px]': size === SeparatorSize.MD && direction === SeparatorDirection.VERTICAL,
    }),
  };
  return <div className={styles.component}/>;
};
