import dayjs from 'dayjs';
import {OrderDirection} from '@symfonia-ksef/graphql';
import {FilterScopeType, filterType} from '../../../state/filters/BaseFilterState';
import {DateRangeFilterType, ValueType} from '../../layout/components/Filters/FiltersState';

export function BuildSearchOptions<T>(properties: (keyof T)[], searchValue: string | undefined): any {
  if (!searchValue) {
    return null;
  }

  const mapped = properties.map(x => ({[x]: {contains: searchValue}}));

  if (mapped.length > 1) {
    return Object.assign({}, {or: mapped});
  }

  return mapped[0];
}

function mapEnumFilter(filter: filterType, key: string): any {
  if (filter.values.length > 1) {
    return {in: filter.values};
  }
  return {eq: filter.values[0]};
}

function mapListUUIDsFilter(filter: filterType, key: string): any {
  if (filter.values.length > 1) {
    return {some: {[key]: {in: filter.values}}};
  }
  return {some: {[key]: {eq: filter.values[0]}}};
}

function mapUUIDsFilter(filter: filterType, key: string): any {
  const filterValues = filter.values.map(x => x.length ? x : null);
  if (filter.values.length > 1) {
    return {in: filterValues};
  }
  return {eq: filterValues[0]};
}

function mapDateRangeFilter(filter: filterType, key: string): any {
  const value: DateRangeFilterType = filter.values[0];
  if (value !== undefined) {
    if (value.specific !== undefined) {
      return {gte: dayjs(value.specific).startOf('day'), lte: dayjs(value.specific).endOf('day')};
    }

    const from = value.from ? {gte: dayjs(value.from).startOf('day')} : undefined;
    const to = value.to ? {lte: dayjs(value.to).endOf('day')} : undefined;

    if (from !== undefined && to !== undefined) {
      return {...from, ...to};
    }

    if (from !== undefined) {
      return from;
    }

    if (to !== undefined) {
      return to;
    }
  }
}

function mapRangeFilter(filter: filterType, key: string): any {
  const value: ValueType = filter.values[0];
  if (value !== undefined) {
    if (value.specific !== undefined) {
      return {eq: value.specific};
    }

    const from = value.from ? {gte: value.from} : undefined;
    const to = value.to ? {lte: value.to} : undefined;

    if (from !== undefined && to !== undefined) {
      return {...from, ...to};
    }

    if (from !== undefined) {
      return from;
    }

    if (to !== undefined) {
      return to;
    }
  }
}

export function BuildFilters<K extends string = string>(filters: Record<string, filterType<K>>): any {
  const mapped = Object.keys(filters).map(key => {
    const filter = filters[key];

    switch (filter.type) {
      case FilterScopeType.String:
        return filter.values.length === 1
          ? BuildObject([...key.split('.'), 'contains'], filter.values[0])
          : BuildObject([...key.split('.'), 'in'], filter.values);
      case FilterScopeType.Date:
        return {[key]: mapDateRangeFilter(filter, key)};
      case FilterScopeType.Range:
        return {[key]: mapRangeFilter(filter, key)};
      case FilterScopeType.Enum:
        return {[key]: mapEnumFilter(filter, key)};
      case FilterScopeType.Number:
        return {[key]: {eq: filter.values[0]}};
      case FilterScopeType.Boolean:
        return {[key]: {eq: filter.values[0]}};
      case FilterScopeType.UUIDs: {
        if (filter.related) {
          return {[filter.related!.parentName]: mapListUUIDsFilter(filter, filter.related!.key)};
        } else {
          return {[key]: mapUUIDsFilter(filter, key)};
        }
      }
      default:
        throw new Error(`Not implemented filter type : ${filter.type} on key: ${key}`);
    }
  });

  if (mapped.length > 1) {
    return Object.assign({}, {and: mapped});
  }

  return mapped[0];
}

export function BuildSortOption(sortFieldName: string, sortDirection: OrderDirection) {
  return BuildObject(sortFieldName.split('.'), sortDirection);
}

export const Eq = (fieldName: string, value: any) => BuildObject([...fieldName.split('.'), 'eq'], value);


function BuildObject(fields: Array<string>, value: any) {
  const reducer = (acc: any, item: any, index: number, arr: string[]) => ({[item]: index + 1 < arr.length ? acc : value});
  return fields.reduceRight(reducer, {});
}
