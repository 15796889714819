import {action, computed, makeObservable, observable} from 'mobx';
import React, {ReactNode} from 'react';
import {
  GetWebsocketNotificationDocument,
  GetWebsocketNotificationQuery,
  GetWebsocketNotificationQueryVariables,
  WebSocketNotificationFragment,
  WebSocketNotificationResponseFragment,
  WebsocketNotificationStateType,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import type {EventParams} from '../../services/SubscriptionEventsService';
import {sessionStorageService} from '../../modules/common/helpers/storage';
import {removeAlert} from '../../services/helpers/AlertService';
import type {NotificationDataType} from '../../services/helpers/NotificationDataParsers';
import {NotificationDataParser, NotificationDataParserI} from '../../services/helpers/NotificationDataParsers';
import {keysTransformers} from '../../services/ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {ksefEventParserManager} from '@symfonia-ksef/state/KSeFSubscriptionServices/KSeFEventsParsersManager';
import {
  KSeFEventsConverter,
} from '../../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/AbstractKSeFEventsConverters';
import {
  KSeFEventConverterFactory,
} from '../../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/KSeFEventsConvertersManager';
import {NotificationsReader} from '../../services/NotificationsReader';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {BaseTableDataRepositoryService} from '../../modules/root/services/TableServices/DataSourceTableService';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

const STORAGE_SWITCH_STATE_KEY = 'storageEventsSwitchStateKey';

export type WsEventsVariables = GetWebsocketNotificationQueryVariables

export type WsEventsQuery = GetWebsocketNotificationQuery

export interface NotificationEvent extends WebSocketNotificationFragment {
  dataParser: NotificationDataParserI;
  ksefEventConverter: KSeFEventsConverter<WebsocketNotificationType, unknown> | null;
}

const getStorageSwitchStateKey = (envId: string) => `${STORAGE_SWITCH_STATE_KEY}.${envId}`;


export class WsEventsRepository extends BaseTableDataRepositoryService<'GetWebsocketNotificationsList', WsEventsQuery, WsEventsVariables> {
  @observable
  public checked: boolean = false;

  @observable
  public unread: boolean = false;

  private relatedAlerts: Record<string, string> = {};

  private readonly notificationsReader!: NotificationsReader;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, private readonly eventParserManager = ksefEventParserManager) {
    super('GetWebsocketNotificationsList', GetWebsocketNotificationDocument, envObserver, earchiveState);
    this.notificationsReader = new NotificationsReader(envObserver, earchiveState);
    this.initialize();
    makeObservable(this);
  }

  @observable.ref
  private _events: NotificationEvent[] = [];

  @computed
  get events(): NotificationEvent[] {
    return this.checked ? (this._events.filter(d => !d?.Visited) ?? []) : this._events;
  }

  @computed
  get eventsNumber() {
    return this._events?.filter?.(el => !el?.Visited).length;
  }

  @computed
  get eventsShortList(): { id: string, message: ReactNode }[] {
    return this.events.filter(e => !e.Visited).slice(0, 3).map(event => ({
      id: event.NotificationId,
      message: event.ksefEventConverter?.notification,
    }));
    // matchNotificationContent(event)
  }

  @computed
  get checkForVisited(): boolean {
    return !!this.data?.WebsocketNotification?.some?.(el => el?.Visited);
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }

  @action.bound
  public checkSwitch(e: React.ChangeEvent<HTMLInputElement>) {
    this.envId && sessionStorageService.setItem(getStorageSwitchStateKey(this.envId), this.setChecked(e.currentTarget.checked));
  }

  @action.bound
  public setUnread(unread: boolean): void {
    this.unread = unread;
  }

  @action.bound
  public setMessageRead(id: string): void {
    if (this._events.find(event => id === event.NotificationId && event.Visited)) {
      return;
    }
    const updated = this._events.map(event => event && event.NotificationId === id ? {
      ...event,
      Visited: true,
    } : event);
    updated && this.setEvents(updated, {setData: true});
    this.notificationsReader.markAsReady(id);
    this.removeAlertFor(id);
  }

  public bindToAlert(relation: { notificationId: string, alertId: string }): void {
    this.relatedAlerts[relation.notificationId] = relation.alertId;
  }

  @action.bound
  public addEvent<T extends WebsocketNotificationType = WebsocketNotificationType>(event: EventParams, data?: NotificationDataType<T>): void {
    if (this._events?.some?.(notification => notification?.NotificationId === event.notificationId)) {
      return;
    }
    const {type: Type, notificationId: NotificationId} = event;
    const parser = this.eventParserManager.setParser({Type, NotificationId} as WebSocketNotificationResponseFragment);
    data && parser.set(data);
    const dataParser = new NotificationDataParser<typeof Type>({Type}).set(data ?? null);
    const newEvent: NotificationEvent = {
      ...keysTransformers.uppercase(event),
      ksefEventConverter: KSeFEventConverterFactory.create<typeof Type>(keysTransformers.uppercase(event), {
        saveAsParser: true,
        data,
      }),
      dataParser,
    };
    this.setEvents([newEvent, ...this._events.length >= 100 ? this._events.slice(0, this.events.length - 1) : this._events], {setData: true});
  }

  @action
  public async markAllAsRead(): Promise<void> {
    if (!this._events) {
      return;
    }

    const markedAsReady = this._events.filter(notification => notification && !notification.Visited).map(notification => notification?.NotificationId as string) ?? [];
    await this.notificationsReader.markAsReadNow(...markedAsReady);
    markedAsReady.forEach(id => this.removeAlertFor(id));
    this.setEvents(this._events.reduce<NotificationEvent[]>((marked, v) => {
      v && marked.push({...v, Visited: true});
      return marked;
    }, []), {setData: true});
  }

  protected override handleEnvIdChange(envId: string | null) {
    envId && this.setChecked(!!sessionStorageService.getItem<boolean>(getStorageSwitchStateKey(envId)));
    this.configure({notificationStateType: WebsocketNotificationStateType.Archived}).fetch();
  }

  protected override onSuccess(data: GetWebsocketNotificationQuery['GetWebsocketNotificationsList']): void {
    const newEvents = (data.WebsocketNotification?.filter?.(Boolean) ?? [])?.map?.(event => ({
      ...event,
      ksefEventConverter: KSeFEventConverterFactory.create(event as WebSocketNotificationFragment, {saveAsParser: true}),
      dataParser: new NotificationDataParser(event as WebSocketNotificationFragment),
    })) as NotificationEvent[];
    (data.WebsocketNotification?.filter?.(Boolean) ?? [])?.forEach?.(event => this.eventParserManager.setParser(event as WebSocketNotificationFragment));
    this.setEvents(newEvents);
  }

  @action
  private setEvents(notificationEvent: NotificationEvent[], opt?: { setData: boolean }): void {
    opt?.setData && this.set({WebsocketNotification: notificationEvent});
    this._events = notificationEvent;
  }

  private removeAlertFor(notificationId: string): boolean {
    if (notificationId in this.relatedAlerts) {
      removeAlert(this.relatedAlerts[notificationId]);
      delete this.relatedAlerts[notificationId];
      return true;
    }
    return false;
  }

  @action
  private setChecked(c: boolean): boolean {
    this.checked = c;
    return this.checked;
  }
}


