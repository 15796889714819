import {AnyObject} from 'yup/es/types';
import {action, computed, makeObservable, observable} from 'mobx';
import {BaseModule, BaseModuleI} from './BaseModule';
import produce, {Immutable} from 'immer';


export type ImmutableStateProducer<StateT extends AnyObject> = (currentState: StateT) => void
export type PartialStateSetter<StateT extends AnyObject> = (currentState: StateT) => Partial<StateT>


//Służy do zarządzania stanem w jednolity sposób, bez konieczności pisania powtarzalnego kodu
//Przyjmuje generyczny stan w postaci dowolnego obiektu i obserwuje cały obiekt oraz jego pierwsze zagnieżdżenie (_state jest mutable, jego zawartość immutable)
//Pozwala resetować, monitorować i ustawiać stan na różne sposoby w zależności od potrzeb
//Dziedziiczy po BaseModuleI:
// zawiera reactionManager umożliwiający subskrybowanie stanu
// umożliwia implementację metod _onMount i _onUnmount
// umożliwia wpięcie w cykl życia -użycie onMount i onUnmount w klasie rodzica/dziedziczącej lub w komponencie reactowym (tu: useModule)
export interface StateI<StateT extends AnyObject> extends BaseModuleI {

  //Ustawia część stanu przy użyciu obiektu lub funkcji przyjmującej obecny stan i zwracającej część stanu który chcemy zmienić. Pozostały stan pozostanie bez zmian.
  //Przydatne dla zmiany stanów bez zagnieżdżeń lub w przypadku napdisania wybrancyh wartości stanu
  //NP. dla stanu {name:string, age:number, items:number[], nested: {nestedItem: {nestedItem2: {nestedArr: number[]}}}}:
  //set(current=>(({ name:"John", items:[...current.items, 4] })) zmieni imię, doda nowy item i pozostawi 'age' oraz 'nested' bez zmian
  //set({items:[1,2,3]}) nadpisze tylko items
  set(partialState: Partial<StateT> | PartialStateSetter<StateT>): this;

  //Nadpisuje cały stan nowym
  // NP. override({ name:"Mark", age:25, items:[2,5], nested:{ nestedItem:{ nestedItem2:{ nestedArr:[1,2] } } } })
  //Lub zmienia jego wybraną część i nadpisuje referencję całości (przydatne przy zagnieżdżonych stanach)
  // NP. override(currentState=>{ currentState.nestedItem.nestedItem2.nestedArr.push(3) }) tutaj pod maską użyty immer do wygotnego napdisania zagnieżdżeń
  override(state: StateT | ImmutableStateProducer<StateT>): this;

  //Przywraca stan lub jego część do wartości inicjującej.
  //Zresetowane zostaną części stanu, których klucze zostaną przekazane jako argumenty np. reset("name", "age").
  //Jeśli nie przekażemy argumentów, zresetowany zostanie cały stan.
  reset(...keys: Array<keyof StateT>): this;

  //Sprawdza czy dana wartość stanu została zmieniona względem wartości inicjującej.
  checkIsDirty(key: keyof StateT): boolean;

  //obiekt zawierający cały stan.
  // Stan obserowany jest jedno zagnieżdżenie w głąb (observalbe.shallow) - oznacza to że _state może być mutowalne ale jego właściwości są już niemutowalne
  get state(): StateT;
}

export class State<StateT extends AnyObject> extends BaseModule implements StateI<StateT> {
  constructor(private initialState: StateT) {
    super();
    this.override(initialState);
    makeObservable(this);
  }

  @observable.shallow
  protected _state!: StateT;

  @computed
  public get state(): StateT {
    return this._state;
  }

  public checkIsDirty(key: keyof StateT): boolean {
    return this._state[key] !== this.initialState[key];
  }

  @action.bound
  public override(state: StateT | ImmutableStateProducer<StateT>): this {
    if (this.isImmutableStateProducer(state)) {
      this._state = produce(state)(this._state as Immutable<StateT>);
      return this;
    }
    this._state = state;
    return this;
  }

  @action.bound
  public set(state: Partial<StateT> | PartialStateSetter<StateT>): this {
    state = typeof state === 'function' ? state(this._state) : state;
    for (const key in state) {
      const currentValue = this._state[key];
      type CurrentValueType = typeof currentValue
      const value = state[key];
      this._state[key] = value as CurrentValueType;
    }
    return this;
  }

  @action.bound
  public reset(...keys: Array<keyof StateT>): this {
    if (!keys.length) {
      this._state = this.initialState;
    }

    for (const key of keys) {
      this._state[key] = this.initialState[key];
    }

    return this;
  }

  private isImmutableStateProducer(state: StateT | ImmutableStateProducer<StateT>): state is ImmutableStateProducer<StateT> {
    return typeof state === 'function';
  }
}
