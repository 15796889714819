import {KSeFStatus} from "@symfonia-ksef/graphql";
import {TagColor} from "@symfonia/brandbook";

export const InvoiceKsefStatusTagColor = (value: KSeFStatus): TagColor => {
    switch (value) {
      case KSeFStatus.Approved:
        return TagColor.GREEN_LIGHT;
      case KSeFStatus.Error:
        return TagColor.RED_LIGHT;
      case KSeFStatus.Pending:
        return TagColor.ORANGE_LIGHT;
      case KSeFStatus.Rejected:
        return TagColor.RED_LIGHT;
      case KSeFStatus.NotSent:
        return TagColor.GREY_LIGHT;
      case KSeFStatus.Sending:
        return TagColor.GREY_LIGHT;
      case KSeFStatus.InterruptedSending:
        return TagColor.RED_LIGHT;
      case KSeFStatus.InterruptedPending:
        return TagColor.RED_LIGHT;
      case KSeFStatus.Ocr:
        return TagColor.GREY_LIGHT;
      default:
        return TagColor.GREY_LIGHT;
    }
  };
  