import {action, computed, makeObservable, observable} from 'mobx';
import {IPostingConfigurationState} from './IPostingConfigurationState';
import {
  CategoryViewModel,
  FinancialYearResponse,
  GetCategoriesDocument,
  GetCategoriesQuery,
  InvoiceTypeGroupEnum,
} from '@symfonia-ksef/graphql';
import {apolloClient} from '../../../../../../root/providers/GraphQLProvider';
import {l} from '../../../../../../logger/loggerInstance';
import {intl} from '../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {localStorageService} from '../../../../../../common/helpers/storage';
import {IVehicleState} from './Vehicles/state/IVehicleState';
import {VehicleState} from './Vehicles/state/VehicleState';
import {ICategoryAccountState} from './CategoryAccounts/CategoryAccountsTable/state/ICategoryAccountState';
import {CategoryAccountState} from './CategoryAccounts/CategoryAccountsTable/state/CategoryAccountState';
import {ICategoryDetailsState} from './CategoryDetails/state/ICategoryDetailsState';
import {CategoryDetailsState} from './CategoryDetails/state/CategoryDetailsState';
import {IPostingInitializationState} from '../IPostingInitializationState';
import {IPostingDocumentTypesState} from './DocumentTypes/state/IPostingDocumentTypesState';
import {PostingDocumentTypesState} from './DocumentTypes/state/PostingDocumentTypesState';
import {ISettingsState} from './Settings/state/ISettingsState';
import {SettingsState} from './Settings/state/SettingsState';
import {ToastVariant} from '@symfonia/brandbook';
import {ISettlementsState} from "./Settlements/State/ISettlementsState";
import {SettlementsState} from "./Settlements/State/SettlementsState";
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export class PostingConfigurationState implements IPostingConfigurationState {
  @observable
  public availableFinancialYears: FinancialYearResponse[] | undefined;

  @observable
  public selectedFinancialYear: string | undefined;

  @observable
  forceRefreshFinancialYear: boolean = false;

  @observable
  public invoiceTypeGroup = InvoiceTypeGroupEnum.Purchase;

  @observable
  companyId = '';

  @observable
  categories: CategoryViewModel[] = [];

  public postingInitializationState: IPostingInitializationState;
  public documentTypesState: IPostingDocumentTypesState;
  public settlementsState: ISettlementsState;
  public vehicleState: IVehicleState;
  public categoryAccountState: ICategoryAccountState;
  public categoryDetailsState: ICategoryDetailsState;
  public settingsState: ISettingsState;

  constructor(postingInitializationState: IPostingInitializationState) {
    makeObservable(this);
    this.postingInitializationState = postingInitializationState;
    this.documentTypesState = new PostingDocumentTypesState(this);
    this.settlementsState = new SettlementsState(this);
    this.vehicleState = new VehicleState(this);
    this.categoryAccountState = new CategoryAccountState(this);
    this.categoryDetailsState = new CategoryDetailsState(this);
    this.settingsState = new SettingsState(this);
    this.loadInvoiceTypeGroupFromStorage();
  }

  @computed
  public get isFinancialYearActive(): boolean {
    return this.availableFinancialYears?.find(x => x.Id === this.selectedFinancialYear)?.IsActive ?? false;
  }

  @computed
  public get isFinancialYearActiveAndNotConfigured(): boolean {
    const financialYearToCheck = this.availableFinancialYears?.find(x => x.Id === this.selectedFinancialYear);
    return (financialYearToCheck?.IsActive && !financialYearToCheck?.IsConfigured) ?? false;
  }

  @action
  loadSelectedFinancialYearFromStorage(selectedFinancialYear: string) {
    const selectedFinancialYearInStorage = localStorageService.getItem('autoposting.SelectedFinancialYear.companyId.' + this.companyId) as string;

    if (selectedFinancialYearInStorage && this.availableFinancialYears?.find(x => x.Id === selectedFinancialYearInStorage)) {
      this.setSelectedFinancialYear(selectedFinancialYearInStorage);
    } else {
      this.setSelectedFinancialYear(selectedFinancialYear);
    }
  }

  @action
  setSelectedFinancialYear(selectedFinancialYear: string) {
    this.selectedFinancialYear = selectedFinancialYear;
    localStorageService.setItem('autoposting.SelectedFinancialYear.companyId.' + this.companyId, selectedFinancialYear);
  }

  @action
  setAvailableFinancialYears(financialYears: FinancialYearResponse[]) {
    this.availableFinancialYears = financialYears;
  }

  @action
  setInvoiceTypeGroup(group: InvoiceTypeGroupEnum) {
    this.invoiceTypeGroup = group;
    localStorageService.setItem('autoposting.InvoiceTypeGroup.companyId.' + this.companyId, group);
  }

  @action
  setCompanyId(companyId: string) {
    this.companyId = companyId;
  }

  @action
  async loadCategories(envId: string) {
    const {data, errors} = await apolloClient.query<GetCategoriesQuery>({
      query: GetCategoriesDocument,
      context: {envId},
    });
    if (errors) {
      l.error('Error when loading categories', undefined, errors);
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.gridErrorMessage}), ToastVariant.ERROR);
      return this;
    }
    if (data) {
      this.categories = data.GetCategoriesViewModel?.items as CategoryViewModel[];
    }

    return this;
  }

  @action
  public loadInvoiceTypeGroupFromStorage() {
    const group = localStorageService.getItem('autoposting.InvoiceTypeGroup.companyId.' + this.companyId) as InvoiceTypeGroupEnum;
    this.invoiceTypeGroup = group ?? InvoiceTypeGroupEnum.Purchase;
  }

  @action
  setForceRefreshFinancialYear(f: boolean) {
    this.forceRefreshFinancialYear = f;
  }
}
