import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FC} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ContextualMenu} from '../../../../../../../../common/components/ContextualMenu/ContextualMenu';
import {IVehicleState} from '../state/IVehicleState';
import {IconSvg} from '@symfonia/brandbook';

interface VehiclesTableMenuProps {
  state: IVehicleState;
}

export const VehiclesActionMenu: FC<VehiclesTableMenuProps> = observer(({state}) => {
  const intl = useIntl();

  const setEditVehicleModal = () => {
    state.selectedRowMenuAnchorEl = undefined;
    state.selectedRowMenuOpen = false;
    state.vehicleModalState.setVehicleToEdit();
  };

  const closeSingleActionsMenu = () => {
    state.setSelectedRowMenuOpen(false);
    state.setSelectedRowMenuAnchorEl(undefined);
  };

  const actions = [
    {
      key: Tr.edit,
      label: intl.formatMessage({id: Tr.edit}),
      onClick: setEditVehicleModal,
      icon: IconSvg.EDIT,
    },
    {
      key: Tr.removeButton,
      label: intl.formatMessage({id: Tr.removeButton}),
      onClick: () => state.setVehicleToDelete(),
      icon: IconSvg.DELETE,

    },
  ];

  return <ContextualMenu
    isOpen={state.selectedRowMenuOpen}
    actions={actions}
    anchorEl={state.selectedRowMenuAnchorEl ?? null}
    onClose={closeSingleActionsMenu}
  />;
});
