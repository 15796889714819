import {EventJobRunner, EventJobRunnerI} from '../EventJobRunner';
import {
  SubscribeGetUpoDocument,
  SubscribeGetUpoQuery,
  SubscribeGetUpoQueryVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {action, computed, makeObservable, observable} from 'mobx';
import {Tr} from '@symfonia-ksef/locales/keys';
import {KSeFSessionCheckerService, PendingProcess} from '../KSeFSessonCheckerService/KSeFSessionCheckerService';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface GenerateUpoJobRunnerI {
  invoiceIds: string[];
  terminationRequired: boolean | null;
  pendingProcesses: PendingProcess[];

  hasToWait: boolean;

  setHasToWait(hasToWait: boolean, opt?: { reset: boolean }): this;

  run(config?: SubscribeGetUpoQueryVariables): Promise<void>;
}

export class GenerateUpoJobRunner extends EventJobRunner<SubscribeGetUpoQueryVariables, keyof SubscribeGetUpoQuery> implements EventJobRunnerI<SubscribeGetUpoQueryVariables>, GenerateUpoJobRunnerI {

  private kSeFSessionChecker!: KSeFSessionCheckerService;

  private prevForce?: boolean;

  private force: boolean = false;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('SubscribeGetUpo', WebsocketNotificationType.GetUpo, SubscribeGetUpoDocument, envObserver, earchiveState);
    this.kSeFSessionChecker = new KSeFSessionCheckerService(envObserver, earchiveState);
    this.configureAlerts({
        success: {
          id: Tr.getUpoSubInProgress,
        },
        error: {
          id: Tr.getUpoSubError,
        },
      },
    );
    makeObservable(this);
  }

  @observable
  private _hasToWait: boolean = false;

  @computed
  public get hasToWait(): boolean {
    return this._hasToWait;
  }

  @computed
  public get invoiceIds(): string[] {
    return this.variables.InvoiceIds;
  }

  @computed
  public get pendingProcesses(): PendingProcess[] {
    return this.kSeFSessionChecker.pendingProcesses;
  }

  @computed
  public get terminationRequired(): boolean | null {
    return this.kSeFSessionChecker.terminationRequired;
  }

  @action.bound
  public setHasToWait(hasToWait: boolean, opt?: {
    reset: boolean
  }): this {
    this._hasToWait = hasToWait;
    if (opt?.reset) {
      this.setForce(false, opt);
    }
    return this;
  }

  public async run(config?: SubscribeGetUpoQueryVariables): Promise<void> {
    if (this.prevForce === false) {
      this.setForce(true);
    }
    config && this.configure(config);
    await this.fetch();
  }

  protected override async beforeFetch(): Promise<boolean> {
    if (this.force) {
      return true;
    }
    this.setHasToWait((await this.kSeFSessionChecker.checkSession(this.invoiceIds)).terminationRequired);
    return !this.hasToWait;
  }

  protected override afterFetch(): void {
    this.force && this.setHasToWait(false);
    this.setForce(false, {reset: this.prevForce !== undefined});
  }

  private setForce(force: boolean, opt?: { reset: boolean }): this {
    this.prevForce = opt?.reset ? undefined : this.force;
    this.force = force;
    return this;
  }
}
