import {useMatch, useResolvedPath} from 'react-router-dom';

export const useIsRouteActive = (path: string): boolean => {
  const resolved = useResolvedPath(path);
  const match = useMatch({path: resolved.pathname});
  return !!match;
};

export const useIsRouteContainsActive = (path: string): boolean => {
  const resolved = useResolvedPath(path);
  return resolved.pathname.includes(path);
};
