import {FC, useEffect, useState} from 'react';
import {UnderlineInputButtonWithClear} from '../../../../../../../../../auto-posting/components/UnderlineInputButton/UnderlineInputButton';
import {PostingChooseAccountModal} from '../../../../../modals/PostingChooseAccountModal';
import {ICategoryAccountState} from '../state/ICategoryAccountState';
import {observer} from 'mobx-react-lite';
import {TreeViewDropdownModel} from '../../../../../../../../../common/components/TreeViewDropdown/TreeViewDropdown';
import {AccountTypeEnum} from '@symfonia-ksef/graphql';
import {intl} from '../../../../../../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Tooltip, TooltipPosition} from '@symfonia/brandbook';

interface CategoryAccountInputProps {
  accountId: string;
  accountName: string | undefined;
  accountType: AccountTypeEnum;
  categoryId: string;
  state: ICategoryAccountState;
}

export const CategoryAccountInput: FC<CategoryAccountInputProps> = observer(
  ({accountId, accountName, accountType, categoryId, state}) => {
    const [account, setAccount] = useState(accountName);
    const [nameNumber, setNameNumber] = useState('');
    const [open, setOpen] = useState(false);

    const hasAccount = account !== undefined;

    useEffect(() => {
      const categoryAccountChanged = state.categoryAccountChangedArray.find(a => a.CategoryId === categoryId);

      setAccount(categoryAccountChanged !== undefined ? categoryAccountChanged.AccountId : accountId);

      setNameNumber(accountName || '');
    }, [state.categoryAccountChangedArray]);

    const handleSelect = (nodeIds: TreeViewDropdownModel) => {
      setAccount(nodeIds.key);

      setNameNumber(nodeIds.value);

      state.tableState.handlePostingAccountModalSelect(nodeIds);

      setOpen(false);
    };

    const handleOpen = (event: React.MouseEvent) => {
      if (state.isLoading) return;

      state.tableState.handleAccountInputClick(categoryId, accountId, accountType);

      setOpen(true);

      event.stopPropagation();
    };

    const handleRemove = (event: React.MouseEvent) => {
      if (state.isLoading) return;

      state.tableState.handleAccountInputClear(categoryId, accountType);

      setNameNumber(intl.formatMessage({id: Tr.postringConfiguration_no_assignment}));

      setAccount(undefined);

      event.stopPropagation();
    };

    return (
      <>
        {open && state.tableState.isPostingAccountModalOpen && (
          <PostingChooseAccountModal
            isLoading={state.isLoading}
            onClose={() => {
              setOpen(false);
              state.tableState.closePostingAccountModal();
            }}
            onSelected={handleSelect}
            state={state.tableState.postingAccountModalState}
          />
        )}
        <Tooltip text={nameNumber} position={TooltipPosition.LEFT}>
          <div>
            <UnderlineInputButtonWithClear
              disabled={state.isLoading}
              disabledClearButton={state.isLoading || !hasAccount}
              handleClear={event => handleRemove(event)}
              onClick={event => handleOpen(event)}
              value={nameNumber}
            />
          </div>
        </Tooltip>
      </>
    );
  },
);
