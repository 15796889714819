import {
  DateRangeSchemaModelInput,
  FiltersFieldsInput,
  NumberRangeSchemaModelOfDecimalInput,
} from '@symfonia-ksef/graphql';
import {InvoicesFilterKeys, InvoicesFiltersState} from './InvoicesFiltersState';
import {InvoiceFilterType} from '../../../models';
import {FilterScopeType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {DateRangeFilterType, ValueType} from '../../../../layout/components/Filters/FiltersState';
import dayjs from 'dayjs';
import {Tr} from '@symfonia-ksef/locales/keys';

export class InvoicesFiltersUtils {
  public static readonly translationsMap: Record<InvoicesFilterKeys, Tr> = {
    Numbers: Tr.InvoiceNumber,
    DocumentTypes: Tr.invoiceDocumentType,
    IssuerNames: Tr.IssuerNames,
    IssuerNips: Tr.IssuerNip,
    RecipientNips: Tr.RecipientNip,
    RecipientNames: Tr.RecipientNames,
    NetValue: Tr.NetValue,
    VatValue: Tr.VatValue,
    GrossValue: Tr.GrossValue,
    netValueSearchAmount: Tr.NetValue,
    netValueFrom: Tr.netValueFrom,
    netValueTo: Tr.netValueTo,
    vatValueSearchAmount: Tr.VatValue,
    vatValueFrom: Tr.vatValueFrom,
    vatValueTo: Tr.vatValueTo,
    grossValueSearchAmount: Tr.GrossValue,
    grossValueFrom: Tr.grossValueFrom,
    grossValueTo: Tr.grossValueTo,
    DateOfIssue: Tr.DateOfIssue,
    KsefDate: Tr.invoiceKsefIssueDate,
    invoiceKsefIssueDate: Tr.invoiceKsefIssueDate,
    invoiceKsefIssueDateFrom: Tr.invoiceKsefIssueDateFrom,
    invoiceKsefIssueDateTo: Tr.invoiceKsefIssueDateTo,
    DateFrom: Tr.DateFrom,
    DateTo: Tr.DateTo,
    KsefNumbers: Tr.KsefNumber,
    KSeFStatuses: Tr.KSeFStatuses,
    RegistrationNumber: Tr.registrationNumber,
    InvoicePostingStatuses: Tr.invoicePostingStatus
  };

  public static buildFiltersForQuery(state: InvoicesFiltersState): FiltersFieldsInput {
    const current = state.parent.scope.variant;
    const filters: FiltersFieldsInput = {
      Downloaded: state.parent.scope.validateVariant(current, InvoiceFilterType.Downloaded),
      ToDownload: state.parent.scope.validateVariant(current, InvoiceFilterType.NotDownloaded),
      Sent: state.parent.scope.validateVariant(current, InvoiceFilterType.Sent),
      ToSend: state.parent.scope.validateVariant(current, InvoiceFilterType.NotSent),
      Own: state.parent.isInternal,
      Foreign: state.parent.isExternal,
      ReadyForPosting: false,
      ToAccept: false,
    };

    for (const [key, filter] of Object.entries(state.getActiveFiltersForQuery)) {
      if (key in filters) {
        continue;
      }
      const filterKey = key as keyof FiltersFieldsInput;
      type Filter = typeof filters[typeof filterKey]
      switch (filter.type) {
        case FilterScopeType.Range: {
          (filters[filterKey] as Filter) = InvoicesFiltersUtils.mapRange(filter.values[0]);
          break;
        }
        case FilterScopeType.Date: {
          (filters[filterKey] as Filter) = InvoicesFiltersUtils.mapDateRange(filter.values[0]);
          break;
        }
        case FilterScopeType.String:
        case FilterScopeType.Number:
        case FilterScopeType.Boolean:
        case FilterScopeType.Enum:
        case FilterScopeType.UUIDs: {
          (filters[filterKey] as Filter) = filter.values.filter(Boolean);
          break;
        }
      }
    }

    return filters;
  }

  private static mapDateRange(dateFilter: DateRangeFilterType): DateRangeSchemaModelInput {
    const dateRange: DateRangeSchemaModelInput = {};
    if (dateFilter.specific) {
      const specificDate = dayjs(dateFilter.specific).format('YYYY-MM-DD');
      dateRange.DateFrom = specificDate;
      dateRange.DateTo = specificDate;
      return dateRange;
    }

    if (dateFilter.from) {
      dateRange.DateFrom = dayjs(dateFilter.from).format('YYYY-MM-DD');
    }
    if (dateFilter.to) {
      dateRange.DateTo = dayjs(dateFilter.to).format('YYYY-MM-DD');
    }
    return dateRange;
  }

  private static mapRange(rangeFilter: ValueType): NumberRangeSchemaModelOfDecimalInput {
    const range: NumberRangeSchemaModelOfDecimalInput = {};
    if (rangeFilter.specific || rangeFilter.specific === 0) {
      range.From = rangeFilter.specific;
      range.To = rangeFilter.specific;
      return range;
    }
    if (rangeFilter.from) {
      range.From = rangeFilter.from;
    }
    if (rangeFilter.to) {
      range.To = rangeFilter.to;
    }
    return range;
  }
}
