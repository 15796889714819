import {observer} from 'mobx-react-lite';
import {Text} from '../../../root/components/Text';
import {AnyObject} from 'yup/es/types';
import {ButtonTertiary, ButtonTertiarySize} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import * as React from 'react';
import {intl} from '../../../root/IntlProvider';
import {MultipleActionsConfig} from '../../types/MenuActions';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {MobileMultipleActionsItems} from './MobileMultipleActionsItems';
import {DesktopMultipleActionsItems} from './DesktopMultipleActionsItems';
import {FormattedMessage} from 'react-intl';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const MultipleActionsBar = observer(<T extends AnyObject = AnyObject>(props: MultipleActionsConfig<T>) => {
  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  return <>{!!props.selectionState.selected.length &&
    <div className="w-full flex justify-between items-center bg-grey-50 rounded-[5px] m-[16px_0] p-[8px_16px]">
      <div>
        <Text small className="font-semibold pr-[8px] border-r-[1px] border-grey-400">
          {props.selectionState.selected.length}&nbsp;
          <FormattedMessage id={isPhone ? Tr.selected : Tr.elementsSelected}/>
        </Text>
        <ButtonTertiary
          size={ButtonTertiarySize.SM}
          className="text-[0.75em] mx-[8px]"
          onClick={() => props.selectionState.clearSelection()}
          text={intl.formatMessage({id: Tr.uncheck})}
        />
      </div>
      {isPhone ? <MobileMultipleActionsItems {...props}/> : <DesktopMultipleActionsItems {...props}/>}
    </div>}</>;
});
