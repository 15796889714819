import {ApolloError} from '@apollo/client';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  AccountTypeEnum,
  InvoiceTypeGroupEnum,
  useGetAccountsLazyQuery,
  useSaveCategoriesAccountsMutation,
} from '@symfonia-ksef/graphql';
import {CategoriesAccountsFiltersKeys} from '../pages/PostingConfiguration/CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {IPostingConfigurationState} from '../pages/PostingConfiguration/IPostingConfigurationState';
import {usePostingConfigurationStatus} from '../../../../hooks/usePostingConfigurationStatus';
import {addAlert} from '../../../../../../services/helpers/AlertService';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const usePostingConfigurationsMethods = (state: IPostingConfigurationState) => {
  const {
    company: {companyId},
  } = earchiveState;
  const [saveChanges] = useSaveCategoriesAccountsMutation({context: {envId: companyId}});
  const [accounts] = useGetAccountsLazyQuery({context: {envId: companyId}});
  const {getRequiredConfigurationStatus} = usePostingConfigurationStatus();
  const categoryAccountState = state.categoryAccountState;

  const onSuccess = () => {
    categoryAccountState.setIsLoading(false);
    categoryAccountState.tableState.triggerTableRefresh();
    categoryAccountState.resetState();
    getAccounts(state.selectedFinancialYear);
    if (state.isFinancialYearActive) getRequiredConfigurationStatus();
    addAlert({
      id: Tr.operationSuccess,
      color: ToastVariant.SUCCESS,
      duration: 10000,
    });
  };

  const onError = (error?: ApolloError) => {
    categoryAccountState.setIsLoading(false);
    addAlert({
      id: Tr.categoryAccountSaveFailed,
      color: ToastVariant.ERROR,
      duration: 10000,
    });
  };

  const saveChangesOnCategoryAccount = () => {
    categoryAccountState.setIsLoading(true);
    saveChanges({
      variables: {
        UpdatedCategoriesAccounts: categoryAccountState.categoryAccountChangedArray,
        CompanyId: companyId,
        FinancialYearId: state.selectedFinancialYear,
      },
    })
      .then((res: any) => {
        if (!res?.data?.SaveCategoriesAccounts.IsDone) {
          onError();
        }
        state.setForceRefreshFinancialYear(true);
        onSuccess();
      })
      .catch((error: ApolloError) => {
        onError(error);
      });
  };

  const getAccounts = (year?: string) => {
    categoryAccountState.setIsLoading(true);
    categoryAccountState.categoryAccountStore.clearDdlAccounts();
    accounts({
      variables: {
        CompanyId: companyId,
        FinancialYearId: year ?? state.selectedFinancialYear,
        InvoiceTypeGroup:
          categoryAccountState.filterState.activeFilters.get(CategoriesAccountsFiltersKeys.InvoiceTypeGroup)
            ?.values[0] ?? InvoiceTypeGroupEnum.Purchase,
        AccountType: AccountTypeEnum.Kup,
      },
    })
      .then(res => {
        if (res.data != undefined) {
          categoryAccountState.categoryAccountStore.setDdlAccountsKUP(res.data.GetAccounts.AccountsPlan);
        }
      })
      .then(() => {
        accounts({
          variables: {
            CompanyId: companyId,
            FinancialYearId: year ?? state.selectedFinancialYear,
            InvoiceTypeGroup:
              categoryAccountState.filterState.activeFilters.get(CategoriesAccountsFiltersKeys.InvoiceTypeGroup)
                ?.values[0] ?? InvoiceTypeGroupEnum.Purchase,
            AccountType: AccountTypeEnum.Nkup,
          },
        }).then(res => {
          if (res.data != undefined) {
            categoryAccountState.categoryAccountStore.setDdlAccountsNKUP(res.data.GetAccounts.AccountsPlan);
          }
        });
      })
      .then(() => {
        categoryAccountState.setIsLoading(false);
        categoryAccountState.tableState.triggerTableRefresh();
      })
      .catch(error => {
        onError(error);
      });
  };

  return {
    saveChangesOnCategoryAccount,
    getAccounts,
  };
};
