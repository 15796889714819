import {action, computed, IObservableArray, makeObservable, observable, reaction} from 'mobx';
import {IPostingState} from '../../../state/IPostingState';
import {IPostingDetailsBodyListState} from '../components/PostingDetailsList/state/IPostingDetailsBodyListState';
import {PostingDetailsBodyListState} from '../components/PostingDetailsList/state/PostingDetailsBodyListState';
import {IPostingDetailsState} from './IPostingDetailsState';
import {IPostingDetailsStore} from './IPostingDetailsStore';
import {PostingDetailsStore} from './PostingDetailsStore';
import {
  AccountTypeEnum,
  CategoryAccountChangeRequestInput,
  DecreeDetailsChangeRequestInput,
  DecreeLineTypeEnum,
  DecreeLineValueChangeRequestInput,
  InvoiceTypeGroupEnum,
  PositionAccountChangeRequestInput,
  PositionCategoryChangeRequestInput,
  PositionValueChangeRequestInput,
  PositionVehicleUsageTypeChangeRequestInput,
  PostingStatusEnum,
  VatSummaryChangeRequestInput,
} from '@symfonia-ksef/graphql';
import {MultiSelectModel} from '@symfonia/symfonia-ui-components';
import {SaveChangesOnDecreeMutationFunction} from '../mutations/SaveChangesOnDecreeMutationFunction';
import {addAlert} from '../../../../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IVatRegistryChangeModalState} from '../modals/state/IVatRegistryChangeModalState';
import {VatRegistryChangeModalState} from '../modals/state/VatRegistryChangeModalState';
import {convertToMapDropdownListOption, convertToMultiSelectType} from '../../../../common/helpers/baseFilterHelpers';
import {ToastVariant} from '@symfonia/brandbook';
import {intl} from '../../../../root/IntlProvider';
import * as React from 'react';
import {DropdownListOption} from '@symfonia/symfonia-ksef-components';
import {
  InvoicePreviewInterface,
  InvoicePreviewRepository,
} from '@symfonia-ksef/state/InvoicePreviewState/InvoicePreviewRepository';
import {AcceptDecreesMutationFunction} from '../../PostingTablePage/Queries/AcceptDecreesMutationFunction';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const decreeStatusEnum = [PostingStatusEnum.Posted, PostingStatusEnum.Incomplete];

export class PostingDetailsState implements IPostingDetailsState {
  @observable
  public decreeId = '';

  @observable
  public invoicePreview = true;

  @observable
  public changedStatus: PostingStatusEnum | undefined = undefined;

  @observable
  public selectedDocumentType: MultiSelectModel = {value: '', key: ''};

  @observable
  public selectedVatSummaryRowId: string | undefined;

  @observable
  public isUnavailableSendToFKModal = false;

  @observable
  public detailsChanges: DecreeDetailsChangeRequestInput | undefined = undefined;

  @observable
  public sendToKsefLoaderButton: boolean = false;

  @observable
  public readonly categoryAccountChangeArray: IObservableArray<CategoryAccountChangeRequestInput> = observable([]);

  @observable
  public readonly positionCategoryChangeArray: IObservableArray<PositionCategoryChangeRequestInput> = observable([]);

  @observable
  public readonly positionAccountChangesArray: IObservableArray<PositionAccountChangeRequestInput> = observable([]);

  @observable
  public readonly positionVehicleUsageTypeChangeArray: IObservableArray<PositionVehicleUsageTypeChangeRequestInput> = observable([]);

  @observable
  public readonly vatSummaryChangeArray: IObservableArray<VatSummaryChangeRequestInput> = observable([]);

  @observable
  public readonly positionValueChangeArray: IObservableArray<PositionValueChangeRequestInput> = observable([]);

  @observable
  public readonly decreeLineValueChangeArray: IObservableArray<DecreeLineValueChangeRequestInput> = observable([]);

  @observable
  public dropdownStatusOptions: Map<React.Key, DropdownListOption<any>> = new Map<number, DropdownListOption>();

  @observable
  public defaultValueDropdownStatus: React.Key = 0;

  @observable
  public isAnyChangeInDetails = false;

  @observable
  public isSaveInProgress = false;

  @observable
  public isSalesInvoice: boolean | undefined;

  @observable
  public canAcceptBeforeSendToFK: boolean = false;

  @observable
  public acceptBeforeSendToFKTurnOnInSettings: boolean = false;

  @observable
  public canAcceptOwn: boolean = false;

  @observable
  public selectedIndex: number = 0;

  public postingDetailsStore: IPostingDetailsStore;
  public postingDetailsBodyListState: IPostingDetailsBodyListState;
  public postingState: IPostingState;

  @observable
  public vatRegistryChangeModalState: IVatRegistryChangeModalState | undefined;

  private readonly salesInvoicePreviewRepository = InvoicePreviewRepository.create({sessionStorageKey: 'salesInvoicePreviewData'});
  private readonly purchaseInvoicePreviewRepository = InvoicePreviewRepository.create({sessionStorageKey: 'purchaseInvoicePreviewData'});

  constructor(postingState: IPostingState) {

    makeObservable(this);
    this.postingState = postingState;
    this.postingDetailsStore = new PostingDetailsStore(this);
    this.postingDetailsBodyListState = new PostingDetailsBodyListState(this);
    this.convertDropdownStatusOptions();
    reaction(
      () => [this.categoryAccountChangeArray.length, this.positionCategoryChangeArray.length, this.positionAccountChangesArray.length, this.positionVehicleUsageTypeChangeArray.length],
      () => {
        if (this.isAnyChangeInDetails) {
          this.postingDetailsStore.fetchRecalculatedDecree();
        }
      },
    );
  }

  @computed
  public get documentTypesOptions(): MultiSelectModel[] {
    return convertToMultiSelectType(this.postingDetailsStore.documentTypesList, e => ({
      value: e.Symbol + ' - ' + e.Name,
      key: e.Id,
    }));
  }

  @action
  public convertDropdownStatusOptions(): void {
    this.dropdownStatusOptions = convertToMapDropdownListOption(decreeStatusEnum, el => ({
      label: intl.formatMessage({id: Tr[el]}) ?? '',
      value: el,
    }));
    this.defaultValueDropdownStatus = decreeStatusEnum.indexOf(this.postingDetailsStore.postingDetails?.PostingStatus ?? PostingStatusEnum.Incomplete);
      this.dropdownStatusOptions.forEach((v, k) => {
      if (v.value === this.changedStatus) this.defaultValueDropdownStatus = k;
    });
  }

  @computed
  public get validationVatRegisterIsNotNull(): boolean {
    return !!this.postingDetailsStore.postingDetails?.VatSummaries.every(e => e.VatRegisterId !== null);
  }

  @computed
  public get areChangesValid(): boolean {
    return (this.isAnyChangeInDetails && this.postingDetailsBodyListState.isSumValid);
  }

  @computed
  public get isLoading(): boolean {
    return this.postingDetailsStore.isLoading;
  }

  @computed
  get invoicePreviewRepository(): InvoicePreviewInterface | null {
    if (this.postingDetailsStore.postingDetails) {
      if (this.postingDetailsStore.postingDetails?.InvoiceTypeGroup === InvoiceTypeGroupEnum.Purchase) {
        return this.purchaseInvoicePreviewRepository;
      }

      if (this.postingDetailsStore.postingDetails?.InvoiceTypeGroup === InvoiceTypeGroupEnum.Sales) {
        return this.salesInvoicePreviewRepository;
      }
    }

    return null;
  }

  @computed
  public get isPageEditableByStatus(): boolean {
    return this.postingDetailsStore.postingDetails?.PostingStatus !== PostingStatusEnum.DecreeSent &&
      this.postingDetailsStore.postingDetails?.PostingStatus !== PostingStatusEnum.DecreeDownloaded;
  }

  @computed
  public get displayAcceptDecreeButton(): boolean {
    return this.isPageEditableByStatus &&
      this.canAcceptBeforeSendToFK &&
      !this.postingDetailsStore.postingDetails?.IsAccepted &&
      this.acceptBeforeSendToFKTurnOnInSettings;
  }

  @action
  public setIsSalesInvoice(type: InvoiceTypeGroupEnum) {
    this.isSalesInvoice = type === InvoiceTypeGroupEnum.Sales;
  }

  @action
  public setInvoicePreview(v: boolean) {
    this.invoicePreview = v;
  }

  @action
  public setDecreeId(id: string | undefined) {
    if (id) {
      this.decreeId = id;
      this.postingDetailsStore.fetchPostingDetail();
      return;
    }
    this.decreeId = '';
  }

  @action
  public changeStatus(v: PostingStatusEnum | undefined) {
    this.changedStatus = v;
    this.updateDecreeDetailsPostingStatusChange(v);
  }

  @action
  public changeDocumentType(v: MultiSelectModel) {
    if (v.key !== undefined) this.selectedDocumentType = v;
    this.updateDecreeDetailsDocumentTypeChange(v.key);
  }

  @action
  public openVatRegisteriesModal(vatRegisterId: string, rowId: string) {
    this.selectedVatSummaryRowId = rowId;

    this.vatRegistryChangeModalState = new VatRegistryChangeModalState();
    this.vatRegistryChangeModalState.setSelectedVatRegistryId(vatRegisterId);
    this.vatRegistryChangeModalState.handleOpen();

    this.postingDetailsStore.fetchPostingVatRegistries().then(() => {
      if (!this.vatRegistryChangeModalState) throw new Error('VatRegistryChangeModalState not set');
      this.vatRegistryChangeModalState.setVatRegistries(this.postingDetailsStore.vatRegistries);
    });
  }

  @action
  public setIsUnavailableSendToFKModal(v: boolean) {
    this.isUnavailableSendToFKModal = v;
  }

  @action
  public resetState() {
    this.categoryAccountChangeArray.clear();
    this.positionCategoryChangeArray.clear();
    this.positionAccountChangesArray.clear();
    this.vatSummaryChangeArray.clear();
    this.positionValueChangeArray.clear();
    this.positionVehicleUsageTypeChangeArray.clear();
    this.changeStatus(this.postingDetailsStore.postingDetails!.PostingStatus);
    this.changeDocumentType({
      key: this.postingDetailsStore.postingDetails!.DocumentType?.Id,
      value: this.postingDetailsStore.postingDetails!.DocumentType?.Symbol ?? '-',
    });
    this.detailsChanges = undefined;
    this.setIsAnyChangeInDetails(false);
    this.postingDetailsStore.fetchPostingDetail();
    this.postingDetailsBodyListState.setIsMaValueModified(false);
    this.postingDetailsBodyListState.setIsWnValueModified(false);
  }

  @action
  public resetButtons() {
    this.categoryAccountChangeArray.clear();
    this.positionCategoryChangeArray.clear();
    this.positionAccountChangesArray.clear();
    this.vatSummaryChangeArray.clear();
    this.positionValueChangeArray.clear();
    this.positionVehicleUsageTypeChangeArray.clear();
    this.detailsChanges = undefined;
    this.setIsAnyChangeInDetails(false);
  }

  @action
  public async saveChanges() {
    this.isSaveInProgress = true;
    await SaveChangesOnDecreeMutationFunction(this.postingState.earchiveState.companyId, {
      DecreeId: this.decreeId,
      CompanyId: this.postingState.earchiveState.companyId,
      UserId: earchiveState.company.userId,
      CategoryAccountChanges: this.categoryAccountChangeArray,
      PositionCategoryChanges: this.positionCategoryChangeArray,
      PositionAccountChanges: this.positionAccountChangesArray,
      PositionVehicleUsageTypeChanges: this.positionVehicleUsageTypeChangeArray,
      DetailsChanges: this.detailsChanges,
      VatSummaryChanges: this.vatSummaryChangeArray,
      PositionValueChanges: this.positionValueChangeArray,
      DecreeLineValueChanges: this.decreeLineValueChangeArray,
      ShouldRememberChanges: false, // TODO: remove hardcode with requirement https://dev.azure.com/symfoniapl/DRO/_workitems/edit/258963
    }).then(response => {
      if (response) {
        addAlert({id: Tr.decreeChangeSuccess, color: ToastVariant.SUCCESS, duration: 10000});
        this.resetButtons();
        this.postingDetailsStore.fetchRecalculatedDecree();
        this.setAcceptBeforeSendToFK(true);
      }
    }).finally(() => this.isSaveInProgress = false);
  }

  @action
  public async acceptDecrees() {
    await AcceptDecreesMutationFunction(
      this.postingState.earchiveState.companyId,
      [this.decreeId],
    ).then(() => {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.acceptedOneSuccess}, {succesMessageValue: this.postingDetailsStore.postingDetails?.InvoiceNumber}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      this.setAcceptBeforeSendToFK(false);
    }).catch(error => {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.acceptedFailure}, {decreeNumber: this.postingDetailsStore.postingDetails?.InvoiceNumber}), ToastVariant.ERROR, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    });
  }

  @action
  public async saveChangesAndAcceptDecree() {
    await this.saveChanges();
    await this.acceptDecrees();
  }

  @action
  public setIsAnyChangeInDetails(isAnyChangeInDetails: boolean) {
    this.isAnyChangeInDetails = isAnyChangeInDetails;
    this.setAcceptBeforeSendToFK(isAnyChangeInDetails);
  }

  @action
  public updateDecreeDetailsDocumentTypeChange(documentTypeId: string) {
    this.detailsChanges = {
      DocumentTypeId: documentTypeId,
      PostingStatus: this.detailsChanges?.PostingStatus ?? this.postingDetailsStore.postingDetails!.PostingStatus,
    };
  }

  @action
  public updateDecreeDetailsPostingStatusChange(postingStatus: PostingStatusEnum | undefined) {
    this.detailsChanges = {
      DocumentTypeId: this.detailsChanges?.DocumentTypeId ?? this.postingDetailsStore.postingDetails!.DocumentType?.Id,
      PostingStatus: postingStatus ?? this.postingDetailsStore.postingDetails!.PostingStatus,
    };
  }

  @action
  public updateCategoryAccountChangeArray(
    accountId: string,
    categoryId: string | null,
    decreeLineType: DecreeLineTypeEnum,
    accountType: AccountTypeEnum,
  ) {
    const request = {
      CategoryId: categoryId,
      AccountId: accountId,
      DecreeLineType: decreeLineType,
      DecreeLineAccountType: accountType,
    } as CategoryAccountChangeRequestInput;

    this.setIsAnyChangeInDetails(true);
    const categoryIndex = this.categoryAccountChangeArray.findIndex(e => e.CategoryId === categoryId && e.DecreeLineAccountType === accountType);
    if (categoryIndex < 0) {
      this.categoryAccountChangeArray.push(request);
    } else {
      this.categoryAccountChangeArray.splice(categoryIndex, 1, request);
    }
  }

  @action
  public updatePositionCategoryChangeArray(positionId: string, categoryId: string) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.positionCategoryChangeArray.findIndex(e => e.InvoicePositionId === positionId);
    if (positionIndex === -1) {
      this.positionCategoryChangeArray.push({CategoryId: categoryId, InvoicePositionId: positionId});
    } else {
      this.positionCategoryChangeArray.splice(positionIndex, 1, {
        CategoryId: categoryId,
        InvoicePositionId: positionId,
      });
    }
  }

  @action
  public updatePositionAccountChangeArray(invoicePositionId: string, accountType: AccountTypeEnum) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.positionAccountChangesArray.findIndex(e => e.InvoicePositionId === invoicePositionId);
    if (positionIndex === -1) {
      this.positionAccountChangesArray.push({InvoicePositionId: invoicePositionId, NewAccountType: accountType});
    } else {
      this.positionAccountChangesArray.splice(positionIndex, 1, {
        InvoicePositionId: invoicePositionId,
        NewAccountType: accountType,
      });
    }
  }

  @action
  updatePositionValueChangeArray(positionId: string, value: number) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.positionValueChangeArray.findIndex(e => e.DecreeLinePositionId === positionId);
    if (positionIndex === -1) {
      this.positionValueChangeArray.push({DecreeLinePositionId: positionId, Value: value});
    } else {
      this.positionValueChangeArray.splice(positionIndex, 1, {
        DecreeLinePositionId: positionId,
        Value: value,
      });
    }
  }

  @action
  public updateVatSummaryChangeArray(vatSummaryLineId: string, vatRegisterId: string) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.vatSummaryChangeArray.findIndex(e => e.VatSummaryLineId === vatSummaryLineId);
    if (positionIndex === -1) {
      this.vatSummaryChangeArray.push({VatSummaryLineId: vatSummaryLineId, VatRegisterId: vatRegisterId});
    } else {
      this.vatSummaryChangeArray.splice(positionIndex, 1, {
        VatSummaryLineId: vatSummaryLineId,
        VatRegisterId: vatRegisterId,
      });
    }
  }

  @action
  public updatePositionVehicleUsageTypeChangeArray(vehicleId: string) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.positionVehicleUsageTypeChangeArray.findIndex(e => e.VehicleId === vehicleId);
    if (positionIndex === -1) {
      this.positionVehicleUsageTypeChangeArray.push({VehicleId: vehicleId});
    }
  }

  @action
  updateDecreeLineValueChangeArray(decreeLineId: string, value: number) {
    this.setIsAnyChangeInDetails(true);
    const positionIndex = this.decreeLineValueChangeArray.findIndex(e => e.DecreeLineId === decreeLineId);
    if (positionIndex === -1) {
      this.decreeLineValueChangeArray.push({DecreeLineId: decreeLineId, Value: value});
    } else {
      this.decreeLineValueChangeArray.splice(positionIndex, 1, {
        DecreeLineId: decreeLineId,
        Value: value,
      });
    }
  }

  @action
  public onCloseVatRegistryChangeModal(): void {
    this.vatRegistryChangeModalState = undefined;
    this.selectedVatSummaryRowId = '';
  }

  @action
  public onSaveVatRegistryChangeModal(vatRegisterId: string): void {
    this.updateVatSummaryChangeArray(this.selectedVatSummaryRowId!, vatRegisterId);
    const vatRegister = this.postingDetailsStore.vatRegistries.find(x => x.Id === vatRegisterId);
    const vatSummary = this.postingDetailsStore.postingDetails?.VatSummaries.find(
      x => x.Id === this.selectedVatSummaryRowId,
    );

    if (!vatRegister) throw new Error('Not found VatRegister');
    if (!vatSummary) throw new Error('Not found VatSummary');

    vatSummary.VatRegisterId = vatRegister.Id;
    vatSummary.VatRegisterName = vatRegister.Name;
    this.onCloseVatRegistryChangeModal();
  }

  @action
  public setIsLoadingSendToKsefButton(v: boolean): void {
    this.sendToKsefLoaderButton = v;
  }

  @action
  public setAcceptBeforeSendToFK(accept: boolean): void {
    this.canAcceptBeforeSendToFK = accept ?? false;
  }

  @action
  public setAcceptBeforeSendToFKTurnOnInSettings(acceptBeforeSendToFKTurnOnInSettings: boolean): void {
    this.acceptBeforeSendToFKTurnOnInSettings = acceptBeforeSendToFKTurnOnInSettings ?? false;
  }

  @action
  public setAcceptOwn(acceptOwn: boolean): void {
    this.canAcceptOwn = acceptOwn;
  }

  @action
  public setSelectedIndex(idx: number): void {
    this.selectedIndex = idx;
  }

  @action
  public onSendToFK(): void {
    if (!this.validationVatRegisterIsNotNull) {
      this.setIsUnavailableSendToFKModal(true);
      return;
    }
    this.setIsLoadingSendToKsefButton(true);
    this.postingState.publishInvoices(this.postingDetailsStore.postingDetails?.InvoiceDocumentId);
  }
}
