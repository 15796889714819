import {
  DecreeLineResponse,
  DecreeLineTypeEnum,
  InvoiceTypeGroupEnum,
  PositionDetailsResponse,
} from '@symfonia-ksef/graphql';
import {PostingDetailsListItemModel} from '../models/PostingDetailListItemModel';
import {PostingDetailListPositionModel} from '../models/PostingDetailListItemPositionModel';

export class PostingDetailListHelper {
  public static mapDecreeLineToModel(decreeLines: DecreeLineResponse[], invoiceTypeGroup: InvoiceTypeGroupEnum): PostingDetailsListItemModel[] {
    return decreeLines.map((line, index) =>
      ({
        id: line.Id,
        rowIndex: index + 1,
        category: line.CategoryName,
        categoryId: line.CategoryId,
        account: line.AccountNameAndNumber,
        accountType: line.AccountType,
        wn: this.getValue(line, invoiceTypeGroup),
        ma: this.getValue(line, invoiceTypeGroup, false),
        positions: line.Positions
          ? this.mapDecreeLinePositionToModel(line, line.Positions)
          : [],
        canChangeAccountType: line.CanAccountBeChanged,
        accountId: line.Account?.Id,
        decreeLineType: line.LineType,
      } as PostingDetailsListItemModel));
  }

  public static mapDecreeLinePositionToModel(
    decreeLine: DecreeLineResponse,
    decreeLinePosition: PositionDetailsResponse[]): PostingDetailListPositionModel[] {
    return decreeLinePosition.map(line =>
      ({
        id: line.Id,
        name: line.Name,
        value: line.Value,
        category: decreeLine.CategoryName,
        categoryId: decreeLine.CategoryId,
        invoicePositionId: line.InvoicePositionId,
        isRuleCategory: line.IsRuleCategory,
        canEditVehicles: line.CanEditVehicles,
        accountType: decreeLine.Account?.AccountType,
        vehicleId: line.VehicleId,
        canChangeAccountType: decreeLine.CanAccountBeChanged,
        accountId: decreeLine.Account?.Id,
        decreeLineType: decreeLine.LineType,
        IsRound: line.IsRound,
      } as PostingDetailListPositionModel));
  }

  private static getValue(line: DecreeLineResponse, invoiceTypeGroup: InvoiceTypeGroupEnum, isWnCell = true) {
    if (invoiceTypeGroup === InvoiceTypeGroupEnum.Sales) {
      if (isWnCell && this.isDocumentRelated(line.LineType)) {
        return line.Value;
      }
      if (!isWnCell && !this.isDocumentRelated(line.LineType)) {
        return line.Value;
      }
    }

    if (invoiceTypeGroup === InvoiceTypeGroupEnum.Purchase) {
      if (isWnCell && !this.isDocumentRelated(line.LineType)) {
        return line.Value;
      }
      if (!isWnCell && this.isDocumentRelated(line.LineType)) {
        return line.Value;
      }
    }
    return null;
  }

  private static isDocumentRelated(value: DecreeLineTypeEnum) {
    return value === DecreeLineTypeEnum.DocumentRelated;
  }
}
