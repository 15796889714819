import {
  DecreeViewModel,
  DecreeViewModelFilterInput,
  GetPostingInvoicesDocument,
  GetPostingInvoicesQuery,
  GetPostingInvoicesQueryVariables,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {IPostingTableState} from './IPostingTableState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {TableSortDirection, ToastVariant} from '@symfonia/brandbook';
import {filterType} from '@symfonia-ksef/state/filters/BaseFilterState';
import {earchiveState, envObserver} from '@symfonia-ksef/state/rootRepository';
import {BuildFilters, BuildSortOption} from '../../../../../common/filters/FilterOptionExtensions';
import {
  BaseTableDataRepositoryService,
  DataSourceHandlerI,
  GetVariablesParams,
} from '../../../../../root/services/TableServices/DataSourceTableService';
import {l} from '../../../../../logger/loggerInstance';
import {intl} from '../../../../../root/IntlProvider';
import {GraphQLErrorWithMessage} from '../../../../../root/providers/GraphQLProvider';

export type PostingRow = Omit<DecreeViewModel, 'Contractor'> & {
  ContractorName: string;
  ContractorIdentifier: string;
};

export class PostingTableRepository extends BaseTableDataRepositoryService<
  'GetPostingInvoicesViewModel',
  GetPostingInvoicesQuery,
  GetPostingInvoicesQueryVariables
> {
  constructor(private readonly postingState: IPostingTableState) {
    super('GetPostingInvoicesViewModel', GetPostingInvoicesDocument, envObserver, earchiveState);
  }

  public setFilters(filterFields: Record<string, filterType>): this {
    return this.configure({FilterFields: BuildFilters(filterFields) as DecreeViewModelFilterInput});
  }

  protected override beforeFetch(): void | Promise<void> | boolean | Promise<boolean> {
    this.setFilters(this.postingState.filterState.getActiveFiltersForQuery);
  }

  protected override onSuccess(data: GetPostingInvoicesQuery['GetPostingInvoicesViewModel']): void | Promise<void> {
    data && this.postingState.setPostingInvoices(data.items as DecreeViewModel[]);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    l.error('Error when loading posting invoices table', undefined, errors);
    const {addAlert} = earchiveState.alertsState;
    addAlert(intl.formatMessage({id: Tr.postingInvoicesActionError}), ToastVariant.ERROR, {
      displayDuration: 10000,
      omitIfHasTheSameAlert: true,
    });
    this.postingState.setShowLoader(false);
  }

  protected override handleEnvIdChange(envId: string | null) {
    envId && this.configure({CompanyId: envId});
  }
}

export class PostingTableDataSourceHandler
  implements
    DataSourceHandlerI<
      'GetPostingInvoicesViewModel',
      GetPostingInvoicesQuery,
      GetPostingInvoicesQueryVariables,
      PostingRow
    >
{
  public readonly repository!: PostingTableRepository;

  constructor(postingState: IPostingTableState) {
    this.repository = new PostingTableRepository(postingState);
  }

  public dataMapper(data: GetPostingInvoicesQuery['GetPostingInvoicesViewModel'] | null): PostingRow[] {
    if (!data?.items?.length) {
      return [];
    }
    return data.items.map(
      ({Contractor, ...rest}) =>
        ({
          ...rest,
          ContractorIdentifier: Contractor?.Identifier ?? '',
          ContractorName: Contractor?.Name ?? '',
        }) as PostingRow,
    );
  }

  public getTotalCount(data: GetPostingInvoicesQuery['GetPostingInvoicesViewModel'] | null): number {
    return data?.totalCount ?? 0;
  }

  public prepareVariablesConfig(params: GetVariablesParams): Partial<GetPostingInvoicesQueryVariables> {
    const direction = params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc;
    let name = params.sortBy?.name ?? '';
    if (name === 'ContractorIdentifier') {
      name = 'Contractor.Identifier';
    }
    if (name === 'ContractorName') {
      name = 'Contractor.Name';
    }
    return {
      Take: params.size,
      Skip: params.offset,
      Order: BuildSortOption(name, direction),
    };
  }
}
