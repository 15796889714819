import {useIntl} from 'react-intl';
import {dateParser} from '../../../common';
import {useMemo} from 'react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Toast, ToastDirection, ToastVariant} from '@symfonia/brandbook';
import {GlobalAlertModel} from '@symfonia-ksef/state/EarchiveState/AlertsState';

const getToastTitleByAlertColor = (color: ToastVariant):string => {
  switch (color) {
    case ToastVariant.INFO:
      return Tr.info;
    case ToastVariant.SUCCESS:
      return Tr.success;
    case ToastVariant.ERROR:
    default:
      return Tr.error;
  }
};
export interface AlertsItemProps {
  alert: GlobalAlertModel;
  onClose: () => void;
}

export const AlertsItem = ({alert, onClose}: AlertsItemProps) => {
  const intl = useIntl();
  const notificationTime = useMemo<string | undefined>(() => alert.timestamp ? intl.formatMessage({id: Tr.ksefRequestLimitTime}, {time: dateParser(new Date(alert.timestamp), true)}) : undefined, [alert]);

  return (
    <Toast
      className='max-w-5xl mt-2'
      id={alert.uid}
      title={alert.title ?? intl.formatMessage({id: getToastTitleByAlertColor(alert.color)})}
      description={<div>{alert.content}{notificationTime && <p style={{marginLeft: 'auto'}}>{notificationTime}</p>}</div>}
      variant={alert.color}
      onClose={() => {
        alert?.onClose?.();
        onClose();
      }}
      actionButton={alert.actions ? {text: alert.actions.title, onClick: alert.actions.onClick} : undefined}
      direction={ToastDirection.VERTICAL}
     />
  );
};
