import {action, IObservableArray, makeObservable, observable} from 'mobx';
import {MultiSelectModel} from '../../../../../common/components/MultiSelect/MultiSelect';
import {IChangeCategoryModalState} from './IChangeCategoryModalState';
import {BaseModalState} from '@symfonia-ksef/state/modals/BaseModalState';
import {CategoryViewModel} from '@symfonia-ksef/graphql';
import {convertToMultiSelectType} from '../../../../../common/helpers/baseFilterHelpers';

export class ChangeCategoryModalState extends BaseModalState implements IChangeCategoryModalState {
  @observable
  public categories: IObservableArray<MultiSelectModel> = observable([]);

  @observable
  public selectedCategoryId = '';

  @observable
  public positionName = '';

  constructor(positionName: string) {
    super();
    makeObservable(this);

    this.positionName = positionName;
  }

  @action
  public onSelect(value: MultiSelectModel | null) {
    this.selectedCategoryId = value?.key ?? '';
  }

  @action
  public setCategories(categories: CategoryViewModel[]) {
    this.categories.replace(
      convertToMultiSelectType(categories, el => ({
        value: el.Name,
        key: el.Id,
      })),
    );
  }

  @action
  public setSelectedCategoryId(id: string): IChangeCategoryModalState {
    this.selectedCategoryId = id;
    return this;
  }

  @action
  public handleSave() {
    this.handleClose();
  }
}
