import {useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {ListItem, ListItemButton} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {AppRoutes} from '../../../root/Routes';
import {Tr} from '../../../../locales/translationKeys';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';


// TODO: This component should be in the admin module
export const AddTenantButton = observer(() => {
  const navigate = useNavigate();
  const setOpenDrawer = earchiveState.layoutState.setOpenDrawer;

  const handleClick = () => {
    setOpenDrawer(false);
    navigate(AppRoutes.administration.address);
  };

  return (
    <List sx={{marginTop: 'auto', marginBottom: '1rem'}}>
      <Divider/>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick} sx={{px: 3}}>
          <ListItemIcon sx={{minWidth: 30, mr: 1}}>{<AddIcon/>}</ListItemIcon>
          <ListItemText primary={<FormattedMessage id={Tr.addTenantButton}/>}/>
        </ListItemButton>
      </ListItem>
    </List>
  );
});
