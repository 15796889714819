import {ISearchTreeViewState} from './state/ISearchTreeViewState';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {observer} from 'mobx-react-lite';
import {PostingTreeView} from '../PostingTreeView/PostingTreeView';
import {Loader} from '../../../common/components/Loader/Loader';

export interface SearchTreeViewProps {
  state: ISearchTreeViewState;
  isLoading?: boolean;
}

export const SearchTreeView = observer(({state, isLoading}: SearchTreeViewProps): JSX.Element => {
  const intl = useIntl();
  return (
    <>
      {state.hasDataToDisplay && !(isLoading ?? false) ? (
        <PostingTreeView
          treeDropdownList={state.nodes}
          onSelect={selected => state.setSelected(selected)}
          selectedItemId={state.selectedId}
          expandedIds={state.expandedIds}
          onToggle={toggled => state.setExpanded(toggled)}
        />
      ) : isLoading ? (
        <Loader/>
      ) : (
        <div className="flex sm:justify-start md:justify-center min-h-[48px] items-center mx-[24px] font-quicksand">
          {intl.formatMessage({id: Tr.noDataToDisplay})}
        </div>
      )}
    </>
  );
});
