import {InvoiceBound} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {GenericInvoicesPage} from '../GenericInvoicesPage';
import {IDocumentsState} from '../state/IDocumentsState';

export interface PurchasePageProps {
  state: IDocumentsState;
}

export const PurchasePage = ({state}: PurchasePageProps) => {
  return <GenericInvoicesPage
    invoicesBound={InvoiceBound.External}
    pageTitleKey={Tr.purchaseDocuments}
    state={state.invoicePageState}/>;
};
