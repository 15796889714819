import {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Modal} from '@symfonia/brandbook';

export interface UnavailableSendToFKModalProps {
  onClose(): void;

  open: boolean;
}

export const UnavailableSendToFKModal: FC<UnavailableSendToFKModalProps> = observer(({onClose, open}) => {
  const intl = useIntl();

  return (
    <Modal
      onClose={onClose}
      title={intl.formatMessage({id: Tr.sendToFKUnavailable})}
      show={open}
      okButton={{text: intl.formatMessage({id: Tr.acknowledge}), onClick: onClose}}
    >
      <div className={''}>
        <FormattedMessage id={Tr.sendToFKUnavailableTxt}/>
      </div>
    </Modal>
  );
});

