import {FC, MouseEventHandler, ReactElement} from 'react';

export type TableRowProps = {
  children: ReactElement | ReactElement[];
  className?: string;
  wrapperClassName?: string;
  columnClassName?: string;
  dropdownContent?: ReactElement;
  dropdownAtColumn?: number;
  dropdownOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  caption?: boolean;
};

export const TableRow: FC<TableRowProps> = ({
  children,
  className = undefined,
  columnClassName = undefined,
  wrapperClassName = undefined,
  dropdownContent = undefined,
  dropdownAtColumn = undefined,
  dropdownOpen = false,
  onClick = undefined,
  caption = false,
}) => <></>;
