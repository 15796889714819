import {action, autorun, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {CreateCategoryStep, ICreateCategoryModalState} from './ICreateCategoryModalState';
import {BaseModalState} from '../../../../../../state/modals/BaseModalState';
import {debounce} from 'lodash';
import {AccountResponse, AccountTypeEnum, InvoiceTypeGroupEnum} from '@symfonia-ksef/graphql';
import {ISearchTreeViewState} from '../../../../components/AccountSelect/state/ISearchTreeViewState';
import {SearchTreeViewState} from '../../../../components/AccountSelect/state/SearchTreeViewState';
import {CreateCategoryMutationFunction} from '../../mutations/CreateCategoryMutationFunction';
import {SaveCategoriesAccountsMutationFunction} from '../../mutations/SaveCategoriesAccountsMutationFunction';
import {convertToTreeViewDropdownModel} from '../../../../../common/helpers/baseFilterHelpers';
import {Tr} from '../../../../../../locales/translationKeys';
import {intl} from '../../../../../../modules/root/IntlProvider';
import {IPostingAccountsSubStore, IPostingCategoriesSubStore} from '../../state/IPostingDetailsStore';
import {ToastVariant} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export class CreateCategoryModalState extends BaseModalState implements ICreateCategoryModalState {
  @observable
  public currentStepIndex = 0;

  @observable
  public isFinished = false;

  @observable
  public isLoading = false;

  @observable
  public categoryName = '';

  @observable
  public newCategoryId: string | undefined = '';

  @observable
  public invoiceTypeGroup: InvoiceTypeGroupEnum = InvoiceTypeGroupEnum.Undefined;

  @observable
  public steps: IObservableArray<CreateCategoryStep> = observable([]);

  @observable
  public searchTreeViewState: ISearchTreeViewState = new SearchTreeViewState();

  @observable
  public positionName = '';

  @observable
  public accountType: AccountTypeEnum = AccountTypeEnum.Undefined;

  @observable
  private isNameUnique = false;

  private debouncedCheckIfNameIsUnique = debounce(() => this.checkIfNameIsUnique(), 250);

  constructor(
    private companyId: string,
    private financialYearId: string,
    public dataStore: IPostingAccountsSubStore & IPostingCategoriesSubStore,
    positionName: string,
  ) {
    super();
    makeObservable(this);

    this.positionName = positionName;

    autorun(() => {
      if (this.dataStore.accounts.length) {
        this.searchTreeViewState.setNodes(this.dataStore.accountsAsTreeNodes);
      }
    });
  }

  @computed
  public get currentStep() {
    return this.steps[this.currentStepIndex];
  }

  @computed
  public get isBackButtonActive() {
    return this.currentStepIndex >= 0;
  }

  @computed
  public get isNextButtonActive() {
    return this.currentStep.stepRequirementCheck ? this.currentStep.stepRequirementCheck() : true;
  }

  @computed
  public get checkFirstStepRequirements() {
    return (
      this.categoryName !== '' &&
      this.isNameUnique &&
      this.searchTreeViewState &&
      this.searchTreeViewState.nodes.length > 0
    );
  }

  @computed
  public get checkSecondStepRequirements() {
    return !!this.searchTreeViewState.selectedId && !!this.accountType;
  }

  @computed
  public get isLastStep() {
    return this.currentStepIndex === this.steps.length - 1;
  }

  @action
  public setSteps(steps: CreateCategoryStep[]) {
    this.steps.replace(steps);
  }

  @action
  public override async handleSave() {
    this.isLoading = true;

    if (this.invoiceTypeGroup === InvoiceTypeGroupEnum.Undefined) throw new Error('InvoiceTypeGroup was not set');

    await CreateCategoryMutationFunction({
      Name: this.categoryName,
      CompanyId: this.companyId,
      InvoiceTypeGroup: this.invoiceTypeGroup,
    })
      .then(async categoryId => {
        this.newCategoryId = categoryId;

        await SaveCategoriesAccountsMutationFunction({
          CompanyId: this.companyId,
          FinancialYearId: this.financialYearId,
          UpdatedCategoriesAccounts: {
            AccountId: this.searchTreeViewState.selectedId,
            AccountType: this.accountType,
            CategoryId: this.newCategoryId,
          },
        }).catch(error => {
          earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.genericError}), ToastVariant.ERROR, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
        });
      })
      .finally(() => {
        this.isLoading = false;
        this.isFinished = true;
      });
  }

  @action
  public handleNextStep() {
    if (this.isLastStep) {
      this.handleSave();
      return;
    }
    this.steps[this.currentStepIndex].completed = true;

    this.currentStepIndex = 1;
  }

  @action
  public handlePrevStep() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex -= 1;
    } else if (this.currentStepIndex === 0) {
      this.handleClose();
    }
    this.steps[this.currentStepIndex].completed = false;
  }

  @action
  public setCategoryName(name: string) {
    this.categoryName = name;
    this.isNameUnique = false;
    this.debouncedCheckIfNameIsUnique();
  }

  @action
  setAccountType(accountType: AccountTypeEnum): void {
    this.accountType = accountType;
    this.dataStore.fetchAccounts(this.accountType);
  }

  @action
  public setAccounts(accounts: AccountResponse[]) {
    const nodes = convertToTreeViewDropdownModel(accounts, el => ({
      value: el?.Number + ' - ' + el?.Name,
      key: el?.Id,
      isSelectable: el.IsFinal && !el.IsDisabled,
      isDisabled: el.IsDisabled,
      isHidden: false,
      parentId: el?.ParentId,
      childrenIds: el?.ChildrenIds,
    }));

    this.searchTreeViewState.setNodes(nodes);
  }

  @action
  public setInvoiceTypeGroup(invoiceTypeGroup: InvoiceTypeGroupEnum) {
    this.invoiceTypeGroup = invoiceTypeGroup;
  }

  private checkIfNameIsUnique() {
    if (this.dataStore.categories.length > 0) {
      this.isNameUnique = !this.dataStore.categories.find(
        x => x.Name.toLowerCase() === this.categoryName.toLowerCase(),
      );
    }
    return this.isNameUnique;
  }
}
