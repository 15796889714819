import {DateRangeFilterType, DateType, ValidationDateError} from '@symfonia-ksef/state/FiltersModel/DatePickerState';
import dayjs, {Dayjs} from 'dayjs';
import {DateRange as DateRangeType} from '@symfonia/brandbook';
import {useEffect, useRef} from 'react';
import {usePrevious} from '@symfonia/symfonia-ui-components';
import {DATE_DMY} from '@symfonia/brandbook-external/types';

type Format = 'Y-M-D' | 'D-M-Y'
type Formatters<T extends string | Date> = Record<Format, (date: T extends string ? DateObj : string, pattern: Format) => T>
const defaultMaskPattern = 'D-M-Y';
export type DateObj = {
  year: number;
  month: number;
  day: number;
};

const errorDateTypeMap: Readonly<Record<keyof DateRangeFilterType, DateType>> = Object.freeze({
  from: 'start',
  to: 'end',
});

const dateFromStringStrategies: { toDate: Formatters<Date>, toString: Formatters<string> } = {
  toString: {
    'Y-M-D': (dateObj) => {
      const {year, month, day} = datePartsToStrings(dateObj);
      return `${year}-${month}-${day}`;
    },
    'D-M-Y': (dateObj) => {
      const {year, month, day} = datePartsToStrings(dateObj);
      return `${day}-${month}-${year}`;
    },
  },
  toDate: {
    'Y-M-D': (str) => {
      const yearMonthDay = str.split('-');
      return new Date(parseInt(yearMonthDay[0], 10), parseInt(yearMonthDay[1], 10) - 1, parseInt(yearMonthDay[2], 10));
    },
    'D-M-Y': (str) => {
      const [day, month, year] = str.split('-');
      return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    },
  },
};


export const parseDate = (str: string, pattern?: Format): Date => {
  const validatedPattern = getValidatedPattern(pattern);
  return dateFromStringStrategies.toDate[validatedPattern](str, validatedPattern);
};

export const toDateParts = (date: string | Date, pattern?: Format): DateObj => {
  date = typeof date === 'string' ? parseDate(date, pattern) : date;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return {year, month, day};
};

export const datePartsToStrings = ({day, month, year}: DateObj): Record<keyof DateObj, string> => {
  return {
    year: String(year),
    month: month >= 10 ? String(month) : `0${month}`,
    day: day >= 10 ? String(day) : `0${day}`,
  };
};

const validatePattern = (str: Format | undefined): boolean => {
  if (!str) {
    return false;
  }
  const validateChar = (char: 'Y' | 'M' | 'D') => str.split('').reduce((sum, currentChar, i, arr) => {
    return currentChar === char ? sum + 1 : sum;
  }, 0) === 1;
  const matched = /[YMD]-[YMD]-[YMD]/.test(str);
  return matched && [validateChar('Y'), validateChar('M'), validateChar('D')].every(Boolean);
};

const getValidatedPattern = (pattern?: Format) => validatePattern(pattern) ? pattern as Format : defaultMaskPattern;

export const adjustToPattern = (date: Date, pattern?: Format): string => {
  const {year, month, day} = datePartsToStrings(toDateParts(date, pattern));
  return getValidatedPattern(pattern).replace('Y', year).replace('M', month).replace('D', day);
};

export const toDateString = (date: Date | undefined | null): string => {
  if (!date) {
    return '';
  }
  return adjustToPattern(date, 'D-M-Y');
};

type Error = { type: ValidationDateError, boundary: DateType, message?: string } | undefined
type Errors = { from: Error, to: Error }

const validator = (dates: DateRangeFilterType | undefined, errType: ValidationDateError, params: { validate?: (dates: DateRangeFilterType | undefined) => boolean, checkIsEmpty?: (dates: DateRangeFilterType | undefined) => boolean, reduce?: (errors: Errors, tuple: [boundary: keyof DateRangeFilterType, date: Date | null], type: ValidationDateError) => Errors }): Errors => {
  const {checkIsEmpty, reduce, validate} = params;
  if (checkIsEmpty?.(dates)) {
    return {from: undefined, to: undefined};
  }
  if (validate ? !validate(dates) : false) {
    return {
      from: {boundary: 'start', type: errType},
      to: {boundary: 'end', type: errType},
    };
  }
  if (reduce && dates) {
    return Object.entries(dates).reduce<Errors>((errors, [key, date], i) => reduce(errors, [key as keyof DateRangeFilterType, date], errType), {
      from: undefined,
      to: undefined,
    });
  }
  return {from: undefined, to: undefined};
};

const dateEmptyValidation = (dates: DateRangeFilterType | undefined): Errors => validator(dates, 'emptyDate', {
  reduce: (errors, [boundary, date], errType) => {
    if (!date) {
      errors[boundary] = {boundary: errorDateTypeMap[boundary], type: errType};
    }
    return errors;
  },
});

const dateValidation = (dates: DateRangeFilterType | undefined): Errors => validator(dates, 'invalidDate', {
  reduce: (errors, [boundary, date], errType) => {
    if (date && !dayjs(date).isValid()) {
      errors[boundary] = {boundary: errorDateTypeMap[boundary], type: errType};
    }
    return errors;
  },
});

export const dateRangeValidation = (dates: DateRangeFilterType | undefined): Errors => validator(dates, 'outOfRange', {
  checkIsEmpty: (d) => !dates?.from || !dates?.to,
  validate: (dates) => (dates?.from && dates?.to) ? !dayjs(dates.from).isAfter(dates.to) : true,
});

export const dateMinValidation = (dates: DateRangeFilterType | undefined, minDate: Dayjs | undefined): Errors => validator(dates, 'minDate', {
  checkIsEmpty: dates => !dates?.from || !dates?.to || !minDate,
  reduce: (errors, [boundary, date], errType) => {
    if (date && minDate && dayjs(date).isBefore(minDate)) {
      errors[boundary] = {boundary: errorDateTypeMap[boundary], type: errType};
    }
    return errors;
  },
});

export const dateMaxValidation = (dates: DateRangeFilterType | undefined, maxDate: Dayjs | undefined): Errors => validator(dates, 'maxDate', {
  checkIsEmpty: dates => !dates?.from || !dates?.to || !maxDate,
  reduce: (errors, [boundary, date], errType) => {
    if (date && maxDate && dayjs(date).isAfter(maxDate)) {
      errors[boundary] = {boundary: errorDateTypeMap[boundary], type: errType};
    }
    return errors;
  },
});

export const validate = (dates: DateRangeFilterType | undefined, minDate: Dayjs | undefined, maxDate: Dayjs | undefined): Errors => {
  const errors: Errors[] = [];
  errors.push(dateEmptyValidation(dates), dateValidation(dates), dateRangeValidation(dates), dateMinValidation(dates, minDate), dateMaxValidation(dates, maxDate));
  return errors.reduce<Errors>((accErrors, {from, to}) => {
    if (from) {
      accErrors.from = from;
    }
    if (to) {
      accErrors.to = to;
    }
    return accErrors;
  }, {from: undefined, to: undefined});
};

export const mapToRange = (values: DateRangeFilterType | undefined): DateRangeType => ({
  from: toDateString(values?.from) as DATE_DMY,
  to: toDateString(values?.to) as DATE_DMY,
});

export const dateFromString = (dateString: string | undefined): Date | undefined => dateString ? parseDate(dateString, 'D-M-Y') : undefined;

export const mapFromRange = (range: DateRangeType | undefined | null): DateRangeFilterType => ({
  from: dateFromString(range?.from),
  to: dateFromString(range?.to),
});

export const defaultMinMaxDatesFactory = () => ({
  min: new Date(2022, 0, 1),
  max: new Date(new Date().toLocaleDateString('en-US')),
});

export const useMinMaxDate = (factory: () => { min: Date, max: Date } = defaultMinMaxDatesFactory): {
  min: Dayjs,
  max: Dayjs,
  minDate: Date,
  maxDate: Date
} => {
  const dates = useRef<{ min: Date, max: Date }>(factory()).current;
  const {min, max} = useRef<{ min: Dayjs, max: Dayjs }>({
    min: dayjs(dates.min),
    max: dayjs(dates.max),
  }).current;
  return {
    min, max, maxDate: dates.max, minDate: dates.min,
  };
};

export const useValidation = (dates: DateRangeFilterType | undefined, setErrors: (reason: ValidationDateError, value: string | null | undefined, boundary: DateType) => void, resetErrors: (boundary: DateType) => void) => {
  const {min, max} = useMinMaxDate();
  const prevDates = usePrevious(dates);
  useEffect(() => {
    if (dates === prevDates) {
      return;
    }

    const {from, to} = validate(dates, min, max);
    from ? setErrors(from.type, null, from.boundary) : resetErrors('start');
    to ? setErrors(to.type, null, to.boundary) : resetErrors('end');

  }, [dates, setErrors, resetErrors]);
};
