import {AbstractNotificationErrorService, ErrorEvent} from './NotificationErrorService';
import {WebsocketErrorType} from '@symfonia-ksef/graphql';
import {AlertConfig} from '../../helpers/AlertService';
import {Tr} from '../../../locales/translationKeys';

class KSeFConnectionErrorService extends AbstractNotificationErrorService {
  constructor() {
    super(WebsocketErrorType.KsefConnection);
  }


  protected _createAlert(event: ErrorEvent): AlertConfig {
    return {id: Tr.ksefConnectionErrorMessage};
  }
}

export const kSeFConnectionErrorService = new KSeFConnectionErrorService();
