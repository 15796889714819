import React, {FC, useMemo} from 'react';
import {ButtonSecondary, ButtonSecondarySize, IconSvg} from '@symfonia/brandbook';

export interface FiltersBarProps {
  tableLength: number;
  isFiltersMenuOpened: boolean;
  openFiltersMenu: (openFiltersMenu: boolean) => void;
  filtersApplied?: boolean;
  testId?: string;
}

export const FiltersBar: FC<FiltersBarProps> = ({
                                                  tableLength,
                                                  isFiltersMenuOpened,
                                                  openFiltersMenu,
                                                  filtersApplied,
                                                  testId,
                                                }) => {
  const filterButton = useMemo(() => {
    return (tableLength && tableLength > 1) || filtersApplied;
  }, [tableLength, filtersApplied]);

  return (
    <ButtonSecondary
        lIcon={IconSvg.FILTER_LIST}
        disabled={!filterButton}
        onClick={() => openFiltersMenu(!isFiltersMenuOpened)}
        size={ButtonSecondarySize.SM}
        testId={testId}
      />
  );
};
