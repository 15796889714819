import * as React from 'react';
import {FC} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Icon, IconColor, IconSize, IconSvg, Modal} from '@symfonia/brandbook';

interface DecreeConfigurationRequiredModalProps {
	open: boolean;
  onClose: () => void;
}

export const DecreeConfigurationRequiredModal: FC<DecreeConfigurationRequiredModalProps> = observer(({open, onClose}) => {
  const intl = useIntl();
	return (<Modal
      onClose={onClose}
      title={intl.formatMessage({id: Tr.decreeConfigurationRequiredModalTitle}, {br: <br/>})}
      show={open}
      centerButtons={true}
      className={'max-w-[480px] font-medium'}
      okButton={{text: intl.formatMessage({id: Tr.acknowledge}), onClick: onClose}}
    >
      <div>
        <p className={'mb-[10px]'}>
          <FormattedMessage
            id={Tr.decreeConfigurationRequiredModalText}
            values={{
              br: <br/>
            }}/>
        </p>
        <ol>
          <li className={'mb-[5px]'}>1. {intl.formatMessage({id: Tr.categoryAccountsRequiredText})}</li>
          <li className={'mb-[5px]'}>2. {intl.formatMessage({id: Tr.settlementsRequiredText})}</li>
          <li className={'mb-[5px]'}>3. {intl.formatMessage({id: Tr.vatRegistriesRequiredText})}</li>
        </ol>
        <div className={'flex items-center mt-[20px]'}>
          <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD}/>
          <p className={'text-sm ml-[5px]'}>{intl.formatMessage({id: Tr.apTemporaryTestVersion})}</p>
        </div>
      </div>
    </Modal>
    );
});
