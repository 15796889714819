import {base64ToBlob} from '../../../../common';
import fileSaver from 'file-saver';
import {
  GetInvoiceFileContentActionGroupQuery,
  GetInvoiceFileContentActionGroupQueryVariables,
  GetInvoiceFileContentSingleQuery,
  GetInvoiceFileContentSingleQueryVariables,
  GetKSeFInvoiceXmlQuery,
  GetKSeFInvoiceXmlQueryVariables,
  InvoiceDto,
} from '@symfonia-ksef/graphql';
import {LazyQueryHookOptions, QueryResult} from '@apollo/client';
import {authState} from '@symfonia-ksef/state/rootRepository';


export function exportToSymfoniaDesktop(invoices: InvoiceDto[]) {
  const invoicesToSend = invoices.map(inv => ({
    Id: inv?.Id,
    InvoiceBound: inv.InvoiceBound,
    KsefStatus: inv.KSeFStatus,
    KsefNumber: inv.KsefNumber,
    KsefDate: inv.KsefDate,
    SchemaVersion: inv.SchemaVersion,
  }));
  if (window && window.parent) {
    window.parent.postMessage({ksefInvoice: invoicesToSend}, '*');
    (window as any)?.chrome?.webview?.postMessage({ksefInvoice: invoicesToSend});
  }
}

export async function getKSeFInvoiceXml(invoiceId: string, getKSeFInvoiceXmlQuery: (options?: Partial<LazyQueryHookOptions<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>>) => Promise<QueryResult<GetKSeFInvoiceXmlQuery, GetKSeFInvoiceXmlQueryVariables>>) {
  const response = await getKSeFInvoiceXmlQuery(
    {
      variables: {
        InvoiceId: invoiceId,
      },
    });
  const xmlContent = response.data?.GetKSeFInvoiceXML?.Content;

  if (!xmlContent) {
    throw new Error('No xml content');
  }

  const blob = new Blob([xmlContent], {type: 'text/xml'});
  const fileName = response.data?.GetKSeFInvoiceXML?.FileName || `${invoiceId}.xml`;
  fileSaver.saveAs(blob, fileName);
}

export async function downloadInvoicePdf(invoiceId: string, downloadInvoicePdfQuery: (options?: Partial<LazyQueryHookOptions<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>>) => Promise<QueryResult<GetInvoiceFileContentSingleQuery, GetInvoiceFileContentSingleQueryVariables>>) {
  const response = await downloadInvoicePdfQuery(
    {
      variables: {
        InvoiceId: invoiceId,
        Token: authState.accessToken ?? undefined,
      },
    });
  const mimeType = response.data?.GetInvoiceFileContentSingle?.MimeType;
  const pdfContent = response.data?.GetInvoiceFileContentSingle?.PdfContent;
  const pdfName = response.data?.GetInvoiceFileContentSingle?.PdfName || `Faktura-${new Date().getTime()}`;

  if (!pdfContent) {
    throw new Error('No pdf content');
  }

  const blob = base64ToBlob(pdfContent, mimeType || 'application/pdf');
  const name = pdfName || `PDF-${new Date().getTime()}`;
  fileSaver.saveAs(blob, name);
}

export async function downloadInvoicesZip(invoicesIds: string[], downloadInvoicesZipQuery: (options?: Partial<LazyQueryHookOptions<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>>) => Promise<QueryResult<GetInvoiceFileContentActionGroupQuery, GetInvoiceFileContentActionGroupQueryVariables>>) {
  const response = await downloadInvoicesZipQuery(
    {
      variables: {
        InvoiceIds: invoicesIds,
        Token: authState.accessToken ?? undefined,
      },
    });

  const mimeType = response.data?.GetInvoiceFileContentActionGroup?.MimeType;
  const zipContent = response.data?.GetInvoiceFileContentActionGroup?.ZipContent;
  const fileName = response.data?.GetInvoiceFileContentActionGroup?.ZipName || `Faktury-${new Date().getTime()}`;

  if (!zipContent) {
    throw new Error('No zip content');
  }

  const blob = base64ToBlob(zipContent, mimeType || 'application/zip');
  fileSaver.saveAs(blob, fileName);
}
