import {observer} from 'mobx-react-lite';
import {
  ChangeEvent,
  DragEvent,
  FunctionComponent,
  MouseEvent,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {AnyObject} from 'yup/es/types';
import {
  DropzoneUploaderStateService,
  DropzoneUploaderStateServiceI,
  DropzoneUploaderStateServicePublicI,
  InputProps as InputServiceProps,
} from './DropzoneUploaderStateService';
import {ValidatorConfig} from './DropzoneUploaderValidator';

export type DropHandler = <T extends HTMLElement>(e: DragEvent<T>) => void

export type OnChange = (e: ChangeEvent<HTMLInputElement>) => void

export type OnClick = (e?: MouseEvent) => void

export type InputProps = InputServiceProps & {
  onChange: OnChange
  triggerUploadWindow: OnClick,
  inputRef: MutableRefObject<HTMLInputElement | null>
}

export type DropzoneProps = {
  onDrop: DropHandler,
  onDragOver: DropHandler,
}

export type ContainerProps = {
  dropzoneProps: DropzoneProps
  inputProps: InputProps,
  inputRef: MutableRefObject<HTMLInputElement | null>,
  stateService: DropzoneUploaderStateServicePublicI,
  handleRemove: (file: File) => void
}


export const withFileUploader = <P extends AnyObject>(Component: FunctionComponent<PropsWithChildren<P & ContainerProps>>, config?: ValidatorConfig) => {
  const ObservedComponent = observer(Component);
  return observer((props: P) => {
    const [stateService] = useState<DropzoneUploaderStateServiceI>(() => new DropzoneUploaderStateService(config));
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onDragOver: DropHandler = useCallback<DropHandler>(e => {
      e.preventDefault();
    }, []);

    const handleRemove = useCallback((file: File) => {
      const dataTransfer = new DataTransfer();
      if (inputRef.current?.files) {
        for (const f of Array.from(inputRef.current?.files)) {
          if (f !== file) {
            dataTransfer.items.add(f);
          }
        }
        inputRef.current.files = dataTransfer.files;
      }

      stateService.removeFile(file);
    }, []);

    const onDrop = useCallback<DropHandler>((e) => {
      e.preventDefault();
      if (stateService.disabled) {
        return;
      }

      const addedFiles = stateService.handleUpload(e.dataTransfer?.files);
      if (!inputRef.current?.files) {
        return;
      }

      const dataTransfer = new DataTransfer();

      for (const file of addedFiles) {
        dataTransfer.items.add(file);
      }

      inputRef.current.files = dataTransfer.files;

    }, [stateService, stateService.disabled]);

    const dropzoneProps = useMemo<ContainerProps['dropzoneProps']>(() => ({
      onDrop,
      onDragOver,
    }), [onDrop, onDragOver]);

    const onChange = useCallback<OnChange>(() => {
      if (!stateService.disabled && !!inputRef.current?.files?.length) {
        stateService.handleUpload(inputRef.current?.files);
      }
    }, [stateService, stateService.disabled]);

    const triggerUploadWindow = useCallback(() => {
      inputRef.current?.click();
    }, []);

    const inputProps = useMemo<ContainerProps['inputProps']>(() => ({
      inputRef: inputRef,
      onChange,
      triggerUploadWindow,
      ...stateService.inputProps,
    }), [onChange, triggerUploadWindow, stateService.inputProps]);

    return <>
      <input ref={inputRef} hidden {...stateService.inputProps} onChange={onChange}/>
      <ObservedComponent
        {...props}
        handleRemove={handleRemove}
        stateService={stateService}
        dropzoneProps={dropzoneProps}
        inputProps={inputProps}
        inputRef={inputRef}
      />
    </>;
  });
};
