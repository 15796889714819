import * as React from 'react';
import {observer} from 'mobx-react-lite';
// @ts-ignore
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

export interface PostingDetailsBodyListNumericInputProps {
  value: number | undefined;
  onPositionValueChange: (val: number) => void;
  isPageEditable: boolean;
  IsRound: boolean;
  maximumValue?: number;
}

export const PostingDetailsBodyListNumericInput = observer(
  ({
    value,
    isPageEditable,
    IsRound,
    maximumValue = 10000000000000,
    onPositionValueChange,
  }: PostingDetailsBodyListNumericInputProps) => {
    return (
      <div className={'inline-flex text-base font-quicksand truncate'}>
        {IsRound && <span className={'font-bold'}>*</span>}
        <CurrencyTextField
          label=""
          className={
            '[&>div>input]:text-right border-b-primary-500 border-b' +
            '[&>div>input]:text-base ' +
            '[&>div>input]:font-quicksand ' +
            '[&>div>input]:border-b-primary-500 ' +
            (IsRound ? ' [&>div>input]:font-bold' : '')
          }
          variant="standard"
          currencySymbol={null}
          value={value}
          minimumValue={0}
          maximumValue={maximumValue}
          size={'small'}
          decimalCharacter={','}
          digitGroupSeparator={'.'}
          modifyValueOnWheel={false}
          outputFormat="number"
          decimalPlaces={2}
          onChange={(event: any, val: number) => onPositionValueChange(val)}
          InputProps={{
            disableUnderline: !isPageEditable,
            readOnly: !isPageEditable,
            inputProps: {step: '0.01'},
          }}
        />
      </div>
    );
  },
);
