import {intl} from '../../root/IntlProvider';

export const intlFunction = (id: string) => {
  return intl.formatMessage({id});
};

export const downloadFromLink = (link: string, fileName: string) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = `${link}`;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
