import {AnyObject} from 'yup/es/types';
import {EventParams, SubscriptionEventsServiceI} from '../../../services/SubscriptionEventsService';
import {SubscriptionStateEnum, WebsocketErrorType, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {useCallback, useEffect, useRef} from 'react';
import {NotificationDataType} from '../../../services/helpers/NotificationDataParsers';

import {debounce, throttle} from '@symfonia/symfonia-ksef-components';


export enum TriggerConfig {
  ALL = 'ALL',
  ERROR_ONLY = 'ERROR_ONLY',
  SUCCESS_ONLY = 'SUCCESS_ONLY',
}

export enum DelayType {
  THROTTLE = 'THROTTLE',
  DEBOUNCE = 'DEBOUNCE'
}

type Delay = { type: DelayType, time: number }

export type ObserverOptions<T extends string | number = WebsocketNotificationType, U extends AnyObject = EventParams> = {
  autorun?: boolean,
  skip?: boolean,
  delay?: Delay,
  types: T[],
  errors?: WebsocketErrorType[],
  statuses?: SubscriptionStateEnum[],
  trigger?: TriggerConfig,
  subscriptionService: SubscriptionEventsServiceI<EventParams, T>
}

export type Observer<T extends string | number = WebsocketNotificationType, U extends AnyObject = EventParams> = (e: U, data?: NotificationDataType) => void


const subscriberFactory = <T extends string | number = WebsocketNotificationType, U extends AnyObject = EventParams>(observer: Observer | null, config: ObserverOptions) => () => {
  if (!observer) {
    return;
  }
  const subscribers = config.types.map(type => config.subscriptionService.subscribe({
    type, onUpdate: (event: EventParams, data?: NotificationDataType) => {
      checkShouldUpdate(event, config) && observer(event, data);
    },
  }));

  return () => subscribers.forEach((sub) => sub.unsubscribe());
};

const wrapObserver = (observer: Observer, delay?: Delay): Observer => {
  if (!delay) {
    return observer;
  }
  switch (delay.type) {
    case DelayType.THROTTLE: {
      return throttle(observer, delay.time);
    }
    case DelayType.DEBOUNCE: {
      return debounce(observer, delay.time);
    }
  }
};

const checkShouldUpdate = (event: EventParams, config: ObserverOptions): boolean => {

  const shouldUpdate: boolean[] = [];
  switch (config.trigger) {
    case TriggerConfig.ERROR_ONLY : {
      shouldUpdate.push(!!event.errorType);
      break;
    }
    case TriggerConfig.SUCCESS_ONLY : {
      shouldUpdate.push(!event.errorType);
    }
  }

  shouldUpdate.push(!config.skip);
  shouldUpdate.push(config.statuses?.includes?.(event.state) ?? true);
  shouldUpdate.push(config.trigger === TriggerConfig.SUCCESS_ONLY || (event.errorType ? config.errors?.includes?.(event.errorType) ?? true : true));

  return shouldUpdate.every(Boolean);
};

export const useSubscription = <T extends string | number = WebsocketNotificationType, U extends AnyObject = EventParams>(observer: Observer | null, config: ObserverOptions, deps: any[] = []) => {

  const unsubscribeRef = useRef<() => void>();

  const subscribe = useCallback(() => {
    unsubscribeRef.current = subscriberFactory(observer && wrapObserver(observer, config.delay), config)();
  }, [...deps, config.skip]);

  useEffect(() => {
    config.autorun && subscribe();
    return () => unsubscribeRef.current?.();
  }, [subscribe, config.autorun]);

  const unsubscribe = useCallback(() => unsubscribeRef.current?.(), []);

  const subscribeAsAction = useCallback((observer: Observer) => {
    return subscriberFactory(wrapObserver(observer, config.delay), config)();
  }, [...deps, config.skip]);

  return {subscribe, subscribeAsAction, unsubscribe};
};

export const subscribeKSeFEvents = <T extends string | number = WebsocketNotificationType, U extends AnyObject = EventParams>(observer: Observer, config: ObserverOptions) => {
  return subscriberFactory(wrapObserver(observer, config.delay), config)();
};
