import {ExtendedTableServiceI} from '../../../../root/services/TableServices/ExtendedTableService';
import {
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  InvoiceBound,
  InvoiceDto,
  KSeFStatus,
  SymfoniaSystem,
} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DefaultDataSourceHandler} from '../../../../root/services/TableServices/DataSourceTableService';
import {ButtonTertiary, ButtonTertiarySize, IconSvg, Tag, TagColor, TagSize, Tooltip} from '@symfonia/brandbook';
import React, {ReactNode} from 'react';
import {InvoicesRepositoryI} from '../Invoices.repository';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {InvoiceModel} from '../../../models';
import {
  DateRenderer,
  InvoiceCurrencyStatusCell,
  InvoiceKsefStatusToText,
  InvoicePostingStatusToText,
} from '../../../../common';
import {FormattedMessage} from 'react-intl';
import {isFeatureEnabled} from '../../../../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../../../../common/enums/FeatureSwitchTypeEnum';
import {ModulesEnum} from '../../../../common/enums/ModulesEnum';
import {IModuleStatusStore} from '@symfonia-ksef/state/ModuleSettings/IModuleStatusStore';
import {IFilehubState} from '../../../pages/Documents/state/IFilehubState';
import {StateI} from '../../../../root/services/MobXServices/State';
import {RowActionsButton} from '../../../../common/components/RowActionsButton/RowActionsButton';
import {ContractorNameCell} from '../../../components/InvoicesTable/Components/ContractorNameCell';
import {InvoiceKsefStatusTagColor} from '../../../../common/helpers/InvoiceKsefStatusTagColor';

export type ContextualMenuState = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  item: InvoiceDto | null;
};

export type InvoicesRow = InvoiceDto;

export type InvoicesColumns = Columns<InvoicesRow, StateI<ContextualMenuState>>;

export type InvoicesTableI = ExtendedTableServiceI<
    InvoicesRow,
    'GetInvoices',
    GetInvoicesQuery,
    GetInvoicesQueryVariables,
    StateI<ContextualMenuState>
  >

export class InvoicesDataSourceHandler extends DefaultDataSourceHandler<
  'GetInvoices',
  GetInvoicesQuery,
  InvoicesRow,
  GetInvoicesQueryVariables
> {
  constructor(repository: InvoicesRepositoryI) {
    super(repository);
  }

  public override dataMapper(data: GetInvoicesQuery['GetInvoices'] | null) {
    return (data?.Items ?? []) as Array<InvoicesRow>;
  }

  public override getTotalCount(data: GetInvoicesQuery['GetInvoices']): number {
    return data.FilteredCount;
  }
}

export const invoicesTableKeysFactory = (row: InvoicesRow): string => row.Id;

const openSingleInvoiceActionsMenu = (
  menuState: StateI<ContextualMenuState>,
  invoice: InvoiceDto,
  anchorRef?: HTMLElement,
) => {
  menuState.override({
    item: invoice,
    anchorEl: anchorRef ?? null,
    isOpen: true,
  });
  // invoicesTableState.getState().setSelectedInvoice(invoice);
  // invoicesTableState.getState().setSelectedInvoiceMenuAnchorEl(anchorRef);
  // invoicesTableState.getState().setSelectedInvoiceMenuOpened(true);
};
const getDownloadedStatusTooltip = (displayText: string): ReactNode => {
  if (!displayText) {
    return '';
  }
  const itemsToDisplay = displayText.split(', ');
  return (
    <div>
      {itemsToDisplay.map((item, index) => (
        <span>
          {item}
          {index !== itemsToDisplay.length - 1 && <br/>}
        </span>
      ))}
    </div>
  );
};

const downloadedStatusToDisplay: {[key in SymfoniaSystem]: string} = {
  [SymfoniaSystem.Fk]: 'FK',
  [SymfoniaSystem.Fksod]: 'FK, SOD',
  [SymfoniaSystem.FksoDeBiuro]: 'FK, SOD, eBiuro',
  [SymfoniaSystem.FKeBiuro]: 'FK, eBiuro',
  [SymfoniaSystem.Han]: 'HAN',
  [SymfoniaSystem.Hanfk]: 'HAN, FK',
  [SymfoniaSystem.Hanfksod]: 'HAN, FK, SOD',
  [SymfoniaSystem.HanfksoDeBiuro]: 'HAN, FK, SOD, eBiuro',
  [SymfoniaSystem.HanfKeBiuro]: 'HAN, FK, eBiuro',
  [SymfoniaSystem.Hansod]: 'HAN, SOD',
  [SymfoniaSystem.HansoDeBiuro]: 'HAN, SOD, eBiuro',
  [SymfoniaSystem.HaNeBiuro]: 'HAN, eBiuro',
  [SymfoniaSystem.Sod]: 'SOD',
  [SymfoniaSystem.SoDeBiuro]: 'SOD, eBiuro',
  [SymfoniaSystem.Undefined]: '',
  [SymfoniaSystem.EBiuro]: 'eBiuro',
};

export const invoicesInitialColumnsFactory = (config: {
  filehubState: IFilehubState;
  invoiceBound: InvoiceBound;
  previewModeEnabled: boolean;
  moduleStatusStore: IModuleStatusStore;
  setContractorModalProps: React.Dispatch<
    React.SetStateAction<
      | {
          modalIsActive: boolean;
          contractorNip: string;
          contractorName: string;
          invoiceId?: string;
        }
      | undefined
    >
  >;
}): InvoicesColumns => ({
  KSeFStatus: {
    header: intl.formatMessage({id: Tr.invoiceKsefStatus}),
    sortable: true,
    order: 1,
    hidden: config.previewModeEnabled || config.invoiceBound === InvoiceBound.External,
    isObserver: true,
    width: 'w-[150px]',
    tooltipContent: row => InvoiceKsefStatusToText(row.KSeFStatus).text,
    cell: row =>
      row.KSeFStatus === KSeFStatus.Ocr ? (
        <></>
      ) : (
        <Tag
          className="whitespace-nowrap"
          text={InvoiceKsefStatusToText(row.KSeFStatus).text}
          color={InvoiceKsefStatusTagColor(row.KSeFStatus)}
          size={TagSize.SM}
        />
      ),
  },
  Downloaded: {
    header: intl.formatMessage({id: Tr.invoiceIsDownloaded}),
    width: 'w-[120px]',
    sortable: true,
    order: 2,
    isObserver: true,
    cell: row => getDownloadedStatusTooltip(downloadedStatusToDisplay[row.Downloaded as SymfoniaSystem]),
  },
  Number: {
    header: intl.formatMessage({id: Tr.invoiceOwnNumber}),
    sortable: true,
    order: 3,
    width: 'w-[150px]',
    isObserver: true,
    cell: row => row.Number,
    cellAsTooltip: true,
    ellipsisEnabled: true,
  },
  DocumentType: {
    header: intl.formatMessage({id: Tr.invoiceDocumentType}),
    sortable: true,
    order: 4,
    hidden: config.previewModeEnabled,
    isObserver: true,
    width: 'w-[160px]',
    cell: row => row.DocumentType,
  },
  IssuerName: {
    header: intl.formatMessage({id: Tr.invoiceSender}),
    sortable: true,
    order: 5,
    width: 'w-[160px]',
    cell: row =>
      config.invoiceBound === InvoiceBound.External ? (
        <ContractorNameCell
          contractorName={row.IssuerName ?? ''}
          contractorNip={row.IssuerNip ?? ''}
          whiteListStatus={row.WhiteListStatus ?? null}
          invoiceId={row.Id}
          setContractorProps={config.setContractorModalProps}
        />
      ) : (
        row.IssuerName
      ),
    isObserver: true,
    cellAsTooltip: config.invoiceBound === InvoiceBound.External ? false : true,
    ellipsisEnabled: true,
  },
  IssuerNip: {
    header: intl.formatMessage({id: Tr.invoicesenderNIP}),
    sortable: true,
    order: 6,
    width: 'w-[140px]',
    cell: row => row.IssuerNip,
    cellAsTooltip: true,
    isObserver: true,
  },

  RecipientName: {
    header: intl.formatMessage({id: Tr.invoiceReceiver}),
    sortable: true,
    order: 7,
    width: 'w-[160px]',
    cell: row => row.RecipientName,
    isObserver: true,
    cellAsTooltip: true,
    ellipsisEnabled: true,
  },
  RecipientNip: {
    header: intl.formatMessage({id: Tr.invoiceReceiverNIP}),
    sortable: true,
    order: 8,
    width: 'w-[140px]',
    cell: row => row.RecipientNip,
    isObserver: true,
    cellAsTooltip: true,
  },
  AttachmentsCount: {
    header: intl.formatMessage({id: Tr.attachments}),
    hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.filehub),
    order: 9,
    width: 'w-[200px]',
    cell: row => (
      <ButtonTertiary
        onClick={() => {
          config.filehubState.setIsFileHubOpen(true);
          config.filehubState.setInvoiceNumber(row.Number);
          config.filehubState.setInvoiceId(row.Id);
        }}
        size={ButtonTertiarySize.SM}
        lIcon={IconSvg.ATTACH_FILE}
        text={`${intl.formatMessage({id: Tr.attached})}: ${row.AttachmentsCount}`}
      />
    ),
  },
  NetValue: {
    header: intl.formatMessage({id: Tr.invoiceAmountNet}),
    sortable: true,
    order: 10,
    hidden: config.previewModeEnabled,
    isObserver: true,
    width: 'w-[140px]',
    alignRight: true,
    cell: row => <InvoiceCurrencyStatusCell value={row.NetValue}/>,
  },
  VatValue: {
    header: intl.formatMessage({id: Tr.invoiceAmountVat}),
    sortable: true,
    order: 11,
    hidden: config.previewModeEnabled,
    isObserver: true,
    width: 'w-[140px]',
    alignRight: true,
    cell: row => <InvoiceCurrencyStatusCell value={row.VatValue}/>,
  },
  GrossValue: {
    header: intl.formatMessage({id: Tr.invoiceAmountGross}),
    sortable: true,
    order: 12,
    hidden: config.previewModeEnabled,
    isObserver: true,
    width: 'w-[140px]',
    alignRight: true,
    cell: row => <InvoiceCurrencyStatusCell value={row.GrossValue}/>,
  },
  DateOfIssue: {
    header: intl.formatMessage({id: Tr.invoiceIssueDate}),
    sortable: true,
    order: 13,
    hidden: config.previewModeEnabled || config.invoiceBound === InvoiceBound.External,
    isObserver: true,
    width: 'w-[180px]',
    cell: row => DateRenderer({value: row.DateOfIssue}) ?? <></>,
  },
  KsefDate: {
    header: intl.formatMessage({id: Tr.invoiceKsefIssueDate}),
    sortable: true,
    order: 14,
    width: 'w-[250px]',
    hidden: config.previewModeEnabled,
    isObserver: true,
    cell: row => DateRenderer({value: row.KsefDate}) ?? <></>,
  },
  KsefNumber: {
    header: intl.formatMessage({id: Tr.invoiceKsefDocumentNumber}),
    sortable: true,
    order: 15,
    width: 'w-[310px]',
    hidden: config.previewModeEnabled,
    cell: row => row.KsefNumber,
    isObserver: true,
    cellAsTooltip: true,
  },
  RegistrationNumber: {
    header: intl.formatMessage({id: Tr.registrationNumber}),
    sortable: true,
    order: 16,
    isObserver: true,
    hidden:
      config.previewModeEnabled ||
      !(
        isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) &&
        config.moduleStatusStore.getModule(ModulesEnum.autoPosting)?.isModulePurchased
      ),
    width: 'w-[200px]',
    tooltipContent: row => !!row.RegistrationNumber,
    cell: row => row.RegistrationNumber,
  },

  InvoicePostingStatus: {
    header: intl.formatMessage({id: Tr.postingStatus}),
    sortable: true,
    order: 17,
    isObserver: true,
    hidden:
      config.previewModeEnabled ||
      !(
        isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) &&
        config.moduleStatusStore.getModule(ModulesEnum.autoPosting)?.isModulePurchased
      ),
    width: 'w-[180px]',
    cell: row => (
      <Tag
        text={InvoicePostingStatusToText(row.InvoicePostingStatus).text}
        color={TagColor.GREY_LIGHT}
        size={TagSize.SM}
      />
    ),
  },
  Id: {
    header: intl.formatMessage({id: Tr.actions}),
    order: 18,
    isObserver: true,
    asAction: true,
    width: 'w-[80px]',
    cell: (row, _, __, contextMenuState) => (
      <span className="invoice-action-wrapper w-full justify-end">
        {row.KSeFStatus === KSeFStatus.Ocr && (
          <Tooltip text={<FormattedMessage id={Tr.ocrInvoiceTooltip}/>}>
            <div>
              <Tag text={'OCR'} size={TagSize.SM} className="bg-[#BBB3CE]"/>
            </div>
          </Tooltip>
        )}
        <RowActionsButton
          onClick={e => {
            openSingleInvoiceActionsMenu(contextMenuState, row, e.currentTarget);
          }}
          testId="invoiceTableRowActionsButton"
        />
      </span>
    ),
  },
});
