import {action, computed, makeObservable, observable} from 'mobx';
import {
  GetWebsocketNotificationDocument,
  GetWebsocketNotificationQuery,
  GetWebsocketNotificationQueryVariables,
  WebSocketNotificationFragment,
  WebsocketNotificationStateType,
} from '@symfonia-ksef/graphql';
import {Repository, RepositoryI} from '../../modules/root/services/MobXServices/Repository/Repository';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface BaseWsActiveEventsRepositoryI extends RepositoryI<'GetWebsocketNotificationsList', GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables>, PendingEventsI {
  updateTimestamp?: number;
}

export interface PendingEventsI {
  get pendingEvents(): WebSocketNotificationFragment[];
}

export interface UpdateActiveEventsI {
  updateEvent(event: WebSocketNotificationFragment): void;
}

interface WsActiveEventsRepositoryI extends BaseWsActiveEventsRepositoryI,UpdateActiveEventsI {
  addEvent(event: WebSocketNotificationFragment): void;

  removeEvent(eventId: string): void;
}

export class WsActiveEventsRepository extends Repository<'GetWebsocketNotificationsList', GetWebsocketNotificationQuery, GetWebsocketNotificationQueryVariables> implements WsActiveEventsRepositoryI {

  @observable
  public updateTimestamp?: number;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, protected config?: {
    autoInitializeDisabled: boolean
  }) {
    super('GetWebsocketNotificationsList', GetWebsocketNotificationDocument, envObserver, earchiveState);
    makeObservable(this);
  }

  @computed
  public get pendingEvents(): WebSocketNotificationFragment[] {
    return (this.data?.WebsocketNotification?.filter(Boolean) ?? []) as WebSocketNotificationFragment[];
  }

  @action.bound
  public updateEvent(event: WebSocketNotificationFragment): void {
    if (this.find(event.NotificationId)) {
      this.set({WebsocketNotification: this.pendingEvents.map(e => e.NotificationId === event.NotificationId ? event : e)});
    }
  }

  public find(id: string): WebSocketNotificationFragment | null {
    return this.pendingEvents.find(e => e.NotificationId === id) ?? null;
  }

  @action.bound
  public addEvent(event: WebSocketNotificationFragment): void {

    if (this.data?.WebsocketNotification?.some?.(notification => notification?.NotificationId === event.NotificationId)) {
      return;
    }

    const previousEvents = this.data?.WebsocketNotification ?? [];

    const newData: GetWebsocketNotificationQuery['GetWebsocketNotificationsList'] = {
      WebsocketNotification: [event, ...previousEvents],
    };

    this.set(newData);
  }

  @action.bound
  public removeEvent(eventId: string): void {
    const newData = this.data?.WebsocketNotification?.filter((event) => event?.NotificationId !== eventId);
    if (newData) {
      this.set({...this.data, WebsocketNotification: newData});
    }
  }

  protected override handleEnvIdChange(envId: string | null) {
    super.handleEnvIdChange(envId);
    if (!envId || this.config?.autoInitializeDisabled) {
      return;
    }
    this.configure({notificationStateType: WebsocketNotificationStateType.Active}).fetch();
  }

  protected override onDataChange(data: GetWebsocketNotificationQuery['GetWebsocketNotificationsList'] | null): void | Promise<void> {
    this.setUpdateTimestamp();
  }

  @action
  private setUpdateTimestamp(): void {
    this.updateTimestamp = Date.now();
  }
}



