import {EventJobRunner} from '../EventJobRunner';
import {
  UploadInvoicesDocument,
  UploadInvoicesMutation,
  UploadInvoicesMutationVariables,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../helpers/fetchMatchedAction';
import {Tr} from '../../locales/translationKeys';
import {AlertConfig} from '../helpers/AlertService';
import {UploadKSeFInvoicesServiceI} from '@symfonia-ksef/state/KSeFSubscriptionServices/UploadKSeFInvoicesService';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export interface UploadKSeFInvoicesJobRunnerI {
  setService(service: UploadKSeFInvoicesServiceI): void;
}

export class UploadKSeFInvoicesJobRunner extends EventJobRunner<UploadInvoicesMutationVariables, keyof UploadInvoicesMutation> implements UploadKSeFInvoicesJobRunnerI {
  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState, private service?: UploadKSeFInvoicesServiceI) {
    super('UploadInvoices', WebsocketNotificationType.UploadInvoices, UploadInvoicesDocument, envObserver, earchiveState, InitializerType.Mutation);
  }

  public override checkIsReady(): boolean {
    const {invoices} = this.variables;
    return super.checkIsReady() && (Array.isArray(invoices) ? !!invoices.length : !!invoices);
  }

  public setService(service: UploadKSeFInvoicesServiceI): void {
    this.service = service;
    this.configureAlerts(this.matchAlerts());
  }

  private matchAlerts(): { success: () => AlertConfig, error: () => AlertConfig } {
    return {
      success: () => {
        return {id: this.service?.multiple ? Tr.uploadInvoicesInProgress : Tr.uploadInvoiceInProgress};
      },
      error: () => {
        return {id: this.service?.multiple ? Tr.uploadInvoicesError : Tr.uploadInvoiceError};
      },
    };
  }
}
