import {Icon, IconSize, IconSvg} from '@symfonia/brandbook';
import clsx from 'clsx';
import {Subject} from 'libs/brandbook/src/external/helpers/Subject';
import {FC, KeyboardEventHandler, PropsWithChildren, useEffect, useState} from 'react';
import {NavLink, Link} from 'react-router-dom';

export enum AccordionSize {
  SM = 'SM',
  MD = 'MD',
}

type AccordionEvent = {
  group?: symbol | string | number;
  id?: symbol | string | number;
  expanded?: boolean;
  expandable?: boolean;
  onClick?: () => void;
  onExpandChange?: () => void;
};

export type AccordionProps = AccordionEvent & {
  title?: string;
  href?: string;
  size?: AccordionSize;
  icon?: IconSvg;
  disabled?: boolean;
  addMargin?: boolean;
  isNavigation?: boolean;
  testId?: string;
};

const accordionSubject = new Subject<AccordionProps>();

export const Accordion: FC<PropsWithChildren<AccordionProps>> = ({
                                                                   children = undefined,
                                                                   group = '',
                                                                   id = Symbol('Accordion ID'),
                                                                   expanded = false,
                                                                   expandable = true,
                                                                   title = '',
                                                                   href = '',
                                                                   size = AccordionSize.MD,
                                                                   icon = undefined,
                                                                   disabled = false,
                                                                   addMargin = false,
                                                                   isNavigation = false,
                                                                   onClick = () => undefined,
                                                                   onExpandChange = () => undefined,
                                                                   testId,
                                                                 }) => {
  const [accordionId] = useState(id);
  const [accordionGroup] = useState(group);
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const [isMouse, setIsMouse] = useState<boolean>(false);

  useEffect(() => {
    return accordionSubject.subscribe(event => {
      if (event.group === accordionGroup) {
        setIsExpanded(event.id === accordionId ? event.expanded || false : false);
      }
    });
  }, []);

  const toggleExpanded = () =>
    accordionSubject.next({id: accordionId, group: accordionGroup, expanded: !isExpanded});

  const mouseDownHandler = () => setIsMouse(true);

  const expandHandler = () => {
    if (!disabled) {
      toggleExpanded();
      onClick();
    }
  };

  const blurHandler = () => setIsMouse(false);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event): void => {
    if (event.code === 'Enter') {
      toggleExpanded();
    }
  };

  const renderLink = () => {
    if (href && href.includes('https://')) {
      return (
        <Link to={href} className="w-full">
          <span className={clsx('grow', {'ml-[8px]': !isNavigation})}>{title}</span>
        </Link>
      );
    }
    if (href) {
      return (
        <NavLink to={href} onClick={onClick} className="w-full">
          {({isActive}) => (
            <span className={clsx('grow', {'ml-[8px]': !isNavigation, 'font-bold text-primary-500': isActive})}>
              {title}
            </span>
          )}
        </NavLink>
      );
    }
    return <span className={clsx('grow', {'ml-[8px]': !isNavigation})}>{title}</span>;
  };

  return (
    <div
      onMouseDown={mouseDownHandler}
      className="group outline-none"
      tabIndex={0}
      onBlur={blurHandler}
      onKeyDown={handleKeyDown}
    >
      <div
        onClick={expandHandler}
        className={clsx({
          'mb-[8px]': addMargin,
          'flex font-quicksand items-center': true,
          'border-b': !isNavigation,
          'border-none': isNavigation,
          'h-[48px] text-base font-medium': size === AccordionSize.SM && !isNavigation,
          'h-[56px] text-xl': size === AccordionSize.MD && !isNavigation,
          'h-[56px] text-base font-medium': isNavigation,
          'cursor-pointer group-hover:text-primary-500 group-hover:border-b-primary-500 text-black': !disabled,
          'border-b-grey-500': !disabled && !isExpanded && !isNavigation,
          'border-b-primary-500': !disabled && isExpanded && !isNavigation,
          'cursor-default border-grey-300 text-grey-300': disabled && !isNavigation,
          'group-focus:outline group-focus:outline-2 group-focus:outline-yellow-500 group-focus:rounded-lg group-focus:border-none':
            !disabled && !isMouse,
        })}
        data-testid={testId}
      >
        {icon && (
          <Icon
            className={clsx({
              'mx-[8px]': true,
              'group-hover:filter-primary-500 filter-grey-900': !disabled,
              'filter-grey-300': disabled,
            })}
            svg={icon}
            size={IconSize.LG}
          />
        )}
        {renderLink()}
        {expandable && (
          <span className="ml-[8px] justify-end inline-flex">
            <Icon
              className={clsx({
                'mx-[8px]': true,
                'group-hover:filter-primary-500 filter-grey-900': !disabled,
                'filter-grey-300': disabled,
              })}
              svg={isExpanded ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
              size={IconSize.LG}
            />
          </span>
        )}
      </div>
      {isExpanded && (
        <div
          className={clsx({
            'mt-[24px] mb-[28px] outline-none font-roboto': !isNavigation,
            'text-sm font-quicksand font-normal': isNavigation,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};
