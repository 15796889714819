import {observer} from 'mobx-react-lite';
import {FormattedMessage, useIntl} from 'react-intl';
import {DialogModal} from '@symfonia/symfonia-ksef-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FC} from 'react';
import {
  InvoicesDownloadCheckerI,
} from '@symfonia-ksef/state/KSeFSubscriptionServices/DownloadInvoicesProgressTracker/DownloadInvoicesProgressTracker';

export const InvoicesDownloadIsRunningModal: FC<{
  downloadChecker: InvoicesDownloadCheckerI
}> = observer(({downloadChecker}) => {
  const intl = useIntl();

  return <DialogModal
    open={downloadChecker.isRunning}
    onCancel={downloadChecker.hideAlert}
    title={intl.formatMessage({id: Tr.DownloadInvoices})}
    okButton={{text: intl.formatMessage({id: Tr.ok}), onClick: downloadChecker.hideAlert}}
  >
    <FormattedMessage id={Tr.invoicesDownloadRunning}/>
  </DialogModal>;
});
