import {SzafirTask} from './szafir-task.model';

export class SzafirSignedTask extends SzafirTask {
  public readonly signatureToCreate: string;

  constructor(id: string, dataToSign: string, signatureToCreate: string) {
    super(dataToSign, id);
    this.signatureToCreate = signatureToCreate;
  }
}
