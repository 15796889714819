import {ContractorViewModel, DecreeViewModel, PostingStatusEnum} from '@symfonia-ksef/graphql';
import {action, autorun, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {IPostingTableState} from './IPostingTableState';
import {MultiSelectModel} from '@symfonia/symfonia-ui-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {PostingTableFilterState} from './PostingTableFilterState';
import {IPostingTableStore} from './IPostingTableStore';
import {PostingTableStore} from './PostingTableStore';
import {PostingTableRefreshService} from './PostingTableRefreshService';
import {ToastVariant} from '@symfonia/brandbook';
import {PostingRow} from './PostingTableRepository';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {IPostingState} from '../../../../state/IPostingState';
import {PostedDecreeResponseFilterKeys} from '../../../../../../modules/earchive/pages/Account/Posting/pages/PostingConfiguration/CategoryAccounts/CategoryAccountsTable/models/CategoryTypesFilterOptions';
import {intl} from '../../../../../../modules/root/IntlProvider';
import {InvoiceDecreeKeysModel} from '../../../../state/PostingState';
import {convertToMultiSelectType} from '../../../../../common/helpers/baseFilterHelpers';
import {AcceptDecreesMutationFunction} from '../../Queries/AcceptDecreesMutationFunction';

export class PostingTableState implements IPostingTableState {
  @observable
  public readonly postingInvoices: IObservableArray<DecreeViewModel> = observable([]);

  @observable
  public showLoader: boolean = false;

  @observable
  refreshCount: number = 0;

  @observable
  showExportToFKPartlyNotAvailableModal: boolean = false;

  @observable
  showAcceptPartlyNotAvailableModal: boolean = false;

  @observable
  selectedMenuAnchorEl: HTMLElement | undefined = undefined;

  @observable
  selectedMenuOpened = false;

  public postingState: IPostingState;
  postingStore: IPostingTableStore;

  filterState: PostingTableFilterState;

  @observable
  public acceptBeforeSendToFKIsActive: boolean = false;

  constructor(postingState: IPostingState, public postingTableRefreshService: PostingTableRefreshService) {
    makeObservable(this);
    this.postingState = postingState;
    this.filterState = new PostingTableFilterState(this);
    this.postingStore = new PostingTableStore(this);

    autorun(() => {
      if (this.postingStore.contractors.length) {
        this.setContractors(this.postingStore.contractors);
      }
    });
    autorun(() => {
      if (postingTableRefreshService.refreshCount) {
        this.triggerRefresh();
      }
    });
  }

  @computed
  get typeFilterObject(): MultiSelectModel[] {
    return convertToMultiSelectType(
      Object.entries(PostedDecreeResponseFilterKeys),
      ([x, y]) => ({
        value: intl.formatMessage({id: Tr[x as keyof typeof Tr]}),
        key: x.toUpperCase(),
      }),
      true,
    );
  }

  @computed
  public get selectedAndFilteredDecrees(): InvoiceDecreeKeysModel[] {
    return this.postingState.selectedDecrees.filter(x =>
      (x.PostingStatus === PostingStatusEnum.Incomplete ||
        x.PostingStatus === PostingStatusEnum.Posted) &&
      !x.isAccepted);
  }

  @computed
  public get validatedRejecteddDecrees(): InvoiceDecreeKeysModel[] {
    return this.postingState.selectedDecrees.filter(x =>
      (x.PostingStatus === PostingStatusEnum.DecreeSent ||
        x.PostingStatus === PostingStatusEnum.DecreeDownloaded ||
        x.PostingStatus === PostingStatusEnum.Failed ||
        x.PostingStatus === PostingStatusEnum.NoAction ||
        x.isAccepted
      ));
  }

  @action
  public triggerRefresh(count: number = 1) {
    this.refreshCount += this.postingTableRefreshService.refreshCount;
  }

  @action
  public setPostingInvoices(postingInvoices: DecreeViewModel[]): void {
    this.postingInvoices.replace(postingInvoices);
  }

  @action
  public setShowLoader(showLoader: boolean): void {
    this.showLoader = showLoader;
  }

  @action
  public setShowExportToFKPartlyNotAvailableModal(showModal: boolean): void {
    this.showExportToFKPartlyNotAvailableModal = showModal;
  }

  @action
  public setShowAcceptPartlyNotAvailableModal(showModal: boolean): void {
    this.showAcceptPartlyNotAvailableModal = showModal;
  }

  @action
  public setAcceptBeforeSendToFKIsActive(acceptBeforeSendToFKIsActive: boolean): void {
    this.acceptBeforeSendToFKIsActive = acceptBeforeSendToFKIsActive;
  }

  @action
  public setContractors(contractors: ContractorViewModel[]): void {
    this.filterState.contractors.replace(convertToMultiSelectType(
      [{Id: '', Name: intl.formatMessage({id: 'missingCategoryLabel'}), Identifier: ''}].concat(contractors),
      (x) => ({
        value: x.Name ?? '-',
        key: x.Id,
      }),
      true,
    ));
  }

  @action
  setSelectedMenuAnchorEl(selectedMenuAnchorEl: HTMLElement | undefined) {
    this.selectedMenuAnchorEl = selectedMenuAnchorEl;
  }

  @action
  setSelectedMenuOpened(selectedMenuOpened: boolean) {
    this.selectedMenuOpened = selectedMenuOpened;
  }

  @action
  async validateAndAcceptDecrees(selectedDecrees: PostingRow[], userId: string | undefined, canAcceptOwnDecrees: boolean) {
    const decreesUnableToAccept = selectedDecrees.some(x =>
      x.PostingStatus === PostingStatusEnum.DecreeSent ||
      x.PostingStatus === PostingStatusEnum.DecreeDownloaded ||
      x.PostingStatus === PostingStatusEnum.Failed ||
      x.PostingStatus === PostingStatusEnum.NoAction ||
      x.IsAccepted ||
      (!canAcceptOwnDecrees && x.CreatedBy === userId),
    );

    if (!decreesUnableToAccept) {
      await this.acceptDecrees(userId, canAcceptOwnDecrees);
      await this.triggerRefresh();
    } else this.setShowAcceptPartlyNotAvailableModal(true);
  }

  @action
  acceptDecreesValidation = () => {
    return this.validatedRejecteddDecrees.map(x => {
      return {
        value: intl.formatMessage({
          id: x.PostingStatus === PostingStatusEnum.DecreeSent || x.PostingStatus === PostingStatusEnum.DecreeDownloaded
            ? Tr[x.PostingStatus]
            : x.PostingStatus === PostingStatusEnum.Failed || x.PostingStatus === PostingStatusEnum.NoAction
              ? Tr.noDecree
              : Tr.decreeAccepted,
        }),
        key: x.Number,
      };
    });
  };

  @action
  exportToFKPartlyUnavailableList = () => {
    const validationArray = [PostingStatusEnum.DecreeDownloaded, PostingStatusEnum.DecreeSent];
    const selectedAndFilteredDecrees = this.postingState.selectedDecrees.filter(x =>
      validationArray.includes(x.PostingStatus) || (this.acceptBeforeSendToFKIsActive && !x.isAccepted));

    function getErrorMessage(item: InvoiceDecreeKeysModel): string {
      if (item.PostingStatus === PostingStatusEnum.DecreeSent || item.PostingStatus === PostingStatusEnum.DecreeDownloaded)
        return intl.formatMessage({id: Tr[item.PostingStatus]});

      if (!item.isAccepted)
        return intl.formatMessage({id: Tr.noAcceptedDecree});

      throw new Error('not implemented error message');
    }

    return convertToMultiSelectType(selectedAndFilteredDecrees, (x) => ({
      value: getErrorMessage(x),
      key: x.Number ?? '',
    }), true);
  };


  @action
  public async acceptDecrees(userId: string | undefined, canAcceptOwnDecrees: boolean) {
    const selectedAndFilteredDecreesToAccept = this.postingState.selectedDecrees.filter(x =>
      (x.PostingStatus === PostingStatusEnum.Incomplete ||
        x.PostingStatus === PostingStatusEnum.Posted) &&
      !x.isAccepted &&
      (!canAcceptOwnDecrees && x.CreatedBy !== userId),
    );

    if (selectedAndFilteredDecreesToAccept.length === 0) {
      return;
    }

    const successMessage = selectedAndFilteredDecreesToAccept.length > 1 ? Tr.acceptedSuccess : Tr.acceptedOneSuccess;
    const successMessageValue = selectedAndFilteredDecreesToAccept.length > 1 ? selectedAndFilteredDecreesToAccept.length : selectedAndFilteredDecreesToAccept[0].Number;

    await AcceptDecreesMutationFunction(
      this.postingState.earchiveState.companyId,
      selectedAndFilteredDecreesToAccept.map(x => x.Id),
    ).then(() => {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: successMessage}, {successMessageValue}), ToastVariant.SUCCESS, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    }).catch(() => {
      earchiveState.alertsState.addAlert(intl.formatMessage({id: Tr.acceptedFailure}, {decreeNumber: this.selectedAndFilteredDecrees[0].Number}), ToastVariant.ERROR, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
    });
  }
}
