import {AnyObject} from 'yup/es/types';
import {
  BaseTableDataRepositoryServiceI,
  BaseVariables,
  DataSourceTableServiceBuildingI,
} from './DataSourceTableService';
import {PaginationTableServiceI, SetterPaginationArg} from './PaginationTableService';
import {Selections, SelectionTableServiceI} from './SelectionTableService';
import {
  BaseExtendedTableServiceI,
  BaseTableServiceI,
  Col,
  EventClick,
  Matrix,
  Row as TableRow,
} from './BaseTableService';
import {TableHeaderProps, TableSort} from '@symfonia/brandbook';
import {action, computed, makeObservable, override} from 'mobx';
import {TableServiceDecorator} from './TableServiceDecorator';
import {isEmpty} from 'ramda';
import {ClickableTableServiceI} from './ClickableTableService';
import {Filter, FilterFactory, FiltersTableServiceI} from './FiltersTableService';
import {isEqual} from 'lodash';

export interface TableServices<Row extends AnyObject, Key extends string = string, Data extends AnyObject = AnyObject, Variables extends BaseVariables = BaseVariables, Context extends AnyObject = AnyObject> {
  dataSource?: DataSourceTableServiceBuildingI<Key, Data, Variables, Row>;
  pagination?: PaginationTableServiceI<Row, Context>;
  selection?: SelectionTableServiceI<Row, Context>;
  repository?: BaseTableDataRepositoryServiceI<Key, Data, Variables>;
  clickableRow?: ClickableTableServiceI<Row, Context>;
  filter?: FiltersTableServiceI<Row, Context>;
  context?: Context;
}

export type SortingSetterParams = { sortBy: TableSort, resetPagination?: boolean }

export type SetFilterParams<Row extends AnyObject, Actions extends AnyObject = AnyObject, Context extends AnyObject = AnyObject> = { filter?: Filter<Row, Context> | FilterFactory<Row, Context>, isActive?: boolean }

export type ClassNamesService<Row extends AnyObject = AnyObject> = Partial<Pick<ExtendedTableServiceI<Row>, 'rowClassName' | 'focusedRowClassName' | 'selectedRowClassName'>>

//Służy do zarządzania tebelą oraz jej rozrzerzeniami.
// Przechowuje stan tabeli i zarządza nim
// Integruje tabele ze źródłem danych, paginacją, wyborem wierszy, aktywowaniem wiersza, sortowaniem, filtrowaniem i kontekstem innego stanu
// zawiera logiczną reprezentację skonfigurowanej macierzy tabeli do wyrenderowania (synchronizuje ją ze wszystkimi zmianami w danych i konfiguracji)
export interface ExtendedTableServiceI<Row extends AnyObject = AnyObject, Key extends string = string, Data extends AnyObject = AnyObject, Variables extends BaseVariables = BaseVariables, Context extends AnyObject = AnyObject> extends BaseTableServiceI<Row, Context> {

  //Zwraca rekord pokazujący które funkcjonalności są aktywne
  get activatedServices(): Record<keyof TableServices<Row, Key, Data, Variables, Context>, boolean>;

  //offset paginacji tabeli
  get offset(): number;

  //aktywna strona paginacji tabeli
  get currentPage(): number;

  //wielkość strony paginacji tabeli (ilość elementów na stronę)
  get size(): number;

  //ilość stron paginacji tabeli
  get pagesCapacity(): number | undefined;

  //zaznaczone wiersze tabeli
  get selected(): TableRow<Row>[];

  //stan loadingu
  get loading(): boolean;

  //konfiguruje zapytanie o dane do tabeli poprzez przekazanie części zmiennych zapytania (zapisuje do stanu repository to co przekazane)
  configureRequest(variables: Partial<Variables> | null): this;

  //pobiera dane do tabeli, zapisuje je w stanie, odpala metody cyklu życia repository, powoduje rerender tabeli (jesli success)
  fetch(): Promise<void>;

  //zaznacza wybrane wiersze
  select(rows: TableRow<Row> | TableRow<Row>[]): void;

  //odznacza wybrane wiersze
  unselect(rows: TableRow<Row> | TableRow<Row>[]): void;

  //odznacza wszystkie wiersze
  clearSelection(): void;

  //ustawia funkcję fitlrującą dane na frontendzie
  setFilter(filter: SetFilterParams<Row, Context>): this;

  //konfiguruje kolumne o podanym kluczu (działa jak State.set). Zmieni tylko to co podane w obiekcie
  configureColumn(key: keyof Row, column: Partial<Col<Row, Context>>): this;

  //ustawia stan paginacji tabeli
  setPagination(arg: SetterPaginationArg): this;

  //ustawia stan sortowania tabeli, powoduje rerender i reset paginacji
  setSorting(params: SortingSetterParams): this;

  //zwraca aktywny (kliknięty) wiersz tabeli
  get focusedRow(): TableRow<Row> | undefined;

  //zwraca index aktywnego wiersza tabeli
  get focusedRowIndex(): number;

  //ustawia aktywny wiersz tabeli
  setFocusedRow(row: TableRow<Row> | undefined): void;

  //wywołuje akcję kliknięcia na wiersz tabeli (aktywowania)
  handleClick(focusedRow: TableRow<Row>, e?: EventClick): void;

  //zwraca klase css dla podanego wiersza
  rowClassName(row: TableRow<Row>): string | undefined;

  //zwraca klasę aktywnego wiersza dla podanego wiersza
  focusedRowClassName(row: TableRow<Row>): string | undefined;

  //zwraca klasę wybranych wierszy
  get selectedRowClassName(): string | undefined;

  //sprawdza czy wiersz jest wybrany
  checkIsSelected(row: TableRow<Row>): boolean;

  //sprawdza czy wiersz jest aktywny
  checkIsFocused(row: TableRow<Row>): boolean;

  //zwraca rekord zawierający wybrane wiersze jako Rekord<row.key,TableRow>
  get selections(): Selections<TableRow<Row>> | undefined;

  //mói o tym czy funkcja filtrowania po stronie frontendu jest aktywna
  get filterIsActive(): boolean;
}

export type SelectionI<T extends AnyObject> = Pick<ExtendedTableServiceI<T>, 'selected' | 'select' | 'unselect' | 'clearSelection'>

export class ExtendedTableService<Row extends AnyObject, Key extends string = string, Data extends AnyObject = AnyObject, Variables extends BaseVariables = BaseVariables, Context extends AnyObject = AnyObject> extends TableServiceDecorator<Row, Context> implements ExtendedTableServiceI<Row, Key, Data, Variables, Context> {
  constructor(baseTable: BaseExtendedTableServiceI<Row, Context>, protected services: TableServices<Row, Key, Data, Variables, Context>) {
    super(baseTable);
    makeObservable(this);
    if (this.services.repository) {
      this.reactionsManager.add(() => this.paginationConfigForQuery, this.handlePaginationChange.bind(this));
      if (this.services.dataSource) {
        this.setPagination({capacity: this.createCapacity()});
        this.reactionsManager.add(() => this.services.repository?.data, data => {
          this.setPagination({capacity: this.createCapacity(data)});
        });
      }
    }
  }

  @computed
  public get selections(): Selections<TableRow<Row>> | undefined {
    return this.services.selection?.selections;
  }

  @computed
  public get focusedRowIndex(): number {
    this.wornIfClickableRowIsDisabled();
    return this.services.clickableRow?.focusedRowIndex ?? -1;
  }

  public get activatedServices(): Record<keyof TableServices<Row, Key, Data, Variables, Context>, boolean> {
    return {
      pagination: !!this.services.pagination,
      dataSource: !!this.services.dataSource,
      selection: !!this.services.selection,
      repository: !!this.services.repository,
      context: !!this.services.context,
      clickableRow: !!this.services.clickableRow,
      filter: !!this.services.filter,
    };
  }

  @computed
  public get loading(): boolean {
    return this.services.repository?.loading ?? false;
  }

  @computed
  public get selected(): TableRow<Row>[] {
    this.wornIfSelectionIsDisabled();
    return this.services.selection?.selected ?? [];
  }

  @computed
  public get filterIsActive(): boolean {
    return !!this.services.filter?.isActive;
  }

  @override
  public override get headers(): TableHeaderProps[] {
    const {pagination, selection} = this.services;
    if (selection) {
      return selection.headers;
    }
    if (pagination) {
      return pagination.headers;
    }
    return super.headers;
  }

  @override
  public override get matrix(): Matrix<Row, Context> {
    const {pagination, selection, clickableRow, filter} = this.services;
    let matrix: Matrix<Row, Context> | undefined = undefined;
    if (filter) {
      matrix = filter.getMatrix(super.matrix, filter.filter, filter.isActive);
    }
    if (pagination) {
      matrix = matrix ? pagination.getMatrix(matrix) : pagination.matrix;
    }

    if (clickableRow) {
      matrix = matrix ? clickableRow.getMatrix(matrix, this) : clickableRow.matrix;
    }

    if (selection) {
      matrix = matrix ? selection.getMatrix(matrix, this) : selection.matrix;
    }

    return matrix ?? super.matrix;

  }

  @computed
  public get offset(): number {
    this.wornIfPaginationIsDisabled();
    return this.services.pagination?.offset ?? 0;
  }

  @computed
  public get focusedRow(): TableRow<Row> | undefined {
    return this.services?.clickableRow?.focusedRow;
  }

  public get selectedRowClassName(): string | undefined {
    return this.services.selection?.selectedRowClassName;
  }

  @computed
  public get currentPage(): number {
    this.wornIfPaginationIsDisabled();
    return this.services.pagination?.current ?? 0;
  }

  @computed
  public get size(): number {
    this.wornIfPaginationIsDisabled();
    return this.services.pagination?.size ?? 0;
  }

  @computed
  public get pagesCapacity(): number | undefined {
    this.wornIfPaginationIsDisabled();
    return this.services.pagination?.pages;
  }

  @computed
  private get paginationConfigForQuery(): Partial<Variables> | undefined {
    const isClientSideSorting = !!this.baseTableService.columns[this.sortBy?.name ?? '']?.sortingCompare;
    const params = this.services.dataSource?.prepareVariablesConfig({
      clientSideSortingEnabled: isClientSideSorting,
      serverSidePaginationEnabled: !!this.activatedServices.pagination && !!this.services.pagination?.serverModeEnabled,
      size: this.size,
      offset: this.offset,
      sortBy: this.sortBy,
    });
    return isEmpty(params) ? undefined : params;
  }

  public async fetch(): Promise<void> {
    this.wornIfRepositoryIsDisabled();
    await this.services.repository?.fetch?.();
  }

  public configureRequest(variables: Partial<Variables> | null): this {
    this.wornIfRepositoryIsDisabled();
    this.services.repository?.configure?.(variables);
    return this;
  }

  @action.bound
  public setSorting(params: SortingSetterParams): this {
    this.setSortBy(params.sortBy);
    params.resetPagination && this.setPagination({page: 1});
    return this;
  }

  public setPagination(arg: SetterPaginationArg): this {
    this.wornIfPaginationIsDisabled();
    this.services?.pagination?.set?.(arg);
    return this;
  }

  public rowClassName(row: TableRow<Row>): string | undefined {
    return this.services?.clickableRow?.rowClassName?.(row);
  }

  public focusedRowClassName(row: TableRow<Row>): string | undefined {
    return this.services?.clickableRow?.focusedRowClassName?.(row);
  }

  @action.bound
  public setFilter({filter, isActive}: SetFilterParams<Row, Context>): this {
    this.wornIfFilterIsDisabled();
    filter && this.services.filter?.setFilter?.(filter);
    isActive !== undefined && this.services.filter?.setIsActive?.(isActive);
    return this;
  }

  public checkIsSelected(row: TableRow<Row>): boolean {
    return !!this.services?.selection?.checkIsSelected?.(row);
  }

  public checkIsFocused(row: TableRow<Row>): boolean {
    return !!this.services?.clickableRow?.checkIsFocused?.(row);
  }

  public setFocusedRow(row: TableRow<Row> | undefined): void {
    this.services?.clickableRow?.setFocusedRow?.(row);
  }

  public handleClick(focusedRow: TableRow<Row>, e?: EventClick): void {
    this.services?.clickableRow?.handleClick?.(focusedRow, e);
  }

  @action.bound
  public select(rows: TableRow<Row> | TableRow<Row>[]): void {
    const rowsArr = Array.isArray(rows) ? rows : [rows];
    this.wornIfSelectionIsDisabled();
    this.services.selection?.select?.(...rowsArr);
  }

  @action.bound
  public unselect(rows: TableRow<Row> | TableRow<Row>[]): void {
    const rowsArr = Array.isArray(rows) ? rows : [rows];
    this.wornIfSelectionIsDisabled();
    this.services.selection?.unselect?.(...rowsArr);
  }

  @action.bound
  public clearSelection(): void {
    this.services.selection?.reset?.();
  }

  @action.bound
  public configureColumn(key: keyof Row, column: Partial<Col<Row, Context>>): this {
    this.setColumns(columns => {
      if (key in columns && !isEmpty(column)) {
        return {...columns, [key]: {...columns[key], ...column}};
      }
      return columns;
    });
    return this;
  }

  protected override _onMount(): void {
    this.baseTableService.onMount();
    this.services.filter?.onMount?.();
    this.services.pagination?.onMount?.();
    this.services.dataSource?.onMount?.();
    this.services.selection?.onMount?.();
    this.services.clickableRow?.onMount?.();
    this.paginationConfigForQuery && this.services.repository?.configure?.(this.paginationConfigForQuery);
  }

  protected override _onUnmount(): void {
    this.baseTableService.onUnmount();
    this.services.filter?.onUnmount?.();
    this.services.pagination?.onUnmount?.();
    this.services.dataSource?.onUnmount?.();
    this.services.selection?.onUnmount?.();
    this.services.clickableRow?.onUnmount?.();
  }

  private async handlePaginationChange(currentVariables: Partial<Variables> | undefined, prevVariables: Partial<Variables> | undefined): Promise<void> {
    if (!this.services.repository || !currentVariables || isEqual(currentVariables, prevVariables)) {
      return;
    }
    await this.services.repository.configure(currentVariables).fetch();
  }

  private createCapacity(data?: Data[Key] | null | undefined): number | undefined {
    if (!this.services.dataSource) {
      return undefined;
    }
    return Math.ceil(this.services.dataSource.getTotalCount(data) / this.size);
  }

  private wornIfSelectionIsDisabled(): boolean {
    if (!this.activatedServices.selection) {
      console.warn('Selection is enabled');
      return true;
    }
    return false;
  }

  private wornIfClickableRowIsDisabled(): boolean {
    if (!this.activatedServices.clickableRow) {
      console.warn('Clickable row is enabled');
      return true;
    }
    return false;
  }

  private wornIfFilterIsDisabled(): boolean {
    if (!this.activatedServices.filter) {
      console.warn('Filter is enabled');
      return true;
    }
    return false;
  }

  private wornIfRepositoryIsDisabled(): boolean {
    if (!this.activatedServices.repository) {
      console.warn('Repository is enabled');
      return true;
    }
    return false;
  }

  private wornIfPaginationIsDisabled(): boolean {
    if (!this.activatedServices.pagination) {
      console.warn('Pagination is enabled');
      return true;
    }
    return false;
  }
}
