import {useCallback} from 'react';

export type Throttled<T extends Array<any>, P = unknown> = (...args: T) => P

export function useThrottle<T extends Array<any> = any[]>(fn: Throttled<T>, time: number, config?: { ignore?: boolean }): Throttled<T> {
  return useCallback<Throttled<T>>(throttle(fn, time, config?.ignore), [fn, time, config?.ignore]);
}


export function throttle<T extends Array<any> = any[]>(fn: Throttled<T>, time: number = 300, ignore: boolean = false): Throttled<T> {
  if (ignore) {
    return fn;
  }

  let invokeTimestamp: number = 0;

  return (...args: Parameters<typeof fn>) => {

    const currentTimestamp = Date.now();

    if (invokeTimestamp <= currentTimestamp - time) {
      invokeTimestamp = currentTimestamp;
      fn(...args);
    }
  };
}
