import {ICategoryAccountState} from './state/ICategoryAccountState';
import {CategoryAccountsTableRedirections} from './CategoryAccountsTableRedirections';
import {Dropdown, DropdownSize, DropdownWidth} from '@symfonia/brandbook';
import {CategoryTypesFilterOptions} from './models/CategoryTypesFilterOptions';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../../../../../../root/IntlProvider';
import {SearchServiceI} from '../../../../../../../../root/services/SearchService/SearchService';
import {SearchInput} from '../../../../../../../../root/components/SearchInput';
import {CategoryAccountService} from './CategoryAccount.service';

type Props = {
  children: React.ReactNode;
  dropdownValue: string[];
  dropdownOnChange: (value: string) => void;
  state: ICategoryAccountState;
  service: CategoryAccountService;
};

const CategoryAccountTableHeader = ({children, dropdownValue, dropdownOnChange, service, state}: Props) => {
  const options = [
    {
      label: intl.formatMessage({id: Tr.allCategories}),
      value: CategoryTypesFilterOptions.All,
    },
    {
      label: intl.formatMessage({id: Tr.basicCategories}),
      value: CategoryTypesFilterOptions.Default,
    },
    {
      label: intl.formatMessage({id: Tr.ownCategories}),
      value: CategoryTypesFilterOptions.Custom,
    },
  ];

  return (
    <div className="flex justify-between w-full md:flex-nowrap flex-wrap">
      <div className="flex gap-[8px] items-center sm:flex-nowrap flex-wrap">
        <SearchInput translationKey={Tr.search} service={service.searchService} className={'w-[230px]'}/>
        <Dropdown
          size={DropdownSize.SM}
          onChange={v => dropdownOnChange(v[0])}
          options={options}
          value={dropdownValue}
          className="w-[230px]"
        />
        {children}
      </div>
      <CategoryAccountsTableRedirections service={service} state={state}/>
    </div>
  );
};

export default CategoryAccountTableHeader;
