import {AnyObject} from 'yup/es/types';
import {BaseExtendedTableServiceI, Col, Columns, Row as TableRow} from './BaseTableService';
import {computed, makeObservable} from 'mobx';
import {TableHeaderProps, TableSort} from '@symfonia/brandbook';
import {Key, ReactElement} from 'react';
import {BaseModule} from '../MobXServices/BaseModule';

export class TableServiceDecorator<Row extends AnyObject, Context extends AnyObject = AnyObject> extends BaseModule implements BaseExtendedTableServiceI<Row, Context> {
  constructor(protected baseTableService: BaseExtendedTableServiceI<Row, Context>) {
    super();
    makeObservable(this);
  }

  @computed
  public get sortBy(): TableSort | undefined {
    return this.baseTableService.sortBy;
  }

  public get context(): Context {
    return this.baseTableService.context;
  }

  @computed
  public get actionIsSticky(): boolean {
    return this.baseTableService.actionIsSticky;
  }

  @computed
  public get columns(): Columns<Row, Context> {
    return this.baseTableService.columns;
  }

  @computed
  public get rows(): TableRow<Row>[] {
    return this.baseTableService.rows;
  }

  @computed
  public get columnsList(): Col<Row, Context>[] {
    return this.baseTableService.columnsList;
  }

  @computed
  public get matrix() {
    return this.baseTableService.matrix;
  }

  @computed
  public get widths(): string[] {
    return this.baseTableService.widths;
  }

  @computed
  public get sortRows(): TableRow<Row>[] {
    return this.baseTableService.sortRows;
  }

  @computed
  public get headers(): TableHeaderProps[] {
    return this.baseTableService.headers;
  }

  public createTableRow(row: TableRow<Row>, columns: Columns<Row, Context>): ReactElement[] {
    return this.baseTableService.createTableRow(row, columns);
  }

  public setSortBy(sortBy: TableSort): void {
    this.baseTableService.setSortBy(sortBy);
  }

  public setRows(rows: Array<Row> | ((currentRows: Array<Row>) => Array<Row>)): void {
    this.baseTableService.setRows(rows);
  }

  public setColumns(columns: Columns<Row, Context> | ((currentColumns: Columns<Row, Context>) => Columns<Row, Context>)): void {
    this.baseTableService.setColumns(columns);
  }

  public configureRows(rowsConfig: Record<Key, Row>): void {
    this.baseTableService.configureRows(rowsConfig);
  }

  public configureColumns(columnsConfig: Partial<Record<keyof (Row), Partial<Omit<Col<Row, Context>, 'field' | 'cell'>>>>): void {
    this.baseTableService.configureColumns(columnsConfig);
  }

  protected override _onMount(): Promise<void> | void {
    return this.baseTableService.onMount();
  }

  protected override _onUnmount(): void {
    return this.baseTableService.onUnmount();
  }
}
