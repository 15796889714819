import {defineMessages} from 'react-intl';

export const calendarT = defineMessages({
  dzisiaj: {
    defaultMessage: 'Dzisiaj',
    id: 'ae13fb58-2e48-4224-83e5-94de9d6f79c4',
    description: 'Przycisk zaznaczania dzisiejszej daty, wartość dla "Dzisiaj"',
  },
  styczen: {
    defaultMessage: 'Styczeń',
    id: '83a3ba8b-02f2-4a80-be54-31621d1b4675',
    description: 'Miesiąc w kalendarzu - wartość dla "Styczeń"',
  },
  luty: {
    defaultMessage: 'Luty',
    id: '7cd83387-63b8-40df-bb79-bf216997b02c',
    description: 'Miesiąc w kalendarzu - wartość dla "Luty"',
  },
  marzec: {
    defaultMessage: 'Marzec',
    id: 'ae24cdc8-bcd0-4395-a825-9b1312f523db',
    description: 'Miesiąc w kalendarzu - wartość dla "Marzec"',
  },
  kwiecien: {
    defaultMessage: 'Kwiecień',
    id: '216ecf1c-1ba1-4aca-9bc7-813dd1487f6b',
    description: 'Miesiąc w kalendarzu - wartość dla "Kwiecień"',
  },
  maj: {
    defaultMessage: 'Maj',
    id: '7252cfdd-dfd4-42aa-8818-1c318fc59a24',
    description: 'Miesiąc w kalendarzu - wartość dla "Maj"',
  },
  czerwiec: {
    defaultMessage: 'Czerwiec',
    id: '94d6c02b-a36f-4bfa-842f-1a76d7c20291',
    description: 'Miesiąc w kalendarzu - wartość dla "Czerwiec"',
  },
  lipiec: {
    defaultMessage: 'Lipiec',
    id: 'bb8030da-193d-4357-a05c-34504d5ddb2b',
    description: 'Miesiąc w kalendarzu - wartość dla "Lipiec"',
  },
  sierpien: {
    defaultMessage: 'Sierpień',
    id: '8e514cf1-3237-4f45-8095-e879f85cb526',
    description: 'Miesiąc w kalendarzu - wartość dla "Sierpień"',
  },
  wrzesien: {
    defaultMessage: 'Wrzesień',
    id: 'ada34467-bd91-43e8-a3fa-c27403fdc5f3',
    description: 'Miesiąc w kalendarzu - wartość dla "Wrzesień"',
  },
  pazdziernik: {
    defaultMessage: 'Październik',
    id: '8065467a-7d69-4adf-a196-ec9108036f30',
    description: 'Miesiąc w kalendarzu - wartość dla "Październik"',
  },
  listopad: {
    defaultMessage: 'Listopad',
    id: '386e9e63-cb4c-48f1-9e7c-676dcf9472d0',
    description: 'Miesiąc w kalendarzu - wartość dla "Listopad"',
  },
  grudzien: {
    defaultMessage: 'Grudzień',
    id: '1dddb1d1-b907-4660-a270-6a073fca61f1',
    description: 'Miesiąc w kalendarzu - wartość dla "Grudzień"',
  },

  poniedzialek: {
    defaultMessage: 'Poniedziałek',
    id: '8a7a5bd7-8e97-425e-aac3-9d7615875728',
    description: 'Dni w kalendarzu - wartość dla "Poniedziałek"',
  },
  wtorek: {
    defaultMessage: 'Wtorek',
    id: '4e394a03-13bc-4fc6-a274-cf420601770b',
    description: 'Dni w kalendarzu - wartość dla "Wtorek"',
  },
  sroda: {
    defaultMessage: 'Środa',
    id: '8771d6e8-9228-43ba-aaeb-619fa681f26c',
    description: 'Dni w kalendarzu - wartość dla "Środa"',
  },
  czwartek: {
    defaultMessage: 'Czwartek',
    id: '8161db2b-979b-4000-8b79-42078bd765b1',
    description: 'Dni w kalendarzu - wartość dla "Czwartek"',
  },
  piatek: {
    defaultMessage: 'Piątek',
    id: 'e0cdfa1d-c92a-4748-8aed-6fe3d7d54913',
    description: 'Dni w kalendarzu - wartość dla "Piątek"',
  },
  sobota: {
    defaultMessage: 'Sobota',
    id: '77ce37bf-91a5-4c0a-aa32-20ab3aedd526',
    description: 'Dni w kalendarzu - wartość dla "Sobota"',
  },
  niedziela: {
    defaultMessage: 'Niedziela',
    id: '7194c183-59d0-4dba-9033-1900ad07b87b',
    description: 'Dni w kalendarzu - wartość dla "Niedziela"',
  },
});
